import React, {useEffect, useState} from 'react'
import {AgencyView} from './AgencyView'
import {SearchOutlined} from '@ant-design/icons'
import {Input, message} from 'antd'
import './basic.css'
import {CampaignLive} from './CampaignLive'
import {CampaignComplete} from './CampaignComplete'
import {CampaignReview} from './CamaignReview'
import {useHistory} from 'react-router-dom'
import httpService from '../../setup/http/httpServices'

export function AgencyViewCampaigns() {
  const history: any = useHistory()
  const [tab, setTab] = useState('live')
  const [campaign, setCampaign] = useState([])
  const [name, setName] = useState('')
  const [foundCampaigns, setFoundCampaigns] = useState([])

  useEffect(() => {
    getCampaigns()
  }, [tab])
  const getCampaigns = () => {
    httpService.getData(
      history.location.state.type === 'BrandView'
        ? '/brand-master-details/' + history.location?.state?.id + '/campaign'
        : '/agency-details/campaign/' + history?.location?.state?.id + '?status=' + tab,
      (response: any) => {
        setCampaign(response?.data?.campaigns)
        setFoundCampaigns(response?.data?.campaigns)
      },
      (error: any) => {
        message.error(error?.message)
      }
    )
  }
  const filter = (e: any) => {
    const keyword = e.target.value

    if (keyword !== '') {
      const results = campaign.filter((campaign: any) => {
        return campaign.campaignDetails.name.toLowerCase().startsWith(keyword.toLowerCase())
        // Use the toLowerCase() method to make it case-insensitive
      })
      setFoundCampaigns(results)
    } else {
      setFoundCampaigns(campaign)
      // If the text field is empty, show all users
    }
    setName(keyword)
  }
  return (
    <div className='Outer d-flex'>
      <AgencyView
        active={3}
        details={history?.location?.state?.profileDetails}
        id={history?.location?.state?.id}
        type={history?.location?.state?.type}
      />
      <div className='view-Campaigns'>
        <div className='Campaigns-topbar'>
          <div className='Campaigns-topbar-left'>
            {tab === 'live' ? (
              <button className='active'>Ongoing ⚡</button>
            ) : (
              <button onClick={() => setTab('live')}>Ongoing ⚡</button>
            )}
            {tab === 'underReview' ? (
              <button className='active'>In Review 🔎</button>
            ) : (
              <button onClick={() => setTab('underReview')}>In Review 🔎</button>
            )}
            {tab === 'completed' ? (
              <button className='active'>Completed ✅ </button>
            ) : (
              <button onClick={() => setTab('completed')}>Completed ✅ </button>
            )}
          </div>
          <div className='Campaigns-topbar-right'>
            <Input
              placeholder='Search Campaigns'
              style={{width: '254px', height: '41px'}}
              className='form-control-solid border'
              onChange={filter}
              value={name}
              prefix={
                <>
                  <SearchOutlined />
                </>
              }
            />
          </div>
        </div>
        <div className='Campaigns-body container'>
          {history.location.state.type == 'BrandView' && <CampaignLive campaign={foundCampaigns} />}
          {history.location.state.type != 'BrandView' && (
            <>
              {tab === 'live' && <CampaignLive campaign={foundCampaigns} />}
              {tab === 'underReview' && <CampaignReview campaign={foundCampaigns} />}
              {tab === 'completed' && <CampaignComplete campaign={foundCampaigns} />}
            </>
          )}
        </div>
      </div>
    </div>
  )
}
