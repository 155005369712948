import React, {useState, useEffect} from 'react'
import {useHistory} from 'react-router-dom'
import {toAbsoluteUrl} from '../../_metronic/helpers'
import {StatsAll} from './Charts/StatsAll'
import {Engagement} from './Components/Engagement'
import {NoData} from './Components/NoData'
import {RecentlyAdd} from './Components/RecentlyAdd'
import {ViewApp} from './Components/ViaApp'
import './Dashboard.scss'
import httpService from '../../setup/http/httpServices'

export function DashboardInfluencer() {
  const rows: any[] = [1, 2, 3, 4, 5]
  const data = true
  const history = useHistory()
  const [dashboardData, setDashboardData] = useState({} as any)

  const getAllData = () => {
    httpService.getData(
      '/dashboard/admin-influencer',
      (res: any) => {
        setDashboardData(res?.data?.data)
      },
      (err: any) => {
        console.log(err?.message)
      }
    )
  }
  useEffect(() => {
    getAllData()
  }, [])

  return (
    <div className='Outer d-flex flex-column'>
      <div className='Dinfluencer'>
        <div className='flex Dinfluencer-top justify-content-between'>
          <div className='Dcard Dash-left' style={{width: '620px', height: '180px'}}>
            <div>
              <div className='Dash-left-top'>
                <div className='Dash-left-image svg-icon svg-icon-secondary bg-secondary bg-opacity-5'>
                  <img src={toAbsoluteUrl('/media/logos/brands.png')} alt='' />
                </div>
                <div className='Dash-left-head'>
                  <h2 style={{fontSize: '16px'}}>Influencers</h2>
                  <p>Total Influencers</p>
                </div>
              </div>
              {!data ? (
                <div className='Dash-left-bottom mt-10'>
                  <h1 className='DheadingBig'>-</h1>
                </div>
              ) : (
                <div className='Dash-left-bottom mt-10'>
                  <h1 className='DheadingBig'>{dashboardData?.totalInfluencer || 0}</h1>
                </div>
              )}
            </div>
            {!data ? (
              <NoData height='160px' />
            ) : (
              <div style={{marginTop: '-40px', width: '400px'}}>
                <StatsAll className='w-100' stats={[]} />
              </div>
            )}
          </div>
          <div className='Dcard Dinfluencer-right' style={{width: '370px', height: '180px'}}>
            <div className='Dinfluencer-right-top'>
              <div className='Dinfluencer-right-img'>
                <img src={toAbsoluteUrl('/media/logos/Instagram.png')} alt='' />
              </div>
              <div className='Dinfluencer-right-head'>
                <h2>Total Instagram Influencers</h2>
              </div>
            </div>
            {!data ? (
              <div className='Dinfluencer-right-bottom'>
                <h1 className='DheadingBig'>-</h1>
              </div>
            ) : (
              <div className='Dinfluencer-right-bottom'>
                <h1 className='DheadingBig'>{dashboardData?.instagramInfluencer || 0}</h1>
              </div>
            )}
          </div>
          <div className='Dcard Dinfluencer-right' style={{width: '370px', height: '180px'}}>
            <div className='Dinfluencer-right-top'>
              <div className='Dinfluencer-right-img'>
                <img src={toAbsoluteUrl('/media/logos/Youtube.png')} alt='' />
              </div>
              <div className='Dinfluencer-right-head'>
                <h2>Total YouTube Influencers</h2>
              </div>
            </div>
            {!data ? (
              <div className='Dinfluencer-right-bottom'>
                <h1 className='DheadingBig'>-</h1>
              </div>
            ) : (
              <div className='Dinfluencer-right-bottom'>
                <h1 className='DheadingBig'>{dashboardData?.youtubeInfluencer}</h1>
              </div>
            )}
          </div>
        </div>
        <div className='flex justify-content-start align-items-start'>
          <ViewApp data={dashboardData?.recentlyJoinedInfluencer} />
          <RecentlyAdd data={dashboardData?.recentlyJoinedInfluencer} />
        </div>
        <div className='flex Dinfluencer-bottom align-items-start'>
          <Engagement data={dashboardData?.topEngagementInfluencer} />
          <div className='Dcard' style={{width: '370px'}}>
            <h2 className='Dheading'>Top Instagram Influencers</h2>
            {dashboardData?.topInstagramInfluencer?.length === 0 ? (
              <NoData />
            ) : (
              <div className='table-responsive'>
                <table className='table table-row-dashed table-row align-middle gs-0 gy-4 m-0'>
                  <thead>
                    <tr className='fw-bolder text-muted'>
                      <th>
                        <span className='d-flex' style={{width: '150px'}}>
                          Influencers
                        </span>
                      </th>
                      <th>
                        <span className='d-flex'>Followers</span>
                      </th>
                      <th>
                        <span className='d-flex' style={{width: '60px'}}>
                          Eng Rate
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {dashboardData?.topInstagramInfluencer?.map((item, index) => (
                      <tr key={index} className=''>
                        <td className='fw-bolder'>
                          <img
                            className='Davatar'
                            src={item?.socialMediaDetails?.instagram?.avatar}
                            alt=''
                          />
                          {item?.basicInfo?.name}
                        </td>
                        <td className='fw-bolder'>
                          {item?.socialMediaDetails?.instagram?.basicStats?.followers >= 1000 &&
                          item?.socialMediaDetails?.instagram?.basicStats?.followers < 1000000
                            ? `${
                                item?.socialMediaDetails?.instagram?.basicStats?.followers / 1000
                              }K`
                            : item?.socialMediaDetails?.instagram?.basicStats?.followers >= 1000000
                            ? `${
                                item?.socialMediaDetails?.instagram?.basicStats?.followers / 1000000
                              }M`
                            : item?.socialMediaDetails?.instagram?.basicStats?.followers}
                        </td>
                        <td className='fw-bolder'>
                          {(
                            item?.socialMediaDetails?.instagram?.basicStats?.engagementRate * 100
                          ).toFixed(2)}
                          %
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
          <div className='Dcard' style={{width: '370px', marginLeft: '20px'}}>
            <h2 className='Dheading'>Top YouTube Influencers</h2>
            {dashboardData?.topYoutubeInfluencer?.length === 0 ? (
              <NoData />
            ) : (
              <div className='table-responsive'>
                <table className='table table-row-dashed table-row align-middle gs-0 gy-4 m-0'>
                  <thead>
                    <tr className='fw-bolder text-muted'>
                      <th>
                        <span className='d-flex' style={{width: '150px'}}>
                          Influencers
                        </span>
                      </th>
                      <th>
                        <span className='d-flex'>Subscribers</span>
                      </th>
                      <th>
                        <span className='d-flex' style={{width: '60px'}}>
                          Eng Rate
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {dashboardData?.topYoutubeInfluencer?.map((item, index) => (
                      <tr key={index} className=''>
                        <td className='fw-bolder'>
                          <img
                            className='Davatar'
                            // src={toAbsoluteUrl('/media/avatars/150-1.jpg')}
                            src={item?.socialMediaDetails?.youtube?.avatar}
                            alt=''
                          />
                          {item?.basicInfo?.name}
                        </td>
                        <td className='fw-bolder'>
                          {item?.socialMediaDetails?.youtube?.basicStats?.followers >= 1000 &&
                          item?.socialMediaDetails?.youtube?.basicStats?.followers < 1000000
                            ? `${item?.socialMediaDetails?.youtube?.basicStats?.followers / 1000}K`
                            : item?.socialMediaDetails?.youtube?.basicStats?.followers >= 1000000
                            ? `${
                                item?.socialMediaDetails?.youtube?.basicStats?.followers / 1000000
                              }M`
                            : item?.socialMediaDetails?.youtube?.basicStats?.followers}
                        </td>
                        <td className='fw-bolder'>
                          {(
                            item?.socialMediaDetails?.youtube?.basicStats?.engagementRate * 100
                          ).toFixed(2)}
                          %
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
