import React, { useEffect, useState } from 'react'
import '../masters/basic.css'
import { SearchOutlined, RightOutlined } from '@ant-design/icons'
import { Input } from 'antd'
import { toAbsoluteUrl } from '../../_metronic/helpers'

function InfluencerList(props: any) {
  const [name, setName] = useState('')
  // the search result
  const [foundInfluencers, setFoundInfluencers] = useState([])

  useEffect(() => {
    setFoundInfluencers(props.influencers)
  }, [props.influencers])

  const [selectedItem, setSelectedItem] = useState(-1)

  const filter = (e: any) => {
    const keyword = e.target.value

    if (keyword !== '') {
      const results = props.influencers.filter((influencer: any) => {
        return influencer.name.toLowerCase().startsWith(keyword.toLowerCase())
        // Use the toLowerCase() method to make it case-insensitive
      })
      setFoundInfluencers(results)
    } else {
      setFoundInfluencers(props.influencers)
      // If the text field is empty, show all users
    }
    setName(keyword)
  }
  return (
    <div className='List' style={{ height: props.height }}>
      <div className='basicDiv w-400px mt-0 overflow-auto' style={{ height: '100%' }}>
        <Input
          placeholder='Search Influencers'
          className='form-control-solid border rounded'
          value={name}
          onChange={filter}
          prefix={
            <>
              <SearchOutlined />
            </>
          }
        />
        <div className='influencers-list rounded'>
          {foundInfluencers && foundInfluencers.length > 0 ? (
            foundInfluencers.map((influencer: any, index: number) => (
              <div
                key={index}
                className={
                  selectedItem == index
                    ? 'd-flex justify-content-start align-items-center list-item bg-grey'
                    : 'd-flex justify-content-start align-items-center list-item'
                }
                onClick={(e) => {
                  props.handleClick(e, index)
                  setSelectedItem(index)
                }}
              >
                <div>
                  {influencer?.photo ? <img
                    src={
                      influencer?.photo
                    }
                    className='rounded-circle profile-photo'
                    alt=''
                  /> :
                    <label
                      className='btn-primary imageBadge symbol'
                      style={{ fontSize: 26, borderRadius: '100%', width: '50px', height: '50px' }}
                    >
                      {influencer.name[0].toUpperCase()}
                    </label>
                  }
                </div>
                <div className='flex justify-content-between ms-3 w-100'>
                  <div className='flex flex-column justify-content-start align-items-start'>
                    <span
                      className={influencer.isPaid ? 'influencer-name' : 'influencer-name grayout'}
                    >
                      {influencer.name}
                    </span>
                    <span className='influencer-location'>
                      {influencer?.city && influencer?.city + ', ' + influencer?.state}
                    </span>
                    <span className='message-badge'>
                      {influencer.textsReceived > 0 && (
                        <span className='badge'>{influencer.textsReceived}</span>
                      )}
                    </span>
                  </div>
                  {selectedItem === index && (
                    <div style={{ fontSize: '18px', marginRight: '18px' }}>
                      <RightOutlined />
                    </div>
                  )}
                </div>
              </div>
            ))
          ) : (
            <div className='flex flex-column w-100 align-items-center' style={{ height: '500px' }}>
              <img
                src={toAbsoluteUrl('/media/logos/bar-chart 1.png')}
                alt=''
                width='50px'
                height='50px'
              />
              <h2 className='m20'>No influencers listed.</h2>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default InfluencerList
