import React from 'react'
import { useHistory } from 'react-router-dom'
import '../Dashboard.scss'
import { NoData } from './NoData'

export function Brands(props: any) {
  const rows: any[] = [1, 2, 3, 4, 5]
  const history = useHistory();
  const viewBrandOrAgency = (id: string) => {
    history.push({
      pathname: '/master/profile',
      state: {id: id, type: "Masters", pageType: "Brand", breadcrums: ["Brand Master", "View Brand"]},
    })
  }
  return (
    <div className='Dcard Dbrands'>
      <h2 className='Dheading'>Top Brands</h2>
      {props.data?.length === 0 ? (
        <NoData />
      ) : (
        <div className='table-responsive'>
          <table className='table table-row-dashed table-row align-middle gs-0 gy-4 m-0'>
            <thead>
              <tr className='fw-bolder text-muted'>
                <th style={{width: '500px'}}>
                  <span className='d-flex'>Brand</span>
                </th>
                <th style={{width: '120px'}}>
                  <span className='d-flex'>Campaigns</span>
                </th>
                <th style={{width: '50px'}}>
                  <span className='d-flex'>Transactions</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {props.data?.map((item, index) => (
                <tr key={index} className=''>
                  <td className='fw-bolder'>
                    <span onClick={() => viewBrandOrAgency(item.brand._id)} className="cursor-pointer">
                    <img
                      className='Davatar'
                      src={item.brand.avatar}
                      alt=''
                    />
                    {item.brand.brandName}
                    </span>
                  </td>
                  <td className='fw-normal'>{item.campaignCount}</td>
                  <td className='fw-bolder'>-</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  )
}
