import {Input, message, Pagination} from 'antd'
import React, {useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap-v5'
import httpService from '../../setup/http/httpServices'
import {KTSVG, toAbsoluteUrl} from '../../_metronic/helpers'
import Moment from 'moment'
import './basic.css'
import {SearchOutlined} from '@ant-design/icons'

export function BrandMasterTable(props: any) {
  const [sort1, setSort1] = useState('rotate-90 ms-2')
  const [sort2, setSort2] = useState('rotate-90 ms-2')
  const [sort3, setSort3] = useState('rotate-90 ms-2')
  const [asc, setAsc] = useState('brandName')
  const [show, setShow] = useState(false)
  const [status, setStatus] = useState(true)

  const [brandParams, setBrandParams] = useState({page: 1, total: 10, limit: 10, search: ''})
  const [brand, setBrand] = useState([])
  const [deleteId, setDeleteId] = useState('')
  const [totalCount, setTotalCount] = useState(0)

  const key = 'updatable'
  const handleDelete = (id: string) => {
    setShow(false)
    message.loading({content: 'Loading...', key})
    httpService.deleteData(
      '/brand/' + deleteId,
      (res: any) => {
        setTimeout(() => {
          message.success({content: 'Brand deleted!', key, duration: 2})
          setDeleteId('')
        }, 1000)
      },
      (error: any) => {
        message.error({content: error.message, key, duration: 2})
      }
    )
  }
  const Sorting1 = () => {
    if (sort1 == 'rotate-90 ms-2') {
      setSort1('rotate-270 ms-2')
    } else {
      setSort1('rotate-90 ms-2')
    }
    if (sort1 == 'rotate-90 ms-2') {
      setSort3('rotate-90 ms-2')
      setSort2('rotate-90 ms-2')
      setAsc('-brandName')
    } else {
      setSort3('rotate-90 ms-2')
      setSort2('rotate-90 ms-2')
      setAsc('brandName')
    }
  }
  const Sorting2 = () => {
    if (sort2 == 'rotate-90 ms-2') {
      setSort2('rotate-270 ms-2')
    } else {
      setSort2('rotate-90 ms-2')
    }
    if (sort2 == 'rotate-90 ms-2') {
      setSort1('rotate-90 ms-2')
      setSort3('rotate-90 ms-2')
      setAsc('-relevantCategory')
    } else {
      setSort1('rotate-90 ms-2')
      setSort3('rotate-90 ms-2')
      setAsc('relevantCategory')
    }
  }
  const Sorting3 = () => {
    if (sort3 == 'rotate-90 ms-2') {
      setSort3('rotate-270 ms-2')
    } else {
      setSort3('rotate-90 ms-2')
    }
    if (sort3 == 'rotate-90 ms-2') {
      setSort1('rotate-90 ms-2')
      setSort2('rotate-90 ms-2')
      setAsc('-updatedAt')
    } else {
      setSort1('rotate-90 ms-2')
      setSort2('rotate-90 ms-2')
      setAsc('updatedAt')
    }
  }

  useEffect(() => {
    getAllBrands()
  }, [deleteId, props.updatable, brandParams.page, brandParams.search, asc])

  const getAllBrands = () => {
    httpService.getData(
      '/brand?limit=' +
        brandParams.limit +
        '&page=' +
        brandParams.page +
        '&search=' +
        brandParams.search +
        '&sort=' +
        asc,
      (response: any) => {
        setBrand(response.data.data.brands)
        setTotalCount(response.data.data.total)
        setStatus(false)
        // setBrandParams({
        //   page: response.data.data.page,
        //   limit: response.data.data.limit,
        //   total: response.data.data.total,
        //   search: brandParams.search,
        // })
      },
      (error: any) => {
        message.error(error.message)
        setStatus(false)
      }
    )
  }
  const getBrand = (id: number) => {
    props.editBrand(id)
  }
  return (
    <div className='categoryTable'>
      <div className='categoryTableHeader'>
        <div className='mt-3 ps-5' style={{marginRight: '32px'}}>
          <h3 style={{cursor: 'default'}}>Brands</h3>
          <p style={{cursor: 'default'}} className='text-gray-500 fs-7'>
            {totalCount} Brands
          </p>
        </div>
        <div className='TableHeaderSearch d-flex align-items-center'>
          <link
            href='https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css'
            rel='stylesheet'
          />
          <Input
            placeholder='Search'
            style={{width: '326px', height: '41px'}}
            className='form-control-solid bg-gray-100 rounded'
            onChange={(e: any) => setBrandParams({...brandParams, ...{search: e.target.value}})}
            prefix={
              <>
                <SearchOutlined />
              </>
            }
          />
        </div>
      </div>
      <div className='card-body mt-0 p-0 d-flex flex-column justify-content-between'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className=' fw-bolder text-muted  align-items-center'>
                {/* <th className='bg-primary bg-opacity-5  w-25px'>
                  <div className='form-check ms-5 form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value='1'
                      data-kt-check='true'
                      data-kt-check-target='.widget-9-check'
                    />
                  </div>
                </th> */}
                <th className='bg-primary bg-opacity-5 min-w-190px '>
                  <span className='d-flex  ms-5'>
                    Brand Name
                    <a
                      className={sort1}
                      onClick={() => {
                        Sorting1()
                      }}
                    >
                      <KTSVG
                        className='svg-icon svg-icon-sm svg-icon-gray-700 flex h-20px pointer'
                        path={toAbsoluteUrl('/media/icons/Custom/SideArrow.svg')}
                      />
                    </a>
                  </span>
                </th>
                <th className='bg-primary bg-opacity-5 min-w-110px'>
                  <span className='d-flex '>
                    Category
                    <a
                      className={sort2}
                      onClick={() => {
                        Sorting2()
                      }}
                    >
                      <KTSVG
                        className='svg-icon svg-icon-sm svg-icon-gray-700 flex h-20px pointer'
                        path={toAbsoluteUrl('/media/icons/Custom/SideArrow.svg')}
                      />
                    </a>
                  </span>
                </th>
                <th className='bg-primary bg-opacity-5 min-w-150px'>
                  <span className='d-flex '>
                    Added
                    <a
                      className={sort3}
                      onClick={() => {
                        Sorting3()
                      }}
                    >
                      <KTSVG
                        className='svg-icon svg-icon-sm svg-icon-gray-700 flex h-20px pointer'
                        path={toAbsoluteUrl('/media/icons/Custom/SideArrow.svg')}
                      />
                    </a>
                  </span>
                </th>
                {props.access && (
                  <th className='bg-primary bg-opacity-5 min-w-100px d-flex justify-content-end'>
                    <span className='d-flex '>Actions hew</span>
                  </th>
                )}
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {brand?.length > 0 &&
                brand.map((data: any) => (
                  <tr>
                    {/* <td>
                      <div className='form-check ms-5 form-check-sm form-check-custom form-check-solid'>
                        <input
                          className='form-check-input widget-9-check'
                          type='checkbox'
                          value='1'
                        />
                      </div>
                    </td> */}
                    <td className='fw-bolder'>
                      <div className='d-flex align-items-center ms-5'>
                        <div className='symbol symbol-30px symbol-circle me-5'>
                          <img src={data.avatar} alt='' />
                          {/* <img src={toAbsoluteUrl('/media/logos/logo-2.svg')} alt='' /> */}
                        </div>
                        <div className='d-flex justify-content-start flex-column'>
                          <span className='text-dark fw-bolder text-hover-primary fs-6'>
                            {data.brandName}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td className='fw-bold'>
                      {data?.relevantCategory.map((x: any) => (
                        <>
                          {x.categoryName}
                          <br />
                        </>
                      ))}
                    </td>
                    <td>
                      <span className='text-dark fw-bold text-hover-primary d-block fs-7'>
                        {Moment(data.updatedAt).format('DD MMMM YYYY')}
                      </span>
                      <span className='text-muted fw-bold text-muted d-block fs-7'>
                        {data?.modifiedBy.firstName + ' ' + data?.modifiedBy.lastName}
                      </span>
                    </td>
                    {props.access && (
                      <td>
                        <div className='d-flex justify-content-end flex-shrink-0'>
                          <span
                            className='svg-icon flex svg-icon-dark bg-primary bg-opacity-5 w-30px h-30px rounded me-2 pointer'
                            onClick={() => getBrand(data._id)}
                          >
                            <KTSVG
                              className='svg-icon-3'
                              path={toAbsoluteUrl('/media/icons/Custom/Pen.svg')}
                            />
                          </span>
                          <span
                            onClick={() => {
                              setShow(true)
                              setDeleteId(data._id)
                            }}
                            className='svg-icon flex svg-icon-danger bg-danger bg-opacity-5 w-30px h-30px rounded pointer'
                          >
                            <KTSVG
                              className='svg-icon-3'
                              path={toAbsoluteUrl('/media/icons/Custom/Dustbin.svg')}
                            />
                          </span>
                          <Modal
                            show={show}
                            onHide={() => {
                              setShow(false)
                              setDeleteId('')
                            }}
                            aria-labelledby='contained-modal-title-vcenter'
                            centered
                          >
                            <Modal.Header>
                              <Modal.Title>Delete</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>Are you sure you wanna delete?</Modal.Body>
                            <Modal.Footer>
                              <button
                                className='btn fw-bolder btn-gray-200 fs-6 dialogButton'
                                onClick={() => {
                                  setShow(false)
                                  setDeleteId('')
                                }}
                              >
                                Cancel
                              </button>
                              <button
                                className='btn create-btn fs-6 dialogButton ms-2'
                                onClick={() => handleDelete(deleteId)}
                              >
                                Delete
                              </button>
                            </Modal.Footer>
                          </Modal>
                        </div>
                      </td>
                    )}
                  </tr>
                ))}
              {brand.length === 0 && !status && (
                <tr>
                  <td colSpan={5}>
                    <div className='m-10'>
                      <div className='flex'>
                        <img
                          className='ms-20'
                          src={toAbsoluteUrl('/media/logos/TableVector.png')}
                        ></img>
                      </div>
                      <div className='flex'>
                        <div>
                          <h2 className='mb-0 m24 flex'>No Brand Listed</h2>
                          {/* <p className='m-0 mt-5 w-600px ms-auto me-auto text-center'>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                            tempor incididunt ut labore et dolore magna aliquaLorem ipsum dolor sit
                            amet
                          </p> */}
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              )}
              {brand.length === 0 && status && (
                <tr>
                  <td colSpan={5}>
                    <div className='m-10'>
                      <div className='flex'>
                        <span className='svg-icon svg-icon-5x flex svg-icon-primary me-3 ms-8'>
                          <KTSVG path={toAbsoluteUrl('/media/icons/Custom/Loading.svg')} />
                        </span>
                      </div>
                      <div className='flex'>
                        <div>
                          <h5 className='mb-0 mt-3 flex'>Fetching Data</h5>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          {/* end::Table */}
        </div>
        {/* end::Table container */}
        <div
          className='d-flex ms-5 me-10 justify-content-between align-items-end flex-wrap'
          style={{marginBottom: '16px'}}
        >
          <span className='text-muted'>
            Page {brandParams.page} of{' '}
            {Math.floor(totalCount % brandParams.limit) === 0
              ? Math.floor(totalCount / brandParams.limit)
              : Math.floor(totalCount / brandParams.limit) + 1}
          </span>
          <div className='d-flex flex-wrap Pagination '>
            <Pagination
              className=''
              pageSize={brandParams.limit}
              defaultCurrent={brandParams.page}
              total={totalCount}
              onChange={(e) => setBrandParams({...brandParams, ...{page: e}})}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
