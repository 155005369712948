import React from 'react'
import { KTSVG, toAbsoluteUrl } from '../../_metronic/helpers'

export function InfluencerDetailById(props: any) {
  return (
    <div className=''>
      <div className='d-flex w-100'>
        <div className='basicDiv col'>
          <h5>Gender</h5>
          <div className='m16 d-flex flex-column'>
            {props.data?.audienceDetails?.gender.map(x => (
              <div className='bg-gray-100 br-50 fw-bolder p-3 ps-5 pe-5 mb-3'>
                <KTSVG
                  className='svg-icon-4 w-15px h-15px svg-icon-dark me-2'
                  path={
                    x === 'Male'
                      ? toAbsoluteUrl('/media/icons/Custom/male.svg')
                      : toAbsoluteUrl('/media/icons/Custom/female.svg')
                  }
                ></KTSVG>
                {x}
              </div>
            ))}
            {props.data?.audienceDetails?.gender.length === 0 && (
              <span>No data available.</span>
            )}
          </div>
        </div>
        <div className='basicDiv ms-7 col'>
          <h5>Age Group</h5>
          <div className='m16 d-flex flex-column'>
            {props.data?.audienceDetails?.ageGroup.sort().map((age: any) => (
              <div className='bg-gray-100 br-50 fw-bolder p-3 ps-5 pe-5  mb-3'>
                <li className='nav-item'>{age}</li>
              </div>
            ))}
            {props.data?.audienceDetails?.ageGroup.length === 0 && (
              <span>No data available.</span>
            )}
          </div>
        </div>
      </div>
      <div className='basicDiv m20 col'>
        <h5>Location</h5>
        {props.data?.audienceDetails?.location ? (
          <div className='m16 d-flex'>
            {props.data?.audienceDetails?.location.split(',').map((locationName: any) => (
              <div className='bg-gray-100 br-50 p-3 ps-5 pe-5 me-3'>
                {locationName}
              </div>
            ))}
          </div>
        ) : (
          <div className='m16 d-flex'>No data avilable</div>
        )}
      </div>
      <div className='basicDiv m20 col'>
        <h5>Languages</h5>
        <div className='m16 d-flex'>
          {props.data?.audienceDetails?.language.map((lan: any) => (
            <div className='bg-gray-100 br-50 p-3 ps-5 pe-5 me-3'>{lan}</div>
          ))}
          {props.data?.audienceDetails?.language.length === 0 && (
            <div>No data available</div>
          )}
        </div>
      </div>
    </div>
  )
}
