import { DatePicker, message } from 'antd'
import clsx from 'clsx'
import { useFormik } from 'formik'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import httpService from '../../setup/http/httpServices'
import { toAbsoluteUrl } from '../../_metronic/helpers'
import '../masters/basic.css'
import { TransactionLeftBreadcrum } from './TransactionLeftBreadcrum'

var initialValues = {
  chequeNumber: '',
  paymentMethod: 'cheque',
  dateOfIssue: '2022/1/12',
  bankName: '',
  ifscCode: '',
  branch: '',
  remarks: '',
  accountNumber: '',
  paymentMode: '',
  referenceNumber: '',
}

const registrationSchema = Yup.object().shape({
  // chequeNumber: Yup.string()
  //   .min(3, 'Minimum 3 symbols')
  //   .max(50, 'Maximum 50 symbols')
  //   .required('Cheque No is required'),
  // bankName: Yup.string()
  //   .min(3, 'Minimum 3 symbols')
  //   .max(50, 'Maximum 50 symbols')
  //   .required('Cheque No is required'),
  // ifscCode: Yup.string()
  //   .min(3, 'Minimum 3 symbols')
  //   .max(50, 'Maximum 50 symbols')
  //   .required('Cheque No is required'),
  // branch: Yup.string()
  //   .min(3, 'Minimum 3 symbols')
  //   .max(50, 'Maximum 50 symbols')
  //   .required('Cheque No is required'),
  // dateOfIssue: Yup.string().required('Date is required'),
  // remarks: Yup.string()
  //   .min(3, 'Minimum 3 characters')
  //   .max(500, 'Maximum 500 characters')
  //   .required('Remark is required'),
})

export function TransactionPayment() {
  const history: any = useHistory()
  var transactionInfoDetails = history.location.state?.transactionInfoDetails
  var transactionAmountDetails = history.location.state?.transactionAmountDetails
  const [loading, setLoading] = useState(false)
  const [transactionDetails, setTransactionDetails] = useState(initialValues)
  const [referenceId, setReferenceId] = useState(null)

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: registrationSchema,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      setTimeout(() => {
        setTransactionDetails(values)
        setLoading(false)
        setSubmitting(false)
        let request: any = {
          basicInformation: transactionInfoDetails,
          amountDetails: [],
          customAmountDetails: [],
          coupon: history.location.state.coupon,
          gstAmount: transactionAmountDetails.cgst + transactionAmountDetails.sgst,
          discount: history.location.state.discount,
        }
        transactionAmountDetails.priceMaster.forEach((x: any) => {
          if (x.qty > 0 && x._id) {
            request.amountDetails.push({
              influencerCategory: x._id,
              qty: x.qty,
            })
          } else if (x.qty > 0 && x.type === 'custom') {
            request.customAmountDetails.push({
              service: x.bucketName,
              qty: x.qty,
              amount: x.costPerInfluencer,
            })
          }
        })
        if (values.paymentMethod === 'cheque') {
          request['chequeDetails'] = {
            amount: transactionAmountDetails.total,
            chequeNumber: values.chequeNumber,
            dateOfIssue: values.dateOfIssue,
            bankName: values.bankName,
            ifscCode: values.ifscCode,
            branch: values.branch,
          }
        } else if (values.paymentMethod === 'bankTransfer') {
          request['bankTransferDetails'] = {
            amount: transactionAmountDetails.total,
            accountNumber: values.accountNumber,
            ifscCode: values.ifscCode,
            paymentMode: values.paymentMode,
            chequeNumber: values.chequeNumber,
            dateOfIssue: values.dateOfIssue,
            referenceNumber: values.referenceNumber,
            remarks: values.remarks,
          }
        } else if (values.paymentMethod === 'cash') {
          request['cashDetails'] = {
            amount: transactionAmountDetails.total,
            memoNumber: values.ifscCode,
            date: values.dateOfIssue,
            receiverName: values.bankName,
            remarks: values.remarks,
          }
        } else if (values.paymentMethod === 'razorpay') {
          request['razorpayDetails'] = {
            amount: transactionAmountDetails.total,
            paymentMode: 'razorpay',
            referenceNumber: referenceId,
            remarks: transactionInfoDetails.remark,
          }
        }
        if (history.location.state._id) {
          httpService.patchData(
            '/transaction/' + history.location.state._id,
            request,
            (response: any) => {
              message.success('Transaction Updated.')
              history.push({
                pathname: '/transaction/dashboard',
                state: {
                  type: 'transactionDashboard',
                },
              })
            },
            (error: any) => {
              message.error(error.message)
            }
          )
        } else
          httpService.postData(
            '/transaction',
            request,
            (response: any) => {
              if (values.paymentMethod === 'razorpay') {
                makePayment(response.data.data.transaction._id)
              } else {
                message.success('Transaction added.')
                history.push({
                  pathname: '/transaction/dashboard',
                  state: {
                    type: 'transactionDashboard',
                  },
                })
              }
            },
            (error: any) => {
              message.error(error?.message)
            }
          )
      }, 1000)
    },
  })

  useEffect(() => {
    try {
      const transactionDetail1 = history.location.state?.transactionDetails
      const transactionPaymentDetails = history.location.state?.transactionPaymentDetails
      if (transactionPaymentDetails) formik.setValues(transactionPaymentDetails)
      setTransactionDetails(transactionDetail1)
    } catch { }
  }, [history.location.state?.transactionDetails])

  const makePayment = (transactionId) => {
    httpService.postData(
      '/payment/order',
      {
        amount: Math.ceil(transactionAmountDetails.total * 100),
        currency: 'INR',
        transaction: transactionId,
      },
      (res: any) => {
        const orderData = res.data.data
        const options = {
          key: process.env.REACT_APP_RZR_KEY, // Enter the Key ID generated from the Dashboard
          amount: orderData.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
          currency: 'INR',
          name: 'Sehaye',
          description: 'Sehaye Payment',
          image: '/media/logos/Vavo-Logo.png',
          order_id: orderData.razorpay.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
          handler: function (response) {
            setReferenceId(response.razorpay_order_id)
            httpService.postData(
              '/payment/verify',
              {
                id: orderData._id,
                orderId: response.razorpay_order_id,
                paymentId: response.razorpay_payment_id,
                signature: response.razorpay_signature,
              },
              (response: any) => {
                message.success("Transaction Completed.")
                // formik.submitForm()
                history.push({
                  pathname: '/transaction/dashboard',
                  state: {
                    type: 'transactionDashboard'
                  },
                })
              },
              (error: any) => {
                message.success('Payment failed')
              }
            )
          },
          theme: {
            color: '#333366',
          },
        }
        const rzp1 = new window.Razorpay(options)
        rzp1.open()
      },
      (err: any) => { }
    )
  }

  return (
    <div className='CompanyDetailsOuter'>
      <TransactionLeftBreadcrum currentStep='3' type={history.location.state?.type} />
      <form
        className='CompanyDetails'
        noValidate
        id='kt_login_signup_form'
        onSubmit={formik.handleSubmit}
      >
        <h2 className='m-0'>Add Payment Details</h2>

        <div className='m24'>
          <p className='fs-6 required mb-0 fw-bold'>Select Payment Method</p>
          <div className='d-flex w-75 mt-2 form-check form-check-custom form-check-solid '>
            <label className='flex me-10 justify-content-start'>
              <input
                {...formik.getFieldProps('paymentMethod')}
                className={clsx(
                  'form-check-input selected w-20px h-20px',
                  {
                    'is-invalid': formik.touched.paymentMethod && formik.errors.paymentMethod,
                  },
                  {
                    'is-valid': formik.touched.paymentMethod && !formik.errors.paymentMethod,
                  }
                )}
                type='radio'
                name='paymentMethod'
                value='cheque'
                checked={formik.values?.paymentMethod === 'cheque'}
                id='flexCheckDefault'
              />
              <label className='fs-6 ms-2 w-50 mb-0'>Cheque</label>

              {formik.touched.paymentMethod && formik.errors.paymentMethod && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='text-danger' role='alert'>
                      {formik.errors.paymentMethod}
                    </span>
                  </div>
                </div>
              )}
            </label>
            <label className='w-auto flex me-10 justify-content-start'>
              <input
                {...formik.getFieldProps('paymentMethod')}
                className={clsx(
                  'form-check-input w-20px h-20px',
                  {
                    'is-invalid': formik.touched.paymentMethod && formik.errors.paymentMethod,
                  },
                  {
                    'is-valid': formik.touched.paymentMethod && !formik.errors.paymentMethod,
                  }
                )}
                type='radio'
                name='paymentMethod'
                value='bankTransfer'
                checked={formik.values?.paymentMethod === 'bankTransfer'}
                id='flexCheckDefault'
              />
              <label className='fs-6 ms-2 mb-0'>Bank Transfer</label>

              {formik.touched.paymentMethod && formik.errors.paymentMethod && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='text-danger' role='alert'>
                      {formik.errors.paymentMethod}
                    </span>
                  </div>
                </div>
              )}
            </label>
            <label className='flex me-10 justify-content-start'>
              <input
                {...formik.getFieldProps('paymentMethod')}
                className={clsx(
                  'form-check-input w-20px h-20px',
                  {
                    'is-invalid': formik.touched.paymentMethod && formik.errors.paymentMethod,
                  },
                  {
                    'is-valid': formik.touched.paymentMethod && !formik.errors.paymentMethod,
                  }
                )}
                type='radio'
                name='paymentMethod'
                value='cash'
                checked={formik.values?.paymentMethod === 'cash'}
                id='flexCheckDefault'
              />
              <label className='fs-6 ms-2 mb-0'>Cash Payments</label>

              {formik.touched.paymentMethod && formik.errors.paymentMethod && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='text-danger' role='alert'>
                      {formik.errors.paymentMethod}
                    </span>
                  </div>
                </div>
              )}
            </label>
            <label className='w-auto flex me-10 justify-content-start'>
              <input
                {...formik.getFieldProps('paymentMethod')}
                className={clsx(
                  'form-check-input w-20px h-20px',
                  {
                    'is-invalid': formik.touched.paymentMethod && formik.errors.paymentMethod,
                  },
                  {
                    'is-valid': formik.touched.paymentMethod && !formik.errors.paymentMethod,
                  }
                )}
                type='radio'
                name='paymentMethod'
                value='razorpay'
                checked={formik.values?.paymentMethod === 'razorpay'}
                id='flexCheckDefault'
              />
              <label className='fs-6 ms-2 mb-0'>Razorpay</label>

              {formik.touched.paymentMethod && formik.errors.paymentMethod && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='text-danger' role='alert'>
                      {formik.errors.paymentMethod}
                    </span>
                  </div>
                </div>
              )}
            </label>
          </div>
        </div>
        {formik.values.paymentMethod === 'cheque' && (
          <div>
            <div className='col m24'>
              <h6 className='fw-bold '>Enter Amount</h6>
              <input
                type='text'
                placeholder='Show calculated total here (pre filled input)'
                value={transactionAmountDetails.total}
                readOnly
                className='form-control form-control-solid bg-gray-100'
              />
            </div>
            <div className='m24 d-flex'>
              <div className='col me-5'>
                <h6 className='fw-bold required'>Cheque No.</h6>
                <input
                  type='text'
                  placeholder='Cheque No.'
                  {...formik.getFieldProps('chequeNumber')}
                  className={clsx(
                    'form-control form-control-solid bg-gray-100',
                    {
                      'is-invalid': formik.touched.chequeNumber && formik.errors.chequeNumber,
                    },
                    {
                      'is-valid': formik.touched.chequeNumber && !formik.errors.chequeNumber,
                    }
                  )}
                />
                {formik.touched.chequeNumber && formik.errors.chequeNumber && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span className='text-danger' role='alert'>
                        {formik.errors.chequeNumber}
                      </span>
                    </div>
                  </div>
                )}
              </div>
              <div className='col'>
                <h6 className='fw-bold required'>Date of Issue</h6>
                <DatePicker
                  {...formik}
                  name='dateOfIssue'
                  value={moment(formik.values['dateOfIssue'], 'YYYY/MM/DD')}
                  className={clsx(
                    'border-0 form-control form-control-solid w-100 bg-gray-100 border-radius',
                    {
                      'is-invalid': formik.touched.dateOfIssue && formik.errors.dateOfIssue,
                    },
                    {
                      'is-valid': formik.touched.dateOfIssue && !formik.errors.dateOfIssue,
                    }
                  )}
                  onChange={(dateOfIssue, dateString) =>
                    formik.setFieldValue('dateOfIssue', dateString)
                  }
                />

                {formik.touched.dateOfIssue && formik.errors.dateOfIssue && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span className='text-danger' role='alert'>
                        {formik.errors.dateOfIssue}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='d-flex m24'>
              <div className='col me-5'>
                <h6 className='fw-bold required'>Bank Name</h6>
                <input
                  type='text'
                  placeholder='Bank Name'
                  {...formik.getFieldProps('bankName')}
                  className={clsx(
                    'form-control form-control-solid bg-gray-100',
                    {
                      'is-invalid': formik.touched.bankName && formik.errors.bankName,
                    },
                    {
                      'is-valid': formik.touched.bankName && !formik.errors.bankName,
                    }
                  )}
                />
                {formik.touched.bankName && formik.errors.bankName && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span className='text-danger' role='alert'>
                        {formik.errors.bankName}
                      </span>
                    </div>
                  </div>
                )}
              </div>
              <div className='col me-5'>
                <h6 className='fw-bold required'>IFSC Code</h6>
                <input
                  type='text'
                  placeholder='IFSC Code'
                  {...formik.getFieldProps('ifscCode')}
                  className={clsx(
                    'form-control form-control-solid bg-gray-100',
                    {
                      'is-invalid': formik.touched.ifscCode && formik.errors.ifscCode,
                    },
                    {
                      'is-valid': formik.touched.ifscCode && !formik.errors.ifscCode,
                    }
                  )}
                />
                {formik.touched.ifscCode && formik.errors.ifscCode && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span className='text-danger' role='alert'>
                        {formik.errors.ifscCode}
                      </span>
                    </div>
                  </div>
                )}
              </div>
              <div className='col '>
                <h6 className='fw-bold required'>Branch</h6>
                <input
                  type='text'
                  placeholder='Branch'
                  {...formik.getFieldProps('branch')}
                  className={clsx(
                    'form-control form-control-solid bg-gray-100',
                    {
                      'is-invalid': formik.touched.branch && formik.errors.branch,
                    },
                    {
                      'is-valid': formik.touched.branch && !formik.errors.branch,
                    }
                  )}
                />
                {formik.touched.branch && formik.errors.branch && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span className='text-danger' role='alert'>
                        {formik.errors.branch}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='m24'>
              <h6 className='fw-bold required'>Remark</h6>
              <div className='col '>
                <textarea
                  placeholder='Remark'
                  {...formik.getFieldProps('remarks')}
                  className={clsx(
                    'form-control form-control-solid bg-gray-100',
                    {
                      'is-invalid': formik.touched.remarks && formik.errors.remarks,
                    },
                    {
                      'is-valid': formik.touched.remarks && !formik.errors.remarks,
                    }
                  )}
                />
                {formik.touched.remarks && formik.errors.remarks && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span className='text-danger' role='alert'>
                        {formik.errors.remarks}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        {formik.values.paymentMethod === 'bankTransfer' && (
          <div>
            <div className='col m24'>
              <h6 className='fw-bold '>Enter Amount</h6>
              <input
                type='text'
                placeholder='Show calculated total here (pre filled input)'
                value={transactionAmountDetails.total}
                readOnly
                className='form-control form-control-solid bg-gray-100'
              />
            </div>
            <div className='m24 d-flex'>
              <div className='col me-5'>
                <h6 className='fw-bold required'>Account No.</h6>
                <input
                  type='text'
                  placeholder='Account No.'
                  {...formik.getFieldProps('accountNumber')}
                  className={clsx(
                    'form-control form-control-solid bg-gray-100',
                    {
                      'is-invalid': formik.touched.accountNumber && formik.errors.accountNumber,
                    },
                    {
                      'is-valid': formik.touched.accountNumber && !formik.errors.accountNumber,
                    }
                  )}
                />
                {formik.touched.accountNumber && formik.errors.accountNumber && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span className='text-danger' role='alert'>
                        {formik.errors.accountNumber}
                      </span>
                    </div>
                  </div>
                )}
              </div>
              <div className='col'>
                <h6 className='fw-bold required'>Cheque No.</h6>
                <input
                  type='text'
                  placeholder='Cheque No.'
                  {...formik.getFieldProps('chequeNumber')}
                  className={clsx(
                    'form-control form-control-solid bg-gray-100',
                    {
                      'is-invalid': formik.touched.chequeNumber && formik.errors.chequeNumber,
                    },
                    {
                      'is-valid': formik.touched.chequeNumber && !formik.errors.chequeNumber,
                    }
                  )}
                />
                {formik.touched.chequeNumber && formik.errors.chequeNumber && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span className='text-danger' role='alert'>
                        {formik.errors.chequeNumber}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='m24 d-flex'>
              <div className='col me-5'>
                <h6 className='fw-bold required'>IFSC Code</h6>
                <input
                  type='text'
                  placeholder='IFSC Code'
                  {...formik.getFieldProps('ifscCode')}
                  className={clsx(
                    'form-control form-control-solid bg-gray-100',
                    {
                      'is-invalid': formik.touched.ifscCode && formik.errors.ifscCode,
                    },
                    {
                      'is-valid': formik.touched.ifscCode && !formik.errors.ifscCode,
                    }
                  )}
                />
                {formik.touched.ifscCode && formik.errors.ifscCode && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span className='text-danger' role='alert'>
                        {formik.errors.ifscCode}
                      </span>
                    </div>
                  </div>
                )}
              </div>
              <div className='col'>
                <h6 className='fw-bold required'>Payment Mode</h6>
                <select
                  placeholder='Payment Mode'
                  {...formik.getFieldProps('paymentMode')}
                  className={clsx(
                    'form-control form-control-solid bg-gray-100',
                    {
                      'is-invalid': formik.touched.paymentMode && formik.errors.paymentMode,
                    },
                    {
                      'is-valid': formik.touched.paymentMode && !formik.errors.paymentMode,
                    }
                  )}
                >
                  <option value='netbanking'>Internet Banking</option>
                  <option value='upi'>UPI</option>
                  <option value='imps'>IMPS</option>
                  <option value='neft'>NEFT</option>
                  <option value='rtgs'>RTGS</option>
                </select>
                {formik.touched.paymentMode && formik.errors.paymentMode && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span className='text-danger' role='alert'>
                        {formik.errors.paymentMode}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='col m16'>
              <h6 className='fw-bold required'>Date of Issue</h6>
              <DatePicker
                {...formik}
                name='dateOfIssue'
                value={moment(formik.values['dateOfIssue'], 'YYYY/MM/DD')}
                className={clsx(
                  'border-0 form-control form-control-solid w-100 bg-gray-100 border-radius',
                  {
                    'is-invalid': formik.touched.dateOfIssue && formik.errors.dateOfIssue,
                  },
                  {
                    'is-valid': formik.touched.dateOfIssue && !formik.errors.dateOfIssue,
                  }
                )}
                onChange={(dateOfIssue, dateString) =>
                  formik.setFieldValue('dateOfIssue', dateString)
                }
              />

              {formik.touched.dateOfIssue && formik.errors.dateOfIssue && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='text-danger' role='alert'>
                      {formik.errors.dateOfIssue}
                    </span>
                  </div>
                </div>
              )}
            </div>
            <div className='col m16'>
              <h6 className='fw-bold required'>Reference Number</h6>
              <input
                type='text'
                placeholder='Reference Number'
                {...formik.getFieldProps('referenceNumber')}
                className={clsx(
                  'form-control form-control-solid bg-gray-100',
                  {
                    'is-invalid': formik.touched.referenceNumber && formik.errors.referenceNumber,
                  },
                  {
                    'is-valid': formik.touched.referenceNumber && !formik.errors.referenceNumber,
                  }
                )}
              />
              {formik.touched.referenceNumber && formik.errors.referenceNumber && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='text-danger' role='alert'>
                      {formik.errors.referenceNumber}
                    </span>
                  </div>
                </div>
              )}
            </div>
            <div className='m16'>
              <h6 className='fw-bold required'>Remarks</h6>
              <div className='col '>
                <textarea
                  placeholder='Remarks'
                  {...formik.getFieldProps('remarks')}
                  className={clsx(
                    'form-control form-control-solid bg-gray-100',
                    {
                      'is-invalid': formik.touched.remarks && formik.errors.remarks,
                    },
                    {
                      'is-valid': formik.touched.remarks && !formik.errors.remarks,
                    }
                  )}
                />
                {formik.touched.remarks && formik.errors.remarks && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span className='text-danger' role='alert'>
                        {formik.errors.remarks}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        {formik.values.paymentMethod === 'cash' && (
          <div>
            <div className='d-flex m24'>
              <div className='col me-5'>
                <h6 className='fw-bold '>Enter Amount</h6>
                <input
                  type='text'
                  placeholder='Show calculated total here (pre filled input)'
                  value={transactionAmountDetails.total}
                  readOnly
                  className='form-control form-control-solid bg-gray-100'
                />
              </div>
              <div className='col'>
                <h6 className='fw-bold required'>Memo No.</h6>
                <input
                  type='text'
                  placeholder='Memo No.'
                  {...formik.getFieldProps('ifscCode')}
                  className={clsx(
                    'form-control form-control-solid bg-gray-100',
                    {
                      'is-invalid': formik.touched.ifscCode && formik.errors.ifscCode,
                    },
                    {
                      'is-valid': formik.touched.ifscCode && !formik.errors.ifscCode,
                    }
                  )}
                />
                {formik.touched.ifscCode && formik.errors.ifscCode && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span className='text-danger' role='alert'>
                        {formik.errors.ifscCode}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='d-flex m16'>
              <div className='col me-5'>
                <h6 className='fw-bold required'>Receiver Name</h6>
                <input
                  type='text'
                  placeholder='Receiver Name'
                  {...formik.getFieldProps('bankName')}
                  className={clsx(
                    'form-control form-control-solid bg-gray-100',
                    {
                      'is-invalid': formik.touched.bankName && formik.errors.bankName,
                    },
                    {
                      'is-valid': formik.touched.bankName && !formik.errors.bankName,
                    }
                  )}
                />
                {formik.touched.bankName && formik.errors.bankName && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span className='text-danger' role='alert'>
                        {formik.errors.bankName}
                      </span>
                    </div>
                  </div>
                )}
              </div>
              <div className='col'>
                <h6 className='fw-bold required'>Date</h6>
                <DatePicker
                  {...formik}
                  name='dateOfIssue'
                  value={moment(formik.values['dateOfIssue'], 'YYYY/MM/DD')}
                  className={clsx(
                    'border-0 form-control form-control-solid w-100 bg-gray-100 border-radius',
                    {
                      'is-invalid': formik.touched.dateOfIssue && formik.errors.dateOfIssue,
                    },
                    {
                      'is-valid': formik.touched.dateOfIssue && !formik.errors.dateOfIssue,
                    }
                  )}
                  onChange={(dateOfIssue, dateString) =>
                    formik.setFieldValue('dateOfIssue', dateString)
                  }
                />

                {formik.touched.dateOfIssue && formik.errors.dateOfIssue && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span className='text-danger' role='alert'>
                        {formik.errors.dateOfIssue}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='m16'>
              <h6 className='fw-bold required'>Remarks</h6>
              <div className='col '>
                <textarea
                  placeholder='Remarks'
                  {...formik.getFieldProps('remarks')}
                  className={clsx(
                    'form-control form-control-solid bg-gray-100',
                    {
                      'is-invalid': formik.touched.remarks && formik.errors.remarks,
                    },
                    {
                      'is-valid': formik.touched.remarks && !formik.errors.remarks,
                    }
                  )}
                />
                {formik.touched.remarks && formik.errors.remarks && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span className='text-danger' role='alert'>
                        {formik.errors.remarks}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        {formik.values.paymentMethod === 'razorpay' ? (
          <div className='w-100 flex'>
            <div className='flex flex-column m24' style={{ height: '333px', width: '444px' }}>
              <img src={toAbsoluteUrl('/media/icons/Custom/credit-card.png')} alt='' />
              <div className='w-100 flex m20'>
                <button className='btn create-btn col'>Add Payment</button>
              </div>
            </div>
          </div>
        ) : (
          <div className='d-flex m24'>
            <button
              type='button'
              className='btn fw-bolder btn-gray-200 CompanyDetailButton'
              onClick={() => {
                history.push({
                  pathname: '/transaction/amount',
                  state: {
                    transactionInfoDetails: history.location.state?.transactionInfoDetails,
                    transactionAmountDetails: history.location.state?.transactionAmountDetails,
                    transactionPaymentDetails: formik.values,
                  },
                })
              }}
            >
              Back
            </button>
            <button
              type='submit'
              id='kt_sign_up_submit'
              className='btn create-btn ms-5 CompanyDetailButton'
            // disabled={formik.isSubmitting || !formik.isValid}
            >
              {!loading && <span className='indicator-label'>Continue</span>}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        )}
      </form>
    </div>
  )
}
