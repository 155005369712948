import React from 'react'

export function OtherDetailById(props: any) {
  return (
    <div>{props.data?.otherDetails ? (<div>{Object.keys(props.data?.otherDetails).map((keyName, i) => (
      <div className='basicDiv' key={i}>
        <h5>{keyName === "do" ? "Do's" : keyName === "dont" ? "Don’t" : keyName === "postProduction" ? "Post-production" : "Platform Posts"}</h5>
        <div className='m16'>
          <span className='nav-item'><div dangerouslySetInnerHTML={{ __html: props.data?.otherDetails[keyName] }} /></span>
          {!props.data?.otherDetails[keyName] && (<span>No data available</span>)}
        </div>
      </div>))}
      </div>):(
        <>
         <div className='basicDiv'>
          <h5>Do's</h5>
          <div className='m16'>
            <span>No data available</span>
          </div>
         </div>
         <div className='basicDiv'>
          <h5>Don'ts</h5>
          <div className='m16'>
            <span>No data available</span>
          </div>
         </div>
         <div className='basicDiv'>
         <h5>Post-Production</h5>
         <div className='m16'>
           <span>No data available</span>
         </div>
        </div>
        <div className='basicDiv'>
          <h5>Platform Posts</h5>
          <div className='m16'>
          <span>No data available</span>
          </div>
        </div>
       </>
      )}
    </div>
  )
}
