import {message} from 'antd'
import {useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../_metronic/helpers'
import httpService from '../../setup/http/httpServices'

export function CampaginById(props: any) {
  var count = 0
  if (props.data?.instagramInfluencerDetails?.mediaCount) {
    Object.keys(props.data?.instagramInfluencerDetails?.mediaCount).map(
      (keyName, i) => (count = count + props.data?.instagramInfluencerDetails?.mediaCount[keyName])
    )
  }
  var count1 = 0
  if (props.data?.instagramInfluencerDetails?.influencerCount) {
    Object.keys(props.data?.instagramInfluencerDetails?.influencerCount).map(
      (keyName, i) => (keyName != "flexible") ? (count1 = count1 + props.data?.instagramInfluencerDetails?.influencerCount[keyName]) : (count1 = props.data?.campaignDetails?.noOfInfluencers)
    )
  }

  const [priceMaster, setPriceMaster] = useState<any>([])

  const Demo = async () => {
    await httpService.getData(
      '/price-master',
      (response: any) => {
        const result = response?.data?.data?.priceMasters.filter(
          (master) =>
            master?.socialMedia?.socialMediaName?.toLowerCase() ===
            props.data?.campaignDetails?.platform.toLowerCase()
        )
        setPriceMaster(result)
      },
      (error: any) => {
        message.error(error.message)
      }
    )
  }

  useEffect(() => {
    Demo()
  }, [])

  return (
    <div className=''>
      <div className='d-flex w-100'>
        <div className='col'>
          <div className='basicDiv rounded-xl rounded-bottom-0 pb-5 mb-0'>
            <div className='d-flex'>
              <h5 className='col'>Content Requirement</h5>
              <p className='col d-flex justify-content-end m-0'>{count}</p>
            </div>
          </div>
          <div className='p-3 ps-7 pe-7 text-gray-400 w-100 bg-gray-200 d-flex'>
            <div className='col'>Type</div>
            <div className='col d-flex justify-content-end'>Counts</div>
          </div>
          <div className='basicDiv rounded-xl rounded-top-0 pt-5 mt-0'>
            {props.data?.instagramInfluencerDetails?.mediaCount?.image > 0 && (
              <Content
                label='Image'
                count={props.data?.instagramInfluencerDetails?.mediaCount.image}
              />
            )}
            {props.data?.instagramInfluencerDetails?.mediaCount?.reel30s > 0 && (
              <Content
                label='Reels: 30 Sec'
                count={props.data?.instagramInfluencerDetails?.mediaCount?.reel30s}
              />
            )}
            {props.data?.instagramInfluencerDetails?.mediaCount?.reel60s > 0 && (
              <Content
                label='Reels: 60 Sec'
                count={props.data?.instagramInfluencerDetails?.mediaCount?.reel60s}
              />
            )}
            {props.data?.instagramInfluencerDetails?.mediaCount?.videoStory > 0 && (
              <Content
                label='Video Story'
                count={props.data?.instagramInfluencerDetails?.mediaCount?.videoStory}
              />
            )}
            {props.data?.instagramInfluencerDetails?.mediaCount?.video > 0 && (
              <Content
                label='Video'
                count={props.data?.instagramInfluencerDetails?.mediaCount.video}
              />
            )}
            {props.data?.instagramInfluencerDetails?.mediaCount?.staticStory > 0 && (
              <Content
                label='Static Story'
                count={props.data?.instagramInfluencerDetails?.mediaCount.staticStory}
              />
            )}
            {props.data?.instagramInfluencerDetails?.mediaCount?.carousel > 0 && (
              <Content
                label='Carousel'
                count={props.data?.instagramInfluencerDetails?.mediaCount.carousel}
              />
            )}
            {!props.data?.instagramInfluencerDetails?.mediaCount && <span>No data available</span>}
          </div>
        </div>
        <div className='col ms-7'>
          <div className='basicDiv rounded-xl rounded-bottom-0 pb-5 mb-0'>
            <div className='d-flex'>
              <h5 className='col'>Influencers</h5>
              <p className='col d-flex justify-content-end m-0'>{count1}</p>
            </div>
          </div>
          <div className='p-3 ps-7 pe-7 text-gray-400 w-100 bg-gray-200 d-flex'>
            <div className='col'>Type</div>
            <div className='col d-flex justify-content-end'>Counts</div>
          </div>
          <div className='basicDiv rounded-xl rounded-top-0 pt-5 mt-0'>
            {priceMaster &&
              priceMaster?.map((x: any) => (
                <>
                  {props?.data?.[
                    props?.data?.campaignDetails?.platform?.toLowerCase() + 'InfluencerDetails'
                  ]?.influencerCount?.[x?.bucketName] && (
                    <Type
                      type={x?.bucketName}
                      count={
                        props?.data?.[
                          props.data?.campaignDetails?.platform?.toLowerCase() + 'InfluencerDetails'
                        ]?.influencerCount?.[x?.bucketName]
                      }
                    />
                  )}
                </>
              ))}
            {!props.data?.instagramInfluencerDetails?.influencerCount && (
              <span>No data available</span>
            )}
          </div>
        </div>
      </div>
      <div className='d-flex w-100'>
        <div className='basicDiv col'>
          <h5>Gender</h5>
          <div className='m16 d-flex flex-column'>
            {props.data?.instagramInfluencerDetails?.primaryGender && (
              <div className='bg-gray-100 br-50 fw-bolder p-3 ps-5 pe-5 me-3'>
                <KTSVG
                  className='svg-icon-4 w-15px h-15px svg-icon-dark me-2'
                  path={
                    props.data?.instagramInfluencerDetails?.gender == 'Male'
                      ? toAbsoluteUrl('/media/icons/Custom/male.svg')
                      : toAbsoluteUrl('/media/icons/Custom/female.svg')
                  }
                ></KTSVG>
                {props.data?.instagramInfluencerDetails?.primaryGender}
              </div>
            )}
            {!props.data?.instagramInfluencerDetails?.primaryGender && (
              <span>No data available</span>
            )}
          </div>
        </div>
        <div className='basicDiv ms-7 col'>
          <h5>Age Group</h5>
          <div className='m16 d-flex flex-column'>
            {props.data?.instagramInfluencerDetails?.ageGroup.map((age: any) => (
              <div className='bg-gray-100 br-50 p-3 ps-5 pe-5 mt-3 '>
                <li className='nav-item'>{age}</li>
              </div>
            ))}
            {props.data?.instagramInfluencerDetails?.ageGroup?.length === 0 && (
              <span>No data available</span>
            )}
          </div>
        </div>
      </div>
      <div className='basicDiv m20 col'>
        <h5>Location</h5>
        <div className='m16 d-flex'>
          {props.data?.instagramInfluencerDetails?.location?.split(',').map((locationName: any) => (
            <div className='bg-gray-100 br-50 p-3 ps-5 pe-5 me-3'>{locationName}</div>
          ))}
          {!props.data?.instagramInfluencerDetails?.location && <span>No data available</span>}
        </div>
      </div>
    </div>
  )
}
export function Type(props: any) {
  return (
    <div className='d-flex w-100'>
      <div className='col-10'>{props.type}</div>
      <div className='col d-flex justify-content-end'>{props.count}</div>
    </div>
  )
}
export function Content(props: any) {
  return (
    <div className='d-flex w-100'>
      <div className='col-10'>
        <KTSVG
          className='svg-icon-4 w-15px h-15px me-2'
          path={
            props.label == 'IGTV'
              ? toAbsoluteUrl('/media/icons/Custom/igtv.svg')
              : props.label == 'Reels: 30 Sec'
              ? toAbsoluteUrl('/media/icons/Custom/reel.svg')
              : props.label == 'Reels: 60 Sec'
              ? toAbsoluteUrl('/media/icons/Custom/reel.svg')
              : props.label == 'Story'
              ? toAbsoluteUrl('/media/icons/Custom/clock.svg')
              : props.label == 'Picture'
              ? toAbsoluteUrl('/media/icons/Custom/image1.svg')
              : toAbsoluteUrl('/media/icons/Custom/youtubeBw.svg')
          }
        ></KTSVG>
        {props.label}
      </div>
      <div className='col d-flex justify-content-end'>{props.count}</div>
    </div>
  )
}
