import React, {useState} from 'react'
import {Link} from 'react-router-dom'

function Brand_Step4() {
  const [long, setLong] = useState(false)
  const [one, setOne] = useState(false)
  return (
    <div style={{width: '546px'}}>
      <div style={{fontSize: '36px', fontWeight: 700}}>Step 4 of 4</div>
      <div className='fs-14 m16'>Looking for what kind of collaboration?</div>
      <div className='d-flex w-100 m16'>
        <div
          className={
            long
              ? 'col rounded flex fs-16 dark-bg text-white pointer'
              : 'col rounded flex fs-16 light-bg pointer'
          }
          style={{height: '72px', marginRight: '16px'}}
          onClick={() => {
            setLong(true)
            setOne(false)
          }}
        >
          <span>Long-Term</span>
        </div>
        <div
          className={
            one
              ? 'col rounded flex fs-16 dark-bg text-white pointer'
              : 'col rounded flex fs-16 light-bg pointer'
          }
          style={{height: '72px'}}
          onClick={() => {
            setLong(false)
            setOne(true)
          }}
        >
          <span>One time</span>
        </div>
      </div>
      <div className='d-flex flex-column m16'>
        <Link to='/auth/register/createAccount'>
          <button
            className='w-100 btn btn-primary rounded'
            style={{fontSize: '14px', fontWeight: 600}}
            disabled={!long && !one}
          >
            Proceed
          </button>
        </Link>
        <Link to='/auth/register/step3'>
          <button className='w-100 btn rounded m8' style={{fontSize: '14px', fontWeight: 600}}>
            Go back
          </button>
        </Link>
      </div>
    </div>
  )
}

export default Brand_Step4
