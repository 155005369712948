import instra from "./images/svg/Instagram.svg"
import youtube from "./images/svg/youtube.svg"
import google from "./images/svg/google.svg"
import mcd from "./images/svg/mcd.svg"
import fb from "./images/svg/fb.svg"
import mamaEarth from "./images/svg/mamaEarth.svg"
import netflix from "./images/svg/netflix.svg"
import graph from "./images/svg/graphIcon.svg"
import campaign1 from "./images/svg/campaignIcon1.svg"
import campaign2 from "./images/svg/campaignIcon2.svg"
import influencerIcon from "./images/svg/influencerIcon.svg"



import p1 from "./images/Dp/p1.jpg"
import p2 from "./images/Dp/p2.jpg"
import p3 from "./images/Dp/p3.jpg"
import p4 from "./images/Dp/p4.jpg"
import p5 from "./images/Dp/p5.jpg"
import p6 from "./images/Dp/p6.jpg"
import p7 from "./images/Dp/p7.jpg"
import p8 from "./images/Dp/p8.jpg"
import p9 from "./images/Dp/p9.jpg"
import p10 from "./images/Dp/p10.jpg"
import p11 from "./images/Dp/p11.jpg"
import p12 from "./images/Dp/p12.jpg"
import p13 from "./images/Dp/p13.jpg"



export const CampaignData = [
    {
        id: 1,
        icon: mamaEarth,
        iconName: "Mamaearth",
        campaignText: "Beautiful InDeed!",
        reach: "4.4M",
        Impressions:"2.28M",
        EngRate: "52%",
        Influencers: "240",
        platform:""
    },
    {
        id: 2,
        icon: netflix,
        iconName: "Metflix India",
        campaignText: "Toh Baat Pakki?",
        reach: "4.4M",
        Impressions:"2.28M",
        EngRate: "52%",
        Influencers: "240",
        platform:""
    },
    {
        id: 3,
        icon: google,
        iconName: "Google India",
        campaignText: "Make Google Do It.",
        reach: "4.4M",
        Impressions:"2.28M",
        EngRate: "52%",
        Influencers: "240",
        platform:""
    },
    {
        id: 4,
        icon: mcd,
        iconName: "Mcdonald",
        campaignText: "Crafted for your craving.",
        reach: "4.4M",
        Impressions:"2.28M",
        EngRate: "52%",
        Influencers: "240",
        platform:""
    },
    {
        id: 5,
        icon: fb,
        iconName: "Facebook",
        campaignText: "Dil kholke dekho duniya  tumhare...",
        reach: "4.4M",
        Impressions:"2.28M",
        EngRate: "52%",
        Influencers: "240",
        platform:""
    },
];



export const  influencer = [
    {
        id: 1,
        dp: p1,
        name: "Sneha Sethi ",
        platform: instra,
        follower: "8.2M",
        subscriber: "3.7M",
        endRate: "68%",
    },
    {
        id: 2,
        dp: p2,
        name: "Dhanush Mishra ",
        platform: youtube,
        follower: "8.2M",
        subscriber: "3.7M",
        endRate: "68%",
    }, {
        id: 3,
        dp: p3,
        name: "Mitesh Kulkarni  ",
        platform: instra,
        follower: "8.2M",
        subscriber: "3.7M",
        endRate: "68%",
    }, {
        id: 4,
        dp: p4,
        name: "Abhinav Mukherjee  ",
        platform: youtube,
        follower: "8.2M",
        subscriber: "3.7M",
        endRate: "68%",
    }, {
        id: 5,
        dp: p5,
        name: "Aniruddh Anand  ",
        platform: instra,
        follower: "8.2M",
        subscriber: "3.7M",
        endRate: "68%",
    },
];

export const cardData = [
    
    {
        id: 1,
        title: "Total Campaigns",
        subTitle:"Total campaigns created this month.",
        value: "24",
        info: "24% more vs last month",
        icon: campaign1,
        graph: graph,
    },
    {
        id: 2,
        title: "Live Campaigns",
        subTitle:"Campaigns that are live.",
        value: "16",
        info: "5% more vs last month",
        icon: campaign2,
        graph: graph,
    }, 
    {
        id: 3,
        title: "Influencers",
        subTitle:"Worked with",
        value: "1274",
        info: "20% more vs last month",
        icon: influencerIcon,
        graph: graph,
    },
     {
        id: 4,
        title: "Instagram",
        subTitle:"Total Instagram Influencers hired",
        value: "821",
        info: "24% more vs last month",
        icon: instra,
        graph: graph,
    },
    {
        id: 5,
        title: "YouTube",
        subTitle:"Total YouTube Influencers hired",
        value: "453",
        info: "12% more vs last month",
        icon: youtube,
        graph: graph,
    },
]