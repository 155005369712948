/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { IUserModel } from '../../../../app/modules/auth/models/UserModel'
import * as auth from "../../../../app/modules/auth/redux/AuthRedux"
import { RootState } from '../../../../setup'

const HeaderUserMenu: FC = () => {
  const user: IUserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as IUserModel

  const dispatch = useDispatch()
  const logout = () => {
    dispatch(auth.actions.logout())
  }

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-5'>
        <Link to={{
          pathname: '/adminSettings/profileManagerSetting',
          state: { id: user.userId, type: 'Profile Details', user: 'self' },
        }}
          className='menu-link text-hover-white px-5'>
          My Profile
        </Link>
      </div>
      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link text-hover-white px-5'>
          Sign Out
        </a>
      </div>
    </div>
  )
}

export { HeaderUserMenu }

