import { SearchOutlined } from '@ant-design/icons'
import { Input, message, Pagination } from 'antd'
import { useFormik } from 'formik'
import { useEffect, useRef, useState } from 'react'
import { Modal } from 'react-bootstrap-v5'
import { Link, useHistory } from 'react-router-dom'
import Select from 'react-select'
import * as Yup from 'yup'
import httpService from '../../setup/http/httpServices'
import { KTSVG, toAbsoluteUrl } from '../../_metronic/helpers'
import loc from '../data/Location.json'
import { ProfileAvatar } from '../modules/widgets/components/ProfileAvatar'
import InfluencerForm from './InfluencerForm'
import { influencer } from '../Dashboard/Components/agencyDashboard/DummyData'
import * as XLSX from 'xlsx'

export function Shipping(props: any) {
  const history: any = useHistory()
  const [show1, setShow1] = useState(false)
  const [show2, setShow2] = useState(false)
  const [showR, setShowR] = useState(false)
  const [show, setShow] = useState(false)
  const [delivery, setDelivery] = useState(
    history.location.state.campaignDetails.shippingMethod === 'delivery'
  )
  const [self, setSelf] = useState(
    history.location.state.campaignDetails.shippingMethod === 'selfCheckout'
  )
  const [influencerList, setinfluencerList] = useState([])
  const [totalCount, setTotalCount] = useState(null)
  const [selectedInfluencer, setSelectedInfluencer] = useState(null)
  const [s, setS] = useState('Andaman and Nicobar Islands')
  const [stateList, setStateList] = useState(['Rajasthan'])
  const [cityList, setCityList] = useState([])
  const [showOrderingInfo, setShowOrderingInfo] = useState(false);
  const [showOrderingInfoModal, setShowOrderingInfoModal] = useState(false);
  const [modeOfBarter, setModeOfBarter] = useState('online'); // Default value is 'online'
  const [updateDetailsModal, setUpdateDetailsModal] = useState(null)
  const [payloadSheetData, setPayloadSheetData] = useState(null)

  const hiddenFileInput = useRef(null)
  var fileUploaded = null
  const [sheetDocument, setSheetDocument] = useState(history.location.state.sheetDocument)

  const handleChange = (event) => {
    if (event.target.files.length === 0) return
    fileUploaded = event.target.files[0]
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;

    reader.onload = (e) => {
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', bookVBA : true });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_json(ws);
      console.log("SHEET DATA VALUE -- ", data, "<<<")
      setSheetDocument(data)
    };

    if (rABS) {
      reader.readAsBinaryString(fileUploaded);
    } else {
      reader.readAsArrayBuffer(fileUploaded);
    };

  }


  const handleFilebulkUploadOnline = () => {

    const campaignId = "from send csv download offline trigger"
    const bulkUploadPayload = {"sheetData": sheetDocument, "influencerList":influencerList}

    if (sheetDocument) {
      httpService.postData(
        '/influencer-onboarding/shipping/bulk-upload-online/' + `${campaignId}`,
        bulkUploadPayload,
        (response: any) => {
          if (response?.status == 200 && response?.data?.data?.msg ) {
            message.success(response.data.data.msg)
          }
          getAllInfluencer()
        },
        (error: any) => {
          if (error?.status == 202 && error?.data?.msg) {
            message.error(error.data.msg)
          } else {
            message.error('Unable to Bulk Upload for Online mode')
          }
          // console.log("Error Message - ", error?.data, "with status code", error?.status, "Errorval", error)
          // message.error('Unable to Bulk Upload for Online mode')
        }
      )
    } else {
      console.log("File value null")
      message.error("No file Chosen")
    }
  }

  const handleFilebulkUploadOffline =() => {

    const campaignId = "from send csv download online trigger"
    const bulkUploadPayload = {"sheetData": sheetDocument, "influencerList":influencerList}

    if (sheetDocument) {
      httpService.postData(
        '/influencer-onboarding/shipping/bulk-upload-offline/' + `${campaignId}`,
        bulkUploadPayload,
        (response: any) => {
          console.log("Response on filebulkuploadoffline", response.data)
          if (response?.status == 200 && response?.data?.data?.msg ) {
            message.success(response.data.data.msg)
          }
          getAllInfluencer()
        },
        (error: any) => {
          if (error?.status == 202 && error?.data?.msg) {
            message.error(error.data.msg)
          } else {
            message.error('Unable to Bulk Upload for Offline mode')
          }
          // console.log("Error Message - ", error?.data, "with status code", error?.status)
          // message.error('Unable to Bulk Upload for Online mode')
        }
      )
    } else {
      console.log("File value none")
      message.error("No file Chosen")
    }
  }


  const getStates = () => {
    var states: any = []
    loc.map((l) => {
      var p = {
        value: l.State,
        label: l.State,
      }
      states.push(p)
    })
    setStateList(states)
    setS('Andaman and Nicobar Islands')
  }

  const getCities = () => {
    var c: any = []
    var ci: any = []
    loc.map((l) => {
      if (l.State === s) {
        c = l.Cities
        c.map((ct: any) => {
          var p = {
            value: ct,
            label: ct,
          }
          ci.push(p)
        })
      }
    })
    setCityList(ci)
  }

  useEffect(() => {
    getCities()
  }, [s])
  const handleClose = () => {
    setShow(false)
    setShow1(false)
    setShow2(false)
  }
  useEffect(() => {
    let interval
    getAllInfluencer()
    interval = setInterval(() => {
      getAllInfluencer()
    }, 10000)
    getStates()
    getCities()
    return () => {
      clearInterval(interval)
    }
  }, [])
  const [addressList, setAddressList] = useState([])
  const getInfluencerDetails = (influencerId) => {
    httpService.getData(
      '/influencer-master/' + influencerId,
      (response) => {
        setAddressList(response.data.data.influencer?.basicInfo?.addressDetails)
      },
      (error) => { }
    )
  }
  const [enableSubmissionButton, setSubmissionButton] = useState(false)

  const bulkDownloadOnline = () => {
    // console.log("BULK DOWNLOAD ONLINE INFLUENCERLIST --- ", influencerList, "<<<<<<<")
    const campaignId = "export_online"; 
    httpService.postDataExcel(
      '/influencer-onboarding/shipping/bulk-download-online/' + `${campaignId}`,
      influencerList,
      campaignId + ".xlsx",
      (response: any) => {
      },
      (error: any) => {
        message.error('Unable to download data for online mode')
      }
      )
  }

  const bulkDownloadOffline = () => {
    // console.log("BULK DOWN OFFLINE --- ", influencerList, "<<<<<<<")
    const campaignId = "export_offline"; 
    httpService.postDataExcel(
      '/influencer-onboarding/shipping/bulk-download-offline/' + `${campaignId}`,
      influencerList,
      campaignId + ".xlsx",
      (response: any) => {
        console.log("Full response in Onboarding --- -- ", response);
      },
      (error: any) => {
        message.error('Unable to Bulk Download for Offline mode')
      }
    )
  }

  const SheetJSFT = ["xlsx", "xlsb", "xlsm", "xls", "xml", "csv", "txt", "ods", "fods", "uos", "sylk", "dif", "dbf", "prn", "qpw", "123", "wb*", "wq*", "html", "htm"].map(function(x) { return "." + x; }).join(",");

  const getAllInfluencer = () => {
    httpService.getData(
      '/influencer-onboarding/' + `${props.campaignId}`,
      (response: any) => {
        let data = response.data.data
        // setAllData(data.influencers)
        setinfluencerList(data.influencers.shortListed)
        setTotalCount(data.counts ? data.counts : null)
        setSubmissionButton(
          data.influencers.shortListed.findIndex((x) => x.oboardingStatus.shipped) > -1
        )
        if (data.influencers.shortListed[0].shipping?.selfCheckout) {
          checkoutFormik.setValues(data.influencers.shortListed[0].shipping?.selfCheckout)
        }
      },
      (error: any) => {
        message.error(error.message)
      }
    )
  }

  const checkoutSchema = Yup.object().shape({
    productLink: Yup.string().required('Product Link is required'),
    promoCode: Yup.string().required('Promo Code is required'),
  })
  const checkoutFormik = useFormik({
    initialValues: {
      productLink: '',
      promoCode: '',
    },
    validationSchema: checkoutSchema,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      influencerList.forEach((x) => {
        httpService.postData(
          '/influencer-onboarding/shipping/self-checkout/' + `${x._id}`,
          values,
          (response: any) => {
            message.success('Shipping Details updated successfully')
            setShow1(false)
            getAllInfluencer()
          },
          (error: any) => {
            message.error('Shipping Details failed to update')
          }
        )
      })
    },
  })

  const trackingSchema = Yup.object().shape({
    vendor: Yup.string()
      .required('Vendor is required')
      .matches(/^(?=.*[A-Za-z]).{3,50}$/, 'Should not contain just numbers'),
    trackingId: Yup.string().required('Tracking ID is required'),
    trackingLink: Yup.string()
      .matches(
        /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
        'Wrong format of URL'
      )
      .min(3, 'Minimum 3 symbols')
      .max(500, 'Maximum 500 symbols')
      .required('Website is required'),
    status: Yup.object().required('Status is required'),
  })
  const vendorFormik = useFormik({
    initialValues: {
      vendor: '',
      trackingId: '',
      trackingLink: '',
      status: null,
    },
    validationSchema: trackingSchema,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setTimeout(() => {
        let request = values
        request.status = request.status.value
        httpService.postData(
          '/influencer-onboarding/shipping/delivery/update-tracking/' + selectedInfluencer._id,
          values,
          (response: any) => {
            if (selectedInfluencer.shipping.product?.isGoodCondition === false) {
              httpService.putData(
                '/influencer-onboarding/shipping/re-disptach/' + selectedInfluencer._id,
                {},
                (response: any) => {
                  message.success('Product redispatched to the influencer')
                },
                (error: any) => {
                  message.error('Shipping failed to dispatch')
                }
              )
            } else {
              message.success('Shipping Details updated successfully')
            }
            setShow1(false)
            getAllInfluencer()
          },
          (error: any) => {
            message.error('Shipping Details failed to update')
          }
        )
      }, 1000)
    },
  })

  const addressSchema = Yup.object().shape({
    address: Yup.string().required('Address is required'),
    city: Yup.string().required('City ID is required'),
    state: Yup.string().required('State is required'),
    pinCode: Yup.string().required('Pincode is required'),
  })
  const addressFormik = useFormik({
    initialValues: {
      address: '',
      city: '',
      state: '',
      pinCode: '',
    },
    validationSchema: addressSchema,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      httpService.postData(
        '/influencer-master/address/' + selectedInfluencer.influencer._id,
        values,
        (response: any) => {
          message.success('Shipping address added successfully')
          setShow2(false)
          getAllInfluencer()
        },
        (error: any) => {
          message.success('Shipping address failed to update')
        }
      )
    },
  })
  const choice = (value: any) => {
    httpService.putData(
      '/campaign-master/shipping-method/' + history.location.state._id,
      {
        shippingMethod: value === 'delivery' ? 'delivery' : 'selfCheckout',
      },
      (response: any) => {
        value === 'delivery' ? setDelivery(true) : setSelf(true)
      },
      (error: any) => {
        message.error(error?.message)
      }
    )
  }
  return (
    <>
      <div style={{ minWidth: '1336px' }}>
        {!delivery && !self && (
          <div>
            <div className='w-100 flex mt-20'>
              <h1 className='fw-bolder'>Choose Shipping method</h1>
            </div>
            <div className='w-100 flex'>
              <p className='fs-3 text-gray-400 w-600px'>
                Based on the shippng options, product will be delivered to the influencer address.
                Once selected selection cannot be reversed.
              </p>
            </div>
            <div className='flex'>
              <div className='basicDiv w-25'>
                <KTSVG
                  className='svg-icon svg-icon-5x flex'
                  path={toAbsoluteUrl('/media/icons/Custom/courier.svg')}
                />
                <div className='w-100 flex m20'>
                  <h3>Offline (Delivery to doorstep)</h3>
                </div>
                <div className='w-100 flex'>
                  <p className='w-350px text-gray-400 text-center'>
                    The product(s) will be delivered physically on the influencer's shipping
                    address.
                  </p>
                </div>
                <div className='w-100 flex'>
                  <button
                    className='btn btn-primary fs-6 dialogButton w-75 h-50px m20 '
                    onClick={() => {
                      choice('delivery')
                    }}
                  >
                    Proceed
                  </button>
                </div>
              </div>

              <div className='basicDiv w-25 ms-5'>
                <KTSVG
                  className='svg-icon svg-icon-5x flex'
                  path={toAbsoluteUrl('/media/icons/Custom/creditCard.svg')}
                />
                <div className='w-100 flex m20'>
                  <h3>Online (Digitally redeemable code)</h3>
                </div>
                <div className='w-100 flex'>
                  <p className='w-350px text-gray-400 text-center'>
                    The influencer can make an online purchase using the promotional code.
                  </p>
                </div>
                <div className='w-100 flex'>
                  <button
                    className='btn btn-primary fs-6 dialogButton w-75 h-50px m20 '
                    onClick={() => {
                      choice('self')
                    }}
                  >
                    Proceed
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {delivery && (
          <div className='w-100 me-20 pe-20'>
            <div className='TableDiv w-100'>
              <div className='TableHeader w-100 justify-content-between'>
                <h3 className='mb-0 w-900px'>
                  Shipping Method Offline (Delivery to doorstep)
                  <p className='m-0 text-gray-400 fs-7'>{influencerList.length} Influencers</p>
                </h3>
                <div className='flex' onClick={() => { bulkDownloadOffline() }}>
                <span
                  style={{ width: '31px', height: '31px' }}
                  className='svg-icon rounded flex svg-icon-primary bg-primary bg-opacity-5 me-3 pointer'
                >
                  <KTSVG path={toAbsoluteUrl('/media/icons/Custom/Export.svg')} />
                </span>
                </div>
                <input
                  type="file"
                  className="form-control form-control-sm" 
                  id="file" 
                  accept={SheetJSFT}
                  onChange={handleChange} />
                <input className="btn btn-secondary btn-sm" type='submit' value="Upload Excel" onClick={handleFilebulkUploadOffline} />
                <div className='d-flex align-items-right justify-content-right'>
                  <link
                    href='https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css'
                    rel='stylesheet'
                  />
                  <Input
                    placeholder='Search influencer'
                    className='form-control-solid bg-gray-100 rounded w-250px h-38px'
                    prefix={
                      <>
                        <SearchOutlined />
                      </>
                    }
                  />
                </div>
                {enableSubmissionButton && (
                  <div className='flex ms-8px'>
                    <Link
                      to={{
                        pathname: '/campaign/contract',
                        state: {
                          ...history.location.state,
                          ...{ breadcrums: ['Campaigns', 'Content Submission'] },
                        },
                      }}
                    >
                      <button className='btn TableButton btn-pink text-white'>
                        Content Submission
                      </button>
                    </Link>
                  </div>
                )}
              </div>
              <div className='card-body mt-0 p-0 h-75'>
                {/* begin::Table container */}
                <div className='table-responsive'>
                  {/* begin::Table */}
                  <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                    {/* begin::Table head */}
                    <thead>
                      <tr className='fw-bolder fs-7 text-muted'>
                        <th className='bg-primary bg-opacity-5 min-w-200px'>
                          <span className='d-flex ms-7'>
                            Influencer
                            {/* <a
                          className={sort1}
                          onClick={() => {
                            if (sort1 == 'rotate-90 ms-2') {
                              setSort1('rotate-270 ms-2')
                            } else {
                              setSort1('rotate-90 ms-2')
                            }
                          }}
                        >
                          <KTSVG
                            className='svg-icon svg-icon-sm svg-icon-gray-700 flex h-20px pointer'
                            path={toAbsoluteUrl('/media/icons/Custom/SideArrow.svg')}
                          />
                        </a> */}
                          </span>
                        </th>
                        <th className='bg-primary bg-opacity-5 min-w-300px'>
                          <span className='d-flex'>
                            Address
                            {/* <a
                          className={sort2}
                          onClick={() => {
                            if (sort2 == 'rotate-90 ms-2') {
                              setSort2('rotate-270 ms-2')
                            } else {
                              setSort2('rotate-90 ms-2')
                            }
                          }}
                        >
                          <KTSVG
                            className='svg-icon svg-icon-sm svg-icon-gray-700 flex h-20px pointer'
                            path={toAbsoluteUrl('/media/icons/Custom/SideArrow.svg')}
                          />
                        </a> */}
                          </span>
                        </th>
                        <th className='bg-primary bg-opacity-5 min-w-100px'>
                          <span className='d-flex'>City</span>
                        </th>
                        <th className='bg-primary bg-opacity-5 min-w-100px'>
                          <span className='d-flex'>State</span>
                        </th>
                        <th className='bg-primary bg-opacity-5 min-w-100px'>
                          <span className='d-flex'>Pincode</span>
                        </th>
                        <th className='bg-primary bg-opacity-5 min-w-100px'>
                          <span className='d-flex'>Tracking</span>
                        </th>
                        <th className='bg-primary bg-opacity-5 min-w-100px'>
                          <span className='d-flex'>Shipped</span>
                        </th>
                        <th className='bg-primary bg-opacity-5 min-w-100px'>
                          <span className='d-flex'>Delivered</span>
                        </th>
                      </tr>
                    </thead>
                    {/* end::Table head */}
                    {/* begin::Table body */}
                    <tbody>
                      {influencerList.map((data: any) => (
                        <tr className=''>
                          <td className='fw-bolder ps-8'>
                            <span className='flex justify-content-start w-100'>
                              <ProfileAvatar
                                src={
                                  data?.influencer.basicInfo.avatar
                                    ? data?.influencer.basicInfo.avatar
                                    : null
                                }
                                className='w-100 rounded-circle ml-2 '
                                name={
                                  data?.influencer.basicInfo.name
                                    ? data?.influencer.basicInfo.name[0].toUpperCase()
                                    : 'NA'
                                }
                              ></ProfileAvatar>

                              <span className='fs-6 ms-4 mt-1 '>
                                <label className='fs-7 text-gray-400 m-0'>
                                  {data?.influencer.basicInfo.name}
                                </label>
                              </span>
                            </span>
                          </td>
                          <td>
                            {data?.shipping?.addressDetail ? (
                              <>{data?.shipping?.addressDetail.address}</>
                            ) : (
                              <button
                                className='btn btn-primary fs-6 dialogButton w-70 '
                                onClick={() => {
                                  getInfluencerDetails(data.influencer._id)
                                  setSelectedInfluencer(data)
                                  setShow2(true)
                                }}
                              >
                                + Add Address
                              </button>
                            )}
                          </td>
                          <td>{data?.shipping?.addressDetail?.city || 'NA'}</td>
                          <td>{data?.shipping?.addressDetail?.state || 'NA'}</td>
                          <td>{data?.shipping?.addressDetail?.pinCode || 'NA'}</td>
                          <td>
                            {/* {data?.shipping?.delivery?.tracking ? (
                              <a
                                href={data?.shipping?.delivery?.tracking?.trackingLink}
                                target='parent'
                              >
                                <div className='tracking-btn'>
                                  {data?.shipping?.delivery?.tracking?.trackingId}
                                </div>
                              </a>
                            ) : ( */}
                            <button
                              className='btn bg-pink bg-opacity-25 text-pink fs-6 dialogButton w-100 h-50px'
                              onClick={() => {
                                getInfluencerDetails(data.influencer._id)
                                setSelectedInfluencer(data)
                                if (data?.shipping?.delivery?.tracking) {
                                  let status
                                  if (data?.shipping?.delivery?.status === 'shipped') {
                                    status = { label: 'Shipped', value: 'shipped' }
                                  } else if (data?.shipping?.delivery?.status === 'delivered') {
                                    status = { label: 'Delivered', value: 'delivered' }
                                  }
                                  vendorFormik.setValues({
                                    vendor: data?.shipping?.delivery?.tracking.vendor,
                                    trackingId: data?.shipping?.delivery?.tracking.trackingId,
                                    trackingLink: data?.shipping?.delivery?.tracking.trackingLink,
                                    status: status,
                                  })
                                } else {
                                  vendorFormik.resetForm()
                                }
                                setShow1(true)
                              }}
                            >
                              {data?.shipping?.delivery?.tracking ? 'View / Update ' : '+ Add '}
                              Details
                            </button>
                            {/* )} */}
                          </td>
                          <td className=''>
                            <span
                              className={
                                data?.shipping?.delivery?.status === 'delivered' ||
                                  data?.shipping?.delivery?.status === 'shipped'
                                  ? 'bg-success w-35px h-35px flex rounded-circle'
                                  : 'bg-gray-100 w-35px h-35px flex rounded-circle'
                              }
                            >
                              <KTSVG
                                className={
                                  data?.shipping?.delivery?.status === 'delivered' ||
                                    data?.shipping?.delivery?.status === 'shipped'
                                    ? 'svg-icon svg-icon-white flex'
                                    : 'svg-icon svg-icon-dark flex'
                                }
                                path={toAbsoluteUrl('/media/icons/Custom/Active.svg')}
                              />
                            </span>
                          </td>
                          <td className=''>
                            {data.shipping.product.reDispatched === true ? (
                              <>
                                <span
                                  className={
                                    data?.shipping?.received
                                      ? 'bg-success w-35px h-35px flex rounded-circle'
                                      : 'bg-gray-100 w-35px h-35px flex rounded-circle'
                                  }
                                >
                                  <KTSVG
                                    className={
                                      data?.shipping?.received
                                        ? 'svg-icon svg-icon-white flex'
                                        : 'svg-icon svg-icon-dark flex'
                                    }
                                    path={toAbsoluteUrl('/media/icons/Custom/Active.svg')}
                                  />
                                </span>
                              </>
                            ) : data.shipping.product.isGoodCondition === false ? (
                              <>
                                <span className='bg-danger w-35px h-35px flex rounded-circle'>
                                  <a
                                    className='menu-link px-3 text-hover-white'
                                    onClick={(e: any) => {
                                      setShowR(true)
                                      setSelectedInfluencer(data)
                                    }}
                                  >
                                    <KTSVG
                                      className='svg-icon svg-icon-white flex'
                                      path={toAbsoluteUrl('/media/icons/Custom/replace.svg')}
                                    />
                                  </a>
                                </span>
                              </>
                            ) : data.shipping.product.reDispatched === true ? (
                              <span
                                className={
                                  data?.shipping?.received
                                    ? 'bg-success w-35px h-35px flex rounded-circle'
                                    : 'bg-gray-100 w-35px h-35px flex rounded-circle'
                                }
                              >
                                <KTSVG
                                  className={
                                    data?.shipping?.received
                                      ? 'svg-icon svg-icon-white flex'
                                      : 'svg-icon svg-icon-dark flex'
                                  }
                                  path={toAbsoluteUrl('/media/icons/Custom/Active.svg')}
                                />
                              </span>
                            ) : (
                              <>
                                <span
                                  className={
                                    data?.shipping?.received
                                      ? 'bg-success w-35px h-35px flex rounded-circle'
                                      : 'bg-gray-100 w-35px h-35px flex rounded-circle'
                                  }
                                >
                                  <KTSVG
                                    className={
                                      data?.shipping?.received
                                        ? 'svg-icon svg-icon-white flex'
                                        : 'svg-icon svg-icon-dark flex'
                                    }
                                    path={toAbsoluteUrl('/media/icons/Custom/Active.svg')}
                                  />
                                </span>
                              </>
                            )}
                            <Modal
                              show={showR}
                              onHide={() => {
                                setShowR(false)
                              }}
                              aria-labelledby='contained-modal-title-vcenter'
                              centered
                            >
                              <Modal.Header>
                                <Modal.Title>
                                  <h1 className='fw-bolder fs-3'>Product Damage Issue</h1>
                                </Modal.Title>
                              </Modal.Header>

                              {selectedInfluencer?.shipping?.product?.mediaType == "external_url" ? (
                                <Modal.Body
                                style={{
                                  margin: 'auto',
                                }}
                              >
                                <a href={selectedInfluencer?.shipping?.product?.link} target='self'>
                                <img
                                  width={100}
                                  src={toAbsoluteUrl('/media/icons/Custom/file.svg')}
                                  alt='Google drive link'
                                  />
                                </a>
                                {/* <p>{selectedInfluencer?.shipping?.product?.link}</p> */}
                              </Modal.Body>
                              ) : (<Modal.Body
                                style={{
                                  margin: 'auto',
                                  height: '500px',
                                  overflow: 'scroll',
                                }}
                              >
                                <img
                                  src={selectedInfluencer?.shipping?.product?.link}
                                  style={{ width: '350px' }}
                                />
                                {/* <div
                                      className='mh-100 mx-auto'
                                      style={{
                                        width: '350px',
                                        height: '350px',
                                        backgroundImage: `url(${selectedInfluencer?.shipping?.product?.link})`,
                                        backgroundPosition: 'center',
                                        backgroundSize: 'cover',
                                        backgroundRepeat: 'no-repeat',
                                      }}
                                    ></div> */}
                              </Modal.Body>)}
                              
                              <Modal.Footer>
                                <div className=' mx-auto'>
                                  <button
                                    className='btn fw-bolder btn-gray-200 fs-6 dialogButton'
                                    onClick={() => {
                                      setShowR(false)
                                    }}
                                    style={{ width: '220px', height: '50px' }}
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    className='btn btn-pink fs-6 dialogButton ms-2 text-white'
                                    onClick={() => {
                                      setShow1(true)
                                      setShowR(false)
                                    }}
                                    style={{ width: '220px', height: '50px' }}
                                  >
                                    Update Details
                                  </button>
                                </div>
                              </Modal.Footer>
                            </Modal>
                            <Modal
                              show={show1}
                              onHide={handleClose}
                              aria-labelledby='contained-modal-title-vcenter'
                              centered
                            >
                              <Modal.Body>
                                <h2>Shipping </h2>

                                <h6 className='fw-bold m24'>Status</h6>
                                <form noValidate onSubmit={vendorFormik.handleSubmit}>
                                  <Select
                                    name='status'
                                    {...vendorFormik.getFieldProps('status')}
                                    placeholder='Status'
                                    styles={{
                                      menuList: (provided, state) => ({
                                        ...provided,
                                        height: '100px',
                                      }),
                                    }}
                                    onChange={(e) => vendorFormik.setFieldValue('status', e)}
                                    // options={["Shipped", "Delivered"]}
                                    options={[
                                      { label: 'Shipped', value: 'shipped' },
                                      { label: 'Delivered', value: 'delivered' },
                                    ]}
                                    className='text-gray-500 form-control form-control-solid bg-gray-100 p-0 border-0 categorySelect'
                                  />{' '}
                                  {vendorFormik.touched.status && vendorFormik.errors.status && (
                                    <div className='fv-plugins-message-container'>
                                      <div className='fv-help-block'>
                                        <span className='text-danger' role='alert'>
                                          {vendorFormik.errors.status}
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                  <h6 className='fw-bold m16'>Vendor</h6>
                                  <input
                                    type='text'
                                    {...vendorFormik.getFieldProps('vendor')}
                                    placeholder='Vendor'
                                    className='form-control form-control-solid bg-gray-100'
                                  />
                                  {vendorFormik.touched.vendor && vendorFormik.errors.vendor && (
                                    <div className='fv-plugins-message-container'>
                                      <div className='fv-help-block'>
                                        <span className='text-danger' role='alert'>
                                          {vendorFormik.errors.vendor}
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                  <h6 className='fw-bold m16'>Tracking Link</h6>
                                  <input
                                    type='text'
                                    {...vendorFormik.getFieldProps('trackingLink')}
                                    placeholder='Tracking Link'
                                    className='form-control form-control-solid bg-gray-100'
                                  />
                                  {vendorFormik.touched.trackingLink &&
                                    vendorFormik.errors.trackingLink && (
                                      <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                          <span className='text-danger' role='alert'>
                                            {vendorFormik.errors.trackingLink}
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                  <h6 className='fw-bold m16'>Tracking ID</h6>
                                  <input
                                    type='text'
                                    {...vendorFormik.getFieldProps('trackingId')}
                                    placeholder='Tracking ID'
                                    className='form-control form-control-solid bg-gray-100'
                                  />
                                  {vendorFormik.touched.trackingId &&
                                    vendorFormik.errors.trackingId && (
                                      <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                          <span className='text-danger' role='alert'>
                                            {vendorFormik.errors.trackingId}
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                  <div className='d-flex'>
                                    <button
                                      type='button'
                                      className='btn btn-gray-100 text-dark fs-6 dialogButton w-100 h-50px m20'
                                      onClick={() => setShow1(false)}
                                    >
                                      Cancel
                                    </button>
                                    <button
                                      type='submit'
                                      className='btn btn-primary fs-6 dialogButton w-100 h-50px m20 ms-5'
                                      disabled={!vendorFormik.isValid}
                                      onClick={() => {
                                        getInfluencerDetails(data.influencer._id)
                                      }}
                                    // onClick={() => setShow(false)}
                                    >
                                      Save Changes
                                    </button>
                                  </div>
                                </form>
                              </Modal.Body>
                            </Modal>
                          </td>
                        </tr>
                      ))}
                    </tbody>

                    {/* end::Table body */}
                  </table>

                  <div className='d-flex ms-5 me-10 justify-content-between align-items-end flex-wrap mb-10'>
                    <span className='text-muted pb-2'>
                      {/* Page 1 of 10 */}
                      {/* {Math.floor(paginationCount % influencerListParams.limit) === 0
                        ? Math.floor(paginationCount / influencerListParams.limit)
                        : Math.floor(paginationCount / influencerListParams.limit) + 1} */}
                    </span>
                    <div className='d-flex flex-wrap '>
                      <Pagination
                        className=''
                        pageSize={10}
                        defaultCurrent={1}
                        total={10}
                      // onChange={(e) =>
                      //   setinfluencerListParams({ ...influencerListParams, ...{ page: e } })
                      // }
                      />
                    </div>
                  </div>
                  {/* end::Table */}
                </div>
                {/* end::Table container */}
              </div>
            </div>
          </div>
        )}
        {self && (
          <div className='w-100 me-20 pe-20 d-flex'>

            <div className='TableDiv w-75'>
              <div className='TableHeader w-100 justify-content-between'>
                <h3 className='mb-0'>
                  Shipping Method Online (Digitally redeemable code)
                  <p className='m-0 text-gray-400 fs-7'>{influencerList.length} Influencers</p>
                </h3>
                <div className='flex' onClick={() => { bulkDownloadOnline() }}>
                <span
                  style={{ width: '31px', height: '31px' }}
                  className='svg-icon rounded flex svg-icon-primary bg-primary bg-opacity-5 me-3 pointer'
                >
                  <KTSVG path={toAbsoluteUrl('/media/icons/Custom/Export.svg')} />
                </span>
                </div>
                <input type="file" className="form-control form-control-sm" id="file" accept={SheetJSFT} onChange={handleChange} />
                <input className="btn btn-secondary btn-sm" type='submit' value="Upload Excel" onClick={handleFilebulkUploadOnline} />
                <div className='d-flex align-items-center justify-content-start'>
                  <link
                    href='https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css'
                    rel='stylesheet'
                  />
                  <Input
                    placeholder='Search influencer'
                    className='form-control-solid bg-gray-100 rounded w-250px h-38px'
                    prefix={
                      <>
                        <SearchOutlined />
                      </>
                    }
                  />
                </div>
                {enableSubmissionButton && (
                  <div className='flex ms-8px'>
                    <Link
                      to={{
                        pathname: '/campaign/contract',
                        state: {
                          ...history.location.state,
                          ...{ breadcrums: ['Campaigns', 'Content Submission'] },
                        },
                      }}
                    >
                      <button className='btn TableButton btn-pink text-white'>
                        Content Submission
                      </button>
                    </Link>
                  </div>
                )}
              </div>
              <div className='card-body mt-0 p-0 h-75'>
                {/* begin::Table container */}
                <div className='table-responsive'>
                  {/* begin::Table */}
                  <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                    {/* begin::Table head */}
                    <thead>
                      <tr className='fw-bolder fs-7 text-muted'>
                        <th className='bg-primary bg-opacity-5 min-w-200px'>
                          <span className='d-flex ms-7'>
                            Influencer
                            {/* <a
                          className={sort1}
                          onClick={() => {
                            if (sort1 == 'rotate-90 ms-2') {
                              setSort1('rotate-270 ms-2')
                            } else {
                              setSort1('rotate-90 ms-2')
                            }
                          }}
                        >
                          <KTSVG
                            className='svg-icon svg-icon-sm svg-icon-gray-700 flex h-20px pointer'
                            path={toAbsoluteUrl('/media/icons/Custom/SideArrow.svg')}
                          />
                        </a> */}
                          </span>
                        </th>
                        <th className='bg-primary bg-opacity-5 min-w-300px'>
                          <span className='d-flex'>
                          {modeOfBarter === 'online' ? 'Details' : 'Address'}
                            {/* Address */}
                            {/* <a
                          className={sort2}
                          onClick={() => {
                            if (sort2 == 'rotate-90 ms-2') {
                              setSort2('rotate-270 ms-2')
                            } else {
                              setSort2('rotate-90 ms-2')
                            }
                          }}
                        >
                          <KTSVG
                            className='svg-icon svg-icon-sm svg-icon-gray-700 flex h-20px pointer'
                            path={toAbsoluteUrl('/media/icons/Custom/SideArrow.svg')}
                          />
                        </a> */}
                          </span>
                        </th>
                        {modeOfBarter === 'online' ? (
                          null
                        ) : (
                          <>
                            <th className='bg-primary bg-opacity-5 min-w-100px'>
                              <span className='d-flex'>City</span>
                            </th>
                            <th className='bg-primary bg-opacity-5 min-w-100px'>
                              <span className='d-flex'>State</span>
                            </th>
                            <th className='bg-primary bg-opacity-5 min-w-100px'>
                              <span className='d-flex'>Pincode</span>
                            </th>
                          </>
                        )}
                        <th className='bg-primary bg-opacity-5 min-w-100px'>
                          <span className='d-flex'>Received</span>
                        </th>

                      </tr>
                    </thead>
                    {/* end::Table head */}
                    {/* begin::Table body */}
                    <tbody>
                      {influencerList.map((data: any) => (
                        <tr className=''>
                          <td className='fw-bolder ps-8'>
                            <span className='flex justify-content-start w-100'>
                              <ProfileAvatar
                                src={
                                  data?.influencer.basicInfo.avatar
                                    ? data?.influencer.basicInfo.avatar
                                    : null
                                }
                                className='w-100 rounded-circle ml-2 '
                                name={
                                  data?.influencer.basicInfo.name
                                    ? data?.influencer.basicInfo.name[0].toUpperCase()
                                    : 'NA'
                                }
                              ></ProfileAvatar>

                              <span className='fs-6 ms-4 mt-1 '>
                                <label className='fs-7 text-gray-400 m-0'>
                                  {data?.influencer.basicInfo.name}
                                </label>
                              </span>
                            </span>
                          </td>
                          <td>
                            {data?.shipping.addressDetail ? (
                              <>{data?.shipping.addressDetail.address}</>
                            ) : (
                              <button
                                className='btn btn-primary fs-6 dialogButton w-70 '
                                onClick={() => {

                                  if (self) {
                                    // setShowOrderingInfo(true);
                                    setShowOrderingInfoModal(true);
                                    setUpdateDetailsModal(data)

                                  } else {
                                    getInfluencerDetails(data.influencer._id)
                                    setSelectedInfluencer(data)
                                    setShow2(true)
                                  }
                                }}
                              >
                                {/* + Add Address */}
                                {self ? 'Update Details' : '+ Add Address'}

                              </button>
                            )}
                          </td>
                          {modeOfBarter === 'online' ? (
                            null
                          ) : (
                            <>
                              <td>{data?.shipping?.addressDetail?.city || 'NA'}</td>
                              <td>{data?.shipping?.addressDetail?.state}</td>
                              <td>{data?.shipping?.addressDetail?.pinCode}</td>
                            </>
                          )}
                          <td className=''>
                            <span
                              className={
                                data?.shipping?.received
                                  ? 'bg-success w-35px h-35px flex rounded-circle'
                                  : 'bg-gray-100 w-35px h-35px flex rounded-circle'
                              }
                            >
                              <KTSVG
                                className={
                                  data?.shipping?.received
                                    ? 'svg-icon svg-icon-white flex'
                                    : 'svg-icon svg-icon-dark flex'
                                }
                                path={toAbsoluteUrl('/media/icons/Custom/Active.svg')}
                              />
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>

                    {/* end::Table body */}
                  </table>
                  <div className='d-flex ms-5 me-10 justify-content-between align-items-end flex-wrap mb-10'>
                    <span className='text-muted pb-2'>
                      {/* Page 1 of 10 */}
                      {/* {Math.floor(paginationCount % influencerListParams.limit) === 0
                        ? Math.floor(paginationCount / influencerListParams.limit)
                        : Math.floor(paginationCount / influencerListParams.limit) + 1} */}
                    </span>
                    <div className='d-flex flex-wrap '>
                      <Pagination
                        className=''
                        pageSize={10}
                        defaultCurrent={1}
                        total={10}
                      // onChange={(e) =>
                      //   setinfluencerListParams({ ...influencerListParams, ...{ page: e } })
                      // }
                      />
                    </div>
                  </div>
                  {/* end::Table */}
                </div>
                {/* end::Table container */}
              </div>
            </div>
          </div>
        )}
        <Modal
          show={show}
          onHide={handleClose}
          aria-labelledby='contained-modal-title-vcenter'
          centered
        >
          <Modal.Body>
            <h2>Add an Address</h2>
            <h6 className='fw-bold required m20'>Address</h6>
            <form noValidate onSubmit={addressFormik.handleSubmit}>
              <textarea
                {...addressFormik.getFieldProps('address')}
                placeholder='Address'
                className='form-control form-control-solid bg-gray-100 h-70px'
              />
              <h6 className='fw-bold required m16'>State</h6>
              <Select
                options={stateList}
                // value={state}
                isMulti={false}
                name='state'
                placeholder='State'
                styles={{
                  menuList: (provided, state) => ({
                    ...provided,
                    height: '100px',
                  }),
                }}
                onChange={(e: any) => {
                  addressFormik.setFieldValue('state', e.value)
                  setS(e.value)
                  // setState(e)
                }}
                className='text-gray-500 form-control form-control-solid bg-gray-100 p-0 border-0 categorySelect'
              />
              <h6 className='fw-bold required m16'>City</h6>
              <Select
                options={cityList}
                // value={['Maha', 'Delhi']}
                isMulti={false}
                name='city'
                placeholder='City'
                styles={{
                  menuList: (provided, state) => ({
                    ...provided,
                    height: '100px',
                  }),
                }}
                onChange={(e) => {
                  addressFormik.setFieldValue('city', e.value)
                }}
                className='text-gray-500 form-control form-control-solid bg-gray-100 p-0 border-0 categorySelect'
              />
              <h6 className='fw-bold required m16'>Pincode</h6>
              <input
                type='text'
                placeholder='Pincode'
                {...addressFormik.getFieldProps('pinCode')}
                className='form-control form-control-solid bg-gray-100'
              />
              <div className=''>
                <button
                  className='btn btn-primary fs-6 dialogButton w-100 h-50px m20 '
                  onClick={() => setShow(false)}
                >
                  Add Address
                </button>
                <div className='w-100 d-flex m16 justify-content-center pb-2'>
                  <a
                    className='link-primary fs-6 fw-bolder'
                    onClick={() => {
                      setShow(false)
                      setShow2(true)
                    }}
                  >
                    Select from an Existing Address
                  </a>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>
        <Modal
          show={show2}
          onHide={handleClose}
          aria-labelledby='contained-modal-title-vcenter'
          centered
        >
          <Modal.Body>
            <h2>Select from an Existing Address</h2>
            <form noValidate onSubmit={addressFormik.handleSubmit}>
              {addressList.map((x) => (
                <div className='w-100 m-2 bg-gray-100 p-3 pt-5 pb-5 rounded d-flex'>
                  <div className='form-check form-check-custom form-check-solid form-check-primary'>
                    <input
                      className='form-check-input'
                      type='radio'
                      value='Address'
                      id='flexRadioDefault'
                      name='platform'
                      checked={x.isDefault}
                      onChange={(e) => {
                        addressList.map((address) => {
                          if (address._id === x._id) address.isDefault = true
                          else address.isDefault = false
                        })
                        setTimeout(() => {
                          setAddressList([...addressList])
                        }, 100)
                      }}
                    />
                  </div>
                  <p className='ms-5 mb-0'>
                    {x.address}, {x.city}, {x.state}, {x.pinCode}
                  </p>
                </div>
              ))}

              <div className=''>
                <button
                  className='btn btn-primary fs-6 dialogButton w-100 h-50px m20 '
                  onClick={() => {
                    if (addressList.length > 0)
                      httpService.postData(
                        '/influencer-onboarding/shipping/update-address/' + selectedInfluencer._id,
                        {
                          addressId: addressList.find((x) => x.isDefault)._id,
                        },
                        (response) => {
                          message.success('Influencer address updated successfully.')
                          getAllInfluencer()
                          setShow2(false)
                        },
                        (error) => {
                          message.error('Something went wrong')
                        }
                      )
                  }}
                >
                  Select Address
                </button>
                <div className='w-100 d-flex m16 justify-content-center pb-2'>
                  <a
                    className='link-primary fs-6 fw-bolder'
                    onClick={() => {
                      setShow2(false)
                      setShow(true)
                    }}
                  >
                    Add a different delivery address
                  </a>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>

        <Modal show={showOrderingInfoModal} onHide={() => setShowOrderingInfoModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Ordering Info</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p className='m-0 text-gray-400 fs-7'>This info will be displayed to the influencer</p>
            {updateDetailsModal &&
              <InfluencerForm
                influencer={updateDetailsModal}
                onSubmit={(updateDetailsModal, values) => {
                  httpService.postData(
                    '/influencer-onboarding/shipping/self-checkout/' + `${updateDetailsModal._id}`,
                    values,
                    (response) => {
                      message.success('Shipping Details updated successfully');
                      setShowOrderingInfoModal(false);
                      getAllInfluencer();
                    },
                    (error) => {
                      message.error('Shipping Details failed to update');
                    }
                  );
                }}
              />
            }
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}
