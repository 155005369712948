import React, {useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../_metronic/helpers'
import httpService from '../../setup/http/httpServices'
import Moment from 'moment'

function ProfileContent(props: any) {
  const data = props.data
  const userData = props.userData
  const [sponsoredPosts, setSponsoredPosts] = useState([])
  const [popularPosts, setPopularPosts] = useState([])
  // const username = data.influencerSocialDetails.instagram.username
  useEffect(() => {
    setSponsoredPosts(userData.user_profile.commercial_posts)
    setPopularPosts(userData.user_profile.top_posts)
  }, [userData.user_profile])

  function nFormatter(num: number) {
    if (num >= 1000000) {
      return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M'
    }
    if (num >= 1000) {
      return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K'
    }
    return num
  }

  return (
    <div className='d-flex flex-column m20 w-100 mb16'>
      {!props.type && (
        <>
          <h2>Sponsored Content</h2>
          <div className='d-flex flex-wrap justify-content-between'>
            {sponsoredPosts &&
              sponsoredPosts.length > 0 &&
              sponsoredPosts.map((post: any) => (
                <div
                  className='d-flex flex-column m20 rounded justify-content-between'
                  style={{backgroundColor: 'white', width: '357px'}}
                >
                  <img
                    className='rounded-top'
                    src={post.thumbnail}
                    style={{
                      width: '357px',
                      height: '290px',
                      objectFit: 'contain',
                    }}
                  />
                  <div style={{padding: '16px'}} className='d-flex flex-column'>
                    <div className='d-flex text-muted' style={{fontSize: '12px'}}>
                      <span>{Moment(post.created).format('DD MMMM YYYY')}</span>
                      <span style={{margin: '0px 8px'}}>&bull;</span>
                      <span>{Moment(post.created).format('h:mm a')}</span>
                    </div>
                    <span className='fs-14 w-100 maxLine'>{post.text}</span>
                  </div>
                  <div className='d-flex w-100' style={{borderTop: '1px solid #f2f4f9'}}>
                    <div style={{padding: '16px 0px', textAlign: 'center'}} className='col '>
                      <img
                        src={toAbsoluteUrl('/media/icons/Custom/eye.svg')}
                        className='svg-icon-3'
                      />{' '}
                      <label>{nFormatter(post.stat.views)}</label>
                    </div>
                    <div
                      style={{
                        padding: '16px 0px',
                        textAlign: 'center',
                        borderLeft: '1px solid #F2F4F9',
                        borderRight: '1px solid #F2F4F9',
                      }}
                      className='col'
                    >
                      <img
                        src={toAbsoluteUrl('/media/icons/Custom/likes.svg')}
                        className='svg-icon-3'
                      />{' '}
                      <label>{nFormatter(post.stat.likes)}</label>
                    </div>
                    <div style={{padding: '16px 0px', textAlign: 'center'}} className='col'>
                      <img
                        src={toAbsoluteUrl('/media/icons/Custom/comments.svg')}
                        className='svg-icon-3'
                      />{' '}
                      <label>{nFormatter(post.stat.comments)}</label>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </>
      )}
      {/* sajfdldasfkjlksadjkdfskjghiaeuwjfedia */}
      <h2 style={{marginTop: '32px'}}>Popular Content</h2>
      <div className='d-flex flex-wrap justify-content-between'>
        {popularPosts &&
          popularPosts.length > 0 &&
          popularPosts.map((post: any) => (
            <div
              className='d-flex flex-column m20 rounded justify-content-between'
              style={{backgroundColor: 'white', width: '357px'}}
            >
              {' '}
              <div>
                <img
                  className='rounded-top'
                  src={post.thumbnail}
                  style={{
                    width: '357px',
                    height: '290px',
                    objectFit: 'contain',
                  }}
                />
                <div style={{padding: '16px'}} className='d-flex flex-column'>
                  <div className='d-flex text-muted' style={{fontSize: '12px'}}>
                    <span>{Moment(post.created).format('DD MMMM YYYY')}</span>
                    {/* <span style={{margin: '0px 8px'}}>&bull;</span> */}
                    {/* <span>{Moment(post.created).format('h:mm a')}</span> */}
                  </div>
                  <span className='fs-14 maxLine'>{post.text}</span>
                </div>
              </div>
              <div className='d-flex w-100' style={{borderTop: '1px solid #f2f4f9'}}>
                <div style={{padding: '16px 0px', textAlign: 'center'}} className='col'>
                  <img src={toAbsoluteUrl('/media/icons/Custom/eye.svg')} className='svg-icon-3' />{' '}
                  <label>{nFormatter(post.stat.views)}</label>
                </div>
                <div
                  style={{
                    padding: '16px 0px',
                    textAlign: 'center',
                    borderLeft: '1px solid #F2F4F9',
                    borderRight: '1px solid #F2F4F9',
                  }}
                  className='col'
                >
                  <img
                    src={toAbsoluteUrl('/media/icons/Custom/likes.svg')}
                    className='svg-icon-3'
                  />{' '}
                  <label>{nFormatter(post.stat.likes)}</label>
                </div>
                <div style={{padding: '16px 0px', textAlign: 'center'}} className='col'>
                  <img
                    src={toAbsoluteUrl('/media/icons/Custom/comments.svg')}
                    className='svg-icon-3'
                  />{' '}
                  <label>{nFormatter(post.stat.comments)}</label>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  )
}

export default ProfileContent
