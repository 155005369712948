import React, { Suspense } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { FallbackView } from '../../_metronic/partials'
import { AgencyBrandMiscellaneous } from '../masters/AgencyBrandMiscellaneous'
import { AgencyBrandSuccess } from '../masters/AgencyBrandSuccess'
import { AgencyBrandTable } from '../masters/AgencyBrandTable'
import { AgencyDetails } from '../masters/AgencyDetails'
import { AgencyViewProfile } from '../masters/AgencyViewProfile'
import { AgencyViewBilling } from '../masters/AgencyViewBilling'
import { AgencyViewCampaigns } from '../masters/AgencyViewCampaign'
import { AgencyViewInfluencer } from '../masters/AgencyViewInfluencer'
import { AgencyViewBrands } from '../masters/AgencyViewBrands'
import { BrandQuestion } from '../masters/BrandQuestion'
import { BrandMaster } from '../masters/BrandMaster'
import { CategoryMaster } from '../masters/CategoryMaster'
import { CompanyDetails } from '../masters/companyDetails'
import { InfluencerMasterAdd } from '../masters/InfluencerMasterAdd'
import { InfluencerAdditionalInfo } from '../masters/InfluencerAdditionalInfo'
import { PriceMatrix } from '../masters/PriceMatrix'
import { SocialMaster } from '../masters/SocialMaster'
import { Dashboard } from '../pages/dashboard'
import { InfluencerOtherDetails } from '../masters/InfluencerOtherDetails'
import { DocumentList } from '../AdminSettings/DocumentList'
import { InfluencerDetail } from '../Campaign/InfluencerDetail'
import { CampaignDashboard } from '../Campaign/CampaignDashboard'
import { InfluencerMaster } from '../masters/InfluencerMaster'
import { TransactionAdd } from '../Transaction/TransactionAdd'
import { TransactionAmount } from '../Transaction/TransactionAmount'
import { TransactionPayment } from '../Transaction/TransactionPayment'
import { TransactionDashboard } from '../Transaction/TransactionDashboard'
import { ActiveTicket } from '../Support/ActiveTicket'
import { Profile } from '../AdminSettings/Profile'
import { ProfileManagerSetting } from '../AdminSettings/ProfileManagerSetting'
import { FAQ } from '../AdminSettings/FAQ'
import { SchedulerCalendar } from '../Scheduler/SchedulerCalendar'
import { OnboardingMainPanel } from '../InfluencerOnboarding/OnboardingMainPanel'

import { CreateCampaign } from '../Campaign/CreateCampaign'

import { AudienceDetails } from '../Campaign/AudienceDetails'
import { OtherDetails } from '../Campaign/OtherDetails'

import { CampaignDetailById } from '../Campaign/CampaignDetailById'
import { ContentSubmission } from '../Campaign/ContentSubmission'
import { DashboardGeneral } from '../Dashboard/DashboardGeneral'
import { DashboardBrand } from '../Dashboard/DashboardBrand'
import { DashboardAgency } from '../Dashboard/DashboardAgency'
import { DashboardInfluencer } from '../Dashboard/DashboardInflencer'
import InfluencerProfile from '../masters/InfluencerProfile'

import { CampaignContract } from '../Campaign/CampaignContract'
import { IUserModel } from '../modules/auth/models/UserModel'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../setup/redux/RootReducer'
import Notifications from '../../_metronic/layout/components/Notifications'
import { Rating } from '../Performance/Rating'
import PerformanceDashboard from '../Performance/PerformanceDashboard'
import PerformanceInfluencer from '../Performance/PerformanceInfluencer'
import AgencyDash from '../Dashboard/Components/agencyDashboard/AgencyDash'

export function PrivateRoutes(props: any) {
  const campaignAccess = props.permissions.campaign
  const masterAccess = props.permissions.masters
  const schedulerAccess = props.permissions.scheduler
  const transactionAccess = props.permissions.transactions
  const adminSettingsAccess = props.permissions.adminSettings
  const supportAccess = props.permissions.support

 
  const user: IUserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as IUserModel
  return (
    <div>
      <Suspense fallback={<FallbackView />}>
        <Switch>
          {/* <Route path='/dashboard' component={Dashboard} /> */}
          <Redirect
            exact
            from='/'
            to={{
              pathname: '/dashboard/general',
              state: { type: 'Dashboard/general' },
            }}
          />
          {/* <Route path='/Masters' component={Masters} /> */}

          {/* Notifications */}
          <Route path='/notifications' component={Notifications} />

          {/* Dashboard routes starts here */}
          {user.userType === 'agency' && <Route path='/dashboard/general' component={AgencyDash} />}
          {user.userType === 'brand' && <Route path='/dashboard/general' component={AgencyDash} />}
          {user.userType === 'admin' && <Route path='/dashboard/general' component={DashboardGeneral} />}
          {/* <Route path='/dashboard/general' component={DashboardGeneral} /> */}
          <Route path='/dashboard/Brand' component={DashboardBrand} />
          <Route path='/dashboard/Agency' component={DashboardAgency} />
          <Route path='/dashboard/Influencer' component={DashboardInfluencer} />
          {/* Dashboard routes ends here */}

          {/* Admin routes begins here */}
          {(user.permissions.campaign[0] || user.permissions.admin) && (
            <Route path='/campaign/influencerOnborading/:id' component={OnboardingMainPanel} />
          )}
          {/* Admin routes ends here */}

          {/* Master routes begins here */}
          {(user.permissions.masters[0] || user.permissions.admin || user.userType === 'agency') && [
            <Route path='/companyDetails' component={CompanyDetails} />,
            <Route path='/agencyDetails' component={AgencyDetails} />,
            <Route path='/agencyBrandMiscellaneous' component={AgencyBrandMiscellaneous} />,
            <Route path='/success' component={AgencyBrandSuccess} />,
            <Route path='/categoryMaster' component={CategoryMaster} />,
          ]}
          {/* Master routes ends here */}

          {/* Campaign routes begins here */}
          {(user.permissions.campaign[1] || user.permissions.admin) && [
            <Route path='/campaign/details' component={CreateCampaign} />,
            <Route path='/campaign/audienceDetails' component={AudienceDetails} />,
            <Route path='/campaign/otherDetails' component={OtherDetails} />,
            <Route path='/campaign/influencerDetails' component={InfluencerDetail} />,
          ]}
          {(user.permissions.campaign[0] || user.permissions.admin) && [
            <Route path='/campaign/campaignDetailById' component={CampaignDetailById} />,
            <Route path='/campaign/dashboard' component={CampaignDashboard} />,
            <Route path='/campaign/contentSubmission' component={ContentSubmission} />,
            <Route path='/campaign/contract' component={CampaignContract} />,
          ]}
          {/* Campaign routes ends here */}

          {/* Master routes starts here */}
          {(user.permissions.masters[0] || user.permissions.admin || user.userType === 'agency') && [
            <Route path='/master/agencies' component={AgencyBrandTable} />,
            <Route path='/master/brands' component={AgencyBrandTable} />,
            <Route path='/master/category' component={CategoryMaster} />,
            <Route path='/master/brand' component={BrandMaster} />,
            <Route path='/master/socialmedia' component={SocialMaster} />,
            <Route path='/master/price-matrix' component={PriceMatrix} />,
            <Route path='/master/influencer' component={InfluencerMaster} />,
            <Route path='/master/influencerBasicInfo' component={InfluencerMasterAdd} />,
            <Route
              path='/master/influencerAdditionalInfo'
              component={InfluencerAdditionalInfo}
            />,
            <Route path='/master/socialMediaDetails' component={InfluencerOtherDetails} />,
            <Route path='/master/influencerProfile' component={InfluencerProfile} />,
            <Route path='/master/profile' component={AgencyViewProfile} />,
            <Route path='/master/billing' component={AgencyViewBilling} />,
            <Route path='/master/campaigns' component={AgencyViewCampaigns} />,
            <Route path='/master/influencers' component={AgencyViewInfluencer} />,
            <Route path='/master/brands-associated' component={AgencyViewBrands} />,
            <Route path='/master/questions' component={BrandQuestion} />,
          ]}
          {/* Master routes ends here */}

          {/* AdminSettings routes begins here */}
          {(user.permissions.adminSettings[0] || user.permissions.admin) && [
            <Route path='/adminSettings/profile' component={Profile} />,
            <Route path='/adminSettings/FAQ' component={FAQ} />,
          ]}
          {/* {(user.permissions.adminSettings[1] || user.permissions.admin) && [ */}
            <Route
              path='/adminSettings/profileManagerSetting'
              component={ProfileManagerSetting}
            />,
            <Route path='/adminSettings/documentList' component={DocumentList} />,
          {/* ]} */}
          {/* AdminSettings routes ends here */}

          {/* Scheduler routes begins here */}
          {(user.permissions.scheduler[0] || user.permissions.admin) && (
            <Route path='/scheduler/calendar' component={SchedulerCalendar} />
          )}
          {/* Scheduler routes ends here */}

          {/* Transaction routes begins here */}
          {(user.permissions.transactions[1] || user.permissions.admin) && [
            <Route path='/transaction/add' component={TransactionAdd} />,
            <Route path='/transaction/amount' component={TransactionAmount} />,
            <Route path='/transaction/payment' component={TransactionPayment} />,
          ]}
          {(user.permissions.transactions[0] || user.permissions.admin) && (
            <Route path='/transaction/dashboard' component={TransactionDashboard} />
          )}
          {/* Transaction routes ends here */}

          {/* Support routes begins here */}
          {(user.permissions.support[0] || user.permissions.admin) && (
            <Route path='/support/activeTicket' component={ActiveTicket} />
          )}
          {/* Support routes ends here */}
          {/* Performance routes starts here */}
          <Route path='/performance/rating' component={Rating} />
          <Route path='/performance/campaign-wise' component={PerformanceDashboard} />
          <Route path='/performance/influencer-wise' component={PerformanceInfluencer} />
          {/* Performance routes ends here */}

          {/* <Redirect to='error/404' /> */}
        </Switch>
      </Suspense>
    </div>
  )
}
