/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import {Link, Redirect, Route, Switch} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import Brand_Step1 from './brandAuth/Brand_Step1'
import Brand_Step2 from './brandAuth/Brand_Step2'
import Brand_Step3 from './brandAuth/Brand_Step3'
import Brand_Step4 from './brandAuth/Brand_Step4'
import {ForgotPassword} from './components/ForgotPassword'
import {Login} from './components/Login'
import {Registration} from './components/Registration'
import {SetPassword} from './components/SetPassword'
import Selection from './Selection'
import SetupAccount from './SetupAccount'

export function AuthPage() {
  const [brand, setBrand] = useState(false)

  useEffect(() => {
    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [])
  useEffect(() => {
    if (window.location.href.split('/').includes('brand')) {
      setBrand(true)
    }
  }, [])

  return (
    <>
      <div className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'>
        {/* begin::Content */}
        <div className='d-flex flex-left flex-column flex-column-fluid p-10 '>
          {/* begin::Logo */}
          <Link style={{marginLeft: '5%'}} to='/auth/admin/login' className=' mb-7'>
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/logos/logo__name__color.png')}
              className='h-45px'
            />
          </Link>
          {/* end::Logo */}
          {/* begin::Wrapper */}
          <div
            style={{marginLeft: '15%', width: '40%', height: '100%', alignItems: 'center'}}
            className='bg-white rounded d-flex'
          >
            <Switch>
              <Route path='/auth/admin/login' component={Login} />
              <Route path='/auth/registration' component={Registration} />
              <Route path='/auth/forgot-password' component={ForgotPassword} />
              <Route path='/auth/set-password' component={SetPassword} />
              <Route path='/auth/register/step1' component={Brand_Step1} />
              <Route path='/auth/register/step2' component={Brand_Step2} />
              <Route path='/auth/register/step3' component={Brand_Step3} />
              <Route path='/auth/register/step4' component={Brand_Step4} />
              <Route path='/auth/register/createAccount' component={SetupAccount} />
              <Route path='/auth/register' component={Selection} />

              <Redirect from='/auth' exact={true} to='/auth/admin/login' />
              <Redirect to='/auth/admin/login' />
            </Switch>
          </div>
          <span
            style={{
              position: 'absolute',
              top: '0',
              right: '0',
              height: '100%',
              width: '44%',
              overflow: 'hidden',
              //backgroundImage: `url(${toAbsoluteUrl('/media/logos/LoginPNG.png')})`,
            }}
          >
            <img
              style={{width: '100%', height: '100%'}}
              src={
                brand
                  ? toAbsoluteUrl('/media/logos/LoginBrandPng.png')
                  : toAbsoluteUrl('/media/logos/LoginPNG.png')
              }
            ></img>
          </span>
        </div>
      </div>
    </>
  )
}
