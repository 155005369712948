import React from 'react'
import "../../Dashboard.scss"
import { NoData } from '../NoData'
import {influencer} from "./DummyData.js"

const TopInstagram = (props:any) => {
  return (
    <div className="Top-Influencer-Box">
        
            <h2>Top Instagram Influencers</h2>

            {
             props.data?.length === 0 ? (
              <NoData />
             ) :
             (
   <div className='table-responsive'>
          <table className='table table-row-dashed table-row align-middle gs-0 gy-4 m-0'>
            <thead>
              <tr className='fw-bolder text-muted row-card'>
                <th style={{width: '200px'}}>
                  <span className='d-flex'>Influencers</span>
                </th>
                <th style={{width: '90px'}}>
                  <span className='d-flex header-text'>Followers</span>
                </th>
                <th className='text-align-right' style={{width: '100px'}}>
                  <span className='d-flex header-text' style={{width:"60px"}}>Eng Rate</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {
                influencer.map((item) => (
                  <tr key={item.id} className='User_row'>
                  <td className='fw-bolder name'>
                    <span  className="cursor-pointer">
                    <img
                      className='Davatar'
                      src={item.dp}
                      alt=''
                    />
                    {item.name}
                    </span>
                  </td>
                  <td className='fw-normal instra'>{item.follower}</td>
                  <td className='fw-bolder text-align-right'>{item.endRate}</td>
                </tr>
                ))
              }
               
              
            </tbody>
          </table>
        </div> 

             )
            }
          

    </div>
  )
}

export default TopInstagram