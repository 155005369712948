import React, {FC, useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap-v5'
import {useDispatch} from 'react-redux'
import {MenuInner} from './MenuInner'
import * as auth from '../../../../app/modules/auth'

const Header: FC = () => {
  const [show, setShow] = useState(false); 

  const dispatch = useDispatch()
  const logout = () => {
    dispatch(auth.actions.logout())
    localStorage.removeItem("session")
  }
  const handleClose = () => setShow(false);

  useEffect(() => {
    
    const session = localStorage.getItem("session")
    if (session && session === "false"){
      setShow(true)
    }
  }, [])

  return (
    <div
      className='header-menu align-items-stretch'
      data-kt-drawer='true'
      data-kt-drawer-name='header-menu'
      data-kt-drawer-activate='{default: true, lg: false}'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'200px', '300px': '250px'}"
      data-kt-drawer-direction='end'
      data-kt-drawer-toggle='#kt_header_menu_mobile_toggle'
      data-kt-swapper='true'
      data-kt-swapper-mode='prepend'
      data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav'}"
    >
      <div
        className='menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch'
        id='#kt_header_menu'
        data-kt-menu='true'
      >
        <MenuInner />
        <Modal
          show={show}
          onHide={handleClose}
          backdrop='static'
          keyboard={false}
          aria-labelledby='contained-modal-title-vcenter'
          centered
        >
          <Modal.Header>
            <Modal.Title>Session Timeout</Modal.Title>
          </Modal.Header>
          <Modal.Body>You have been logged out</Modal.Body>
          <Modal.Footer>
            <button
              className='btn btn-primary fs-6 dialogButton ms-2'
              onClick={logout}
            >
              Logout
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  )
}

export {Header}
