import {OpenInNew} from '@material-ui/icons'
import React from 'react'
import './calendar.css'
import Moment from 'moment'

export const CalendarEvent = ({event}: any) => {
  const description = event.extendedProps.description
  return (
    <div className='flex event-container'>
      <div className='flex event-left'>
        <img src={description.brandLogo} alt='' />
        <div>
          <h2>{event.title}</h2>
          <p>{description.brandName}</p>
        </div>
      </div>
      <div className='flex event-right'>
        <div className='event-item'>
          <p>Influencers</p>
          <h6>{description.noOfInfluencers}</h6>
        </div>
        <div className='event-item'>
          <p>Agency</p>
          <h6>{description.agencyName}</h6>
        </div>
        <div className='event-item'>
          <p>Live</p>
          <h6>{Moment(description.liveDate).format('YYYY-MM-DD')}</h6>
        </div>
        <div className='event-item'>
          <p>Time</p>
          <h6>{Moment(description.liveDate.substring(0, 19)).format('h:mm a')}</h6>
        </div>
        <div className='event-item'>
          <OpenInNew />
        </div>
      </div>
    </div>
  )
}
