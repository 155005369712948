import {ProfileTable} from './ProfileTable'
import '../masters/basic.css'

export function Profile() {
  return (
    <div className='w-100 flex'>
      <div className='m20 rounded bg-white w-75'>
        <ProfileTable></ProfileTable>
      </div>
    </div>
  )
}
