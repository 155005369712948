import React from 'react'
import { useHistory } from 'react-router-dom';
import '../Dashboard.scss'
import {NoData} from './NoData'

export function Agencies(props: any) {
  const rows: any[] = [1, 2, 3, 4, 5]
  const history = useHistory();
  const viewBrandOrAgency = (id: string) => {
    history.push({
      pathname: '/master/profile',
      state: {id: id, pageType: "Agency", type: "Masters", breadcrums: ["Agency Master", "View Agency"]},
    })
  }

  return (
    <div className='Dcard Dagencies h-400px'>
      <h2 className='Dheading'>Top Agencies</h2>
      {props.data?.length === 0 ? (
        <NoData />
      ) : (
        <div className='table-responsive'>
          <table className='table table-row-dashed table-row align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bolder text-muted'>
                <th style={{width: '600px'}}>
                  <span className='d-flex'>Agency</span>
                </th>
                <th>
                  <span className='d-flex'>Campaigns</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {props.data?.map((item, index) => (
                <tr key={index} className=''>
                  <td className='fw-bolder cursor-pointer'>
                    <span onClick={() => viewBrandOrAgency(item.agency._id)} className="cursor-pointer">
                      <img
                        className='Davatar'
                        src={item.agency.avatar}
                        alt=''
                    />
                      {item.agency?.agencyName}
                    </span>
                  </td>
                  <td className='fw-normal'>{item.campaignCount}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  )
}
