/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import Moment from 'moment'
import { FC, useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import '../../../app/masters/basic.css'
import { IUserModel } from '../../../app/modules/auth/models/UserModel'
import { RootState } from '../../../setup'
import httpService from '../../../setup/http/httpServices'
import { KTSVG, MessageModel, toAbsoluteUrl } from '../../helpers'

type Props = {
  isDrawer?: boolean
  influencer?: any
  groupId?: string
  textsReceived?: number
}

var bufferMessages: any[] = [];
// var bufferMessages = []

const ChatInner: FC<Props> = ({ isDrawer = false, influencer = {}, groupId = '', textsReceived = 0 }) => {
  const [chatUpdateFlag, toggleChatUpdateFlat] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')
  const [messages, setMessages] = useState<MessageModel[]>([])
  const history: any = useHistory()
  const [intervalId, setIntervalId] = useState<any>(0)
  const [firstMessageSent, setFirstMessageSent] = useState(false);


  const chatData = function () {
    if (history.location.state?.status === 'Communicate' && groupId) {
      httpService.getData(
        '/chat?groupId=' + groupId + '&page=1&limit=50',
        (response: any) => {
          bufferMessages = []
          response.data.data.chats.forEach((element) => {
            const message: MessageModel = {
              user: element[element.sender],
              type: element.sender === 'campaign' ? 'out' : 'in',
              text: element?.message,
              time: element.updatedAt,
            }

            bufferMessages.push(message)
          })
          bufferMessages = bufferMessages.reverse()
          setMessages(bufferMessages)
          setTimeout(() => {
            if (document.querySelector('#kt_drawer_chat_messenger_body > div') != null) {
              document
                .querySelector('#kt_drawer_chat_messenger_body > div')
                .scrollTo(
                  0,
                  document.querySelector('#kt_drawer_chat_messenger_body > div').scrollHeight
                )
            }
          }, 100)
        },
        (error: any) => { }
      )
    }
  }

  useEffect(() => {
    setMessages([])
    if (intervalId) {
      clearInterval(intervalId)
    }
    let interval = setInterval(() => {
      chatData()
    }, 2000)
    setIntervalId(interval)
    return () => {
      clearInterval(interval);
    }
  }, [groupId])
  const user: IUserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as IUserModel
  const sendMessage = () => {
    const newMessage: MessageModel = {
      user: user.userId,
      type: 'out',
      text: message,
      time: 'Just now',
    }

    if (!firstMessageSent) {
      setFirstMessageSent(true);
    }

    bufferMessages.push(newMessage)
    setMessages(bufferMessages)
    toggleChatUpdateFlat(!chatUpdateFlag)
    setMessage('')
    httpService.postData(
      '/chat',
      {
        campaign: history.location.state._id,
        influencer: influencer._id,
        sender: 'campaign',
        message: message
      },
      (response: any) => {
        chatData()
      },
      (error: any) => {
        console.log(error)
      }
    )
    toggleChatUpdateFlat((flag) => !flag)
  }

  const onEnterPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault()
      sendMessage()
    }
  }

  return (
    <div
      style={{ background: "#F9FAFC" }}
      className='card-body overflow-auto'
      id={isDrawer ? 'kt_drawer_chat_messenger_body' : 'kt_chat_messenger_body'}
    >
      <div
        style={{ height: '85%' }}
        className='overflow-auto pe-5'
        data-kt-element='messages'
        data-kt-scroll='true'
        data-kt-scroll-activate='{default: false, lg: true}'
        data-kt-scroll-max-height='auto'
        data-kt-scroll-dependencies={
          isDrawer
            ? '#kt_drawer_chat_messenger_header, #kt_drawer_chat_messenger_footer'
            : '#kt_header, #kt_toolbar, #kt_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer'
        }
        data-kt-scroll-wrappers={
          isDrawer ? '#kt_drawer_chat_messenger_body' : '#kt_content, #kt_chat_messenger_body'
        }
        data-kt-scroll-offset={isDrawer ? '0px' : '-2px'}
      >
        {messages.map((message, index) => {
          // const state = message.type === 'in' ? 'info' : 'primary'
          const state = message.type === 'in' ? '#fff' : '#EEEBF5'
          const border = message.type === 'in' ? '12px 12px 12px 0px' : '12px 12px 0px 12px'


          const templateAttr = {}
          if (message.template) {
            Object.defineProperty(templateAttr, 'data-kt-element', {
              value: `template-${message.type}`,
            })
          }
          const contentClass = `${isDrawer ? '' : 'd-flex'} justify-content-${message.type === 'in' ? 'start' : 'end'
            } `
          return (
            <div

              key={`message${index}`}
              className={clsx('d-flex', contentClass,  { 'd-none': message.template })}
              {...templateAttr}
            >
              <div

                className={clsx(
                  'd-flex flex-column align-items',
                  `align-items-${message.type === 'in' ? 'start' : 'end'}`
                )}
              >
                <div
                  style={{ borderRadius: `${border}`, background: `${state}` }}
                  className={clsx(
                    'p-3 ',
                    // `bg-light-${state}`,
                    'text-dark fw-bold mw-lg-400px',
                    // `text-${message.type === 'in' ? 'start' : 'end'}`
                  )}
                  data-kt-element='message-text'
                  dangerouslySetInnerHTML={{ __html: message.text }}
                ></div>
                <div className='d-flex align-items-center mb-2'>
                  <div className='ms-3'>
                    <span className='text-muted fs-7 mb-1'>
                      {Moment(message.time).format('DD MMM YYYY | HH:mm A')}
                    </span>
                  </div>
                  <div className='ms-auto'>
                    <span className='badge bg-primary'>{textsReceived}</span>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
        {messages.length === 0 && (
          <div className='h-100 d-flex align-items-end'>
                  {!firstMessageSent && (

            <div>
              <div className='d-flex'>
                <span className='w-30px h-30px flex bg-danger bg-opacity-25 rounded-circle'>
                  <KTSVG
                    className='svg-icon svg-icon-danger'
                    path={toAbsoluteUrl('/media/icons/Custom/I.svg')}
                  />
                </span>
                <h3 className='text-danger m-0 mt-2 ms-3'>Important</h3>
              </div>
              <div className='m20 mb-5'>
                <ul>
                  <li>
                    Do not communicate with influencers on any other platform or channel except the
                    Sehaye app.
                  </li>
                  <li>Do not share your contact information with influencers.</li>
                  <li>Do not request any contact information from influencers.</li>
                </ul>
              </div>
            </div>
                  )}
          </div>
        )}
      </div>
      {/* {influencer.disableChat && */}
      <div
        className='card-footer p-0'
        id={isDrawer ? 'kt_drawer_chat_messenger_footer' : 'kt_chat_messenger_footer'}
      >
        <div className='d-flex flex-stack'>
          <div className='d-flex align-items-center me-2 w-100'>
            <textarea
              className='form-control form-control-flush mb-3 w-100 pt-7'
              rows={2}
              data-kt-element='input'
              placeholder='Type your message here'
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              onKeyDown={onEnterPress}
            ></textarea>
          </div>
          <button
            style={{ background: "#E72281" }}
            className='btn  rounded-circle w-40px h-40px ps-3 pt-2'
            type='button'
            data-kt-element='send'
            onClick={sendMessage}
          >
            {/* <KTSVG
                className='svg-icon-2'
                path={toAbsoluteUrl('/media/svg/misc/send-Icon.svg')}
              /> */}
            <img src="/media/svg/misc/send-Icon.svg" alt="" />
          </button>
        </div>
      </div>
      {/* } */}
    </div>
  )
}

export { ChatInner }
