import {SearchOutlined} from '@ant-design/icons'
import {Input, message} from 'antd'
import clsx from 'clsx'
import {useFormik} from 'formik'
import Moment from 'moment'
import {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap-v5'
import {shallowEqual, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import Select from 'react-select'
import * as Yup from 'yup'
import httpService from '../../setup/http/httpServices'
import {RootState} from '../../setup/redux/RootReducer'
import {KTSVG, toAbsoluteUrl} from '../../_metronic/helpers'
import '../masters/basic.css'
import {IUserModel} from '../modules/auth/models/UserModel'

var initialValues = {
  brand: '',
  issueCause: '',
  issue: '',
  issueDescription: '',
}

const registrationSchema = Yup.object().shape({
  issue: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Issue is required'),
  issueDescription: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(500, 'Maximum 500 characters')
    .required('Issue Description is required'),
  issueCause: Yup.string().required('Issue Location is required'),
})

export function ActiveTicket(props: any) {
  const user: IUserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as IUserModel
  const access = user.permissions.support[1] || user.permissions.admin

  const history: any = useHistory()
  const [desc, setDesc] = useState({Brand: 'Coca Cola'})
  const [show, setShow] = useState(false)
  const [status, setStatus] = useState(true)

  const [loading, setLoading] = useState(false)
  const [companyDetails, setCompanyDetails] = useState(initialValues)
  const [category, setCategory] = useState(history.location.state.status || 'unresolved')

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const [brand, setBrand] = useState({value: 'default', label: 'brand'})
  const [brands, setBrands] = useState([])
  const [tickets, setTickets] = useState([])
  const [selectedTicket, setSelectedTicket] = useState('')
  const [selectedTicketData, setSelectedTicketData] = useState<any>({})
  const [ticketParams, setTicketParams] = useState({
    limit: 0,
    page: 1,
    campaigns: [],
    search: '',
    total: 0,
  })
  useEffect(
    function () {
      getAllMasters()
      getTicketData(category)
    },
    [selectedTicket, show]
  )
  useEffect(
    function () {
      getTicketData(category)
    },
    [ticketParams.search]
  )
  const getAllMasters = () => {
    httpService.getData(
      '/brand-master',
      (response: any) => {
        response.data.data.brands.map((x: any) => {
          x['value'] = x._id
          x['label'] = x.brandName
          return x
        })
        let array = []
        response.data.data.brands.map((brand) =>
          array.push({value: brand._id, label: brand.brandDetails.brandName})
        )
        setBrands(array)
        if (user.userType === 'brand') {
          let currentBrand = response.data.data.brands.find((x) => x._id === user.brandId)
          const currentBrandName = currentBrand.brandDetails.brandName

          setBrand({value: user.brandId, label: currentBrandName})
          formik.setFieldValue('brand', user.brandId)
        } else {
          formik.setFieldValue('brand', user.userId)
        }
      },
      (error: any) => {
        message.error(error?.message)
      }
    )
  }
  const [total, setTotal] = useState(0)
  const getTicketData = (category?: string) => {
    httpService.getData(
      '/ticket?limit=0&&search=' + ticketParams.search + '&&status=' + category,
      (response: any) => {
        setTickets(response.data.data.tickets)
        setTotal(response.data.data.total)
        setStatus(false)
        // setTicketParams({ ...ticketParams, ...{ total: response.data.data.tickets.length } })
      },
      (error: any) => {
        message.error(error?.message)
        setStatus(false)
      }
    )
  }
  const formik = useFormik({
    initialValues: companyDetails || initialValues,
    validationSchema: registrationSchema,
    enableReinitialize: true,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setTimeout(() => {
        httpService.postData(
          '/ticket',
          values,
          (response: any) => {
            setShow(false)
            message.success('Ticket added successfully.')
            formik.resetForm()
          },
          (error: any) => {
            message.error(error.message)
          }
        )
        setCompanyDetails(values)
        setLoading(false)
        setSubmitting(false)
      }, 1000)
    },
  })

  return (
    <div>
      <div className='flex justify-content-start bg-white h-75px w-100 border'>
        <ul className='col nav nav-pills ms-10'>
          <li className='nav-item '>
            <span
              style={{borderRadius: '49px'}}
              onClick={() => {
                setCategory('unresolved')
                setTotal(0)
                setTimeout(() => {
                  getTicketData('unresolved')
                  setSelectedTicket('')
                  setSelectedTicketData({})
                }, 100)
              }}
              className={
                (category === 'unresolved' ? 'active text-white' : 'text-gray-400') +
                ' nav-link d-flex w-auto h-40px flex fw-bold cursor-pointer'
              }
            >
              Active 🛠️ {category === 'unresolved' && '(' + total + ')'}
            </span>
          </li>
          <li className='nav-item '>
            <span
              style={{borderRadius: '49px'}}
              onClick={() => {
                setCategory('underReview')
                setTotal(0)
                setTimeout(() => {
                  getTicketData('underReview')
                  setSelectedTicket('')
                  setSelectedTicketData({})
                }, 100)
              }}
              className={
                (category === 'underReview' ? 'active text-white' : 'text-gray-400') +
                ' nav-link d-flex w-auto h-40px flex fw-bold cursor-pointer'
              }
            >
              In Review 🔎 {category === 'underReview' && '(' + total + ')'}
            </span>
          </li>
          <li className='nav-item '>
            <span
              style={{borderRadius: '49px'}}
              onClick={() => {
                setCategory('resolved')
                setTotal(0)
                setTimeout(() => {
                  getTicketData('resolved')
                  setSelectedTicket('')
                  setSelectedTicketData({})
                }, 100)
              }}
              className={
                (category === 'resolved' ? 'active text-white' : 'text-gray-400') +
                ' nav-link d-flex w-auto h-40px flex fw-bold cursor-pointer'
              }
            >
              Resolved 💯 {category === 'resolved' && '(' + total + ')'}
            </span>
          </li>
        </ul>
        <div className='col ms-10 me-5 TableHeaderSearch d-flex justify-content-end align-items-center'>
          <link
            href='https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css'
            rel='stylesheet'
          />
          <Input
            placeholder='Search campaigns'
            style={{width: '250px', height: '40px'}}
            className='form-control-solid border rounded'
            onChange={(e: any) => {
              setTimeout(() => {
                setTicketParams({...ticketParams, ...{search: e.target.value}})
                // getTicketData(category);
              }, 100)
            }}
            prefix={
              <>
                <SearchOutlined />
              </>
            }
          />
          {access && (
            <button
              style={{ height: '40px', borderRadius: '4px'}}
              className='btn d-flex flex ms-5 fs-6 create-btn'
              onClick={(e: any) => {
                formik.setValues(initialValues)
                handleShow()
              }}
            >
             + Create New Ticket 📝
            </button>
          )}
        </div>
      </div>
      {tickets.length > 0 && (
        <div className='d-flex'>
          <div style={{overflowY: 'scroll', height: '85vh'}} className='col-5'>
            <h2 className='mt-6 ms-6'>
              {total} {category === 'unresolved' && 'Active'}
              {category === 'underReview' && 'Under Review'} {category === 'resolved' && 'Resolved'}{' '}
              Tickets
            </h2>
            {tickets.map((ticket: any) => (
              <div className='TicketDiv'>
                <div className='d-flex'>
                  <div className='col d-flex'>
                    <span className='text-dark fw-bold bg-gray-100 fs-7 rounded p-2 pt-1 pb-1 me-3'>
                      {ticket.issueCause}
                    </span>
                  </div>
                  <div className='col flex justify-content-end'>
                    <p className='m-0 '>{Moment(ticket.updatedAt).format('DD MMM YYYY')}</p>
                    <ul className='text-dark fs-7 mb-0'>
                      <li>{Moment(ticket.updatedAt).format('HH : MM')} </li>
                    </ul>
                  </div>
                </div>
                <div className='mt-4'>
                  <h6>{ticket.issue}</h6>
                  <p className='m-0'>{ticket.issueDescription}</p>
                </div>
                <div className='d-flex mt-4'>
                  <div className='col d-flex'>
                    <div className='symbol symbol-35px symbol-circle me-5'>
                      {!ticket?.brand?.avatar ? (
                        <img src={toAbsoluteUrl('/media/logos/CocaCola.png')} alt='' />
                      ) : (
                        <img src={ticket?.brand?.avatar} alt='' />
                      )}
                    </div>
                    <div className='flex justify-content-start'>
                      <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
                        {ticket?.brand?.brandName}
                      </a>
                    </div>
                  </div>
                  {access && (
                    <div className='col flex justify-content-end'>
                      <span
                        className='svg-icon flex svg-icon-dark bg-primary bg-opacity-5 w-30px h-30px rounded me-2 pointer'
                        onClick={() => {
                          httpService.getData(
                            '/ticket/' + ticket._id,
                            (response: any) => {
                              setSelectedTicketData(response.data.data.ticket)
                              setSelectedTicket(ticket._id)
                            },
                            (error: any) => {
                              message.error(error.message)
                            }
                          )
                        }}
                      >
                        <KTSVG
                          className='svg-icon-3'
                          path={toAbsoluteUrl('/media/icons/Custom/eye.svg')}
                        ></KTSVG>
                      </span>
                      <span
                        className='svg-icon flex svg-icon-danger bg-danger bg-opacity-5 w-30px h-30px rounded pointer me-2'
                        onClick={() => {
                          httpService.deleteData(
                            '/ticket/' + ticket._id,
                            (response: any) => {
                              setSelectedTicketData({})
                              setSelectedTicket('')
                              getAllMasters()
                            },
                            (error: any) => {
                              message.error(error.message)
                            }
                          )
                        }}
                      >
                        <KTSVG
                          className='svg-icon-3'
                          path={toAbsoluteUrl('/media/icons/Custom/Dustbin.svg')}
                        ></KTSVG>
                      </span>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
          {selectedTicket && (
            <div className='col'>
              <div className='TicketDesc position-relative'>
                <div className='d-flex'>
                  <div className='col d-flex'>
                    <div className='symbol symbol-35px symbol-circle me-5'>
                      {!selectedTicketData?.brand?.avatar ? (
                        <img src={toAbsoluteUrl('/media/logos/CocaCola.png')} alt='' />
                      ) : (
                        <img src={selectedTicketData?.brand?.avatar} alt='' />
                      )}
                    </div>
                    <div className='flex justify-content-start flex-column'>
                      <div className='w-100'>
                        <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
                          {selectedTicketData?.brand?.brandName}
                        </a>
                      </div>
                      <span className='text-muted d-flex fw-bold text-muted d-block fs-7'>
                        <p className='m-0 '>
                          {Moment(selectedTicketData?.updatedAt).format('DD MMM YYYY')}
                        </p>
                        <ul className=' fs-7 mb-0'>
                          <li>{Moment(selectedTicketData?.updatedAt).format('HH:MM')}</li>
                        </ul>
                      </span>
                    </div>
                  </div>
                  <div className='col flex justify-content-end'>
                    {/* <KTSVG
                    className='svg-icon svg-icon-dark svg-icon-2x mt-2 me-7'
                    path={toAbsoluteUrl('/media/icons/Custom/Chat.svg')}
                  ></KTSVG> */}
                    <span
                      className='svg-icon flex svg-icon-danger bg-danger bg-opacity-5 w-30px h-30px rounded pointer me-2'
                      onClick={() => {
                        httpService.deleteData(
                          '/ticket/' + selectedTicket,
                          (response: any) => {
                            setSelectedTicketData({})
                            setSelectedTicket('')
                            getAllMasters()
                          },
                          (error: any) => {
                            message.error(error.message)
                          }
                        )
                      }}
                    >
                      <KTSVG
                        className='svg-icon-3'
                        path={toAbsoluteUrl('/media/icons/Custom/Dustbin.svg')}
                      ></KTSVG>
                    </span>
                    {/* <KTSVG
                    className='svg-icon svg-icon-dark '
                    path={toAbsoluteUrl('/media/icons/Custom/Menu.svg')}
                  ></KTSVG> */}
                  </div>
                </div>
                <div className='m24'>
                  <h6 className='m-0'>Issues faced at</h6>
                  <p className='m-0 fs-6'>{selectedTicketData?.issueCause}</p>
                </div>
                <div className='m24'>
                  <h6 className='m-0'>{selectedTicketData?.issue}</h6>
                  <p className='m-0 fs-6'>{selectedTicketData?.issueDescription}</p>
                </div>
                <div className='m24 d-flex'>
                  <span className='text-dark fw-bold bg-gray-100 fs-7 rounded p-2 pt-1 pb-1 me-3'>
                    {selectedTicketData.issueCause}
                  </span>
                  {/* <span className='text-dark fw-bold bg-gray-100 fs-7 rounded p-2 pt-1 pb-1'>
                Payment
              </span> */}
                </div>
                <div style={{width: '93%'}} className='d-flex position-absolute mb-8 bottom-0'>
                  <button
                    className='btn fw-bolder btn-gray-200 w-100'
                    onClick={() => {
                      setSelectedTicketData({})
                      setSelectedTicket('')
                    }}
                  >
                    Go Back
                  </button>
                  {category !== 'resolved' && (
                    <button
                      type='submit'
                      className='btn btn-success w-100 ms-5'
                      onClick={(e: any) => {
                        httpService.patchData(
                          '/ticket/' + selectedTicket,
                          {
                            status: 'Resolved',
                          },
                          (response: any) => {
                            message.success('Ticket resolved successfully')
                            setSelectedTicket('')
                          },
                          (error: any) => {
                            message.error(error.message)
                          }
                        )
                      }}
                    >
                      Mark as Resolved
                    </button>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {tickets.length == 0 && !status && (
        <div className='m-20'>
          <div className='flex'>
            <img className='ms-20' src={toAbsoluteUrl('/media/logos/TicketVector.png')}></img>
          </div>
          <div className='flex'>
            <div>
              <h2 className='mb-0 m24 flex'>No Tickets Listed</h2>
            </div>
          </div>
          <div className='flex'>
            {access && (
              <button
                className='btn m24 TableButton create-btn text-white'
                onClick={(e: any) => {
                  formik.setValues(initialValues)
                  handleShow()
                }}
              >
                + Create a New Ticket
              </button>
            )}
          </div>
        </div>
      )}
      {tickets.length == 0 && status && (
        <div className='m-20'>
          <div className='flex'>
            <span className='svg-icon svg-icon-5x flex svg-icon-primary me-3 ms-8'>
              <KTSVG path={toAbsoluteUrl('/media/icons/Custom/Loading.svg')} />
            </span>
          </div>
          <div className='flex'>
            <div>
              <h5 className='mb-0 mt-3 flex'>Fetching Data</h5>
            </div>
          </div>
        </div>
      )}
      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby='contained-modal-title-vcenter '
        centered
      >
        <Modal.Body className='p-0 '>
          <div style={{width: '686px'}} className='bg-white rounded'>
            <div className=' w-100 bg-white rounded p-10'>
              <h3 className='m-0'>Create New Ticket</h3>
              <form noValidate id='kt_login_signup_form' onSubmit={formik.handleSubmit}>
                <div className='col m24'>
                  <h6 className='fw-bold d-flex'>Brand</h6>
                  <Select
                    options={brands}
                    isDisabled={user.userType === 'brand'}
                    value={brand}
                    isMulti={false}
                    name='brand'
                    placeholder='Brands'
                    styles={{
                      menuList: (provided, state) => ({
                        ...provided,
                        height: '150px',
                      }),
                    }}
                    className={clsx(
                      'text-gray-500 form-control form-control-solid bg-gray-100 p-0 border-0 categorySelect'
                    )}
                    onChange={(e: any) => {
                      formik.setFieldValue('brand', e.value)
                      setBrand(e)
                    }}
                  />
                </div>
                <div className='col m24'>
                  <h6 className='fw-bold required'>Where do you come across this issue?</h6>
                  <select
                    {...formik.getFieldProps('issueCause')}
                    className={clsx(
                      'form-select text-gray-500 border-0 bg-gray-100',
                      {
                        'is-invalid': formik.touched.issueCause && formik.errors.issueCause,
                      },
                      {
                        'is-valid': formik.touched.issueCause && !formik.errors.issueCause,
                      }
                    )}
                  >
                    <option value=''>Location</option>
                    <option value='Tansaction'>Tansaction</option>
                    <option value='Master'>Master</option>
                    <option value='Campaign'>Campaign</option>
                  </select>
                  {formik.touched.issueCause && formik.errors.issueCause && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span className='text-danger' role='alert'>
                          {formik.errors.issueCause}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
                <div className='col m24'>
                  <h6 className='fw-bold required'>What is the Issue?</h6>
                  <input
                    type='text'
                    placeholder='What is the Issue?'
                    {...formik.getFieldProps('issue')}
                    className={clsx(
                      'form-control form-control-solid bg-gray-100',
                      {
                        'is-invalid': formik.touched.issue && formik.errors.issue,
                      },
                      {
                        'is-valid': formik.touched.issue && !formik.errors.issue,
                      }
                    )}
                  />
                  {formik.touched.issue && formik.errors.issue && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span className='text-danger' role='alert'>
                          {formik.errors.issue}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
                <div className=' m24'>
                  <h6 className='fw-bold required'>Tell us a little more about this issue.</h6>
                  <div className='col '>
                    <textarea
                      placeholder='Description'
                      {...formik.getFieldProps('issueDescription')}
                      className={clsx(
                        'form-control form-control-solid bg-gray-100 h-100px',
                        {
                          'is-invalid':
                            formik.touched.issueDescription && formik.errors.issueDescription,
                        },
                        {
                          'is-valid':
                            formik.touched.issueDescription && !formik.errors.issueDescription,
                        }
                      )}
                    />
                    {formik.touched.issueDescription && formik.errors.issueDescription && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span className='text-danger' role='alert'>
                            {formik.errors.issueDescription}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='d-flex m24 w-100'>
                  <button
                    type='button'
                    className='btn fw-bolder btn-gray-200 w-100 h-50px'
                    onClick={(e) => {
                      setShow(false)
                    }}
                  >
                    Cancel
                  </button>

                  <button
                    type='submit'
                    id='kt_sign_up_submit'
                    className='btn create-btn ms-5 w-100 h-50px'
                    disabled={formik.isSubmitting || !formik.isValid}
                  >
                    {!loading && <span className='indicator-label'>Create Ticket</span>}
                    {loading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
