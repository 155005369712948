import clsx from 'clsx'
import {useFormik} from 'formik'
import React, {useEffect, useState} from 'react'
import {Link, useHistory} from 'react-router-dom'
import * as Yup from 'yup'
import {toAbsoluteUrl} from '../../_metronic/helpers'
import './basic.css'
import {BrandAdminLeftBreadcrum} from './BrandAgencyLeftBreadcrum'
import {ImageCropper} from './ImageCropper'
import loc from '../data/Location.json'
import Select from 'react-select'
import httpService from '../../setup/http/httpServices'
import axios from 'axios'

var initialValues = {
  brandName: '',
  industry: '',
  description: '',
  contactPerson: '',
  email: '',
  phoneNumber: '',
  website: '',
  city: '',
  state: '',
  pinCode: '',
  logoUrl: new File([], ''),
  sameAsBefore: false,
}

const registrationSchema = Yup.object().shape({
  brandName: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Name is required')
    .matches(/^(?=.*[A-Za-z]).{3,50}$/, 'Should not contain just numbers'),

  industry: Yup.string().required('Industry is required'),
  // logoUrl: Yup.object().required('Logo URL is required'),
  description: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(500, 'Maximum 500 characters')
    .required('Description is required'),
  contactPerson: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Contact Person is required')
    .matches(/^(?=[^A-Za-z\n]*[A-Za-z])[A-Za-z._-]*[A-Za-z0-9._ -]*$/,"Must be alphanumeric"), 
  email: Yup.string()
    .email('Wrong format of Email')
    .matches(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      'Wrong format of Email'
    )
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  phoneNumber: Yup.string()
    .min(10, 'Minimum 10 digits')
    .max(12, 'Maximum 13 digits with country code')
    .required('Phone Number is required'),
  website: Yup.string()
    .matches(
      /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
      'Wrong format of URL'
    )
    .min(3, 'Minimum 3 symbols')
    .max(500, 'Maximum 500 symbols')
    .required('Website is required'),
  pinCode: Yup.string().min(6, 'Minimum 6 digit').max(6, 'Maximum 6 digit'),
})

export function AgencyDetails() {
  const history: any = useHistory()
  var companyDetails = history.location.state?.companyDetails
  const [bgImage, setBgImage] = useState(toAbsoluteUrl('/media/logos/demoImage.svg'))
  const [toggleCrop, setToggleCrop] = useState(false)
  const [isBrand, setIsBrand] = useState(history.location.state?.type === 'Brand')
  const [loading, setLoading] = useState(false)
  const [agencyDetails, setAgencyDetails] = useState(initialValues)
  const [selectedFile, setSelectedFile] = useState(new File([], ''))
  const [hasError, setHasError] = useState(false)
  const [s, setS] = useState('Andaman and Nicobar Islands')
  const [st, setSt] = useState(['Rajasthan'])
  const [ct, setCt] = useState([])
  const [city, setCity] = useState({})
  const [state, setState] = useState({})
  const [showRemove, setShowRemove] = useState(true)

  const formik = useFormik({
    initialValues: agencyDetails || initialValues,
    validationSchema: registrationSchema,
    enableReinitialize: true,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      
      if (selectedFile.size === 0 && !bgImage.includes(".amazonaws.com")) {
        setHasError(true)
        setSubmitting(false)
        return
      } else {
        values.logoUrl = selectedFile
    
      }
      setLoading(true)
      setTimeout(() => {
        try {
          setHasError(true)
          setAgencyDetails(values)
          setLoading(false)
          setSubmitting(false)
          history.push({
            pathname: '/agencyBrandMiscellaneous',
            state: {
              companyDetails: companyDetails,
              agencyDetails: {...history.location.state?.agencyDetails, ...values},
              miscellaneous: history.location.state?.miscellaneous,
              _id: history.location.state?._id,
              type: history.location.state?.type,
            },
          })
        } catch {
          
          setLoading(false)
          setSubmitting(false)
          setStatus('Registration process has broken')
          // Remove logs when not needed
        }
       
      }, 1000)
    },
  })
 
          // Remove logs when not needed

  useEffect(() => {
    try {
      var agencyDetail1 = history.location.state?.agencyDetails
      if (agencyDetail1?.agencyName) agencyDetail1.brandName = agencyDetail1.agencyName
      var c = {
        value: agencyDetail1.city,
        label: agencyDetail1.city,
      }
      var s = {
        value: agencyDetail1.state,
        label: agencyDetail1.state,
      }
      setState(s)
      setCity(c)
      if (agencyDetail1.logoUrl && agencyDetail1.logoUrl.size > 0) {
        setSelectedFile(agencyDetail1.logoUrl)
        var reader = new FileReader()
        reader.readAsDataURL(agencyDetail1.logoUrl)
        reader.onload = function () {
          setBgImage('' + reader.result + '')
        }
      } else if (agencyDetail1?.avatar) {
        handleCrop('' + agencyDetail1?.avatar + '')
      }
      setAgencyDetails(agencyDetail1)
    } catch {}
  }, [history.location.state?.agencyDetails])



  function onChange(event: any) {
    let file = event.target.files[0]
    setSelectedFile(file)
    var reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
      setBgImage('' + reader.result + '')
      setToggleCrop(true)
    }
  }
  function resetImage() {
    setBgImage(toAbsoluteUrl('/media/logos/demoImage.svg'))
    setSelectedFile(new File([], ''))
    setShowRemove(false)
  }

  async function handleCrop(image: any) {
    setBgImage(image)
    axios
      .get(image, {headers: httpService.serviceHeader, ...{responseType: 'arraybuffer'}})
      .then((response) => {
        const data = new Blob([response.data], {type: selectedFile.type})
        // const data = response.blob();
        setSelectedFile(
          new File([data], selectedFile?.name, {
            type: selectedFile.type,
          })
        )
      })
    formik.setErrors({})
    setToggleCrop(false)
  }

  const handleCancel = () => {
    setBgImage(toAbsoluteUrl('/media/logos/demoImage.svg'))
    setSelectedFile(new File([], ''))
    setToggleCrop(false)
  }
  const getSt = () => {
    var s: any = []
    loc.map((l) => {
      var p = {
        value: l.State,
        label: l.State,
      }
      s.push(p)
    })
    setSt(s)
    setS('Andaman and Nicobar Islands')
  }

  const getCt = () => {
    var c: any = []
    var ci: any = []
    loc.map((l) => {
      if (l.State === s) {
        c = l.Cities
        c.map((ct: any) => {
          var p = {
            value: ct,
            label: ct,
          }
          ci.push(p)
        })
      }
    })
    setCt(ci)
  }
  useEffect(() => {
    getSt()
    getCt()
  }, [])
  useEffect(() => {
    getCt()
  }, [s])
  const validateName = (name: any) => {
    httpService.getData(
      '/' + history.location.state?.type.toLowerCase() + '-master/',
      (res: any) => {
        if (history.location.state?.type === 'Agency') {
          for (let i = 0; i < res.data.data?.agencies.length; i++) {
            if (res.data.data?.agencies[i].agencyDetails.agencyName === name) {
              setNameError(true)
              break
            } else {
              setNameError(false)
            }
          }
        } else {
          for (let i = 0; i < res.data.data?.brands.length; i++) {
            if (res.data.data?.brands[i].brandDetails.brandName === name) {
              setNameError(true)
              break
            } else {
              setNameError(false)
            }
          }
        }
      },
      (err: any) => {
        console.log(err.message)
      }
    )
  }
  const [nameError, setNameError] = useState(false)
  const handleChange = (e: any) => {
    if (e.target.checked) {
      setState({label: companyDetails?.companyState, value: companyDetails?.companyState})
      setCity({label: companyDetails?.companyCity, value: companyDetails?.companyCity})
      setAgencyDetails({
        ...agencyDetails,
        brandName: formik.values.brandName,
        industry: formik.values.industry,
        description: companyDetails?.companyDescription,
        contactPerson: companyDetails?.companyContactPerson,
        email: companyDetails?.companyEmail,
        phoneNumber: companyDetails?.companyPhoneNumber,
        website: companyDetails?.companyWebsite,
        city: companyDetails?.companyCity,
        state: companyDetails?.companyState,
        pinCode: companyDetails?.companyPinCode,
        sameAsBefore: true,
      })
    } else {
      setState({label: '', value: ''})
      setCity({label: '', value: ''})
      setAgencyDetails({
        ...agencyDetails,
        description: '',
        contactPerson: '',
        email: '',
        phoneNumber: '',
        website: '',
        city: '',
        state: '',
        pinCode: '',
        brandName: formik.values.brandName,
        industry: formik.values.industry,
        sameAsBefore: false,
      })
    }
  }
  return (
    <div className='CompanyDetailsOuter'>
      <BrandAdminLeftBreadcrum currentStep='2' type={history.location.state?.type} />
      <form
        className='CompanyDetails'
        noValidate
        id='kt_login_signup_form'
        onSubmit={formik.handleSubmit}
        style={{marginBottom: '100px'}}
      >
        <h2 className='mb-0'> {isBrand ? 'Add Brand Information' : 'Add Agency Details'}</h2>
        <div className='row d-flex justify-content-start align-items-center mt-5'>
          <span
            style={{width: '120px', height: '120px'}}
            className='mt-5 col-fluid svg-icon svg-icon-2x flex svg-icon-dark bg-gray-100 h-120px w-120px rounded-circle'
          >
            <div className='image-input image-input-circle' data-kt-image-input='true'>
              <div
                className='image-input-wrapper w-125px h-125px'
                style={{backgroundImage: `url(` + bgImage + `)`}}
              ></div>
              <label
                className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow'
                data-kt-image-input-action='change'
                data-bs-toggle='tooltip'
                data-bs-original-title='Change avatar'
              >
                <i className='bi bi-pencil-fill fs-7'></i>
                <input
                  type='file'
                  name='avatar'
                  accept='.png, .jpg, .jpeg'
                  onChange={(event) => onChange(event)}
                 
                />
                <input type='hidden' name='avatar_remove' />
              </label>

              {selectedFile.size > 0 && (
                <span
                  className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow'
                  data-kt-image-input-action='cancel'
                  data-bs-toggle='tooltip'
                  data-bs-dismiss='click'
                  title='Cancel avatar'
                >
                  <i className='bi bi-x fs-2'></i>
                </span>
              )}
              {(selectedFile.size > 0 ||
                (history.location.state?.agencyDetails?.avatar && showRemove)) && (
                <span
                  className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow'
                  data-kt-image-input-action='remove'
                  data-bs-toggle='tooltip'
                  data-bs-dismiss='click'
                  title='Remove avatar'
                  onClick={resetImage}
                >
                  <i className='bi bi-x fs-2'></i>
                </span>
              )}
            </div>
          </span>

          <div className='col mt-5 '>
            <div className='ms-5'>
              <h2 className='required'>{history.location.state?.type} Logo</h2>
            </div>
            <div className='ms-5'>Logo Ratio 1:1 | 120 px x 120 px</div>
          </div>
          {hasError && selectedFile.size === 0 && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span className='text-danger' role='alert'>
                  Company logo is required.
                </span>
              </div>
            </div>
          )}
        </div>

        <div className='row pt-4 pe-5  mt-5'>
          <div className='col '>
            <h6 className='fw-bold required'>{history.location.state?.type} Name</h6>
            <input
              type='text'
              placeholder={'' + history.location.state?.type + ' Name'}
              value={formik.values.brandName}
              onChange={(e) => {
                validateName(e.target.value)
                formik.setFieldValue('brandName', e.target.value)
              }}
              className={clsx(
                'form-control form-control-solid bg-gray-100',
                {
                  'is-invalid': (formik.touched.brandName && formik.errors.brandName) || nameError,
                },
                {
                  'is-valid': formik.touched.brandName && !formik.errors.brandName,
                }
              )}
            />
            {nameError && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='text-danger' role='alert'>
                    {history.location.state?.type} Name already taken.
                  </span>
                </div>
              </div>
            )}
            {formik.errors.brandName && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='text-danger' role='alert'>
                    {formik.errors.brandName}
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className='col '>
            <h6 className='fw-bold required'>Industry</h6>
            <select
              {...formik.getFieldProps('industry')}
              className={clsx(
                'form-select text-gray-500 border-0 bg-gray-100',
                {
                  'is-invalid': formik.touched.industry && formik.errors.industry,
                },
                {
                  'is-valid': formik.touched.industry && !formik.errors.industry,
                }
              )}
            >
              <option value=''>Industry</option>
              <option value='Airlines'>Airlines</option>
              <option value='Beauty/Makeup'>Beauty/Makeup</option>
              <option value='Decor'>Decor</option>
              <option value='Entertainment/Comedy'>Entertainment/Comedy</option>
              <option value='Fashion'>Fashion</option>
              <option value='Finance'>Finance</option>
              <option value='Fintech'>Fintech</option>
              <option value='Fitness'>Fitness</option>
              <option value='Food'>Food</option>
              <option value='Natural & Safe Skincare Products'>
                Natural & Safe Skincare Products
              </option>
              <option value='Parenting'>Parenting</option>
              <option value='Retail'>Retail</option>
              <option value='Skincare & Beauty'>Skincare & Beauty</option>
              <option value='Tech'>Tech</option>
              <option value='Travel'>Travel</option>
            </select>
            {formik.touched.industry && formik.errors.industry && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='text-danger' role='alert'>
                    {formik.errors.industry}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='d-flex justify-content-start' style={{marginTop: '24px'}}>
          <input
            type='checkbox'
            className='form-check-input w-20px h-20px me-2'
            onChange={handleChange}
            checked={formik.values.sameAsBefore}
          />
          <h6 className='fw-bold'>Same as company details</h6>
        </div>
        <div className='row pt-2 pe-5'>
          <h6 className='fw-bold required '>About the {history.location.state?.type}</h6>
          <div className='col'>
            <textarea
            style={{resize:"none"}}
              placeholder={history.location.state?.type + ' Description'}
              {...formik.getFieldProps('description')}
              className={clsx(
                'form-control form-control-solid bg-gray-100 h-70px',
                {
                  'is-invalid': formik.touched.description && formik.errors.description,
                },
                {
                  'is-valid': formik.touched.description && !formik.errors.description,
                }
              )}
            />
            {formik.touched.description && formik.errors.description && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='text-danger' role='alert'>
                    {formik.errors.description}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='row pt-4 pe-5 '>
          <div className='col '>
            <h6 className='fw-bold required'>Contact Person</h6>
            <input
              type='text'
              placeholder='Contact Person'
              {...formik.getFieldProps('contactPerson')}
              className={clsx(
                'form-control form-control-solid bg-gray-100',
                {
                  'is-invalid': formik.touched.contactPerson && formik.errors.contactPerson,
                },
                {
                  'is-valid': formik.touched.contactPerson && !formik.errors.contactPerson,
                }
              )}
            />
            {formik.touched.contactPerson && formik.errors.contactPerson && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='text-danger' role='alert'>
                    {formik.errors.contactPerson}
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className='col '>
            <h6 className='fw-bold required'>Email Address</h6>
            <input
              type='text'
              placeholder='Email Address'
              {...formik.getFieldProps('email')}
              className={clsx(
                'form-control form-control-solid bg-gray-100',
                {
                  'is-invalid': formik.touched.email && formik.errors.email,
                },
                {
                  'is-valid': formik.touched.email && !formik.errors.email,
                }
              )}
            />
            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='text-danger' role='alert'>
                    {formik.errors.email}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='row pt-4 pe-5 '>
          <div className='col '>
            <h6 className='fw-bold required'>Phone Number</h6>
            <input
              type='number'
              placeholder='Phone Number'
              {...formik.getFieldProps('phoneNumber')}
              className={clsx(
                'form-control form-control-solid bg-gray-100',
                {
                  'is-invalid': formik.touched.phoneNumber && formik.errors.phoneNumber,
                },
                {
                  'is-valid': formik.touched.phoneNumber && !formik.errors.phoneNumber,
                }
              )}
            />
            {formik.touched.phoneNumber && formik.errors.phoneNumber && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='text-danger' role='alert'>
                    {formik.errors.phoneNumber}
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className='col '>
            <h6 className='fw-bold required'>Website</h6>
            <input
              type='text'
              placeholder='Website'
              {...formik.getFieldProps('website')}
              className={clsx(
                'form-control form-control-solid bg-gray-100',
                {
                  'is-invalid': formik.touched.website && formik.errors.website,
                },
                {
                  'is-valid': formik.touched.website && !formik.errors.website,
                }
              )}
            />
            {formik.touched.website && formik.errors.website && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='text-danger' role='alert'>
                    {formik.errors.website}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='row pt-4 pe-5 pb-5'>
          <div className='col '>
            <h6 className='fw-bold'>State</h6>
            <Select
              options={st}
              value={state}
              isMulti={false}
              name='state'
              placeholder='Andaman and Nicobar Islands'
              styles={{
                menuList: (provided, state) => ({
                  ...provided,
                  height: 'auto',
                  maxHeight:'200px'
                }),
              }}
              className={clsx(
                'text-gray-500 form-control form-control-solid bg-gray-100 p-0 border-0 categorySelect',
                {
                  'is-invalid': formik.touched.state && formik.errors.state,
                },
                {
                  'is-valid': formik.touched.state && !formik.errors.state,
                }
              )}
              onChange={(e: any) => {
                formik.setFieldValue('state', e.value)
                setS(e.value)
                setState(e)
              }}
            />
            {formik.touched.state && formik.errors.state && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='text-danger' role='alert'>
                    {formik.errors.state}
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className='col '>
            <h6 className='fw-bold'>City</h6>
            <Select
              options={ct}
              value={city}
              isMulti={false}
              name='city'
              placeholder='City'
              styles={{
                menuList: (provided, state) => ({
                  ...provided,
                  height: 'auto',
                  maxHeight:'200px'
                }),
              }}
              className={clsx(
                'text-gray-500 form-control form-control-solid bg-gray-100 p-0 border-0 categorySelect',
                {
                  'is-invalid': formik.touched.city && formik.errors.city,
                },
                {
                  'is-valid': formik.touched.city && !formik.errors.city,
                }
              )}
              onChange={(e: any) => {
                formik.setFieldValue('city', e.value)
                setCity(e)
              }}
            />
            {formik.touched.city && formik.errors.city && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='text-danger' role='alert'>
                    {formik.errors.city}
                  </span>
                </div>
              </div>
            )}
          </div>

          <div className='col '>
            <h6 className='fw-bold'>Pincode</h6>
            <input
              type='text'
              placeholder='Pincode'
              {...formik.getFieldProps('pinCode')}
              className={clsx(
                'form-control form-control-solid bg-gray-100',
                {
                  'is-invalid': formik.touched.pinCode && formik.errors.pinCode,
                },
                {
                  'is-valid': formik.touched.pinCode && !formik.errors.pinCode,
                }
              )}
              style={{height: '38px'}}
            />
            {formik.touched.pinCode && formik.errors.pinCode && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='text-danger' role='alert'>
                    {formik.errors.pinCode}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='d-flex pt-4 pe-5'>
          <Link
            to={{
              pathname: '/companyDetails',
              state: {
                companyDetails: companyDetails,
                agencyDetails: formik.values,
                miscellaneous: history.location.state?.miscellaneous,
                _id: history.location.state?._id,
                type: history.location.state?.type,
              },
            }}
          >
            <button type='button' className='btn fw-bolder btn-gray-200 CompanyDetailButton'>
              Go Back
            </button>
          </Link>
          <button
            type='submit'
            id='kt_sign_up_submit'
            className='btn create-btn CompanyDetailButton ms-5'
            disabled={formik.isSubmitting || !formik.isValid || nameError}
          >
            {!loading && <span className='indicator-label'>Next</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
      {toggleCrop && <ImageCropper cancel={handleCancel} editImage={handleCrop} image={bgImage} />}
    </div>
  )
}
