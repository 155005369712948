import { SearchOutlined } from '@ant-design/icons'
import { Input, message, Pagination } from 'antd'
import { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap-v5'
import { KTSVG, toAbsoluteUrl } from '../../_metronic/helpers'
import httpService from '../../setup/http/httpServices'
import './basic.css'
export function PriceMatrixTable(props: any) {
  const [sort1, setSort1] = useState('rotate-90 ms-2')
  const [sort2, setSort2] = useState('rotate-90 ms-2')
  const [sort3, setSort3] = useState('rotate-90 ms-2')
  const [sort4, setSort4] = useState('rotate-90 ms-2')
  const [asc, setAsc] = useState('bucketName')
  const [show, setShow] = useState(false)
  const [status, setStatus] = useState(true)
  const [priceMatrixParams, setPriceMatrixParams] = useState({
    page: 1,
    total: 10,
    limit: 10,
    search: '',
  })
  const [priceMatrix, setPriceMatrix] = useState([])
  const [deleteId, setDeleteId] = useState('')
  const [totalCount, setTotalCount] = useState(0)

  const key = 'updatable'
  const handleDelete = (id: string) => {
    setShow(false)
    message.loading({content: 'Loading...', key})
    httpService.deleteData(
      '/price-master/' + deleteId,
      (res: any) => {
        setTimeout(() => {
          message.success({content: 'Price matrix deleted!', key, duration: 2})
          setDeleteId('')
        }, 1000)
      },
      (err: any) => {
        message.error(err?.message)
      }
    )
  }
  useEffect(() => {
    getAllPriceMatrix()
  }, [deleteId, props.updatable, priceMatrixParams.page, priceMatrixParams.search, asc])

  const getAllPriceMatrix = () => {
    httpService.getData(
      '/price-master?limit=' +
        priceMatrixParams.limit +
        '&page=' +
        priceMatrixParams.page +
        '&search=' +
        priceMatrixParams.search +
        '&sort=' +
        asc,
      (response: any) => {
        setPriceMatrix(response.data.data.priceMasters)
        setTotalCount(response.data.data.total)
        setStatus(false)
        // setPriceMatrixParams({
        //   page: response.data.data.page,
        //   limit: response.data.data.limit,
        //   total: response.data.data.total,
        //   search: priceMatrixParams.search,
        // })
      },
      (error: any) => {
        message.error(error.message)
        setStatus(false)
      }
    )
  }
  const getBrand = (id: number) => {
    props.editPriceMatrix(id)
  }
  const Sorting1 = () => {
    if (sort1 == 'rotate-90 ms-2') {
      setSort1('rotate-270 ms-2')
    } else {
      setSort1('rotate-90 ms-2')
    }
    if (sort1 == 'rotate-90 ms-2') {
      setSort4('rotate-90 ms-2')
      setSort3('rotate-90 ms-2')
      setSort2('rotate-90 ms-2')
      setAsc('-bucketName')
    } else {
      setSort4('rotate-90 ms-2')
      setSort3('rotate-90 ms-2')
      setSort2('rotate-90 ms-2')
      setAsc('bucketName')
    }
  }
  const Sorting2 = () => {
    if (sort2 == 'rotate-90 ms-2') {
      setSort2('rotate-270 ms-2')
    } else {
      setSort2('rotate-90 ms-2')
    }
    if (sort2 == 'rotate-90 ms-2') {
      setSort4('rotate-90 ms-2')
      setSort3('rotate-90 ms-2')
      setSort1('rotate-90 ms-2')
      setAsc('-socialMedia')
    } else {
      setSort4('rotate-90 ms-2')
      setSort3('rotate-90 ms-2')
      setSort1('rotate-90 ms-2')
      setAsc('socialMedia')
    }
  }
  const Sorting3 = () => {
    if (sort3 == 'rotate-90 ms-2') {
      setSort3('rotate-270 ms-2')
    } else {
      setSort3('rotate-90 ms-2')
    }
    if (sort3 == 'rotate-90 ms-2') {
      setSort2('rotate-90 ms-2')
      setSort4('rotate-90 ms-2')
      setSort1('rotate-90 ms-2')
      setAsc('-minimumFollowerCount')
    } else {
      setSort2('rotate-90 ms-2')
      setSort4('rotate-90 ms-2')
      setSort1('rotate-90 ms-2')
      setAsc('minimumFollowerCount')
    }
  }
  const Sorting4 = () => {
    if (sort4 == 'rotate-90 ms-2') {
      setSort4('rotate-270 ms-2')
    } else {
      setSort4('rotate-90 ms-2')
    }
    if (sort4 == 'rotate-90 ms-2') {
      setSort2('rotate-90 ms-2')
      setSort3('rotate-90 ms-2')
      setSort1('rotate-90 ms-2')
      setAsc('-costPerInfluencer')
    } else {
      setSort2('rotate-90 ms-2')
      setSort3('rotate-90 ms-2')
      setSort1('rotate-90 ms-2')
      setAsc('costPerInfluencer')
    }
  }
  return (
    <div className='categoryTable'>
      <div className='categoryTableHeader'>
        <div className='mt-3 min-w-150px ps-5'>
          <h3 style={{cursor: 'default'}}>Price Matrix</h3>
          <p style={{cursor: 'default'}} className='text-gray-500 fs-7'>
            {totalCount} Price Matrix
          </p>
        </div>
        <div className='TableHeaderSearch d-flex align-items-center'>
          <link
            href='https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css'
            rel='stylesheet'
          />
          <Input
            placeholder='Search'
            style={{width: '286px', height: '41px'}}
            className='form-control-solid bg-gray-100 rounded'
            onChange={(e: any) =>
              setPriceMatrixParams({...priceMatrixParams, ...{search: e.target.value}})
            }
            prefix={
              <>
                <SearchOutlined />
              </>
            }
          />
        </div>
      </div>
      <div className='card-body mt-0 p-0 d-flex flex-column justify-content-between '>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder text-muted'>
                {/* <th className='bg-primary ps-5 bg-opacity-5 w-25px'>
                <div className='form-check ms-5 form-check-sm form-check-custom form-check-solid'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    value='1'
                    data-kt-check='true'
                    data-kt-check-target='.widget-9-check'
                  />
                </div>
              </th> */}
                <th className='bg-primary fw-bold bg-opacity-5 min-w-100px ms-5 ps-5'>
                  <span className='d-flex'>
                    Matrix Name
                    <a
                      className={sort1}
                      onClick={() => {
                        Sorting1()
                      }}
                    >
                      <KTSVG
                        className='svg-icon svg-icon-sm svg-icon-gray-700 flex h-20px pointer'
                        path={toAbsoluteUrl('/media/icons/Custom/SideArrow.svg')}
                      />
                    </a>
                  </span>
                </th>
                <th className='bg-primary fw-bold bg-opacity-5 min-w-150px'>
                  <span className='d-flex'>
                    Social Media
                    <a
                      className={sort2}
                      onClick={() => {
                        Sorting2()
                      }}
                    >
                      <KTSVG
                        className='svg-icon svg-icon-sm svg-icon-gray-700 flex h-20px pointer'
                        path={toAbsoluteUrl('/media/icons/Custom/SideArrow.svg')}
                      />
                    </a>
                  </span>
                </th>
                <th className='bg-primary fw-bold bg-opacity-5 min-w-150px'>
                  <span className='d-flex'>
                    Followers Count
                    <a
                      className={sort3}
                      onClick={() => {
                        Sorting3()
                      }}
                    >
                      <KTSVG
                        className='svg-icon svg-icon-sm svg-icon-gray-700 flex h-20px pointer'
                        path={toAbsoluteUrl('/media/icons/Custom/SideArrow.svg')}
                      />
                    </a>
                  </span>
                </th>
                <th className='bg-primary fw-bold bg-opacity-5 min-w-150px'>
                  <span className='d-flex'>
                    Cost/Influencers
                    <a
                      className={sort4}
                      onClick={() => {
                        Sorting4()
                      }}
                    >
                      <KTSVG
                        className='svg-icon svg-icon-sm svg-icon-gray-700 flex h-20px pointer'
                        path={toAbsoluteUrl('/media/icons/Custom/SideArrow.svg')}
                      />
                    </a>
                  </span>
                </th>
                {props.access && (
                  <th className='bg-primary fw-bold bg-opacity-5 min-w-150px d-flex justify-content-end'>
                    Actions
                  </th>
                )}
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {priceMatrix &&
                priceMatrix.length > 0 &&
                priceMatrix?.map((data: any) => (
                  <tr>
                    {/* <td>
                    <div className='form-check ms-5 ps-5 form-check-sm form-check-custom form-check-solid'>
                      <input
                        className='form-check-input widget-9-check'
                        type='checkbox'
                        value='1'
                      />
                    </div>
                  </td> */}
                    <td className='fw-bolder ms-5 ps-5'>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
                            {data?.bucketName}
                          </a>
                        </div>
                      </div>
                    </td>
                    <td className='fw-bold'>{data?.socialMedia?.socialMediaName}</td>
                    <td className='fw-bold'>
                      {data?.minimumFollowerCount >= 1000
                        ? data?.minimumFollowerCount / 1000 + 'K'
                        : data?.minimumFollowerCount}
                      {'-'}
                      {data?.maximumFollowerCount >= 1000
                        ? data?.maximumFollowerCount / 1000 + 'K'
                        : data?.maximumFollowerCount}
                    </td>

                    <td>
                      <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-7'>
                        Rs. {data?.costPerInfluencer}
                      </a>
                    </td>
                    {props.access && (
                      <td>
                        <div className='d-flex justify-content-end flex-shrink-0'>
                          <span
                            className='svg-icon flex svg-icon-dark bg-primary bg-opacity-5 w-30px h-30px rounded me-2 pointer'
                            onClick={() => getBrand(data._id)}
                          >
                            <KTSVG
                              className='svg-icon-3'
                              path={toAbsoluteUrl('/media/icons/Custom/Pen.svg')}
                            />
                          </span>
                          <span
                            onClick={() => {
                              setShow(true)
                              setDeleteId(data._id)
                            }}
                            className='svg-icon flex svg-icon-danger bg-danger bg-opacity-5 w-30px h-30px rounded pointer'
                          >
                            <KTSVG
                              className='svg-icon-3'
                              path={toAbsoluteUrl('/media/icons/Custom/Dustbin.svg')}
                            />
                          </span>
                          <Modal
                            show={show}
                            onHide={() => {
                              setShow(false)
                              setDeleteId('')
                            }}
                            aria-labelledby='contained-modal-title-vcenter'
                            centered
                          >
                            <Modal.Header>
                              <Modal.Title>Delete</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>Are you sure you wanna delete?</Modal.Body>
                            <Modal.Footer>
                              <button
                                className='btn fw-bolder btn-gray-200 fs-6 dialogButton'
                                onClick={() => {
                                  setShow(false)
                                  setDeleteId('')
                                }}
                              >
                                Cancel
                              </button>
                              <button
                                className='btn create-btn fs-6 dialogButton ms-2'
                                onClick={() => handleDelete(deleteId)}
                              >
                                Delete
                              </button>
                            </Modal.Footer>
                          </Modal>
                        </div>
                      </td>
                    )}
                  </tr>
                ))}
              {priceMatrix.length === 0 && !status && (
                <tr>
                  <td colSpan={3}>
                    <div className='m-20'>
                      <div className='flex'>
                        <img
                          className='ms-20'
                          src={toAbsoluteUrl('/media/logos/TableVector.png')}
                        ></img>
                      </div>
                      <div className='flex'>
                        <div>
                          <h2 className='mb-0 m24 flex'>No Price Matrix Listed</h2>
                          {/* <p className='m-0 mt-5 w-600px ms-auto me-auto text-center'>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                            tempor incididunt ut labore et dolore magna aliquaLorem ipsum dolor sit
                            amet
                          </p> */}
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              )}
              {priceMatrix.length === 0 && status && (
                <tr>
                  <td colSpan={5}>
                    <div className='m-20'>
                      <div className='w-100 flex'>
                        <span className='svg-icon svg-icon-5x flex svg-icon-primary me-3 ms-8'>
                          <KTSVG path={toAbsoluteUrl('/media/icons/Custom/Loading.svg')} />
                        </span>
                      </div>
                      <div className='w-100 flex'>
                        <div>
                          <h5 className='mb-0 mt-3 flex'>Fetching Data</h5>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>

            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        <div
          className='d-flex ms-5 me-10 justify-content-between align-items-center flex-wrap'
          style={{marginBottom: '16px'}}
        >
          <span className='text-muted'>
            Page {priceMatrixParams.page} of{' '}
            {Math.floor(totalCount % priceMatrixParams.limit) === 0
              ? Math.floor(totalCount / priceMatrixParams.limit)
              : Math.floor(totalCount / priceMatrixParams.limit) + 1}
          </span>
          <div className='d-flex flex-wrap Pagination '>
            <Pagination
              className=''
              pageSize={priceMatrixParams.limit}
              defaultCurrent={priceMatrixParams.page}
              total={totalCount}
              onChange={(e) => setPriceMatrixParams({...priceMatrixParams, ...{page: e}})}
            />
          </div>
        </div>
        {/* end::Table container */}
      </div>
    </div>
  )
}
