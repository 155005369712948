import React, {useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../_metronic/helpers'
import './basic.css'
import {Link} from 'react-router-dom'
import {Details} from '@material-ui/icons'
import {Modal} from 'react-bootstrap-v5'
import {useHistory} from 'react-router-dom'
import httpService from '../../setup/http/httpServices'
import {message} from 'antd'

export function AgencyView(props: any) {
  const history: any = useHistory()
  const profileDetails = props.details
  const type = props.type
  const name = props.name
  const id = props?.id
  const {active} = props.active
  const isBrandMaster = false

  const [email, setEmail] = useState('')
  const [deleteId, setDeleteId] = useState('')
  const [show, setShow] = useState(false)
  const pageType = history.location.state?.type == 'AgencyView' ? 'Agency' : 'Brand'
  const key = 'updatable'

  const getData = () => {
    httpService.getData(
      pageType === 'Brand'
        ? '/brand-master-details/' + history.location?.state?.id + '/profile/'
        : '/agency-details/profile/' + history.location?.state?.id,
      (response: any) => {
        if (response.data.brandInformation)
          response.data.agencyInformation = response.data.brandInformation
        if (pageType == 'Brand') setEmail(response.data?.agencyInformation?.email)
        else setEmail(response.data?.agencyInformation?.emailAddress)
      },
      (error: any) => {
        console.log(error.message);
      }
    )
  }
  useEffect(() => {
    getData()
  }, [])
  const handleEdit = () => {
    httpService.getData(
      '/' + pageType.toLowerCase() + '-master/' + history.location.state?.id,
      (res: any) => {
        let resp = res.data.data[pageType.toLowerCase()]
        let agencyDetailsResponse = resp.agencyDetails ? resp.agencyDetails : resp.brandDetails
        let companyDetailsResponse = resp.companyDetails
        let miscellaneousResponse = resp.miscellaneous
        let companyDetails = {}
        if (companyDetailsResponse) {
          companyDetails = {
            agencyAssociated: companyDetailsResponse.agencyAssociated,
            companyName: companyDetailsResponse.companyName,
            gstNumber: companyDetailsResponse.gstNumber,
            companyDescription: companyDetailsResponse.description,
            companyContactPerson: companyDetailsResponse.contactPerson,
            companyEmail: companyDetailsResponse.email,
            companyPhoneNumber: companyDetailsResponse.phoneNumber,
            companyWebsite: companyDetailsResponse.website,
            companyBillingAddress: companyDetailsResponse.billingAddress,
            companyCity: companyDetailsResponse.city,
            companyState: companyDetailsResponse.state,
            companyPinCode: companyDetailsResponse.pinCode,
          }
        }

        history.push({
          pathname: '/companyDetails',
          state: {
            companyDetails: companyDetails,
            agencyDetails: agencyDetailsResponse,
            miscellaneous: miscellaneousResponse,
            _id: resp._id,
            type: history.location.state?.type == 'AgencyView' ? 'Agency' : 'Brand',
          },
        })
      },
      (err: any) => {
        message.error(err.message)
      }
    )
  }
  const handleDelete = () => {
    setShow(false)
    message.loading({content: 'Loading...'})
    httpService.deleteData(
      '/' + pageType.toLowerCase() + '-master/' + deleteId,
      (res: any) => {
        setTimeout(() => {
          message.success({content: pageType + ' deleted!', key, duration: 2})
          setDeleteId('')
        }, 1000)
        if (pageType == 'Agency')
          history.push({
            pathname: '/master/agencies',
            state: {type: 'Agency'},
          })
        else
          history.push({
            pathname: '/master/agencies',
            state: {type: 'Brand'},
          })
      },
      (err: any) => {
        message.error({content: err.message, key, duration: 2})
        setDeleteId('')
      }
    )
  }
  const resendLink = () => {
    httpService.postData(
      '/auth/set-password',
      {email: email},
      (response: any) => {
        message.success('Access details shared to ' + email)
      },
      (error: any) => {
        if (error.message) message.error(error.message)
      }
    )
  }
  return (
    <div className='view-menu'>
      <div className='view-menu-image'>
        <img
        style={{borderRadius:"50%"}}
          src={
            profileDetails?.agencyAvatar ? profileDetails?.agencyAvatar : profileDetails?.brandLogo
          }
          alt=''
        />
        <button
          type='button'
          className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary rotate-90'
          data-kt-menu-trigger='hover'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='top-end'
        >
          <KTSVG
            className='svg-icon  svg-icon-dark'
            path={toAbsoluteUrl('/media/icons/Custom/Menu90.svg')}
          />
        </button>
        <span
          className='menu border border-3 menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold w-200px pt-3 pb-3'
          data-kt-menu='true'
        >
          <span className='menu-item px-3'>
            <a
              className='menu-link px-3 text-hover-white'
              onClick={(e: any) => {
                handleEdit()
              }}
            >
              Edit
            </a>
          </span>
          <span className='menu-item px-3'>
            <a
              className='menu-link px-3 text-hover-white'
              onClick={(e: any) => {
                setShow(true)
                setDeleteId(history.location.state?.id)
              }}
            >
              Delete
            </a>
          </span>
          <span className='menu-item px-3'>
            <a className='menu-link px-3 text-hover-white' onClick={(e: any) => resendLink()}>
              Resend Activation Link
            </a>
          </span>

          <Modal
            show={show}
            onHide={() => {
              setShow(false)
            }}
            aria-labelledby='contained-modal-title-vcenter'
            centered
          >
            <Modal.Header>
              <Modal.Title>Delete</Modal.Title>
            </Modal.Header>
            <Modal.Body>Are you sure you wanna delete?</Modal.Body>
            <Modal.Footer>
              <button
                className='btn fw-bolder btn-gray-200 fs-6 dialogButton'
                onClick={() => {
                  setShow(false)
                  setDeleteId('')
                }}
              >
                Cancel
              </button>
              <button
                className='btn btn-primary fs-6 dialogButton ms-2'
                onClick={() => handleDelete()}
              >
                Delete
              </button>
            </Modal.Footer>
          </Modal>
        </span>
      </div>
      <div className='brand'>
        <p className='brand-name'>
          {profileDetails?.agencyName ? profileDetails?.agencyName : profileDetails?.brandName}
        </p>
        <p className='brand-address'>
          {profileDetails?.agencyCity ? profileDetails?.agencyCity : profileDetails?.brandCity},
          {profileDetails?.agencyState ? profileDetails?.agencyState : profileDetails?.brandState}
        </p>
      </div>
      <div className='menu-select'>
        <Link
          to={{pathname: '/master/profile', state: {profileDetails, id, type: type, name: name}}}
          className='menu-options'
        >
          <p>Profile</p>
          {active === 1 && <KTSVG path={toAbsoluteUrl('/media/icons/Custom/SideArrow.svg')} />}
        </Link>
        <hr />
        <Link
          to={{pathname: '/master/billing', state: {profileDetails, id, type: type, name: name}}}
          className='menu-options'
        >
          <p>Billing</p>
          {active === 2 && <KTSVG path={toAbsoluteUrl('/media/icons/Custom/SideArrow.svg')} />}
        </Link>
        <hr />
        <Link
          to={{pathname: '/master/campaigns', state: {profileDetails, id, type: type, name: name}}}
          className='menu-options'
        >
          <p>Campaigns</p>
          {active === 3 && <KTSVG path={toAbsoluteUrl('/media/icons/Custom/SideArrow.svg')} />}
        </Link>
        <hr />
        <Link
          to={{
            pathname: '/master/influencers',
            state: {profileDetails, id, type: type, name: name},
          }}
          className='menu-options'
        >
          <p>Influencer</p>
          {active === 4 && <KTSVG path={toAbsoluteUrl('/media/icons/Custom/SideArrow.svg')} />}
        </Link>
        <hr />
        {type === 'AgencyView' && (
          <Link
            to={{
              pathname: '/master/brands-associated',
              state: {profileDetails, id, type: type, name: name},
            }}
            className='menu-options'
          >
            <p>Brands Associated</p>
            {active === 5 && <KTSVG path={toAbsoluteUrl('/media/icons/Custom/SideArrow.svg')} />}
          </Link>
        )}
        {type === 'BrandView' && (
          <Link
            to={{
              pathname: '/master/questions', 
              state: {profileDetails, id, type: type, name: name},
            }}
            className='menu-options'
          >
            <p>Questions</p>
            {active === 5 && <KTSVG path={toAbsoluteUrl('/media/icons/Custom/SideArrow.svg')} />}
          </Link>
        )}
      </div>
    </div>
  )
}
