import React from 'react';
import '../../masters/basic.css'
import { ChartBar } from './ChartBar';

export function Chart(props:any) {
  const dataPointValues = props.dataPoints.map((dataPoint:any) => dataPoint.value);
  const totalMaximum = Math.max(...dataPointValues);

  return (
    <div className='chart mt-3'>
      {props.dataPoints.map((dataPoint:any) => (
        <ChartBar
          key={dataPoint.label}
          value={dataPoint.value}
          maxValue={totalMaximum}
          label={dataPoint.label}
          bgColor={dataPoint.bgColor}
        />
      ))}
    </div>
  );
}