import {message} from 'antd'
import React, {useState} from 'react'
import {Link, useHistory} from 'react-router-dom'
import httpService from '../../setup/http/httpServices'
import {toAbsoluteUrl} from '../../_metronic/helpers'
import './basic.css'
import {BrandAdminLeftBreadcrum} from './BrandAgencyLeftBreadcrum'

export function AgencyBrandSuccess() {
  const history: any = useHistory()
  const [list, setList] = useState([])
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  return (
    <div className='CompanyDetailsOuter'>
      <BrandAdminLeftBreadcrum currentStep='4' type={history.location.state?.type} />
      <div className='CompanyDetails bg-white m-5 p-5  rounded h-90'>
        <div style={{width: '100%', height: '367px'}} className='d-flex justify-content-center'>
          <img src={toAbsoluteUrl('/media/logos/Site-constructor.png')}></img>
        </div>
        <div className='d-flex justify-content-center'>
          <h1 className='fw-bolder text-dark ms-5 mt-5'>Wohoo! Let's get this rolling! 🌪️</h1>
        </div>
        <div className='d-flex justify-content-center'>
          <p style={{fontSize: '14px', textAlign: 'center', width: '75%', marginTop: '15px'}}>
            You just {history.location.state?._id ? 'Updated' : 'Added'} a new{' '}
            {history.location.state?.type} successfully! You can now share the link with the contact
            person to assign access to the profile.
          </p>
        </div>
        <div style={{width: '100%', marginTop: '30px'}} className='d-flex justify-content-center'>
          <Link
            style={{width: '30%', height: '49px'}}
            to={{
              pathname: '/companyDetails',
              state: {type: history.location.state?.type},
            }}
          >
            <button className='btn btn-gray-200 flex w-100 h-100'>
              {history.location.state?.type === 'Agency' && (
                <b className='fs-6'>Add Another Agency</b>
              )}
              {history.location.state?.type === 'Brand' && (
                <b className='fs-6'>Add Another Brand</b>
              )}
            </button>
          </Link>
          {!history.location.state.companyDetails.agencyAssociated &&
          <div style={{width: '30%', height: '49px'}}>
            <button
              className='btn fw-bolder create-btn  ms-5 w-100 h-100'
              onClick={() => {
                httpService.postData(
                  '/auth/set-password',
                  {email: history.location.state.agencyDetails.email},
                  (response: any) => {
                    message.success(
                      'Access details shared to ' + history.location.state.agencyDetails.email
                    )
                    history.push({
                      pathname:
                        history.location.state.type === 'Brand'
                          ? '/master/brands'
                          : '/master/agencies',
                      state: {
                        type: history.location.state.type,
                      },
                    })
                  },
                  (error: any) => {
                    if (error.message) message.error(error.message)
                  }
                )
              }}
            >
              Send Access Link
            </button>
          </div>
          }
        </div>
        <Link
          to={{
            pathname:
              history.location.state.type === 'Brand' ? '/master/brands' : '/master/agencies',
            state: {
              type: history.location.state.type,
            },
          }}
        >
          <div className='link-primary fs-6 fw-bolder mt-10 text-center'>
            Go to {history.location.state.type === 'Brand' ? 'Brand' : 'Agency'} List
          </div>
        </Link>
      </div>
    </div>
  )
}
