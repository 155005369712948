import { message } from 'antd'
import { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { KTSVG, toAbsoluteUrl } from '../../_metronic/helpers'
import httpService from '../../setup/http/httpServices'
import { AgencyView } from './AgencyView'
import './basic.css'

export function AgencyViewInfluencer() {
  const [influencersList, setInfluencersList] = useState([])
  const [influencersCount, setInfluencersCount] = useState(0)
  const history: any = useHistory()
  const [influencerParams, setInfluencerParams] = useState({
    page: 1,
    total: 0,
    limit: 0,
    search: '',
    gender: '',
    age: '',
    platform: 'instagram',
    followers: '',
    avgViews: '',
    avgLikes: '',
  })
  useEffect(() => {
    getInfluencerList()
  }, [influencerParams])

  const getInfluencerList = () => {
    httpService.getData(
      '/influencer-master?platform=' +
        influencerParams.platform +
        '&&search=' +
        influencerParams.search +
        '&&age=' +
        influencerParams.age +
        '&&followers=' +
        influencerParams.followers +
        '&&avgViews=' +
        influencerParams.avgViews +
        '&&avgLikes=' +
        influencerParams.avgLikes +
        '&&gender=' +
        influencerParams.gender,
      (response: any) => {
        setInfluencersList(response.data.data.influencers)
        setInfluencersCount(response.data.data.total)
      },
      (error: any) => {
        message.error(error.message)
      }
    )
  }
  const handleView = (id: any) => {
    httpService.getData(
      '/influencer-master/' + id,
      (res: any) => {
        res.data.data.influencer.additionalInfo.interestedCategories =
          res.data.data.influencer.additionalInfo.interestedCategories.join(',')
        history.push({
          pathname: '/master/influencerProfile',
          state: {
            influencerBasicInfo: res.data.data.influencer.basicInfo,
            influencerAdditinalInfo: res.data.data.influencer.additionalInfo,
            influencerSocialDetails: res.data.data.influencer.socialMediaDetails,
            influencerStats: res.data.data.influencer.stats,
            join_date: res.data.data.influencer.createdAt,
            _id: res.data.data.influencer._id,
            type: 'influencerProfile',
          },
        })
      },
      (err: any) => {
        message.error({content: err.message, duration: 2})
      }
    )
  }
  return (
    <div className='Outer d-flex'>
      <AgencyView
        active={4}
        details={history?.location?.state?.profileDetails}
        id={history?.location?.state?.id}
        type={history?.location?.state?.type}
        name={history?.location?.state?.name}
      />
      <div className='influencer-view container'>
        {influencersList.length === 0 && (
          <>
            <div>
              <div className='flex'>
                <img className='ms-20' src={toAbsoluteUrl('/media/logos/TableVector.png')}></img>
              </div>
              <div className='flex'>
                <div>
                  <h2 className='mb-0 m24 flex'>No influencers listed.</h2>
                  {/* <p className='m-0 mt-5 w-600px ms-auto me-auto text-center'>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliquaLorem ipsum dolor sit amet
                  </p> */}
                </div>
              </div>
              <div className='flex'>
                <Link
                  to={{
                    pathname: '/master/influencerBasicInfo',
                    state: history.location.state,
                  }}
                >
                  <button className='btn m24 TableButton btn-pink text-white'>
                    Add New Influencer
                  </button>
                </Link>
              </div>
            </div>
          </>
        )}
        <div className='campaignGrid' style={{marginTop: '0px', marginLeft: '0px'}}>
          {influencersList.map((data: any, index: number) => (
            <div key={index} onClick={() => handleView(data?._id)} className='pointer'>
              <span className='d-flex w-100'>
                <span className='flex justify-content-start w-100'>
                  {data.basicInfo.avatar ? (
                    <label className='campaignLabel w-65px h-65px rounded-circle'>
                      <img className='w-100 rounded-circle' src={data.basicInfo.avatar}></img>
                    </label>
                  ) : (
                    <span
                      className='symbol create-btn pt-4 w-65px h-65px imageBadge'
                      style={{fontSize: 26, borderRadius: '100%'}}
                    >
                      {data?.basicInfo.name[0].toUpperCase()}
                    </span>
                  )}
                  <span className='fs-6 ms-4 mt-1 '>
                    <h6 className='mb-0'>{data?.basicInfo.name}</h6>{' '}
                    <label className='fs-7 text-gray-400 m-0'>{data?.basicInfo.name}</label>
                    <label className='flex fs-7'>
                      <KTSVG
                        className='svg-icon me-1'
                        path={toAbsoluteUrl('/media/icons/Custom/MapMarker.svg')}
                      />{' '}
                      {data.basicInfo.city}, {data.basicInfo.state}
                    </label>
                  </span>
                </span>
              </span>
              <span className='m16 flex'>
                <span className=' flex w-30px h-30px border rounded-circle me-2'>
                  <img className='w-50' src={toAbsoluteUrl('/media/icons/Custom/Shirt.svg')}></img>
                </span>
                <span className=' flex w-30px h-30px border rounded-circle me-2'>
                  <img className='w-50' src={toAbsoluteUrl('/media/icons/Custom/Music.svg')}></img>
                </span>
                <span className=' flex w-30px h-30px border rounded-circle me-2'>
                  <img
                    className='w-50'
                    src={toAbsoluteUrl('/media/icons/Custom/Airplane.svg')}
                  ></img>
                </span>
                <span className=' flex w-30px h-30px border rounded-circle me-2'>
                  <img
                    className='w-50'
                    src={toAbsoluteUrl('/media/icons/Custom/Devices.svg')}
                  ></img>
                </span>
              </span>
              <span className='m16 w-100 flex'>
                <span className='w-100px '>
                  <h3 className='mb-1 flex'>
                    {data.socialMediaDetails[influencerParams.platform]?.basicStats
                      ? data.socialMediaDetails[influencerParams.platform]?.basicStats?.followers
                      : '-'}
                  </h3>
                  <p className='fs-7 text-gray-400 flex m-0'>Followers</p>
                </span>
                <span className='w-35px h-100 flex align-content-start'>
                  <KTSVG
                    className='svg-icon h-100 mt-4 svg-icon-2x '
                    path={toAbsoluteUrl('/media/icons/Custom/VerticalLine.svg')}
                  />
                </span>
                <span className='w-100px '>
                  <h3 className='mb-1 flex'>
                    {data.socialMediaDetails[influencerParams.platform]?.basicStats
                      ? data.socialMediaDetails[influencerParams.platform]?.basicStats?.avgViews
                      : '-'}
                  </h3>
                  <p className='fs-7 text-gray-400 flex m-0'>Avg Views</p>
                </span>
                <span className='w-35px h-100 flex align-content-start'>
                  <KTSVG
                    className='svg-icon h-100 mt-4 svg-icon-2x '
                    path={toAbsoluteUrl('/media/icons/Custom/VerticalLine.svg')}
                  />
                </span>
                <span className='w-100px '>
                  <h3 className='mb-1 flex'>
                    {data.socialMediaDetails[influencerParams.platform]?.basicStats
                      ? data.socialMediaDetails[influencerParams.platform]?.basicStats?.avgLikes
                      : '-'}
                  </h3>
                  <p className='fs-7 text-gray-400 flex m-0'>Avg Likes</p>
                </span>
              </span>
              <span className='m16 h-100px w-100 flex'>
                <span className='w-115px h-100 flex rounded me-2'>
                  <img className='w-100' src={toAbsoluteUrl('/media/logos/reel3.png')}></img>
                </span>
                <span className='w-115px h-100 flex rounded me-2'>
                  <img className='w-100' src={toAbsoluteUrl('/media/logos/reel1.png')}></img>
                </span>
                <span className='w-115px h-100 flex rounded'>
                  <img className='w-100' src={toAbsoluteUrl('/media/logos/reel2.png')}></img>
                </span>
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
