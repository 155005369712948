/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, {FC} from 'react'
import {Redirect, Switch, Route} from 'react-router-dom'
import {shallowEqual, useSelector} from 'react-redux'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {PrivateRoutes} from './PrivateRoutes'
import {Logout, AuthPage} from '../modules/auth'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {RootState} from '../../setup'
import {Register} from '../modules/auth/Register'
import { IUserModel } from '../modules/auth/models/UserModel'


const permission = {
  admin: true,
  masters: [true, true],
  campaign: [true, true],
  scheduler: [true, true],
  transactions: [true, true],
  adminSettings: [true, true],
  support: [true, true],
}
const Routes: FC = () => {
  const isAuthorized = useSelector<RootState>(({auth}) => auth.user, shallowEqual)
  const user: IUserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as IUserModel

  return (
    <Switch>
      {!isAuthorized ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route>
          <AuthPage />
        </Route>
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect
          from='/auth'
          to={{
            pathname: '/dashboard/general', state: {type: 'Dashboard/general'}
          }}
        />
      )}
      <Route path='/register' component={Register} />
      <Route path='/error' component={ErrorsPage} />
      <Route path='/logout' component={Logout} />
      

    

      {!isAuthorized ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect to='/auth/admin/login' />
      ) : (
        <MasterLayout>
          <PrivateRoutes permissions={permission} />
        </MasterLayout>
      )}
    </Switch>
  )
}

export {Routes}
