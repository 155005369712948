import React from 'react'
import {KTSVG, toAbsoluteUrl} from '../../_metronic/helpers'
import './basic.css'
import Moment from 'moment'
import {useHistory} from 'react-router-dom'
import httpService from '../../setup/http/httpServices'
export function CampaignReview(props: any) {
  const data = [1, 2, 3]
  const campaigns = props?.campaign
  const history = useHistory()

  const redirectViewCampaign = (data: any) => {
    httpService.getData(
      '/campaign-master/' + data._id,
      (response: any) => {
        response.data.data.campaign.campaignStatus = response.data.data.campaign.status;
        history.push({
          pathname: '/campaign/campaignDetailById',
          state: {
            ...response.data.data.campaign,
            _id: data._id,
            ...{
              type: response.data.data.campaign.campaignDetails.name,
              breadcrums: ['Campaigns', 'Campaign Overview'],
            },
          },
        })
      },
      (error: any) => {}
    )
  }
  return (
    <div className='row'>
      {campaigns?.length === 0 && (
        <div>
          <div className='flex'>
            <img src={toAbsoluteUrl('/media/logos/TableVector.png')}></img>
          </div>
          <div className='flex'>
            <div>
              <h2 className='mb-0 m24 flex'>No Campaigns Listed</h2>
              {/* <p className='m-0 mt-5 w-600px ms-auto me-auto text-center'>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore et dolore magna aliquaLorem ipsum dolor sit amet
              </p> */}
            </div>
          </div>
        </div>
      )}
      {campaigns?.length > 0 &&
        campaigns?.map((index) => (
          <div className='Campaigns-card col pointer' onClick={() => redirectViewCampaign(index)}>
            <div className='Campaigns-card-header'>
              <h2>{index?.campaignDetails?.name}</h2>
              <p>{index?.campaignDetails?.description}</p>
            </div>
            <div className='Campaigns-card-body container'>
              <div className='row'>
                <div className='col'>
                  <h2>Media Required</h2>
                  {index?.campaignDetails?.platform == 'Instagram' && (
                    <p>
                      <img
                        src={toAbsoluteUrl('/media/logos/Instagram.png')}
                        style={{marginRight: '4px'}}
                        alt=''
                      />
                      Instagram
                    </p>
                  )}
                  {index?.campaignDetails?.platform == 'Youtube' && (
                    <p>
                      <img
                        src={toAbsoluteUrl('/media/logos/Youtube.png')}
                        style={{marginRight: '4px'}}
                        alt=''
                      />
                      Youtube
                    </p>
                  )}
                </div>
                <div className='col'>
                  <h2>Content Type</h2>
                  <p>Image, Video, Reel</p>
                </div>
              </div>
              <div className='row'>
                <div className='col'>
                  <h2>Barter Value</h2>
                  <p>
                    ₹ {index?.campaignDetails?.barterValue?.min} - ₹{' '}
                    {index?.campaignDetails?.barterValue?.max}
                  </p>
                </div>
                <div className='col'>
                  <h2>Influencers Associated</h2>
                  <p>{index?.campaignDetails?.noOfInfluencers}</p>
                </div>
              </div>
              <div className='row'>
                <div className='col'>
                  <h2>Application Deadline</h2>
                  <p>{Moment(index?.campaignDetails?.endDate).format('DD MMMM YYYY')}</p>
                </div>
                <div className='col'>
                  <h2>Influencer Required</h2>
                  <p>50</p>
                </div>
              </div>
            </div>
            <div className='Campaigns-card-footer'>
              <p>{Moment(index?.updatedAt).format('DD MMMM YYYY')}</p>
            </div>
          </div>
        ))}
    </div>
  )
}
