import {SearchOutlined} from '@ant-design/icons'
import {Input, message, Pagination} from 'antd'
import Moment from 'moment'
import React, {useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap-v5'
import httpService from '../../setup/http/httpServices'
import {KTSVG, toAbsoluteUrl} from '../../_metronic/helpers'
import './basic.css'

export function SocialMasterTable(props: any) {
  const [sort1, setSort1] = useState('rotate-90 ms-2')
  const [sort2, setSort2] = useState('rotate-90 ms-2')
  const [asc, setAsc] = useState('socialMediaName')
  const [show, setShow] = useState(false)
  const [status, setStatus] = useState(true)
  const [socialMediaParams, setSocialMediaParams] = useState({
    page: 1,
    total: 10,
    limit: 10,
    search: '',
  })
  const [socialMedia, setSocialMedia] = useState([])
  const [deleteId, setDeleteId] = useState('')
  const [totalCount, setTotalCount] = useState(0)

  const key = 'updatable'
  const handleDelete = (id: string) => {
    setShow(false)
    message.loading({content: 'Loading...', key})
    httpService.deleteData(
      '/social-media-master/' + deleteId,
      (res: any) => {
        setTimeout(() => {
          message.success({content: 'Social media deleted!', key, duration: 2})
          setDeleteId('')
        }, 1000)
      },
      (error: any) => {
        message.error({content: error.message, key, duration: 2})
      }
    )
  }
  const Sorting1 = () => {
    if (sort1 == 'rotate-90 ms-2') {
      setSort1('rotate-270 ms-2')
    } else {
      setSort1('rotate-90 ms-2')
    }
    if (sort1 == 'rotate-90 ms-2') {
      setSort2('rotate-90 ms-2')
      setAsc('-socialMediaName')
    } else {
      setSort2('rotate-90 ms-2')
      setAsc('socialMediaName')
    }
  }
  const Sorting2 = () => {
    if (sort2 == 'rotate-90 ms-2') {
      setSort2('rotate-270 ms-2')
    } else {
      setSort2('rotate-90 ms-2')
    }
    if (sort2 == 'rotate-90 ms-2') {
      setSort1('rotate-90 ms-2')
      setAsc('-updatedAt')
    } else {
      setSort1('rotate-90 ms-2')
      setAsc('updatedAt')
    }
  }
  useEffect(() => {
    getAllSocialMedias()
  }, [deleteId, props.updatable, socialMediaParams.page, socialMediaParams.search, asc])

  const getAllSocialMedias = () => {
    httpService.getData(
      '/social-media-master?limit=' +
        socialMediaParams.limit +
        '&page=' +
        socialMediaParams.page +
        '&search=' +
        socialMediaParams.search +
        '&sort=' +
        asc,
      (response: any) => {
        setSocialMedia(response.data.data.socialMedias)
        setTotalCount(response.data.data.total)
        setStatus(false)
        // setSocialMediaParams({
        //   limit: response.data.data.limit,
        //   page: response.data.data.page,
        //   total: response.data.data.total,
        //   search: socialMediaParams.search
        // })
      },
      (error: any) => {
        message.error(error.message)
        setStatus(false)
      }
    )
  }
  const getSocialMedia = (id: number) => {
    props.editSocialMedia(id)
  }
  return (
    <div className='socialTable mb-5'>
      <div className='categoryTableHeader'>
        <div className='mt-3 ps-5 min-w-150px'>
          <h3 style={{cursor: 'default'}}>Platforms</h3>
          {totalCount && (
            <p style={{cursor: 'default'}} className='text-gray-500 fs-7'>
              {totalCount} Social Medias
            </p>
          )}
        </div>
        <div className='TableHeaderSearch d-flex align-items-center'>
          <link
            href='https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css'
            rel='stylesheet'
          />
          <Input
            placeholder='Search'
            style={{width: '300px', height: '41px'}}
            className='form-control-solid bg-gray-100 rounded'
            onChange={(e: any) =>
              setSocialMediaParams({...socialMediaParams, ...{search: e.target.value}})
            }
            prefix={
              <>
                <SearchOutlined />
              </>
            }
          />
        </div>
      </div>
      <div className='card-body mt-0 p-0 d-flex flex-column justify-content-between'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table min-h-95 table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder text-muted'>
                <th className='bg-primary bg-opacity-5 ps-5 min-w-190px'>
                  <span className='d-flex'>
                    Name
                    <a
                      className={sort1}
                      onClick={() => {
                        Sorting1()
                      }}
                    >
                      <KTSVG
                        className='svg-icon svg-icon-sm svg-icon-gray-700 flex h-20px pointer'
                        path={toAbsoluteUrl('/media/icons/Custom/SideArrow.svg')}
                      />
                    </a>
                  </span>
                </th>
                <th className='bg-primary bg-opacity-5 min-w-150px'>
                  <span className='d-flex'>
                    Added
                    <a
                      className={sort2}
                      onClick={() => {
                        Sorting2()
                      }}
                    >
                      <KTSVG
                        className='svg-icon svg-icon-sm svg-icon-gray-700 flex h-20px pointer'
                        path={toAbsoluteUrl('/media/icons/Custom/SideArrow.svg')}
                      />
                    </a>
                  </span>
                </th>
                {props.access && (
                  <th className='bg-primary bg-opacity-5 min-w-100px d-flex justify-content-end'>
                    Actions
                  </th>
                )}
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {socialMedia.length > 0 &&
                socialMedia.map((data: any) => (
                  <tr>
                    <td className='fw-bolder'>
                      <div className='d-flex ps-5 align-items-center'>
                        <div className='symbol symbol-30px symbol-circle me-5'>
                          <img src={data.avatar} alt='' />
                          {/* <img src={toAbsoluteUrl('/media/logos/logo-2.svg')} alt='' /> */}
                        </div>
                        <div className='d-flex justify-content-start flex-column'>
                          <span className='text-dark fw-bolder text-hover-primary fs-6'>
                            {data?.socialMediaName}
                          </span>
                        </div>
                      </div>
                    </td>

                    <td>
                      <span className='text-dark fw-bold text-hover-primary d-block fs-7'>
                        {Moment(data?.updatedAt).format('DD MMMM YYYY')}
                      </span>
                      <span className='text-muted fw-bold text-muted d-block fs-7'>
                        {data?.modifiedBy['firstName']} {data?.modifiedBy['lastName']}
                      </span>
                    </td>
                    {props.access && (
                      <td className='d-flex justify-content-end'>
                        <div className='d-flex justify-content-start flex-shrink-0'>
                          <span
                            className='svg-icon flex svg-icon-dark bg-primary bg-opacity-5 w-30px h-30px rounded me-2 pointer'
                            onClick={() => getSocialMedia(data?._id)}
                          >
                            <KTSVG
                              className='svg-icon-3'
                              path={toAbsoluteUrl('/media/icons/Custom/Pen.svg')}
                            />
                          </span>
                          <span
                            onClick={() => {
                              setShow(true)
                              setDeleteId(data?._id)
                            }}
                            className='svg-icon flex svg-icon-danger bg-danger bg-opacity-5 w-30px h-30px rounded pointer'
                          >
                            <KTSVG
                              className='svg-icon-3'
                              path={toAbsoluteUrl('/media/icons/Custom/Dustbin.svg')}
                            />
                          </span>
                          <Modal
                            show={show}
                            onHide={() => {
                              setShow(false)
                              setDeleteId('')
                            }}
                            aria-labelledby='contained-modal-title-vcenter'
                            centered
                          >
                            <Modal.Header>
                              <Modal.Title>Delete</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>Are you sure you wanna delete?</Modal.Body>
                            <Modal.Footer>
                              <button
                                className='btn fw-bolder btn-gray-200 fs-6 dialogButton'
                                onClick={() => {
                                  setShow(false)
                                  setDeleteId('')
                                }}
                              >
                                Cancel
                              </button>
                              <button
                                className='btn create-btn fs-6 dialogButton ms-2'
                                onClick={() => handleDelete(deleteId)}
                              >
                                Delete
                              </button>
                            </Modal.Footer>
                          </Modal>
                        </div>
                      </td>
                    )}
                  </tr>
                ))}
              {socialMedia.length === 0 && !status && (
                <tr>
                  <td colSpan={3}>
                    <div className='m-20'>
                      <div className='flex'>
                        <img
                          className='ms-20'
                          src={toAbsoluteUrl('/media/logos/TableVector.png')}
                        ></img>
                      </div>
                      <div className='flex'>
                        <div>
                          <h2 className='mb-0 m24 flex'>No Social Media Listed</h2>
                        </div>
                      </div>
                      <div className='flex'>
                        {props.access && (
                          <button className='btn m24 TableButton btn-pink text-white'>
                            + Add New Social Media
                          </button>
                        )}
                      </div>
                    </div>
                  </td>
                </tr>
              )}
              {socialMedia.length === 0 && status && (
                <tr>
                  <td colSpan={3}>
                    <div className='m-20'>
                      <div className='flex'>
                        <span className='svg-icon svg-icon-5x flex svg-icon-primary me-3 ms-8'>
                          <KTSVG path={toAbsoluteUrl('/media/icons/Custom/Loading.svg')} />
                        </span>
                      </div>
                      <div className='flex'>
                        <div>
                          <h5 className='mb-0 mt-3 flex'>Fetching Data</h5>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>

            {/* end::Table body */}
          </table>

          {/* end::Table */}
        </div>
        <div
          className='d-flex ms-5 me-10 justify-content-between align-items-center flex-wrap'
          style={{marginBottom: '16px'}}
        >
          <span className='text-muted'>
            Page {socialMediaParams.page} of{' '}
            {Math.floor(totalCount % socialMediaParams.limit) === 0
              ? Math.floor(totalCount / socialMediaParams.limit)
              : Math.floor(totalCount / socialMediaParams.limit) + 1}
          </span>
          <div className='d-flex flex-wrap '>
            <Pagination
              className=''
              pageSize={socialMediaParams.limit}
              defaultCurrent={socialMediaParams.page}
              total={totalCount}
              onChange={(e) => setSocialMediaParams({...socialMediaParams, ...{page: e}})}
            />
          </div>
        </div>
        {/* end::Table container */}
      </div>
    </div>
  )
}
