import React from 'react'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

export function Register() {
  return (
    <div className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed bg-white'>
      {/* begin::Content */}
      <div className='d-flex flex-left flex-column flex-column-fluid p-10 '>
        <Link style={{marginLeft: '5%'}} to='/auth/admin/login' className=' mb-7'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/logos/logo__name__color.png')}
            className='h-45px'
          />
        </Link>
        <div
          style={{marginLeft: '5%', width: '40%', height: '100%', marginTop: '168px'}}
          className='d-flex flex-column align-items-center justify-content-between'
        >
          {' '}
          <div className='d-flex justify-content-center w-100'>
            <div>
              <p className='fs-7'>Privacy Policy &nbsp; | &nbsp; Terms and Conditions</p>
              <p className='fs-7 text-gray-600'>©2021 Sehaye. All Rights Reserved.</p>
            </div>
          </div>
        </div>

        <span
          style={{
            position: 'absolute',
            top: '0',
            right: '0',
            height: '100%',
            width: '44%',
            overflow: 'hidden',
          }}
        >
          <img
            style={{width: '100%', height: 'auto'}}
            src={toAbsoluteUrl('/media/logos/brand-login.png')}
          ></img>
        </span>
      </div>
    </div>
  )
}
