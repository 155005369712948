/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { getCSSVariableValue } from '../../../_metronic/assets/ts/_utils'
import { toAbsoluteUrl } from '../../../_metronic/helpers'

type Props = {
  className: string
  stats: Array<Object>
  section: Object
}

const OverviewChart: React.FC<Props> = ({ className, stats, section }) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const month: Array<String> = []
  const data: Array<Number> = []

  stats?.forEach((reach: any) => {
    month.push(reach?.month)
    data.push(Math.round(reach[section["key"]]))
  })

  useEffect(() => {
    if (!chartRef.current) return;
    const chart1 = new ApexCharts(chartRef.current, chart1Options(data, month, section["key"]))
    if (chart1) chart1.render()

    return () => {
      if (chart1) {
        chart1.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, data])

  return (
    <div className={`${className}`}>
      {data.length > 0 ? <div className='d-flex flex-column'>
        <div ref={chartRef} id='kt_charts_widget_3_chart' style={{ height: '458px' }}></div>
      </div> : <EmptyBody label={section["key"]} />}
    </div>
  )
}

const chart1Options = (data: any, month: any, type: any): ApexOptions => {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  var series = [{ name: type, data: data }]
  return {
    series: series,

    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height: 458,
      toolbar: {
        show: false,
      },
      redrawOnParentResize: false,
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'gradient',
      opacity: 1,
      gradient: {
        type: 'vertical',
        shadeIntensity: 0.5,
        gradientToColors: undefined,
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 0.5,
        stops: [25, 50, 100],
      },
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 2,
      colors: ['#513A98'],
    },
    xaxis: {
      categories: month,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        show: false,
        position: 'front',
        stroke: {
          color: labelColor,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        position: 'front',
        stroke: {
          color: labelColor,
          width: 1,
          dashArray: 3,
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return `${val}`
        },
      },
    },
    colors: ['#EEEBF5'],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      strokeColors: '#513A98',
      strokeWidth: 2,
    },
  }
}

export function EmptyBody(props: any) {
  return (
    <div className='chart-body flex w-100 h-400px'>
      {' '}
      <img src={toAbsoluteUrl('/media/logos/bar-chart 1.png')} alt='' />
      <h2>No {props.label}</h2>
      <p>Statistical details are not available for {props.label}</p>
    </div>
  )
}

export { OverviewChart }
