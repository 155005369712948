import {KTSVG, toAbsoluteUrl} from '../../_metronic/helpers'

export function BrandAdminLeftBreadcrum(props: any) {
  function selectedTick(step: number) {
    if (props.currentStep < step) {
      return (
        <span className='ms-4 BrandIcon svg-icon flex svg-icon-gray-800 bg-gray-100 w-40px h-40px rounded'>
          <KTSVG path={toAbsoluteUrl('/media/icons/Custom/Active.svg')} />
        </span>
      )
    } else if (props.currentStep == step) {
      return (
        <span className='ms-4 BrandIcon svg-icon flex svg-icon-warning bg-warning-custom-light  w-40px h-40px rounded'>
          <KTSVG path={toAbsoluteUrl('/media/icons/Custom/Active.svg')} />
        </span>
      )
    } else if (props.currentStep > step) {
      return (
        <span className='ms-4 svg-icon flex svg-icon-gray-300 bg-success-dark w-40px h-40px rounded'>
          <KTSVG path={toAbsoluteUrl('/media/icons/Custom/Active.svg')} />
        </span>
      )
    }
  }
  var dashedLine =
    props.currentStep > 1
      ? toAbsoluteUrl('/media/icons/Custom/Line.svg')
      : toAbsoluteUrl('/media/icons/Custom/LineGray.svg')
  return (
    <div className='BrandSteps' style={{marginTop: '20px'}}>
      <div className='row'>
        {selectedTick(1)}
        <span className='col-8'>
          <div className='fs-8 text-gray-500'>Step 1</div>
          <div className='fw-bolder'>Enter Company Details</div>
        </span>
      </div>
      <span className='ms-1 BrandLine svg-icon svg-icon-3x'>
        <KTSVG
          path={
            props.currentStep > 1
              ? toAbsoluteUrl('/media/icons/Custom/LineGreen.svg')
              : toAbsoluteUrl('/media/icons/Custom/Line.svg')
          }
        />
      </span>
      <div className='row mt-10'>
        {selectedTick(2)}
        <span className='col-8'>
          <div className='fs-8 text-gray-500'>Step 2</div>
          <div className='fw-bolder'>
            {props.type == 'Agency' ? 'Add Agency Details' : 'Add Brand Information'}
          </div>
        </span>
      </div>
      <span className='ms-1 BrandLine svg-icon svg-icon-3x'>
        <KTSVG
          path={
            props.currentStep > 2 ? toAbsoluteUrl('/media/icons/Custom/LineGreen.svg') : dashedLine
          }
        />
      </span>
      <div className='row mt-10'>
        {selectedTick(3)}
        <span className='col-8'>
          <div className='fs-8 text-gray-500'>Step 3</div>
          <div className='fw-bolder'>Link Social Media</div>
        </span>
      </div>
    </div>
  )
}
