import React, {useEffect, useState} from 'react'
import {AgencyView} from './AgencyView'
import './basic.css'
import Select from 'react-select'
import clsx from 'clsx'
import {Link, useHistory} from 'react-router-dom'
import { useFormik } from 'formik'
import httpService from '../../setup/http/httpServices'
import { message } from 'antd'

const brands: any = [
  {
    value:'0',
    label:'Industry'
  },
  {
    value: '1',
    label: 'Airlines',
  },
  {
    value: '2',
    label: 'Beauty/Makeup',
  },
  {
    value: '3',
    label: 'Entertainment/Comedy',
  },
  {
    value: '4',
    label: 'Fashion',
  },
  {
    value: '5',
    label: 'Finance',
  },
  {
    value: '6',
    label: 'Fintech',
  },
  {
    value: '7',
    label: 'Fitness',
  },
  {
    value: '8',
    label: 'Food',
  },
  {
    value: '9',
    label: 'Natural & Safe Skincare Products',
  },
  {
    value: '10',
    label: 'Parenting',
  },
  {
    value: '11',
    label: 'Retail',
  },
  {
    value: '12',
    label: 'Skincare & Beauty',
  },
  {
    value: '13',
    label: 'Tech',
  },
  {
    value: '14',
    label: 'Travel',
  },

]

var initialValues = {
  releventIndustry: '',
  workedWithInfluencerBefore: false,
  selectionPreferences: [],
  collaborationLookingFor: 'Long Term',
}



export function BrandQuestion() {
  const [brand, setBrand] = useState({
    value: '0',
    label: 'Industry',
  })
  const history: any = useHistory()
  const [exp, setExp] = useState('Yes')
  const [infArr, setInfArr] = useState([])
  const [collab, setCollab] = useState('Long Term')
  const [loading,setLoading] = useState(false)

  

  const formik = useFormik({
    initialValues: initialValues,
    // validationSchema: registrationSchema,
    enableReinitialize: true,
    onSubmit: () => {
      setLoading(true)
      setTimeout(() => {
        try {
          let data = {
            releventIndustry: brand.label,
            workedWithInfluencerBefore: exp==="Yes"?true:false,
            selectionPreferences: infArr,
            collaborationLookingFor: collab,
          }
          httpService.patchData(
            '/brand-master/questions/'+history.location.state.id,
            data,
            (response:any)=>{
              setLoading(false)
              message.success("Answers saved!")
              // history.push({
              //   pathname: '/master/profile',
              //   state: history.location.state,
              // })
            },
            (error: any) => {
              // message.error(error?.message)
              // handleShow1()
            }
            )
          
        } catch {
          setLoading(false)
          // setSubmitting(false)
          // setStatus('Registration process has broken')
          // Remove logs when not needed
        }
      }, 1000)
      
    },
  })
  useEffect(()=>{
    httpService.getData(
      '/brand-master/'+history.location.state.id,
      (response:any)=>{
        setCollab(response.data.data.brand.questions.collaborationLookingFor)
        setInfArr(response.data.data.brand.questions.selectionPreferences)
        if(response.data.data.brand.questions.workedWithInfluencerBefore){
          setExp('Yes')
        } else {
          setExp('No')
        }
        brands.forEach(brand =>{
          if(brand.label === response.data.data.brand.questions.releventIndustry){
            setBrand(brand)
          }
        })
      },
      (error: any) => {
        // message.error(error?.message)
        // handleShow1()
      }
    )
    
  },[])

  const handleInfChange = (s:any)=>{
    if(infArr.includes(s)){
      let newArr = [...infArr]
      let n = newArr.indexOf(s)
      newArr.splice(n,1)
      setInfArr(newArr)
    } else{
      let newArr = [...infArr]
      newArr.push(s)
      setInfArr(newArr)
    }
    
  }

  return (
    <div className='Outer d-flex'>
      <AgencyView 
        active={5}
        // pageType={history?.location?.state?.pageType} 
        details={history?.location?.state?.profileDetails}
        id={history?.location?.state?.id}
        type={history?.location?.state?.type}
        name={history?.location?.state?.name} 
        />
      <form className='view-questions' onSubmit={formik.handleSubmit}>
        <div className='question-card'>
          <h2 className='question-label'>1. Associated Industry</h2>
          <div className='question-input'>
            <Select
              options={brands}
              value={brand}
              isMulti={false}
              name='brandName'
              placeholder='Select Industry'
              styles={{
                menuList: (provided, state) => ({
                  ...provided,
                  height: '100px',
                }),
              }}
              className={clsx(
                'text-gray-500 form-control form-control-solid bg-gray-100 p-0 border-0 categorySelect'
              )}
              onChange={(e: any) => {
                setBrand(e)
              }}
            />
          </div>
        </div>

        <div className='question-card'>
          <h2 className='question-label'>
            2. Experience working with an Influencer or influencer marketing?
          </h2>
          <div className='question-input-button'>
            <button className={exp === 'Yes' ? 'active' : ''} onClick={() => setExp('Yes')} type="button">
              Yes
            </button>
            <button className={exp === 'No' ? 'active' : ''} onClick={() => setExp('No')} type="button">
              No
            </button>
          </div>
        </div>

        <div className='question-card card-checkbox'>
          <h2 className='question-label'>3. Defining an ideal influencer</h2>
          <div className='question-input-checkbox container'>
            <div className='row'>
              <div className='col checkbox-col'>
                <input
                  type='checkbox'
                  value='Quality Audience'
                  onChange={()=>handleInfChange("qualityAudience")}
                  checked={infArr.includes("qualityAudience")}
                />
                <label>Quality Audience</label>
              </div>
              <div className='col checkbox-col'>
                <input
                  type='checkbox'
                  value='Engagement Rate'
                  onChange={()=>handleInfChange("engagementRate")}
                  checked={infArr.includes("engagementRate")}
                />
                <label>Engagement Rate</label>
              </div>
              <div className='col checkbox-col'>
                <input
                  type='checkbox'
                  value='Gender Ratio'
                  onChange={()=>handleInfChange("genderRatio")}
                  checked={infArr.includes("genderRatio")}
                />
                <label>Gender Ratio</label>
              </div>
              <div className='col checkbox-col'>
                <input
                  type='checkbox'
                  value='Followers Count'
                  onChange={()=>handleInfChange("followersCount")}
                  checked={infArr.includes("followersCount")}
                />
                <label>Followers Count</label>
              </div>
              <div className='col checkbox-col'>
                <input
                  type='checkbox'
                  value='Location'
                  onChange={()=>handleInfChange("location")}
                  checked={infArr.includes("location")}
                />
                <label>Location</label>
              </div>
              <div className='col checkbox-col'>
                <input
                  type='checkbox'
                  value='Age Group'
                  onChange={()=>handleInfChange("ageGroup")}
                  checked={infArr.includes("ageGroup")}
                />
                <label>Age Group</label>
              </div>
            </div>
          </div>
        </div>

        <div className='question-card'>
          <h2 className='question-label'>4. Looking for what kind of collaboration?</h2>
          <div className='question-input-button'>
            <button
              className={collab === 'Long term' ? 'active' : ''}
              onClick={() => setCollab('Long term')}
              type="button"
            >
              Long-term
            </button>
            <button
              className={collab === 'Short term' ? 'active' : ''}
              onClick={() => setCollab('Short term')}
              type="button"
            >
              Short-term
            </button>
          </div>
        </div>
        <div className='d-flex pt-4 pe-5'>
          <Link
            to={{
              pathname:
                '/master/profile',
              state: history.location.state,
            }}
          >
            <button type='button' className='btn fw-bolder btn-gray-200 CompanyDetailButton'>
              Cancel
            </button>
          </Link>
          <button
            type='submit'
            // id='kt_sign_up_submit'
            className='btn create-btn ms-5 CompanyDetailButton'
            disabled={loading}
          >
            {!loading && <span className='indicator-label'>Update</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span> 
              // <span className='indicator-label'>Update</span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}
