import React, {useState} from 'react'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {Link} from 'react-router-dom'

function Brand_Step2() {
  const [yes, setYes] = useState(false)
  const [no, setNo] = useState(false)
  return (
    <div style={{width: '546px'}}>
      <div style={{fontSize: '36px', fontWeight: 700}}>Step 2 of 4</div>
      <div className='fs-14 m16'>
        Experience working with an Influencer or influencer marketing?
      </div>
      <div className='d-flex w-100 m16'>
        <div
          className={
            yes
              ? 'col rounded flex fs-16 dark-bg text-white pointer'
              : 'col rounded flex fs-16 light-bg pointer'
          }
          style={{height: '72px', marginRight: '16px'}}
          onClick={() => {
            setYes(true)
            setNo(false)
          }}
        >
          <span>Yes</span>
        </div>
        <div
          className={
            no
              ? 'col rounded flex fs-16 dark-bg text-white pointer'
              : 'col rounded flex fs-16 light-bg pointer'
          }
          style={{height: '72px'}}
          onClick={() => {
            setNo(true)
            setYes(false)
          }}
        >
          <span>No</span>
        </div>
      </div>
      <div className='d-flex flex-column m16'>
        <Link to='/auth/register/step3'>
          <button
            className='w-100 btn btn-primary rounded'
            style={{fontSize: '14px', fontWeight: 600}}
            disabled={!yes && !no}
          >
            Next
          </button>
        </Link>
        <Link to='/auth/register/step1'>
          <button className='w-100 btn rounded m8' style={{fontSize: '14px', fontWeight: 600}}>
            Go back
          </button>
        </Link>
      </div>
    </div>
  )
}

export default Brand_Step2
