import clsx from 'clsx'
import { FC } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { IUserModel } from '../../../../app/modules/auth/models/UserModel'
import { RootState } from '../../../../setup'
import { KTSVG } from '../../../helpers'
import { HeaderUserMenu } from '../../../partials'
import { useLayout } from '../../core'
import { BottomMenu } from './BottomMenu'
import './font.css'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'w-10px h-10px w-md-40px h-md-40px mt-3',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
  toolbarButtonIconSizeClass = 'svg-icon-1'

const Topbar: FC = () => {
  const user: IUserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as IUserModel
  const { config } = useLayout()
  const history: any = useHistory()

  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>

        {/* <div
          className={clsx(
            'btn btn-icon btn-active-light-primary position-relative me-3',
            toolbarButtonHeightClass
          )}
          onClick={(e: any) => {
            history.push({
              pathname: '/',
              state: { type: 'Notifications' },
            })
          }}
        >
          <KTSVG path='/media/icons/Custom/Search.svg' className={toolbarButtonIconSizeClass} />
        </div>
        <div
          className={clsx(
            'btn btn-icon btn-active-light-primary position-relative me-3',
            toolbarButtonHeightClass
          )}
          onClick={(e: any) => {
            history.push({
              pathname: '/',
              state: { type: 'Notifications' },
            })
          }}
        >
          <KTSVG path='/media/icons/Custom/Phone.svg' className={toolbarButtonIconSizeClass} />
        </div>
        <div
          className={clsx(
            'btn btn-icon btn-active-light-primary position-relative me-3',
            toolbarButtonHeightClass
          )}
          onClick={(e: any) => {
            history.push({
              pathname: '/',
              state: { type: 'Notifications' },
            })
          }}
        >
          <KTSVG path='/media/icons/Custom/Chat.svg' className={toolbarButtonIconSizeClass} />
        </div>
        <div
          className={clsx(
            'btn btn-icon btn-active-light-primary position-relative me-3',
            toolbarButtonHeightClass
          )}
          onClick={(e: any) => {
            history.push({
              pathname: '/notifications',
              state: { type: 'Notifications' },
            })
          }}
        >
          <KTSVG path='/media/icons/Custom/Bell.svg' className={toolbarButtonIconSizeClass} />
        </div> */}

        <BottomMenu />
      </div>
      {/* begin::User */}
      <div
        className={clsx('d-flex align-items-center UserMenu ', toolbarButtonMarginClass)}
        id='kt_header_user_menu_toggle'
      >
        {/* begin::Toggle */}
        <div style={{ marginTop: '15px', marginRight: '10px', display: '', justifyContent: 'right' }}>
          <div
            style={{ fontWeight: 'bold', justifyContent: 'right', display: 'flex' }}
            className='mb-0  '
          >
            {user?.firstName + ' ' + user?.lastName}
          </div>
          <p style={{ justifyContent: 'right', display: 'flex', color: 'gray' }} className=''>
            {user.permissions.admin && <>Admin</>}
            {user.userType === 'brand' && <>Brand</>}
            {user.userType === 'agency' && <>Agency</>}
          </p>
        </div>
        <span
          className={clsx(
            ' cursor-pointer symbol btn-primary imageBadge',
            toolbarUserAvatarHeightClass
          )}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          {user?.firstName && user?.firstName[0]?.toUpperCase()}
          {user?.lastName && user?.lastName[0]?.toUpperCase()}
          {/* <img src={toAbsoluteUrl('/media/logos/Initials.png')} alt='metronic'  /> */}
        </span>
        <HeaderUserMenu />
        {/* end::Toggle */}
      </div>
      {/* end::User */}

      {/* begin::Aside Toggler */}
      {config.header.left === 'menu' && (
        <div className='d-flex align-items-center d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
            id='kt_header_menu_mobile_toggle'
          >
            <KTSVG path='/media/icons/duotune/text/txt001.svg' className='svg-icon-1' />
          </div>
        </div>
      )}
    </div>
  )
}

export { Topbar }

