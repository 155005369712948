import React, { useEffect, useState } from 'react'
import { AgencyView } from './AgencyView'
import { Instagram, YouTube, Twitter, Facebook } from '@material-ui/icons'
import { toAbsoluteUrl } from '../../_metronic/helpers'
import './basic.css'
import { StatsAll2 } from '../Dashboard/Charts/StatsAll2'
import { Chart } from './Charts/Chart'
import { HorizontalChart } from './Charts/HorizontalChart'
import { DonutChart3 } from './Charts/DonutChart3'
import { DonutChart } from './Charts/DonutChart'
import { useHistory } from 'react-router-dom'
import httpService from '../../setup/http/httpServices'
import { message } from 'antd'
import img from "../Dashboard/Components/agencyDashboard/images/svg/bar-chart.svg"
import chart from "../Dashboard/Components/agencyDashboard/images/svg/Chart.svg"





export function AgencyViewProfile() {
  const history: any = useHistory()
  const pageType = history.location.state.type
  const [day, setDay] = useState(false)
  const [month, setMonth] = useState(true)
  const [profileData, setProfileData] = useState({} as any)
  const [year, setYear] = useState(false)

  const chartDataPoints = [
    { label: 'J', value: 45, bgColor: '#EEEBF5' },
    { label: 'F', value: 60, bgColor: '#EEEBF5' },
    { label: 'M', value: 70, bgColor: '#EEEBF5' },
    { label: 'A', value: 40, bgColor: '#EEEBF5' },
    { label: 'M', value: 30, bgColor: '#EEEBF5' },
    { label: 'J', value: 50, bgColor: '#EEEBF5' },
    { label: 'J', value: 60, bgColor: '#EEEBF5' },
    { label: 'A', value: 55, bgColor: '#513A98' },
  ]
  var campaign = []
  useEffect(() => {
    getAllPriceMatrix()
  }, [history])
  const getAllPriceMatrix = () => {
    httpService.getData(
      pageType === 'BrandView'
        ? '/brand-master-details/' + history.location?.state?.id + '/profile/'
        : '/agency-details/profile/' + history.location?.state?.id,
      (response: any) => {
        if (response.data.brandInformation)
          response.data.agencyInformation = response.data.brandInformation
        setProfileData(response.data)
        campaign.push(response.data?.campaignInformation?.live)
        campaign.push(response.data?.campaignInformation?.completed)
        campaign.push(response.data?.campaignInformation?.underReview)

        // setPriceMatrixParams({
        //   page: response.data.data.page,
        //   limit: response.data.data.limit,
        //   total: response.data.data.total,
        //   search: priceMatrixParams.search,
        // })
      },
      (error: any) => {
        message.error(error.message)
      }
    )
  }
  return (
    <div className='Outer d-flex'>
      <AgencyView
        active={1}
        details={profileData}
        id={history.location?.state?.id}
        type={history?.location?.state?.type}
        name={history?.location?.state?.name}
      />
      <div className='view-profile'>
        <div className='profile'>
          <div className='profile-detail'>
            <h2 className='about'>About the {history.location?.type}</h2>
            <p>{profileData?.agencyInformation?.about}</p>
          </div>
          <div className='profile-detail'>
            <h2>Contact Person</h2>
            <p>{profileData?.agencyInformation?.contactPerson}</p>
          </div>
          <div className='profile-detail'>
            <h2>Email</h2>
            {pageType === 'BrandView' ? (
              <p>{profileData?.agencyInformation?.email}</p>
            ) : (
              <p>{profileData?.agencyInformation?.emailAddress}</p>
            )}
          </div>
          <div className='profile-detail'>
            <h2>Phone Number</h2>
            <p>{profileData?.agencyInformation?.phoneNumber}</p>
          </div>
          <div className='profile-detail'>
            <h2>Website</h2>
            <p>{profileData?.agencyInformation?.website}</p>
          </div>
          <div className='profile-detail'>
            <h2>Industry</h2>
            <p>{profileData?.agencyInformation?.industry}</p>
          </div>
          <div className='profile-detail'>
            <h2>GST Number</h2>
            <p>{profileData?.agencyInformation?.gstNumber}</p>
          </div>
          <div className='profile-detail'>
            <h2>Billing Details</h2>
            <p>{profileData?.agencyInformation?.billingDetails}</p>
          </div>
          <div className='profile-social'>
            <div className='social-icons'>
              {profileData?.agencyInformation?.miscellaneous.map((social: any) => (
                <>
                  {social.socialMedia == 'instagram' && (
                    <a href={social.link} className='icon-link flex'>
                      <Instagram fontSize='large' htmlColor='#FC2B82' />
                    </a>
                  )}
                  {social.socialMedia == 'youtube' && (
                    <a href='/' className='icon-link flex'>
                      <YouTube fontSize='large' htmlColor='#ED1F24' />
                    </a>
                  )}
                  {social.socialMedia == 'twitter' && (
                    <a href='/' className='icon-link flex'>
                      <Twitter fontSize='large' htmlColor='#1DA1F2' />
                    </a>
                  )}
                  {social.socialMedia == 'facebook' && (
                    <a href='/' className='icon-link flex'>
                      <Facebook fontSize='large' htmlColor='#1877F2' />
                    </a>
                  )}
                </>
              ))}
            </div>
            <div className='profile-joined'>
              <p>
                Joined<span> 24 May 2018</span>
              </p>
            </div>
          </div>
        </div>
        <div className='profile-campaigns'>
          <div className='campaign-heading'>
            <h2>Overall Campaigns</h2>
          </div>
        {profileData?.campaignInformation ?
 
          <>
            <div className='campaign-pie'>
            <div>
              {/* <p style={{fontWeight: 'bold', fontSize: '24px', lineHeight: '36px'}}>-</p>
              <p>Campaigns</p> */}
              <DonutChart3 className='w-100 pe-5' campaign={campaign} />
            </div>
          </div>
          </>
          :
          <>
          {/* <img src={chart} alt=""/> */}
          </>
        }
          <div className='campaign-pie-markers'>
            <div className='campaign-pie-marker'>
              <p>
                <div style={{ backgroundColor: '#EC6363' }}></div>Live
              </p>
              <p>{profileData?.campaignInformation?.live}</p>
            </div>
            <div className='campaign-pie-marker'>
              <p>
                <div style={{ backgroundColor: '#2DCA73' }}></div>Completed
              </p>
              <p>{profileData?.campaignInformation?.completed}</p>
            </div>
            <div className='campaign-pie-marker'>
              <p>
                <div style={{ backgroundColor: '#FCA440' }}></div>Under Review
              </p>
              <p>{profileData?.campaignInformation?.underReview}</p>
            </div>
          </div>
        </div>
      </div>

      <div className='view-chart'>
        <div className='influencers-chart'>
          <div className='inf-chart-heading'>
            <div className='inf-chart-heading-left'>
              <h2>Overall Influencers worked with</h2>
            </div>
            <div className='inf-chart-heading-right'>
              <p>
                <div style={{ backgroundColor: '#008ffb' }}></div>Pico
              </p>
              <p>
                <div style={{ backgroundColor: '#00e396' }}></div>Micro
              </p>
              <p>
                <div style={{ backgroundColor: '#feb019' }}></div>Nano
              </p>
              <p>
                <div style={{ backgroundColor: '#ff4560' }}></div>Mega
              </p>
              <p>
                <div style={{ backgroundColor: '#775dd0' }}></div>Exa
              </p>
            </div>
          </div>


          {/* <div className='chart-body '> */}
          {/* <img src={toAbsoluteUrl('/media/logos/bar-chart 1.png')} alt='' />
            <h2>No Influencer Data</h2>
            <p>Create campaign to view associated data</p> */}
          {/* <HorizontalChart className='w-100 pe-5' />
          </div> */}

          {(pageType === "AgencyView" || pageType === "BrandView") && (profileData.influencers === "to be done" || profileData.influencers ==="") ?
            <>
              <div className="No_data">
                <img className='img' src={img} alt=""  />
              <div className="content-box">
                <h2>No Influencer Data</h2>
                <p>Influencers will be available once they are listed</p>
              </div>
              </div>
            </>
            :
            <>
              <div className='chart-body '>
                {/* <img src={toAbsoluteUrl('/media/logos/bar-chart 1.png')} alt='' />
            <h2>No Influencer Data</h2>
            <p>Create campaign to view associated data</p> */}
                <HorizontalChart className='w-100 pe-5' />
              </div>
            </>

          }


        </div>

          
          {
            pageType == "AgencyView" 
            ?
            <>
            <div className='chart-container'>
          <div className='chart-heading'>
            <h2 className='m-0'>Overall Brands Added</h2>
          </div>

          {/* No Data Page */}
          <div className="No_data">
                <img className='img' src={img} alt="" />
              <div className="content-box">
                <h2>No Brands Added</h2>
                <p>Brands will be available once they are listed</p>
                {/* <div className='add_brand_btn'>Add New Brand 😍</div> */}
              </div>
              </div>


        </div>

            </>
            :
            <></>
          }

{/* 
        <div className='chart-container'>
          <div className='chart-heading'>
            <h2 className='m-0'>Overall Brands Added</h2>
          </div> */}

          {/* <div className='chart-topbar'> */}
          {/* <div className='chart-topbar-left'>
              <p>
                <span>-</span> followers
              </p>
              <p>0% vs last month</p>
            </div> */}
          {/* <div className='chart-topbar-left'>
            <div className='d-flex'>
            <h3 className='m-0'>30 Brands Added</h3> 
          </div>
            </div>
            <div className='chart-topbar-right'>
              <div
                onClick={() => {
                  setYear(true)
                  setDay(false)
                  setMonth(false)
                }}
                className={
                  year
                    ? ' w-50px pointer flex bg-nightSky text-white h-25px rounded ms-1'
                    : ' w-50px pointer flex bg-gray-100 text-black h-25px rounded ms-1'
                }
              >
                Year
              </div>
              <div
                onClick={() => {
                  setYear(false)
                  setDay(false)
                  setMonth(true)
                }}
                className={
                  month
                    ? ' w-50px pointer flex bg-nightSky text-white h-25px rounded ms-1'
                    : ' w-50px pointer flex bg-gray-100 text-black h-25px rounded ms-1'
                }
              >
                Month
              </div>
              <div
                onClick={() => {
                  setYear(false)
                  setDay(true)
                  setMonth(false)
                }}
                className={
                  day
                    ? ' w-50px pointer flex bg-nightSky text-white h-25px rounded ms-1'
                    : ' w-50px pointer flex bg-gray-100 text-black h-25px rounded ms-1'
                }
              >
                Day
              </div>
            </div>
          </div>
          <div className='chart-body'> */}
          {/* <img src={toAbsoluteUrl('/media/logos/bar-chart 1.png')} alt="" />
            <h2>No Brands Added</h2>
            <p>Engagement Rate will be available once the campaign is activated</p>
            <button>Add Brand</button> */}
          {/* <StatsAll2 className='w-100 pe-5' Day={day} Month={month} Year={year} />
          </div> */}
          
{/* 
          <div className="No_data">
                <img className='img' src={img} alt=""  />
              <div className="content-box">
                <h2>No Brands Added</h2>
                <p>Brands will be available once they are listed</p>
              </div>
              </div>


        </div> */}




        {/* --------------------------- Impressions  --------------------------------------  */}

        <div className='chart-container'>
          <div className='chart-heading'>
            <h2 className='m-0'>Average Impressions</h2>
          </div>

          {/* 
          <div className='chart-topbar'>
            {/* <div className='chart-topbar-left'>
              <p>
                <span>-</span> impressions
              </p>
              <p>0% vs last month</p>
            </div> */}

          {/* <div className='chart-topbar-left'>
            <div className='d-flex'>
            <h3 className='m-0'>30 Followers</h3> 
          </div>
            </div>
            <div className='chart-topbar-right'>
              <div
                onClick={() => {
                  setYear(true)
                  setDay(false)
                  setMonth(false)
                }}
                className={
                  year
                    ? ' w-50px pointer flex bg-nightSky text-white h-25px rounded ms-1'
                    : ' w-50px pointer flex bg-gray-100 text-black h-25px rounded ms-1'
                }
              >
                Year
              </div>
              <div
                onClick={() => {
                  setYear(false)
                  setDay(false)
                  setMonth(true)
                }}
                className={
                  month
                    ? ' w-50px pointer flex bg-nightSky text-white h-25px rounded ms-1'
                    : ' w-50px pointer flex bg-gray-100 text-black h-25px rounded ms-1'
                }
              >
                Month
              </div>
              <div
                onClick={() => {
                  setYear(false)
                  setDay(true)
                  setMonth(false)
                }}
                className={
                  day
                    ? ' w-50px pointer flex bg-nightSky text-white h-25px rounded ms-1'
                    : ' w-50px pointer flex bg-gray-100 text-black h-25px rounded ms-1'
                }
              >
                Day
              </div>
            </div>
          </div> 
          
          <div className='chart-body'> */}

          {/* <img src={toAbsoluteUrl('/media/logos/bar-chart 1.png')} alt='' />
            <h2>No Impressions</h2>
            <p>Impressions will be available once the campaign is activated</p> */}

          {/* <StatsAll2 className='w-100 pe-5' Day={day} Month={month} Year={year} />
          </div>  */}


          {pageType == "AgencyView" || "BrandView" && profileData.impressions == "to be done" || profileData.impressions == ""
            ? <>
              <div className="No_data">
                <img className='img' src={img} alt=""  />
              <div className="content-box">
                <h2>No Average Impressions</h2>
                <p>Impressions will be available when a campaign is activated</p>
              </div>
              </div>
            </> :
            <>
              <div className='chart-topbar'>
                {/* <div className='chart-topbar-left'>
              <p>
                <span>-</span> impressions
              </p>
              <p>0% vs last month</p>
            </div> */}

                <div className='chart-topbar-left'>
                  <div className='d-flex'>
                    <h3 className='m-0'>30 Followers</h3>
                  </div>
                </div>
                <div className='chart-topbar-right'>
                  <div
                    onClick={() => {
                      setYear(true)
                      setDay(false)
                      setMonth(false)
                    }}
                    className={
                      year
                        ? ' w-50px pointer flex bg-nightSky text-white h-25px rounded ms-1'
                        : ' w-50px pointer flex bg-gray-100 text-black h-25px rounded ms-1'
                    }
                  >
                    Year
                  </div>
                  <div
                    onClick={() => {
                      setYear(false)
                      setDay(false)
                      setMonth(true)
                    }}
                    className={
                      month
                        ? ' w-50px pointer flex bg-nightSky text-white h-25px rounded ms-1'
                        : ' w-50px pointer flex bg-gray-100 text-black h-25px rounded ms-1'
                    }
                  >
                    Month
                  </div>
                  <div
                    onClick={() => {
                      setYear(false)
                      setDay(true)
                      setMonth(false)
                    }}
                    className={
                      day
                        ? ' w-50px pointer flex bg-nightSky text-white h-25px rounded ms-1'
                        : ' w-50px pointer flex bg-gray-100 text-black h-25px rounded ms-1'
                    }
                  >
                    Day
                  </div>
                </div>
              </div>

              <div className='chart-body'>

                {/* <img src={toAbsoluteUrl('/media/logos/bar-chart 1.png')} alt='' />
            <h2>No Impressions</h2>
            <p>Impressions will be available once the campaign is activated</p> */}

                <StatsAll2 className='w-100 pe-5' Day={day} Month={month} Year={year} />
              </div>
            </>
          }
        </div>

        {/* --------------------------- Impressions End --------------------------------------  */}


        {/* --------------------------- Reach -------------------------------------------------  */}

        <div className='chart-container'>
          <div className='chart-heading'>
            <h2 className='m-0'>Average Reach</h2>
          </div>


          {/* <div className='chart-topbar'> */}
          {/* <div className='chart-topbar-left'>
              <p>
                <span>-</span> followers
              </p>
              <p>0% vs last month</p>
            </div> */}
          {/* <div className='chart-topbar-left'>
            <div className='d-flex'>
            <h3 className='m-0'>30 Followers</h3> 
          </div>
            </div>
            <div className='chart-topbar-right'>
              <div
                onClick={() => {
                  setYear(true)
                  setDay(false)
                  setMonth(false)
                }}
                className={
                  year
                    ? ' w-50px pointer flex bg-nightSky text-white h-25px rounded ms-1'
                    : ' w-50px pointer flex bg-gray-100 text-black h-25px rounded ms-1'
                }
              >
                Year
              </div>
              <div
                onClick={() => {
                  setYear(false)
                  setDay(false)
                  setMonth(true)
                }}
                className={
                  month
                    ? ' w-50px pointer flex bg-nightSky text-white h-25px rounded ms-1'
                    : ' w-50px pointer flex bg-gray-100 text-black h-25px rounded ms-1'
                }
              >
                Month
              </div>
              <div
                onClick={() => {
                  setYear(false)
                  setDay(true)
                  setMonth(false)
                }}
                className={
                  day
                    ? ' w-50px pointer flex bg-nightSky text-white h-25px rounded ms-1'
                    : ' w-50px pointer flex bg-gray-100 text-black h-25px rounded ms-1'
                }
              >
                Day
              </div>
            </div>
          </div>
          <div className='chart-body'> */}
          {/* <img src={toAbsoluteUrl('/media/logos/bar-chart 1.png')} alt='' />
            <h2>No Avg. Reach</h2>
            <p>Reach will be available once the campaign is activated</p> */}
          {/* <StatsAll2 className='w-100 pe-5' Day={day} Month={month} Year={year} />
          </div> */}


          {pageType == "AgencyView" || "BrandView" && profileData.reach == "to be done" || profileData.reach == "" ?
            <>
               <div className="No_data">
                <img className='img' src={img} alt=""  />
              <div className="content-box">
                <h2>No Average Reach</h2>
                <p>Reach will be available when a campaign is activated</p>
              </div>
              </div>
            </>
            :
            <>
              <div className='chart-topbar'>
                {/* <div className='chart-topbar-left'>
              <p>
                <span>-</span> followers
              </p>
              <p>0% vs last month</p>
            </div> */}
                <div className='chart-topbar-left'>
                  <div className='d-flex'>
                    <h3 className='m-0'>30 Followers</h3>
                  </div>
                </div>
                <div className='chart-topbar-right'>
                  <div
                    onClick={() => {
                      setYear(true)
                      setDay(false)
                      setMonth(false)
                    }}
                    className={
                      year
                        ? ' w-50px pointer flex bg-nightSky text-white h-25px rounded ms-1'
                        : ' w-50px pointer flex bg-gray-100 text-black h-25px rounded ms-1'
                    }
                  >
                    Year
                  </div>
                  <div
                    onClick={() => {
                      setYear(false)
                      setDay(false)
                      setMonth(true)
                    }}
                    className={
                      month
                        ? ' w-50px pointer flex bg-nightSky text-white h-25px rounded ms-1'
                        : ' w-50px pointer flex bg-gray-100 text-black h-25px rounded ms-1'
                    }
                  >
                    Month
                  </div>
                  <div
                    onClick={() => {
                      setYear(false)
                      setDay(true)
                      setMonth(false)
                    }}
                    className={
                      day
                        ? ' w-50px pointer flex bg-nightSky text-white h-25px rounded ms-1'
                        : ' w-50px pointer flex bg-gray-100 text-black h-25px rounded ms-1'
                    }
                  >
                    Day
                  </div>
                </div>
              </div>
              <div className='chart-body'>
                {/* <img src={toAbsoluteUrl('/media/logos/bar-chart 1.png')} alt='' />
            <h2>No Avg. Reach</h2>
            <p>Reach will be available once the campaign is activated</p> */}
                <StatsAll2 className='w-100 pe-5' Day={day} Month={month} Year={year} />
              </div>
            </>
          }

        </div>
        {/* --------------------------- React End --------------------------------------  */}


        {/* --------------------------- EngagementRate --------------------------------------  */}

        <div className='chart-container'>
          <div className='chart-heading'>
            <h2 className='m-0'>Average Engagement Rate</h2>
          </div>
          {/* 
          <div className='chart-topbar'>
            <div className='chart-topbar-left'>
            <div className='d-flex'>
            <h3 className='m-0'>30 Followers</h3> 
          </div>
            </div>
            <div className='chart-topbar-right'>
              <div
                onClick={() => {
                  setYear(true)
                  setDay(false)
                  setMonth(false)
                }}
                className={
                  year
                    ? ' w-50px pointer flex bg-nightSky text-white h-25px rounded ms-1'
                    : ' w-50px pointer flex bg-gray-100 text-black h-25px rounded ms-1'
                }
              >
                Year
              </div>
              <div
                onClick={() => {
                  setYear(false)
                  setDay(false)
                  setMonth(true)
                }}
                className={
                  month
                    ? ' w-50px pointer flex bg-nightSky text-white h-25px rounded ms-1'
                    : ' w-50px pointer flex bg-gray-100 text-black h-25px rounded ms-1'
                }
              >
                Month
              </div>
              <div
                onClick={() => {
                  setYear(false)
                  setDay(true)
                  setMonth(false)
                }}
                className={
                  day
                    ? ' w-50px pointer flex bg-nightSky text-white h-25px rounded ms-1'
                    : ' w-50px pointer flex bg-gray-100 text-black h-25px rounded ms-1'
                }
              >
                Day
              </div>
            </div>
          </div>
          <div className='chart-body'> */}
          {/* <img src={toAbsoluteUrl('/media/logos/bar-chart 1.png')} alt='' />
            <h2>No Engagement Rate</h2>
            <p>Engagement Rate will be available once the campaign is activated</p> */}
          {/* <StatsAll2 className='w-100 pe-5' Day={day} Month={month} Year={year} />
          </div> */}


          {pageType == "AgencyView" || "BrandView" && profileData.engagementRate == "to be done" || profileData.engagementRate == ""
            ?
            <>
               <div className="No_data">
                <img className='img' src={img} alt=""  />
              <div className="content-box">
                <h2>No Engagement Rate</h2>
                <p>Engagement Rate will be available once the campaign is activated</p>
              </div>
              </div>
            </>
            :
            <>
              <div className='chart-topbar'>
                <div className='chart-topbar-left'>
                  <div className='d-flex'>
                    <h3 className='m-0'>30 Followers</h3>
                  </div>
                </div>
                <div className='chart-topbar-right'>
                  <div
                    onClick={() => {
                      setYear(true)
                      setDay(false)
                      setMonth(false)
                    }}
                    className={
                      year
                        ? ' w-50px pointer flex bg-nightSky text-white h-25px rounded ms-1'
                        : ' w-50px pointer flex bg-gray-100 text-black h-25px rounded ms-1'
                    }
                  >
                    Year
                  </div>
                  <div
                    onClick={() => {
                      setYear(false)
                      setDay(false)
                      setMonth(true)
                    }}
                    className={
                      month
                        ? ' w-50px pointer flex bg-nightSky text-white h-25px rounded ms-1'
                        : ' w-50px pointer flex bg-gray-100 text-black h-25px rounded ms-1'
                    }
                  >
                    Month
                  </div>
                  <div
                    onClick={() => {
                      setYear(false)
                      setDay(true)
                      setMonth(false)
                    }}
                    className={
                      day
                        ? ' w-50px pointer flex bg-nightSky text-white h-25px rounded ms-1'
                        : ' w-50px pointer flex bg-gray-100 text-black h-25px rounded ms-1'
                    }
                  >
                    Day
                  </div>
                </div>
              </div>
              <div className='chart-body'>
                {/* <img src={toAbsoluteUrl('/media/logos/bar-chart 1.png')} alt='' />
            <h2>No Engagement Rate</h2>
            <p>Engagement Rate will be available once the campaign is activated</p> */}
                <StatsAll2 className='w-100 pe-5' Day={day} Month={month} Year={year} />
              </div>
            </>
          }
          {/* --------------------------- EngagementRate End--------------------------------------  */}


        </div>
      </div>
    </div>
  )
}
