import {LeftOutlined, RightOutlined, SearchOutlined} from '@ant-design/icons'
import {Input, message, Rate} from 'antd'
import {useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import Select from 'react-select'
import httpService from '../../setup/http/httpServices'
import {KTSVG, toAbsoluteUrl} from '../../_metronic/helpers'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../setup'
import {IUserModel} from '../modules/auth/models/UserModel'

export function Rating() {
  const [sort1, setSort1] = useState('rotate-90 ms-2')
  const [sort2, setSort2] = useState('rotate-90 ms-2')
  const [brands, setBrands] = useState([])
  const [agencies, setAgencies] = useState([])
  const [campaigns, setCampaigns] = useState([])
  const [brand, setBrand] = useState(null)
  const [agency, setAgency] = useState(null)
  const [rating, setRating] = useState('Influencer')
  const [type, setType] = useState('Influencer')
  const [campaignDetails, setCampaignDetails] = useState(null)
  const [campaignStats, setCampaignStats] = useState(null)
  const [campaignApplications, setCampaignApplications] = useState(null)
  const history: any = useHistory()
  const user: IUserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as IUserModel

  const getBrands = (x: any) => {
    return {
      label: (
        <div>
          <img
            src={x.brandDetails.avatar}
            height='32px'
            width='32px'
            style={{borderRadius: '40px'}}
          />{' '}
          {x.brandDetails.brandName}
        </div>
      ),
      value: x._id,
    }
  }
  const getAgencies = (x: any) => {
    return {
      label: (
        <div>
          <img
            src={x.agencyDetails.avatar}
            height='32px'
            width='32px'
            style={{borderRadius: '40px'}}
          />{' '}
          {x.agencyDetails.agencyName}
        </div>
      ),
      value: x._id,
    }
  }
  const getAllMasters = () => {
    getBrand()
    httpService.getData(
      '/agency-master?limit=0',
      (response: any) => {
        setAgencies(
          [
            {
              agencyDetails: {
                avatar: toAbsoluteUrl('/media/logos/categoryIcon.png'),
                agencyName: 'Select Agency',
              },
            },
          ].concat(response.data.data.agencies)
        )
      },
      (error: any) => {
        message.error(error?.message)
      }
    )
    httpService.getData(
      '/campaign-master?limit=0&&brand=' +
        (brand?.value ? brand?.value : '') +
        (user.userType === 'brand' ? user.brandId : '') +
        '&&agency=' +
        (agency?.value ? agency?.value : '') +
        (user.userType === 'agency' ? user.agencyId : '') +
        '&&status=completed',
      (response: any) => {
        response.data.data.campaigns.map((x: any) => {
          x['value'] = x._id
          x['label'] = x.campaignDetails.name
          return x
        })
        setCampaigns([{value: '', label: 'Select Campaign'}].concat(response.data.data.campaigns))
      },
      (error: any) => {
        message.error(error.message)
      }
    )
  }
  const getBrand = () => {
    httpService.getData(
      '/brand-master?limit=0',
      (response: any) => {
        let list = []
        response.data.data.brands.forEach((x: any) => {
          if (agency && agency.value === x?.companyDetails?.agencyAssociated?._id) {
            list.push(x)
          } else if (!agency || agency?.label?.props?.children[2] === 'Select Agency') {
            setBrands(response.data.data.brands)
          }
        })
        if (agency && agency?.label?.props?.children[2] !== 'Select Agency') {
          setBrands(list)
        }
      },
      (error: any) => {}
    )
  }
  useEffect(() => {
    getAllMasters()
  }, [brand, agency])

  const getInfluencers = (value, search?) => {
    httpService.getData(
      '/campaign-master/' + value,
      (response) => {
        setCampaignDetails(response.data.data.campaign)
        httpService.getData(
          '/content-master/rating/' + value + '?search=' + (search || ''),
          (response: any) => {
            setCampaignStats(response.data.data.stats)
            setCampaignApplications(response.data.data.campaignApplications)
          },
          (error: any) => {
            setCampaignStats(null)
            setCampaignApplications(null)
          }
        )
      },
      (error) => {
        message.error(error.message)
      }
    )
  }

  useEffect(() => {}, [campaignDetails?.id])
  return (
    <div style={{minWidth: '1336px'}}>
      <div className='container-fluid flex justify-content-start bg-white h-75px w-100 border'>
        <div className='flex form-check form-check-custom form-check-solid'>
          {!(user.userType === 'brand' || user.userType === 'agency') && (
            <>
              <div className='w240'>
                <Select
                  options={agencies.map((x) => getAgencies(x))}
                  isMulti={false}
                  value={agency}
                  placeholder={
                    <div>
                      <img
                        src={toAbsoluteUrl('/media/logos/categoryIcon.png')}
                        height='32px'
                        width='32px'
                        style={{borderRadius: '40px'}}
                      />{' '}
                      Select Agency{' '}
                    </div>
                  }
                  styles={{
                    menuList: (provided, state) => ({
                      ...provided,
                      height: 'auto',
                      maxHeight: '250px',
                    }),
                  }}
                  className={'form-select p-0 categorySelect w-100'}
                  onChange={(e: any) => {
                    setAgency(e)
                  }}
                />
              </div>
              <LeftOutlined style={{marginLeft: '24px', fontSize: '10px'}} />
              <RightOutlined style={{marginRight: '24px', fontSize: '10px'}} />
              <div className='w240'>
                <Select
                  options={brands.map((x) => getBrands(x))}
                  isMulti={false}
                  value={brand}
                  placeholder={
                    <div>
                      <img
                        src={toAbsoluteUrl('/media/logos/categoryIcon.png')}
                        height='32px'
                        width='32px'
                        style={{borderRadius: '40px'}}
                      />{' '}
                      Select Brand{' '}
                    </div>
                  }
                  styles={{
                    menuList: (provided, state) => ({
                      ...provided,
                      height: 'auto',
                      maxHeight: '250px',
                    }),
                  }}
                  className={'form-select p-0 categorySelect w-100'}
                  onChange={(e: any) => {
                    setBrand(e)
                  }}
                />
              </div>
              <RightOutlined style={{marginLeft: '24px', marginRight: '24px', fontSize: '10px'}} />
            </>
          )}
          <select
            className='form-select w240'
            onChange={(e: any) => {
              getInfluencers(e.target.value)
            }}
          >
            <option value='' selected disabled hidden>
              Campaign Name
            </option>
            {campaigns.length > 0 &&
              campaigns.map((campaign: any) => (
                <option key={campaign.value} value={campaign?.value}>
                  {campaign.label}
                </option>
              ))}
          </select>
        </div>
      </div>
      {campaignApplications && campaignStats ? (
        <div className='w-100 d-flex'>
          <div className=' w-350px ms-6'>
            <div
              onClick={() => setRating('Influencer')}
              className={
                rating === 'Influencer'
                  ? 'basicDiv pointer shadow-sm'
                  : 'basicDiv pointer bg-gray-200 '
              }
            >
              <h2 className='mb-1 flex'>Average Influencer Ratings</h2>
              <p className='text-center m-0'>
                Ratings provided to the influencers by brand/agency based on their overall
                performance on the campaign
              </p>
              <span className='flex'>
                <Rate allowHalf value={campaignStats?.influencerRating || 0} disabled />
              </span>
              <p className='text-center m-0 fs-8'>
                {campaignStats?.influencerRating.toFixed(1) || 0} out of 5 (
                {campaignApplications.length} influencers)
              </p>
            </div>
            {/* <div
                // onClick={() => setRating('Sehaye')}
                style={{ cursor: 'not-allowed' }}
                className={
                  rating === 'Sehaye' ? 'basicDiv shadow-sm' : 'basicDiv bg-gray-200'
                }
              >
                <h2 className='mb-1 flex'>Average ratings for Sehaye</h2>
                <p className='text-center m-0'>
                  Ratings provided by brand/agency and influencers for the platform (Sehaye)
                </p>
                <span className='flex'>
                  <Rate allowHalf value={campaignStats?.campaignRating} disabled />
                </span>
                <p className='text-center m-0 fs-8'>{campaignStats?.campaignRating || 0} out of 5 (60 influencers)</p>
              </div> */}
          </div>
          <div className='w-75  me-20' style={{minWidth: '1000px'}}>
            <div className='socialTable ms-6 h-750px w-100 me-6'>
              <div className='categoryTableHeader mb-6'>
                <div className='mt-3 w-auto ps-5'>
                  <h3 style={{cursor: 'default'}}>
                    {rating == 'Sehaye' ? 'Average ratings for Sehaye' : 'Average Influencer Ratings'}
                  </h3>
                  <p className='fs-7 m-0 text-gray-400'>
                    {campaignApplications.length} influencers
                  </p>
                  {/* {totalCount && (
            <p style={{cursor: 'default'}} className='text-gray-500 fs-7'>
              {totalCount} Social Medias
            </p>
          )} */}
                </div>
                {rating == 'Sehaye' && (
                  <div className='flex justify-content-start ms-6'>
                    <ul className='nav nav-pills'>
                      <li className='nav-item'>
                        <a
                          onClick={() => setType('Influencer')}
                          className={
                            type == 'Influencer'
                              ? 'nav-link d-flex w-auto h-40px flex text-white fw-bolder bg-purple active'
                              : 'nav-link d-flex w-auto h-40px flex text-gray-400 fw-bold'
                          }
                          // className={highlightActive(
                          //   !['completed', 'pending', 'onHold', 'cancelled'].includes(
                          //     transactionListParams.search
                          //   )
                          // )}
                          // onClick={(e) => {
                          //   transactionListParams.search = ''
                          //   setTransactionListParams({...transactionListParams})
                          //   getTransactions()
                          // }}
                        >
                          Influencer
                        </a>
                      </li>
                      <li className='nav-item '>
                        <a
                          onClick={() => setType('Brand/Agency')}
                          className={
                            type == 'Brand/Agency'
                              ? 'nav-link d-flex w-auto h-40px flex text-white fw-bolder bg-purple active'
                              : 'nav-link d-flex w-auto h-40px flex text-gray-400 fw-bold'
                          }
                          // className={highlightActive(transactionListParams.search === 'completed')}
                          // onClick={(e) => {
                          //   transactionListParams.search = 'completed'
                          //   setTransactionListParams({...transactionListParams})
                          //   getTransactions()
                          // }}
                        >
                          Brand/Agency
                        </a>
                      </li>
                    </ul>
                  </div>
                )}
                <div className='TableHeaderSearch d-flex justify-content-end align-items-center me-6 col'>
                  <link
                    href='https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css'
                    rel='stylesheet'
                  />
                  <Input
                    placeholder='Search'
                    style={{width: '250px', height: '40px'}}
                    className='form-control-solid bg-gray-100'
                    onChange={(e: any) => getInfluencers(campaignDetails._id, e.target.value)}
                    prefix={
                      <>
                        <SearchOutlined />
                      </>
                    }
                  />
                </div>
              </div>
              <div className='card-body h-700px mt-0 p-0 d-flex flex-column justify-content-between'>
                {/* begin::Table container */}
                <div className='table-responsive'>
                  {/* begin::Table */}
                  <table className='table min-h-95 table-row-gray-300 align-middle gs-0 gy-4'>
                    {/* begin::Table head */}
                    <thead>
                      <tr className='fw-bolder text-muted'>
                        {type === 'Influencer' && (
                          <>
                            <th className='bg-primary bg-opacity-5 ps-5 min-w-400px'>
                              <span className='d-flex'>
                                Influencer
                                {/* <a
                            className={sort1}
                            onClick={() => {
                              if (sort1 == 'rotate-90 ms-2') {
                                setSort1('rotate-270 ms-2')
                              } else {
                                setSort1('rotate-90 ms-2')
                              }
                            }}
                          >
                            <KTSVG
                              className='svg-icon svg-icon-sm svg-icon-gray-700 flex h-20px pointer'
                              path={toAbsoluteUrl('/media/icons/Custom/SideArrow.svg')}
                            />
                          </a> */}
                              </span>
                            </th>
                            <th className='bg-primary bg-opacity-5 min-w-100px'>
                              <span className='d-flex'>
                                Type
                                {/* <a
                            className={sort2}
                            onClick={() => {
                              if (sort2 == 'rotate-90 ms-2') {
                                setSort2('rotate-270 ms-2')
                              } else {
                                setSort2('rotate-90 ms-2')
                              }
                            }}
                          >
                            <KTSVG
                              className='svg-icon svg-icon-sm svg-icon-gray-700 flex h-20px pointer'
                              path={toAbsoluteUrl('/media/icons/Custom/SideArrow.svg')}
                            />
                          </a> */}
                              </span>
                            </th>
                            <th className='bg-primary bg-opacity-5 min-w-100px text-right'>
                              Followers
                            </th>
                            <th className='bg-primary bg-opacity-5 min-w-100px text-right'>
                              Avg. Views
                            </th>
                            <th className='bg-primary bg-opacity-5 min-w-100px text-right'>
                              Eng. Rate
                            </th>
                            <th className='bg-primary bg-opacity-5 min-w-100px '>Ratings</th>
                          </>
                        )}
                        {type == 'Brand/Agency' && (
                          <>
                            <th className='bg-primary bg-opacity-5 ps-5 min-w-400px'>
                              <span className='d-flex'>
                                Brand Name
                                {/* <a
                            className={sort1}
                            onClick={() => {
                              if (sort1 == 'rotate-90 ms-2') {
                                setSort1('rotate-270 ms-2')
                              } else {
                                setSort1('rotate-90 ms-2')
                              }
                            }}
                          >
                            <KTSVG
                              className='svg-icon svg-icon-sm svg-icon-gray-700 flex h-20px pointer'
                              path={toAbsoluteUrl('/media/icons/Custom/SideArrow.svg')}
                            />
                          </a> */}
                              </span>
                            </th>
                            <th className='bg-primary bg-opacity-5 min-w-100px'>
                              <span className='d-flex'>
                                Campaign Type
                                {/* <a
                            className={sort2}
                            onClick={() => {
                              if (sort2 == 'rotate-90 ms-2') {
                                setSort2('rotate-270 ms-2')
                              } else {
                                setSort2('rotate-90 ms-2')
                              }
                            }}
                          >
                            <KTSVG
                              className='svg-icon svg-icon-sm svg-icon-gray-700 flex h-20px pointer'
                              path={toAbsoluteUrl('/media/icons/Custom/SideArrow.svg')}
                            />
                          </a> */}
                              </span>
                            </th>
                            <th className='bg-primary bg-opacity-5 min-w-100px text-center'>
                              Influencers
                            </th>
                            <th className='bg-primary bg-opacity-5 min-w-100px text-center'>
                              Campaign End Date
                            </th>
                            <th className='bg-primary bg-opacity-5 min-w-100px '>Ratings</th>
                          </>
                        )}
                      </tr>
                    </thead>
                    {/* end::Table head */}
                    {/* begin::Table body */}
                    <tbody>
                      {type === 'Influencer' &&
                        campaignApplications?.map((influencer: any) => (
                          <tr>
                            <td className='fw-bolder'>
                              <div className='d-flex ps-5 align-items-center'>
                                <div className='symbol symbol-30px symbol-circle me-5'>
                                  <img
                                    src={
                                      influencer?.influencer?.socialMediaDetails[
                                        campaignDetails.campaignDetails.platform.toLowerCase()
                                      ]?.avatar || influencer.influencer.basicInfo.avatar
                                    }
                                    alt=''
                                  />
                                </div>
                                <div className=''>
                                  <span className='text-dark fw-bolder text-hover-primary fs-6'>
                                    {influencer.influencer.basicInfo.name}
                                  </span>
                                  {influencer.influencer.basicInfo.location && (
                                    <p className='m-0 fw-lighter fs-7 mt-1'>
                                      <KTSVG
                                        className='svg-icon svg-icon-gray-300 me-1'
                                        path={toAbsoluteUrl('/media/icons/Custom/mapMarker.svg')}
                                      />
                                      {influencer.influencer.basicInfo.location}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </td>

                            <td>
                              <span
                                style={{borderRadius: '35%'}}
                                className='bg-white fw-bolder p-1 px-3 w-60px border flex'
                              >
                                {influencer.priceMaster.bucketName}
                              </span>
                            </td>
                            <td className='fw-bolder text-right'>
                              <KTSVG
                                className='svg-icon me-1'
                                path={toAbsoluteUrl('/media/icons/Custom/people.svg')}
                              />{' '}
                              {(
                                influencer?.influencer?.socialMediaDetails[
                                  campaignDetails.campaignDetails.platform.toLowerCase()
                                ].basicStats?.followers / 1000
                              ).toFixed(3)}
                              k
                            </td>
                            <td className='fw-bolder text-right'>
                              <KTSVG
                                className='svg-icon me-1'
                                path={toAbsoluteUrl('/media/icons/Custom/eye2.svg')}
                              />{' '}
                              {(
                                influencer?.influencer?.socialMediaDetails[
                                  campaignDetails.campaignDetails.platform.toLowerCase()
                                ].basicStats?.avgViews / 1000
                              ).toFixed(3)}
                              k
                            </td>
                            <td className='fw-bolder text-right'>
                              <KTSVG
                                className='svg-icon-5 me-1'
                                path={toAbsoluteUrl('/media/icons/Custom/percentage.svg')}
                              />{' '}
                              {influencer?.influencer?.socialMediaDetails[
                                campaignDetails.campaignDetails.platform.toLowerCase()
                              ].basicStats?.engagementRate.toFixed(2)}
                              %
                            </td>
                            <td>
                              <Rate
                                allowHalf
                                value={influencer?.rating?.influencerRating || 0}
                                onChange={(event: any) => {
                                  httpService.putData(
                                    '/influencer-onboarding/rate/rateInfluencer/' + influencer._id,
                                    {
                                      rating: event,
                                    },
                                    (success: any) => {
                                      getInfluencers(campaignDetails._id)
                                    },
                                    (error: any) => {}
                                  )
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                      {type === 'Brand/Agency' &&
                        campaignApplications.length > 0 &&
                        campaignApplications?.map((influencer: any) => (
                          <tr>
                            <td className='fw-bolder'>
                              <div className='d-flex ps-5 align-items-center'>
                                <div className='symbol symbol-30px symbol-circle me-5'>
                                  <img
                                    src={
                                      influencer?.influencer?.socialMediaDetails[
                                        campaignDetails.campaignDetails.platform.toLowerCase()
                                      ]?.avatar || influencer.influencer.basicInfo.avatar
                                    }
                                    alt=''
                                  />
                                  {/* <img src={toAbsoluteUrl('/media/logos/logo-2.svg')} alt='' /> */}
                                </div>
                                <div className=''>
                                  <span className='text-dark fw-bolder text-hover-primary fs-6'>
                                    {influencer.influencer.basicInfo.name}
                                  </span>
                                  <p className='m-0 fw-lighter fs-7 mt-1'>
                                    <KTSVG
                                      className='svg-icon svg-icon-gray-300 me-1'
                                      path={toAbsoluteUrl('/media/icons/Custom/mapMarker.svg')}
                                    />
                                    Mumbai,Maharastra
                                  </p>
                                </div>
                              </div>
                            </td>

                            <td className='fw-bolder fs-4'>Barter</td>
                            <td className='fw-bolder text-center'>
                              <div className='symbol-group symbol-hover flex-shrink-0 me-2 flex'>
                                <div className='symbol symbol-circle symbol-35px border-0'>
                                  <div className='symbol-label fw-bolder bg-warning text-inverse-warning'>
                                    A
                                  </div>
                                </div>
                                <div className='symbol symbol-circle symbol-35px border-0'>
                                  <img src={toAbsoluteUrl('/media/avatars/150-2.jpg')} alt='' />
                                </div>
                                <div className='symbol symbol-circle symbol-35px border-0'>
                                  <img src={toAbsoluteUrl('/media/avatars/150-3.jpg')} alt='' />
                                </div>
                                <div className='symbol symbol-circle symbol-35px border-0'>
                                  <div className='symbol-label fw-bolder bg-info text-inverse-primary'>
                                    S
                                  </div>
                                </div>
                                <div className='symbol symbol-circle symbol-35px border-0'>
                                  <div className='symbol-label fw-bolder bg-gray'>+5</div>
                                </div>
                              </div>
                            </td>
                            <td className='fw-bolder text-center'>02 November 2021</td>
                            <td>
                              <Rate allowHalf defaultValue={2.5} />
                            </td>
                          </tr>
                        ))}
                      {/* {socialMedia.length === 0 && !status  && (
                <tr>
                  <td colSpan={3}>
                    <div className='m-20'>
                      <div className='flex'>
                        <img
                          className='ms-20'
                          src={toAbsoluteUrl('/media/logos/TableVector.png')}
                        ></img>
                      </div>
                      <div className='flex'>
                        <div>
                          <h2 className='mb-0 m24 flex'>No Social Media Listed</h2>
                        </div>
                      </div>
                      <div className='flex'>
                        {props.access && (
                        <button className='btn m24 TableButton btn-pink text-white'>
                          + Add New Social Media
                        </button>
                        )}
                      </div>
                    </div>
                  </td>
                </tr>
              )}
              {socialMedia.length === 0 && status  && (
                <tr>
                  <td colSpan={3}>
                    <div className='m-20'>
                      <div className='flex'>
                      <span
              className='svg-icon svg-icon-5x flex svg-icon-primary me-3 ms-8'
            >
              <KTSVG path={toAbsoluteUrl('/media/icons/Custom/Loading.svg')} />
            </span> 
                        </div>
                        <div className='flex'>
                          <div>
                            <h5 className='mb-0 mt-3 flex'>Fetching Data</h5>
                          </div>
                        </div>
                    </div>
                  </td>
                </tr>
              )} */}
                    </tbody>

                    {/* end::Table body */}
                  </table>

                  {/* end::Table */}
                </div>

                {/* <div
          className='d-flex ms-5 me-10 justify-content-between align-items-center flex-wrap'
          style={{marginBottom: '16px'}}
        >
          <span className='text-muted'>
            Page {socialMediaParams.page} of{' '}
            {Math.floor(totalCount % socialMediaParams.limit) === 0
              ? Math.floor(totalCount / socialMediaParams.limit)
              : Math.floor(totalCount / socialMediaParams.limit) + 1}
          </span>
          <div className='d-flex flex-wrap '>
            <Pagination
              className=''
              pageSize={socialMediaParams.limit}
              defaultCurrent={socialMediaParams.page}
              total={totalCount}
              onChange={(e) => setSocialMediaParams({...socialMediaParams, ...{page: e}})}
            />
          </div>
        </div> */}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className='basicDiv' style={{margin: '20px'}}>
            <div className='flex'>
              <img className='ms-20' src={toAbsoluteUrl('/media/logos/TableVector.png')}></img>
            </div>
            <div className='flex'>
              <div>
                <h2 className='mb-0 m24 flex'>
                  {campaignDetails
                    ? 'No data present for the selected campaign'
                    : 'No Campaign Selected'}
                </h2>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}
