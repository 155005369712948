/* eslint-disable jsx-a11y/alt-text */
import { message } from 'antd'
import { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { shallowEqual, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { RootState } from '../../setup'
import httpService from '../../setup/http/httpServices'
import { KTSVG, toAbsoluteUrl } from '../../_metronic/helpers'
// import { ChatInner } from '../../_metronic/partials'
import { NewChatInner } from '../../_metronic/partials'
import InfluencerList from '../Campaign/InfluencerList'
import '../masters/basic.css'
import { IUserModel } from '../modules/auth/models/UserModel'
import './admin.css'

import io from 'socket.io-client';
const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;
const socket = io(SOCKET_URL);

export function Communication(props: any) {
  const [influencerList, setInfluencerList] = useState([] as any)
  const [groupId, setGroupId] = useState<string>(null)
  const [influencer, setInfluencer] = useState(null)
  const [customId, setCustomId] = useState(null)
  const [index1, setIndex1] = useState(-1)
  const history: any = useHistory()
  const [influencerMediaCount, setInfluencerMediaCount] = useState(history.location.state.platform === 'Youtube'
    ? history.location.state?.youtubeInfluencerDetails?.mediaCount
    : history.location.state?.instagramInfluencerDetails?.mediaCount)
  const [imageCount, setImageCount] = useState(influencerMediaCount?.image || 0)
  const [staticStoryCount, setStaticStoryCount] = useState(influencerMediaCount?.staticStory || 0)
  const [videoStoryCount, setVideoStoryCount] = useState(influencerMediaCount?.videoStory || 0)
  const [reel1Count, setReel1Count] = useState(influencerMediaCount?.reel30s || 0)
  const [reel2Count, setReel2Count] = useState(influencerMediaCount?.reel60s || 0)
  const [videoCount, setVideoCount] = useState(influencerMediaCount?.video || 0)
  const [carouselCount, setCarousalCount] = useState(influencerMediaCount?.carousel || 0)
  const [onlineStatus, setOnlineStatus] = useState<string>('offline'); // Assume initially offline
  const [isConnected, setIsConnected] = useState(socket.connected);


  const [show, setShow] = useState(false)
  const [updated, setUpdated] = useState(true)
  const user: IUserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as IUserModel

  const handleClick = (e: any, index: number) => {
    setInfluencer(influencerList[index])
    setUpdated(typeof (influencerList[index].customMediaCount) !== 'object')
    setIndex1(index)
    setInfluencerMediaCount(influencerList[index].customMediaCount || history.location.state[history.location.state.campaignDetails.platform.toLowerCase() + "InfluencerDetails"].mediaCount)
    if (history.location.state?.status === 'Communicate' && influencerList[index]._id)
      httpService.getData(
        '/chat/groupId?campaignId=' + history.location.state._id + '&influencerId=' + influencerList[index]._id,
        (response: any) => {
          setGroupId(response.data.data.groupId)
        },
        (error: any) => {
          console.log(error?.message);
        }
      )


  }

  useEffect(() => {
    function onConnect() {
      setIsConnected(true);
      console.log("|=== socket connected ===|")
      socket.emit("getInfluencerStatus", {"msg" : influencer?._id})
    }

    function onDisconnect() {
      console.log("|=== socket disconnected ===|")
      setIsConnected(false);
    }
    
    function getStatusTrigger(value) {
      setOnlineStatus(value)
    }
    
    function getOnlineUsers(value) {
      if (value?.message && value?.users) {
        console.log(`Emit triggered for ${value.message} with userList ${value.users}`)
        setOnlineStatus("offline")
        for (let x of value.users) {
          console.log("X_id", x, "-- single user")
          if (x.username == influencer?._id) {
            setOnlineStatus("online")
          }
        }
      }
    }

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on('onlineUsers', getOnlineUsers);

    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);  
      socket.off('onlineUsers', getOnlineUsers);
    };

    // Fetch online status for the influencer here (may need an API call)
    // Update the onlineStatus state accordingly (e.g., 'online' or 'offline')
    // fetch this information for the currently selected influencer
    // and update the state accordingly.
  }, [influencer]); // Trigger this effect whenever influencer changes


  useEffect(() => {
    setImageCount(influencerMediaCount?.image || 0)
    setStaticStoryCount(influencerMediaCount?.staticStory || 0)
    setVideoStoryCount(influencerMediaCount?.videoStory || 0)
    setReel1Count(influencerMediaCount?.reel30s || 0)
    setReel2Count(influencerMediaCount?.reel60s || 0)
    setVideoCount(influencerMediaCount?.video || 0)
    setCarousalCount(influencerMediaCount.carousel || 0)
  }, [influencerMediaCount])

  const handleUpdateAndContinue = () => {

    httpService.putData('/influencer-onboarding/custom-media/' + influencer.applicationId,
      {
        customMediaCount: {
          image: imageCount,
          videoStory: videoStoryCount,
          staticStory: staticStoryCount,
          reel30s: reel1Count,
          reel60s: reel2Count,
          video: videoCount,
          carousel: carouselCount,
          updated: true,
        }
      },
      (response: any) => {
        setShow(false)
        setUpdated(false);
        getInfluencerDetails();
      },
      (err: any) => { message.error({ content: err.message }) })

  }
  useEffect(() => {
    getInfluencerDetails();
  }, [])

  const getInfluencerDetails = () => {
    httpService.getData(
      '/influencer-onboarding/' + history.location.state._id,
      (response: any) => {
        setInfluencerList([])
        var list = []
        response.data.data.influencers.shortListed.forEach((influencer: any) => {
          list.push({
            _id: influencer.influencer._id,
            photo: influencer.influencer.basicInfo.avatar || influencer.influencer.socialMediaDetails?.instagram?.avatar || influencer.influencer.socialMediaDetails?.youtube?.avatar,
            name: influencer.influencer.basicInfo.name,
            city: influencer?.influencer?.basicInfo?.city,
            state: influencer?.influencer?.basicInfo?.state,
            isPaid: influencer.isPaid,
            applicationId: influencer._id,
            disableChat: influencer?.shipping?.received || false,
            customMediaCount: influencer.customMediaCount,
            content: [],
          })
        })
        setInfluencerList(list)
        handleClick({}, 0)
      },
      (error: any) => { }
    )
  }
  return (
    <div className='d-flex w-100 justify-content-between'>
      {influencerList.length === 0 &&
        <div className='w-100 h-100 flex'>
          <div className='bg-white p-10 mt-15 rounded'>
            <div className='flex'>
              <img className='ms-20' src={toAbsoluteUrl('/media/logos/TableVector.png')}></img>
            </div>
            <div className='flex'>
              <div>
                <h2 className='mb-0 m24 flex'>No influencers shortlisted yet.</h2>
              </div>
            </div>
            <div className='flex'>
              <button className='btn m24 TableButton btn-gray-100 text-dark me-5'
                onClick={() => {
                  history.push({
                    pathname: '/campaign/influencerOnborading/' + history.location.state._id,
                    state: {
                      ...history.location.state,
                      ...{ type: 'influencerOnboard', status: 'Shortlist' },
                    },
                  })
                }}>
                Go to Shortlisting
              </button>
              <button className='btn m24 TableButton btn-pink text-white'
                onClick={() => {
                  history.push({
                    pathname: '/campaign/influencerOnborading/' + history.location.state._id,
                    state: {
                      ...history.location.state,
                      ...{ type: 'influencerOnboard', status: 'Payment' },
                    },
                  })
                }}>
                Proceed To Make Payments
              </button>

            </div>
          </div>
        </div>}
      {influencerList.length > 0 && (<>
        <InfluencerList influencers={influencerList} handleClick={handleClick} height='780px' />

        <div
          className='basicDiv w-100 p-0 overflow-hidden'
          style={{ height: '780px', marginRight: '20px', marginLeft: '20px' }}
        >
          {groupId ?
            <div className='card w-100  rounded-0' id='kt_drawer_chat_messenger'>
              <div className='card-header pe-5' id='kt_drawer_chat_messenger_header'>
                <div className='card-title'>
                  <div>
                    {influencer?.photo ? <img
                      src={
                        influencer?.photo
                      }
                      className='rounded-circle profile-photo me-2'
                      alt=''
                    /> :
                      <label
                        className='btn-primary imageBadge symbol me-2'
                        style={{ fontSize: 26, borderRadius: '100%', width: '50px', height: '50px' }}
                      >
                        {influencer.name[0].toUpperCase()}
                      </label>
                    }
                  </div>
                  <div className='d-flex justify-content-center flex-column ms-3'>
                    <a className='fs-5 fw-bolder text-gray-900 text-hover-primary m-1 ms-0 lh-1 '>
                      <span className={influencer.isPaid ? '' : 'grayout'}>{influencer?.name}</span>
                    </a>
                    <div className='mb-0 lh-1'>
                      {/* <span className='badge badge-success badge-circle w-10px h-10px me-1'></span>
                      <span className='fs-7 fw-bold text-gray-400'>Active</span> */}
                      <span
                        className={`online-status ${onlineStatus === 'online' ? 'online' : 'offline'}`}
                      >
                        {onlineStatus === 'online' ? 'Online' : 'Offline'}
                      </span>
                    </div>
                  </div>
                </div>

                <div className='card-toolbar'>
                  <div className='me-2 question-input-button'>
                    <button
                      className='fs-7 p-2 px-3 text-dark'
                      onClick={() => {
                        history.push({
                          pathname:
                            '/campaign/influencerOnborading/' + `${history.location.state._id}`,
                          state: {
                            ...history.location.state,
                            ...{ type: 'influencerOnboard', status: 'Replace' },
                          },
                        })
                      }}
                    // onClick={() => setCollab('Long-term')}
                    >
                      <KTSVG
                        className='svg-icon me-3 svg-icon-dark'
                        path={toAbsoluteUrl('/media/icons/Custom/Replace.svg')}
                      />
                      Replace
                    </button>
                  </div>
                  <div className='me-2 question-input-button'>
                    <button
                      className={'fs-7 p-2 px-3 text-dark'}
                      onClick={() => {
                        httpService.getData(
                          '/influencer-master/' + influencer._id,
                          (res: any) => {
                            res.data.data.influencer.additionalInfo.interestedCategories =
                              res.data.data.influencer.additionalInfo.interestedCategories.join(',')
                            // history.push({
                            //   pathname: '/master/influencerProfile',
                            //   state: {
                            //     influencerBasicInfo: res.data.data.influencer.basicInfo,
                            //     influencerAdditinalInfo: res.data.data.influencer.additionalInfo,
                            //     influencerSocialDetails: res.data.data.influencer.socialMediaDetails,
                            //     influencerStats: res.data.data.influencer.stats,
                            //     _id: res.data.data.influencer._id,
                            //     type: 'influencerProfile',
                            //   },
                            // })
                            window.open("/master/influencerProfile?name=" + influencer._id);
                          },
                          (err: any) => {
                            message.error({ content: err.message })
                          }
                        )
                      }}
                    >
                      View Profile
                    </button>
                  </div>
                </div>
              </div>
              {/* <ChatInner isDrawer={true} influencer={influencer} groupId={groupId} textsReceived={influencer.textsReceived} /> */}
              <NewChatInner isDrawer={true} influencer={influencer} groupId={groupId} textsReceived={influencer.textsReceived} />
            </div> :
            <div style={{ paddingTop: 100 }}>
              <div className='flex'>
                <img className='ms-20' src={toAbsoluteUrl('/media/logos/TableVector.png')}></img>
              </div>
              <div className='flex'>
                <div>
                  <h2 className='mb-0 m24 flex'>No influencers selected.</h2>
                </div>
              </div>
            </div>
          }
        </div>
      </>
      )}
      {influencer && (
        <div className='d-flex flex-column' style={{ marginRight: '20px', width: '363px' }}>
          <div className='d-flex flex-column basicDiv2' style={{ width: '363px' }}>
            <h3 style={{ margin: '16px' }}>
              {' '}
              <span className={influencer.isPaid ? '' : 'grayout'}>{influencer?.name}</span>'s
              Content List
            </h3>
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-gray-300'>
                {/* begin::Table head */}
                <thead>
                  <tr className='fw-bolder text-muted'>
                    <th className='bg-primary bg-opacity-5 ps-5 '>
                      <span className='d-flex'>Type</span>
                    </th>
                    <th className='bg-primary bg-opacity-5'>
                      <span className='d-flex'>Count</span>
                    </th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  <tr>
                    <td className='fw-bolder' style={{ paddingLeft: '16px', paddingTop: '16px' }}>
                      <label className='w-100 flex  justify-content-start'>
                        <img
                          className='w-20px h-20px'
                          src={toAbsoluteUrl('/media/logos/ImageBW.svg')}
                        />
                        <h6 className='mb-0 fs-6 ms-2 w-100'>Image</h6>
                      </label>
                    </td>
                    <td style={{ paddingTop: '8px' }}>
                      <span className='d-flex align-items-center'>
                        {updated &&
                          <img
                            className='pointer'
                            style={{ height: '32px', width: '32px', marginRight: '12px' }}
                            src={toAbsoluteUrl('/media/icons/Custom/minus-button.svg')}
                            onClick={() => {
                              if (imageCount !== 0) setImageCount(imageCount - 1)
                            }}
                          />}
                        <input
                          type='number'
                          className='form-control form-control-solid bg-gray-100 fs-16'
                          style={{ width: '48px', height: '48px', marginRight: '12px' }}
                          value={imageCount}
                          onChange={(event) => setImageCount(parseInt(event.target.value))}
                        />
                        {updated &&
                          <img
                            className='pointer'
                            style={{ height: '32px', width: '32px' }}
                            src={toAbsoluteUrl('/media/icons/Custom/plus-button.svg')}
                            onClick={() => setImageCount(imageCount + 1)}
                          />}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className='fw-bolder' style={{ paddingLeft: '16px' }}>
                      <label className='w-100 flex  justify-content-start'>
                        <img
                          className='w-20px h-20px'
                          src={toAbsoluteUrl('/media/logos/YoutubeBW.svg')}
                        />
                        <h6 className='mb-0 fs-6 ms-2 w-100'>Video Story</h6>
                      </label>
                    </td>
                    <td style={{ paddingTop: '0px' }}>
                      <span className='d-flex align-items-center'>
                        {updated &&
                          <img
                            className='pointer'
                            style={{ height: '32px', width: '32px', marginRight: '12px' }}
                            src={toAbsoluteUrl('/media/icons/Custom/minus-button.svg')}
                            onClick={() => {
                              if (videoStoryCount !== 0) setVideoStoryCount(videoStoryCount - 1)
                            }}
                          />}
                        <input
                          type='number'
                          className='form-control form-control-solid bg-gray-100 fs-16'
                          style={{ width: '48px', height: '48px', marginRight: '12px' }}
                          value={videoStoryCount}
                          onChange={(event) => setVideoStoryCount(parseInt(event.target.value))}
                        />
                        {updated &&
                          <img
                            className='pointer'
                            style={{ height: '32px', width: '32px' }}
                            src={toAbsoluteUrl('/media/icons/Custom/plus-button.svg')}
                            onClick={() => setVideoStoryCount(videoStoryCount + 1)}
                          />}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className='fw-bolder' style={{ paddingLeft: '16px' }}>
                      <label className='w-100 flex  justify-content-start'>
                        <img
                          className='w-20px h-20px'
                          src={toAbsoluteUrl('/media/logos/ReelBW.svg')}
                        />
                        <h6 className='mb-0 fs-6 ms-2 w-100'>Reel (For 30 Sec)</h6>
                      </label>
                    </td>
                    <td style={{ paddingTop: '0px' }}>
                      <span className='d-flex align-items-center'>
                        {updated &&
                          <img
                            className='pointer'
                            style={{ height: '32px', width: '32px', marginRight: '12px' }}
                            src={toAbsoluteUrl('/media/icons/Custom/minus-button.svg')}
                            onClick={() => {
                              if (reel1Count !== 0) setReel1Count(reel1Count - 1)
                            }}
                          />}
                        <input
                          type='number'
                          className='form-control form-control-solid bg-gray-100 fs-16'
                          style={{ width: '48px', height: '48px', marginRight: '12px' }}
                          value={reel1Count}
                          onChange={(event) => setReel1Count(parseInt(event.target.value))}
                        />
                        {updated &&
                          <img
                            className='pointer'
                            style={{ height: '32px', width: '32px' }}
                            src={toAbsoluteUrl('/media/icons/Custom/plus-button.svg')}
                            onClick={() => setReel1Count(reel1Count + 1)}
                          />}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className='fw-bolder' style={{ paddingLeft: '16px' }}>
                      <label className='w-100 flex  justify-content-start'>
                        <img
                          className='w-20px h-20px'
                          src={toAbsoluteUrl('/media/logos/ReelBW.svg')}
                        />
                        <h6 className='mb-0 fs-6 ms-2 w-100'>Reel (For 60 Sec)</h6>
                      </label>
                    </td>
                    <td style={{ paddingTop: '0px' }}>
                      <span className='d-flex align-items-center'>
                        {updated &&
                          <img
                            className='pointer'
                            style={{ height: '32px', width: '32px', marginRight: '12px' }}
                            src={toAbsoluteUrl('/media/icons/Custom/minus-button.svg')}
                            onClick={() => {
                              if (reel2Count !== 0) setReel2Count(reel2Count - 1)
                            }}
                          />}
                        <input
                          type='number'
                          className='form-control form-control-solid bg-gray-100 fs-16'
                          style={{ width: '48px', height: '48px', marginRight: '12px' }}
                          value={reel2Count}
                          onChange={(event) => setReel2Count(parseInt(event.target.value))}
                        />
                        {updated &&
                          <img
                            className='pointer'
                            style={{ height: '32px', width: '32px' }}
                            src={toAbsoluteUrl('/media/icons/Custom/plus-button.svg')}
                            onClick={() => setReel2Count(reel2Count + 1)}
                          />}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className='fw-bolder' style={{ paddingLeft: '16px' }}>
                      <label className='w-100 flex  justify-content-start'>
                        <img
                          className='w-20px h-20px'
                          src={toAbsoluteUrl('/media/logos/YoutubeBW.svg')}
                        />
                        <h6 className='mb-0 fs-6 ms-2 w-100'>Video</h6>
                      </label>
                    </td>
                    <td style={{ paddingTop: '0px' }}>
                      <span className='d-flex align-items-center'>
                        {updated &&
                          <img
                            className='pointer'
                            style={{ height: '32px', width: '32px', marginRight: '12px' }}
                            src={toAbsoluteUrl('/media/icons/Custom/minus-button.svg')}
                            onClick={() => {
                              if (videoCount !== 0) setVideoCount(videoCount - 1)
                            }}
                          />}
                        <input
                          type='number'
                          className='form-control form-control-solid bg-gray-100 fs-16'
                          style={{ width: '48px', height: '48px', marginRight: '12px' }}
                          value={videoCount}
                          onChange={(event) => setVideoCount(parseInt(event.target.value))}
                        />
                        {updated &&
                          <img
                            className='pointer'
                            style={{ height: '32px', width: '32px' }}
                            src={toAbsoluteUrl('/media/icons/Custom/plus-button.svg')}
                            onClick={() => setVideoCount(videoCount + 1)}
                          />}
                      </span>
                    </td>
                  </tr>

                  <tr>
                    <td className='fw-bolder' style={{ paddingLeft: '16px' }}>
                      <label className='w-100 flex  justify-content-start'>
                        <img
                          className='w-20px h-20px'
                          src={toAbsoluteUrl('/media/logos/YoutubeBW.svg')}
                        />
                        <h6 className='mb-0 fs-6 ms-2 w-100'>Carousel</h6>
                      </label>
                    </td>
                    <td style={{ paddingTop: '0px' }}>
                      <span className='d-flex align-items-center'>
                        {updated &&
                          <img
                            className='pointer'
                            style={{ height: '32px', width: '32px', marginRight: '12px' }}
                            src={toAbsoluteUrl('/media/icons/Custom/minus-button.svg')}
                            onClick={() => {
                              if (carouselCount !== 0) setCarousalCount(carouselCount - 1)
                            }}
                          />}
                        <input
                          type='number'
                          className='form-control form-control-solid bg-gray-100 fs-16'
                          style={{ width: '48px', height: '48px', marginRight: '12px' }}
                          value={carouselCount}
                          onChange={(event) => setCarousalCount(parseInt(event.target.value))}
                        />
                        {updated &&
                          <img
                            className='pointer'
                            style={{ height: '32px', width: '32px' }}
                            src={toAbsoluteUrl('/media/icons/Custom/plus-button.svg')}
                            onClick={() => setCarousalCount(carouselCount + 1)}
                          />}
                      </span>
                    </td>
                  </tr>
                  <tr>

                    <td className='fw-bolder' style={{ paddingLeft: '16px' }}>
                      <label className='w-100 flex  justify-content-start'>
                        <img
                          className='w-20px h-20px'
                          src={toAbsoluteUrl('/media/logos/YoutubeBW.svg')}
                        />
                        <h6 className='mb-0 fs-6 ms-2 w-100'>Static Story</h6>
                      </label>
                    </td>
                    <td style={{ paddingTop: '0px' }}>
                      <span className='d-flex align-items-center'>
                        {updated &&
                          <img
                            className='pointer'
                            style={{ height: '32px', width: '32px', marginRight: '12px' }}
                            src={toAbsoluteUrl('/media/icons/Custom/minus-button.svg')}
                            onClick={() => {
                              if (staticStoryCount !== 0) setStaticStoryCount(staticStoryCount - 1)
                            }}
                          />}
                        <input
                          type='number'
                          className='form-control form-control-solid bg-gray-100 fs-16'
                          style={{ width: '48px', height: '48px', marginRight: '12px' }}
                          value={staticStoryCount}
                          onChange={(event) => setStaticStoryCount(parseInt(event.target.value))}
                        />
                        {updated &&
                          <img
                            className='pointer'
                            style={{ height: '32px', width: '32px' }}
                            src={toAbsoluteUrl('/media/icons/Custom/plus-button.svg')}
                            onClick={() => setStaticStoryCount(staticStoryCount + 1)}
                          />}
                      </span>
                    </td>
                  </tr>

                </tbody>
                {/* end::Table body */}
              </table>
              {/* end::Table */}
              {updated &&
                <div
                  className='flex'
                  style={{ paddingLeft: '24px', paddingRight: '24px', paddingBottom: '16px' }}
                >
                  <button onClick={() => setShow(true)} className='col btn btn-primary'>Update Content List</button>
                </div>}
              <div className='w-100 d-flex justify-content-center pb-5'>
                <Link
                  to={{
                    pathname: `/campaign/influencerOnborading/${history.location.state._id}`,
                    state: {
                      ...history.location.state,
                      ...{ type: 'influencerOnboard', status: 'Contract' },
                    }
                  }}

                  className='link-primary fs-6 fw-bolder'
                  style={{ marginLeft: '5px' }}
                >
                  Go To Contract
                </Link>
              </div>
            </div>
          </div>
          <div className='m20'>
            <span style={{ fontSize: '14px', fontWeight: 600 }}>Note:</span>
            <ol style={{ marginTop: '12px', paddingLeft: '12px' }}>
              <li style={{ fontSize: '12px' }}>
                Once you finalize the content and click proceed to contract, you won't be able to
                redefine content requirement.
              </li>
              <li style={{ fontSize: '12px' }}>
                Count can be added/subtracted based on direct discussion between influencers and
                brands.
              </li>
            </ol>
          </div>
          <Modal
            show={show}
            onHide={() => setShow(false)}
            aria-labelledby='contained-modal-title-vcenter '
            centered
          >
            <Modal.Body className='p-0 '>
              <div className='basicDiv m-0'>
                <h3 className='m-0 d-flex'>
                  ⚠️ Content Count Confirmation
                </h3>
                <p className='mb-0 m16'>
                  Are you sure you want to proceed with the content list counts? Once updated you won't be able to update the content list.
                </p>

                <div className='m24 w-100'>

                  <button onClick={handleUpdateAndContinue} className='col w-100 btn btn-primary'>Update and Continue</button>

                </div>
                <div
                  className='d-flex justify-content-center m12 pointer'
                  onClick={() => {
                    setShow(false)
                  }}
                >
                  <b>Cancel</b>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      )}
    </div>
  )
}
