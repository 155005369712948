/* eslint-disable react/jsx-no-target-blank */
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import { AsideMenuItem } from './AsideMenuItem'
import { useLayout } from '../../core'
import { IUserModel } from '../../../../app/modules/auth/models/UserModel'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../../../setup/redux/RootReducer'

export function AsideMenuMain() {
  const user: IUserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as IUserModel
  console.log(user)

  const intl = useIntl()
  const { config, classes } = useLayout()
  const { aside } = config
  const [state, setState] = useState({})
  const access = [false, false]
  return (
    <>
      {/* <div  className='menu-item '>
        <div className='menu-content pt-0 pb-2'>
          <span className='menu-section text-gray-400 fw-bolder text-uppercase fs-7 ls-1'>
            SELECT CAMPAIGN
          </span>
        </div>
      </div>
      <div className='menu-item'>
        <div className='menu-content pt-0 pb-2'>
          <select style={{maxWidth:"194px"}} className=' form-select border-1 border-gray-800 w-190px fw-light me-5 text-white  bg-transparent'>
            <option>None Selected</option>
          </select>
        </div>
      </div> */}

      {/* <div className='menu-item'>
        <div className='menu-content'>
          <img src={toAbsoluteUrl('/media/logos/Dash-logo.png')}  alt='' />
        </div>
      </div> */}

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-gray-400 text-uppercase fs-7 fw-bolder ls-1'>
            Core
          </span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to=''
        icon='/media/icons/Custom/Dashboard.svg'
        title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
        fontIcon='bi-app-indicator'
      >
        <AsideMenuItem
          to={{ pathname: '/dashboard/general', state: { type: 'Dashboard/general' } }}
          title='General'
          hasBullet={true}
        />
        {user.permissions.admin && <>
          <AsideMenuItem
            to={{ pathname: '/dashboard/agency', state: { type: 'Dashboard/agency' } }}
            title='For Agency'
            hasBullet={true}
          />
          <AsideMenuItem
            to={{ pathname: '/dashboard/brand', state: { type: 'Dashboard/brands' } }}
            title='For Brands'
            hasBullet={true}
          />
          <AsideMenuItem
            to={{ pathname: '/dashboard/influencer', state: { type: 'Dashboard/influencer' } }}
            title='For Influencer'
            hasBullet={true}
          />
        </>
        }
      </AsideMenuItemWithSub>

      {user.userType === 'agency' && (<AsideMenuItem
        to={{
          pathname: '/master/brands',
          state: { type: 'Brand' },
        }}
        title='Brand Master'
        icon='/media/icons/Custom/Master.svg'
        fontIcon='bi-layers'
      >
      </AsideMenuItem>)}
      {(user.permissions.admin) && (
        <AsideMenuItemWithSub
          to=''
          icon='/media/icons/Custom/Master.svg'
          title='Masters'
          fontIcon='bi-layers'
        >
          <AsideMenuItem
            to={{
              pathname: '/master/agencies',
              state: { type: 'Agency' },
            }}
            title='Agency Master'
            hasBullet={true}
          />
          <AsideMenuItem
            to={{
              pathname: '/master/brands',
              state: { type: 'Brand' },
            }}
            title='Brand Master'
            hasBullet={true}
          />
          <AsideMenuItem
            to={{ pathname: '/master/influencer', state: { type: 'influencer', reloaded: false } }}
            title='Influencer Master'
            hasBullet={true}
          />
          {/* <AsideMenuItem to={{}} title='Media Matrix' hasBullet={true} /> */}
          <AsideMenuItem
            to={{ pathname: '/master/category', state: { type: 'categoryMaster' } }}
            title='Category Master'
            hasBullet={true}
          />
          <AsideMenuItem
            to={{ pathname: '/master/brand', state: { type: 'brandMaster' } }}
            title='Brand Master (app)'
            hasBullet={true}
          />
          <AsideMenuItem
            to={{ pathname: '/master/socialmedia', state: { type: 'socialMaster' } }}
            title='Media Master'
            hasBullet={true}
          />
          <AsideMenuItem
            to={{ pathname: '/master/price-matrix', state: { type: 'priceMetrixMaster' } }}
            title='Price Matrix Master'
            hasBullet={true}
          />
        </AsideMenuItemWithSub>
      )}

      <div className='menu-item mt-5'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-7 fw-bolder ls-1'>
            CAMPAIGNS
          </span>
        </div>
      </div>
      {(user.permissions.campaign[0] || user.permissions.admin) && (
        <AsideMenuItemWithSub
          to='/accounts'
          title='Campaigns'
          icon='/media/icons/Custom/ArrowInTarget.svg'
          fontIcon='bi-person'
        >
          {(user.permissions.campaign[1] || user.permissions.admin) && (
            <AsideMenuItem
              to={{ pathname: '/campaign/details', state: { type: 'campaignDetails' } }}
              title='Create New'
              hasBullet={true}
            />
          )}
          {/* <AsideMenuItem
            to={{ pathname: '/campaign/contentSubmission', state: { type: 'contentSubmission' } }}
            title='Submissions'
            hasBullet={true}
          /> */}
          <AsideMenuItem
            to={{
              pathname: '/campaign/dashboard',
              state: { type: 'campaign/dashboard', status: '', reloaded: false },
            }}
            title='All'
            hasBullet={true}
          />
          <AsideMenuItem
            to={{
              pathname: '/campaign/dashboard',
              state: { type: 'campaign/dashboard', status: 'draft', reloaded: false },
            }}
            title='Draft'
            hasBullet={true}
          />
          <AsideMenuItem
            to={{
              pathname: '/campaign/dashboard',
              state: { type: 'campaign/dashboard', status: 'upcoming', reloaded: false },
            }}
            title='Upcoming'
            hasBullet={true}
          />
          <AsideMenuItem
            to={{
              pathname: '/campaign/dashboard',
              state: { type: 'campaign/dashboard', status: 'underReview', reloaded: false },
            }}
            title='Under Review'
            hasBullet={true}
          />
          <AsideMenuItem
            to={{
              pathname: '/campaign/dashboard',
              state: { type: 'campaign/dashboard', status: 'live', reloaded: false },
            }}
            title='Ongoing'
            hasBullet={true}
          />
          <AsideMenuItem
            to={{
              pathname: '/campaign/dashboard',
              state: { type: 'campaign/dashboard', status: 'completed', reloaded: false },
            }}
            title='Completed'
            hasBullet={true}
          />
        </AsideMenuItemWithSub>
      )}
      {(user.permissions.scheduler[0] || user.permissions.admin) && (
        <AsideMenuItemWithSub
          to='/accounts'
          title='Scheduler'
          icon='/media/icons/Custom/Recent.svg'
          fontIcon='bi-person'
        >
          <AsideMenuItem
            to={{
              pathname: '/scheduler/calendar',
              state: { type: 'schedular/calendar/week', status: 'week' },
            }}
            title='This Week'
            hasBullet={true}
          />
          <AsideMenuItem
            to={{
              pathname: '/scheduler/calendar',
              state: { type: 'schedular/calendar/month', status: 'month' },
            }}
            title='This Month'
            hasBullet={true}
          />
        </AsideMenuItemWithSub>
      )}
      <AsideMenuItemWithSub
        to='/accounts'
        title='Performance'
        icon='/media/icons/Custom/Performance.svg'
        fontIcon='bi-person'
      >
        <AsideMenuItem
          to={{
            pathname: '/performance/campaign-wise',
            state: { type: 'performanceCampaign' },
          }}
          title='Campaign-wise'
          hasBullet={true}
        />
        <AsideMenuItem
          to={{ pathname: '/performance/influencer-wise', state: { type: 'performanceInfluencer' } }}
          title='Influencer-wise'
          hasBullet={true}
        />
        <AsideMenuItem
          to={{ pathname: '/performance/rating', state: { type: 'performanceRating' } }}
          title='Ratings'
          hasBullet={true}
        />
      </AsideMenuItemWithSub>
      {(user.permissions.transactions[0] || user.permissions.admin) && (
        <AsideMenuItemWithSub
          to='/accounts'
          title='Transactions'
          icon='/media/icons/Custom/Transaction.svg'
          fontIcon='bi-person'
        >
          {(user.permissions.transactions[1] || user.permissions.admin) && (
            <AsideMenuItem
              to={{ pathname: '/transaction/add', state: { type: 'transactionAdd' } }}
              title='Add'
              hasBullet={true}
            />
          )}
          <AsideMenuItem
            to={{ pathname: '/transaction/dashboard', state: { type: 'transactionDashboard' } }}
            title='Due Payments'
            hasBullet={true}
          />
        </AsideMenuItemWithSub>
      )}

      <div className='menu-item mt-10'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-7 fw-bolder ls-1'>
            MISCELLANOUS
          </span>
        </div>
      </div>
      {(user.permissions.adminSettings[0] || user.permissions.admin) && (
        <AsideMenuItemWithSub
          to='/accounts'
          title='Admin Settings'
          icon='/media/icons/Custom/Setting.svg'
          fontIcon='bi-person'
        >
          <AsideMenuItem
            to={{ pathname: '/adminSettings/documentList', state: { type: 'documentList' } }}
            title='Document List'
            hasBullet={true}
          />
          {/* <AsideMenuItem to={{pathname: '/adminSettings/profile', state: {type: 'profile'}}} title='Profile' hasBullet={true} /> */}
          <AsideMenuItem
            to={{ pathname: '/adminSettings/FAQ', state: { type: 'FAQ' } }}
            title='F.A.Q'
            hasBullet={true}
          />
          <AsideMenuItem
            to={{
              pathname: '/adminSettings/profile',
              state: { type: 'Access Manager', breadcrums: ['Profile Manager'] },
            }}
            title='Access Manager'
            hasBullet={true}
          />
        </AsideMenuItemWithSub>
      )}
      {(user.permissions.support[0] || user.permissions.admin) && (
        <AsideMenuItemWithSub
          to='/accounts'
          title='Support'
          icon='/media/icons/Custom/Phone.svg'
          fontIcon='bi-person'
        >
          <AsideMenuItem
            to={{
              pathname: '/support/activeTicket',
              state: { type: 'ActiveTicket', status: 'unresolved' },
            }}
            title='Active Ticket'
            hasBullet={true}
          />
          {/* <AsideMenuItem
            to={{
              pathname: '/support/activeTicket',
              state: {type: 'ActiveTicket', status: 'underReview'},
            }}
            title='Scheduled Requests'
            hasBullet={true}
          /> */}
        </AsideMenuItemWithSub>
      )}
    </>
  )
}
