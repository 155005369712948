import { FC, useEffect, useState } from 'react'
import clsx from 'clsx'
import Moment from 'moment'
import { KTSVG, MessageModel, toAbsoluteUrl } from '../../helpers'
import io from 'socket.io-client';

// const SOCKET_URL = "http://localhost:8080";

const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;

type Props = {
  isDrawer?: boolean
  influencer?: any
  groupId?: string
  textsReceived?: number
}

const NewChatInner: FC<Props> = ({ isDrawer = false, influencer = {}, groupId = '', textsReceived = 0 }) => {

  const [message, setMessage] = useState<string>('')
  const [messages, setMessages] = useState([]) as any;
  const [getchatData, setGetChatData] = useState([]) as any;
  const socket = io(SOCKET_URL);
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [firstMessageSent, setFirstMessageSent] = useState(false);

  console.log("Influencer Chat Switched -- ", groupId, "<<<<<" )

  const tempList = []
  for (let val of getchatData) { tempList.push((val?.user?._id) ? val.user._id : "none")}
  const stickyFlag = tempList.every( (val, i, arr) => val == arr[0] )
  // console.log("WHat is my templist -- ",tempList)

  // async function initializeSocket() {
  //   try {
  //         socket.on('connect', () => {
  //           console.log("=== socket connected ====", groupId, "<<<<<<<<<<<<<<<<<<<<<<<")
  //           socket.emit("get_init_msg", groupId)
  //         })
    
  //         socket.on('disconnect', () => {
  //           console.log("=== socket disconnected ====")
  //         })
    
  //         socket.on('error', () => {
  //           console.log("socekt error", )
  //         })
    
  //       } catch (error) {
  //         console.log("scoket is not inialized", error)
  //       }
  // }

  const sendMessage = () => {
    if (message != "" || typeof message === 'undefined') {
      const tempObj = []
      tempObj.push({text:message})
      socket.emit("send_message", {"messageArray":tempObj, groupId:groupId, sender:"campaign"})
    }
    setMessage('')
  }


  // useEffect(() => {
  //   try {
  //     initializeSocket()
  //     socket.on(groupId, (chatDataDb) => {
  //       // debugger
  //       console.log("Message listener in app triggered with data", chatDataDb)
  //       let cloneChat = [...getchatData]
  //       setGetChatData(cloneChat.concat(chatDataDb))
  //       console.log("LATEST CHAT DATA - ", getchatData)
  //     });
  //   } catch (error) {
  //     console.log("ERROR", error)
  //   }
  // }, [])

  useEffect(() => {
    function onConnect() {
      setIsConnected(true);
      console.log("===socket connected===")
      socket.emit("get_init_msg", groupId)
      socket.emit("getInfluencerStatus", {"msg" : influencer})
    }

    function onDisconnect() {
      console.log("===socket disconnected===")
      setIsConnected(false);
    }

    function getChatTrigger(value) {
      // console.log("Message listener in app triggered with data", value)
      // let cloneChat = [...getchatData]
      // setGetChatData(cloneChat.concat(value))
      setGetChatData(value.reverse())
    }

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on(groupId, getChatTrigger);

    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      socket.off(groupId, getChatTrigger);
    };
  }, [groupId]);

  

  //   useEffect(() => {
  //   console.log("EMIT INIT EXEC in Panel", groupId)
  //   socketServices.emit("get_init_msg", groupId)
  // }, [])
  //   try {
  //     socketServices.on(groupId, (chatDataDb) => {
  //       console.log("Message listener in app triggered with data", chatDataDb)
  //       let cloneChat = [...getchatData]
  //       setGetChatData(cloneChat.concat(chatDataDb))
  //       console.log("LATEST CHAT DATA - ", getchatData)
  //   });
  //   } catch (error) {
  //     console.log("ERRROR VAL - ", error)
  //   }



  // const socket = io(SOCKET_URL, {});
  // useEffect(() => {

  // socket.on("connect", () => {
  //   // or with emit() and custom event names

  //   socket.on(groupId, (chatDataDb) => {
  //     console.log("Message listener in Panel triggered with data", chatDataDb)
  //     let cloneChat = [...getchatData]

  //     setGetChatData(cloneChat.concat(chatDataDb))
  //     console.log("LATEST CHAT DATA - ", getchatData)


  //   socket.emit("get_init_msg", groupId)

  // });
  // });
  // }, [])


  const onEnterPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault()
      sendMessage()
    }
  }

  return (
  <div
      style={{ background: "#F9FAFC" }}
      className='card-body overflow-auto'
      id={isDrawer ? 'kt_drawer_chat_messenger_body' : 'kt_chat_messenger_body'}
    >
      <div
        style={{ height: '85%' }}
        className='overflow-auto pe-5'
        data-kt-element='messages'
        data-kt-scroll='true'
        data-kt-scroll-activate='{default: false, lg: true}'
        data-kt-scroll-max-height='auto'
        data-kt-scroll-dependencies={
          isDrawer
            ? '#kt_drawer_chat_messenger_header, #kt_drawer_chat_messenger_footer'
            : '#kt_header, #kt_toolbar, #kt_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer'
        }
        data-kt-scroll-wrappers={
          isDrawer ? '#kt_drawer_chat_messenger_body' : '#kt_content, #kt_chat_messenger_body'
        }
        data-kt-scroll-offset={isDrawer ? '0px' : '-2px'}
      >

        {
          getchatData.map((chatMessage,index) => {

            const state = (chatMessage.user._id == 1) ? '#fff' : '#EEEBF5'
            const border = (chatMessage.user._id == 1) ? '12px 12px 12px 0px' : '12px 12px 0px 12px'
            const templateAttr = {}

            if (chatMessage.template) {
              Object.defineProperty(templateAttr, 'data-kt-element', {
                value: `template-${chatMessage.type}`,
              })
            }

            const contentClass = `${isDrawer ? '' : 'd-flex'} justify-content-${chatMessage.user._id == 1 ? 'start' : 'end' } `

            // const contentClass = `'' justify-content-start mb-10`
            return (
            <div
              key={`chatMessage${index}`}
              className={clsx('d-flex', contentClass, { 'd-none': chatMessage.template })}
              {...templateAttr}
            >
              <div
                className={clsx(
                'd-flex flex-column align-items',
                `align-items-${chatMessage.user._id == 1 ? 'start' : 'end'}`
              )}>
                <div
                  style={{ borderRadius: `${border}`, background: `${state}` }}
                  className={clsx(
                    'p-3 ',
                    'text-dark fw-bold mw-lg-400px',
                  )}
                  data-kt-element='message-text'
                  dangerouslySetInnerHTML={{ __html: chatMessage.text }}
                ></div>

                <div className='d-flex align-items-center mb-2'>
                  <div className='ms-3'>
                    <span className='text-muted fs-7 mb-1'>
                      {Moment(chatMessage.createdAt).format('DD MMM YYYY | HH:mm A')}
                    </span>
                  </div>
                  {/* <div className='ms-auto'>
                    <span className='badge bg-primary'>{textsReceived}</span>
                  </div> */}
                </div>
              </div>
            </div>
            
            )

          })
        }

        {(getchatData.length === 0 || stickyFlag == true && tempList[0] == 1) ?  (
          <div className='h-100 d-flex align-items-end'>
            <div>
              <div className='d-flex'>
                <span className='w-30px h-30px flex bg-danger bg-opacity-25 rounded-circle'>
                  <KTSVG
                    className='svg-icon svg-icon-danger'
                    path={toAbsoluteUrl('/media/icons/Custom/I.svg')}
                  />
                </span>
                <h3 className='text-danger m-0 mt-2 ms-3'>Important</h3>
              </div>
              <div className='m20 mb-5'>
                <ul>
                  <li>
                    Do not communicate with influencers on any other platform or channel except the
                    Sehaye app.
                  </li>
                  <li>Do not share your contact information with influencers.</li>
                  <li>Do not request any contact information from influencers.</li>
                </ul>
              </div>
            </div>
          </div>
        ) : null}
      </div>

      <div
        className='card-footer p-0'
        id={isDrawer ? 'kt_drawer_chat_messenger_footer' : 'kt_chat_messenger_footer'}
      >
        <div className='d-flex flex-stack'>
          <div className='d-flex align-items-center me-2 w-100'>
            <textarea
              className='form-control form-control-flush mb-3 w-100 pt-7'
              rows={2}
              data-kt-element='input'
              placeholder='Type your message here'
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              onKeyDown={onEnterPress}
            ></textarea>
          </div>
          <button
            style={{ background: "#E72281" }}
            className='btn  rounded-circle w-40px h-40px ps-3 pt-2'
            type='button'
            data-kt-element='send'
            onClick={sendMessage}
          >
            <img src="/media/svg/misc/send-Icon.svg" alt="" />
          </button>
        </div>
      </div>
    </div>
  )


}

export { NewChatInner }