import axios from "axios";


const httpService = {
    serviceHeader: {
        Accept: "application/json",
        "Content-Type": "application/json",
    },
    serviceHeaderFormData: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data; boundary=any",
    },
    baseURL: process.env.REACT_APP_API_URL,

    async downloadBlob(url: any, isSuccess: any, isError: any) {
        axios
            .get(this.baseURL + url, { headers: httpService.serviceHeader, ...{ responseType: "arraybuffer" } })
            .then((response) => {
                if (response.status === 200) {
                    const blob = new Blob([response.data], { type: 'image/png' });
                    var reader = new FileReader();
                    reader.readAsDataURL(blob)
                    reader.onloadend = function () {
                        isSuccess(reader.result);
                    }
                }
            })
            .catch((error) => {
                if (error?.response?.status === 401) {
                    window.location.href='/error/401';
                } else isError(error?.response?.data.error);
            });
    },

    async downloadFile(url: any, fileName: any, isError: any) {
        axios
            .get(this.baseURL + url, { headers: httpService.serviceHeader, ...{ responseType: "arraybuffer" } })
            .then((response) => {
                if (response.status === 200) {
                    const blob = new Blob([response.data]);
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = fileName;
                    link.click();
                }
            })
            .catch((error) => {
                if (error?.response?.status === 401) {
                    window.location.href='/error/401';
                } else isError(error?.response?.data.error);
            });
    },

    async getData(url: any, isSuccess: any, isError: any, headers?: any) {
        axios
            .get(this.baseURL + url, { headers: httpService.serviceHeader, ...headers })
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    isSuccess(response);
                    return response.data.data;
                }
            })
            .catch((error) => {
                if (error?.response?.status === 401) {
                    window.location.href='/error/401';
                } else if(error?.response?.data.error) isError(error?.response?.data.error)
                // else window.location.href='/error/404';;
            });
    },

    async postData(url: any, data: any, isSuccess: any, isError: any) {
        axios
            .post(this.baseURL + url, data, { headers: data instanceof FormData ? httpService.serviceHeaderFormData : httpService.serviceHeader })
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    // console.log("postdata response success -- ", response.data)
                    isSuccess(response);
                }
                else isError(response);
            })
            .catch(function (error) {
                // message.error(error?.response?.data.message)
                // console.log("Error response -- ", error, "<<")
                if (error?.response?.status === 401) {
                    window.location.href='/error/401';
                } else isError(error?.response?.data.error);
            });
    },

    async postDataExcel(url: any, data: any, fileName: any, isSuccess: any, isError: any) {
        axios
            .post(this.baseURL + url, data, { headers: httpService.serviceHeader, ...{ responseType: "arraybuffer" } })
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    const blob = new Blob([response.data]);
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = fileName;
                    link.click();
                }
            })
            .catch((error) => {
                if (error?.response?.status === 401) {
                    window.location.href='/error/401';
                } else isError(error?.response?.data.error);
            });
    },

    async putData(url: any, data: any, isSuccess: any, isError: any) {
        axios
            .put(this.baseURL + url, data, { headers: data instanceof FormData ? httpService.serviceHeaderFormData : httpService.serviceHeader })
            .then((response) => {
                if (response.status === 200) {
                    isSuccess(response);
                }
            })
            .catch(function (error) {
                if (error?.response?.status === 401) {
                    window.location.href='/error/401';
                } else isError(error?.response?.data.error);
            });
    },

    async patchData(url: any, data: any, isSuccess: any, isError: any) {
        axios
            .patch(this.baseURL + url, data, { headers: httpService.serviceHeader })
            .then((response) => {
                if (response.status === 200) {
                    isSuccess(response);
                }
            })
            .catch(function (error) {
                if (error?.response?.status === 401) {
                    window.location.href='/error/401';
                } else isError(error?.response?.data.error);
            });
    },

    async deleteData(url: any, isSuccess: any, isError: any) {
        axios
            .delete(this.baseURL + url, { headers: httpService.serviceHeader })
            .then(function (response) {
                if (response.status === 200) {
                    isSuccess(response);
                }
            })
            .catch(function (error) {
                if (error?.response?.status === 401) {
                    window.location.href='/error/401';
                } else isError(error?.response?.data.error);
            });
    },

    async getThirdpartyData(url: any, isSuccess: any, isError: any) {
        axios
            .get(url)
            .then((response) => {
                if (response.status === 200) {
                    isSuccess(response);
                }
            })
            .catch(function (error) {
                isError(error.response);
            });
    },
};

export default httpService;