import { CheckCircleOutlined, CloseCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import React, { useEffect, useState } from 'react'

export function Alerts(props: any) {

    const [visible, setVisible] = useState(props.visible);
    useEffect(() =>{
        setVisible(props.visible)
    }, [props?.visible])
    return visible ? <div className={props.type === 'success' ? "alert alert-dismissible alert-success d-flex align-items-center p-3" : "alert alert-dismissible alert-danger d-flex align-items-center p-3"} role="alert">
        {props.type === 'success' && <CheckCircleOutlined /> }
        {props.type === 'error' && <ExclamationCircleOutlined /> }
        <div className='ms-2'>
            {props.message}
        </div>
        <button type="button" className="position-absolute position-sm-relative btn btn-icon ms-sm-auto h-unset" style={{color: `${props.type == "success" ? "green" : "#9E3539"}`, width: "fit-content"}} onClick={() => setVisible(false) }>
            <CloseCircleOutlined />
        </button>
    </div> : <></>
}
