import React, {useEffect, useState} from 'react'
import httpService from '../../../setup/http/httpServices'
import '../Dashboard.scss'
import {NoData} from './NoData'
import Moment from 'moment'
import {Modal} from 'react-bootstrap-v5'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {message} from 'antd'

export function Transaction(props: any) {

  const [transactionData, setTransactionData] = useState<any>({})
  const [priceMaster, setPriceMaster] = useState<any>({})
  const [CGST, setCGST] = useState<number>(0)
  const [SGST, setSGST] = useState<number>(0)
  const [subTotal, setSubTotal] = useState<number>(0)
  const [total, setTotal] = useState<number>(0)
  const [billShow, setBillShow] = useState(false)

  const getTransactionById = (transactionId: string, flag: boolean) => {
    httpService.getData(
      '/transaction/' + transactionId,
      (response: any) => {
        const transactionDetails = JSON.parse(JSON.stringify(response.data.data.transaction))
        setTransactionData(transactionDetails)
        let subtotalValue = 0
        ;[...transactionDetails.amountDetails, ...transactionDetails.customAmountDetails]
          // .filter((x: any) => x.socialMedia.socialMediaName === transactionDetails.transactionInfoDetails.platform )
          .forEach((number: any) => {
            if (number.qty) {
              subtotalValue +=
                // parseInt(number.qty) *
                number?.amount
                  ? number.amount
                  : parseInt(priceMaster[number.influencerCategory].costPerInfluencer)
            }
          })
        setSubTotal(subtotalValue)
        setCGST(subtotalValue * 0.09)
        setSGST(subtotalValue * 0.09)
        setTotal((subtotalValue + subtotalValue * 0.09 * 2) - response.discount)
        setTimeout(() => {
          setBillShow(flag)          
        }, 100);
      },
      (error: any) => {}
    )
  }
  useEffect(() => {
    httpService.getData(
      '/price-master',
      (response: any) => {
        let priceMaster: any = response.data.data.priceMasters
        const priceObject: any = {}
        priceMaster.forEach((x: any) => {
          priceObject[x._id] = x
          x.qty = 0
        })
        setPriceMaster(priceObject)
      },
      (error: any) => {
        message.error(error.message)
      }
    )
  }, [])
  return (
    <>
      <div className='Dcard Dinfluencers h-400px '>
        <h2 className='Dheading'>Recent Transactions</h2>
        {!props.data ? (
          <NoData />
        ) : (
          <div className='table-responsive'>
            <table className='table table-row-dashed table-row align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bolder text-muted'>
                  <th style={{width: '450px'}}>
                    <span className='d-flex'>Brand /Agency</span>
                  </th>
                  <th style={{width: '100px'}}>
                    <span className='d-flex'>Amount(Rs.)</span>
                  </th>
                  <th style={{width: '100px'}}>
                    <span className='d-flex'>Status</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {props.data?.map((item, index) => (
                  <tr key={index} className=''>
                    <td
                      className='fw-bolder pointer'
                      onClick={() => getTransactionById(item._id, true)}
                    >
                      {item.basicInformation?.brand?.brandDetails?.avatar ? (
                        <img
                          className='Davatar'
                          src={item.basicInformation.brand.brandDetails.avatar}
                          alt=''
                        />
                      ) : (
                        <img
                          className='Davatar'
                          src='https://cdn0.iconfinder.com/data/icons/most-usable-logos/120/Amazon-512.png'
                          alt=''
                        />
                      )}

                      {/* {item.basicInformation?.agency?.agencyDetails.agencyName} */}
                      {item.basicInformation?.brand?.brandDetails.brandName}
                    </td>
                    <td className='fw-normal'>{item.amount}</td>
                    <td className='fw-bolder'>
                      <span
                        className={
                          item.status === 'Completed'
                            ? 'text-success bg-success bg-opacity-20 rounded p-2 pt-1 pb-1'
                            : 'text-warning bg-warning bg-opacity-20 rounded p-2 pt-1 pb-1'
                        }
                      >
                        {item.status}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <Modal
        show={billShow}
        data-backdrop='true'
        onHide={() => setBillShow(false)}
        aria-labelledby='contained-modal-title-vcenter '
        centered
      >
        <Modal.Body className='p-0 '>
          <div style={{width: '600px'}} className='bg-white rounded'>
            <div className=' w-100 bg-white rounded p-8'>
              <div className='d-flex'>
                <div className='col flex justify-content-start'>
                  <img className='h-40px' src={toAbsoluteUrl('/media/logos/Vavo-Logo.svg')}></img>
                </div>
                <h3 className='col flex justify-content-end m-0'>Invoice</h3>
              </div>
              <div className='col d-flex m16'>
                <div className='w-400px border rounded p-3 pt-2 me-5'>
                  <p className='m-0 text-gray-400 fw-bold fs-9'>Invoice to</p>
                  <h6 className='m-0 mt-2'>Amazon India</h6>
                  <p className='m-0 fs-9 mt-1'>
                    Brigade Gateway, 8th floor, 26/1, Dr. Rajkumar Road, Malleshwaram(W),
                    Bangalore-560055, Karnataka, India.
                  </p>
                </div>
                <div className='w-150px border rounded p-3 pt-2 '>
                  <p className='m-0 text-gray-400 fs-9'>Invoice No:</p>
                  <p className='m-0'>{transactionData?.basicInformation?.referenceNumber}</p>
                  <p className='m-0 mt-2 text-gray-400 fs-9'>Date:</p>
                  <p className='m-0'>{Moment(transactionData?.createdAt).format('DD MMMM YYYY')}</p>
                </div>
              </div>
              <div className='m16 border rounded'>
                <div className='p-3 rounded'>
                  <div className='d-flex'>
                    <div className='col flex fw-bold justify-content-start'>Type</div>
                    <div className='col flex fw-bold justify-content-end me-5'>
                      Influencer Count
                    </div>
                    <span className='text-gray-400 flex'>x</span>
                    <div className='col flex fw-bold justify-content-start ms-5'>Barter Value</div>
                    <div className='col flex fw-bold justify-content-end'>Amount</div>
                  </div>
                  <span className='separator border-gray-200 m12'></span>
                  {transactionData?.amountDetails?.map((amount: any, index: number) => (
                    <div className='col m12 d-flex' key={index}>
                      <div className='col flex fw-bold justify-content-start'>
                        {priceMaster[amount.influencerCategory]?.bucketName}
                      </div>
                      <div className='col flex fw-bold justify-content-end me-5'>{amount.qty}</div>
                      <span className='text-gray-400 flex'>x</span>
                      <div className='col flex fw-bold justify-content-start ms-5'>
                        {priceMaster[amount.influencerCategory]?.costPerInfluencer}
                      </div>

                      <div className='col flex fw-bold justify-content-end'>
                        <span placeholder='200'>
                          Rs.{' '}
                          {priceMaster[amount.influencerCategory]?.costPerInfluencer * amount.qty}
                        </span>
                      </div>
                    </div>
                  ))}
                  {transactionData?.customAmountDetails?.map((amount: any, index: number) => (
                    <div className='col m12 d-flex' key={index}>
                      <div className='col flex fw-bold justify-content-start'>{amount.service}</div>
                      <div className='col flex fw-bold justify-content-end me-5'>{amount.qty}</div>
                      <span className='text-gray-400 flex'>x</span>
                      <div className='col flex fw-bold justify-content-start ms-5'>
                        {amount.amount}
                      </div>

                      <div className='col flex fw-bold justify-content-end'>
                        <span placeholder='200'>Rs. {amount.amount * amount.qty}</span>
                      </div>
                    </div>
                  ))}
                  <span className='separator border-gray-200 m12'></span>
                  <div className='col m12 d-flex'>
                    <div className='col fw-bolder fs-6 flex justify-content-start'>Sub Total</div>
                    <div className='col flex justify-content-end me-5'></div>
                    <span className='text-gray-400 flex'></span>
                    <div className='col fw-bolder fs-6 flex justify-content-start ms-5 ps-15'>
                      11
                    </div>

                    <div className='col fw-bolder fs-6 flex justify-content-end'>
                      <span placeholder='200'>Rs. {subTotal}</span>
                    </div>
                  </div>
                  <span className='separator border-gray-200 m12'></span>
                  <h6 className='m12 fs-7'>Taxes</h6>
                  <div className='m12 d-flex'>
                    <div className='col fs-7 flex justify-content-start '>CGST(9%)</div>
                    <div className='col fs-7 flex justify-content-end'>Rs. {CGST}</div>
                  </div>
                  <div className='m12 d-flex'>
                    <div className='col fs-7 flex justify-content-start'>SGST(9%)</div>
                    <div className='col fs-7 flex justify-content-end'>Rs. {SGST}</div>
                  </div>
                </div>
                <div className='m12 h-40px d-flex'>
                  <div className='col-8 fw-bolder border p-3 fs-6 bg-gray-100 flex justify-content-start'>
                    Grand Total
                  </div>
                  <div className='col-4 fw-bolder p-3 fs-3 text-white bg-pink flex justify-content-end'>
                    Rs. {total}
                  </div>
                </div>
                <div className='m12 p-3 pt-0  d-flex'>
                  <div className='col fs-7 flex justify-content-start '>Status</div>
                  <div className='col fs-7 flex text-danger fw-bolder justify-content-end'>
                    {/* NOT PAID */}
                    {transactionData?.status?.toUpperCase()}
                  </div>
                </div>
              </div>
              <div className='m16'>
                <h6 className='fs-7 text-gray-400'>{transactionData.method} Detail:</h6>
                <div className='col d-flex'>
                  {transactionData.method == 'Cash' && (
                    <div className='w-400px border rounded p-3 pt-2 me-5'>
                      <div className='d-flex'>
                        <div className='col-8'>
                          <p className='m-0 text-gray-400 fw-bold fs-9'>Memo Number:</p>
                          <p className='m-0 fs-7'>{transactionData.cashDetails.memoNumber}</p>
                        </div>
                        <div className='col-4'>
                          <p className='m-0 text-gray-400 fw-bold fs-9'>Receiver Name:</p>
                          <p className='m-0 fs-7'>{transactionData.cashDetails.receiverName}</p>
                        </div>
                      </div>
                      <div className='d-flex mt-2'>
                        <div className='col-4'>
                          <p className='m-0 text-gray-400 fw-bold fs-9'>Issue Date:</p>
                          <p className='m-0 fs-7'>
                            {Moment(transactionData?.cashDetails.date).format('DD MMMM YYYY')}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                  {transactionData.method == 'Cheque' && (
                    <div className='w-400px border rounded p-3 pt-2 me-5'>
                      <div className='d-flex'>
                        <div className='col-8'>
                          <p className='m-0 text-gray-400 fw-bold fs-9'>Cheque Number: </p>
                          <p className='m-0 fs-7'>{transactionData.chequeDetails.chequeNumber}</p>
                        </div>
                        <div className='col-4'>
                          <p className='m-0 text-gray-400 fw-bold fs-9'>IFSC Code:</p>
                          <p className='m-0 fs-7'>{transactionData.chequeDetails.ifscCode}</p>
                        </div>
                      </div>
                      <div className='d-flex mt-2'>
                        <div className='col-8'>
                          <p className='m-0 text-gray-400 fw-bold fs-9'>Bank Name & Branch:</p>
                          <p className='m-0 fs-7'>
                            {transactionData.chequeDetails.bankName},
                            {transactionData.chequeDetails.branch}
                          </p>
                        </div>
                        <div className='col-8'>
                          <p className='m-0 text-gray-400 fw-bold fs-9'>Issue Date:</p>
                          <p className='m-0 fs-7'>
                            {Moment(transactionData?.chequeDetails.date).format('DD MMMM YYYY')}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                  {transactionData.method == 'Bank Transfer' && (
                    <div className='w-400px border rounded p-3 pt-2 me-5'>
                      <div className='d-flex'>
                        <div className='col-8'>
                          <p className='m-0 text-gray-400 fw-bold fs-9'>Cheque Number: </p>
                          <p className='m-0 fs-7'>
                            {transactionData.bankTransferDetails.chequeNumber}
                          </p>
                        </div>
                        <div className='col-4'>
                          <p className='m-0 text-gray-400 fw-bold fs-9'>Reference Number</p>
                          <p className='m-0 fs-7'>
                            {transactionData.bankTransferDetails.referenceNumber}
                          </p>
                        </div>
                      </div>
                      <div className='d-flex mt-2'>
                        <div className='col-4'>
                          <p className='m-0 text-gray-400 fw-bold fs-9'>Issue Date</p>
                          <p className='m-0 fs-7'>
                            {Moment(transactionData?.bankTransferDetails.date).format(
                              'DD MMMM YYYY'
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className='w-150px border rounded p-3 pt-2 '>
                    <p className='m-0 text-gray-400 fs-9'>GST No:</p>
                    <p className='m-0 fs-7'>27AAICC9817C1ZL</p>
                    <p className='m-0 mt-2 text-gray-400 fs-9'>PAN No:</p>
                    <p className='m-0 fs-7'>AAICC9817C</p>
                  </div>
                </div>
              </div>
              <div className='m16'>
                <p className='m-0 fw-bold fs-9'>
                  Terms & Conditions; Payment should be credited within 30 days from date of
                  invoice.
                </p>
                <p style={{fontWeight: 'bolder'}} className='mt-1 m-0 fs-9'>
                  For Clanify Media Private Limited
                </p>
              </div>
              <div className='m30 d-flex'>
                <h6 className='m-0'>Neha Puri, </h6> <p className='fs-8 ms-1 m-0'> Director</p>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
