import clsx from 'clsx'
import { useFormik } from 'formik'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import httpService from '../../../../setup/http/httpServices'
import { requestPassword } from '../redux/AuthCRUD'

const initialValues = {
  email: '',
  newPassword: '',
  confirmPassword: '',
}

const setPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  newPassword: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('This field is required'),
  confirmPassword: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('This field is required'),
})

export function SetPassword() {
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const formik = useFormik({
    initialValues,
    validationSchema: setPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      if (values.confirmPassword !== values.newPassword) {
        setHasErrors(true)
        return
      }
      setLoading(true)
      setHasErrors(undefined)
      setTimeout(() => {
        let userType = window.location.search.split("=").length > 1 ? window.location.search.split("=")[1] + '/' : ''
        httpService.patchData(
          window.location.pathname.replace('set', userType + 'reset'),
          {
            password: values.newPassword,
          },
          (response: any) => {
            setHasErrors(false)
          },
          (error: any) => { }
        )
        // requestPassword(values.email)
        //   .then(({data: {result}}) => {
        //     setHasErrors(false)
        //     setLoading(false)
        //   })
        //   .catch(() => {
        //     setHasErrors(true)
        //     setLoading(false)
        //     setSubmitting(false)
        //     setStatus('The login detail is incorrect')
        //   })
      }, 1000)
    },
  })

  return (
    <>
      <form
        className='form w-75 position-relative flex fv-plugins-bootstrap5 fv-plugins-framework '
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='w-100'>
          <div>
            {/* begin::Title */}
            <h1 style={{ fontWeight: 700, fontSize: '36px', lineHeight: '54px' }}>
              Welcome! 🙌
            </h1>
            {/* end::Title */}

            {/* begin::Link */}
            <div className='fs-14'>Enter the details to reset your password</div>
            {/* end::Link */}
          </div>

          {/* begin::Title */}
          {hasErrors === true && (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>
                Sorry, looks like there are some errors detected, please try again.
              </div>
            </div>
          )}

          {hasErrors === false && (
            <div className='mb-10 bg-light-success p-4 rounded'>
              <div className='text-success fw-bold'>
                Password reset successful. <Link to='/auth/admin/login'> Go to Login page</Link>.
              </div>
            </div>
          )}
          {/* end::Title */}

          {/* begin::Form group */}
          <div className='fv-row mb-10 m32'>
            <label className='form-label fs-14 required'>Email</label>

            <input
              type='email'
              placeholder='Enter your email'
              autoComplete='off'
              {...formik.getFieldProps('email')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                { 'is-invalid': formik.touched.email && formik.errors.email },
                {
                  'is-valid': formik.touched.email && !formik.errors.email,
                }
              )}
            />
            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.email}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group */}
          <div className='fv-row mb-10'>
            <div className='d-flex justify-content-between mt-n5'>
              <div className='d-flex flex-stack mb-2'>
                {/* begin::Label */}
                <label className='form-label fs-14 mb-0 mt-5 required'>Password</label>

                {/* end::Label */}
                {/* begin::Link */}
                {/* <Link
              to='/auth/forgot-password'
              className='link-primary fs-6 fw-bolder'
              style={{marginLeft: '5px'}}
            >
              Forgot Password ?
            </Link> */}
                {/* end::Link */}
              </div>
            </div>
            <input
              type='password'
              placeholder='Enter your password'
              autoComplete='off'
              {...formik.getFieldProps('newPassword')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.newPassword && formik.errors.newPassword,
                },
                {
                  'is-valid': formik.touched.newPassword && !formik.errors.newPassword,
                }
              )}
            />
            {formik.touched.newPassword && formik.errors.newPassword && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.newPassword}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group */}
          <div className='fv-row mb-10'>
            <div className='d-flex justify-content-between mt-n5'>
              <div className='d-flex flex-stack mb-2'>
                {/* begin::Label */}
                <label className='form-label fs-14 mb-0 mt-5 required'>Confirm Password</label>

                {/* end::Label */}
                {/* begin::Link */}
                {/* <Link
              to='/auth/forgot-password'
              className='link-primary fs-6 fw-bolder'
              style={{marginLeft: '5px'}}
            >
              Forgot Password ?
            </Link> */}
                {/* end::Link */}
              </div>
            </div>
            <input
              type='password'
              placeholder='Re-enter your password'
              autoComplete='off'
              {...formik.getFieldProps('confirmPassword')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.confirmPassword && formik.errors.confirmPassword,
                },
                {
                  'is-valid': formik.touched.confirmPassword && !formik.errors.confirmPassword,
                }
              )}
            />
            {formik.touched.confirmPassword && formik.errors.confirmPassword && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.confirmPassword}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group */}
          <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
            <button
              type='submit'
              id='kt_password_reset_submit'
              className='btn btn-primary w-100 mb-5'
            >
              <span className='indicator-label'>Reset Password</span>
              {loading && (
                <span className='indicator-progress'>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </div>
        {/* end::Form group */}
      </form>
    </>
  )
}
