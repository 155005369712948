/* eslint-disable jsx-a11y/alt-text */
import { message, Select } from 'antd'
import clsx from 'clsx'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import CustomSelect from 'react-select'
import * as Yup from 'yup'
import httpService from '../../setup/http/httpServices'
import { toAbsoluteUrl } from '../../_metronic/helpers'
import loc from '../data/Location.json'
import '../masters/basic.css'
import { CampaignLeftBreadcrum } from './CampaignLeftBreadcrum'
const { Option } = Select

var initialInstagramValues = {
  influencerType: '',
  ageGroup: '',
  flexible: false,
  engagementRatePerVideo: '',
  primaryGender: '',
  image: '',
  videoStory: '',
  reel30s: '',
  reel60s: '',
  video: '',
  carousel: '',
  staticStory: '',
  location: '',
}

var initialYoutubeValues = {
  influencerType: '',
  flexible: false,
  primaryGender: '',
  engagementRatePerVideo: '',
  category: '',
}
const registrationSchema = Yup.object().shape({
  // influencerType: Yup.array().required('Influencer Type is required'),
  // flexible: Yup.array().required('Flexible is required'),
  // engagementRatePerVideo: Yup.string()
  //   .required('Engagement Rate/Video is required'),
  // ageGroup: Yup.array().required('Influencer Type is required'),
  // category: Yup.string()
  // .required('Category is required'),
})
export function InfluencerDetail() {
  const history: any = useHistory()
  const campaignDetails = history.location.state?.campaignDetails
  const influencerDetails = history.location.state
  const [loading, setLoading] = useState(false)
  const [influencerYoutubeDetails, setInfluencerYoutubeDetails] = useState(initialYoutubeValues)
  const [influencerInstgramDetails, setInfluencerInstagramDetails] =
    useState(initialInstagramValues)
  const [categories, setCategories] = useState([])
  const [priceMaster, setPriceMaster] = useState<any>([])
  const [category, setCategory] = useState<any>([])
  const [disableInfluencerType, setDisableInfluencerType] = useState(false)
  const [disableITInsta, setDisableITInsta] = useState(false)
  const [cities, setCities] = useState([])
  const [location, setLocation] = useState<any>([])




  useEffect(function () {
    getAllMasters()
    updatData()
    getCities()
  }, [])
  const getCities = () => {
    var c: any = []
    var ci: any = []
    ci.push({ value: 'PAN India', label: 'PAN India' })
    loc.map((l) => {
      c = l.Cities
      c.map((ct: any) => {
        var p = {
          value: ct,
          label: ct,
        }
        ci.push(p)
      })
    })
    setCities(ci)
  }
  const isFormDisabled = () => {
    if (campaignDetails.platform === 'Instagram') {
      if (formik.values.flexible)
        return !(formik.values.primaryGender && formik.values.ageGroup.length > 0 && location.length > 0)
      else
        return !(formik.values.primaryGender && formik.values.ageGroup.length > 0 && location.length > 0 && (priceMaster.filter(master => master.count).length > 0))
    } else if (campaignDetails.platform === 'Youtube') {
      if (formik.values.flexible)
        return !(formik.values.engagementRatePerVideo && category.length > 0)
      else
        return !(formik.values.engagementRatePerVideo && category.length > 0 && (priceMaster.filter(master => master.count).length > 0))
    }
  }
  const getCounterValue: any = () => {
    const totalValue = priceMaster.reduce((total: any, number: any) => {
      return parseInt(total) + parseInt(number.count || 0)
    }, 0);
    let count = history.location.state.campaignDetails.noOfInfluencers - (parseInt(totalValue) || 0);
    return count;
  }
  const updatData = () => {
    // var influencerDetails: any;
    if (
      campaignDetails.platform === 'Instagram' &&
      influencerDetails.instagramInfluencerDetails?.ageGroup?.length > 0
    ) {
      const details = influencerDetails?.instagramInfluencerDetails
      influencerDetails.platform = 'Instagram'
      formik.setFieldValue('flexible', details.influencerCount.flexible)
      formik.setFieldValue('primaryGender', details.primaryGender)
      formik.setFieldValue('ageGroup', details.ageGroup)
      formik.setFieldValue('engagementRatePerVideo', details.engagementRatePerVideo)
      formik.setFieldValue('image', details.mediaCount.image)
      formik.setFieldValue('videoStory', details.mediaCount.videoStory)
      formik.setFieldValue('reel30s', details.mediaCount.reel30s)
      formik.setFieldValue('reel60s', details.mediaCount.reel60s)
      formik.setFieldValue('video', details.mediaCount.video)
      formik.setFieldValue('carousel', details.mediaCount.carousel)
      formik.setFieldValue('staticStory', details.mediaCount.staticStory)
      formik.setFieldValue('ageGroup', details.ageGroup)
      formik.setFieldValue('location', details.location)
      setLocation(
        details['location'].split(',')?.map((x: any) => {
          return { label: x, value: x }
        })
      )
    } else if (
      campaignDetails.platform === 'Youtube' &&
      influencerDetails.youtubeInfluencerDetails?.engagementRatePerVideo
    ) {
      const details = influencerDetails?.youtubeInfluencerDetails
      influencerDetails.platform = 'Youtube'
      // let type = Object.keys(influencerDetails.influencerCount).filter((x: any) => !isNaN(parseInt(influencerDetails.influencerCount[x])));
      // formik.setFieldValue('influencerType', type);
      formik.setFieldValue('flexible', details.influencerCount.flexible)
      formik.setFieldValue('primaryGender', details.primaryGender)
      formik.setFieldValue('engagementRatePerVideo', details.engagementRatePerVideo)
      formik.setFieldValue('category', details.categories)
      // setInfluencerYoutubeDetails(influencerDetails)
    }
    if (influencerDetails) {
      formik.setFieldValue('platform', campaignDetails?.platform)
    }
  }
  const getAllMasters = () => {
    httpService.getData(
      '/price-master',
      (response: any) => {
        let priceMaster: any = response.data.data.priceMasters.filter(
          (x) =>
            x.socialMedia.socialMediaName.toLowerCase() === campaignDetails?.platform.toLowerCase()
        )

        const influencer =
          campaignDetails.platform === 'Youtube'
            ? influencerDetails?.youtubeInfluencerDetails?.influencerCount
            : influencerDetails?.instagramInfluencerDetails?.influencerCount
        priceMaster.map((x: any) => {
          if (influencer && influencer[x.bucketName] && influencer[x.bucketName] > 0) {
            x.count = influencer[x.bucketName]
            x.checked = true
          } else {
            x.count = null
            x.checked = false
          }
        })
        const numAscending = [...priceMaster].sort((a, b) => a.minimumFollowerCount - b.minimumFollowerCount);
        setPriceMaster(numAscending)
      },
      (error: any) => {
        message.error(error.message)
      }
    )
    httpService.getData(
      '/category-master',
      (response: any) => {
        response.data.data.categories.map((x: any) => {
          x['value'] = x._id
          x['label'] = x.categoryName
          return x
        })
        setCategories(response.data.data.categories)
        if (campaignDetails.platform === 'Instagram') {
        } else if (campaignDetails.platform === 'Youtube') {
          formik.setFieldValue(
            'engagementRatePerVideo',
            influencerDetails?.youtubeInfluencerDetails?.engagementRatePerVideo
          )
          if (influencerDetails?.youtubeInfluencerDetails?.categories)
            setCategory(influencerDetails?.youtubeInfluencerDetails?.categories)
        }
      },
      (error: any) => {
        message.error(error.message)
      }
    )
  }

  const values = () => {
    campaignDetails.platform === 'Youtube'
      ? setInfluencerYoutubeDetails(initialYoutubeValues)
      : setInfluencerInstagramDetails(initialInstagramValues)
  }
  const initValues: any =
    campaignDetails.platform === 'Youtube'
      ? influencerYoutubeDetails || initialYoutubeValues
      : influencerInstgramDetails || initialInstagramValues

  const formik = useFormik({
    initialValues: initValues,
    validationSchema: registrationSchema,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      const totalValue = priceMaster.reduce((total: any, number: any) => {
        return parseInt(total) + parseInt(number.count || 0)
      }, 0)
      if (history.location.state.campaignDetails.noOfInfluencers !== totalValue && !values.flexible) {
        message.error('Influencer count does not match with the count specified in previous step')
        setSubmitting(false)

        return
      }

      setLoading(true)
      setTimeout(() => {
        campaignDetails.platform === 'Youtube'
          ? setInfluencerYoutubeDetails({ ...influencerYoutubeDetails, ...values })
          : setInfluencerInstagramDetails({ ...influencerInstgramDetails, ...values })
        setLoading(false)
        setSubmitting(false)
        let request: any = {
          campaignDetails: history.location.state.campaignDetails
        }
        if (history.location.state.stateOverview?.step3) {
          request.audienceDetails = history.location.state?.audienceDetails;
        }
        if (history.location.state.stateOverview?.step4) {
          request.otherDetails = history.location.state?.otherDetails;
        }
        if (campaignDetails.platform === 'Instagram') {
          request['instagramInfluencerDetails'] = {
            mediaCount: {
              image: values.image,
              videoStory: values.videoStory,
              reel30s: values.reel30s,
              reel60s: values.reel60s,
              video: values.video,
              carousel: values.carousel,
              staticStory: values.staticStory,
            },
            influencerCount: {
              flexible: values.flexible,
            },
            primaryGender: values.primaryGender,
            ageGroup: values.ageGroup,
            location: location.map((x: any) => x.label).join(','),
          }

          priceMaster.forEach((master: any) => {
            if (master.count > 0) {
              request['instagramInfluencerDetails']['influencerCount'][master.bucketName] =
                parseInt(master.count)
            }
          })
          console.log('setSubmitting', values)
        } else if (campaignDetails.platform === 'Youtube') {
          request['youtubeInfluencerDetails'] = {
            influencerCount: {
              flexible: values.flexible,
            },
            engagementRatePerVideo: values.engagementRatePerVideo,
            categories: category,
          }
          priceMaster.forEach((master: any) => {
            if (master.count > 0) {
              request['youtubeInfluencerDetails']['influencerCount'][master.bucketName] = parseInt(
                master.count
              )
            }
          })
        }
        httpService.patchData(
          '/campaign-master/' + history.location.state?._id,
          request,
          (response: any) => {
            message.success('Influencer details saved successfully.')
            const state = {
              ...history.location.state,
            }
            if (campaignDetails.platform === 'Instagram') {
              state.instagramInfluencerDetails = request['instagramInfluencerDetails']
            } else if (campaignDetails.platform === 'Youtube') {
              state.youtubeInfluencerDetails = request['youtubeInfluencerDetails']
            }
            history.push({
              pathname: '/campaign/audienceDetails',
              state: {
                ...state,
              },
            })
          },
          (error: any) => {
            message.error(error.message)
          }
        )
      }, 1000)
    },
  })
  const setPriceData = (value: any, key: string, index: number) => {
    const tempBucket = [...priceMaster]
    if (key === 'checked' && !value) {
      tempBucket[index]['count'] = 0
    }
    tempBucket[index][key] = value
    setPriceMaster(tempBucket)
  }
  const back = () => {
    let variable = ''
    const state = {
      ...history.location.state,
    }
    if (campaignDetails.platform === 'Instagram') {
      state.instagramInfluencerDetails = {
        mediaCount: {
          image: formik.values.image,
          videoStory: formik.values.videoStory,
          reel30s: formik.values.reel30s,
          reel60s: formik.values.reel60s,
          video: formik.values.video,
          carousel: formik.values.carousel,
          staticStory: formik.values.staticStory,
        },
        influencerCount: {
          flexible: formik.values.flexible,
        },
        primaryGender: formik.values.primaryGender,
        ageGroup: formik.values.ageGroup,
        location: formik.values.location,
      }
      priceMaster.forEach((master: any) => {
        if (master.count > 0) {
          state.instagramInfluencerDetails.influencerCount[master.bucketName] = parseInt(
            master.count
          )
        }
      })
    } else if (campaignDetails.platform === 'Youtube') {
      state.youtubeInfluencerDetails = {
        influencerCount: {
          flexible: formik.values.flexible,
        },
        engagementRatePerVideo: formik.values.engagementRatePerVideo,
        categories: category,
      }
      priceMaster.forEach((master: any) => {
        if (master.count > 0) {
          state.youtubeInfluencerDetails.influencerCount[master.bucketName] = parseInt(master.count)
        }
      })
    }
    campaignDetails.platform === 'Instagram'
      ? (variable = 'instagramInfluencerDetails')
      : (variable = 'youtubeInfluencerDetails')
    history.push({
      pathname: '/campaign/details',
      state: { ...state },
    })
  }
  return (
    <div className='CompanyDetailsOuter'>
      {values}
      <CampaignLeftBreadcrum currentStep='2' />
      <form
        className='CompanyDetails campaignDetails'
        noValidate
        id='kt_login_signup_form'
        onSubmit={formik.handleSubmit}
        style={{ marginBottom: '40px' }}
      >
        <h2 className='mb-0 '>Enter Influencer Preferences</h2>

        {campaignDetails.platform === 'Youtube' && (
          <div>
            <div className='m32 flex justify-content-start'>
              <img
                className='influencerDetailsImage me-2'
                src={toAbsoluteUrl('/media/logos/Youtube.svg')}
              />
              <h3 className='mb-0 '>YouTube</h3>
            </div>
            <hr className='m16'></hr>
            <div className='m32' data-kt-buttons='true'>
              <div className='fw-bold flex justify-content-start w-100 mr-0'>
                <div className='w-50'>
                  <h6 className='fw-bold mb-0 required'>Influencer Type (based on subscribers)</h6>
                  <p className='text-gray-400 mb-0 fs-7'>Add influencer count based on the requirement</p>
                </div>
                <div className='w-50 text-align-right me-5'>
                  Pending Influencer Count: {getCounterValue() < 0 ? <span className='text-danger' role='alert'>Count Exceeded</span> : getCounterValue()}
                </div>
              </div>
              <div
                className='d-flex w-100 form-check form-check-custom form-check-solid mt-5'
                style={{ flexWrap: 'wrap' }}
              >
                {priceMaster.map((master: any, index: number) => (
                  <label className='w-50 flex justify-content-start' style={{ paddingRight: '16px' }}>
                    <input
                      disabled={disableInfluencerType}
                      onChange={(e) => setPriceData(e.target.checked, 'checked', index)}
                      className={clsx(
                        'form-check-input w-20px h-20px'
                      )}
                      type='checkbox'
                      name='influencerType'
                      checked={master.checked}
                      value='nano'
                      id='flexCheckDefault'
                    />
                    <div className='fs-6 ms-4 mt-1 w-50'>
                      <h6 className='mb-0'>{master.bucketName}</h6>{' '}
                      <label>
                        {master?.minimumFollowerCount >= 1000
                          ? master?.minimumFollowerCount / 1000 + 'K'
                          : master?.minimumFollowerCount}
                        {'-'}
                        {master?.maximumFollowerCount >= 1000
                          ? master?.maximumFollowerCount / 1000 + 'K'
                          : master?.maximumFollowerCount}
                      </label>
                    </div>
                    {formik.touched.influencerType && formik.errors.influencerType && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span className='text-danger' role='alert'>
                            {formik.errors.influencerType}
                          </span>
                        </div>
                      </div>
                    )}

                    <div className=' mt-1 w-100'>
                      <input
                        type='number'
                        onChange={(e) => setPriceData(e.target.value, 'count', index)}
                        disabled={!master.checked || disableInfluencerType}
                        value={master.checked ? master.count : ''}
                        className={clsx(
                          'form-control form-control-solid bg-gray-100 w-100',
                          {
                            'is-invalid': formik.touched.nano && formik.errors.nano,
                          },
                          {
                            'is-valid': formik.touched.nano && !formik.errors.nano,
                          }
                        )}
                      />
                    </div>
                  </label>
                ))}
              </div>

              <div className='flex justify-content-start m16'>
                <hr className='w-30px h-3px'></hr>
                <p className='text-gray-400 ms-5 fs-7 m-0 me-5'>OR</p>
                <hr className='w-30px h-3px'></hr>
              </div>
              <label className='w-100'>
                <div className=' flex w-100 form-check form-check-custom form-check-solid'>
                  <label className='m16 w-100 flex  justify-content-start'>
                    <input
                      {...formik.getFieldProps('flexible')}
                      className={clsx('form-check-input w-20px h-20px')}
                      type='checkbox'
                      name='flexible'
                      value='flexible'
                      onChange={(e: any) => {
                        formik.setFieldValue('flexible', e.target.checked)
                        if (e.target.checked) {
                          setDisableInfluencerType(true)
                          let master = [...priceMaster]
                          priceMaster.map((e: any) => {
                            e.checked = false
                            e.count = 0
                          })
                          setPriceMaster(master)
                        } else {
                          setDisableInfluencerType(false)
                        }
                      }}
                      checked={formik.values?.flexible}
                      id='flexCheckDefault'
                    />
                    <div className='fs-6 ms-4 mt-1 w-50'>
                      <h6 className='mb-0'>Flexible</h6>{' '}
                      <label>If you’re unsure with influencer count </label>
                    </div>
                    {formik.touched.flexible && formik.errors.flexible && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span className='text-danger' role='alert'>
                            {formik.errors.flexible}
                          </span>
                        </div>
                      </div>
                    )}
                  </label>
                </div>
              </label>
            </div>

            <div className='col m24'>
              <h6 className='fw-bold required'>Engagement Rate/Video</h6>
              <input
                type='number'
                placeholder='Enter the expected percentage'
                {...formik.getFieldProps('engagementRatePerVideo')}
                className={clsx(
                  'form-control form-control-solid bg-gray-100',
                  {
                    'is-invalid':
                      formik.touched.engagementRatePerVideo && formik.errors.engagementRatePerVideo,
                  },
                  {
                    'is-valid':
                      formik.touched.engagementRatePerVideo &&
                      !formik.errors.engagementRatePerVideo,
                  }
                )}
              />
              {formik.touched.engagementRatePerVideo && formik.errors.engagementRatePerVideo && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='text-danger' role='alert'>
                      {formik.errors.engagementRatePerVideo}
                    </span>
                  </div>
                </div>
              )}
            </div>
            <div className='col m24'>
              <h6 className='fw-bold required'>Category</h6>
              <Select
                mode='tags'
                style={{ width: '100%' }}
                placeholder='Select one or more categories'
                {...formik.getFieldProps('category')}
                className={clsx(
                  'form-control form-control-solid bg-gray-100 p-0 ms-0',
                  {
                    'is-invalid': formik.touched.category && formik.errors.category,
                  },
                  {
                    'is-valid': formik.touched.category && !formik.errors.category,
                  }
                )}
                value={category}
                onChange={(e: any) => setCategory(e)}
              >

                {categories.map((data: any) => (
                  <Option key={data._id}>{data.label}</Option>
                ))}
              </Select>


              {formik.touched.category && formik.errors.category && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='text-danger' role='alert'>
                      {formik.errors.category}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        {campaignDetails.platform === 'Instagram' && (
          <div>
            <div className='m32 flex justify-content-start'>
              <img
                className='influencerDetailsImage me-2'
                src={toAbsoluteUrl('/media/logos/Instagram.svg')}
              />
              <h3 className='mb-0 '>Instagram</h3>
            </div>
            <hr className='m16'></hr>
            <div className='m32' data-kt-buttons='true'>
              <h6 className='fw-bold mb-0 required'>Content Type</h6>
              <p className='text-gray-400 mb-0 fs-7'>Add media count required for the campaign</p>
              <label className='w-100'>
                <div className='flex w-100 mt-5'>
                  <label className='w-100 flex me-10 justify-content-start'>
                    <img
                      className='w-20px h-20px'
                      src={toAbsoluteUrl('/media/logos/ImageBW.svg')}
                    />
                    <h6 className='mb-0 fs-6 ms-2 w-50'>Image</h6>
                    <img
                      className='pointer'
                      style={{ height: '32px', width: '32px', marginRight: '12px' }}
                      src={toAbsoluteUrl('/media/icons/Custom/minus-button.svg')}
                      onClick={() => {
                        if (formik.values.image != 0) {
                          formik.setFieldValue('image', parseInt(formik.values.image) - 1)
                        }
                      }}
                    />
                    <div className=' mt-1 w-100'>
                      <input
                        type='number'
                        placeholder='0'
                        {...formik.getFieldProps('image')}
                        className={clsx(
                          'form-control form-control-solid bg-gray-100 w-100 align-center',
                          {
                            'is-invalid': formik.touched.image && formik.errors.image,
                          },
                          {
                            'is-valid': formik.touched.image && !formik.errors.image,
                          }
                        )}
                      />
                      {formik.touched.image && formik.errors.image && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span className='text-danger' role='alert'>
                              {formik.errors.image}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                    <img
                      className='pointer'
                      style={{ height: '32px', width: '32px', marginLeft: '12px' }}
                      src={toAbsoluteUrl('/media/icons/Custom/plus-button.svg')}
                      onClick={() => {
                        if (formik.values.image)
                          formik.setFieldValue('image', parseInt(formik.values.image) + 1)
                        else formik.setFieldValue('image', 1)
                      }}
                    />
                  </label>
                  <label className='w-100 flex justify-content-start'>
                    <img
                      className='w-20px h-20px'
                      src={toAbsoluteUrl('/media/logos/YoutubeBW.svg')}
                    />

                    <h6 className='mb-0 fs-6 ms-2 w-50'>Video Story</h6>
                    <img
                      className='pointer'
                      style={{ height: '32px', width: '32px', marginRight: '12px' }}
                      src={toAbsoluteUrl('/media/icons/Custom/minus-button.svg')}
                      onClick={() => {
                        if (formik.values.videoStory != 0) {
                          formik.setFieldValue('videoStory', parseInt(formik.values.videoStory) - 1)
                        }
                      }}
                    />
                    <div className=' mt-1 w-100'>
                      <input
                        type='number'
                        placeholder='0'
                        {...formik.getFieldProps('videoStory')}
                        className={clsx(
                          'form-control form-control-solid bg-gray-100 w-100 align-center',
                          {
                            'is-invalid': formik.touched.videoStory && formik.errors.videoStory,
                          },
                          {
                            'is-valid': formik.touched.videoStory && !formik.errors.videoStory,
                          }
                        )}
                      />
                      {formik.touched.videoStory && formik.errors.videoStory && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span className='text-danger' role='alert'>
                              {formik.errors.videoStory}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                    <img
                      className='pointer'
                      style={{ height: '32px', width: '32px', marginLeft: '12px' }}
                      src={toAbsoluteUrl('/media/icons/Custom/plus-button.svg')}
                      onClick={() => {
                        if (formik.values.videoStory)
                          formik.setFieldValue('videoStory', parseInt(formik.values.videoStory) + 1)
                        else formik.setFieldValue('videoStory', 1)
                      }}
                    />
                  </label>
                </div>
                <div className='flex w-100 mt-5'>
                  <label className='w-100 flex me-10 justify-content-start'>
                    <img className='w-20px h-20px' src={toAbsoluteUrl('/media/logos/ReelBW.svg')} />

                    <div className='fs-6 ms-2 mt-1 w-50'>
                      <h6 className='mb-0'>Reel</h6> <label>For 30 sec</label>
                    </div>
                    <img
                      className='pointer'
                      style={{ height: '32px', width: '32px', marginRight: '12px' }}
                      src={toAbsoluteUrl('/media/icons/Custom/minus-button.svg')}
                      onClick={() => {
                        if (formik.values.reel30s != 0) {
                          formik.setFieldValue('reel30s', parseInt(formik.values.reel30s) - 1)
                        }
                      }}
                    />
                    <div className=' mt-1 w-100'>
                      <input
                        type='number'
                        placeholder='0'
                        {...formik.getFieldProps('reel30s')}
                        className={clsx(
                          'form-control form-control-solid bg-gray-100 w-100 align-center',
                          {
                            'is-invalid': formik.touched.reel30s && formik.errors.reel30s,
                          },
                          {
                            'is-valid': formik.touched.reel30s && !formik.errors.reel30s,
                          }
                        )}
                      />
                      {formik.touched.reel30s && formik.errors.reel30s && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span className='text-danger' role='alert'>
                              {formik.errors.reel30s}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                    <img
                      className='pointer'
                      style={{ height: '32px', width: '32px', marginLeft: '12px' }}
                      src={toAbsoluteUrl('/media/icons/Custom/plus-button.svg')}
                      onClick={() => {
                        if (formik.values.reel30s)
                          formik.setFieldValue('reel30s', parseInt(formik.values.reel30s) + 1)
                        else formik.setFieldValue('reel30s', 1)
                      }}
                    />
                  </label>
                  <label className='w-100 flex  justify-content-start'>
                    <img className='w-20px h-20px' src={toAbsoluteUrl('/media/logos/ReelBW.svg')} />

                    <div className='fs-6 ms-2 mt-1 w-50'>
                      <h6 className='mb-0'>Reel</h6> <label>For 60 sec</label>
                    </div>
                    <img
                      className='pointer'
                      style={{ height: '32px', width: '32px', marginRight: '12px' }}
                      src={toAbsoluteUrl('/media/icons/Custom/minus-button.svg')}
                      onClick={() => {
                        if (formik.values.reel60s != 0) {
                          formik.setFieldValue('reel60s', parseInt(formik.values.reel60s) - 1)
                        }
                      }}
                    />
                    <div className=' mt-1 w-100'>
                      <input
                        type='number'
                        placeholder='0'
                        {...formik.getFieldProps('reel60s')}
                        className={clsx(
                          'form-control form-control-solid bg-gray-100 w-100 align-center',
                          {
                            'is-invalid': formik.touched.reel60s && formik.errors.reel60s,
                          },
                          {
                            'is-valid':
                              formik.touched.reel60s &&
                              !formik.errors.reel60s,
                          }
                        )}
                      />
                      {formik.touched.reel60s && formik.errors.reel60s && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span className='text-danger' role='alert'>
                              {formik.errors.reel60s}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                    <img
                      className='pointer'
                      style={{ height: '32px', width: '32px', marginLeft: '12px' }}
                      src={toAbsoluteUrl('/media/icons/Custom/plus-button.svg')}
                      onClick={() => {
                        if (formik.values.reel60s)
                          formik.setFieldValue('reel60s', parseInt(formik.values.reel60s) + 1)
                        else formik.setFieldValue('reel60s', 1)
                      }}
                    />
                  </label>
                </div>
                <div className='flex w-100 mt-5'>
                  <label className='w-100 flex me-10 justify-content-start'>
                    <img
                      className='w-20px h-20px'
                      src={toAbsoluteUrl('/media/logos/YoutubeBW.svg')}
                    />

                    <h6 className='mb-0 fs-6 ms-2 w-50'>Video</h6>
                    <img
                      className='pointer'
                      style={{ height: '32px', width: '32px', marginRight: '12px' }}
                      src={toAbsoluteUrl('/media/icons/Custom/minus-button.svg')}
                      onClick={() => {
                        if (formik.values.video != 0) {
                          formik.setFieldValue('video', parseInt(formik.values.video) - 1)
                        }
                      }}
                    />
                    <div className=' mt-1 w-100'>
                      <input
                        type='number'
                        placeholder='0'
                        {...formik.getFieldProps('video')}
                        className={clsx(
                          'form-control form-control-solid bg-gray-100 w-100 align-center',
                          {
                            'is-invalid': formik.touched.video && formik.errors.video,
                          },
                          {
                            'is-valid': formik.touched.video && !formik.errors.video,
                          }
                        )}
                      />
                      {formik.touched.video && formik.errors.video && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span className='text-danger' role='alert'>
                              {formik.errors.video}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                    <img
                      className='pointer'
                      style={{ height: '32px', width: '32px', marginLeft: '12px' }}
                      src={toAbsoluteUrl('/media/icons/Custom/plus-button.svg')}
                      onClick={() => {
                        if (formik.values.video)
                          formik.setFieldValue('video', parseInt(formik.values.video) + 1)
                        else formik.setFieldValue('video', 1)
                      }}
                    />
                  </label>
                  {/* <label className='w-100 flex  justify-content-start'>
                    <img className='w-20px h-20px' src={toAbsoluteUrl('/media/logos/IGTVBW.svg')} />

                    <h6 className='mb-0 fs-6 ms-2 w-50'>Shorts</h6>
                    <img
                      className='pointer'
                      style={{ height: '32px', width: '32px', marginRight: '12px' }}
                      src={toAbsoluteUrl('/media/icons/Custom/minus-button.svg')}
                      onClick={() => {
                        if (formik.values.shorts != 0) {
                          formik.setFieldValue('shorts', parseInt(formik.values.shorts) - 1)
                        }
                      }}
                    />
                    <div className=' mt-1 w-100'>
                      <input
                        type='number'
                        placeholder='0'
                        {...formik.getFieldProps('shorts')}
                        className={clsx(
                          'form-control form-control-solid bg-gray-100 w-100 align-center',
                          {
                            'is-invalid': formik.touched.shorts && formik.errors.shorts,
                          }
                        )}
                      />
                      {formik.touched.shorts && formik.errors.shorts && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span className='text-danger' role='alert'>
                              {formik.errors.shorts}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                    <img
                      className='pointer'
                      style={{ height: '32px', width: '32px', marginLeft: '12px' }}
                      src={toAbsoluteUrl('/media/icons/Custom/plus-button.svg')}
                      onClick={() => {
                        if (formik.values.shorts)
                          formik.setFieldValue('shorts', parseInt(formik.values.shorts) + 1)
                        else formik.setFieldValue('shorts', 1)
                      }}
                    />
                  </label> */}
                  <label className='w-100 flex justify-content-start'>
                    <img
                      className='w-20px h-20px'
                      src={toAbsoluteUrl('/media/logos/YoutubeBW.svg')}
                    />

                    <h6 className='mb-0 fs-6 ms-2 w-50'>Carousel</h6>
                    <img
                      className='pointer'
                      style={{ height: '32px', width: '32px', marginRight: '12px' }}
                      src={toAbsoluteUrl('/media/icons/Custom/minus-button.svg')}
                      onClick={() => {
                        if (formik.values.carousel != 0) {
                          formik.setFieldValue('carousel', parseInt(formik.values.carousel) - 1)
                        }
                      }}
                    />
                    <div className=' mt-1 w-100'>
                      <input
                        type='number'
                        placeholder='0'
                        {...formik.getFieldProps('carousel')}
                        className={clsx(
                          'form-control form-control-solid bg-gray-100 w-100 align-center',
                          {
                            'is-invalid': formik.touched.carousel && formik.errors.carousel,
                          },
                          {
                            'is-valid': formik.touched.carousel && !formik.errors.carousel,
                          }
                        )}
                      />
                      {formik.touched.carousel && formik.errors.carousel && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span className='text-danger' role='alert'>
                              {formik.errors.carousel}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                    <img
                      className='pointer'
                      style={{ height: '32px', width: '32px', marginLeft: '12px' }}
                      src={toAbsoluteUrl('/media/icons/Custom/plus-button.svg')}
                      onClick={() => {
                        if (formik.values.carousel)
                          formik.setFieldValue('carousel', parseInt(formik.values.carousel) + 1)
                        else formik.setFieldValue('carousel', 1)
                      }}
                    />
                  </label>
                </div>
                <div className='flex w-50 mt-5'>
                  <label className='w-100 flex me-5 justify-content-start'>
                    <img
                      className='w-20px h-20px'
                      src={toAbsoluteUrl('/media/logos/YoutubeBW.svg')}
                    />

                    <h6 className='mb-0 fs-6 ms-2 w-50'>Static Story</h6>
                    <img
                      className='pointer'
                      style={{ height: '32px', width: '32px', marginRight: '12px' }}
                      src={toAbsoluteUrl('/media/icons/Custom/minus-button.svg')}
                      onClick={() => {
                        if (formik.values.staticStory != 0) {
                          formik.setFieldValue(
                            'staticStory',
                            parseInt(formik.values.staticStory) - 1
                          )
                        }
                      }}
                    />
                    <div className=' mt-1 w-100'>
                      <input
                        type='number'
                        placeholder='0'
                        {...formik.getFieldProps('staticStory')}
                        className={clsx(
                          'form-control form-control-solid bg-gray-100 w-100 align-center',
                          {
                            'is-invalid': formik.touched.staticStory && formik.errors.staticStory,
                          },
                          {
                            'is-valid': formik.touched.staticStory && !formik.errors.staticStory,
                          }
                        )}
                      />
                      {formik.touched.staticStory && formik.errors.staticStory && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span className='text-danger' role='alert'>
                              {formik.errors.staticStory}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                    <img
                      className='pointer'
                      style={{ height: '32px', width: '32px', marginLeft: '12px' }}
                      src={toAbsoluteUrl('/media/icons/Custom/plus-button.svg')}
                      onClick={() => {
                        if (formik.values.staticStory)
                          formik.setFieldValue(
                            'staticStory',
                            parseInt(formik.values.staticStory) + 1
                          )
                        else formik.setFieldValue('staticStory', 1)
                      }}
                    />
                  </label>
                </div>
              </label>
            </div>
            <div className='m32' data-kt-buttons='true'>
              <div className='fw-bold flex justify-content-start w-100 mr-0'>
                <div className='w-50'>
                  <h6 className='fw-bold mb-0 required'>Influencer Type (Based on Followers)</h6>
                  <p className='text-gray-400 mb-0 fs-7'>Enter a number for each influencer type</p>
                </div>
                <div className='w-50 text-align-right me-5'>
                  Pending Influencer Count: {getCounterValue() < 0 ? <span className='text-danger' role='alert'>Count Exceeded</span> : getCounterValue()}
                </div>
              </div>
              <div
                className='d-flex w-100 form-check form-check-custom form-check-solid mt-5'
                style={{ flexWrap: 'wrap' }}
              >
                {priceMaster.map((master: any, index: number) => (
                  <label className='w-50 flex justify-content-start' style={{ paddingRight: '16px' }}>
                    <input
                      disabled={disableITInsta}
                      onChange={(e) => setPriceData(e.target.checked, 'checked', index)}
                      className={clsx('form-check-input w-20px h-20px')}
                      type='checkbox'
                      name='influencerType'
                      checked={master.checked}
                      value='nano'
                      id='flexCheckDefault'
                    />
                    <div className='fs-6 ms-4 mt-1 w-50'>
                      <h6 className='mb-0'>{master.bucketName}</h6>{' '}
                      <label>
                        {master?.minimumFollowerCount >= 1000
                          ? master?.minimumFollowerCount / 1000 + 'K'
                          : master?.minimumFollowerCount}
                        {'-'}
                        {master?.maximumFollowerCount >= 1000
                          ? master?.maximumFollowerCount / 1000 + 'K'
                          : master?.maximumFollowerCount}
                      </label>
                    </div>
                    {formik.touched.influencerType && formik.errors.influencerType && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span className='text-danger' role='alert'>
                            {formik.errors.influencerType}
                          </span>
                        </div>
                      </div>
                    )}

                    <div className=' mt-1 w-100'>
                      <input
                        type='number'
                        placeholder='Enter exact number'
                        onChange={(e) => setPriceData(e.target.value, 'count', index)}
                        disabled={!master.checked || disableITInsta}
                        value={master.checked ? master.count : ''}
                        className={clsx(
                          'form-control form-control-solid bg-gray-100 w-100',
                          {
                            'is-invalid': formik.touched.nano && formik.errors.nano,
                          },
                          {
                            'is-valid': formik.touched.nano && !formik.errors.nano,
                          }
                        )}
                      />
                    </div>
                  </label>
                ))}
              </div>

              <div className='flex justify-content-start m16'>
                <hr className='w-30px h-3px'></hr>
                <p className='text-gray-400 ms-5 fs-7 m-0 me-5'>OR</p>
                <hr className='w-30px h-3px'></hr>
              </div>
              <label className='w-100'>
                <div className=' flex w-100 form-check form-check-custom form-check-solid'>
                  <label className='m16 w-100 flex  justify-content-start'>
                    <input
                      {...formik.getFieldProps('flexible')}
                      className={clsx(
                        'form-check-input w-20px h-20px',
                        {
                          'is-invalid': formik.touched.flexible && formik.errors.flexible,
                        },
                        {
                          'is-valid': formik.touched.flexible && !formik.errors.flexible,
                        }
                      )}
                      type='checkbox'
                      value='flexible'
                      checked={formik.values.flexible}
                      id='flexCheckDefault'
                      onChange={(e: any) => {
                        formik.setFieldValue('flexible', e.target.checked)
                        if (e.target.checked) {
                          setDisableITInsta(true)
                          let master = [...priceMaster]
                          priceMaster.map((e: any) => {
                            e.checked = false
                            e.count = 0
                          })
                          setPriceMaster(master)
                        } else {
                          setDisableITInsta(false)
                        }
                      }}
                    />
                    <div className='fs-6 ms-4 mt-1 w-100'>
                      <h6 className='mb-0'>Flexible</h6>{' '}
                      <label>
                        By selecting this option, you open up your campaign to all influencer types
                      </label>
                    </div>
                    {formik.touched.flexible && formik.errors.flexible && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span className='text-danger' role='alert'>
                            {formik.errors.flexible}
                          </span>
                        </div>
                      </div>
                    )}
                  </label>
                </div>
              </label>
            </div>
            <div className='m32'>
              <p className='fs-6 required mb-0 fw-bold'>Primary Gender</p>
              <div className='flex form-check form-check-custom form-check-solid '>
                <label className='w-100 flex me-10 justify-content-start'>
                  <input
                    {...formik.getFieldProps('primaryGender')}
                    className={clsx(
                      'form-check-input w-20px h-20px',
                      {
                        'is-invalid': formik.touched.primaryGender && formik.errors.primaryGender,
                      },
                      {
                        'is-valid': formik.touched.primaryGender && !formik.errors.primaryGender,
                      }
                    )}
                    type='radio'
                    value='Male'
                    checked={formik.values.primaryGender?.includes('Male')}
                    id='flexCheckDefault'
                  />
                  <label className='fs-6 ms-2 w-50 mb-0'>Male</label>

                  {formik.touched.primaryGender && formik.errors.primaryGender && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span className='text-danger' role='alert'>
                          {formik.errors.primaryGender}
                        </span>
                      </div>
                    </div>
                  )}
                </label>
                <label className='w-100 flex me-10 justify-content-start'>
                  <input
                    {...formik.getFieldProps('primaryGender')}
                    className={clsx(
                      'form-check-input w-20px h-20px',
                      {
                        'is-invalid': formik.touched.primaryGender && formik.errors.primaryGender,
                      },
                      {
                        'is-valid': formik.touched.primaryGender && !formik.errors.primaryGender,
                      }
                    )}
                    type='radio'
                    value='Female'
                    checked={formik.values.primaryGender?.includes('Female')}
                    id='flexCheckDefault'
                  />
                  <label className='fs-6 ms-2 w-50 mb-0'>Female</label>

                  {formik.touched.primaryGender && formik.errors.primaryGender && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span className='text-danger' role='alert'>
                          {formik.errors.primaryGender}
                        </span>
                      </div>
                    </div>
                  )}
                </label>
                <label className='w-100 flex me-10 justify-content-start'>
                  <input
                    {...formik.getFieldProps('primaryGender')}
                    className={clsx(
                      'form-check-input w-20px h-20px',
                      {
                        'is-invalid': formik.touched.primaryGender && formik.errors.primaryGender,
                      },
                      {
                        'is-valid': formik.touched.primaryGender && !formik.errors.primaryGender,
                      }
                    )}
                    type='radio'
                    value='Other'
                    checked={formik.values.primaryGender?.includes('Other')}
                    id='flexCheckDefault'
                  />
                  <label className='fs-6 ms-2 w-50 mb-0'>Other</label>

                  {formik.touched.primaryGender && formik.errors.primaryGender && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span className='text-danger' role='alert'>
                          {formik.errors.primaryGender}
                        </span>
                      </div>
                    </div>
                  )}
                </label>
                <label className='w-100 flex me-10 justify-content-start'>
                  <input
                    {...formik.getFieldProps('primaryGender')}
                    className={clsx(
                      'form-check-input w-20px h-20px',
                      {
                        'is-invalid': formik.touched.primaryGender && formik.errors.primaryGender,
                      },
                      {
                        'is-valid': formik.touched.primaryGender && !formik.errors.primaryGender,
                      }
                    )}
                    type='radio'
                    value='All'
                    checked={formik.values.primaryGender?.includes('All')}
                    id='flexCheckDefault'
                  />
                  <label className='fs-6 ms-2 w-50 mb-0'>All</label>

                  {formik.touched.primaryGender && formik.errors.primaryGender && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span className='text-danger' role='alert'>
                          {formik.errors.primaryGender}
                        </span>
                      </div>
                    </div>
                  )}
                </label>
              </div>
            </div>
            <div className='m32'>
              <p className='fs-6 required mb-0 fw-bold'>Select Age Group</p>
              <div className='flex form-check form-check-custom form-check-solid '>
                {/* <label className='w-100 flex me-10 justify-content-start'>
                  <input
                    {...formik.getFieldProps('ageGroup')}
                    className={clsx(
                      'form-check-input w-20px h-20px',
                      {
                        'is-invalid': formik.touched.ageGroup && formik.errors.ageGroup,
                      },
                      {
                        'is-valid': formik.touched.ageGroup && !formik.errors.ageGroup,
                      }
                    )}
                    type='checkbox'
                    value='13-17'
                    checked={formik.values.ageGroup?.includes('13-17')}
                    id='flexCheckDefault'
                  />
                  <label className='fs-6 ms-2 w-50 mb-0'>13-17</label>

                  {formik.touched.ageGroup && formik.errors.ageGroup && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span className='text-danger' role='alert'>
                          {formik.errors.ageGroup}
                        </span>
                      </div>
                    </div>
                  )}
                </label> */}
                <label className='w-100 flex me-10 justify-content-start'>
                  <input
                    {...formik.getFieldProps('ageGroup')}
                    className={clsx(
                      'form-check-input w-20px h-20px',
                      {
                        'is-invalid': formik.touched.ageGroup && formik.errors.ageGroup,
                      },
                      {
                        'is-valid': formik.touched.ageGroup && !formik.errors.ageGroup,
                      }
                    )}
                    type='checkbox'
                    value='18-24'
                    checked={formik.values.ageGroup?.includes('18-24')}
                    id='flexCheckDefault'
                  />
                  <label className='fs-6 ms-2 w-50 mb-0'>18-24</label>

                  {formik.touched.ageGroup && formik.errors.ageGroup && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span className='text-danger' role='alert'>
                          {formik.errors.ageGroup}
                        </span>
                      </div>
                    </div>
                  )}
                </label>
                <label className='w-100 flex me-10 justify-content-start'>
                  <input
                    {...formik.getFieldProps('ageGroup')}
                    className={clsx(
                      'form-check-input w-20px h-20px',
                      {
                        'is-invalid': formik.touched.ageGroup && formik.errors.ageGroup,
                      },
                      {
                        'is-valid': formik.touched.ageGroup && !formik.errors.ageGroup,
                      }
                    )}
                    type='checkbox'
                    value='25-34'
                    checked={formik.values.ageGroup?.includes('25-34')}
                    id='flexCheckDefault'
                  />
                  <label className='fs-6 ms-2 w-50 mb-0'>25-34</label>

                  {formik.touched.ageGroup && formik.errors.ageGroup && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span className='text-danger' role='alert'>
                          {formik.errors.ageGroup}
                        </span>
                      </div>
                    </div>
                  )}
                </label>
                <label className='w-100 flex me-10 justify-content-start'>
                  <input
                    {...formik.getFieldProps('ageGroup')}
                    className={clsx(
                      'form-check-input w-20px h-20px',
                      {
                        'is-invalid': formik.touched.ageGroup && formik.errors.ageGroup,
                      },
                      {
                        'is-valid': formik.touched.ageGroup && !formik.errors.ageGroup,
                      }
                    )}
                    type='checkbox'
                    value='35-44'
                    checked={formik.values.ageGroup?.includes('35-44')}
                    id='flexCheckDefault'
                  />
                  <label className='fs-6 ms-2 w-50 mb-0'>35-44</label>

                  {formik.touched.ageGroup && formik.errors.ageGroup && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span className='text-danger' role='alert'>
                          {formik.errors.ageGroup}
                        </span>
                      </div>
                    </div>
                  )}
                </label>
                <label className='w-100 flex me-10 justify-content-start'>
                  <input
                    {...formik.getFieldProps('ageGroup')}
                    className={clsx(
                      'form-check-input w-20px h-20px',
                      {
                        'is-invalid': formik.touched.ageGroup && formik.errors.ageGroup,
                      },
                      {
                        'is-valid': formik.touched.ageGroup && !formik.errors.ageGroup,
                      }
                    )}
                    type='checkbox'
                    value='45-65'
                    checked={formik.values.ageGroup?.includes('45-65')}
                    id='flexCheckDefault'
                  />
                  <label className='fs-6 ms-2 w-50 mb-0'>45-65</label>

                  {formik.touched.ageGroup && formik.errors.ageGroup && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span className='text-danger' role='alert'>
                          {formik.errors.ageGroup}
                        </span>
                      </div>
                    </div>
                  )}
                </label>
                <label className='w-100 flex me-10 justify-content-start'>
                  <input
                    {...formik.getFieldProps('ageGroup')}
                    className={clsx(
                      'form-check-input w-20px h-20px',
                      {
                        'is-invalid': formik.touched.ageGroup && formik.errors.ageGroup,
                      },
                      {
                        'is-valid': formik.touched.ageGroup && !formik.errors.ageGroup,
                      }
                    )}
                    type='checkbox'
                    value='65+'
                    checked={formik.values.ageGroup?.includes('65+')}
                    id='flexCheckDefault'
                  />
                  <label className='fs-6 ms-2 w-50 mb-0'>65+</label>

                  {formik.touched.ageGroup && formik.errors.ageGroup && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span className='text-danger' role='alert'>
                          {formik.errors.ageGroup}
                        </span>
                      </div>
                    </div>
                  )}
                </label>
              </div>
            </div>
            <div className='col m24'>
              <h6 className='fw-bold required'>Location</h6>
              {/* <input
                type='text'
                placeholder='Location'
                {...formik.getFieldProps('location')}
                className={clsx(
                  'form-control form-control-solid bg-gray-100',
                  {
                    'is-invalid': formik.touched.location && formik.errors.location,
                  },
                  {
                    'is-valid': formik.touched.location && !formik.errors.location,
                  }
                )}
              /> */}
              {/* <CustomSelect
                options={cities}
                value={{value: formik.values.location, label: formik.values.location}}
                onChange={(e: any) => {
                  formik.setFieldValue('location', e.label)
                }}
                styles={{
                  menuList: (provided, state) => ({
                    ...provided,
                    height: '150px',
                  }),
                }}
                className={clsx(
                  'form-control form-control-solid bg-gray-100 p-0 border-0 categorySelect',
                  {
                    'is-invalid': formik.touched.location && formik.errors.location,
                  },
                  {
                    'is-valid': formik.touched.location && !formik.errors.location,
                  }
                )}
                placeholder='Example: Mumbai, New Delhi, Pune, Kolkata'
              /> */}
              <CustomSelect
                options={cities}
                isMulti={true}
                className={clsx(
                  'form-control form-control-solid bg-gray-100 p-0 border-0 categorySelect',
                  {
                    'is-invalid': formik.touched.location && formik.errors.location,
                  },
                  {
                    'is-valid': formik.touched.location && !formik.errors.location,
                  }
                )}
                value={location}
                onChange={(e: any) => {
                  setLocation(e)
                }}
                styles={{
                  menuList: (provided, state) => ({
                    ...provided,
                    height: '200px',
                  }),
                }}
                isOptionDisabled={(option) => {
                  if (location.filter(value => value.label === 'PAN India').length > 0) {
                    return true
                  } else return false
                }}
                placeholder='Example: Mumbai, New Delhi, Pune, Kolkata'
              />


              {formik.touched.location && formik.errors.location && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='text-danger' role='alert'>
                      {formik.errors.location}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        <div className='d-flex m32 pe-5'>
          {/* <Link
            to={{
              pathname: '/campaign/details',
              state: history.location.state,
            }}
          > */}
          <button onClick={back} className='btn fw-bolder btn-gray-200 CompanyDetailButton'>
            Go Back {formik.isValid}
          </button>
          {/* </Link> */}
          <button
            type='submit'
            id='kt_sign_up_submit'
            className='btn create-btn CompanyDetailButton ms-5'
            disabled={isFormDisabled()}
          // onClick={() => setShow(true)}
          >


            {!loading && <span className='indicator-label'>Save & Continue</span>}
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}
