import {useEffect, useState} from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import {Link, useHistory} from 'react-router-dom'
import Select from 'react-select'
import '../../../../app/Dashboard/Dashboard.scss'
import { IUserModel } from '../../../../app/modules/auth/models/UserModel'
import { RootState } from '../../../../setup'

export function MenuInner() {
  const history: any = useHistory()
  const user: IUserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as IUserModel
  // const intl = useIntl()
  // const adminOptions = [{ value: "Influencer Onboarding", key: "Influencer Onboarding" }]
  const customControlStyles = {
    backgroundColor: '#39296A', // Change this to the desired background color
    color: '#FFFFFF', // Change this to the desired text color
  };
  const [adminOptions, setAdminOptions] = useState<any>([
    {value: 'Campaign Overview', label: 'Campaign Overview'},
    {value: 'Influencer Onboarding', label: 'Influencer Onboarding'},
    {value: 'Content Submission', label: 'Content Submission'}
  ])
  const pageType = history.location.state?.type
  const breadcrums = history.location.state?.breadcrums

  useEffect(function(){
    if (history?.location?.state?.campaignDetails?.status === 'completed') {
      adminOptions.push({value: 'Campaign Performance', label: 'Campaign Performance'})
      setAdminOptions(adminOptions);
    }
  },[])

  return (
    <>
      <div className='d-flex flex-column justify-content-between'>
        <div className=' mb-0 mt-3 d-flex'>
          {pageType === 'influencerMasterForm' ? (
            <h3 className=' mb-0'> Influencer Master </h3>
          ) : pageType === 'documentList' ? (
            <h3 className=' mb-0'>Document Master</h3>
          ) : pageType === 'Notifications' ? (
            <div style={{height: '54px'}} className='flex'>
              <h3>Notifications</h3>
            </div>
          ) : pageType === 'performanceCampaign' ? (
            <h3 className=' mb-0'>Campaigns</h3>
          ) : pageType === 'performanceInfluencer' ? (
            <h3 className=' mb-0'>Campaigns</h3>
          ) : pageType === 'AgencyView' ? (
            <h3 className=' mb-0'>Masters</h3>
          ) : pageType === 'BrandView' ? (
            <h3 className=' mb-0'>Masters</h3>
          ) : pageType === 'categoryMaster' ? (
            <h3 className=' mb-0'>Masters</h3>
          ) : pageType === 'brandMaster' ? (
            <h3 className=' mb-0'>Masters</h3>
          ) : pageType === 'socialMaster' ? (
            <h3 className=' mb-0'>Masters</h3>
          ) : pageType === 'priceMetrixMaster' ? (
            <h3 className=' mb-0'>Masters</h3>
          ) : pageType === 'profile' ? (
            <h3 className=' mb-0'>Profile Manager</h3>
          ) : pageType === 'campaignDetails' ? (
            <h3 className=' mb-0'>Campaign Master</h3>
          ) : pageType === 'campaign/dashboard' ? (
            <h3 className=' mb-0'>Campaigns</h3>
          ) : pageType === 'influencer' ? (
            <h3 className=' mb-0'>Profiles</h3>
          ) : pageType === 'influencerAdd' ? (
            <h3 className=' mb-0'>Influencer Master</h3>
          ) : pageType === 'influencerCreate' ? (
            <h3 className=' mb-0'>Influencer Master</h3>
          ) : pageType === 'performanceRating' ? (
            <h3 className=' mb-0'>Campaigns</h3>
          ) : pageType === 'FAQ' ? (
            <h3 className=' mb-0'>FAQs</h3>
          ) : pageType === 'transactionDashboard' ? (
            <h3 className=' mb-0'>Transactions</h3>
          ) : pageType === 'transactionAdd' ? (
            <h3 className=' mb-0'>Transaction</h3>
          ) : pageType === 'ActiveTicket' ? (
            <h3 className=' mb-0'>Support</h3>
          ) : pageType === 'influencerOnboard' && breadcrums ? (
            <>
              <h3 className=' mb-0'>{history.location.state.campaignDetails?.name}</h3>
            </>
          ) : pageType === 'schedular/calendar/today' ? (
            <h3 className=' mb-0'>Scheduler</h3>
          ) : pageType === 'schedular/calendar/week' ? (
            <h3 className=' mb-0'>Scheduler</h3>
          ) : pageType === 'schedular/calendar/month' ? (
            <h3 className=' mb-0'>Scheduler</h3>
          ) : pageType === 'contentSubmission' ? (
            <h3 className='mb-0'>Campaign Master</h3>
          ) : pageType === 'influencerProfile' ? (
            <h3 className='mb-0'>Profiles</h3>
          ) : pageType === 'Dashboard/general' && user.permissions.admin ? (
            <div className='Dash-Navbar' style={{height: 'fit-content'}}>
              <button
                onClick={() =>
                  history.push({pathname: '/dashboard/general', state: {type: 'Dashboard/general'}})
                }
                className='active'
              >
                Overview 👁️
              </button>
              <button
                onClick={() =>
                  history.push({pathname: '/dashboard/brand', state: {type: 'Dashboard/brands'}})
                }
              >
                Brand 😍
              </button>
              <button
                onClick={() =>
                  history.push({pathname: '/dashboard/agency', state: {type: 'Dashboard/agency'}})
                }
              >
                Agency 🤝
              </button>

              <button
                onClick={() =>
                  history.push({
                    pathname: '/dashboard/influencer',
                    state: {type: 'Dashboard/influencer'},
                  })
                }
              >
                Influencer 👌
              </button>
            </div>
          ) : pageType === 'Dashboard/agency' && user.permissions.admin ? (
            <div className='Dash-Navbar' style={{height: 'fit-content'}}>
              <button
                onClick={() =>
                  history.push({pathname: '/dashboard/general', state: {type: 'Dashboard/general'}})
                }
              >
                Overview 👁️
              </button>
              <button
                onClick={() =>
                  history.push({pathname: '/dashboard/brand', state: {type: 'Dashboard/brands'}})
                }
              >
                Brand 😍
              </button>
              <button
                onClick={() =>
                  history.push({pathname: '/dashboard/agency', state: {type: 'Dashboard/agency'}})
                }
                className='active'
              >
                Agency 🤝
              </button>

              <button
                onClick={() =>
                  history.push({
                    pathname: '/dashboard/influencer',
                    state: {type: 'Dashboard/influencer'},
                  })
                }
              >
                Influencer 👌
              </button>
            </div>
          ) : pageType === 'Dashboard/brands' && user.permissions.admin ? (
            <div className='Dash-Navbar' style={{height: 'fit-content'}}>
              <button
                onClick={() =>
                  history.push({pathname: '/dashboard/general', state: {type: 'Dashboard/general'}})
                }
              >
                Overview 👁️
              </button>
              <button
                onClick={() =>
                  history.push({pathname: '/dashboard/brand', state: {type: 'Dashboard/brands'}})
                }
                className='active'
              >
                Brand 😍
              </button>
              <button
                onClick={() =>
                  history.push({pathname: '/dashboard/agency', state: {type: 'Dashboard/agency'}})
                }
              >
                Agency 🤝
              </button>

              <button
                onClick={() =>
                  history.push({
                    pathname: '/dashboard/influencer',
                    state: {type: 'Dashboard/influencer'},
                  })
                }
              >
                Influencer 👌
              </button>
            </div>
          ) : pageType === 'Dashboard/influencer' && user.permissions.admin ? (
            <div className='Dash-Navbar' style={{height: 'fit-content'}}>
              <button
                onClick={() =>
                  history.push({pathname: '/dashboard/general', state: {type: 'Dashboard/general'}})
                }
              >
                Overview 👁️
              </button>
              <button
                onClick={() =>
                  history.push({pathname: '/dashboard/brand', state: {type: 'Dashboard/brands'}})
                }
              >
                Brand 😍
              </button>
              <button
                onClick={() =>
                  history.push({pathname: '/dashboard/agency', state: {type: 'Dashboard/agency'}})
                }
              >
                Agency 🤝
              </button>

              <button
                onClick={() =>
                  history.push({
                    pathname: '/dashboard/influencer',
                    state: {type: 'Dashboard/influencer'},
                  })
                }
                className='active'
              >
                Influencer 👌
              </button>
            </div>
          ) :  user.permissions.admin ? (
            <h3 className=' mb-0'>{pageType}</h3>
          ) : <></>}
        </div>
        <div className='mt-0'>
          {pageType === 'categoryMaster' && (
            <p>
              <Link to={{pathname: '/master/category', state: {type: 'categoryMaster'}}}>
                {' '}
                Category Master{' '}
              </Link>
            </p>
          )}

          {pageType === 'Brand' && (
            <p>
              <Link
                to={{
                  pathname: '/master/brands',
                  state: {type: 'Brand'},
                }}
              >
                {pageType} Master
              </Link>{' '}
              {'>'} {history.location.state?._id ? 'Edit' : 'Create'} {pageType}
            </p>
          )}
          {pageType === 'BrandView' && (
            <p>
              <Link
                to={{
                  pathname: '/master/brands',
                  state: {type: 'Brand'},
                }}
              >
                Master
              </Link>{' '}
              {'>'} View Brand {'>'} {history.location.state?.name}
            </p>
          )}
          {pageType === 'Agency' && (
            <p>
              {' '}
              <Link
                to={{
                  pathname: '/master/agencies',
                  state: {type: 'Agency'},
                }}
              >
                {pageType} Master
              </Link>{' '}
              {'>'} {history.location.state?._id ? 'Edit' : 'Create'} {pageType}{' '}
            </p>
          )}
          {pageType === 'AgencyView' && (
            <p>
              {' '}
              <Link
                to={{
                  pathname: '/master/agencies',
                  state: {type: 'Agency'},
                }}
              >
                Agency Master
              </Link>{' '}
              {'>'} View Agency {'>'} {history.location.state?.name}
            </p>
          )}
          {pageType === 'brandMaster' && <p> Brand Master (for app) </p>}
          {pageType === 'socialMaster' && <p> Social Media Master </p>}
          {pageType === 'influencerMasterForm' && <p> Influencer Master {'>'} Add Influencer </p>}
          {pageType === 'influencer' && (
            <p>
              <Link
                to={{pathname: '/master/influencer', state: {type: 'influencer', reloaded: false}}}
              >
                {' '}
                Influencer Master
              </Link>{' '}
            </p>
          )}
          {pageType === 'influencerAdd' && (
            <p>
              <Link
                to={{pathname: '/master/influencer', state: {type: 'influencer', reloaded: false}}}
              >
                Influencer Masters{' '}
              </Link>{' '}
              {'>'} Add Influencer{' '}
            </p>
          )}
          {pageType === 'influencerCreate' && (
            <p>
              <Link
                to={{pathname: '/master/influencer', state: {type: 'influencer', reloaded: false}}}
              >
                Influencer Masters{' '}
              </Link>{' '}
              {'>'} Edit Influencer{' '}
            </p>
          )}
          {pageType === 'priceMetrixMaster' && <p> Price Matrix Master </p>}
          {pageType === 'documentList' && <p> Document List </p>}
          {pageType === 'profile' && <p> Admin Settings {'>'} Profile Manager </p>}
          {pageType === 'performanceRating' && (
            <p>
              <Link
                to={{
                  pathname: '/campaign/dashboard',
                  state: {type: 'campaign/dashboard', status: '', reloaded: false},
                }}
              >
                {' '}
                Campaigns{' '}
              </Link>
              {'>'} Ratings{' '}
            </p>
          )}
          {pageType === 'performanceCampaign' && <p> Campaigns {'>'} Performance</p>}
          {pageType === 'performanceInfluencer' && <p> Influencers {'>'} Performance</p>}
          {pageType === 'FAQ' && <p> Admin Settings {'>'} FAQs </p>}
          {pageType === 'transactionDashboard' && <p>All Transactions </p>}
          {pageType === 'transactionAdd' && (
            <p>
              <Link
                to={{pathname: '/transaction/dashboard', state: {type: 'transactionDashboard'}}}
              >
                {' '}
                Transactions{' '}
              </Link>
              {'>'} Add Transaction{' '}
            </p>
          )}
          {pageType === 'ActiveTicket' && <p> Support {'>'} Active Tickets </p>}
          {pageType === 'campaignDetails' && (
            <p>
              <Link
                to={{
                  pathname: '/campaign/dashboard',
                  state: {type: 'campaign/dashboard', status: '', reloaded: false},
                }}
              >
                {' '}
                Campaign Master{' '}
              </Link>{' '}
              {'>'} Create Campaign{' '}
            </p>
          )}
          {pageType === 'campaign/dashboard' && <p className='text-gray-400'> Campaigns List </p>}
          {pageType === 'contentSubmission' && (
            <p>
              {' '}
              <Link
                to={{
                  pathname: '/campaign/dashboard',
                  state: {type: 'campaign/dashboard', status: '', reloaded: false},
                }}
              >
                Campaigns{' '}
              </Link>
              {'>'} Content {'>'} Submission{' '}
            </p>
          )}
          {pageType === 'schedular/calendar/today' && <p> Campaigns {'>'} Calendar </p>}
          {pageType === 'schedular/calendar/week' && <p> Campaigns {'>'} Calendar </p>}
          {pageType === 'schedular/calendar/month' && <p> Campaigns {'>'} Calendar </p>}
          {pageType === 'influencerProfile' && (
            <p>
              <Link
                to={{pathname: '/master/influencer', state: {type: 'influencer', reloaded: false}}}
              >
                {' '}
                Masters{' '}
              </Link>{' '}
              {'>'} Influencer Master {'>'} Profiles {'>'}{' '}
              {history.location.state.influencerBasicInfo.name}
            </p>
          )}
          {breadcrums && (
            <p>
              {' '}
              {breadcrums.map((x, index) => (
                <>
                  {x === 'Campaigns' ? (
                    <Link
                      to={{
                        pathname: '/campaign/dashboard',
                        state: {type: 'campaign/dashboard', status: '', reloaded: false},
                      }}
                    >
                      {x}
                    </Link>
                  ) : (
                    x
                  )}
                  {breadcrums.length - 1 !== index && ' > '}
                </>
              ))}
            </p>
          )}
        </div>
      </div>
      {breadcrums &&
        [
          'Approved',
          'Shortlist',
          'Payment',
          'Communicate',
          'Replace',
          'Contract',
          'Shipping',
        ].includes(history?.location.state?.status) && (
          <Select
            options={adminOptions}
            isMulti={false}
            placeholder={' Select Option'}
            value={adminOptions.find((x) => x.label === breadcrums[1])}
            className={'form-select p-0 categorySelect ms-5 w-300px header-select-box'}
            onChange={(e: any) => {
              if (e.value === 'Influencer Onboarding') {
                history.push({
                  pathname: '/campaign/influencerOnborading/' + history?.location.state._id,
                  state: {
                    ...history.location.state,
                    ...{
                      status: history?.location.state.status,
                      breadcrums: ['Campaigns', 'Influencer Onboarding'],
                    },
                  },
                })
              } else if (e.value === 'Content Submission') {
                history.push({
                  pathname: '/campaign/contract',
                  state: {
                    ...history.location.state,
                    ...{breadcrums: ['Campaigns', 'Content Submission']},
                  },
                })
                // history.push({
                //   pathname: '/campaign/contentSubmission',
                //   state: {
                //     ...history.location.state,
                //     ...{ breadcrums: ["Campaigns", "Content", "Submissions"] }
                //   }
                // })
              } else if (e.value === 'Campaign Overview') {
                history.push({
                  pathname: '/campaign/campaignDetailById',
                  state: {
                    ...history.location.state,
                    ...{breadcrums: ['Campaigns', 'Campaign Overview']},
                  },
                })
              } else if (e.value === 'Campaign Performance') {
                history.push({
                  pathname: '/performance/campaign-wise',
                  state: {
                    ...history.location.state,
                    ...{breadcrums: ['Campaign Name', 'Performance']},
                    from:"ongoing"
                  },
                })
              }
            }}
            styles={{
              control: (provided) => ({
                ...provided,
                backgroundColor: customControlStyles.backgroundColor,
              }),
              singleValue: (provided) => ({
                ...provided,
                color: customControlStyles.color,
              }),
            }}
          />
        )}
    </>
  )
}
