import axios from 'axios'
import clsx from 'clsx'
import {useFormik} from 'formik'
import React, {useEffect, useState} from 'react'
import {Link, useHistory} from 'react-router-dom'
import Select from 'react-select'
import * as Yup from 'yup'
import {toAbsoluteUrl} from '../../_metronic/helpers'
import loc from '../data/Location.json'
import './basic.css'
import {ImageCropper} from './ImageCropper'
import {InfluencerMatrixLeftBreadcrum} from './InfluencerMatrixLeftBreadcrum'

var initialValues = {
  name: '',
  description: '',
  email: '',
  phoneNumber: '',
  address: '',
  city: '',
  state: '',
  pinCode: '',
  profilePicture: {},
}

const registrationSchema = Yup.object().shape({
  name: Yup.string()
    // .min(3, 'Minimum 3 symbols')
    // .max(50, 'Maximum 50 symbols')
    // .required('Name is required'),
    .required('Name is required')
    .matches(/^(?=.*[A-Za-z]).{3,50}$/, 'Wrong format'),
  description: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(500, 'Maximum 500 characters')
    .required('Description is required'),
  email: Yup.string()
    .email('Wrong format of Email')
    .matches(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      'Wrong format of Email'
    )
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  phoneNumber: Yup.string()
    .min(10, 'Minimum 10 digits')
    .max(12, 'Maximum 13 digits with country code')
    .required('Phone Number is required'),
  // address: Yup.string()
  //   .min(3, 'Minimum 3 characters')
  //   .max(500, 'Maximum 500 characters')
  //   .required('Address is required'),
  // city: Yup.string().required('City is required'),
  // state: Yup.string().required('State is required'),
  // pinCode: Yup.string()
  //   .min(6, 'Minimum 6 digit')
  //   .max(6, 'Maximum 6 digit')
  //   .required('Pincode is required'),
})

export function InfluencerMasterAdd() {
  const history: any = useHistory()
  const [loading, setLoading] = useState(false)
  const [influencerBasicInfo, setInfluencerBasicInfo] = useState(initialValues)
  const [s, setS] = useState('Andaman and Nicobar Islands')
  const [st, setSt] = useState(['Rajasthan'])
  const [ct, setCt] = useState([])
  const [city, setCity] = useState({})
  const [state, setState] = useState({})

  const formik = useFormik({
    initialValues: influencerBasicInfo || initialValues,
    validationSchema: registrationSchema,
    enableReinitialize: true,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setTimeout(() => {
        try {
          setInfluencerBasicInfo(values)
          setLoading(false)
          setSubmitting(false)
          let data: any = values
          if (selectedFile.size > 0) data.profilePicture = selectedFile
          else delete data['profilePicture']
          history.push({
            pathname: '/master/influencerAdditionalInfo',
            state: {
              influencerBasicInfo: data,
              influencerAdditinalInfo: history.location.state?.influencerAdditinalInfo,
              influencerSocialDetails: history.location.state?.influencerSocialDetails,
              _id: history.location.state?._id,
              type: history.location.state?.type,
            },
          })
        } catch {
          setLoading(false)
          setSubmitting(false)
          setStatus('Registration process has broken')
          // Remove logs when not needed
        }
      }, 1000)
    },
  })

  useEffect(() => {
    let influencerBasicInfo = history.location.state?.influencerBasicInfo
    if (influencerBasicInfo?.address?.length > 0) {
      setState({
        value: influencerBasicInfo?.state,
        label: influencerBasicInfo?.state,
      })
      setCity({
        value: influencerBasicInfo?.city,
        label: influencerBasicInfo?.city,
      })
    }
    if (influencerBasicInfo?.profilePicture && influencerBasicInfo?.profilePicture.size > 0) {
      setSelectedFile(influencerBasicInfo.profilePicture)
      var reader = new FileReader()
      reader.readAsDataURL(influencerBasicInfo.profilePicture)
      reader.onload = function () {
        setBgImage('' + reader.result + '')
      }
    } else if (influencerBasicInfo?.avatar) {
      handleCrop('' + influencerBasicInfo?.avatar + '')
    }
    setInfluencerBasicInfo(influencerBasicInfo)
  }, [history.location.state?.influencerBasicInfo])

  const [bgImage, setBgImage] = useState(toAbsoluteUrl('/media/logos/demoImage.svg'))
  const [selectedFile, setSelectedFile] = useState(new File([], ''))
  const [toggleCrop, setToggleCrop] = useState(false)
  function onChange(event: any) {
    let file = event.target.files[0]
    setSelectedFile(file)
    var reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
      setBgImage('' + reader.result + '')
      setToggleCrop(true)
    }
  }
  function resetImage() {
    setBgImage(toAbsoluteUrl('/media/logos/demoImage.svg'))
    setSelectedFile(new File([], ''))
  }
  async function handleCrop(image: any) {
    setBgImage(image)
    const response = await fetch(image, {mode: 'no-cors'})
    const data = await response.blob()
    setSelectedFile(
      new File([data], selectedFile?.name, {
        type: selectedFile.type,
      })
    )
    formik.setErrors({})
    setToggleCrop(false)
  }
  const getSt = () => {
    var s: any = []
    loc.map((l) => {
      var p = {
        value: l.State,
        label: l.State,
      }
      s.push(p)
    })
    setSt(s)
    setS('Andaman and Nicobar Islands')
  }

  const getCt = () => {
    var c: any = []
    var ci: any = []
    loc.map((l) => {
      if (l.State == s) {
        c = l.Cities
        c.map((ct: any) => {
          var p = {
            value: ct,
            label: ct,
          }
          ci.push(p)
        })
      }
    })
    setCt(ci)
  }
  useEffect(() => {
    getSt()
    getCt()
  }, [])
  useEffect(() => {
    getCt()
  }, [s])

  const handleCancel = () => {
    setBgImage(toAbsoluteUrl('/media/logos/demoImage.svg'))
    setSelectedFile(new File([], ''))
    setToggleCrop(false)
  }
  return (
    <div className='CompanyDetailsOuter'>
      <InfluencerMatrixLeftBreadcrum currentStep='1' />
      <form
        className='CompanyDetails'
        noValidate
        id='kt_login_signup_form'
        onSubmit={formik.handleSubmit}
        style={{marginBottom: '100px'}}
      >
        <h2 className='m-0'>Basic Info</h2>
        <p className='text-gray-400 fs-7 m-0'>Add information related to new influencer. </p>
        <div className='row d-flex justify-content-start align-items-center mt-5'>
          <span
            style={{width: '120px', height: '120px'}}
            className='mt-5 col-fluid svg-icon svg-icon-2x flex svg-icon-dark bg-gray-100 h-120px w-120px rounded-circle'
          >
            <div
              className='image-input image-input-circle'
              data-kt-image-input='true'
              style={{backgroundImage: `url(${bgImage})`}}
            >
              <div
                className='image-input-wrapper w-125px h-125px'
                style={{backgroundImage: `url(${bgImage})`}}
              ></div>
              <label
                className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow'
                data-kt-image-input-action='change'
                data-bs-toggle='tooltip'
                data-bs-original-title='Change avatar'
              >
                <i className='bi bi-pencil-fill fs-7'></i>
                <input
                  type='file'
                  name='avatar'
                  accept='.png, .jpg, .jpeg'
                  onChange={(event) => onChange(event)}
                />
                <input type='hidden' name='avatar_remove' />
              </label>

              {selectedFile.size > 0 && (
                <span
                  className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow'
                  data-kt-image-input-action='cancel'
                  data-bs-toggle='tooltip'
                  data-bs-dismiss='click'
                  title='Cancel avatar'
                >
                  <i className='bi bi-x fs-2'></i>
                </span>
              )}
              {selectedFile.size > 0 && (
                <span
                  className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow'
                  data-kt-image-input-action='remove'
                  data-bs-toggle='tooltip'
                  data-bs-dismiss='click'
                  title='Remove avatar'
                  onClick={resetImage}
                >
                  <i className='bi bi-x fs-2'></i>
                </span>
              )}
            </div>
          </span>

          <div className='col mt-5 '>
            <div className='ms-5'>
              <h2 className=''>Profile Picture</h2>
            </div>
            <div className='ms-5 fs-5'>200 px x 200 px</div>
          </div>
        </div>

        <div className='row pt-4 pe-5 mt-5'>
          <div className='col '>
            <h6 className='fw-bold required'>Name</h6>
            <input
              type='text'
              placeholder='Name'
              {...formik.getFieldProps('name')}
              className={clsx(
                'form-control form-control-solid bg-gray-100',
                {
                  'is-invalid': formik.touched.name && formik.errors.name,
                },
                {
                  'is-valid': formik.touched.name && !formik.errors.name,
                }
              )}
            />
            {formik.touched.name && formik.errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='text-danger' role='alert'>
                    {formik.errors.name}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='pt-4 pe-5 mt-3'>
          <h6 className='fw-bold required '>Influencer Description</h6>
          <div className='col '>
            <textarea
              placeholder={'Influencer Description'}
              {...formik.getFieldProps('description')}
              className={clsx(
                'form-control form-control-solid bg-gray-100 h-70px',
                {
                  'is-invalid': formik.touched.description && formik.errors.description,
                },
                {
                  'is-valid': formik.touched.description && !formik.errors.description,
                }
              )}
            />
            {formik.touched.description && formik.errors.description && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='text-danger' role='alert'>
                    {formik.errors.description}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='row pt-4 pe-5 mt-3 '>
          <div className='col '>
            <h6 className='fw-bold required'>Email</h6>
            <input
              type='text'
              placeholder='Email'
              {...formik.getFieldProps('email')}
              className={clsx(
                'form-control form-control-solid bg-gray-100',
                {
                  'is-invalid': formik.touched.email && formik.errors.email,
                },
                {
                  'is-valid': formik.touched.email && !formik.errors.email,
                }
              )}
            />
            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='text-danger' role='alert'>
                    {formik.errors.email}
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className='col '>
            <h6 className='fw-bold required'>Phone Number</h6>
            <input
              type='number'
              placeholder='Phone Number'
              {...formik.getFieldProps('phoneNumber')}
              className={clsx(
                'form-control form-control-solid bg-gray-100',
                {
                  'is-invalid': formik.touched.phoneNumber && formik.errors.phoneNumber,
                },
                {
                  'is-valid': formik.touched.phoneNumber && !formik.errors.phoneNumber,
                }
              )}
            />
            {formik.touched.phoneNumber && formik.errors.phoneNumber && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='text-danger' role='alert'>
                    {formik.errors.phoneNumber}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='pt-4 pe-5 mt-3'>
          <div className='col '>
            <h6 className='fw-bold '>Address</h6>
            <textarea
              placeholder='Address'
              {...formik.getFieldProps('address')}
              className={clsx(
                'form-control form-control-solid bg-gray-100 h-70px',
                {
                  'is-invalid': formik.touched.address && formik.errors.address,
                },
                {
                  'is-valid': formik.touched.address && !formik.errors.address,
                }
              )}
            />
            {formik.touched.address && formik.errors.address && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='text-danger' role='alert'>
                    {formik.errors.address}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='row pt-4 pe-5 mt-3 pb-5'>
          <div className='col '>
            <h6 className='fw-bold'>State</h6>
            <Select
              options={st}
              value={state}
              isMulti={false}
              name='companyState'
              placeholder='Andaman and Nicobar Islands'
              styles={{
                menuList: (provided, state) => ({
                  ...provided,
                  height: 'auto',
                  maxHeight:'200px'
                }),
              }}
              className={clsx(
                'text-gray-500 form-control form-control-solid bg-gray-100 p-0 border-0 categorySelect',
                {
                  'is-invalid': formik.touched.state && formik.errors.state,
                },
                {
                  'is-valid': formik.touched.state && !formik.errors.state,
                }
              )}
              onChange={(e: any) => {
                formik.setFieldValue('state', e.value)
                setS(e.value)
                setState(e)
              }}
            />

            {formik.touched.state && formik.errors.state && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='text-danger' role='alert'>
                    {formik.errors.state}
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className='col '>
            <h6 className='fw-bold'>City</h6>
            <Select
              options={ct}
              value={city}
              isMulti={false}
              name='companyCity'
              placeholder='City'
              styles={{
                menuList: (provided, state) => ({
                  ...provided,
                  height: 'auto',
                  maxHeight:'200px'
                }),
              }}
              className={clsx(
                'text-gray-500 form-control form-control-solid bg-gray-100 p-0 border-0 categorySelect',
                {
                  'is-invalid': formik.touched.city && formik.errors.city,
                },
                {
                  'is-valid': formik.touched.city && !formik.errors.city,
                }
              )}
              onChange={(e: any) => {
                formik.setFieldValue('city', e.value)
                setCity(e)
              }}
            />
            {formik.touched.city && formik.errors.city && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='text-danger' role='alert'>
                    {formik.errors.city}
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className='col '>
            <h6 className='fw-bold'>Pincode</h6>
            <input
              type='text'
              placeholder='Pincode'
              {...formik.getFieldProps('pinCode')}
              className={clsx(
                'form-control form-control-solid bg-gray-100',
                {
                  'is-invalid': formik.touched.pinCode && formik.errors.pinCode,
                },
                {
                  'is-valid': formik.touched.pinCode && !formik.errors.pinCode,
                }
              )}
              style={{height: '38px'}}
            />
            {formik.touched.pinCode && formik.errors.pinCode && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='text-danger' role='alert'>
                    {formik.errors.pinCode}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='d-flex pt-4 pe-5 mt-3'>
          <Link
            to={{
              pathname: '/master/influencer',
              state: {type: history.location.state.type, reloaded: false},
            }}
          >
            <button type='button' className='btn fw-bolder btn-gray-200 CompanyDetailButton'>
              Cancel
            </button>
          </Link>
          <button
            type='submit'
            id='kt_sign_up_submit'
            className='btn create-btn CompanyDetailButton ms-5'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && <span className='indicator-label'>Next</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
      {toggleCrop && <ImageCropper cancel={handleCancel} editImage={handleCrop} image={bgImage} />}
    </div>
  )
}
