import { useEffect, useState } from 'react'
import httpService from '../../../../setup/http/httpServices'
import '../../Dashboard.scss'
import TopCampaigns from './TopCampaigns'
import TopInfluencers from './TopInfluencers'
import TopInstagram from './TopInstagram'
import TopYoutube from './TopYoutube'
//  Icons
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../../../setup'
import { IUserModel } from '../../../modules/auth/models/UserModel'
import campaign1 from "./images/svg/campaignIcon1.svg"
import campaign2 from "./images/svg/campaignIcon2.svg"
import graph from "./images/svg/graphIcon.svg"
import influencerIcon from "./images/svg/influencerIcon.svg"
import instra from "./images/svg/Instagram.svg"
import youtube from "./images/svg/youtube.svg"

const AgencyDash = () => {
  const data = true
  const user: IUserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as IUserModel
  const [dashboardData, setDashboardData] = useState({} as any)


  const getAgencyDetails = () => {
    httpService.getData(
      user.userType === 'brand' ? '/dashboard/brand' : '/dashboard/agency',
     (res: any)=> {
      setDashboardData(res.data.data)
     },
     (err: any) => {
      console.log(err?.message)
     }
    )
  }
  
  useEffect(() => {
    getAgencyDetails()
  }, [])


  //  Cart Data 


  const dataBrand = [
    {
      id: 1,
      value: dashboardData?.totalCampaigns,
      title: "Total Campaigns",
      subTitle:"Total campaigns created this month.",
      info: "24% more vs last month",
      icon: campaign1,
      graph: graph,
    },
    {
      id: 2,
      value: dashboardData?.liveCampaigns,
      title: "Live Campaigns",
      subTitle:"Campaigns that are live.",
      info: "16% more vs last month",
      icon: campaign2,
      graph: graph,
    },
    {
      id: 3,
      value: dashboardData?.totalInfluencerCount,
      title: "Influencers",
      subTitle:"Worked with",
      info: "20% more vs last month",
      icon: influencerIcon,
      graph: graph,
    },
    {
      id: 4,
      value: dashboardData?.totalInstaInfluencerCount,
      title: "Instagram",
      subTitle:"Total Instagram Influencers hired",
      info: "24% more vs last month",
      icon: instra,
      graph: graph,
    },
    {
      id: 5,
      value: dashboardData?.totalYoutubeInfluencerCount,
      title: "YouTube",
      subTitle:"Total YouTube Influencers hired",
      info: "12% more vs last month",
      icon: youtube,
      graph: graph,
    },
  ]

  return (
    <div  className='Outer d-flex flex-column'>
       <div className='AgcyContainer'>
        <div className='flex Agcy-top justify-content-between'>
         
           {
            dataBrand.map((item) => (
              <div key={item.id} className='AgcyCard'>
              <div className="content-box">
              <div className='Dash-left-image svg-icon svg-icon-secondary bg-secondary bg-opacity-5' style={{background:"yellow"}}>
                  <img src={item.icon} alt="" className='svg-icon-3'/>
                </div>
                <div className="text-content">
                <h2>{item.title}</h2>
                  <p>{item.subTitle}</p>
                </div>
              </div>
                 <div className="info-value" >
                 <h2>{item.value}</h2>
                  <div style={{display:"flex",width:"100%"}}>
                    <img src={item.graph} alt="" />
                    {/* <span style={{marginLeft:"10px"}}> {item.info}</span> */}
                   </div>
                 </div>
                </div>
            ))
           }

          
        </div>

        <div className="Campaign_box">
         <TopCampaigns data = {dashboardData?.topCamapaigns}/>
        </div>
        
        <div className= "Top-Card-Container">
          <TopInfluencers data = {dashboardData?.topInstagramInfluencers} />
        <TopInstagram data = {dashboardData?.topInstagramInfluencers}/>
        <TopYoutube data = {dashboardData?.topYoutubeInfluencers}/>
        </div>
       
      </div>
    </div>
  )
}

export default AgencyDash