import moment from 'moment';
import React from 'react'
import { toAbsoluteUrl } from '../../../_metronic/helpers';
import '../Dashboard.scss'
import { NoData } from './NoData';



export function ViewApp(props: any) {
  // const rows :any[] = [0,1,2,3,4];


  return (
    <div className='Dcard DviewApp'>
      <h2 className='Dheading'>Recent Joined via App</h2>
      {props.data?.length===0 ? (
        <NoData />
      ) : (
        <div className='table-responsive'>
          <table className="table table-row-dashed table-row align-middle gs-0 gy-4 m-0">
            <thead>
              <tr className='fw-bolder text-muted'>
                <th style={{width: "350px"}}>
                  <span className='d-flex'>Influencers</span>
                </th>
                <th>
                  <span className='d-flex'>Platform</span>
                </th>
                <th style={{width: "120px"}}>
                  <span className='d-flex'>Date</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {props.data?.map((item,index) => (
                <tr key={index} className=''>
                  <td className='fw-bolder'>
                    <img className='Davatar' src={item?.socialMediaDetails?.instagram?.avatar || item?.socialMediaDetails?.youtube?.avatar} alt='' />
                   {item?.basicInfo?.name}
                  </td>
                  <td className='fw-normal'>
                 {
                   item?.socialMediaDetails?.youtube && item?.socialMediaDetails?.instagram ? 
                   <>
                   <img src={toAbsoluteUrl('/media/logos/Youtube.png')} alt='' style={{marginRight: '8px'}} />
                   <img src={toAbsoluteUrl('/media/logos/Instagram.png')}  alt='' />
                   </>
                   : item?.socialMediaDetails?.youtube ? <img src={toAbsoluteUrl('/media/logos/Youtube.png')} alt='' style={{marginRight: '8px'}} />
                   : item?.socialMediaDetails?.instagram ? <img src={toAbsoluteUrl('/media/logos/Instagram.png')}  alt='' />
                   
                   : ""
              }
                  </td>
                  <td className='fw-normal'>
                    {moment(item?.createdAt).format('DD MMM YYYY ')}
                  </td>      
                </tr>
              ))}
            </tbody>
          </table> 
        </div>
      )}
    </div>         
  )
}