import {message} from 'antd'
import clsx from 'clsx'
import {useFormik} from 'formik'
import {useEffect, useState} from 'react'
import {Link, useHistory} from 'react-router-dom'
import Select from 'react-select'
import * as Yup from 'yup'
import httpService from '../../setup/http/httpServices'
import loc from '../data/Location.json'
import '../masters/basic.css'
import {CampaignLeftBreadcrum} from './CampaignLeftBreadcrum'

var initialValues = {
  gender: [] as any,
  ageGroup: [] as any,
  language: [] as any,
  location: [] as any,
}

const registrationSchema = Yup.object().shape({
  gender: Yup.array().required('Influencer Type is required'),
  ageGroup: Yup.array().required('Influencer Type is required'),
  // location: Yup.array().required('Location is required'),
  // language: Yup.array().required('Language is required'),
})
export function AudienceDetails() {
  const history: any = useHistory()
  const [loading, setLoading] = useState(false)
  const [audienceDetails, setAudienceDetails] = useState(initialValues)
  const [language, setLanguage] = useState<any>([])
  const [location, setLocation] = useState<any>([])
  const [cities, setCities] = useState([])

  //location and language
  const [show, setShow] = useState(false)

  const languages: any = [
    {value: 'English', label: 'English'},
    {value: 'Hindi', label: 'Hindi'},
    // {value: 'Bengali', label: 'Bengali'},
    // {value: 'Marathi', label: 'Marathi'},
    // {value: 'Telugu', label: 'Telugu'},
    // {value: 'Tamil', label: 'Tamil'},
    // {value: 'Urdu', label: 'Urdu'},
    // {value: 'Gujarati', label: 'Gujarati'},
    // {value: 'Kannada', label: 'Kannada'},
    // {value: 'Malayalam', label: 'Malayalam'},
    // {value: 'Odia', label: 'Odia'},
    // {value: 'Punjabi', label: 'Punjabi'},
    // {value: 'Assamese', label: 'Assamese'},
    // {value: 'Maithili', label: 'Maithili'},
  ]
  // const locations: any = [
  //   {value: 'Mumbai', label: 'Mumbai'},
  //   {value: 'Delhi', label: 'Delhi'},
  // ]
  const getCt = () => {
    var c: any = []
    var ci: any = []
    ci.push({value: 'PAN India', label: 'PAN India'})
    loc.map((l) => {
      c = l.Cities
      c.map((ct: any) => {
        var p = {
          value: ct,
          label: ct,
        }
        ci.push(p)
      })
    })
    ci.push({value: 'any', label: 'any'})
    setCities(ci)
  }
  const formik = useFormik({
    initialValues: audienceDetails || initialValues,
    validationSchema: registrationSchema,
    enableReinitialize: true,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setTimeout(() => {
        setAudienceDetails(values)
        setLoading(false)
        setSubmitting(false)
        let request: any = {
          campaignDetails: history.location.state.campaignDetails,
          // influencerDetails: history.location.state.influencerDetails,
          audienceDetails: values,
          otherDetails: history.location.state?.otherDetails,
        }
        if (history.location.state?.campaignDetails.platform === 'Instagram') {
          request.instagramInfluencerDetails = history.location.state?.instagramInfluencerDetails
        } else if (history.location.state?.campaignDetails.platform === 'Youtube') {
          request.youtubeInfluencerDetails = history.location.state?.youtubeInfluencerDetails
        }

        request.audienceDetails.language = language.map((x: any) => x.label)
        request.audienceDetails.location = location.map((x: any) => x.label).join(',')
        httpService.patchData(
          '/campaign-master/' + history.location.state?._id,
          request,
          (response: any) => {
            message.success('Audience details saved successfully.')
            history.push({
              pathname: '/campaign/otherDetails',
              state: {
                ...history.location.state,
                ...{
                  audienceDetails: values,
                },
              },
            })
          },
          (error: any) => {
            message.error(error.message)
          }
        )
      }, 1000)
    },
  })

  useEffect(() => {
    getCt()
    var audienceDetail1 = history.location.state?.audienceDetails
    if (audienceDetail1) {
      audienceDetail1['language'] &&
        setLanguage(
          audienceDetail1['language']?.map((x: any) => {
            return {label: x, value: x}
          })
        )
      if (audienceDetail1['location'])
        setLocation(
          audienceDetail1['location'].split(',')?.map((x: any) => {
            return {label: x, value: x}
          })
        )
      setAudienceDetails(audienceDetail1)
    }
  }, [
    history.location.state?.audienceDetails?.language,
    history.location.state?.audienceDetails?.location,
  ])

  const updateGender = (value, currentGender) => {
    if (value.target.checked && formik.values.gender.includes('All')) {
      formik.values.gender.splice(formik.values.gender.indexOf('All'), 1)
      formik.setFieldValue('gender', [currentGender])
    }
    else if (value.target.checked) {
      formik.setFieldValue('gender', [...formik.values.gender, ...[currentGender]])
    }

  }
  return (
    <div className='CompanyDetailsOuter'>
      <CampaignLeftBreadcrum currentStep='3' />
      <form
        className='CompanyDetails campaignDetails'
        noValidate
        id='kt_login_signup_form'
        onSubmit={formik.handleSubmit}
        style={{marginBottom: '100px'}}
      >
        <h2 className='mb-0 '>Set target Audience</h2>
        <p className='text-gray-400 fs-7'>
          Add some information related to your campaign audience.
        </p>
        <div className='m32'>
          <p className='fs-6 required mb-0 fw-bold'>Primary Gender</p>
          <div className='flex mt-2 form-check form-check-custom form-check-solid '>
            <label className='w-100 flex me-10 justify-content-start'>
              <input
                {...formik.getFieldProps('gender')}
                className={clsx(
                  'form-check-input w-20px h-20px',
                  {
                    'is-invalid': formik.touched.gender && formik.errors.gender,
                  },
                  {
                    'is-valid': formik.touched.gender && !formik.errors.gender,
                  }
                )}
                type='checkbox'
                value='Male'
                checked={formik.values.gender?.includes('Male')}
                onChange={(value) => updateGender(value, 'Male')}
                id='flexCheckDefault'
              />
              <label className='fs-6 ms-2 w-50 mb-0'>Male</label>

              {formik.touched.gender && formik.errors.gender && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='text-danger' role='alert'>
                      {formik.errors.gender}
                    </span>
                  </div>
                </div>
              )}
            </label>
            <label className='w-100 flex me-10 justify-content-start'>
              <input
                {...formik.getFieldProps('gender')}
                className={clsx(
                  'form-check-input w-20px h-20px',
                  {
                    'is-invalid': formik.touched.gender && formik.errors.gender,
                  },
                  {
                    'is-valid': formik.touched.gender && !formik.errors.gender,
                  }
                )}
                type='checkbox'
                value='Female'
                checked={formik.values.gender?.includes('Female')}
                onChange={(value) => updateGender(value, 'Female')}
                id='flexCheckDefault'
              />
              <label className='fs-6 ms-2 w-50 mb-0'>Female</label>

              {formik.touched.gender && formik.errors.gender && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='text-danger' role='alert'>
                      {formik.errors.gender}
                    </span>
                  </div>
                </div>
              )}
            </label>
            <label className='w-100 flex me-10 justify-content-start'>
              <input
                {...formik.getFieldProps('gender')}
                className={clsx(
                  'form-check-input w-20px h-20px',
                  {
                    'is-invalid': formik.touched.gender && formik.errors.gender,
                  },
                  {
                    'is-valid': formik.touched.gender && !formik.errors.gender,
                  }
                )}
                type='checkbox'
                value='Others'
                checked={formik.values.gender?.includes('Others')}
                onChange={(value) => updateGender(value, 'Others')}
                id='flexCheckDefault'
              />
              <label className='fs-6 ms-2 w-50 mb-0'>Others</label>

              {formik.touched.gender && formik.errors.gender && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='text-danger' role='alert'>
                      {formik.errors.gender}
                    </span>
                  </div>
                </div>
              )}
            </label>
            <label className='w-100 flex me-10 justify-content-start'>
              <input
                {...formik.getFieldProps('gender')}
                className={clsx(
                  'form-check-input w-20px h-20px',
                  {
                    'is-invalid': formik.touched.gender && formik.errors.gender,
                  },
                  {
                    'is-valid': formik.touched.gender && !formik.errors.gender,
                  }
                )}
                type='checkbox'
                value='All'
                onChange={(value) => {
                  formik.setFieldValue('gender', value.target.checked ? ['All'] : [])
                }}
                checked={formik.values.gender?.includes('All')}
                id='flexCheckDefault'
              />
              <label className='fs-6 ms-2 w-50 mb-0'>All</label>

              {formik.touched.gender && formik.errors.gender && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='text-danger' role='alert'>
                      {formik.errors.gender}
                    </span>
                  </div>
                </div>
              )}
            </label>
          </div>
        </div>
        <div className='m32'>
          <p className='fs-6 required mb-0 fw-bold'>Select Age Group</p>
          <div className='flex form-check form-check-custom form-check-solid '>
            {/* <label className='w-100 flex me-10 justify-content-start'>
              <input
                {...formik.getFieldProps('ageGroup')}
                className={clsx(
                  'form-check-input w-20px h-20px',
                  {
                    'is-invalid': formik.touched.ageGroup && formik.errors.ageGroup,
                  },
                  {
                    'is-valid': formik.touched.ageGroup && !formik.errors.ageGroup,
                  }
                )}
                type='checkbox'
                value='13-17'
                checked={formik.values.ageGroup?.includes('13-17')}
                id='flexCheckDefault'
              />
              <label className='fs-6 ms-2 w-50 mb-0'>13-17</label>

              {formik.touched.ageGroup && formik.errors.ageGroup && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='text-danger' role='alert'>
                      {formik.errors.ageGroup}
                    </span>
                  </div>
                </div>
              )}
            </label> */}
            <label className='w-100 flex me-10 justify-content-start'>
              <input
                {...formik.getFieldProps('ageGroup')}
                className={clsx(
                  'form-check-input w-20px h-20px',
                  {
                    'is-invalid': formik.touched.ageGroup && formik.errors.ageGroup,
                  },
                  {
                    'is-valid': formik.touched.ageGroup && !formik.errors.ageGroup,
                  }
                )}
                type='checkbox'
                value='18-24'
                checked={formik.values.ageGroup?.includes('18-24')}
                id='flexCheckDefault'
              />
              <label className='fs-6 ms-2 w-50 mb-0'>18-24</label>

              {formik.touched.ageGroup && formik.errors.ageGroup && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='text-danger' role='alert'>
                      {formik.errors.ageGroup}
                    </span>
                  </div>
                </div>
              )}
            </label>
            <label className='w-100 flex me-10 justify-content-start'>
              <input
                {...formik.getFieldProps('ageGroup')}
                className={clsx(
                  'form-check-input w-20px h-20px',
                  {
                    'is-invalid': formik.touched.ageGroup && formik.errors.ageGroup,
                  },
                  {
                    'is-valid': formik.touched.ageGroup && !formik.errors.ageGroup,
                  }
                )}
                type='checkbox'
                value='25-34'
                checked={formik.values.ageGroup?.includes('25-34')}
                id='flexCheckDefault'
              />
              <label className='fs-6 ms-2 w-50 mb-0'>25-34</label>

              {formik.touched.ageGroup && formik.errors.ageGroup && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='text-danger' role='alert'>
                      {formik.errors.ageGroup}
                    </span>
                  </div>
                </div>
              )}
            </label>
            <label className='w-100 flex me-10 justify-content-start'>
              <input
                {...formik.getFieldProps('ageGroup')}
                className={clsx(
                  'form-check-input w-20px h-20px',
                  {
                    'is-invalid': formik.touched.ageGroup && formik.errors.ageGroup,
                  },
                  {
                    'is-valid': formik.touched.ageGroup && !formik.errors.ageGroup,
                  }
                )}
                type='checkbox'
                value='35-44'
                checked={formik.values.ageGroup?.includes('35-44')}
                id='flexCheckDefault'
              />
              <label className='fs-6 ms-2 w-50 mb-0'>35-44</label>

              {formik.touched.ageGroup && formik.errors.ageGroup && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='text-danger' role='alert'>
                      {formik.errors.ageGroup}
                    </span>
                  </div>
                </div>
              )}
            </label>
            <label className='w-100 flex me-10 justify-content-start'>
              <input
                {...formik.getFieldProps('ageGroup')}
                className={clsx(
                  'form-check-input w-20px h-20px',
                  {
                    'is-invalid': formik.touched.ageGroup && formik.errors.ageGroup,
                  },
                  {
                    'is-valid': formik.touched.ageGroup && !formik.errors.ageGroup,
                  }
                )}
                type='checkbox'
                value='45-65'
                checked={formik.values.ageGroup?.includes('45-65')}
                id='flexCheckDefault'
              />
              <label className='fs-6 ms-2 w-50 mb-0'>45-64</label>

              {formik.touched.ageGroup && formik.errors.ageGroup && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='text-danger' role='alert'>
                      {formik.errors.ageGroup}
                    </span>
                  </div>
                </div>
              )}
            </label>
          </div>
        </div>
        <div className='col m32'>
          <h6 className='fw-bold required'>Language</h6>
          <Select
            placeholder='Choose one or more languages'
            options={languages}
            value={language}
            isMulti={true}
            name='language'
            //  onChange={(e: any) => setLanguage(e)}
            onChange={(e: any) => {
              formik.setFieldValue('language', e.value)
              setLanguage(e)
            }}
            styles={{
              menuList: (provided, state) => ({
                ...provided,
                height: '200px',
              }),
            }}
            className={clsx(
              'form-control form-control-solid bg-gray-100 p-0 border-0 categorySelect',
              {
                'is-invalid': formik.touched.language && formik.errors.language,
              },
              {
                'is-valid': formik.touched.language && !formik.errors.language,
              }
            )}
          />
          <p style={{color: 'red'}}>
            {show && language == '' ? 'Select atleast One Language' : ''}
          </p>
          {formik.touched.language && formik.errors.language && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span className='text-danger' role='alert'>
                  {formik.errors.language}
                </span>
              </div>
            </div>
          )}
        </div>
        <div className='col m32'>
          <h6 className='fw-bold required'>Location</h6>
          {/* <select
            {...formik.getFieldProps('location')}
            className={clsx(
              'form-select text-gray-500 border-0 bg-gray-100',
              {
                'is-invalid': formik.touched.location && formik.errors.location,
              },
              {
                'is-valid': formik.touched.location && !formik.errors.location,
              }
            )}
          >
            <option value=''>Enter city, state</option>
            <option value='Mumbai'>Mumbai</option>
          </select> */}
          <Select
            options={cities}
            isMulti={true}
            className={clsx(
              'form-control form-control-solid bg-gray-100 p-0 border-0 categorySelect',
              {
                'is-invalid': formik.touched.location && formik.errors.location,
              },
              {
                'is-valid': formik.touched.location && !formik.errors.location,
              }
            )}
            value={location}
            onChange={(e: any) => {
              setLocation(e)
            }}
            styles={{
              menuList: (provided, state) => ({
                ...provided,
                height: '200px',
              }),
            }}
            placeholder='Example: Mumbai, New Delhi, Pune, Kolkata'
          />
          <p style={{color: 'red'}}>
            {show && location == '' ? 'Select atleast One Location' : ''}
          </p>
          {formik.touched.location && formik.errors.location && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span className='text-danger' role='alert'>
                  {formik.errors.location}
                </span>
              </div>
            </div>
          )}
        </div>

        <div className='d-flex m32 pe-5'>
          <Link
            to={{
              pathname: '/campaign/influencerDetails',
              state: {
                ...history.location.state,
                ...{
                  audienceDetails: {
                    ...formik.values,
                    language: language.map((x: any) => x.label),
                    location: location.map((x: any) => x.label).join(','),
                  },
                },
              },
            }}
          >
            <button className='btn fw-bolder btn-gray-200 CompanyDetailButton'>Go Back</button>
          </Link>
          <button
            type='submit'
            id='kt_sign_up_submit'
            className='btn create-btn CompanyDetailButton ms-5'
            disabled={
              formik.isSubmitting ||
              !formik.isValid ||
              language?.length == 0 ||
              location?.length == 0
            }
            onClick={() => setShow(true)}
          >
            {!loading && <span className='indicator-label'>Save & Continue</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}
