import {message} from 'antd'
import clsx from 'clsx'
import {useFormik} from 'formik'
import {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap-v5'
import {Link, useHistory} from 'react-router-dom'
import * as Yup from 'yup'
import {KTSVG, toAbsoluteUrl} from '../../_metronic/helpers'
import httpService from '../../setup/http/httpServices'
import {InfluencerMatrixLeftBreadcrum} from './InfluencerMatrixLeftBreadcrum'
import './basic.css'

var initialValues = {
  username: '',
  link: '',
}

const registrationSchema = Yup.object().shape({
  // username: Yup.string().required('Username is required'),
  // link: Yup.string().required('Link is required'),
})

export function InfluencerOtherDetails() {
  const history: any = useHistory()
  const id = history.location.state._id

  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const [show1, setShow1] = useState(false)

  const handleClose1 = () => setShow1(false)
  const handleShow1 = () => setShow1(true)

  const [loading, setLoading] = useState(false)
  const [influencerOther, setInfluencerOther] = useState<any>(initialValues)
  const [instagramVerified, setInstagramVerified] = useState(false)
  const [youtubeVerified, setYoutubeVerified] = useState(false)

  const verifySocialMedia = (media, userName) => {
    let apiEndPoint = 'stats'
    if (history.location.state.influencerSocialDetails) {
      apiEndPoint = 'refresh-stats'
    }
    if (
      (media === 'youtube' && !youtubeVerified) ||
      (media === 'instagram' && !instagramVerified)
    ) {
      httpService.getData(
        '/iqdata/' + apiEndPoint + '/' + id + '?platform=' + media + '&username=' + userName,
        (response: any) => {
          setRequest({forYoutube: true, forInstagram: true})
          if (media === 'youtube' && response.data.data.success) {
            // influencerOther.youtube.verificationStatus = 'Verified'
            setYoutubeVerified(true)
          } else if (media === 'instagram' && response.data.data.success) {
            // influencerOther.instagram.verificationStatus = 'Verified'
            setInstagramVerified(true)
          }
          message.success('User ' + userName + ' verified')
        },
        (error: any) => {
          if (error.message) message.error(error.message)
          else message.error('Something is not right. Please validate username.')
        }
      )
    }
  }
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: registrationSchema,
    enableReinitialize: true,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      if (!youtubeVerified && !instagramVerified) {
        setLoading(false)
        setSubmitting(false)
        // temporarily  commented due to API limitations
        // return;
      }
      setSubmitting(false)
      submitForm(false)
    },
  })

  const [requestSent, setRequest] = useState({forYoutube: false, forInstagram: false})
  const submitForm = (verifyUser: boolean) => {
    setRequest({forYoutube: false, forInstagram: false})
    const values = formik.values
    setInfluencerOther(values)

    let request = {
      ...history.location.state?.influencerBasicInfo,
      ...history.location.state?.influencerAdditinalInfo,
      ...{
        instagram: values.username,
        youtube: values.link,
      },
    }
    request.ageGroup = JSON.stringify(request.ageGroup)
    var form_data = new FormData()

    for (var key in request) {
      if (key !== 'addressDetails') form_data.append(key, request[key])
    }
    httpService.patchData(
      '/influencer-master/' + history.location.state?._id,
      form_data,
      (response: any) => {
        if (verifyUser) {
          if (values.username) {
            verifySocialMedia('instagram', values.username)
          }
          if (values.link) {
            verifySocialMedia('youtube', values.link)
          }
        } else {
          handleShow()
        }
        setLoading(false)
      },
      (error: any) => {
        message.error(error.message)
        setLoading(false)
      }
    )
  }
  useEffect(() => {
    try {
      var influencerOther1 = history.location.state?.influencerSocialDetails
      formik.setFieldValue('username', influencerOther1.instagram?.username)
      setInstagramVerified(
        influencerOther1.instagram.username &&
          influencerOther1.instagram.verificationStatus === 'verified'
      )
      formik.setFieldValue('link', influencerOther1.youtube?.username)
      setInfluencerOther(influencerOther1)
      setYoutubeVerified(
        influencerOther1.youtube.username &&
          influencerOther1.youtube.verificationStatus === 'verified'
      )
    } catch {}
  }, [history.location.state?.influencerOther])

  return (
    <div className='CompanyDetailsOuter'>
      <InfluencerMatrixLeftBreadcrum currentStep='3' />
      <form
        className='CompanyDetails'
        noValidate
        id='kt_login_signup_form'
        onSubmit={formik.handleSubmit}
      >
        <h3 className='m-0'>Link Social Media</h3>
        <div className='m30 pt-0 pe-5 '>
          <h6 className='fw-bolder '>Verify Platforms</h6>

          <div className='otherCard ps-5 flex bg-gray-100 m8'>
            <div className='col-2 flex justify-content-start'>
              <img src={toAbsoluteUrl('/media/logos/Youtube.png')} />
              <p className='fw-bold mb-0 ms-2'>Youtube</p>
            </div>
            <div className='col-7'>
              <input
                type='text'
                placeholder='Enter username'
                {...formik.getFieldProps('link')}
                onChange={(e: any) => {
                  formik.setFieldValue('link', e.target.value)
                  setRequest({forYoutube: false, forInstagram: requestSent.forInstagram})
                  if (influencerOther.youtube) influencerOther.youtube.verificationStatus = 'Verify'
                  setYoutubeVerified(false)
                  setInfluencerOther(influencerOther)
                }}
                className={clsx(
                  'form-control form-control-solid bg-gray-100 border-0',
                  {
                    'is-invalid': formik.touched.link && formik.errors.link,
                  },
                  {
                    'is-valid': formik.touched.link && !formik.errors.link,
                  }
                )}
              />
              {/* <p className='text-gray-400 mb-0'>https://sehaye.com/</p> */}
            </div>
            <div className='col'>
              {influencerOther?.youtube?.verificationStatus === 'Verified' || youtubeVerified ? (
                <button
                  className='btn btn-success verify-platform-buttons d-flex'
                  type='button'
                  disabled
                >
                  <span className='mt-1 BrandIcon svg-icon flex svg-icon-gray-800 rounded'>
                    <KTSVG path={toAbsoluteUrl('/media/icons/Custom/Active.svg')} />
                  </span>{' '}
                  Verified
                </button>
              ) : (
                <button
                  className='btn btn-white d-flex verify-platform-buttons'
                  type='button'
                  onClick={() => {
                    submitForm(true)
                  }}
                >
                  Request{requestSent.forYoutube && 'ed'} Data
                </button>
              )}
            </div>
          </div>
          {/* {formik.touched.link && formik.errors.link && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span className='text-danger' role='alert'>
                  {formik.errors.link}
                </span>
              </div>
            </div>
          )} */}
          <div className='otherCard ps-5 flex bg-gray-100 m8'>
            <div className='col-2 flex justify-content-start'>
              <img src={toAbsoluteUrl('/media/logos/Instagram.png')} />
              <p className='fw-bold mb-0 ms-2'>Instagram</p>
            </div>
            <div className='col-7'>
              <input
                type='text'
                placeholder='Enter username'
                {...formik.getFieldProps('username')}
                onChange={(e: any) => {
                  formik.setFieldValue('username', e.target.value)
                  setRequest({forYoutube: requestSent.forYoutube, forInstagram: false})
                  if (influencerOther.instagram)
                    influencerOther.instagram.verificationStatus = 'Verify'
                  setInstagramVerified(false)
                  setInfluencerOther(influencerOther)
                }}
                className={clsx(
                  'form-control form-control-solid bg-gray-100 border-0',
                  {
                    'is-invalid': formik.touched.username && formik.errors.username,
                  },
                  {
                    'is-valid': formik.touched.username && !formik.errors.username,
                  }
                )}
              />

              {/* <p className='text-gray-400 mb-0'>@username</p> */}
            </div>
            <div className='col'>
              {instagramVerified && (
                <button className='btn btn-success verify-platform-buttons d-flex' disabled>
                  <span className='mt-1 BrandIcon svg-icon flex svg-icon-gray-800 rounded'>
                    <KTSVG path={toAbsoluteUrl('/media/icons/Custom/Active.svg')} />
                  </span>{' '}
                  Verified
                </button>
              )}
              {!instagramVerified && (
                <button
                  className='btn btn-white verify-platform-buttons d-flex'
                  type='button'
                  onClick={() => {
                    submitForm(true)
                  }}
                >
                  Request{requestSent.forInstagram && 'ed'} Data
                </button>
              )}
            </div>
          </div>
          {formik.touched.link &&
            formik.touched.username &&
            formik.values.link === '' &&
            formik.values.username === '' && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='text-danger' role='alert'>
                    Atleast one Social Media Id is required
                  </span>
                </div>
              </div>
            )}
        </div>
        <div className='d-flex pt-4 pb-5'>
          <Link
            to={{
              pathname: '/master/influencerAdditionalInfo',
              state: {
                influencerBasicInfo: history.location.state?.influencerBasicInfo,
                influencerAdditinalInfo: history.location.state?.influencerAdditinalInfo,
                influencerSocialDetails: {
                  instagram: {username: formik.values.username, verificationStatus: 'Verify'},
                  youtube: {link: formik.values.link, verificationStatus: 'Verify'},
                },
                _id: history.location.state?._id,
                type: history.location.state?.type,
              },
            }}
          >
            <button
              type='button'
              style={{width: '172px', height: '53px'}}
              className='btn fw-bolder btn-gray-200 w-172px h-53px'
            >
              Go Back
            </button>
          </Link>
          <button
            type='submit'
            id='kt_sign_up_submit'
            className='btn ms-5 create-btn CompanyDetailButton'
            disabled={!(instagramVerified || youtubeVerified)}
            // disabled={formik.values.link === '' && formik.values.username === ''}
          >
            {!loading && <span className='indicator-label'>Save & Finish</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          {/* <Button variant='primary ms-5' onClick={handleShow}>
            Success modal
          </Button> */}

          <Modal
            show={show}
            onHide={handleClose}
            backdrop='static'
            keyboard={false}
            aria-labelledby='contained-modal-title-vcenter '
            centered
          >
            <Modal.Body className='p-0 '>
              <div style={{width: '745px'}}>
                <div
                  style={{width: '100%', height: '291px'}}
                  className='d-flex justify-content-center bg-white rounded-top '
                >
                  <img
                    style={{position: 'absolute', top: '15px'}}
                    src={toAbsoluteUrl('/media/logos/InfluencerModal.png')}
                  ></img>
                </div>
                <div
                  className='text-center bg-white rounded-bottom'
                  style={{width: '100%', height: '270px'}}
                >
                  <h2 style={{paddingTop: '32px', fontSize: '18px'}}>
                    {!history.location.state?._id
                      ? 'Looks like a great time to welcome the new member! 🎈'
                      : 'Influencer details updated Successfully'}
                  </h2>
                  <p
                    style={{
                      width: '486px',
                      height: '54px',
                      marginRight: 'auto',
                      marginLeft: 'auto',
                      paddingTop: '4px',
                      fontSize: '12px',
                    }}
                    className='mb-0'
                  >
                    {!history.location.state?._id
                      ? 'You just added a new influencer successfully! You can now share the link with the influencer to assign access to the profile.'
                      : 'You just updated influencer details successfully! You can now share the link with the influencer to assign access to the profile.'}
                  </p>
                  <div className='flex'>
                    <Link
                      to={{
                        pathname: '/master/influencerBasicInfo',
                        state: {type: 'influencer'},
                      }}
                    >
                      <button
                        style={{width: '263px'}}
                        className='btn btn-gray-100 me-5 text-dark border m32'
                      >
                        Add another Influencer
                      </button>
                    </Link>
                    <button
                      style={{width: '263px'}}
                      className='btn create-btn w-263px m32'
                      onClick={() => {
                        httpService.postData(
                          '/influencer-master/send-access-link/' + history.location.state?._id,
                          {},
                          (response: any) => {
                            message.success(
                              'Access email has been shared to the registerd email address.'
                            )
                            history.push({
                              pathname: '/master/influencer',
                              state: {type: 'influencer', reloaded: false},
                            })
                          },
                          (error: any) => {
                            message.error(error.message)
                          }
                        )
                      }}
                    >
                      Send Access Link
                    </button>
                  </div>
                  <Link
                    to={{
                      pathname: '/master/influencer',
                      state: {type: 'influencer', reloaded: false},
                    }}
                  >
                    <div className='link-primary fs-6 fw-bolder mt-10'>Go to Influencer List</div>
                  </Link>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          {/* <Button variant='primary ms-5' onClick={handleShow1}>
            Fail modal
          </Button> */}

          <Modal
            show={show1}
            onHide={handleClose1}
            backdrop='static'
            keyboard={false}
            aria-labelledby='contained-modal-title-vcenter '
            centered
          >
            <Modal.Body className='p-0 '>
              <div style={{width: '745px'}}>
                <div
                  style={{width: '100%', height: '291px'}}
                  className='d-flex justify-content-center bg-white rounded-top '
                >
                  <img
                    style={{position: 'absolute', top: '15px'}}
                    src={toAbsoluteUrl('/media/logos/InfluencerModal.png')}
                  ></img>
                </div>
                <div
                  className='text-center bg-white rounded-bottom'
                  style={{width: '100%', height: '270px'}}
                >
                  <h2 style={{paddingTop: '32px', fontSize: '18px'}}>Failed to Add Influencer</h2>
                  <div className='flex'>
                    <button
                      style={{width: '263px'}}
                      onClick={handleClose1}
                      className='btn create-btn w-263px m32'
                    >
                      Try Again
                    </button>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </form>
    </div>
  )
}
