import React, {useEffect, useState} from 'react'
import {AgencyView} from './AgencyView'
import {SearchOutlined} from '@ant-design/icons'
import {Input, message, Pagination} from 'antd'
import Moment from 'moment'
import {KTSVG, toAbsoluteUrl} from '../../_metronic/helpers'
import './basic.css'
import httpService from '../../setup/http/httpServices'
import {useHistory} from 'react-router-dom'

export function AgencyViewBilling() {
  const history: any = useHistory()
  const rows = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  const [totalCount, setTotalCount] = useState(0)
  const [status, setStatus] = useState('all')

  const [agencyBillingParams, setAgencyBillingParams] = useState({
    page: 1,
    total: 4,
    limit: 10,
    search: '',
  })
  const [billing, setBilling] = useState([] as any)
  useEffect(() => {
    getAllPriceMatrix()
  }, [status, history, agencyBillingParams.page, agencyBillingParams.search])
  const getAllPriceMatrix = () => {
    httpService.getData(
      history.location.state.type === "BrandView" ? '/brand-master-details/'+history.location?.state?.id+'/billing/' : '/agency-details/billing/'+ history.location?.state?.id,
      // '/agency-details/billing/' +
        history?.location?.state?.id +
        '?status=' +
        status +
        '&limit=' +
        agencyBillingParams.limit +
        '&page=' +
        agencyBillingParams.page +
        '&search=' +
        agencyBillingParams.search,
      (response: any) => {
        setBilling(response?.data?.billings?.billings)
        setTotalCount(response?.data?.billings?.total)
        // setPriceMatrixParams({
        //   page: response.data.data.page,
        //   limit: response.data.data.limit,
        //   total: response.data.data.total,
        //   search: priceMatrixParams.search,
        // })
      },
      (error: any) => {
        message.error(error?.message)
      }
    )
  }
  return (
    <div className='Outer d-flex'>
      <AgencyView
        active={2}
        details={history?.location?.state?.profileDetails}
        id={history?.location?.state?.id}
        type={history?.location?.state?.type}
        name={history?.location?.state?.name} 
      />
      <div className='view-Bill'>
        <div className='Bill-topbar'>
          <div className='topbar-left'>
            <div className='topbar-left1'>
              <h2>Billing</h2>
              <p>{billing.length} Transactions</p>
            </div>
            <div className='topbar-left2'>
              <button
                onClick={() => {
                  setStatus('all')
                }}
                className={status == 'all' ? 'active' : ''}
              >
                All
              </button>
              <button
                onClick={() => {
                  setStatus('completed')
                }}
                className={status == 'completed' ? 'active' : ''}
              >
                Completed
              </button>
              <button
                onClick={() => {
                  setStatus('pending')
                }}
                className={status == 'pending' ? 'active' : ''}
              >
                Pending
              </button>
              <button
                onClick={() => {
                  setStatus('onHold')
                }}
                className={status == 'onHold' ? 'active' : ''}
              >
                On Hold
              </button>
              <button
                onClick={() => {
                  setStatus('cancelled')
                }}
                className={status == 'cancelled' ? 'active' : ''}
              >
                Cancelled
              </button>
            </div>
          </div>
          <div className='topbar-right'>
            <Input
              placeholder='Search'
              style={{width: '198px', height: '34px'}}
              className='form-control-solid border'
              onChange={(e: any) =>
                setAgencyBillingParams({...agencyBillingParams, ...{search: e.target.value}})
              }
              prefix={
                <>
                  <SearchOutlined />
                </>
              }
            />
            <button className='btn create-btn d-flex flex me-2 w-auto h-34px fs-7 ms-3'>
              Add Transaction 💸
            </button>
          </div>
        </div>
        <div className='table-responsive bill-table'>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bolder text-muted bill-row'>
                <th
                  className='bg-primary bg-opacity-5 min-w-144px'
                  style={{paddingLeft: '24px', width: '164px'}}
                >
                  <span className='d-flex'>Date</span>
                </th>
                <th className='bg-primary bg-opacity-5 min-w-104px' style={{width: '124px'}}>
                  <span className='d-flex'>Reference #</span>
                </th>
                <th className='bg-primary bg-opacity-5 min-w-408px' style={{width: '428px'}}>
                  <span className='d-flex'>Campaign</span>
                </th>
                <th className='bg-primary bg-opacity-5 min-w-104px' style={{width: '124px'}}>
                  <span className='d-flex'>Amount</span>
                </th>
                <th className='bg-primary bg-opacity-5 min-w-104px' style={{width: '124px'}}>
                  <span className='d-flex'>Mode</span>
                </th>
                <th className='bg-primary bg-opacity-5 min-w-104px' style={{width: '124px'}}>
                  <span className='d-flex'>Status</span>
                </th>
                <th className='bg-primary bg-opacity-5 min-w-123px' style={{width: '143px'}}>
                  <span className='d-flex'>Actions</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {billing.length === 0 && (
                <tr>
                  <td colSpan={7}>
                    <div>
                      <div className='flex'>
                        <img
                          className='ms-20'
                          src={toAbsoluteUrl('/media/logos/TableVector.png')}
                        ></img>
                      </div>
                      <div className='flex'>
                        <div>
                          <h2 className='mb-0 m24 flex'>No Bills Listed</h2>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              )}
              {billing &&
                billing.length > 0 &&
                billing?.map((index) => (
                  <tr key={index?._id} className='bill-row'>
                    <td className='fw-bolder' style={{paddingLeft: '24px'}}>
                      {Moment(index?.updatedAt).format('DD MMMM YYYY')}
                    </td>
                    <td className='fw-normal'>{index?.basicInformation?.referenceNumber}</td>
                    <td className='fw-normal'>
                      {index?.basicInformation?.campaign?.campaignDetails?.name}
                    </td>
                    <td className='fw-normal'>
                      {index.cashDetails
                        ? index?.cashDetails?.amount
                        : index.chequeDetails
                        ? index?.chequeDetails?.amount
                        : index?.bankTransferDetails?.amount}{' '}
                    </td>
                    <td className='fw-normal'>Standard</td>
                    <td className='fw-normal'>
                      {index.status == 'Pending' ? (
                        <span className='text-danger bg-danger bg-opacity-20 fs-7 rounded p-2 pt-1 pb-1'>
                          Pending
                        </span>
                      ) : (
                        <span className='text-success bg-success bg-opacity-20 fs-7 rounded p-2 pt-1 pb-1'>
                          Complete
                        </span>
                      )}
                    </td>
                    <td className='d-flex'>
                      <div className='d-flex justify-content-start flex-shrink-0'>
                        <span className='svg-icon flex svg-icon-dark bg-primary bg-opacity-5 w-30px h-30px rounded me-2 pointer'>
                          <KTSVG
                            className='svg-icon-3'
                            path={toAbsoluteUrl('/media/icons/Custom/Eye.svg')}
                          />
                        </span>
                        <span className='svg-icon flex svg-icon-dark bg-primary bg-opacity-5 w-30px h-30px rounded me-2 pointer'>
                          <KTSVG
                            className='svg-icon-3'
                            path={toAbsoluteUrl('/media/icons/Custom/Pen.svg')}
                          />
                        </span>
                        <span className='svg-icon flex svg-icon-danger bg-danger bg-opacity-5 w-30px h-30px rounded pointer'>
                          <KTSVG
                            className='svg-icon-3'
                            path={toAbsoluteUrl('/media/icons/Custom/Dustbin.svg')}
                          />
                        </span>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          <div className='d-flex ms-5 me-10 justify-content-between align-items-end flex-wrap mb-10'>
            <span className='text-muted pb-2'>
              Page {agencyBillingParams.page} of{' '}
              {Math.floor(totalCount % agencyBillingParams.limit) === 0
                ? Math.floor(totalCount / agencyBillingParams.limit)
                : Math.floor(totalCount / agencyBillingParams.limit) + 1}
            </span>
            <div className='d-flex flex-wrap '>
              <Pagination
                className=''
                pageSize={agencyBillingParams.limit}
                defaultCurrent={agencyBillingParams.page}
                total={totalCount}
                onChange={(e) => {
                  setAgencyBillingParams({...agencyBillingParams, ...{page: e}})
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
