import FullCalendar from '@fullcalendar/react'
import { Calendar } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'
import listPlugin from '@fullcalendar/list'
import timeGridPlugin from '@fullcalendar/timegrid'
import Moment from 'moment'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import httpService from '../../setup/http/httpServices'
import './calendar.css'
import { CalendarEvent } from './CalendarEvent'
import { CampaignModal } from './CampaignModal'
import { IUserModel } from '../modules/auth/models/UserModel'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../setup'

export function SchedulerCalendar() {
  const user: IUserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as IUserModel
  const history: any = useHistory()
  const [show, setShow] = useState(false)
  const [details, setDetails] = useState({})
  const [arr, setArr] = useState([])
  const [contentType, setContentType] = useState('')

  const [campaigns, setCampaigns] = useState([])
  const [agencies, setAgencies] = useState([])
  const [brands, setBrands] = useState([])
  const [influencers, setInfluencers] = useState([])

  const [campaignId, setCampaignId] = useState('')
  const [agencyId, setAgencyId] = useState(user.agencyId || '')
  const [brandId, setBrandId] = useState(user.brandId || '')
  const [platform, setPlatform] = useState('')
  const [influencer, setInfluencer] = useState('')

  const ResetFilterHandler = (e: any) => (
    e.preventDefault(),
    setCampaignId(''),
    setAgencyId(''),
    setBrandId(''),
    setPlatform(''),
    setInfluencer('')
  )

  const [allDaySlot, setAllDaySlot] = useState(false)
  const calendarRef = useRef(null)

  const handleClick = (eventDetails: any) => {
    setDetails(eventDetails.extendedProps.description)
    setShow(true)
  }
  const getEvents = (startStr, endStr) => {
    httpService.getData(
      '/scheduler/date?startDate=' + startStr + '&endDate=' + endStr + '&platform=' + platform +
      '&campaign=' + campaignId + '&agency=' + agencyId + '&brand=' + brandId,
      (res: any) => {
        if (res.data.data.scheduledPost.length > 0) {
          let data = []
          res.data.data.scheduledPost.forEach((x: any) => {
            x.campaigns.forEach((y, index) => {
              let item = y
              item.type = x.type
              item.contentType = x.contentType
              item.liveDate = x.postDate
              let obj = {
                id: index,
                allDay: false,
                start: item.liveDate.substring(0, 19),
                end: Moment(item.liveDate).add(30, 'm').toISOString().substring(0, 19),
                title: item.name,
                description: item,
                className: 'event-info',
              }
              data.push(obj)
            })
          })
          setArr(data)
        } else {
          setArr([])
        }
      },
      (err: any) => {}
    )
  }
  const getHeaderLists = () => {
    let param = ''
    if (user.userType === 'brand') {
      param += '?brand=' + user.brandId
    } else if (user.userType === 'agency') {
      param += '?agency=' + user.agencyId
    }
    httpService.getData(
      '/campaign-master' + param,
      (res: any) => {
        setCampaigns(res.data.data.campaigns)
      },
      (err: any) => {}
    )
    httpService.getData(
      '/brand-master',
      (res: any) => {
        setBrands(res.data.data.brands)
      },
      (err: any) => {}
    )
    httpService.getData(
      '/agency-master',
      (res: any) => {
        setAgencies(res.data.data.agencies)
      },
      (err: any) => {}
    )
    httpService.getData(
      '/influencer-master',
      (res: any) => {
        setInfluencers(res.data.data.influencers)
      },
      (err: any) => {}
    )
  }
  useEffect(() => {
    if (history.location.state.type == 'schedular/calendar/week') {
      calendarRef.current.getApi().changeView('dayGridWeek')
    } else {
      calendarRef.current.getApi().changeView('dayGridMonth')
    }
  }, [history.location.state.type])
  useEffect(() => {
    getHeaderLists()
  }, [campaignId, agencyId, brandId])

  const renderEvent = (eventInfo: any) => {
    return (
      <div onClick={() => handleClick(eventInfo?.event)}>
        {eventInfo?.view.type === 'timeGridDay' ? (
          <CalendarEvent event={eventInfo?.event} />
        ) : (
          <div className='flex event-container-small'>
            <img src={eventInfo?.event.extendedProps.description.brandLogo} alt='' />
            <p>{eventInfo?.event.title}</p>
            <p style={{marginLeft: '15px'}}>
              {Moment(eventInfo?.event?.extendedProps?.description.liveDate).format('h:mm a')}
            </p>
          </div>
        )}
      </div>
    )
  }

  const dateChangeHandle = (x) => {
    let startStr = x.startStr.split('T')[0]
    let endStr = x.endStr.split('T')[0]
    getEvents(startStr, endStr)
  }

  return (
    <div className='Outer d-flex cal' style={{minWidth: '1336px'}}>
      <CampaignModal
        show={show}
        details={details}
        contentType={contentType}
        close={() => setShow(false)}
      />
      <div className='container-fluid flex justify-content-between bg-white h-75px w-100 border'>
        <div className='flex form-check form-check-custom form-check-solid w-100  justify-content-start '>
          <div className='filters '>Filters</div>
          <select
            value={campaignId}
            className='form-select mx-2'
            style={{width: '448px'}}
            onChange={(event: any) => {
              setCampaignId(event.target.value)
            }}
          >
            <option value=''>Select Campaign</option>
            {campaigns &&
              campaigns.map((campaign) => (
                <option value={campaign._id}>{campaign.campaignDetails.name}</option>
              ))}
          </select>
          {!(user.userType === 'agency' || user.userType === 'brand') && (
            <select
              value={agencyId}
              className='form-select mx-2'
              style={{width: '247px'}}
              onChange={(event: any) => {
                setAgencyId(event.target.value)
              }}
            >
              <option value=''>Select Agency</option>
              {agencies &&
                agencies.map((agency) => (
                  <option value={agency._id}>{agency.agencyDetails.agencyName}</option>
                ))}
            </select>
          )}
          {!(user.userType === 'agency' || user.userType === 'brand') && (
            <select
              value={brandId}
              className='form-select mx-2'
              style={{width: '247px'}}
              onChange={(event: any) => {
                setBrandId(event.target.value)
              }}
            >
              <option value=''>Select Brand</option>
              {brands &&
                brands.map((brand) => (
                  <option value={brand._id}>{brand.brandDetails.brandName}</option>
                ))}
            </select>
          )}
          <select
            className='form-select mx-2'
            value={influencer}
            onChange={(e: any) => setInfluencer(e.target.value)}
            style={{width: '247px'}}
          >
            <option value=''>Select Influencer</option>
            {influencers &&
              influencers.map((influencer) => (
                <option value={influencer._id}>{influencer.basicInfo.name}</option>
              ))}
          </select>
          <select
            value={platform}
            className='form-select mx-2'
            style={{width: '247px'}}
            onChange={(event: any) => {
              setPlatform(event.target.value)
            }}
          >
            <option value=''>Select Media</option>
            <option value='Instagram'>Instagram</option>
            <option value='Youtube'>Youtube</option>
          </select>
          <div className='filters cursor-pointer' onClick={ResetFilterHandler}>
            Reset Filters
          </div>
        </div>
      </div>
      <div className='view-calendar'>
        <div className='card card-custom'>
          <div className='card-body'>
            <FullCalendar
              ref={calendarRef}
              plugins={[dayGridPlugin, timeGridPlugin, listPlugin]}
              headerToolbar={{
                left: 'prev,title,next',
                right: 'timeGridDay,dayGridWeek,dayGridMonth',
              }}
              height='100%'
              displayEventTime={true}
              allDaySlot={false}
              defaultAllDay={true}
              initialView='dayGridMonth'
              eventContent={renderEvent}
              views={{
                dayGridMonth: {buttonText: 'Month'},
                dayGridWeek: {buttonText: 'Week'},
                timeGridDay: {buttonText: 'Day'},
              }}
              datesSet={(x) => {
                dateChangeHandle(x)
              }}
              events={arr}
              eventTimeFormat={{
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                meridiem: true,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
