import React, {useEffect, useState} from 'react'
import httpService from '../../setup/http/httpServices'
import {toAbsoluteUrl} from '../../_metronic/helpers'
import Moment from 'moment'

function ProfileCampaigns(props: any) {
  const id = props.data._id
  const [campaigns, setCampaigns] = useState([])
  useEffect(() => {
    getAllCampaigns()
  }, [props.data])
  const getAllCampaigns = () => {
    httpService.getData(
      '/influencer-master/campaign/' + id,
      (res: any) => {
        setCampaigns(res.data.data.campaigns)
      },
      (err: any) => {
        console.log(err.message)
      }
    )
  }
  return (
    <div className='d-flex w-100 m20 justify-content-between flex-wrap'>
      {campaigns.length == 0 && (
        <div className='container p-0'>
          <div className='bg-white rounded flex'>
            <div className='p-8 pt-0'>
              <div className='flex'>
                <img className='' src={toAbsoluteUrl('/media/logos/TableVector.png')}></img>
              </div>
              <div className='flex'>
                <div>
                  <h2 className='mb-0 m24 flex'>No Campaigns Listed</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {campaigns.length > 0 &&
        campaigns.map((data: any) => (
          <>
            {props.type && data.campaignDetails.platform == 'Youtube' && (
              <div
                style={{backgroundColor: 'white', padding: '24px', width: '546px'}}
                className='rounded mb16 d-flex flex-column'
              >
                <span className='d-flex w-100'>
                  <span className='flex justify-content-start w-100'>
                    <label className='campaignLabel w-40px h-40px rounded-circle'>
                      <img
                        className='w-100'
                        src={data?.campaignDetails?.brandMaster?.brandDetails?.avatar}
                      />{' '}
                    </label>
                    <span className='fs-6 ms-4 mt-1 '>
                      <h6 className='mb-0'>
                        {data.campaignDetails.brandMaster.brandDetails.brandName}
                      </h6>{' '}
                      <label className='fs-7 text-gray-700'>
                        {data.campaignDetails.agencyMaster.agencyDetails.agencyName}
                      </label>
                    </span>
                  </span>
                  <span className='flex h-25px justify-content-end w-50'>
                    {data.status == 'Under Review' && (
                      <span className='text-warning bg-warning bg-opacity-20 fs-7 rounded p-2 pt-1 pb-1'>
                        Under Review
                      </span>
                    )}
                    {data.status == 'Completed' && (
                      <span className='text-success bg-success bg-opacity-20 fs-7 rounded p-2 pt-1 pb-1'>
                        Completed
                      </span>
                    )}
                    {data.status === 'Active' && (
                      <span className='text-warning bg-warning bg-opacity-20 fs-7 rounded p-2 pt-1 pb-1'>
                        Active
                      </span>
                    )}
                  </span>
                </span>
                <div className='d-flex flex-column m16'>
                  <span className='fs-16'>{data.campaignDetails.name}</span>
                  <span className='fs-12'>{data.campaignDetails.desription}</span>
                </div>
                <div className='d-flex w-100 justify-content-between m16'>
                  <div className='d-flex flex-column'>
                    <span className='fs-12 text-muted'>Content type</span>
                    <span className='fs-12 fw-bold'>Engagement Rate</span>
                    <span className='fs-12 text-muted m16'>Barter Value</span>
                    <span className='fs-12 fw-bold'>
                      ₹{data.campaignDetails.barterValue.min}-₹
                      {data.campaignDetails.barterValue.max}
                    </span>
                  </div>
                  <div className='d-flex flex-column'>
                    <span className='fs-12 text-muted'>Barter value</span>
                    <span className='fs-12 fw-bold'>
                      ₹{data.campaignDetails.barterValue.min}-₹
                      {data.campaignDetails.barterValue.max}
                    </span>
                    <span className='fs-12 text-muted m16'>Application Deadline</span>
                    <span className='fs-12 fw-bold'>
                      {Moment(data.campaignDetails.endDate).format('DD MMMM YYYY')}
                    </span>
                  </div>
                  <div className='d-flex flex-column'>
                    <span className='fs-12 text-muted'>Influencers Associated</span>
                    <span className='fs-12 fw-bold'>{data.campaignDetails.noOfInfluencers}</span>
                    <span className='fs-12 text-muted m16'>Submission Deadline</span>
                    <span className='fs-12 fw-bold'>3 days left</span>
                  </div>
                </div>
                <div className='d-flex justify-content-between m24'>
                  <span style={{fontSize: '10px'}}>
                    {Moment(data.updatedAt).format('DD MMMM YYYY')}
                  </span>
                </div>
              </div>
            )}
            {!props.type && data?.campaignDetails?.platform == 'Instagram' && (
              <div
                style={{backgroundColor: 'white', padding: '24px', width: '546px'}}
                className='rounded mb16 d-flex flex-column'
              >
                <span className='d-flex w-100'>
                  <span className='flex justify-content-start w-100'>
                    <label className='campaignLabel w-40px h-40px rounded-circle'>
                      <img
                        className='w-100'
                        src={data?.campaignDetails?.brandMaster?.brandDetails?.avatar}
                      />{' '}
                    </label>
                    <span className='fs-6 ms-4 mt-1 '>
                      <h6 className='mb-0'>
                        {data?.campaignDetails?.brandMaster?.brandDetails?.brandName}
                      </h6>{' '}
                      <label className='fs-7 text-gray-700'>
                        {data?.campaignDetails?.agencyMaster?.agencyDetails?.agencyName}
                      </label>
                    </span>
                  </span>
                  <span className='flex h-25px justify-content-end w-50'>
                    {data?.status == 'Under Review' && (
                      <span className='text-warning bg-warning bg-opacity-20 fs-7 rounded p-2 pt-1 pb-1'>
                        Under Review
                      </span>
                    )}
                    {data?.status == 'Completed' && (
                      <span className='text-success bg-success bg-opacity-20 fs-7 rounded p-2 pt-1 pb-1'>
                        Completed
                      </span>
                    )}
                    {data?.status === 'Active' && (
                      <span className='text-warning bg-warning bg-opacity-20 fs-7 rounded p-2 pt-1 pb-1'>
                        Active
                      </span>
                    )}
                  </span>
                </span>
                <div className='d-flex flex-column m16'>
                  <span className='fs-16'>{data?.campaignDetails?.name}</span>
                  <span className='fs-12'>{data?.campaignDetails?.desription}</span>
                </div>
                <div className='d-flex w-100 justify-content-between m16'>
                  <div className='d-flex flex-column'>
                    <span className='fs-12 text-muted'>Content type</span>
                    <span className='fs-12 fw-bold'>Image,Video,Reel</span>
                    <span className='fs-12 text-muted m16'>Barter Value</span>
                    <span className='fs-12 fw-bold'>
                      ₹{data?.campaignDetails?.barterValue?.min}-₹
                      {data?.campaignDetails?.barterValue?.max}
                    </span>
                  </div>
                  <div className='d-flex flex-column'>
                    <span className='fs-12 text-muted'>Barter value</span>
                    <span className='fs-12 fw-bold'>
                      ₹{data?.campaignDetails?.barterValue?.min}-₹
                      {data?.campaignDetails?.barterValue?.max}
                    </span>
                    <span className='fs-12 text-muted m16'>Application Deadline</span>
                    <span className='fs-12 fw-bold'>
                      {Moment(data?.campaignDetails?.endDate).format('DD MMMM YYYY')}
                    </span>
                  </div>
                  <div className='d-flex flex-column'>
                    <span className='fs-12 text-muted'>Influencers Associated</span>
                    <span className='fs-12 fw-bold'>{data?.campaignDetails?.noOfInfluencers}</span>
                    <span className='fs-12 text-muted m16'>Submission Deadline</span>
                    <span className='fs-12 fw-bold'>3 days left</span>
                  </div>
                </div>
                <div className='d-flex justify-content-between m24'>
                  <span style={{fontSize: '10px'}}>
                    {Moment(data.updatedAt).format('DD MMMM YYYY')}
                  </span>
                </div>
              </div>
            )}
          </>
        ))}
    </div>
  )
}

export default ProfileCampaigns
