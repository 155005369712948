import React from 'react'
import {toAbsoluteUrl} from '../../helpers'

function Notifications() {
  const rows = [1, 2, 3, 4]
  const rows2 = [1, 2]
  return (
    <div className='w-100 d-flex align-items-center flex-column'>
      <div
        style={{
          marginTop: '20px',
          backgroundColor: 'white',
          borderRadius: '12px',
          height: 'auto',
          width: '972px',
        }}
      >
        <h2 style={{margin: '16px'}}>Today</h2>
        {rows.map((ele: any, ind: number) => (
          <>
            <div style={{margin: '16px'}} className='d-flex justify-content-between'>
              <div className='d-flex'>
                <img
                  src={toAbsoluteUrl('/media/logos/Groupm.png')}
                  alt=''
                  style={{
                    width: '32px',
                    height: '32px',
                    borderRadius: '60px',
                    marginRight: '12px',
                    alignSelf: 'center',
                  }}
                />
                <div className='d-flex flex-column'>
                  <span style={{fontSize: '16px', fontWeight: 600}}>Groupm </span>
                  <span style={{fontSize: '14px', fontWeight: 400}}>
                    Submitted the campaign, approval required to proceed further
                  </span>
                </div>
              </div>
              <span className='align-self-center'>timestamp </span>
            </div>
            {ind != rows.length - 1 && <hr style={{marginLeft: '16px', marginRight: '16px'}} />}
          </>
        ))}
      </div>
      <div
        style={{
          marginTop: '20px',
          backgroundColor: 'white',
          borderRadius: '12px',
          height: 'auto',
          width: '972px',
        }}
      >
        <h2 style={{margin: '16px'}}>Yesterday's</h2>
        {rows2.map((ele: any, ind: number) => (
          <>
            <div style={{margin: '16px'}} className='d-flex justify-content-between'>
              <div className='d-flex'>
                <img
                  src={toAbsoluteUrl('/media/logos/Groupm.png')}
                  alt=''
                  style={{
                    width: '32px',
                    height: '32px',
                    borderRadius: '60px',
                    marginRight: '12px',
                    alignSelf: 'center',
                  }}
                />
                <div className='d-flex flex-column'>
                  <span style={{fontSize: '16px', fontWeight: 600}}>Groupm </span>
                  <span style={{fontSize: '14px', fontWeight: 400}}>
                    Submitted the campaign, approval required to proceed further
                  </span>
                </div>
              </div>
              <span className='align-self-center'>timestamp</span>
            </div>
            {ind != rows2.length - 1 && <hr style={{marginLeft: '16px', marginRight: '16px'}} />}
          </>
        ))}
      </div>
    </div>
  )
}

export default Notifications
