import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import httpService from '../../../../setup/http/httpServices'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { CampaginById } from '../../../Campaign/CampaginById'
import "../../Dashboard.scss"
import { NoData } from '../NoData'
// import { NoData } from './NoData'
import {CampaignData} from "./DummyData.js"


const TopCampaigns = (props:any) => {
  
const [campaignAgencyDetails, setCampaignAgencyDetails] = useState({} as any)


const getAgencyDetails = () => {
  httpService.getData(
    '/dashboard/brand',
   (res: any)=> {
    setCampaignAgencyDetails(res.data)
   },
   (err: any) => {
    console.log(err?.message)
   }
  )
}

useEffect(() => {
  getAgencyDetails()
}, [])



  return (
    <div className="Top-Campaign-Box">
        <div className="table_container" style={{width:"100%"}}>
            <h2>Top Campaigns</h2>
            {
              props.data?.length === 0 ?
              (
                <NoData/>
              ):(
                <div className='table-responsive'>
                <table className='table table-row-dashed table-row align-middle gs-0 gy-4 m-0'>
                  <thead>
                    <tr className='fw-bolder text-muted row-card '>
                      <th style={{width: '256px'}}>
                        <span className='d-flex'>Brand</span>
                      </th>
                      <th style={{width: '626px'}}>
                        <span className='d-flex agcy_header-text'>Campaign</span>
                      </th>
                      <th className='text-align-center' style={{width: '80px'}}>
                        <span className='d-flex header-text'>Platform</span>
                      </th>
                      <th className='text-align-center' style={{width: '96px'}}>
                        <span className='d-flex header-text'>Reach</span>
                      </th>
                      <th className='text-align-center' style={{width: '96px'}}>
                        <span className='d-flex header-text'>Impressions</span>
                      </th>
                      <th className='text-align-center' style={{width: '96px'}}>
                        <span className='d-flex header-text'>Eng. Rate</span>
                      </th>
                      <th className='text-align-center' style={{width: '96px'}}>
                        <span className='d-flex header-text'>Influencers</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    
                     {
                     CampaignData.map((item,index) =>(
                        <tr key={index} className='User_row' style={{}}>
                        <td className='fw-bolder'>
                          <span  className="cursor-pointer">
                          <img
                            className='Davatar'
                            src={item.icon}
                            alt=''
                          />
                          {item.iconName}
                          </span>
                        </td>
                        <td className='fw-normal agcy_text'>{item.campaignText}</td>
                        <td className='fw-bolder text-align-left'>
               
                          {item.platform === "Instagram" ? <img src={toAbsoluteUrl('/media/logos/Instagram.png')} alt='' /> : <img src={toAbsoluteUrl('/media/logos/Instagram.png')} alt='' />}
                          
                        </td>
                        <td className='fw-bolder text-align-left'>{item.reach}</td>
                        <td className='fw-bolder text-align-left'>{item.Impressions} </td>
                        <td className='fw-bolder text-align-left'>{item.EngRate}</td>
                        <td className='fw-bolder text-align-left'>{item.Influencers}</td>
                      </tr>
                      ))
                     }
                  </tbody>
                </table>
              </div>
              )
            }
           
        </div>

    </div>
  )
}

export default TopCampaigns