import {message} from 'antd'
import clsx from 'clsx'
import {ContentState, convertFromHTML, convertToRaw, EditorState} from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import {useFormik} from 'formik'
import React, {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap-v5'
import {Editor} from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import * as Yup from 'yup'
import httpService from '../../setup/http/httpServices'
import {KTSVG, toAbsoluteUrl} from '../../_metronic/helpers'
import '../masters/basic.css'
var initialValues = {
  page: '',
}

const registrationSchema = Yup.object().shape({
  page: Yup.string().required('Page Name is required'),
})

export function DocumentList() {
  const [content, setContent] = useState<string>('')
  const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty())
  const [agencyDetails, setAgencyDetails] = useState(initialValues)
  const [loading, setLoading] = useState(false)
  const [documentId, setDocumentId] = useState('')
  const [documentList, setDocumentList] = useState([])
  const [show, setShow] = useState(false)
  const [deleteId, setDeleteId] = useState('')

  useEffect(() => {
    getDocumentList()
  }, [])

  const getDocumentList = () => {
    httpService.getData(
      '/document-master',
      (response: any) => {
        setDocumentList(response.data.data.documents)
      },
      (error: any) => {
        message.error(error.message)
      }
    )
  }

  const resetForm = () => {
    formik.resetForm()
    formik.isValid = true
    setDocumentId('')
    setEditorState(EditorState.createEmpty())
  }

  const formik = useFormik({
    initialValues: agencyDetails || initialValues,
    validationSchema: registrationSchema,
    enableReinitialize: true,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setTimeout(() => {
        try {
          if (documentId) {
            httpService.patchData(
              '/document-master/' + documentId,
              {
                pageName: values.page,
                description: content,
              },
              (response: any) => {
                message.success('Document added successfully')
                getDocumentList()
              },
              (error: any) => {
                message.error(error.message)
              }
            )
          } else {
            httpService.postData(
              '/document-master',
              {
                pageName: values.page,
                description: content,
              },
              (response: any) => {
                message.success('Document added successfully')
                getDocumentList()
              },
              (error: any) => {
                message.error(error.message)
              }
            )
          }
          setLoading(false)
          formik.resetForm()
          setDocumentId('')
          setEditorState(EditorState.createEmpty())
          setSubmitting(false)
        } catch {
          setLoading(false)
          setSubmitting(false)
          setStatus('Registration process has broken')
        }
      }, 1000)
    },
  })

  const handleDocument = (id: any) => {
    httpService.getData(
      '/document-master/' + id,
      (response: any) => {
        setDocumentId(id)
        formik.setFieldValue('page', response.data.data.document.pageName)
        const content = convertFromHTML(response.data.data.document.description)
        setEditorState(
          EditorState.createWithContent(
            ContentState.createFromBlockArray(content.contentBlocks, content.entityMap)
          )
        )
      },
      (error: any) => {
        message.error(error.message)
      }
    )
  }

  const handleDelete = (id: any) => {
    setShow(false)
    httpService.deleteData(
      '/document-master/' + id,
      (response: any) => {
        message.success('Document deleted successfully')
        getDocumentList()
      },
      (error: any) => {
        message.error(error.message)
      }
    )
  }

  return (
    <div className='CompanyDetailsOuter'>
      <div className='BrandSteps documentList'>
        <h3 className='m-0'>Pages and Documents</h3>

        {documentList &&
          documentList.length > 0 &&
          documentList.map((data: any) => (
            <div className='flex'>
              <h4 className='m36 col fw-bold'>{data.pageName}</h4>
              <span
                className='svg-icon m36 flex svg-icon-dark bg-primary bg-opacity-5 w-30px h-30px rounded me-2 pointer'
                onClick={() => handleDocument(data._id)}
              >
                <KTSVG className='svg-icon' path={toAbsoluteUrl('/media/icons/Custom/Pen.svg')} />
              </span>
              <span
                className='svg-icon m36 flex svg-icon-danger bg-danger bg-opacity-5 w-30px h-30px rounded pointer'
                onClick={() => {
                  setShow(true)
                  setDeleteId(data._id)
                }}
              >
                <KTSVG
                  className='svg-icon'
                  path={toAbsoluteUrl('/media/icons/Custom/Dustbin.svg')}
                />
              </span>
              <Modal
                show={show}
                onHide={() => {
                  setShow(false)
                  setDeleteId('')
                }}
                aria-labelledby='contained-modal-title-vcenter'
                centered
              >
                <Modal.Header>
                  <Modal.Title>Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you wanna delete?</Modal.Body>
                <Modal.Footer>
                  <button
                    className='btn fw-bolder btn-gray-200 fs-6 dialogButton'
                    onClick={() => {
                      setShow(false)
                      setDeleteId('')
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className='btn create-btn fs-6 dialogButton ms-2'
                    onClick={() => handleDelete(deleteId)}
                  >
                    Delete
                  </button>
                </Modal.Footer>
              </Modal>
            </div>
          ))}
        {documentList.length == 0 && (
          <div className='flex flex-column w-100 align-items-center' style={{height: '300px'}}>
            <img src={toAbsoluteUrl('/media/logos/bar-chart 1.png')} alt='' />
            <h2 className='m20'>No Documents Added</h2>
          </div>
        )}
      </div>
      <form
        className='CompanyDetails addDocument'
        noValidate
        id='kt_login_signup_form'
        onSubmit={formik.handleSubmit}
      >
        <h3 className='m-0'>Add New Page</h3>
        <p className='text-gray-400 m-0 fs-7'>Pages will be visible to all the app users. </p>
        <div className=' m24'>
          <h6 className='fw-bold required'>Page Name</h6>
          <input
            {...formik.getFieldProps('page')}
            className={clsx(
              'form-control text-gray-500 border-0 mb-10 bg-gray-100',
              {
                'is-invalid': formik.touched.page && formik.errors.page,
              },
              {
                'is-valid': formik.touched.page && !formik.errors.page,
              }
            )}
            type='text'
          />
          {formik.touched.page && formik.errors.page && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span className='text-danger' role='alert'>
                  {formik.errors.page}
                </span>
              </div>
            </div>
          )}
          <Editor
            editorState={editorState}
            wrapperClassName='card border'
            editorClassName='card-body bg-gray-100 m-2 h-400px'
            onEditorStateChange={(newState) => {
              setEditorState(newState)
              setContent(draftToHtml(convertToRaw(newState.getCurrentContent())))
            }}
            toolbar={{
              options: [
                'inline',
                'blockType',
                'fontSize',
                'list',
                'textAlign',
                'history',
                'embedded',
                'emoji',
                'image',
              ],
              inline: {inDropdown: true},
              list: {inDropdown: true},
              textAlign: {inDropdown: true},
              link: {inDropdown: true},
              history: {inDropdown: true},
            }}
          />
        </div>
        <div className='d-flex m32 pe-5'>
          <button
            type='button'
            className='btn fw-bolder btn-gray-200 CompanyDetailButton'
            onClick={resetForm}
          >
            Cancel
          </button>
          <button
            type='submit'
            id='kt_sign_up_submit'
            className='btn create-btn CompanyDetailButton ms-5'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && <span className='indicator-label'>Add Page</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}
