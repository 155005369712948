import { SearchOutlined } from '@ant-design/icons'
import { Input, message, Pagination } from 'antd'
import Moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap-v5'
import { shallowEqual, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { RootState } from '../../setup/redux/RootReducer'
import httpService from '../../setup/http/httpServices'
import { KTSVG, toAbsoluteUrl } from '../../_metronic/helpers'
import { IUserModel } from '../modules/auth/models/UserModel'
import './basic.css'
export function Table(props: any) {
  const user: IUserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as IUserModel

  const [sort1, setSort1] = useState('rotate-90 ms-2')
  const [asc, setAsc] = useState('companyDetails.companyName')
  const [sort2, setSort2] = useState('rotate-90 ms-2')
  const [sort4, setSort4] = useState('rotate-90 ms-2')
  const [show, setShow] = useState(false)
  const [status, setStatus] = useState(true)
  const history: any = useHistory()

  const handleClose = () => setShow(false)
  // const Data = useMemo(() => MockData, [])
  const [agencyList, setAgencyList] = useState([])
  const [agencyListParams, setAgencyListParams] = useState({
    limit: 10,
    page: 1,
    total: 0,
    search: '',
  })
  const pageType = props.type
  const [totalCount, setTotalCount] = useState(0)
  const [deleteId, setDeleteId] = useState('')

  const key = 'updatable'
  const handleDelete = (id: string) => {
    setShow(false)
    message.loading({ content: 'Loading...', key })
    httpService.deleteData(
      '/' + pageType.toLowerCase() + '-master/' + deleteId,
      (res: any) => {
        setTimeout(() => {
          message.success({ content: pageType + ' deleted!', key, duration: 2 })
          setDeleteId('')
        }, 1000)
      },
      (err: any) => {
        message.error({ content: err.message, key, duration: 2 })
        setDeleteId('')
      }
    )
  }

  const exportData = () => {
    const type = pageType === 'Agency' ? 'agency' : 'brand'
    httpService.downloadFile(
      '/export/' + type + '-master',
      type + ".xlsx",
      (err) => {
        console.log(err);
      }
    )
  }
  const editBrandOrAgency = (id: string) => {
    httpService.getData(
      '/' + pageType.toLowerCase() + '-master/' + id,
      (res: any) => {
        let resp = res.data.data[pageType.toLowerCase()]
        let agencyDetailsResponse = resp.agencyDetails ? resp.agencyDetails : resp.brandDetails
        let companyDetailsResponse = resp.companyDetails
        let miscellaneousResponse = resp.miscellaneous
        let companyDetails = {}
        if (companyDetailsResponse) {
          companyDetails = {
            agencyAssociated: companyDetailsResponse.agencyAssociated,
            companyName: companyDetailsResponse.companyName,
            gstNumber: companyDetailsResponse.gstNumber,
            companyDescription: companyDetailsResponse.description,
            companyContactPerson: companyDetailsResponse.contactPerson,
            companyEmail: companyDetailsResponse.email,
            companyPhoneNumber: companyDetailsResponse.phoneNumber,
            companyWebsite: companyDetailsResponse.website,
            companyBillingAddress: companyDetailsResponse.billingAddress,
            companyCity: companyDetailsResponse.city,
            companyState: companyDetailsResponse.state,
            companyPinCode: companyDetailsResponse.pinCode,
          }
        }

        history.push({
          pathname: '/companyDetails',
          state: {
            companyDetails: companyDetails,
            agencyDetails: agencyDetailsResponse,
            miscellaneous: miscellaneousResponse,
            _id: resp._id,
            type: history.location.state?.type,
          },
        })
      },
      (err: any) => {
        message.error(err.message)
      }
    )
  }
  const viewBrandOrAgency = (data: any) => {
    data.agencyDetails
      ? history.push({
        pathname: '/master/profile',
        state: { id: data._id, type: 'AgencyView', name: data?.agencyDetails?.agencyName },
      })
      : history.push({
        pathname: '/master/profile',
        state: { id: data._id, type: 'BrandView', name: data.brandDetails?.brandName },
      })
  }
  const Sorting1 = () => {
    if (sort1 == 'rotate-90 ms-2') {
      setSort1('rotate-270 ms-2')
    } else {
      setSort1('rotate-90 ms-2')
    }
    if (sort1 == 'rotate-90 ms-2') {
      setSort4('rotate-90 ms-2')
      setSort2('rotate-90 ms-2')
      setAsc('-companyDetails.companyName')
    } else {
      setSort4('rotate-90 ms-2')
      setSort2('rotate-90 ms-2')
      setAsc('companyDetails.companyName')
    }
  }
  const Sorting2 = () => {
    if (sort2 == 'rotate-90 ms-2') {
      setSort2('rotate-270 ms-2')
    } else {
      setSort2('rotate-90 ms-2')
    }
    if (sort2 == 'rotate-90 ms-2') {
      setSort1('rotate-90 ms-2')
      setSort4('rotate-90 ms-2')
      setAsc('-isActive')
    } else {
      setSort1('rotate-90 ms-2')
      setSort4('rotate-90 ms-2')
      setAsc('isActive')
    }
  }
  const Sorting4 = () => {
    if (sort4 == 'rotate-90 ms-2') {
      setSort4('rotate-270 ms-2')
    } else {
      setSort4('rotate-90 ms-2')
    }
    if (sort4 == 'rotate-90 ms-2') {
      setSort1('rotate-90 ms-2')
      setSort2('rotate-90 ms-2')
      setAsc('-updatedAt')
    } else {
      setSort1('rotate-90 ms-2')
      setSort2('rotate-90 ms-2')
      setAsc('updatedAt')
    }
  }
  useEffect(() => {
    getAllBrands()
  }, [deleteId, agencyListParams.page, agencyListParams.search, pageType, asc])
  const getAllBrands = () => {
    let url = '/' + pageType.toLowerCase() +
      '-master';
    url += '?limit=' + agencyListParams.limit +
      '&page=' + agencyListParams.page +
      '&search=' + agencyListParams.search +
      '&sort=' + asc;
    httpService.getData(
      url,
      (response: any) => {
        let agencyResponse = response.data.data
        setAgencyList(agencyResponse.agencies ? agencyResponse.agencies : agencyResponse.brands)
        setTotalCount(agencyResponse.total)
        setStatus(false)
        // setAgencyListParams({
        //   limit: agencyResponse.limit,
        //   page: agencyResponse.page,
        //   total: agencyResponse.total,
        //   search: agencyListParams.search,
        // })
      },
      (error: any) => {
        message.error(error.message)
        setStatus(false)
      }
    )
  }
  return (
    <>
      <div className='TableHeader'>
        <div className='mt-3 min-w-150px'>
          <h3 style={{ cursor: 'default' }}>{pageType}</h3>
          <p style={{ cursor: 'default' }} className='text-gray-500 fs-7'>
            {totalCount} active {pageType}
          </p>
        </div>
        <div className='TableHeaderSearch d-flex align-items-center'>
          <link
            href='https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css'
            rel='stylesheet'
          />
          <Input
            placeholder='Search'
            style={{ width: '284px', height: '41px', borderRadius: '6px' }}
            className='form-control-solid bg-gray-100'
            onChange={(e: any) => {
              const params = { ...agencyListParams }
              params.search = e.target.value
              setAgencyListParams({ ...params })
              getAllBrands()
            }}
            prefix={
              <>
                <SearchOutlined />
              </>
            }
          />
        </div>
        {/* <div className='flex'>
            <span
              style={{width: '31px', height: '31px'}}
              className='svg-icon flex svg-icon-primary bg-primary bg-opacity-5 me-3 rounded pointer'
            >
              <KTSVG path={toAbsoluteUrl('/media/icons/Custom/Filter.svg')} />
            </span>
          </div> */}
        <div className='flex' onClick={() => { exportData() }}>
          <span
            style={{ width: '31px', height: '31px' }}
            className='svg-icon rounded flex svg-icon-primary bg-primary bg-opacity-5 me-3 pointer'
          >
            <KTSVG path={toAbsoluteUrl('/media/icons/Custom/Export.svg')} />
          </span>
        </div>
        {(user.permissions.masters[1] || user.permissions.admin || user.userType === 'agency') && (
          <div className='flex'>
            <Link
              to={{
                pathname: '/companyDetails',
                state: { type: pageType },
              }}
            >
              {' '}
              <button
                style={{
                  width: 'max-content',
                  height: '34px',
                  fontSize: '14px',
                  padding: '8px 16px',
                }}
                className='btn create-btn d-flex flex me-2'
              >
                {pageType === 'Agency' ? 'Add New Agency 🤝' : 'Add New Brand 😍'}
              </button>
            </Link>
          </div>
        )}
      </div>
      <div className='card-body mt-0 p-0 d-flex flex-column justify-content-between'>
        {/* begin::Table container */}
        <div className='table-responsive' style={{overflowX: "unset"}}>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder text-muted'>
                {/* <th className='bg-primary bg-opacity-5  w-25px'>
                    <div className='form-check ms-5 form-check-sm form-check-custom form-check-solid'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value='1'
                        data-kt-check='true'
                        data-kt-check-target='.widget-9-check'
                      />
                    </div>
                  </th> */}
                <th className='bg-primary bg-opacity-5 min-w-190px'>
                  <span className='d-flex ms-8'>
                    {pageType} Name
                    <a
                      className={sort1}
                      onClick={() => {
                        Sorting1()
                      }}
                    >
                      <KTSVG
                        className='svg-icon svg-icon-sm svg-icon-gray-700 flex h-20px pointer'
                        path={toAbsoluteUrl('/media/icons/Custom/SideArrow.svg')}
                      />
                    </a>
                  </span>
                </th>
                <th className='bg-primary bg-opacity-5 min-w-180px'>
                  {pageType === 'Brand' ? 'Agency' : ''}
                </th>
                <th className='bg-primary bg-opacity-5 min-w-120px'>
                  <span className='d-flex'>Campaigns</span>
                </th>
                <th className='bg-primary bg-opacity-5 min-w-110px'>
                  <span className='d-flex'>Influencers</span>
                </th>
                {pageType === 'Agency' && (
                  <th className='bg-primary bg-opacity-5 min-w-110px'>
                    <span className='d-flex'>
                      Status
                      <a
                        className={sort2}
                        onClick={() => {
                          Sorting2()
                        }}
                      >
                        <KTSVG
                          className='svg-icon svg-icon-sm svg-icon-gray-700 flex h-20px pointer'
                          path={toAbsoluteUrl('/media/icons/Custom/SideArrow.svg')}
                        />
                      </a>
                    </span>
                  </th>
                )}
                <th className='bg-primary Added bg-opacity-5 min-w-150px'>
                  <span className='d-flex'>
                    Added
                    <a
                      className={sort4}
                      onClick={() => {
                        Sorting4()
                      }}
                    >
                      <KTSVG
                        className='svg-icon svg-icon-sm svg-icon-gray-700 flex h-20px pointer'
                        path={toAbsoluteUrl('/media/icons/Custom/SideArrow.svg')}
                      />
                    </a>
                  </span>
                </th>
                {(user.permissions.masters[1] || user.permissions.admin || user.userType === 'agency') && (
                  <th className='bg-primary bg-opacity-5 min-w-100px d-flex justify-content-end'>
                    Actions
                  </th>
                )}
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {agencyList.length > 0 &&
                agencyList.map((data: any, index: number) => (
                  <tr key={index}>
                    {/* <td>
                        <div className='form-check ms-5 form-check-sm form-check-custom form-check-solid'>
                          <input
                            className='form-check-input widget-9-check'
                            type='checkbox'
                            value='1'
                          />
                        </div>
                      </td> */}
                    <td className='fw-bolder'>
                      <div
                        className='d-flex align-items-center ms-8  cursor-pointer'
                        onClick={() => {
                          // if(pageType === 'Agency')
                          viewBrandOrAgency(data)
                        }}
                      >
                        <div className='symbol symbol-35px symbol-circle me-5'>
                          {/* {data?.agencyDetails &&<img src={data?.agencyDetails.avatar} alt='' />} */}
                          {props.type === 'Brand' && (
                            <img src={data?.brandDetails?.avatar} alt='' />
                          )}
                          {props.type === 'Agency' && (
                            <img src={data?.agencyDetails?.avatar} alt='' />
                          )}
                         
                        </div>
                        <div className='d-flex justify-content-start flex-column'>
                          <span className='text-dark fw-bolder text-hover-primary fs-6'>
                            {props.type === 'Agency' &&
                              data?.agencyDetails &&
                              data?.agencyDetails.agencyName}
                            {props.type === 'Brand' &&
                              data?.brandDetails &&
                              data?.brandDetails.brandName
                              }
                             
                          </span>
                          <span className='text-muted fw-bold text-muted d-block fs-7'>
                            {props.type === 'Agency' &&
                              (data?.agencyDetails?.city && data?.agencyDetails?.city + ', ') + (data?.agencyDetails?.state && data?.agencyDetails?.state)}
                            {props.type === 'Brand' &&
                              (data?.brandDetails?.city && data?.brandDetails?.city + ', ') + (data?.brandDetails?.state && data?.brandDetails?.state)}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td className='fw-bolder'>
                      {pageType === 'Brand'
                        ? data?.companyDetails?.agencyAssociated?.agencyDetails?.agencyName
                        : ''}
                    </td>
                    <td className='fw-bolder'>NA</td>
                    <td>
                      -
                      {/* <div className='symbol-group symbol-hover flex-shrink-0 me-2'>
                          <div className='symbol symbol-circle symbol-35px border-0'>
                            <div className='symbol-label fw-bolder bg-warning text-inverse-warning'>
                              A
                            </div>
                          </div>
                          <div className='symbol symbol-circle symbol-35px border-0'>
                            <img src={toAbsoluteUrl('/media/avatars/150-2.jpg')} alt='' />
                          </div>
                          <div className='symbol symbol-circle symbol-35px border-0'>
                            <img src={toAbsoluteUrl('/media/avatars/150-3.jpg')} alt='' />
                          </div>
                          <div className='symbol symbol-circle symbol-35px border-0'>
                            <div className='symbol-label fw-bolder bg-info text-inverse-primary'>
                              S
                            </div>
                          </div>
                          <div className='symbol symbol-circle symbol-35px border-0'>
                            <div className='symbol-label fw-bolder bg-gray'>+5</div>
                          </div>
                        </div> */}
                    </td>
                    {pageType === 'Agency' && (
                      <td className='fw-bolder'>{data.isActive ? 'Active' : 'Inactive'}</td>
                    )}
                    <td className='Added'>
                      <span className='text-dark fw-bolder text-hover-primary d-block fs-6'>
                        {Moment(data?.updatedAt).format('DD MMMM YYYY')}
                      </span>
                      <span className='text-muted fw-bold text-muted d-block fs-7'>
                        {data?.modifiedBy?.firstName + ' ' + data?.modifiedBy?.lastName}
                      </span>
                    </td>
                    {(user.permissions.masters[1] || user.permissions.admin || user.userType === 'agency') && (
                      <td className='d-flex justify-content-end'>
                        <div className='d-flex justify-content-start flex-shrink-0'>
                          <span
                            className='svg-icon flex svg-icon-dark bg-primary bg-opacity-5 w-30px h-30px rounded me-2 pointer'
                            onClick={() => viewBrandOrAgency(data)}
                          >
                            <KTSVG
                              className='svg-icon-3'
                              path={toAbsoluteUrl('/media/icons/Custom/eye.svg')}
                            />
                          </span>
                          <span
                            className='svg-icon flex svg-icon-dark bg-primary bg-opacity-5 w-30px h-30px rounded me-2 pointer'
                            onClick={() => editBrandOrAgency(data?._id)}
                          >
                            <KTSVG
                              className='svg-icon-3'
                              path={toAbsoluteUrl('/media/icons/Custom/Pen.svg')}
                            />
                          </span>
                          <span
                            onClick={() => {
                              setShow(true)
                              setDeleteId(data?._id)
                            }}
                            className='svg-icon flex svg-icon-danger bg-danger bg-opacity-5 w-30px h-30px rounded pointer'
                          >
                            <KTSVG
                              className='svg-icon-3'
                              path={toAbsoluteUrl('/media/icons/Custom/Dustbin.svg')}
                            />
                          </span>
                          <Modal
                            show={show}
                            onHide={handleClose}
                            aria-labelledby='contained-modal-title-vcenter'
                            centered
                          >
                            <Modal.Header>
                              <Modal.Title>Delete</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>Are you sure you wanna delete?</Modal.Body>
                            <Modal.Footer>
                              <button
                                className='btn fw-bolder btn-gray-200 fs-6 dialogButton'
                                onClick={() => {
                                  setShow(false)
                                  setDeleteId('')
                                }}
                              >
                                Cancel
                              </button>
                              <button
                                className='btn create-btn fs-6 dialogButton ms-2'
                                onClick={() => handleDelete(deleteId)}
                              >
                                Delete
                              </button>
                            </Modal.Footer>
                          </Modal>
                        </div>
                      </td>
                    )}
                  </tr>
                ))}
              {agencyList.length === 0 && !status && (
                <tr>
                  <td colSpan={7}>
                    <div className='m-20'>
                      <div className='flex'>
                        <img
                          className='ms-20'
                          src={toAbsoluteUrl('/media/logos/TableVector.png')}
                        ></img>
                      </div>
                      <div className='flex'>
                        <div>
                          <h2 className='mb-0 m24 flex'>No {pageType} Listed</h2>
                        </div>
                      </div>
                      <div className='flex'>
                        <Link
                          to={{
                            pathname: '/companyDetails',
                            state: { type: pageType },
                          }}
                        >
                          {(user.permissions.masters[1] || user.permissions.admin) && (
                            <button className='btn m24 TableButton create-btn text-white'>
                              {pageType === 'Agency' ? 'Add New Agency 🤝' : 'Add New Brand 😍'}
                            </button>
                          )}
                        </Link>
                      </div>
                    </div>
                  </td>
                </tr>
              )}
              {agencyList.length === 0 && status && (
                <tr>
                  <td colSpan={7}>
                    <div className='m-20'>
                      <div className='flex'>
                        <span className='svg-icon svg-icon-5x flex svg-icon-primary me-3 ms-8'>
                          <KTSVG path={toAbsoluteUrl('/media/icons/Custom/Loading.svg')} />
                        </span>
                      </div>
                      <div className='flex'>
                        <div>
                          <h5 className='mb-0 mt-3 flex'>Fetching Data</h5>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>

            {/* end::Table body */}
          </table>

          {/* end::Table */}
        </div>
        <div className='d-flex ms-5 me-10 justify-content-between align-items-end flex-wrap mb-10'>
          <span className='text-muted pb-2'>
            Page {agencyListParams.page} of{' '}
            {Math.floor(totalCount % agencyListParams.limit) === 0
              ? Math.floor(totalCount / agencyListParams.limit)
              : Math.floor(totalCount / agencyListParams.limit) + 1}
          </span>
          <div className='d-flex flex-wrap '>
            <Pagination
              className=''
              pageSize={agencyListParams.limit}
              defaultCurrent={agencyListParams.page}
              total={totalCount}
              onChange={(e) => setAgencyListParams({ ...agencyListParams, ...{ page: e } })}
            />
          </div>
        </div>
        {/* end::Table container */}
      </div>
    </>
  )
}
