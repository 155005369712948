import { DatePicker, message, Tooltip } from 'antd'
import 'bootstrap/dist/js/bootstrap.bundle.js'
import clsx from 'clsx'
import { useFormik } from 'formik'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import Select from 'react-select'
import * as Yup from 'yup'
import { toAbsoluteUrl } from '../../_metronic/helpers'
import { RootState } from '../../setup'
import httpService from '../../setup/http/httpServices'
import '../masters/basic.css'
import { IUserModel } from '../modules/auth/models/UserModel'
import { CampaignLeftBreadcrum } from './CampaignLeftBreadcrum'

var initialValues = {
  name: '',
  agencyMaster: '',
  brandMaster: '',
  description: '',
  startDate: moment().add(0, 'days').startOf('day'),
  endDate: moment().add(1, 'days').endOf('day'),
  liveDate: moment().add(2, 'days').startOf('day'),
  campaignEndDate: moment().add(3, 'days').endOf('day'),
  noOfInfluencers: '',
  influencerCategories: [],
  type: '',
  minBarterValue: '',
  maxBarterValue: '',
  mode: '',
  platform: '',
}

const registrationSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Campaign Name is required')
    .matches(/^(?=.*[A-Za-z]).{3,50}$/, 'Should not contain just numbers'),
  // agencyMaster: Yup.string().required('Agency name is required'),
  brandMaster: Yup.string().required('Brand name is required'),
  description: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(500, 'Maximum 500 characters')
    .required('Description is required'),
  campaignEndDate: Yup.string().required('Campaign End Date is required'),
  liveDate: Yup.string().required('Campaign Live Date is required'),
  startDate: Yup.string().required('Campaign Start Date is required'),
  endDate: Yup.string().required('Campaign End Date is required'),
  noOfInfluencers: Yup.string()
    .min(1, 'Minimum 1 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('No of Influencer is required'),
  influencerCategories: Yup.array().required('Influencer Category is required'),
  type: Yup.string().required('Campaign Type is required'),
  minBarterValue: Yup.string()
    .min(1, 'Minimum 1 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Barter Value is required'),
  maxBarterValue: Yup.string()
    .min(1, 'Minimum 1 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Barter Value is required'),
  mode: Yup.string().required('Campaign Type is required'),
  platform: Yup.string().required('Platform is required'),
})

export function CreateCampaign() {
  const history: any = useHistory()
  const [loading, setLoading] = useState(false)
  const [campaignDetails, setCampaignDetails] = useState(initialValues)
  const [brands, setBrands] = useState([])
  const [agencies, setAgencies] = useState([])
  const [categories, setCategories] = useState([])
  const [editId, setEditId] = useState(null)
  const [agency, setAgency] = useState<any>(null)
  const [brand, setBrand] = useState<any>(null)
  const [category, setCategory] = useState(null)
  const user: IUserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as IUserModel

  const formik = useFormik({
    initialValues: campaignDetails || initialValues,
    validationSchema: registrationSchema,
    validate: (values) => {
      console.log(values)
      let errors: any = {}
      if (
        values.minBarterValue &&
        values.maxBarterValue &&
        values.minBarterValue > values.maxBarterValue
      ) {
        errors.minBarterValue = 'Invalid Barter Range.'
      }
      return errors
    },
    enableReinitialize: true,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setTimeout(() => {
        try {
          setCampaignDetails(values)
          setLoading(false)
          setSubmitting(false)
          let request: any = values
          // delete request.minBarterValue;
          // delete request.maxBarterValue;
          request['barterValue'] = {
            min: values.minBarterValue,
            max: values.maxBarterValue,
          }
          request.influencerCategories = request.influencerCategories.map((x) => (x = x._id))
          if (values.mode === 'Online') {
            request.shippingMethod = 'selfCheckout'
          } else if (values.mode === 'Offline') {
            request.shippingMethod = 'delivery'
          }
          request.brandMaster = brand.value
          request.brandName = brand.label
          if (agency?.value !== '') {
            request.agencyMaster = agency?.value
            request.agencyName = agency?.label
          } else {
            delete request.agencyMaster
            delete request.agencyName
          }
          request.startDate =
            moment(formik.values.startDate).format('yyyy-MM-DDTHH:mm:ss.SSS') + 'Z'
          request.endDate = moment(formik.values.endDate).format('yyyy-MM-DDTHH:mm:ss.SSS') + 'Z'
          request.liveDate = moment(formik.values.liveDate).format('yyyy-MM-DDTHH:mm:ss.SSS') + 'Z'
          request.campaignEndDate =
            moment(formik.values.campaignEndDate).format('yyyy-MM-DDTHH:mm:ss.SSS') + 'Z'
          if (editId) {
            let updateRequest: any = {
              campaignDetails: request,
              audienceDetails: history.location.state?.audienceDetails,
              otherDetails: history.location.state?.otherDetails,
            }
            if (updateRequest?.audienceDetails?.ageGroup && updateRequest?.audienceDetails?.ageGroup.length === 0) {
              delete updateRequest.audienceDetails;
            }
            // if(history.location.state?.instagramInfluencerDetails){
            //   updateRequest.instagramInfluencerDetails = history.location.state?.instagramInfluencerDetails
            // }
            // else if(history.location.state?.youtubeInfluencerDetails) {
            //   updateRequest.youtubeInfluencerDetails = history.location.state?.youtubeInfluencerDetails
            // }
            httpService.patchData(
              '/campaign-master/' + editId,
              updateRequest,
              (response: any) => {
                message.success('Campaign details updated successfully')
                history.push({
                  pathname: '/campaign/influencerDetails',
                  state: {
                    ...history.location.state,
                    ...{
                      campaignDetails: values,
                      _id: response.data.data.campaign._id,
                      type: history.location.state?.type,
                    },
                  },
                })
              },
              (error: any) => {
                message.error(error.message)
              }
            )
          } else {
            httpService.postData(
              '/campaign-master',
              {
                campaignDetails: request,
              },
              (response: any) => {
                message.success('Campaign details saved successfully')
                history.push({
                  pathname: '/campaign/influencerDetails',
                  state: {
                    ...history.location.state,
                    ...{
                      campaignDetails: values,
                      _id: response.data.data.campaign._id,
                      type: history.location.state?.type,
                    },
                  },
                })
              },
              (error: any) => {
                message.error(error.message)
              }
            )
          }
        } catch {
          setLoading(false)
          setSubmitting(false)
          setStatus('Registration process has broken')
        }
      }, 1000)
    },
  })
  function disabledApplicationOpenDate(current) {
    // Can not select days before today and today
    return current && current < moment().startOf('day')
  }
  function disabledApplicationCloseDate(current) {
    // Can not select days before today and today
    return current && current < moment(formik.values['startDate']).add(1, 'day')
  }
  function disabledCampaignLiveDate(current) {
    // Can not select days before today and today
    return current && current < moment(formik.values['endDate']).endOf('day')
  }
  function disabledCampaignEndDate(current) {
    // Can not select days before today and today
    return current && current < moment(formik.values['liveDate']).add(1, 'day')
  }
  useEffect(() => {
    setEditId(history.location.state?._id)
    getAllMasters()
  }, [])
  const campaignDetail = history.location.state?.campaignDetails
  useEffect(() => {
    try {
      campaignDetail.maxBarterValue = campaignDetail.barterValue.max
      campaignDetail.minBarterValue = campaignDetail.barterValue.min
      setCampaignDetails(campaignDetail)
      formik.setFieldValue('type', campaignDetail?.type)
      formik.setFieldValue('mode', campaignDetail?.mode)
      formik.setFieldValue('platform', campaignDetail?.platform)
      debugger
      if (campaignDetail) {
        if (campaignDetail?.influencerCategories) {
          if (typeof campaignDetail?.influencerCategories[0] === 'string') {
            const selectedCategory: any = categories.filter((x: any) =>
              campaignDetail?.influencerCategories.includes(x._id)
            )
            setCategory(selectedCategory)
          } else if (typeof campaignDetail?.influencerCategories[0] === 'object') {
            const selectedCategory: any = categories.filter((x: any) =>
              campaignDetail?.influencerCategories.find((y) => y._id === x._id)
            )
            setCategory(selectedCategory)
          }
        }
      }
    } catch {}
  }, [history.location.state?.campaignDetails, categories])

  const getAllMasters = () => {
    httpService.getData(
      '/brand-master',
      (response: any) => {
        response.data.data.brands.map((x: any) => {
          x.value = x._id
          x.label = x?.brandDetails?.brandName ? x.brandDetails.brandName : null
          return x
        })

        setBrands(response.data.data.brands)

        if (campaignDetail?.brandMaster?.brandDetails) {
          campaignDetail.brandName = campaignDetail.brandMaster.brandDetails.brandName
          campaignDetail.brandMaster = campaignDetail.brandMaster._id
        }
        if (user.userType === 'brand') {
          let currentBrand = response.data.data.brands.find((x) => x._id === user.brandId)
          setBrand(currentBrand)
          formik.setFieldValue('brandMaster', user.brandId)
        } else {
          setBrand({
            label: campaignDetail.brandName,
            value: campaignDetail.brandMaster,
          })
        }
      },
      (error: any) => {
        if (error?.message) message.error(error.message)
      }
    )
    httpService.getData(
      '/agency-master',
      (response: any) => {
        response.data.data.agencies.map((x: any) => {
          x.value = x._id
          x.label = x?.agencyDetails?.agencyName ? x.agencyDetails.agencyName : null
          return x
        })
        if (campaignDetail?.agencyMaster?.agencyDetails) {
          campaignDetail.agencyName = campaignDetail.agencyMaster.agencyDetails.agencyName
          campaignDetail.agencyMaster = campaignDetail.agencyMaster._id
        }
        setAgencies([{value: '', label: 'Sehaye Internal'}].concat(response.data.data.agencies))
        if (user.userType === 'agency') {
          let currentAgency = response.data.data.agencies.find((x) => x._id === user.agencyId)
          setAgency(currentAgency)
        } else if (campaignDetail?.agencyMaster)
          setAgency({
            label: campaignDetail.agencyName,
            value: campaignDetail.agencyMaster,
          })
      },
      (error: any) => {
        if (error?.message) message.error(error.message)
      }
    )
    httpService.getData(
      '/category-master',
      (response: any) => {
        response.data.data.categories.map((x: any) => {
          x.value = x._id
          x.label = x.categoryName ? x.categoryName : null
          return x
        })
        setCategories(response.data.data.categories)
      },
      (error: any) => {
        if (error?.message) message.error(error.message)
      }
    )
    httpService.getData(
      '/agency-master',
      (response: any) => {
        response.data.data.agencies.map((x: any) => {
          x.value = x._id
          x.label = x?.agencyDetails?.agencyName ? x.agencyDetails.agencyName : null
          return x
        })
        setAgencies([{value: '', label: 'Sehaye Internal'}].concat(response.data.data.agencies))
        if (campaignDetail) {
          if (campaignDetail?.agencyMaster)
            if (user.userType === 'agency') {
              let currentAgency = response.data.data.agencies.find((x) => x._id === user.agencyId)
              setAgency(currentAgency)
            } else {
              setAgency({
                label: campaignDetail.agencyName,
                value: campaignDetail.agencyMaster,
              })
            }
          if (user.userType === 'brand') {
            let currentBrand = brands.find((x) => x._id === user.brandId)
            setBrand(currentBrand)
          } else {
            setBrand({
              label: campaignDetail.brandName,
              value: campaignDetail.brandMaster,
            })
          }
        }
      },
      (error: any) => {
        if (error?.message) message.error(error.message)
      }
    )
    httpService.getData(
      '/category-master',
      (response: any) => {
        response.data.data.categories.map((x: any) => {
          x.value = x._id
          x.label = x?.categoryName ? x.categoryName : null
          return x
        })
        setCategories(response.data.data.categories)
      },
      (error: any) => {
        if (error?.message) message.error(error.message)
      }
    )
  }
  useEffect(() => {
    getAllMasters()
  }, [])
  const getBrand = () => {
    httpService.getData(
      '/brand-master?limit=0',
      (response: any) => {
        let list = [{value: '', label: 'Select Brand'}]
        response.data.data.brands.map((x: any) => {
          if (agency && agency.value && agency.value === x?.companyDetails?.agencyAssociated?._id) {
            list.push({value: x._id, label: x.brandDetails.brandName})
            return x
          } else if (!agency || agency?.label === 'Sehaye Internal' || agency?.value === '') {
            x.value = x._id
            x.label = x?.brandDetails?.brandName ? x.brandDetails.brandName : null
            if (!x?.companyDetails?.agencyAssociated) {
              list.push({value: x._id, label: x.brandDetails.brandName})
            }
            return x
          }
        })
        if (user.userType === 'brand') {
          let currentbrand = list.find((x) => x.value === user.brandId)
          setBrand(currentbrand)
        }
        setBrands(list)
      },
      (error: any) => {}
    )
  }
  useEffect(() => {
    getBrand()
  }, [agency])
  // function onChange(date: any, dateString: string) {
  //   initialValues.liveDate = dateString
  // }
  // function onChange1(date: any, dateString: string) {
  //   initialValues.startDate = dateString
  // }
  // function onChange2(date: any, dateString: string) {
  //   initialValues.endDate = dateString
  // }
  return (
    <div className='CompanyDetailsOuter'>
      <CampaignLeftBreadcrum currentStep='1' />
      <form
        className='CompanyDetails campaignDetails'
        noValidate
        id='kt_login_signup_form'
        onSubmit={formik.handleSubmit}
      >
        <h2 className='mb-0 '>Create Campaign Overview</h2>

        <div className='col m32'>
          <h6 className='fw-bold required'>Campaign Name</h6>
          <input
            type='text'
            placeholder='Example: "Your brand name" - New Product Launch'
            {...formik.getFieldProps('name')}
            className={clsx(
              'form-control form-control-solid bg-gray-100',
              {
                'is-invalid': formik.touched.name && formik.errors.name,
              },
              {
                'is-valid': formik.touched.name && !formik.errors.name,
              }
            )}
          />
          {formik.touched.name && formik.errors.name && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span className='text-danger' role='alert'>
                  {formik.errors.name}
                </span>
              </div>
            </div>
          )}
        </div>
        <div className='flex m24'>
          {user.userType !== 'brand' && (
            <div className='col me-7'>
              <h6 className='fw-bold'>Agency Name</h6>
              <Select
                options={agencies}
                value={agency}
                isMulti={false}
                isDisabled={
                  !user.permissions.admin &&
                  (user.userType === 'brand' || user.userType === 'agency')
                }
                name='agencyMaster'
                placeholder={
                  !user.permissions.admin && user.userType === 'brand' ? '' : 'Sehaye Internal'
                }
                styles={{
                  menuList: (provided, state) => ({
                    ...provided,
                    height: 'auto',
                    maxHeight: '200px',
                  }),
                }}
                className={clsx(
                  'text-gray-500 form-control form-control-solid bg-gray-100 p-0 border-0 categorySelect',
                  {
                    'is-invalid': formik.touched.agencyMaster && formik.errors.agencyMaster,
                  },
                  {
                    'is-valid': formik.touched.agencyMaster && !formik.errors.agencyMaster,
                  }
                )}
                onChange={(e: any) => {
                  formik.setFieldValue('agencyMaster', e.value)
                  setAgency(e)
                }}
              />
              {formik.touched.agencyMaster && formik.errors.agencyMaster && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='text-danger' role='alert'>
                      {formik.errors.agencyMaster}
                    </span>
                  </div>
                </div>
              )}
            </div>
          )}
          <div className='col '>
            <h6 className='fw-bold required'>Brand Name</h6>
            <Select
              options={brands}
              value={brand}
              isMulti={false}
              name='brandMaster'
              placeholder='Select Brand'
              isDisabled={!user.permissions.admin && user.userType === 'brand'}
              styles={{
                menuList: (provided, state) => ({
                  ...provided,
                  height: 'auto',
                  maxHeight: '200px',
                }),
              }}
              className={clsx(
                'text-gray-500 form-control form-control-solid bg-gray-100 p-0 border-0 categorySelect',
                {
                  'is-invalid': formik.touched.brandMaster && formik.errors.brandMaster,
                },
                {
                  'is-valid': formik.touched.brandMaster && !formik.errors.brandMaster,
                }
              )}
              onChange={(e: any) => {
                formik.setFieldValue('brandMaster', e.value)
                setBrand(e)
              }}
            />
            {formik.touched.brandMaster && formik.errors.brandMaster && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='text-danger' role='alert'>
                    {formik.errors.brandMaster}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='m24'>
          <h6 className='fw-bold required '>Campaign Description</h6>
          <div className='col '>
            <textarea
              placeholder='Example: "Your brand" is launching a new tinted sunscreen with no white cast and improved SPF formula, made for everyday use!

              Include product name, links and other basic information:
              "Your Brand Name - Tinted Sunscreen"
              www.yourbrandname.com/newproductlaunch'
              {...formik.getFieldProps('description')}
              className={clsx(
                'form-control form-control-solid bg-gray-100 h-150px',
                {
                  'is-invalid': formik.touched.description && formik.errors.description,
                },
                {
                  'is-valid': formik.touched.description && !formik.errors.description,
                }
              )}
            />
            {formik.touched.description && formik.errors.description && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='text-danger' role='alert'>
                    {formik.errors.description}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='flex m24'>
          <div className='col me-7'>
            <h6 className='fw-bold required'>Applications Open on</h6>
            <DatePicker
              {...formik}
              placeholder='Start Date'
              allowClear={false}
              name='startDate'
              value={moment(formik.values['startDate'], 'YYYY/MM/DD')}
              className={clsx(
                'border-0 form-control form-control-solid w-100 bg-gray-100 border-radius',
                {
                  'is-invalid': formik.touched.startDate && formik.errors.startDate,
                },
                {
                  'is-valid': formik.touched.startDate && !formik.errors.startDate,
                }
              )}
              onChange={(date, dateString) => {
                formik.setFieldValue('startDate', dateString)
                if (new Date(formik.getFieldProps('endDate').value) <= new Date(dateString)) {
                  message.warn('This might affect other campaigns dates too')
                  formik.setFieldValue('endDate', moment(dateString).add(1, 'days').endOf('day'))
                }
                if (new Date(formik.getFieldProps('liveDate').value) <= new Date(dateString)) {
                  formik.setFieldValue('liveDate', moment(dateString).add(2, 'days').startOf('day'))
                }
                if (
                  new Date(formik.getFieldProps('campaignEndDate').value) <= new Date(dateString)
                ) {
                  formik.setFieldValue(
                    'campaignEndDate',
                    moment(dateString).add(3, 'days').endOf('day')
                  )
                }
              }}
              disabledDate={(current) => {
                // Disable dates before the current date
                return current && current < moment().startOf('day');
              }}
            />
            {formik.touched.startDate && formik.errors.startDate && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='text-danger' role='alert'>
                    {formik.errors.startDate}
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className='col me-7'>
            <h6 className='fw-bold required'>Application Close on</h6>
            <DatePicker
              // {...formik}
              value={moment(formik.values['endDate'], 'YYYY/MM/DD')}
              disabledDate={disabledApplicationCloseDate}
              name='endDate'
              allowClear={false}
              className={clsx(
                'border-0 form-control form-control-solid w-100 bg-gray-100 border-radius',
                {
                  'is-invalid': formik.touched.endDate && formik.errors.endDate,
                },
                {
                  'is-valid': formik.touched.endDate && !formik.errors.endDate,
                }
              )}
              onChange={(date, dateString) => {
                formik.setFieldValue('endDate', dateString)
                if (new Date(formik.getFieldProps('liveDate').value) <= new Date(dateString)) {
                  message.warn('This might affect other campaigns dates too')
                  formik.setFieldValue('liveDate', moment(dateString).add(1, 'days').startOf('day'))
                }
                if (
                  new Date(formik.getFieldProps('campaignEndDate').value) <= new Date(dateString)
                ) {
                  formik.setFieldValue(
                    'campaignEndDate',
                    moment(dateString).add(2, 'days').endOf('day')
                  )
                }
              }}
            />
            {formik.touched.endDate && formik.errors.endDate && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='text-danger' role='alert'>
                    {formik.errors.endDate}
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className='col me-7'>
            <h6 className='fw-bold required'>Campaign Live Date</h6>
            <DatePicker
              {...formik}
              placeholder='Campaign Live Date'
              disabledDate={disabledCampaignLiveDate}
              allowClear={false}
              name='liveDate'
              value={moment(formik.values['liveDate'], 'YYYY/MM/DD')}
              className={clsx(
                'border-0 form-control form-control-solid w-100 bg-gray-100 border-radius',
                {
                  'is-invalid': formik.touched.liveDate && formik.errors.liveDate,
                },
                {
                  'is-valid': formik.touched.liveDate && !formik.errors.liveDate,
                }
              )}
              onChange={(date, dateString) => {
                formik.setFieldValue('liveDate', dateString)
                if (
                  new Date(formik.getFieldProps('campaignEndDate').value) <= new Date(dateString)
                ) {
                  message.warn('This might affect other campaigns dates too')
                  formik.setFieldValue('campaignEndDate', dateString)
                }
              }}
            />

            {formik.touched.liveDate && formik.errors.liveDate && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='text-danger' role='alert'>
                    {formik.errors.liveDate}
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className='col'>
            <h6 className='fw-bold required'>Campaign End Date</h6>
            <DatePicker
              {...formik}
              placeholder='Campaign End Date'
              disabledDate={disabledCampaignEndDate}
              allowClear={false}
              name='campaignEndDate'
              value={moment(formik.values['campaignEndDate'], 'YYYY/MM/DD')}
              className={clsx(
                'border-0 form-control form-control-solid w-100 bg-gray-100 border-radius',
                {
                  'is-invalid': formik.touched.campaignEndDate && formik.errors.campaignEndDate,
                },
                {
                  'is-valid': formik.touched.campaignEndDate && !formik.errors.campaignEndDate,
                }
              )}
              onChange={(date, dateString) =>{
                const startDate = moment(formik.values['startDate'], 'YYYY/MM/DD');
      const endDate = moment(dateString, 'YYYY/MM/DD');
       // Check if the end date is before the start date
       if (endDate.isBefore(startDate)) {
        formik.setFieldError('campaignEndDate', 'Campaign End Date must be after Start Date');
      } else {
                formik.setFieldValue('campaignEndDate', dateString)}
      }}
            />

            {formik.touched.campaignEndDate && formik.errors.campaignEndDate && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='text-danger' role='alert'>
                    {formik.errors.campaignEndDate}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='flex m24'>
          <div className='col me-7'>
            <h6 className='fw-bold required'>Number of Influencers required</h6>
            <input
              type='number'
              placeholder='Enter an approximate number'
              {...formik.getFieldProps('noOfInfluencers')}
              className={clsx(
                'form-control form-control-solid bg-gray-100',
                {
                  'is-invalid': formik.touched.noOfInfluencers && formik.errors.noOfInfluencers,
                },
                {
                  'is-valid': formik.touched.noOfInfluencers && !formik.errors.noOfInfluencers,
                }
              )}
            />
            {formik.touched.noOfInfluencers && formik.errors.noOfInfluencers && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='text-danger' role='alert'>
                    {formik.errors.noOfInfluencers}
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className='col '>
            <h6 className='fw-bold required'>Influencer Categories</h6>
            <Select
              options={categories}
              value={category}
              isMulti={true}
              name='influencerCategories'
              placeholder='Select category'
              styles={{
                menuList: (provided, state) => ({
                  ...provided,
                  height: 'auto',
                  maxHeight: '200px',
                }),
              }}
              className={clsx(
                'text-gray-500 form-control form-control-solid bg-gray-100 p-0 border-0 categorySelect',
                {
                  'is-invalid':
                    formik.touched.influencerCategories && formik.errors.influencerCategories,
                },
                {
                  'is-valid':
                    formik.touched.influencerCategories && !formik.errors.influencerCategories,
                }
              )}
              onChange={(e: any) => {
                formik.setFieldValue('influencerCategories', e)
                setCategory(e)
              }}
            />
            {formik.touched.influencerCategories && formik.errors.influencerCategories && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='text-danger' role='alert'>
                    {formik.errors.influencerCategories}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='flex m24'>
          <div className='col'>
            <h6 className='fw-bold required'>Campaign Type</h6>
            <div className='flex form-check form-check-custom form-check-solid mt-5'>
              <label className='w-100 me-10'>
                <input
                  {...formik.getFieldProps('type')}
                  className={clsx(
                    'form-check-input w-25px h-25px',
                    {
                      'is-invalid': formik.touched.type && formik.errors.type,
                    },
                    {
                      'is-valid': formik.touched.type && !formik.errors.type,
                    }
                  )}
                  type='radio'
                  value='Barter'
                  checked={formik.values.type === 'Barter'}
                  id='flexRadioDefault'
                />
                <label className='fs-6 ms-1 mt-1'>Barter</label>
              </label>
              <Tooltip placement='top' title='Coming soon' color='orange'>
                <label className='w-100'>
                  <input
                    {...formik.getFieldProps('type')}
                    className={clsx(
                      'form-check-input w-25px h-25px',
                      {
                        'is-invalid': formik.touched.type && formik.errors.type,
                      },
                      {
                        'is-valid': formik.touched.type && !formik.errors.type,
                      }
                    )}
                    type='radio'
                    value='Monetary'
                    checked={formik.values.type === 'Monetary'}
                    id='flexRadioDefault'
                    disabled={true}
                  />
                  <label className='fs-6 ms-1 mt-1'>Monetary</label>
                </label>
              </Tooltip>
            </div>

            {formik.touched.type && formik.errors.type && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='text-danger' role='alert'>
                    {formik.errors.type}
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className='col'>
            <h6 className='fw-bold required'>Barter Range (In Rs)</h6>
            <input
              type='number'
              placeholder='Minimum Value'
              {...formik.getFieldProps('minBarterValue')}
              min={500}
              className={clsx(
                'form-control form-control-solid bg-gray-100',
                {
                  'is-invalid': (formik.touched.minBarterValue && formik.errors.minBarterValue) ||
                  (formik.touched.minBarterValue && Number(formik.values.minBarterValue) < 500),

                },
                {
                  'is-valid': formik.touched.minBarterValue && !formik.errors.minBarterValue && Number(formik.values.minBarterValue) >= 500,
                }
              )}
            />
            {/* {formik.touched.minBarterValue && formik.errors.minBarterValue && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='text-danger' role='alert'>
                    {formik.errors.minBarterValue}
                  </span>
                </div>
              </div>
            )} */}

{formik.touched.minBarterValue && (
    <div className='fv-plugins-message-container'>
      {Number(formik.values.minBarterValue) < 500 && (
        <div className='fv-help-block'>
          <span className='text-danger' role='alert'>
            Value cannot be less than 500.
          </span>
        </div>
      )}
      {formik.errors.minBarterValue && (
        <div className='fv-help-block'>
          <span className='text-danger' role='alert'>
            {formik.errors.minBarterValue}
          </span>
        </div>
      )}
    </div>
  )}
          </div>
          <div className='col ms-5'>
            <h6 className='fw-bold'>&nbsp;</h6>
            <input
              type='number'
              placeholder='Maximum Value'
              {...formik.getFieldProps('maxBarterValue')}
              className={clsx(
                'form-control form-control-solid bg-gray-100',
                {
                  'is-invalid': formik.touched.maxBarterValue && formik.errors.maxBarterValue,
                },
                {
                  'is-valid': formik.touched.maxBarterValue && !formik.errors.maxBarterValue,
                }
              )}
            />
            {formik.touched.maxBarterValue && formik.errors.maxBarterValue && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='text-danger' role='alert'>
                    {formik.errors.maxBarterValue}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='flex m24'>
          <div className='col me-7'>
            <h6 className='fw-bold required'>Mode of Barter</h6>
            <div className='d-flex form-check form-check-custom form-check-solid mt-5'>
              <label className='me-10'>
                <input
                  {...formik.getFieldProps('mode')}
                  className={clsx(
                    'form-check-input w-25px h-25px',
                    {
                      'is-invalid': formik.touched.mode && formik.errors.mode,
                    },
                    {
                      'is-valid': formik.touched.mode && !formik.errors.mode,
                    }
                  )}
                  type='radio'
                  value='Online'
                  checked={formik.values.mode === 'Online'}
                  id='flexRadioDefault'
                />
                <label className='fs-6 ms-1 mt-1'>Online (Digitally redeemable code)</label>
              </label>
              <label>
                <input
                  {...formik.getFieldProps('mode')}
                  className={clsx(
                    'form-check-input w-25px h-25px',
                    {
                      'is-invalid': formik.touched.mode && formik.errors.mode,
                    },
                    {
                      'is-valid': formik.touched.mode && !formik.errors.mode,
                    }
                  )}
                  type='radio'
                  value='Offline'
                  checked={formik.values.mode === 'Offline'}
                  id='flexRadioDefault'
                />
                <label className='fs-6 ms-1 mt-1'>Offline (Delivery to doorstep)</label>
              </label>
            </div>

            {formik.touched.mode && formik.errors.mode && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='text-danger' role='alert'>
                    {formik.errors.mode}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='flex m24'>
          <div className='col me-7'>
            <h6 className='fw-bold required'>Platform</h6>

            <div className='flex m16' data-kt-buttons='true'>
              <label className='btn form-control form-control-solid bg-gray-100 d-flex flex-stack text-start p-4 me-7'>
                <div className='d-flex align-items-center me-2'>
                  <div className='ms-2 me-2'>
                    <img src={toAbsoluteUrl('/media/logos/Youtube.png')} />
                  </div>
                  <div className='flex-grow-1'>
                    <div className='fs-6'>Youtube</div>
                  </div>
                </div>
                <div className='form-check form-check-custom form-check-solid form-check-primary ms-5'>
                  <input
                    {...formik.getFieldProps('platform')}
                    className={clsx(
                      'form-check-input',
                      {
                        'is-invalid': formik.touched.platform && formik.errors.platform,
                      },
                      {
                        'is-valid': formik.touched.platform && !formik.errors.platform,
                      }
                    )}
                    type='radio'
                    value='Youtube'
                    checked={formik.values.platform === 'Youtube'}
                    id='flexRadioDefault'
                    name='platform'
                  />
                </div>
              </label>
              <label className='btn form-control form-control-solid bg-gray-100 d-flex flex-stack text-start p-4'>
                <div className='d-flex align-items-center me-2'>
                  <div className='ms-2 me-2'>
                    <img src={toAbsoluteUrl('/media/logos/Instagram.png')} />
                  </div>
                  <div className='flex-grow-1'>
                    <div className='fs-6'>Instagram</div>
                  </div>
                </div>
                <div className='form-check form-check-custom form-check-solid form-check-primary ms-5'>
                  <input
                    {...formik.getFieldProps('platform')}
                    className={clsx(
                      'form-check-input',
                      {
                        'is-invalid': formik.touched.platform && formik.errors.platform,
                      },
                      {
                        'is-valid': formik.touched.platform && !formik.errors.platform,
                      }
                    )}
                    type='radio'
                    value='Instagram'
                    checked={formik.values.platform === 'Instagram'}
                    id='flexRadioDefault'
                    name='platform'
                  />
                </div>
              </label>
            </div>
            {formik.touched.platform && formik.errors.platform && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='text-danger' role='alert'>
                    {formik.errors.platform}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='d-flex m32 pe-5'>
          <Link
            to={{
              pathname: '/campaign/dashboard',
              state: {type: 'campaign/dashboard', filter: 'All'},
            }}
          >
            <button type='button' className='btn fw-bolder btn-gray-200 CompanyDetailButton'>
              Go Back
            </button>
          </Link>
          <button
            type='submit'
            id='kt_sign_up_submit'
            className='btn create-btn CompanyDetailButton ms-5'
            // disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && <span className='indicator-label'>Save & Continue</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}
