import { message } from 'antd'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import httpService from '../../setup/http/httpServices'
import '../masters/basic.css'
import { TransactionLeftBreadcrum } from './TransactionLeftBreadcrum'

export function TransactionAmount() {
  const history: any = useHistory()

  const [priceMaster, setPriceMaster] = useState<any>([])
  const [CGST, setCGST] = useState<number>(0)
  const [SGST, setSGST] = useState<number>(0)
  const [subTotal, setSubTotal] = useState<number>(0)
  const [total, setTotal] = useState<number>(0)
  const [couponCode, setCouponCode] = useState('')
  const [coupons, setCoupons] = useState([])
  const [discount, setDiscount] = useState(null)
  const [invalidCoupon, setInvalidCoupon] = useState(false)
  var transactionAmountDetail1 = history.location.state?.transactionAmountDetails

  useEffect(() => {
    httpService.getData(
      '/coupon',
      (response: any) => {
        setCoupons(response.data.data.coupons)
      },
      (error: any) => {}
    )
    if (history.location.state?.transactionAmountDetails) {
      transactionAmountDetail1 = history.location.state?.transactionAmountDetails
      setPriceMaster(transactionAmountDetail1.priceMaster)
      const subtotal: number = transactionAmountDetail1.priceMaster.reduce(
        (total: any, number: any) => {
          return parseInt(total) + parseInt(number.qty) * parseInt(number.costPerInfluencer)
        },
        0
      )
      setSubTotal(subtotal)
      setCGST(transactionAmountDetail1.cgst)
      setSGST(transactionAmountDetail1.sgst)
      setTotal(transactionAmountDetail1.total)
      setCouponCode(transactionAmountDetail1.couponCode)
    } else {
      httpService.getData(
        '/price-master',
        (response: any) => {
          let priceMaster: any = response.data.data.priceMasters.filter(
            (x: any) =>
              x.socialMedia.socialMediaName ===
              history.location.state.transactionInfoDetails.platform
          )
          priceMaster.map((x: any) => {
            x.qty = 0
          })
          addPrice(priceMaster)
          setPriceMaster(priceMaster)
          setCouponCode(transactionAmountDetail1.couponCode)
        },
        (error: any) => {
          message.error(error.message)
        }
      )
    }
  }, [history.location.state?.transactionAmountDetails])

  const addPrice = (priceMaster: any) => {
    priceMaster.push({
      bucketName: 'Service ' + (priceMaster.filter((x: any) => x.type).length + 1),
      costPerInfluencer: 0,
      qty: 1,
      type: 'custom',
    })
    setPriceMaster([...priceMaster])
  }

  const handleSubmit = () => {
    if (total <= 0) {
      message.error('Total value of transaction can not be zero')
      return
    }
    let coupon
    if (couponCode) {
      coupon = coupons.find((x) => x.code === couponCode)._id
    }
    history.push({
      pathname: '/transaction/payment',
      state: {
        ...history.location.state,
        ...{
          transactionAmountDetails: {
            priceMaster: priceMaster,
            sgst: SGST,
            cgst: CGST,
            total: total,
          },
        },
        coupon: coupon,
        discount: discount,
      },
    })
  }
  const setPriceData = (value: any, key: string, index: number) => {
    const tempBucket = [...priceMaster]
    tempBucket[index][key] = value
    setPriceMaster(tempBucket)
    let subtotal: number = priceMaster.reduce((total: any, number: any) => {
      return parseInt(total) + parseInt(number.qty) * parseInt(number.costPerInfluencer)
    }, 0)
    setSubTotal(subtotal)
    setCGST(subtotal * 0.09)
    setSGST(subtotal * 0.09)
    setTotal(subtotal + subtotal * 0.09 * 2)
  }

  return (
    <div className='CompanyDetailsOuter'>
      <TransactionLeftBreadcrum currentStep='2' type={history.location.state?.type} />
      <div className='CompanyDetails' id='kt_login_signup_form'>
        <h2 className='m-0'>Enter Billing Information</h2>
        <div className='m24 d-flex'>
          <div className='col fw-bolder flex justify-content-start'>Influencer Category</div>
          <div className='col fw-bolder flex justify-content-end me-5'>Influencer Count</div>
          <span className='text-gray-400'>x</span>
          <div className='col fw-bolder flex justify-content-start ms-5'>Barter Value</div>
          <div className='col fw-bolder flex justify-content-end'>Amount</div>
        </div>
        <span className='separator border-gray-200 m16'></span>

        {priceMaster.map((bucket: any, index: number) => (
          <React.Fragment>
            <div className='col m16 d-flex'>
              <div
                className={
                  bucket.type === 'custom'
                    ? 'col fw-bold flex justify-content-start'
                    : 'col fw-bold flex justify-content-start required'
                }
              >
                {bucket.type === 'custom' ? (
                  <div className='col flex justify-content-start '>
                    <input
                      type='text'
                      value={bucket.bucketName}
                      onChange={(e) => setPriceData(e.target.value, 'bucketName', index)}
                      placeholder={'Service Name  '}
                      className={clsx('form-control form-control-solid bg-gray-100 w-200px h-35px')}
                    />
                  </div>
                ) : (
                  bucket.bucketName
                )}
              </div>
              <div className='col flex justify-content-end me-5'>
                <input
                  type='number'
                  disabled={bucket.type === 'custom'}
                  onChange={(e) => setPriceData(e.target.value, 'qty', index)}
                  value={bucket.qty}
                  className={clsx('form-control form-control-solid bg-gray-100 w-95px h-35px')}
                />
              </div>
              <span className='text-gray-400 flex'>
                {bucket.type !== 'custom' ? 'x' : <div>&nbsp;</div>}{' '}
              </span>
              <div className='col flex justify-content-start ms-5'>
                <input
                  type='number'
                  // {...formik.getFieldProps('microValue')}
                  onChange={(e) => setPriceData(e.target.value, 'costPerInfluencer', index)}
                  value={bucket.costPerInfluencer}
                  disabled={bucket.type !== 'custom'}
                  className={clsx('form-control form-control-solid bg-gray-100 w-125px h-35px')}
                />
              </div>

              <div className='col fw-bold flex justify-content-end'>
                <span placeholder='200'>Rs. {bucket.qty * bucket.costPerInfluencer}</span>
              </div>
            </div>
          </React.Fragment>
        ))}
        <div className='col m16 d-flex'>
          <button className='service-btn' type='button' onClick={() => addPrice(priceMaster)}>
            +Add Custom Service
          </button>
        </div>
        <span className='separator border-gray-200 m16'></span>
        <div className='m16 d-flex'>
          <div className='col fw-bolder flex justify-content-start'>Subtotal</div>
          <div className='col flex justify-content-end me-5 fw-bolder'>
            {priceMaster.reduce((total: any, number: any) => {
              return total + parseInt(number.qty)
            }, 0)}
          </div>
          <span className='text-gray-400 flex'></span>
          <div className='col flex justify-content-start ms-5'></div>

          <div className='col fw-bold flex justify-content-end'>
            <span placeholder='0000'>Rs. {subTotal}</span>
          </div>
        </div>
        <span className='separator border-gray-200 m16'></span>
        <h6 className='m16'>Taxes</h6>
        <div className='m16 d-flex'>
          <div className='col fw-bold flex justify-content-start '>CGST(9%)</div>
          <div className='col fw-bold flex justify-content-end'>
            <span placeholder='0000'>Rs. {CGST}</span>
          </div>
        </div>
        <div className='m16 d-flex'>
          <div className='col fw-bold flex justify-content-start'>SGST(9%)</div>
          <div className='col fw-bold flex justify-content-end'>
            <span placeholder='0000'>Rs. {SGST}</span>
          </div>
        </div>
        <span className='separator border-gray-200 m16'></span>
        <div className='m16 d-flex align-items-baseline'>
          <span className='fs-16'>Coupon Code</span>
          <span style={{marginLeft: '34px', marginRight: '8px', width: '180px'}}>
            <input
              className='form-control'
              type='text'
              placeholder='SEHAYE2022'
              value={couponCode}
              onChange={(e: any) => {
                setCouponCode(e.target.value)
              }}
            />

            {invalidCoupon && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='text-danger fs-9 fw-bolder' role='alert'>
                    Coupon is invalid. Try another
                  </span>
                </div>
              </div>
            )}
          </span>
          <button
            className='btn create-btn'
            onClick={() => {
              const coupon = coupons.find((x) => x.code === couponCode)
              if (coupon) {
                setInvalidCoupon(false)
                const subtotal: number = priceMaster.reduce((total: any, number: any) => {
                  return parseInt(total) + parseInt(number.qty) * parseInt(number.costPerInfluencer)
                }, 0)
                const gstValue = subtotal * 0.09
                setSGST(gstValue)
                setCGST(gstValue)
                const fintotal = subtotal + gstValue * 2
                if (coupon.minimumSpend > fintotal) {
                  setDiscount(0)
                  setTotal(fintotal)
                } else if (coupon.percentageValue) {
                  setDiscount((fintotal * coupon.percentageValue) / 100)
                  setTotal(fintotal - (fintotal * coupon.percentageValue) / 100)
                } else if (coupon.moneyValue) {
                  setDiscount(coupon.moneyValue)
                  setTotal(fintotal - coupon.moneyValue)
                }
              } else {
                setInvalidCoupon(true)
                const subtotal: number = priceMaster.reduce((total: any, number: any) => {
                  return parseInt(total) + parseInt(number.qty) * parseInt(number.costPerInfluencer)
                }, 0)
                const gstValue = subtotal * 0.09
                setSGST(gstValue)
                setCGST(gstValue)
                const fintotal = subtotal + gstValue * 2
                setDiscount(0)
                setTotal(fintotal)
              }
            }}
          >
            Apply
          </button>
        </div>
        {couponCode && !coupons.find((x) => x.code === couponCode) ? (
          <div className='fw-600 fs-5' style={{color: 'red'}}>
            Coupon is invalid. Try another
          </div>
        ) : (
          couponCode &&
          coupons.find((x) => x.minimumSpend > total) && (
            <div className='fw-600 fs-5' style={{color: 'red'}}>
              Spend atleast Rs. {coupons.find((x) => x.minimumSpend > total).minimumSpend} to apply
              this coupon.
            </div>
          )
        )}
        <span className='separator border-gray-200 m16'></span>
        <div className='m16 d-flex'>
          <div className='col fw-bolder fs-3 flex justify-content-start'>Total</div>
          <div className='col fw-bold flex justify-content-end'>
            <span className='fs-3' placeholder='0000'>
              Rs. {total}
            </span>
          </div>
        </div>
        <div className='d-flex m24'>
          <Link
            to={{
              pathname: '/transaction/add',
              state: {
                ...history.location.state,
                ...{
                  transactionAmountDetails: {
                    couponCode: couponCode,
                    priceMaster: priceMaster,
                    sgst: SGST,
                    cgst: CGST,
                    total: total,
                  },
                },
              },
            }}
          >
            <button type='button' className='btn fw-bolder btn-gray-200 CompanyDetailButton'>
              Back
            </button>
          </Link>
          <button
            type='submit'
            id='kt_sign_up_submit'
            onClick={handleSubmit}
            className='btn create-btn ms-5 CompanyDetailButton'
          >
            <span className='indicator-label'>Continue</span>
          </button>
        </div>
      </div>
    </div>
  )
}
