import React from 'react';
import '../../masters/basic.css'

export function ChartBar(props:any) {
  let barFillHeight = '0%';

  if (props.maxValue > 0) {
    barFillHeight = Math.round((props.value / props.maxValue) * 100) + '%';
  }

  return (
    <div className='chart-bar'>
      <div className='chart-bar__inner'>
        <div
          className='chart-bar__fill'
          style={{ height: barFillHeight, backgroundColor: props.bgColor}}
        ></div>
      </div>
      <div className='chart-bar__label fs-8 text-gray-600'>{props.label}</div>
    </div>
  );
}
