import { message } from 'antd'
import clsx from 'clsx'
import { ContentState, convertFromHTML, convertToRaw, EditorState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { Accordion, Modal } from 'react-bootstrap'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { shallowEqual, useSelector } from 'react-redux'
import Select from 'react-select'
import * as Yup from 'yup'
import httpService from '../../setup/http/httpServices'
import { RootState } from '../../setup/redux/RootReducer'
import { KTSVG, toAbsoluteUrl } from '../../_metronic/helpers'
import '../masters/basic.css'
import { IUserModel } from '../modules/auth/models/UserModel'

var initialValues = {
  tags: '',
  question: '',
  answer: '',
}

const registrationSchema = Yup.object().shape({
  question: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Issue is required'),
})

export function FAQ() {
  const user: IUserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as IUserModel
  const access = user.permissions.adminSettings[1] || user.permissions.admin

  const [loading, setLoading] = useState(false)
  const [FAQDetails, setFAQDetails] = useState(initialValues)
  const [visible, setVisible] = useState<any>([])
  const [content, setContent] = useState<string>('')
  const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty())
  const [selectedTags, setSelectedTags] = useState<any>([])
  const [tags, setTags] = useState<any>([
    { value: 'Transaction', label: 'Transaction' },
    { value: 'Master', label: 'Master' },
  ])
  const [show, setShow] = useState(false)
  const [faqList, setFAQList] = useState([])
  const [deleteId, setDeleteId] = useState('')

  useEffect(() => {
    getFAQList()
  }, [])

  const getFAQList = () => {
    httpService.getData(
      '/faq',
      (response: any) => {
        setFAQList(response.data.data.faqs)
      },
      (error: any) => {
        message.error(error.message)
      }
    )
  }

  const formik = useFormik({
    initialValues: FAQDetails || initialValues,
    validationSchema: registrationSchema,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      setTimeout(() => {
        try {
          let request = {
            question: values.question,
            answer: content,
            tags: selectedTags.map((x: any) => x.value),
          }
          if (deleteId) {
            httpService.patchData(
              '/faq/' + deleteId,
              request,
              (response: any) => {
                formik.resetForm()
                setSelectedTags([])
                setEditorState(EditorState.createEmpty())
                setShow(false)
                setDeleteId('')
                getFAQList()
                message.success('FAQ updated successfully.')
              },
              (error: any) => {
                message.error(error.message)
              }
            )
          } else {
            httpService.postData(
              '/faq',
              request,
              (response: any) => {
                formik.resetForm()
                setSelectedTags([])
                setEditorState(EditorState.createEmpty())
                setShow(false)
                setDeleteId('')
                message.success('FAQ added successfully.')
                getFAQList()
              },
              (error: any) => {
                message.error(error.message)
              }
            )
          }
          setLoading(false)
          setSubmitting(false)
        } catch {
          setLoading(false)
          setSubmitting(false)
          setStatus('Registration process has broken')
        }
      }, 1000)
    },
  })

  const editFAQ = (id: string) => {
    formik.resetForm()
    setSelectedTags([])
    setEditorState(EditorState.createEmpty())
    httpService.getData(
      '/faq/' + id,
      (response: any) => {
        setShow(true)
        setDeleteId(id)
        formik.setFieldValue('question', response.data.data.faq.question)
        let tags: any[] = []
        response.data.data.faq.tags.forEach((x: string) => {
          tags.push({ label: x, value: x })
        })
        setSelectedTags(tags)
        const content = convertFromHTML(response.data.data.faq.answer)
        setEditorState(
          EditorState.createWithContent(
            ContentState.createFromBlockArray(content.contentBlocks, content.entityMap)
          )
        )
      },
      (error: any) => {
        message.error(error.message)
      }
    )
  }

  const handleDelete = (id: string) => {
    setShow(false)
    const key = 'updatable'
    message.loading({ content: 'Loading...', key })
    httpService.deleteData(
      '/faq/' + id,
      (res: any) => {
        setTimeout(() => {
          message.success({ content: 'FAQ deleted!', key, duration: 2 })
          setDeleteId('')
        }, 1000)
        getFAQList()
      },
      (err: any) => {
        message.error(err.message)
      }
    )
  }
  return (
    <div className='flex'>
      <div className='basicDiv w-75 pt-6'>
        <div className='d-flex'>
          <div className='col'>
            <h3 className='m-0'>Frequently Asked Questions</h3>
            <p className='fs-7 text-gray-400'>{faqList.length} FAQs</p>
          </div>
          <div className='col jEnd'>
            {access && (
              <button
                onClick={() => {
                  setShow(true)
                }}
                className='btn create-btn d-flex flex justify-content-center me-2 h-35px fs-7 ms-3'
              >
                Add New FAQ ❔
              </button>
            )}
          </div>
        </div>
        {faqList && faqList.length > 0 && (
          <Accordion>
            {faqList.map((faq: any, index: number) => (
              <Accordion.Item eventKey={index.toString()} className='border-0'>
                <Accordion.Header
                  className='d-flex'
                // onClick={(x: any) => handleClick(index)}
                >
                  {/* <div className='form-check me-2 form-check-sm form-check-custom form-check-solid'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    value='1'
                    data-kt-check='true'
                    data-kt-check-target='.widget-9-check'
                  />
                </div> */}
                  <p className='m-0 fs-5 fw-bold col'>{faq.question}</p>
                  {!visible.includes(index) && (
                    <div className='jEnd col'>
                      <span
                        className='svg-icon flex svg-icon-dark bg-primary bg-opacity-5 w-30px h-30px rounded me-2 pointer'
                        onClick={() => editFAQ(faq?._id)}
                      >
                        <KTSVG
                          className='svg-icon-3'
                          path={toAbsoluteUrl('/media/icons/Custom/Pen.svg')}
                        />
                      </span>
                      {/* <span
                      className='svg-icon flex svg-icon-dark bg-primary bg-opacity-5 w-30px h-30px rounded me-2 pointer'
                    // onClick={() => editBrandOrAgency(data?._id)}
                    >
                      <KTSVG
                        className='svg-icon-3'
                        path={toAbsoluteUrl('/media/icons/Custom/Setting.svg')}
                      />
                    </span> */}
                      <span
                        className='svg-icon flex svg-icon-danger bg-danger bg-opacity-5 w-30px h-30px rounded pointer me-2'
                        onClick={() => handleDelete(faq._id)}
                      >
                        <KTSVG
                          className='svg-icon-3'
                          path={toAbsoluteUrl('/media/icons/Custom/Dustbin.svg')}
                        />
                      </span>
                    </div>
                  )}
                </Accordion.Header>
                <Accordion.Body className='bg-gray-200'>
                  {/* {faq.answer} */}
                  <div dangerouslySetInnerHTML={{ __html: faq.answer }} />
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        )}
        {faqList.length == 0 && (
          <div className='w-100'>
            <div className='flex'>
              <img src={toAbsoluteUrl('/media/logos/Finance.png')}></img>
            </div>
            <div className='flex'>
              <div>
                <h2 className='m24 flex'>No FAQs Listed</h2>
              </div>
            </div>
          </div>
        )}
      </div>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        aria-labelledby='contained-modal-title-vcenter '
        centered
      >
        <Modal.Body className='p-0 '>
          <div style={{ width: '830px' }} className='bg-white rounded'>
            <div className='w-100 bg-white rounded p-10'>
              <form noValidate id='kt_login_signup_form' onSubmit={formik.handleSubmit}>
                <div className='col'>
                  <h6 className='fw-bold required'>Question</h6>
                  <input
                    type='text'
                    placeholder='Question'
                    {...formik.getFieldProps('question')}
                    className={clsx(
                      'form-control form-control-solid bg-gray-100',
                      {
                        'is-invalid': formik.touched.question && formik.errors.question,
                      },
                      {
                        'is-valid': formik.touched.question && !formik.errors.question,
                      }
                    )}
                  />
                  {formik.touched.question && formik.errors.question && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span className='text-danger' role='alert'>
                          {formik.errors.question}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
                <div className=' m24'>
                  <h6 className='fw-bold required'>Answer</h6>
                  <Editor
                    editorState={editorState}
                    wrapperClassName='card border'
                    onEditorStateChange={(newState) => {
                      setEditorState(newState)
                      setContent(draftToHtml(convertToRaw(newState.getCurrentContent())))
                    }}
                    editorClassName='card-body bg-gray-100 m-2 h-300px'
                    toolbar={{
                      options: [
                        'inline',
                        'blockType',
                        'fontSize',
                        'list',
                        'textAlign',
                        'history',
                        'embedded',
                        'emoji',
                        'image',
                      ],
                      inline: { inDropdown: true },
                      list: { inDropdown: true },
                      textAlign: { inDropdown: true },
                      link: { inDropdown: true },
                      history: { inDropdown: true },
                    }}
                  />
                  {formik.touched.answer && formik.errors.answer && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span className='text-danger' role='alert'>
                          {formik.errors.answer}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
                <div className='col m24'>
                  <h6 className='fw-bold required'>Tags</h6>
                  <Select
                    options={tags}
                    className={clsx(
                      'form-control form-control-solid bg-gray-100 p-0 border-0 categorySelect',
                      {
                        'is-invalid': formik.touched.tags && formik.errors.tags,
                      },
                      {
                        'is-valid': formik.touched.tags && !formik.errors.tags,
                      }
                    )}
                    value={selectedTags}
                    isMulti={true}
                    onChange={(e: any) => setSelectedTags(e)}
                  />
                  {formik.touched.tags && formik.errors.tags && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span className='text-danger' role='alert'>
                          {formik.errors.tags}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
                <div className='d-flex m24 w-100'>
                  <button
                    onClick={() => {
                      formik.resetForm()
                      setSelectedTags([])
                      setEditorState(EditorState.createEmpty())
                      setDeleteId('')
                      setShow(false)
                    }}
                    className='btn fw-bolder btn-gray-200 w-100 h-50px'
                  >
                    Cancel
                  </button>

                  <button
                    type='submit'
                    id='kt_sign_up_submit'
                    className='btn create-btn ms-5 w-100 h-50px'
                    disabled={formik.isSubmitting || !formik.isValid}
                  >
                    {!loading && (
                      <span className='indicator-label'>
                        {deleteId ? 'Update Ticket' : 'Add Ticket'}
                      </span>
                    )}
                    {loading && (
                      <span className='indicator-progress' style={{ display: 'block' }}>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
