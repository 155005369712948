import { message } from 'antd'
import React from 'react'
import { useHistory } from 'react-router-dom'
import httpService from '../../../setup/http/httpServices'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import '../Dashboard.scss'
import { NoData } from './NoData'

export function Influencers(props: any) {
  const history: any = useHistory()
  const handleView = (id: string) => {
    httpService.getData(
      '/influencer-master/' + id,
      (res: any) => {
        res.data.data.influencer.additionalInfo.interestedCategories =
          res.data.data.influencer.additionalInfo.interestedCategories.join(',')
        history.push({
          pathname: '/master/influencerProfile',
          state: {
            influencerBasicInfo: res.data.data.influencer.basicInfo,
            influencerAdditinalInfo: res.data.data.influencer.additionalInfo,
            influencerSocialDetails: res.data.data.influencer.socialMediaDetails,
            influencerStats: res.data.data.influencer.stats,
            _id: res.data.data.influencer._id,
            type: 'influencerProfile',
          },
        })
      },
      (err: any) => {
        message.error({ content: err.message })
      }
    )
  }
  return (
    <div className='Dcard Dinfluencers'>
      <h2 className='Dheading'>Top Influencers</h2>
      {props.data?.length === 0 ? (
        <NoData />
      ) : (
        <div className='table-responsive'>
          <table className='table table-row-dashed table-row align-middle gs-0 gy-4 m-0'>
            <thead>
              <tr className='fw-bolder text-muted'>
                <th style={{ width: '500px' }}>
                  <span className='d-flex'>Influencers</span>
                </th>
                <th style={{ width: '100px' }}>
                  <span className='d-flex'>Platform</span>
                </th>
                <th>
                  <span className='d-flex'>Eng, Rate</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {props.data?.map((item, index) => (
                <tr key={index} className=''>
                  <td className='fw-bolder pointer' onClick={() => handleView(item._id)}>
                    <img
                      className='Davatar'
                      src={item.basicInfo?.avatar ? item.basicInfo.avatar : toAbsoluteUrl('/media/avatars/blank.png')}
                      alt=''
                    />

                    {/* <img
                      className='Davatar'
                      src={item.influencer.avatar}
                      alt=''
                    /> */}

                    {item.basicInfo.name}
                  </td>
                  <td className='fw-normal'>
                    <img src={toAbsoluteUrl('/media/logos/Instagram.png')} alt='' />
                  </td>
                  <td className='fw-normal'>
                    {(item.socialMediaDetails?.instagram?.basicStats?.engagementRate * 100).toFixed(2)}%
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  )
}
