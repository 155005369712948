import { message } from 'antd'
import { ContentState, convertFromHTML, convertToRaw, EditorState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap-v5'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import {shallowEqual, useSelector} from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import { RootState } from '../../setup'
import httpService from '../../setup/http/httpServices'
import { toAbsoluteUrl } from '../../_metronic/helpers'
import '../masters/basic.css'
import { IUserModel } from '../modules/auth/models/UserModel'
import { CampaignLeftBreadcrum } from './CampaignLeftBreadcrum'

var initialValues = {
  content1: '',
  content2: '',
  content3: '',
  content4: '',
}

const registrationSchema = Yup.object().shape({})

export function OtherDetails() {

  const user: IUserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as IUserModel
  const [content1, setContent1] = useState<string>('')
  const [content2, setContent2] = useState<string>('')
  const [content3, setContent3] = useState<string>('')
  const [content4, setContent4] = useState<string>('')
  const [editorState1, setEditorState1] = useState<EditorState>(EditorState.createEmpty())
  const [editorState2, setEditorState2] = useState<EditorState>(EditorState.createEmpty())
  const [editorState3, setEditorState3] = useState<EditorState>(EditorState.createEmpty())
  const [editorState4, setEditorState4] = useState<EditorState>(EditorState.createEmpty())
  const history: any = useHistory()
  const [loading, setLoading] = useState(false)
  const [otherDetails, setOtherDetails] = useState(initialValues)

  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const formik = useFormik({
    initialValues: otherDetails || initialValues,
    validationSchema: registrationSchema,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      setTimeout(() => {
        let request: any = {
          campaignDetails: history.location.state.campaignDetails,
          // influencerDetails: history.location.state.influencerDetails,
          audienceDetails: history.location.state.audienceDetails,
          otherDetails: {
            do: content1,
            dont: content2,
            postProduction: content3,
            platformPost: content4,
          },
        }
        if (history.location.state?.campaignDetails.platform === "Instagram") {
          request.instagramInfluencerDetails = history.location.state?.instagramInfluencerDetails
        } else if (history.location.state?.campaignDetails.platform === "Youtube") {
          request.youtubeInfluencerDetails = history.location.state?.youtubeInfluencerDetails
        }
        setOtherDetails(values)
        setLoading(false)
        setSubmitting(false)
        httpService.patchData(
          '/campaign-master/' + history.location.state?._id,
          request,
          (response: any) => {
            message.success('Other details saved successfully.')
            if(user?.permissions?.admin){
              httpService.putData(
                '/campaign-master/status/' + response.data.data.campaign._id + '/Approved', {}, (response: any) => {},
                (error: any) => {
                  message.error('Campaign status failed to update')
                }
              )
            }
            // history.push({
            //   pathname: '/campaign/dashboard',
            //   state: {type: 'campaign/dashboard', status: ''},
            // })
            setShow(true)
          },
          (error: any) => {
            message.error(error.message)
          }
        )
      }, 1000)
    },
  })

  useEffect(() => {
    try {
      var otherDetail1 = history.location.state?.otherDetails
      setContent1(otherDetail1.do)
      setContent2(otherDetail1.dont)
      setContent3(otherDetail1.platformPost)
      setContent4(otherDetail1.postProduction)
      const content1 = convertFromHTML(otherDetail1.do)
      setEditorState1(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(content1.contentBlocks, content1.entityMap)
        )
      )
      const content2 = convertFromHTML(otherDetail1.dont)
      setEditorState2(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(content2.contentBlocks, content2.entityMap)
        )
      )
      const content3 = convertFromHTML(otherDetail1.platformPost)
      setEditorState3(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(content3.contentBlocks, content3.entityMap)
        )
      )
      const content4 = convertFromHTML(otherDetail1.postProduction)
      setEditorState4(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(content4.contentBlocks, content4.entityMap)
        )
      )
      setOtherDetails(otherDetail1)
    } catch { }
  }, [history.location.state?.otherDetails])
  return (
    <div className='CompanyDetailsOuter'>
      <CampaignLeftBreadcrum currentStep='4' />
      <form
        className='CompanyDetails campaignDetails'
        noValidate
        id='kt_login_signup_form'
        onSubmit={formik.handleSubmit}
      >
        <h2 className='mb-0 '>Define content guidelines</h2>

        <div className=' m32'>
          <h6 className='fw-bold'>Do’s</h6>
          <Editor
            placeholder={
              'Example:' +
              '\n' +
              '- Shoot pictures in natural light only' +
              '\n' +
              '- Show the packaging and the product while unboxing' +
              '\n' +
              '- Apply the product on your skin'
            }
            editorState={editorState1}
            wrapperClassName='card border'
            editorClassName='card-body bg-gray-100 m-2 h-150px'
            onEditorStateChange={(newState) => {
              setEditorState1(newState)
              setContent1(draftToHtml(convertToRaw(newState.getCurrentContent())))
            }}
            toolbar={{
              options: [
                'inline',
                'blockType',
                'fontSize',
                'list',
                'textAlign',
                'history',
                'embedded',
                'emoji',
                'image',
              ],
              inline: { inDropdown: true },
              list: { inDropdown: true },
              textAlign: { inDropdown: true },
              link: { inDropdown: true },
              history: { inDropdown: true },
            }}
          />
        </div>
        <div className=' m24'>
          <h6 className='fw-bold'>Don’ts</h6>
          <Editor
            placeholder={
              'Example:' +
              '\n' +
              "- Don't combine with other beauty products" +
              '\n' +
              " - Don't shoot in front of a studio-style background" +
              '\n' +
              " - Don't feature kids or pets in the video" +
              '\n' +
              " - Don't include fashion styling along with product usage"
            }
            editorState={editorState2}
            wrapperClassName='card border'
            editorClassName='card-body bg-gray-100 m-2 h-150px'
            onEditorStateChange={(newState) => {
              setEditorState2(newState)
              setContent2(draftToHtml(convertToRaw(newState.getCurrentContent())))
            }}
            toolbar={{
              options: [
                'inline',
                'blockType',
                'fontSize',
                'list',
                'textAlign',
                'history',
                'embedded',
                'emoji',
                'image',
              ],
              inline: { inDropdown: true },
              list: { inDropdown: true },
              textAlign: { inDropdown: true },
              link: { inDropdown: true },
              history: { inDropdown: true },
            }}
          />
        </div>
        <div className=' m24'>
          <h6 className='fw-bold'>Post Production</h6>
          <Editor
            editorState={editorState3}
            wrapperClassName='card border'
            editorClassName='card-body bg-gray-100 m-2 h-150px'
            onEditorStateChange={(newState) => {
              setEditorState3(newState)
              setContent3(draftToHtml(convertToRaw(newState.getCurrentContent())))
            }}
            toolbar={{
              options: [
                'inline',
                'blockType',
                'fontSize',
                'list',
                'textAlign',
                'history',
                'embedded',
                'emoji',
                'image',
              ],
              inline: { inDropdown: true },
              list: { inDropdown: true },
              textAlign: { inDropdown: true },
              link: { inDropdown: true },
              history: { inDropdown: true },
            }}
          />
        </div>
        <div className=' m24'>
          <h6 className='fw-bold'>Platform Post</h6>
          <Editor
            placeholder={
              'Example:' +
              '\n' +
              ' - Describe your experience with the product in before and after sequence' +
              '\n' +
              ' - Mention the brand Instagram profile as @YourBrandName in the caption' +
              '\n' +
              ' - Include the mandatory hashtags #YourBrandName, #NewProductName, etc.' +
              '\n' +
              ' - Post only between 10.00 AM and 12.00 noon or 6.00 PM and 8.00 PM'
            }
            editorState={editorState4}
            wrapperClassName='card border'
            editorClassName='card-body bg-gray-100 m-2 h-150px'
            onEditorStateChange={(newState) => {
              setEditorState4(newState)
              setContent4(draftToHtml(convertToRaw(newState.getCurrentContent())))
            }}
            toolbar={{
              options: [
                'inline',
                'blockType',
                'fontSize',
                'list',
                'textAlign',
                'history',
                'embedded',
                'emoji',
                'image',
              ],
              inline: { inDropdown: true },
              list: { inDropdown: true },
              textAlign: { inDropdown: true },
              link: { inDropdown: true },
              history: { inDropdown: true },
            }}
          />
        </div>
        <div className='d-flex m32 pe-5'>
          <Link
            to={{
              pathname: '/campaign/audienceDetails',
              state: {
                ...history.location.state,
                otherDetails: {
                  do: content1,
                  dont: content2,
                  postProduction: content3,
                  platformPost: content4,
                },
              },
            }}
          >
            <button className='btn fw-bolder btn-gray-200 CompanyDetailButton'>Go Back</button>
          </Link>
          <button
            type='submit'
            id='kt_sign_up_submit'
            className='btn create-btn CompanyDetailButton ms-5'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && <span className='indicator-label'>Save Campaign</span>}
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop='static'
        keyboard={false}
        aria-labelledby='contained-modal-title-vcenter '
        centered
      >
        <Modal.Body className='p-0 '>
          <div style={{ width: '745px' }} className='rounded'>
            <div
              style={{ width: '100%', height: '282px' }}
              className='d-flex justify-content-center bg-white rounded-top '
            >
              <img
                style={{ position: 'absolute', top: '15px' }}
                src={toAbsoluteUrl('/media/logos/Advertise.png')}
              ></img>
            </div>
            <div
              className='text-center bg-white rounded-bottom'
              style={{ width: '100%', height: '270px' }}
            >
              <h2 style={{ paddingTop: '32px', fontSize: '18px' }}>Good stuff is in the works! 🔥</h2>
              <p
                style={{
                  width: '486px',
                  height: '54px',
                  marginRight: 'auto',
                  marginLeft: 'auto',
                  paddingTop: '4px',
                  fontSize: '12px',
                }}
                className='mb-0'
              >
                Your campaign has been sent for review successfully! You can track it under the 'In
                Review' section. Once it is live, it will appear under your 'Ongoing' campaigns
                section.
              </p>
              <div className='flex'>
                <Link
                  to={{
                    pathname: '/campaign/details',
                    state: { type: 'campaignDetails' },
                  }}
                >
                  <button
                    style={{ width: '263px' }}
                    className='btn btn-gray-100 me-5 text-dark border m32'
                  >
                    Add another Campaign
                  </button>
                </Link>
                <Link
                  to={{
                    pathname: '/campaign/dashboard',
                    state: { type: 'campaign/dashboard', status: '', reloaded: false },
                  }}
                >
                  <button style={{ width: '263px' }} className='btn create-btn border m32'>
                    Go to Dashboard
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
