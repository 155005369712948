/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import { useFormik } from 'formik'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import { login } from '../redux/AuthCRUD'
import * as auth from '../redux/AuthRedux'
import jwt_decode from 'jwt-decode'
import httpService from '../../../../setup/http/httpServices'
import { message } from 'antd'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const [disable, setDisable] = useState(false)

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      setTimeout(() => {
        login(values.email, values.password)
          .then(({ data: { data } }) => {
            let userObj: any = jwt_decode(data.token)
            setLoading(false)
            dispatch(auth.actions.login(data.token))
            // if (userObj.userType === 'brand' || userObj.userType === 'agency') {
            //   let authData = {
            //     canLogIn: true,
            //     brandName: data.firstName,
            //     firstName: data.firstName,
            //     lastName: "",
            //     email: data.email,
            //     token: data.token,
            //     userId: userObj.userId,
            //     permissions: {
            //       "admin": false,
            //       "masters": [false, false],
            //       "campaign": [true, true],
            //       "scheduler": [true, true],
            //       "transactions": [true, true],
            //       "adminSettings": [true, true],
            //       "support": [true, true]
            //     }
            //   }
            //   dispatch(auth.actions.setUser(authData))
            //   setTimeout(() => {
            //     window.location.reload()                
            //   }, 100);
            // }
            // else {
            httpService.getData(
              '/user/' + userObj.userId,
              (response: any) => {
                let authData = data
                authData.userId = userObj.userId
                authData.userType = userObj.userType
                if (userObj.userType === "agency")
                  authData.agencyId = userObj.agencyId
                else 
                  authData.brandId = userObj.brandId
                authData['permissions'] = response.data.data.user.permissions
                dispatch(auth.actions.setUser(authData))
                window.location.reload()
              },
              (error: any) => {
                setLoading(false)
                message.error('Not enough user detaila available to provide access')
              }
            )
            // }

          })
          .catch(() => {
            setLoading(false)
            setSubmitting(false)
            setStatus('The login detail is incorrect')
            setDisable(true)
          })
      }, 1000)
    },
  })

  return (
    <form
      className='form w-75 h-100 position-relative flex'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      <div>
        {/* begin::Heading */}
        <div className='mb-5'>
          <h1 style={{ fontWeight: 700, fontSize: '36px', lineHeight: '54px' }}>Hello 👋</h1>
          <div className='fs-14'>
            Log in to catch up on the action and get new campaigns rolling.
            {/* <Link to='/auth/registration' className='link-primary fw-bolder'>
            Create an Account
          </Link> */}
          </div>
        </div>
        {/* begin::Heading */}

        {
          formik.status && (
            <div className='alert alert-danger'>
              <div className='alert-text font-weight-bold'>{formik.status}</div>
            </div>
          )
          // : (
          //   <div className='mb-10 bg-light-info p-8 rounded'>
          //     <div className='text-info'>
          //       Use account <strong>admin@demo.com</strong> and password <strong>demo</strong> to
          //       continue.
          //     </div>
          //   </div>
          // )
        }

        {/* begin::Form group */}
        <div className='fv-row mb-10 m32'>
          <label className='form-label fs-14 required'>Email</label>
          <input
            placeholder='Enter your email'
            //{...formik.getFieldProps('email')}
            value={formik.values.email}
            onChange={(e: any) => {
              formik.setFieldValue('email', e.target.value)
              setDisable(false)
            }}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              { 'is-invalid': formik.touched.email && formik.errors.email },
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
            type='email'
            name='email'
            autoComplete='off'
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <span className='text-danger' role='alert'>
                {formik.errors.email}
              </span>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <div className='d-flex justify-content-between mt-n5'>
            <div className='d-flex flex-stack mb-2'>
              {/* begin::Label */}
              <label className='form-label fs-14 mb-0 mt-5 required'>Password</label>
              {/* end::Label */}
              {/* begin::Link */}
              {/* <Link
              to='/auth/forgot-password'
              className='link-primary fs-6 fw-bolder'
              style={{marginLeft: '5px'}}
            >
              Forgot Password ?
            </Link> */}
              {/* end::Link */}
            </div>
          </div>
          <input
            type='password'
            placeholder='Enter your password'
            autoComplete='off'
            // {...formik.getFieldProps('password')}
            value={formik.values.password}
            onChange={(e: any) => {
              formik.setFieldValue('password', e.target.value)
              setDisable(false)
            }}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.password && formik.errors.password,
              },
              {
                'is-valid': formik.touched.password && !formik.errors.password,
              }
            )}
          />
          {formik.touched.password && formik.errors.password && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span className='text-danger' role='alert'>
                  {formik.errors.password}
                </span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Action */}
        <div className='text-center'>
          <button
            type='submit'
            id='kt_sign_in_submit'
            className='btn create-btn w-100 mb-5'
            disabled={formik.isSubmitting || !formik.isValid || disable}
          >
            {!loading && <span className='indicator-label'>Login</span>}
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <p className='fs-7'><a href="https://sehaye.com/terms-and-privacy-policy" target="_blank">Privacy Policy &nbsp; | &nbsp; Terms and Conditions</a></p>
          <Link
            to='/auth/forgot-password'
            className='text-gray-600 fs-6 fw-bolder'
            style={{ marginLeft: '5px' }}
          >
            Forgot Password?
          </Link>
          <p className='fs-7 text-gray-600' style={{textAlign: "center"}}>©2024 Sehaye. All Rights Reserved.</p>
        </div>
        {/* end::Action */}
        {/* <div className='fv-row fixed-bottom position-absolute d-flex justify-content-center w-100 '>
          <div>
            <p className='fs-7'><a href="https://sehaye.com/terms-and-privacy-policy">Privacy Policy &nbsp; | &nbsp; Terms and Conditions</a></p>
            <p className='fs-7 text-gray-600' style={{textAlign: "center"}}>©2024 Sehaye. All Rights Reserved.</p>
          </div>
        </div> */}
      </div>
    </form>
  )
}
