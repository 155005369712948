import {message} from 'antd'
import Moment from 'moment'
import {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap-v5'
import {useHistory} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../_metronic/helpers'
import httpService from '../../setup/http/httpServices'
import ProfileAudience from './ProfileAudience'
import ProfileCampaigns from './ProfileCampaigns'
import ProfileContent from './ProfileContent'
import ProfileInfluencer from './ProfileInfluencer'
import ProfileOverview from './ProfileOverview'
function InfluencerProfile() {
  const history: any = useHistory()
  const [overview, setOverview] = useState(true)
  const [influencer, setInfluencer] = useState(false)
  const [audience, setAudience] = useState(false)
  const [content, setContent] = useState(false)
  const [campaigns, setCampaigns] = useState(false)
  const [userData, setUserData] = useState(null)
  const [instagram, setInstagram] = useState(true)
  const [youtube, setYoutube] = useState(false)
  const [state, setState] = useState(null)
  const [data, setData] = useState(null)
  const [count, setCount] = useState(0)
  // const [facebook, setFacebook] = useState(false)
  const [deleteId, setDeleteId] = useState('')
  const [show, setShow] = useState(false)
  const key = 'updatable'
  const queryString = require('query-string')
  const id = queryString.parse(window.location.search)
  useEffect(() => {
    if (history.location.state == undefined) {
      setCount(count + 1)
      httpService.getData(
        '/influencer-master/' + id?.name,
        (res: any) => {
          res.data.data.influencer.additionalInfo.interestedCategories =
            res.data.data.influencer.additionalInfo.interestedCategories.join(',')
          setData({
            influencerBasicInfo: res.data.data.influencer.basicInfo,
            influencerAdditinalInfo: res.data.data.influencer.additionalInfo,
            influencerSocialDetails: res.data.data.influencer.socialMediaDetails,
            influencerStats: res.data.data.influencer.stats,
            _id: res.data.data.influencer._id,
            type: 'influencerProfile',
          })
          history.push({
            pathname: '/master/influencerProfile',
            state: {
              influencerBasicInfo: res.data.data.influencer.basicInfo,
              influencerAdditinalInfo: res.data.data.influencer.additionalInfo,
              influencerSocialDetails: res.data.data.influencer.socialMediaDetails,
              influencerStats: res.data.data.influencer.stats,
              _id: res.data.data.influencer._id,
              type: 'influencerProfile',
            },
          })
        },
        (err: any) => {
          message.error({content: err.message})
        }
      )
    } else {
      setData(history.location.state)
    }
  }, [count == 0])
  useEffect(() => {
    if (instagram && data?.influencerSocialDetails?.instagram?.username) getInstaData()
    else if (data?.influencerSocialDetails?.youtube?.username) getYtData()
    // else {
    //   message.warning("We don't have media details for this social media")
    // }
  }, [instagram, youtube, data])

  const getInstaData = () => {
    httpService.getData(
      '/iqdata/stats/' +
        data._id +
        '?platform=instagram&username=' +
        data.influencerSocialDetails.instagram?.username,
      (res: any) => {
        setUserData(res.data.data)
      },
      (err: any) => {
        console.log(err?.message)
      }
    )
  }
  const getYtData = () => {
    httpService.getData(
      '/iqdata/stats/' +
        data._id +
        '?platform=youtube&username=' +
        data.influencerSocialDetails.youtube?.username,
      (res: any) => {
        setUserData(res.data.data)
      },
      (err: any) => {
        setUserData(null)
        message.error(err?.message)
      }
    )
  }
  const handleDelete = () => {
    message.loading({content: 'Loading...', key})
    httpService.deleteData(
      '/influencer-master/' + data._id,
      (res: any) => {
        message.success({content: 'Influencer deleted!', key, duration: 2})
        history.push({pathname: '/master/influencer', state: {type: 'influencer', reloaded: false}})
      },
      (err: any) => {
        message.error({content: err.message, key, duration: 2})
      }
    )
  }

  const handleEdit = () => {
    message.loading({content: 'Loading...', key})
    httpService.getData(
      '/influencer-master/' + data._id,
      (res: any) => {
        res.data.data.influencer.additionalInfo.interestedCategories =
          res.data.data.influencer.additionalInfo.interestedCategories.join(',')
        history.push({
          pathname: '/master/influencerBasicInfo',
          state: {
            influencerBasicInfo: res.data.data.influencer.basicInfo,
            influencerAdditinalInfo: res.data.data.influencer.additionalInfo,
            influencerSocialDetails: res.data.data.influencer.socialMediaDetails,
            _id: res.data.data.influencer._id,
            type: 'influencerCreate',
          },
        })
      },
      (err: any) => {
        message.error({content: err.message, key, duration: 2})
      }
    )
  }
  return (
    <div className='d-flex' style={{minWidth: '1336px'}}>
      <div className='basicDiv w-400px ms-20px' style={{height: 'fit-content'}}>
        <div className='d-flex flex-column'>
          <div className='d-flex w-100 justify-content-between'>
            {userData?.user_profile?.picture ? (
              <label className='campaignLabel rounded-circle'>
                <img
                  src={userData?.user_profile?.picture}
                  style={{borderRadius: '100%', width: '140px', height: '140px'}}
                ></img>
              </label>
            ) : (
              <label
                className='create-btn imageBadge pt-8 symbol'
                style={{fontSize: 60, borderRadius: '100%', width: '140px', height: '140px'}}
              >
                {data?.influencerBasicInfo.name[0].toUpperCase()}
              </label>
            )}
            <button
              type='button'
              className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary rotate-90'
              data-kt-menu-trigger='hover'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='top-end'
            >
              <KTSVG
                className='svg-icon  svg-icon-dark'
                path={toAbsoluteUrl('/media/icons/Custom/Menu90.svg')}
              />
            </button>
            <span
              className='menu border border-3 menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold w-200px pt-3 pb-3'
              data-kt-menu='true'
            >
              <span className='menu-item px-3'>
                <a
                  className='menu-link px-3 text-hover-white'
                  onClick={(e: any) => {
                    handleEdit()
                  }}
                >
                  Edit
                </a>
              </span>
              <span className='menu-item px-3'>
                <a
                  className='menu-link px-3 text-hover-white'
                  onClick={(e: any) => {
                    setShow(true)
                    setDeleteId(history.location.state?.id)
                  }}
                >
                  Delete
                </a>
              </span>

              <Modal
                show={show}
                onHide={() => {
                  setShow(false)
                }}
                aria-labelledby='contained-modal-title-vcenter'
                centered
              >
                <Modal.Header>
                  <Modal.Title>Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you wanna delete?</Modal.Body>
                <Modal.Footer>
                  <button
                    className='btn fw-bolder btn-gray-200 fs-6 dialogButton'
                    onClick={() => {
                      setShow(false)
                      setDeleteId('')
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className='btn create-btn fs-6 dialogButton ms-2'
                    onClick={() => handleDelete()}
                  >
                    Delete
                  </button>
                </Modal.Footer>
              </Modal>
            </span>
          </div>
          <div className='d-flex align-items-center m20'>
            <span className='fs-20'>{data?.influencerBasicInfo?.name}</span>
            {data?.influencerAdditinalInfo?.gender == 'Female' && (
              <span
                style={{
                  backgroundColor: '#f9fafc',
                  width: '20px',
                  height: '20px',
                  textAlign: 'center',
                }}
                className='rounded-circle ms-3'
              >
                <img
                  src={toAbsoluteUrl('/media/icons/Custom/female.svg')}
                  style={{height: '14px', width: '14px', marginBottom: '1px'}}
                />
              </span>
            )}
            {data?.influencerAdditinalInfo?.gender == 'Male' && (
              <span
                style={{
                  backgroundColor: '#f9fafc',
                  width: '20px',
                  height: '20px',
                  textAlign: 'center',
                }}
                className='rounded-circle ms-3'
              >
                <img
                  src={toAbsoluteUrl('/media/icons/Custom/male.svg')}
                  style={{height: '14px', width: '14px', marginBottom: '1px'}}
                />
              </span>
            )}
          </div>
          <span className='fs-14'>
            {data?.influencerBasicInfo?.city
              ? data?.influencerBasicInfo?.city + ',' + data?.influencerBasicInfo?.state
              : 'NA'}
          </span>
          {data?.influencerSocialDetails?.instagram?.verificationStatus === 'verified' && (
            <span className='fs-14'>
              <a
                href={'https://instagram.com/' + data?.influencerSocialDetails?.instagram?.username}
                target='parent'
              >
                <img
                  src={toAbsoluteUrl('/media/logos/Instagram.png')}
                  style={{padding: '8px 8px 4px 0'}}
                />
                {data?.influencerSocialDetails?.instagram?.username}
              </a>
            </span>
          )}
          {data?.influencerSocialDetails?.youtube?.verificationStatus === 'verified' && (
            <span className='fs-14'>
              <a
                href={
                  'https://www.youtube.com/@' + data?.influencerSocialDetails?.youtube?.username
                }
                target='parent'
              >
                <img
                  src={toAbsoluteUrl('/media/logos/Youtube.png')}
                  style={{padding: '8px 8px 4px 0'}}
                />
                {data?.influencerSocialDetails?.youtube?.username}
              </a>
            </span>
          )}
          <div className='d-flex m24'>
            <div className='d-flex flex-column'>
              <span className='fs-14'>Brands</span>
              <span className='fs-20'>{data?.influencerStats?.brands || 'NA'}</span>
            </div>
            <div className='d-flex flex-column' style={{marginLeft: '75px'}}>
              <span className='fs-14'>Campaigns</span>
              <span className='fs-20'>{data?.influencerStats?.campaings || 'NA'}</span>
            </div>
            <div className='d-flex flex-column' style={{marginLeft: '41px'}}>
              <span className='fs-14'>Rating</span>
              <div className='d-flex fs-20 align-items-start'>
                {data?.influencerStats?.rating ? (
                  <img src={toAbsoluteUrl('/media/logos/star.png')} className='align-self-center' />
                ) : (
                  <></>
                )}
                <span style={{marginLeft: '2px'}}>{data?.influencerStats?.rating || 'NA'}</span>
              </div>
            </div>
          </div>
          {data?.influencerBasicInfo?.description && (
            <span className='m24'>{data?.influencerBasicInfo?.description}</span>
          )}
          {data?.influencerBasicInfo?.email && (
            <span className='m24 bg-custom'>
              <img src={toAbsoluteUrl('/media/logos/mail.png')} className='align-self-center' />{' '}
              <span className='fs-14'>{data?.influencerBasicInfo.email}</span>
            </span>
          )}
          {data?.influencerBasicInfo?.phoneNumber && (
            <span className='bg-custom mt-2'>
              <img src={toAbsoluteUrl('/media/logos/phone.png')} className='align-self-center' />{' '}
              <span className='fs-14'>{data?.influencerBasicInfo?.phoneNumber}</span>
            </span>
          )}
          <span className='tex-gray-400 fs-8' style={{marginTop: '32px', marginBottom: '8px'}}>
            {Moment(data?.join_date).format('DD MMMM YYYY')}
          </span>
        </div>
      </div>
      <div className='d-flex ms-20px m20 flex-column' style={{width: '1113px'}}>
        <div className='d-flex'>
          {data?.influencerSocialDetails?.instagram?.verificationStatus === 'verified' && (
            <span
              className={instagram ? 'bg-custom me-2 bg-white fw-bolder' : 'bg-custom me-2'}
              onClick={() => {
                setInstagram(true)
                setYoutube(false)
              }}
            >
              <img src={toAbsoluteUrl('/media/logos/Instagram.png')} />
              <label className='fs-14 ms-2'>For Instagram</label>
            </span>
          )}
          {data?.influencerSocialDetails?.youtube?.verificationStatus === 'verified' && (
            <span
              className={youtube ? 'bg-custom me-2 bg-white fw-bolder' : 'bg-custom me-2'}
              onClick={() => {
                setInstagram(false)
                setYoutube(true)
              }}
            >
              <img src={toAbsoluteUrl('/media/logos/Youtube.png')} />
              <label className='fs-14 ms-2'>For Youtube</label>
            </span>
          )}
          {/* <span
            className={facebook ? 'bg-custom bg-white fw-bolder' : 'bg-custom'}
            onClick={() => {
              setInstagram(false)
              setYoutube(false)
              setFacebook(true)
            }}
          >
            <img src={toAbsoluteUrl('/media/logos/Facebook.png')} />
            <label className='fs-14 ms-2'>For Facebook</label>
          </span> */}
        </div>
        <div
          className='basicDiv pt-0 pb-0 flex justify-content-start '
          style={{overflow: 'hidden'}}
        >
          <div
            onClick={() => {
              setOverview(true)
              setInfluencer(false)
              setAudience(false)
              setContent(false)
              setCampaigns(false)
            }}
            className={
              overview ? 'p-5 fw-bolder border-bottom border-primary border-2 h-50px' : 'p-5 h-50px'
            }
          >
            <a>Overview</a>
          </div>
          <div
            onClick={() => {
              setOverview(false)
              setInfluencer(true)
              setAudience(false)
              setContent(false)
              setCampaigns(false)
            }}
            className={
              influencer
                ? 'p-5 fw-bolder border-bottom border-primary border-2  h-50px'
                : 'p-5 h-50px'
            }
          >
            <a>Influencer</a>
          </div>
          <div
            onClick={() => {
              setOverview(false)
              setInfluencer(false)
              setAudience(true)
              setContent(false)
              setCampaigns(false)
            }}
            className={
              audience ? 'p-5 fw-bolder border-bottom border-primary border-2 h-50px' : 'p-5 h-50px'
            }
          >
            <a>Audience</a>
          </div>
          <div
            onClick={() => {
              setOverview(false)
              setInfluencer(false)
              setAudience(false)
              setContent(true)
              setCampaigns(false)
            }}
            className={
              content ? 'p-5 fw-bolder border-bottom border-primary border-2 h-50px' : 'p-5 h-50px'
            }
          >
            <a>Content</a>
          </div>
          <div
            onClick={() => {
              setOverview(false)
              setInfluencer(false)
              setAudience(false)
              setContent(false)
              setCampaigns(true)
            }}
            className={
              campaigns
                ? 'p-5 fw-bolder border-bottom border-primary border-2 h-50px'
                : 'p-5 h-50px'
            }
          >
            <a>Campaign</a>
          </div>
        </div>
        {overview && <ProfileOverview userData={userData} type={youtube} />}
        {influencer && <ProfileInfluencer data={data} userData={userData} type={youtube} />}
        {audience && <ProfileAudience data={data} userData={userData} type={youtube} />}
        {content && <ProfileContent data={data} userData={userData} type={youtube} />}
        {campaigns && <ProfileCampaigns data={data} type={youtube} />}
      </div>
    </div>
  )
}

export default InfluencerProfile
