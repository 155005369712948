import React from 'react'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import '../Dashboard.scss'
import {NoData} from './NoData'

export function Campaign(props: any) {
  const rows: any[] = [1, 2, 3, 4, 5]

  return (
    <div className='Dcard Dcampaigns'>
      <h2 className='Dheading'>Top Campaigns</h2>
      {!props.data ? (
        <NoData />
      ) : (
        <div className='table-responsive'>
          <table className='table table-row-dashed table-row align-middle gs-0 gy-4 m-0'>
            <thead>
              <tr className='fw-bolder text-muted'>
                <th style={{width: '250px'}}>
                  <span className='d-flex'>Brand</span>
                </th>
                <th style={{width: '400px'}}>
                  <span className='d-flex'>Campaign</span>
                </th>
                <th>
                  <span className='d-flex'>Platform</span>
                </th>
                <th>
                  <span className='d-flex'>Reach</span>
                </th>
                <th>
                  <span className='d-flex'>Impressions</span>
                </th>
                <th>
                  <span className='d-flex'>Eng. Rate</span>
                </th>
                <th>
                  <span className='d-flex'>Influencers</span>
                </th>
                <th>
                  <span className='d-flex'>Top Influencers</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {props.data?.map((index) => (
                <tr key={index} className=''>
                  <td className='fw-bolder'>
                    <img
                      className='Davatar'
                      src='https://cdn0.iconfinder.com/data/icons/most-usable-logos/120/Amazon-512.png'
                      alt=''
                    />
                    Netflix India
                  </td>
                  <td className='fw-normal'>Toh Baat Pakki?</td>
                  <td className='fw-normal'>
                    <img src={toAbsoluteUrl('/media/logos/Instagram.png')} alt='' />
                  </td>
                  <td className='fw-normal'>4.4M</td>
                  <td className='fw-normal'>2.12M</td>
                  <td className='fw-normal'>50%</td>
                  <td className='fw-normal'>240</td>
                  <td className='fw-normal'>
                    <img
                      className='Davatar'
                      src={toAbsoluteUrl('/media/avatars/150-1.jpg')}
                      alt=''
                    />
                    <img
                      className='Davatar'
                      src={toAbsoluteUrl('/media/avatars/150-2.jpg')}
                      alt=''
                    />
                    <img
                      className='Davatar'
                      src={toAbsoluteUrl('/media/avatars/150-3.jpg')}
                      alt=''
                    />
                    <img
                      className='Davatar'
                      src={toAbsoluteUrl('/media/avatars/150-4.jpg')}
                      alt=''
                    />
                    <img
                      className='Davatar'
                      src={toAbsoluteUrl('/media/avatars/150-5.jpg')}
                      alt=''
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  )
}
