/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { SearchOutlined } from '@ant-design/icons'
import { Input, message } from 'antd'
import { useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import httpService from '../../setup/http/httpServices'
import { RootState } from '../../setup/redux/RootReducer'
import { KTSVG, toAbsoluteUrl } from '../../_metronic/helpers'
import { IUserModel } from '../modules/auth/models/UserModel'
import './basic.css'
import { Tooltip } from 'antd';

export function InfluencerMaster(props: any) {
  const handleView = (id: any) => {
    httpService.getData(
      '/influencer-master/' + id,
      (res: any) => {
        res.data.data.influencer.additionalInfo.interestedCategories =
          res.data.data.influencer.additionalInfo.interestedCategories.join(',')
        history.push({
          pathname: '/master/influencerProfile',
          state: {
            influencerBasicInfo: res.data.data.influencer.basicInfo,
            influencerAdditinalInfo: res.data.data.influencer.additionalInfo,
            influencerSocialDetails: res.data.data.influencer.socialMediaDetails,
            influencerStats: res.data.data.influencer.stats,
            join_date: res.data.data.influencer.createdAt,
            _id: res.data.data.influencer._id,
            type: 'influencerProfile',
          },
        })
      },
      (err: any) => {
        message.error({ content: err.message, duration: 2 })
      }
    )
  }
  const user: IUserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as IUserModel
  const history: any = useHistory()
  const [influencerParams, setInfluencerParams] = useState({
    page: 1,
    total: 0,
    limit: 0,
    search: '',
    gender: '',
    age: '',
    platform: 'instagram',
    followers: '',
    avgViews: '',
    avgLikes: '',
  })
  const [influencersList, setInfluencersList] = useState([])
  const [influencersCount, setInfluencersCount] = useState(0)
  // if (history.location.state && !!!history.location.state.reloaded) {
  //   history.push({pathname: '/master/influencer', state: {type: 'influencer', reloaded: true}})
  //   window.location.reload()
  // }
  const [categories, setCategories] = useState({})

  useEffect(() => {
    getInfluencerList()
    getAllCategories()
  }, [influencerParams])

  const getAllCategories = () => {
    httpService.getData(
      '/category-master?limit=0',
      (response: any) => {
        let category: any = {}
        response.data.data.categories.forEach((element) => {
          category[element.iqDataCategoryId] = element
        })
        setCategories(category)
        getInfluencerList()
      },
      (error: any) => message.error(error.message)
    )
  }
  const getInfluencerList = () => {
    httpService.getData(
      '/influencer-master?platform=' +
      influencerParams.platform +
      '&&search=' +
      influencerParams.search +
      '&&age=' +
      influencerParams.age +
      '&&followers=' +
      influencerParams.followers +
      '&&avgViews=' +
      influencerParams.avgViews +
      '&&avgLikes=' +
      influencerParams.avgLikes +
      '&&gender=' +
      influencerParams.gender,
      (response: any) => {
        setInfluencersList(
          response.data.data.influencers.filter(
            (x: any) =>
              x.socialMediaDetails[influencerParams.platform].verificationStatus === 'verified'
          )
        )
        setInfluencersCount(response.data.data.total)
      },
      (error: any) => {
        message.error(error.message)
      }
    )
  }
  const onView = (id: string) => {
    httpService.getData(
      '/influencer-master/' + id,
      (res: any) => {
        res.data.data.influencer.additionalInfo.interestedCategories =
          res.data.data.influencer.additionalInfo.interestedCategories.join(',')
        history.push({
          pathname: '/master/influencerProfile',
          state: {
            influencerBasicInfo: res.data.data.influencer.basicInfo,
            influencerAdditinalInfo: res.data.data.influencer.additionalInfo,
            influencerSocialDetails: res.data.data.influencer.socialMediaDetails,
            influencerStats: res.data.data.influencer.stats,
            _id: res.data.data.influencer._id,
            type: 'influencerProfile',
          },
        })
      },
      (err: any) => {
        message.error({ content: err.message })
      }
    )
  }
  return (
    <div className='justify-content-center ' style={{minWidth: '1110px'}}>
      <div className='container-fluid flex justify-content-between bg-white h-75px border'>
        <div className='justify-content-start bg-white'>
          <h3 className='m-0'>Influencer Master</h3>
          <p className='text-gray-400 fs-7 m-0'>{influencersCount} Influencers</p>
        </div>
        <div className='d-flex justify-content-between'>
          <Input
            placeholder='Search influencers'
            style={{width: '342px', height: '41px', marginLeft: '32px'}}
            className='form-control-solid border rounded'
            prefix={
              <>
                <SearchOutlined />
              </>
            }
            onChange={(e: any) =>
              setInfluencerParams({...influencerParams, ...{search: e.target.value}})
            }
          />
          {(user.permissions.masters[1] || user.permissions.admin) && (
            <div
              className='d-flex justify-content-end align-items-center'
              style={{width: 'inherit'}}
            >
              <Link
                to={{
                  pathname: '/master/influencerBasicInfo',
                  state: {...history.location.state, type: 'influencerAdd'},
                }}
              >
                <button
                  style={{width: '224px', height: '40px', borderRadius: '4px'}}
                  className='btn create-btn d-flex flex ms-5 fs-6'
                >
                  Add Influencer 👌
                </button>
              </Link>
            </div>
          )}
        </div>
      </div>
      <div
        className='container-fluid flex justify-content-start bg-white border'
        style={{padding: '10px 0px'}}
      >
        <div className='flex form-check form-check-custom form-check-solid ps-7 '>
          <label className='me-10 '>
            <input
              className='form-check-input w-20px h-20px'
              type='radio'
              name='platform'
              id='flexRadioDefault'
              checked={influencerParams.platform === 'instagram'}
              onChange={(event: any) => {
                if (event.target.checked) {
                  influencerParams.platform = 'instagram'
                  setInfluencerParams({ ...influencerParams })
                }
              }}
            />
            <label className='fs-7 ms-2'>Instagram</label>
          </label>
          <label className=''>
            <input
              className='form-check-input w-20px h-20px'
              type='radio'
              name='platform'
              id='flexRadioDefault'
              checked={influencerParams.platform === 'youtube'}
              onChange={(event: any) => {
                if (event.target.checked) {
                  influencerParams.platform = 'youtube'
                  setInfluencerParams({ ...influencerParams })
                }
              }}
            />
            <label className='fs-7 ms-2'>Youtube</label>
          </label>
          <span className='w-35px'>
            <KTSVG
              className='svg-icon svg-icon-3x '
              path={toAbsoluteUrl('/media/icons/Custom/VerticalLine.svg')}
            />
          </span>
          <select
            className='form-select w-auto'
            value={influencerParams.age}
            onChange={(event: any) => {
              setInfluencerParams({ ...influencerParams, ...{ age: event.target.value } })
            }}
          >
            <option value=''>Age Group (5)</option>
            <option value='13-17'>13-17</option>
            <option value='18-24'>18-24</option>
            <option value='25-34'>25-34</option>
            <option value='35-44'>35-44</option>
            <option value='45-65'>45-65</option>
            <option value='65+'>65+</option>
          </select>
          <select
            className='form-select w-auto ms-5 '
            value={influencerParams.gender}
            onChange={(event: any) => {
              setInfluencerParams({ ...influencerParams, ...{ gender: event.target.value } })
            }}
          >
            <option value=''>Gender</option>
            <option value='Male'>Male</option>
            <option value='Female'>Female</option>
            <option value='Others'>Others</option>
          </select>
          <select
            className='form-select w-auto ms-5 '
            value={influencerParams.followers}
            onChange={(event: any) => {
              setInfluencerParams({ ...influencerParams, ...{ followers: event.target.value } })
            }}
          >
            <option value=''>Followers</option>
            <option value='0-1000'>0-1k</option>
            <option value='1000-100000'>1k-100k</option>
            <option value='100000'>100k+</option>
          </select>
          <select
            className='form-select w-auto ms-5 '
            value={influencerParams.avgViews}
            onChange={(event: any) => {
              setInfluencerParams({ ...influencerParams, ...{ avgViews: event.target.value } })
            }}
          >
            <option value=''>Avg. Views</option>
            <option value='0-1000'>0-1k</option>
            <option value='1000-100000'>1k-100k</option>
            <option value='100000'>100k+</option>
          </select>
          <select
            className='form-select w-auto ms-5 '
            value={influencerParams.avgLikes}
            onChange={(event: any) => {
              setInfluencerParams({ ...influencerParams, ...{ avgLikes: event.target.value } })
            }}
          >
            <option value=''>Avg. Likes</option>
            <option value='0-1000'>0-1k</option>
            <option value='1000-100000'>1k-100k</option>
            <option value='100000'>100k+</option>
          </select>
          <span
            className=' fs-7 w-80px ms-5 cursor-pointer'
            onClick={() => {
              setInfluencerParams({
                page: 1,
                total: 0,
                limit: 0,
                search: '',
                gender: '',
                age: '',
                platform: 'instagram',
                followers: '',
                avgViews: '',
                avgLikes: '',
              })
            }}
          >
            Reset Filters
          </span>
        </div>
      </div>
      {influencersList.length === 0 && (
        <>
          <div>
            <div className='flex'>
              <img className='ms-20' src={toAbsoluteUrl('/media/logos/TableVector.png')}></img>
            </div>
            <div className='flex'>
              <div>
                <h2 className='mb-0 m24 flex'>No Influencers Listed</h2>
              </div>
            </div>
            <div className='flex'>
              {(user.permissions.masters[1] || user.permissions.admin) && (
                <Link
                  to={{
                    pathname: '/master/influencerBasicInfo',
                    state: { ...history.location.state, type: 'influencerAdd' },
                  }}
                >
                  <button className='btn m24 TableButton create-btn text-white'>
                    Add Influencer 👌
                  </button>
                </Link>
              )}
            </div>
          </div>
        </>
      )}

      <div className='campaignGrid'>
        {influencersList.map((data: any, index: number) => (
          <div key={index} onClick={() => {
            // if(data.socialMediaDetails[influencerParams.platform].applicationStatus === 'rejected'){
            //   message.error(data.socialMediaDetails[influencerParams.platform].reason)
            // }
            // else {
              handleView(data?._id)
            // }
          }
          } className='pointer'>
            <span className='d-flex w-100'>
              <span className='flex justify-content-start w-100'>
                {influencerParams.platform == 'instagram' && (
                  <>
                    {data.socialMediaDetails.instagram.avatar ? (
                      <label className='campaignLabel w-65px h-65px rounded-circle'>
                        <img
                          className='w-100 rounded-circle'
                          src={data.socialMediaDetails.instagram.avatar}
                        ></img>
                      </label>
                    ) : (
                      <span
                        className='symbol create-btn pt-4 w-65px h-65px imageBadge'
                        style={{ fontSize: 26, borderRadius: '100%' }}
                      >
                        {data?.basicInfo.name[0].toUpperCase()}
                      </span>
                    )}
                  </>
                )}
                {influencerParams.platform == 'youtube' && (
                  <>
                    {data.socialMediaDetails.youtube.avatar ? (
                      <label className='campaignLabel w-65px h-65px rounded-circle'>
                        <img
                          className='w-100 rounded-circle'
                          src={data.socialMediaDetails.youtube.avatar}
                        ></img>
                      </label>
                    ) : (
                      <span
                        className='symbol create-btn pt-4 w-65px h-65px imageBadge'
                        style={{ fontSize: 26, borderRadius: '100%' }}
                      >
                        {data?.basicInfo.name[0].toUpperCase()}
                      </span>
                    )}
                  </>
                )}
                <span className='fs-6 ms-4 mt-1 '>
                  <h6 className='mb-0'>{data?.basicInfo.name}</h6>{' '}
                  <label className='fs-7 text-gray-400 m-0'>{data?.basicInfo.name}</label>
                  {data.basicInfo.city && (
                    <label className='flex fs-7'>
                      <KTSVG
                        className='svg-icon me-1'
                        path={toAbsoluteUrl('/media/icons/Custom/MapMarker.svg')}
                      />{' '}
                      {data.basicInfo.city}, {data.basicInfo.state}
                    </label>
                  )}
                </span>

                <span className='flex h-25px justify-content-end w-50'>
                  {data.socialMediaDetails[influencerParams.platform].applicationStatus ===
                    'rejected' && (
                      <Tooltip placement='top' title={data.socialMediaDetails[influencerParams.platform].reason} color='red'><span className='text-danger bg-danger bg-opacity-20 fs-7 rounded p-2 pt-1 pb-1'>
                        Rejected
                      </span>
                      </Tooltip>
                    )}
                  {data.socialMediaDetails[influencerParams.platform].applicationStatus ===
                    'inProgress' && (
                      <span
                        className=' fs-7 rounded p-2 pt-1 pb-1'
                        style={{ color: '#0AAFFF', background: 'rgba(206, 239, 255, 0.25)' }}
                      >
                        under review
                      </span>
                    )}
                  {data.socialMediaDetails[influencerParams.platform].applicationStatus ===
                    'approved' && (
                      <span
                        className=' fs-7 rounded p-2 pt-1 pb-1'
                        style={{ color: '#24A25C', background: 'rgba(213, 244, 227, 0.25)' }}
                      >
                        Approved
                      </span>
                    )}
                  {data.socialMediaDetails[influencerParams.platform].applicationStatus ===
                    'unlinked' && (
                      <span
                        className=' fs-7 rounded p-2 pt-1 pb-1'
                        style={{ color: '#494646', background: '#F9FAFC' }}
                      >
                        Unlinked
                      </span>
                    )}
                </span>
              </span>
            </span>
            <span className='m16 flex'>
              {data.socialMediaDetails[influencerParams.platform].interestedCategories &&
                data.socialMediaDetails[influencerParams.platform].interestedCategories.length >
                0 &&
                data.socialMediaDetails[influencerParams.platform]?.interestedCategories.map(
                  (interest) => (
                    <span
                      className=' flex w-30px h-30px border rounded-circle me-2'
                      title={interest.name}
                    >
                      <img className='w-75' src={categories[interest.id]?.avatar}></img>
                    </span>
                  )
                )}
            </span>
            <span className='m16 w-100 flex'>
              <span className='w-100px '>
                <h3 className='mb-1 flex'>
                  {data.socialMediaDetails &&
                    data.socialMediaDetails[influencerParams.platform]?.basicStats
                    ? data.socialMediaDetails[influencerParams.platform]?.basicStats?.followers >
                      1000
                      ? (
                        data.socialMediaDetails[influencerParams.platform]?.basicStats
                          ?.followers / 1000
                      ).toFixed(2) + 'k'
                      : data.socialMediaDetails[influencerParams.platform]?.basicStats?.followers
                    : '-'}
                </h3>
                <p className='fs-7 text-gray-400 flex m-0'>Followers</p>
              </span>
              <span className='w-35px h-100 flex align-content-start'>
                <KTSVG
                  className='svg-icon h-100 mt-4 svg-icon-2x '
                  path={toAbsoluteUrl('/media/icons/Custom/VerticalLine.svg')}
                />
              </span>
              <span className='w-100px '>
                <h3 className='mb-1 flex'>
                  {data.socialMediaDetails &&
                    data.socialMediaDetails[influencerParams.platform]?.basicStats
                    ? data.socialMediaDetails[influencerParams.platform]?.basicStats?.avgViews >
                      1000
                      ? (
                        data.socialMediaDetails[influencerParams.platform]?.basicStats?.avgViews /
                        1000
                      ).toFixed(2) + 'k'
                      : data.socialMediaDetails[influencerParams.platform]?.basicStats?.avgViews
                    : '-'}
                </h3>
                <p className='fs-7 text-gray-400 flex m-0'>Avg Views</p>
              </span>
              <span className='w-35px h-100 flex align-content-start'>
                <KTSVG
                  className='svg-icon h-100 mt-4 svg-icon-2x '
                  path={toAbsoluteUrl('/media/icons/Custom/VerticalLine.svg')}
                />
              </span>
              <span className='w-100px '>
                <h3 className='mb-1 flex'>
                  {data.socialMediaDetails &&
                    data.socialMediaDetails[influencerParams.platform]?.basicStats
                    ? data.socialMediaDetails[influencerParams.platform]?.basicStats?.avgLikes >
                      1000
                      ? (
                        data.socialMediaDetails[influencerParams.platform]?.basicStats?.avgLikes /
                        1000
                      ).toFixed(2) + 'k'
                      : data.socialMediaDetails[influencerParams.platform]?.basicStats?.avgLikes
                    : '-'}
                </h3>
                <p className='fs-7 text-gray-400 flex m-0'>Avg Likes</p>
              </span>
            </span>
            <span className='m16 h-100px w-100 flex'>
              {data.socialMediaDetails &&
                data.socialMediaDetails[influencerParams.platform]?.topPosts.length > 0 && (
                  <>
                    <span className='w-115px h-100 flex rounded me-2' style={{ minWidth: '115px' }}>
                      <img
                        className='influencer-thumbnail'
                        src={
                          data.socialMediaDetails &&
                          data.socialMediaDetails[influencerParams.platform]?.topPosts[0]?.image
                        }
                      ></img>
                    </span>
                    <span className='w-115px h-100 flex rounded me-2' style={{ minWidth: '115px' }}>
                      <img
                        className='influencer-thumbnail'
                        src={
                          data.socialMediaDetails &&
                          data.socialMediaDetails[influencerParams.platform]?.topPosts[1]?.image
                        }
                      ></img>
                    </span>
                    <span className='w-115px h-100 flex rounded' style={{ minWidth: '115px' }}>
                      <img
                        className='influencer-thumbnail'
                        src={
                          data.socialMediaDetails &&
                          data.socialMediaDetails[influencerParams.platform]?.topPosts[2]?.image
                        }
                      ></img>
                    </span>
                  </>
                )}
            </span>
          </div>
        ))}
      </div>
    </div>
  )
}
