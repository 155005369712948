import { message } from 'antd'
import clsx from 'clsx'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap-v5'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import { KTSVG, toAbsoluteUrl } from '../../_metronic/helpers'
import httpService from '../../setup/http/httpServices'
import { BrandAdminLeftBreadcrum } from './BrandAgencyLeftBreadcrum'
import './basic.css'

var initialValues = {
  socialMedia1: '',
  links1: '',
  socialMedia2: '',
  links2: '',
  socialMedia3: '',
  links3: '',
}

const registrationSchema = Yup.object().shape({
  links1: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Brand name is required'),
  socialMedia1: Yup.string().required('Social Media is required'),
  links2: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Brand name is required'),
  socialMedia2: Yup.string().required('Social Media is required'),
  links3: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Brand name is required'),
  socialMedia3: Yup.string().required('Social Media is required'),
})

export function AgencyBrandMiscellaneous() {
  const history: any = useHistory()
  var companyDetails = history.location.state?.companyDetails
  var agencyDetail = history.location.state?.agencyDetails

  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const [counter, setCounter] = useState(0)

  useEffect(() => {}, [counter])
  useEffect(() => {
    var miscellaneous = history.location.state?.miscellaneous
    if (miscellaneous && miscellaneous.length > 0) {
      let misc = {
        socialMedia1: miscellaneous[0]?.socialMedia,
        links1: miscellaneous[0]?.link,
        socialMedia2: miscellaneous[1]?.socialMedia,
        links2: miscellaneous[1]?.link,
        socialMedia3: miscellaneous[2]?.socialMedia,
        links3: miscellaneous[2]?.link,
      }
      formik.setValues(misc)
    }
  }, [history.location.state?.miscellaneous])

  const handleAdd = () => {
    setCounter(counter + 1)
  }
  const handleDelete = () => {
    setCounter(counter - 1)
  }

  const [loading, setLoading] = useState(false)
  const [agencyMisc, setAgencyMisc] = useState(initialValues)

  const formik = useFormik({
    initialValues: agencyMisc || initialValues,
    // validationSchema: registrationSchema,
    enableReinitialize: true,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setTimeout(() => {
        try {
          setAgencyMisc(values)
          setLoading(false)
          setSubmitting(false)
          // if(history.location.state?.type === "Brand")
          let socialMedia: any = {}
          if (values.socialMedia1) {
            if (!values.links1) {
              message.error('Please insert value for selected social media :' + values.socialMedia2)
              return
            }
            socialMedia[values.socialMedia1] = values.links1
          }
          if (values.socialMedia2) {
            if (!values.links2) {
              message.error('Please insert value for selected social media :' + values.socialMedia2)
              return
            }
            socialMedia[values.socialMedia2] = values.links2
          }
          if (values.socialMedia3) {
            if (!values.links3) {
              message.error('Please insert value for selected social media :' + values.socialMedia2)
              return
            }
            socialMedia[values.socialMedia3] = values.links3
          }
          let brandRequest = {
            ...companyDetails,
            logoUrl: agencyDetail.logoUrl,
            brandName: agencyDetail.brandName,
            industry: agencyDetail.industry,
            brandDescription: agencyDetail.description,
            brandContactPerson: agencyDetail.contactPerson,
            brandEmail: agencyDetail.email,
            brandPhoneNumber: agencyDetail.phoneNumber,
            brandWebsite: agencyDetail.website,
            brandCity: agencyDetail.city,
            brandState: agencyDetail.state,
            brandPinCode: agencyDetail.pinCode,
            miscellaneous: JSON.stringify(socialMedia),
          }
          if (!brandRequest.agencyAssociated) {
            delete brandRequest.agencyAssociated
          }
          let agencyRequest = {
            ...companyDetails,
            logoUrl: agencyDetail.logoUrl,
            agencyName: agencyDetail.brandName,
            industry: agencyDetail.industry,
            agencyDescription: agencyDetail.description,
            agencyContactPerson: agencyDetail.contactPerson,
            agencyEmail: agencyDetail.email,
            agencyPhoneNumber: agencyDetail.phoneNumber,
            agencyWebsite: agencyDetail.website,
            agencyCity: agencyDetail.city,
            agencyState: agencyDetail.state,
            agencyPinCode: agencyDetail.pinCode,
            miscellaneous: JSON.stringify(socialMedia),
          }
          var form_data = new FormData()

          if (history.location.state.type == 'Brand') {
            for (var key in brandRequest) {
              form_data.append(key, brandRequest[key])
            }
          }
          if (history.location.state.type == 'Agency') {
            for (var key in agencyRequest) {
              form_data.append(key, agencyRequest[key])
            }
          }
          if (history.location.state._id) {
            httpService.patchData(
              '/' +
                history.location.state.type.toLowerCase() +
                '-master/' +
                history.location.state._id,
              form_data,
              (res: any) => {
                history.push({
                  pathname: '/success',
                  state: {
                    companyDetails: companyDetails,
                    agencyDetails: agencyDetail,
                    agencyMiscellaneous: values,
                    type: history.location.state?.type,
                    _id: history.location.state?._id,
                  },
                })
              },
              (error: any) => message.error(error.message)
            )
          } else {
            httpService.postData(
              '/' + history.location.state.type.toLowerCase() + '-master',
              form_data,
              (res: any) => {
                history.push({
                  pathname: '/success',
                  state: {
                    companyDetails: companyDetails,
                    agencyDetails: agencyDetail,
                    agencyMiscellaneous: values,
                    type: history.location.state?.type,
                  },
                })
              },
              (error: any) => message?.error(error?.message)
            )
          }
        } catch {
          setLoading(false)
          setSubmitting(false)
          setStatus('Registration process has broken')
          // Remove logs when not needed
        }
      }, 1000)
    },
  })

  var count = 0
  const back = () => {
    let socialMedia: any = []
    if (formik.values.socialMedia1) {
      if (!formik.values.links1) {
        message.error(
          'Please insert value for selected social media :' + formik.values.socialMedia2
        )
        return
      }
      socialMedia[0] = {socialMedia: formik.values.socialMedia1, link: formik.values.links1}
    }
    if (formik.values.socialMedia2) {
      if (!formik.values.links2) {
        message.error(
          'Please insert value for selected social media :' + formik.values.socialMedia2
        )
        return
      }
      socialMedia[1] = {socialMedia: formik.values.socialMedia2, link: formik.values.links2}
    }
    if (formik.values.socialMedia3) {
      if (!formik.values.links3) {
        message.error(
          'Please insert value for selected social media :' + formik.values.socialMedia2
        )
        return
      }
      socialMedia[2] = {socialMedia: formik.values.socialMedia3, link: formik.values.links3}
    }
    history.push({
      pathname: '/agencyDetails',
      state: {
        companyDetails: {...history.location.state?.companyDetails},
        agencyDetails: {...history.location.state?.agencyDetails},
        miscellaneous: socialMedia,
        _id: history.location.state?._id,
        type: history.location.state?.type,
      },
    })
  }
  useEffect(() => {}, [
    formik.values.socialMedia1,
    formik.values.socialMedia2,
    formik.values.socialMedia3,
  ])

  return (
    <div className='CompanyDetailsOuter'>
      <BrandAdminLeftBreadcrum currentStep='3' type={history.location.state?.type} />
      <form
        className='CompanyDetails'
        noValidate
        id='kt_login_signup_form'
        onSubmit={formik.handleSubmit}
      >
        <h2> Link Social Media </h2>

        <h5 className='m32'>Social Media Accounts</h5>

        <div className='row pt-4 mt-5'>
          <div className='col '>
            <h6 className='fw-bolder '>Social Media</h6>
            <select
              {...formik.getFieldProps('socialMedia1')}
              className={clsx('form-select text-gray-500 border-0 bg-gray-100', {
                'is-invalid': formik.touched.socialMedia1 && formik.errors.socialMedia1,
              })}
            >
              <option value=''>Social Media</option>
              <option value='twitter'>Twitter</option>
              <option value='instagram'>Instagram</option>
              <option value='youtube'>Youtube</option>
            </select>
            {formik.touched.socialMedia1 && formik.errors.socialMedia1 && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='text-danger' role='alert'>
                    {formik.errors.socialMedia1}
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className='col-8 mb-5'>
            <h6 className='fw-bolder '>Links/ID</h6>
            <input
              type='text'
              placeholder='Links/ID'
              {...formik.getFieldProps('links1')}
              className={clsx('form-control form-control-solid bg-gray-100', {
                'is-invalid': formik.touched.links1 && formik.errors.links1,
              })}
            />
            {formik.touched.links1 && formik.errors.links1 && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='text-danger' role='alert'>
                    {formik.errors.links1}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='row pt-4 mt-5'>
          <div className='col '>
            <h6 className='fw-bolder '>Social Media</h6>
            <select
              {...formik.getFieldProps('socialMedia2')}
              className={clsx('form-select text-gray-500 border-0 bg-gray-100', {
                'is-invalid': formik.touched.socialMedia2 && formik.errors.socialMedia2,
              })}
            >
              <option value=''>Social Media</option>
              <option value='twitter'>Twitter</option>
              <option value='instagram'>Instagram</option>
              <option value='youtube'>Youtube</option>
            </select>
            {formik.touched.socialMedia2 && formik.errors.socialMedia2 && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='text-danger' role='alert'>
                    {formik.errors.socialMedia2}
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className='col-8 mb-5'>
            <h6 className='fw-bolder '>Links/ID</h6>
            <input
              type='text'
              placeholder='Links/ID'
              {...formik.getFieldProps('links2')}
              className={clsx('form-control form-control-solid bg-gray-100', {
                'is-invalid': formik.touched.links2 && formik.errors.links2,
              })}
            />
            {formik.touched.links2 && formik.errors.links2 && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='text-danger' role='alert'>
                    {formik.errors.links2}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='row pt-4 mt-5'>
          <div className='col '>
            <h6 className='fw-bolder '>Social Media</h6>
            <select
              {...formik.getFieldProps('socialMedia3')}
              className={clsx('form-select text-gray-500 border-0 bg-gray-100', {
                'is-invalid': formik.touched.socialMedia3 && formik.errors.socialMedia3,
              })}
            >
              <option value=''>Social Media</option>
              <option value='twitter'>Twitter</option>
              <option value='instagram'>Instagram</option>
              <option value='youtube'>Youtube</option>
            </select>
            {formik.touched.socialMedia3 && formik.errors.socialMedia3 && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='text-danger' role='alert'>
                    {formik.errors.socialMedia3}
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className='col-8 mb-5'>
            <h6 className='fw-bolder '>Links/ID</h6>
            <input
              type='text'
              placeholder='Links/ID'
              {...formik.getFieldProps('links3')}
              className={clsx('form-control form-control-solid bg-gray-100', {
                'is-invalid': formik.touched.links3 && formik.errors.links3,
              })}
            />
            {formik.touched.links3 && formik.errors.links3 && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='text-danger' role='alert'>
                    {formik.errors.links3}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='w-100 flex align-items-end'>
          <div className='w-100'>
            {Array.from(Array(counter)).map((c, index) => {
              count = count + 1
              return (
                <div className='row pt-4 mt-5'>
                  <div className='col '>
                    <h6 className='fw-bolder '>Social Media</h6>
                    <select
                      placeholder='Social Media'
                      {...formik.getFieldProps('socialMedia3')}
                      className={clsx(
                        'form-select text-gray-500 border-0 bg-gray-100',
                        {
                          'is-invalid': formik.touched.socialMedia3 && formik.errors.socialMedia3,
                        },
                        {
                          'is-valid': formik.touched.socialMedia3 && !formik.errors.socialMedia3,
                        }
                      )}
                    >
                      <option value='Twitter'>Twitter</option>
                      <option value='Instagram'>Instagram</option>
                      <option value='Youtube'>Youtube</option>
                    </select>
                    {formik.touched.socialMedia3 && formik.errors.socialMedia3 && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span className='text-danger' role='alert'>
                            {formik.errors.socialMedia3}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='col-8 mb-5'>
                    <h6 className='fw-bolder '>Links/ID</h6>
                    <input
                      type='text'
                      placeholder='Links/ID'
                      {...formik.getFieldProps('links3')}
                      className={clsx('form-control form-control-solid bg-gray-100', {
                        'is-invalid': formik.touched.links3 && formik.errors.links3,
                      })}
                    />
                    {formik.touched.links3 && formik.errors.links3 && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span className='text-danger' role='alert'>
                            {formik.errors.links3}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )
            })}
          </div>
          <div>
            {counter > 0 && (
              <span
                style={{marginBottom: '25px'}}
                onClick={handleDelete}
                className='svg-icon flex svg-icon-danger bg-danger bg-opacity-5  ms-2 w-30px h-30px rounded pointer'
              >
                <KTSVG
                  className='svg-icon-3'
                  path={toAbsoluteUrl('/media/icons/Custom/Dustbin.svg')}
                />
              </span>
            )}
          </div>
        </div>
        {/* <div className='d-flex align-items-center'>
          {' '}
          <p onClick={handleAdd} className='fw-bolder text-primary mt-5 pointer'>
            + Add Social Media
          </p>
        </div> */}

        <div className='d-flex pt-4 pb-5'>
          {/* <Link
            to={{
              pathname: '/agencyDetails',
              state: history.location.state,
            }}
          > */}
          <button
            type='button'
            onClick={back}
            style={{width: '172px', height: '53px'}}
            className='btn fw-bolder btn-gray-200 w-172px h-53px'
          >
            Go Back
          </button>
          {/* </Link> */}
          <button
            type='submit'
            id='kt_sign_up_submit'
            className='btn ms-5 create-btn CompanyDetailButton'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && <span className='indicator-label'>Save & Finish</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>

        {/* <Button variant='primary' onClick={handleShow}>
          Launch demo modal
        </Button> */}

        <Modal
          show={show}
          onHide={handleClose}
          backdrop='static'
          keyboard={false}
          aria-labelledby='contained-modal-title-vcenter '
          centered
        >
          <Modal.Body className='p-0 '>
            <div style={{width: '586px'}}>
              <div
                style={{width: '100%', height: '291px'}}
                className='d-flex justify-content-center bg-primary rounded-top '
              >
                <img
                  style={{position: 'absolute', top: '15px'}}
                  src={toAbsoluteUrl('/media/logos/ModalAgency.png')}
                ></img>
              </div>
              <div
                className='text-center bg-white rounded-bottom'
                style={{width: '100%', height: '270px'}}
              >
                <h2 style={{paddingTop: '32px', fontSize: '18px'}}>Agency Sucessfully Added</h2>
                {/* <p
                  className='mb-0 text-gray-500'
                >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                  incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                  exercitation ullamco
                </p> */}
                <button
                  style={{width: '263px', marginTop: '28px'}}
                  className='btn create-btn w-263px '
                >
                  Add Brand
                </button>
                <br />
                <button
                  style={{padding: '0px', marginTop: '19px', fontWeight: 'bold'}}
                  className='btn '
                >
                  Create a Campaign
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </form>
    </div>
  )
}
