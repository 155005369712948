import React from 'react'
import {Link} from 'react-router-dom'

function Brand_Step3() {
  return (
    <div style={{width: '546px'}}>
      <div style={{fontSize: '36px', fontWeight: 700}}>Step 3 of 4</div>
      <div className='fs-14 m16'>Defining an ideal influencer</div>
      <div className='d-flex flex-column w-100 m16'>
        <div className='d-flex w-100'>
          <div
            className='col rounded'
            style={{backgroundColor: '#D2D5D940', padding: '22px 16px', marginRight: '16px'}}
          >
            <input type='checkbox' />
            <label style={{fontSize: '14px', fontWeight: 600, marginLeft: '18px'}}>
              Quality Audience
            </label>
          </div>
          <div className='col rounded' style={{backgroundColor: '#D2D5D940', padding: '22px 16px'}}>
            <input type='checkbox' />
            <label style={{fontSize: '14px', fontWeight: 600, marginLeft: '18px'}}>
              Engagement rate
            </label>
          </div>
        </div>
        <div className='d-flex w-100 m16'>
          <div
            className='col rounded'
            style={{backgroundColor: '#D2D5D940', padding: '22px 16px', marginRight: '16px'}}
          >
            <input type='checkbox' />
            <label style={{fontSize: '14px', fontWeight: 600, marginLeft: '18px'}}>
              Gender Ratio
            </label>
          </div>
          <div className='col rounded' style={{backgroundColor: '#D2D5D940', padding: '22px 16px'}}>
            <input type='checkbox' />
            <label style={{fontSize: '14px', fontWeight: 600, marginLeft: '18px'}}>
              Follower Count
            </label>
          </div>
        </div>
        <div className='d-flex w-100 m16'>
          <div
            className='col rounded'
            style={{backgroundColor: '#D2D5D940', padding: '22px 16px', marginRight: '16px'}}
          >
            <input type='checkbox' />
            <label style={{fontSize: '14px', fontWeight: 600, marginLeft: '18px'}}>Location</label>
          </div>
          <div className='col rounded' style={{backgroundColor: '#D2D5D940', padding: '22px 16px'}}>
            <input type='checkbox' />
            <label style={{fontSize: '14px', fontWeight: 600, marginLeft: '18px'}}>Age Group</label>
          </div>
        </div>
      </div>
      <div className='d-flex flex-column m16'>
        <Link to='/auth/register/step4'>
          <button
            className='w-100 btn btn-primary rounded'
            style={{fontSize: '14px', fontWeight: 600}}
          >
            Next
          </button>
        </Link>
        <Link to='/auth/register/step2'>
          <button className='w-100 btn rounded m8' style={{fontSize: '14px', fontWeight: 600}}>
            Go back
          </button>
        </Link>
      </div>
    </div>
  )
}

export default Brand_Step3
