import React, {useState} from 'react'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {Link} from 'react-router-dom'

function Selection() {
  const [brand, setBrand] = useState(false)
  const [agency, setAgency] = useState(false)
  return (
    <div style={{width: '546px'}}>
      <div>
        <div style={{fontSize: '36px', fontWeight: 700, lineHeight: '54px'}}>Create an Account</div>
        <div className='fs-14'>subtitle goes here</div>
        <div className='d-flex w-100 m32'>
          <div
            className={
              agency
                ? 'col rounded flex flex-column fs-16 dark-bg text-white pointer'
                : 'col rounded flex flex-column fs-16 light-bg pointer'
            }
            style={{height: '148px', marginRight: '16px'}}
            onClick={() => {
              setAgency(true)
              setBrand(false)
            }}
          >
            <img
              src={
                agency
                  ? toAbsoluteUrl('/media/logos/agency-grey.png')
                  : toAbsoluteUrl('/media/logos/agency-dark.png')
              }
            />
            <span>Agency</span>
          </div>
          <div
            className={
              brand
                ? 'col rounded flex flex-column fs-16 dark-bg text-white pointer'
                : 'col rounded flex flex-column fs-16 light-bg pointer'
            }
            style={{height: '148px'}}
            onClick={() => {
              setAgency(false)
              setBrand(true)
            }}
          >
            <img
              src={
                brand
                  ? toAbsoluteUrl('/media/logos/brand-light.png')
                  : toAbsoluteUrl('/media/logos/brand-dark.png')
              }
            />
            <span>Brand</span>
          </div>
        </div>
        <div className='d-flex flex-column m16'>
          <Link to='/auth/register/step1'>
            <button
              className='w-100 btn btn-primary rounded'
              style={{fontSize: '14px', fontWeight: 600}}
              disabled={!brand && !agency}
            >
              Continue
            </button>
          </Link>
          <button className='w-100 btn rounded m8' style={{fontSize: '14px', fontWeight: 600}}>
            Go to login
          </button>
        </div>
      </div>
    </div>
  )
}

export default Selection
