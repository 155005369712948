import { message, Select, Tag } from 'antd'
import clsx from 'clsx'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import httpService from '../../setup/http/httpServices'
import './basic.css'
import { InfluencerMatrixLeftBreadcrum } from './InfluencerMatrixLeftBreadcrum'
const { Option } = Select

const langs: any = [
  { value: 'Assamese', label: 'Assamese' },
  { value: 'Bengali', label: 'Bengali' },
  { value: 'English', label: 'English' },
  { value: 'Gujarati', label: 'Gujarati' },
  { value: 'Hindi', label: 'Hindi' },
  { value: 'Kannada', label: 'Kannada' },
  { value: 'Maithili', label: 'Maithili' },
  { value: 'Malayalam', label: 'Malayalam' },
  { value: 'Marathi', label: 'Marathi' },
  { value: 'Odia', label: 'Odia' },
  { value: 'Punjabi', label: 'Punjabi' },
  { value: 'Telugu', label: 'Telugu' },
  { value: 'Tamil', label: 'Tamil' },
  { value: 'Urdu', label: 'Urdu' },
]
var initialValues = {
  gender: '',
  ageGroup: '',
  interestedCategories: '',
  brandInterested: '',
  languages: '',
}

const registrationSchema = Yup.object().shape({
  gender: Yup.string().required('At least one of the fields is required'),
  ageGroup: Yup.string().required('At least one of the fields is required'),
  // languages: Yup.string().required('Language is required'),
  // interestedCategories: Yup.string()
  //   .required('Field is required'),
  //   brandInterested: Yup.string()
  //   .required('Field is required'),
})

export function InfluencerAdditionalInfo() {
  const history: any = useHistory()

  const [loading, setLoading] = useState(false)
  const [interestedCategories, setInterestedCategories] = useState<any>([])
  const [brandInterested, setBrandInterested] = useState([])
  const [languages, setLanguages] = useState<any>([])
  const [influencerAdditinalInfo, setInfluencerAdditinalInfo] = useState(initialValues)

  const formik = useFormik({
    initialValues: influencerAdditinalInfo || initialValues,
    validationSchema: registrationSchema,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      setTimeout(() => {
        try {
          // setInfluencerSocialDetails(values)
          let request = {
            // ...history.location.state,
            ...history.location.state?.influencerBasicInfo,
            // ...history.location.state?.influencerAdditinalInfo,
            ageGroup: values.ageGroup,
            gender: values.gender,
            language: languages.join(),
            interestedCategories: interestedCategories.join(),
          }
          request.ageGroup = JSON.stringify(request.ageGroup)
          var form_data = new FormData()

          for (var key in request) {
            if (key !== 'addressDetails') form_data.append(key, request[key])
          }
          if (history.location.state?._id) {
            httpService.patchData(
              '/influencer-master/' + history.location.state?._id,
              form_data,
              (response: any) => {
                setLoading(false)
                setSubmitting(false)
                let basicInfo: any = history.location.state?.influencerBasicInfo
                if (response.data.data.influencer.basicInfo?.addressDetails.length > 0) {
                  const addressId = response.data.data.influencer.basicInfo?.addressDetails.find(
                    (x) => x.isDefault
                  )._id
                  if (addressId)
                    basicInfo.addressId = addressId
                }
                history.push({
                  pathname: '/master/socialMediaDetails',
                  state: {
                    influencerBasicInfo: basicInfo,
                    influencerAdditinalInfo: {
                      interestedCategories: interestedCategories.join(),
                      gender: values.gender,
                      ageGroup: values.ageGroup,
                      interestedBrands: brandInterested.join(),
                      language: languages.join(),
                    },
                    influencerSocialDetails: history.location.state?.influencerSocialDetails,
                    _id: history.location.state?._id,
                    type: history.location.state?.type,
                  },
                })
              },
              (error: any) => {
                message.error(error?.message)
                // handleShow1()
              }
            )
          } else {
            form_data.append('instagram', '')
            form_data.append('youtube', '')
            httpService.postData(
              '/influencer-master',
              form_data,
              (response: any) => {
                setLoading(false)
                setSubmitting(false)
                let basicInfo: any = history.location.state?.influencerBasicInfo
                const addressId = response.data.data.influencer.basicInfo.addressDetails.find(
                  (x) => x.isDefault
                )._id
                basicInfo.addressId = addressId
                history.push({
                  pathname: '/master/socialMediaDetails',
                  state: {
                    influencerBasicInfo: basicInfo,
                    influencerAdditinalInfo: {
                      interestedCategories: interestedCategories.join(),
                      gender: values.gender,
                      ageGroup: values.ageGroup,
                      interestedBrands: brandInterested?.join(),
                      language: languages?.join(),
                    },
                    influencerSocialDetails: history.location.state?.influencerSocialDetails,
                    _id: response.data.data.influencer._id,
                    type: history.location.state?.type,
                  },
                })
              },
              (error: any) => {

                message.error(error.message)
                // handleShow1()
              }
            )
          }
        } catch {
          setLoading(false)
          setSubmitting(false)
          setStatus('Registration process has broken')
          // Remove logs when not needed
        }
      }, 1000)
    },
  })
  const tagRender = (props) => {
    const { label, value, closable, onClose } = props;

    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };

    return (
      <Tag
        color='#39296a'
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          marginRight: 5,
          borderRadius: '25px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        {label}
      </Tag>
    );
  };
  useEffect(() => {
    getBrands()
    httpService.getData(
      '/category-master?limit=' + 0 + '&page=' + 1 + '&search=',
      (response: any) => {
        setCategories(response.data.data.categories)
        var influencerAdditinalInfo = history.location.state?.influencerAdditinalInfo
        if (influencerAdditinalInfo?.gender) {
          formik.setFieldValue('gender', influencerAdditinalInfo?.gender)
          formik.setFieldValue(
            'ageGroup',
            typeof influencerAdditinalInfo?.ageGroup === 'object'
              ? influencerAdditinalInfo?.ageGroup[0]
              : influencerAdditinalInfo?.ageGroup
          )
          influencerAdditinalInfo.interestedCategories = typeof influencerAdditinalInfo?.interestedCategories === 'string' ? influencerAdditinalInfo?.interestedCategories.split(",") : influencerAdditinalInfo?.interestedCategories
          setInterestedCategories(influencerAdditinalInfo?.interestedCategories)
          if (
            influencerAdditinalInfo?.language &&
            influencerAdditinalInfo?.language.split(',').length > 0
          )
            setLanguages(influencerAdditinalInfo?.language.split(','))
        }
      },
      (error: any) => {
        message.error(error.message)
      }
    )
  }, [history.location.state?.influencerAdditinalInfo])

  const [brandList, setBrandList] = useState([])
  const [agency, setAgency] = useState({})

  function getBrands() {
    httpService.getData(
      '/agency-master?limit=' + 0 + '&page=' + 1 + '&search=',
      (response: any) => {
        let agencyResponse = response.data.data
        agencyResponse.agencies.map((x: any) => {
          x['value'] = x._id
          x['label'] = x.agencyDetails.agencyName
          return x
        })
        setBrandList(agencyResponse.agencies)
        // const seletedAgencyAssociated: any = agencyResponse.agencies.find((x: any) => x._id === history.location.state.companyDetails.agencyAssociated);
        // setAgency({ ...seletedAgencyAssociated })
      },
      (error: any) => {
        message.error(error.message)
      }
    )
  }

  const [categories, setCategories] = useState([])
  return (
    <div className='CompanyDetailsOuter'>
      <InfluencerMatrixLeftBreadcrum currentStep='2' />
      <form
        className='CompanyDetails'
        noValidate
        id='kt_login_signup_form'
        onSubmit={formik.handleSubmit}
      >
        <h3> Add Information </h3>

        <div className='row pt-4 mt-5'>
          <div className='col '>
            <h6 className='fw-bold required'>Category</h6>
            {categories && categories.length > 0 &&
              <Select
                mode='tags'
                tagRender={tagRender}
                style={{ width: '100%' }}
                placeholder='Example: Fashion and beauty, health and fitness, travel and lifestyle, business, etc'
                className={clsx(
                  'form-control form-control-solid bg-gray-100 p-0 ms-0',
                  {
                    'is-invalid':
                      formik.touched.interestedCategories && formik.errors.interestedCategories,
                  },
                  {
                    'is-valid':
                      formik.touched.interestedCategories && !formik.errors.interestedCategories,
                  }
                )}
                value={interestedCategories}
                onChange={(e: any) => setInterestedCategories(e)}
              // onChange={(e: any) => formik.setFieldValue('interestedCategories', e.join())}
              >
                {categories.map((x) => (
                  <Option
                    {...(interestedCategories.length >= 3 && !interestedCategories.includes(x._id)
                      ? { disabled: true }
                      : null)}
                    key={x._id}
                  >
                    {x.categoryName}
                  </Option>
                ))}
                {/* <Option key={'Sports'}>{'Sports'}</Option>
              <Option key={'Entertainment'}>{'Entertainment'}</Option> */}
              </Select>
            }
            {formik.touched.interestedCategories && formik.errors.interestedCategories && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='text-danger' role='alert'>
                    {formik.errors.interestedCategories}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className='m24'>
          <p className='fs-6 required mb-0 fw-bold'>Primary Gender</p>
          <div className='flex mt-2 form-check form-check-custom form-check-solid '>
            <label className='w-100 me-10 justify-content-start'>
              <input
                {...formik.getFieldProps('gender')}
                className={clsx(
                  'form-check-input w-20px h-20px',
                  {
                    'is-invalid': formik.touched.gender && formik.errors.gender,
                  },
                  {
                    'is-valid': formik.touched.gender && !formik.errors.gender,
                  }
                )}
                type='radio'
                name='gender'
                value='Male'
                checked={formik.values.gender === 'Male'}
                // onClick={(e: any) => {
                //   setGender('Male')
                // }
                // }
                id='flexCheckDefault'
              />
              <label className='fs-6 ms-2 w-50 mb-0'>Male</label>

              {formik.touched.gender && formik.errors.gender && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='text-danger' role='alert'>
                      {formik.errors.gender}
                    </span>
                  </div>
                </div>
              )}
            </label>
            <label className='w-100 me-10 justify-content-start'>
              <input
                {...formik.getFieldProps('gender')}
                className={clsx(
                  'form-check-input w-20px h-20px',
                  {
                    'is-invalid': formik.touched.gender && formik.errors.gender,
                  },
                  {
                    'is-valid': formik.touched.gender && !formik.errors.gender,
                  }
                )}
                type='radio'
                name='gender'
                value='Female'
                checked={formik.values.gender === 'Female'}
                // onClick={(e: any) => {
                //   setGender('Female')
                // }
                // }
                id='flexCheckDefault'
              />
              <label className='fs-6 ms-2 w-50 mb-0'>Female</label>

              {formik.touched.gender && formik.errors.gender && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='text-danger' role='alert'>
                      {formik.errors.gender}
                    </span>
                  </div>
                </div>
              )}
            </label>
            <label className='w-100 me-10 justify-content-start'>
              <input
                {...formik.getFieldProps('gender')}
                className={clsx(
                  'form-check-input w-20px h-20px',
                  {
                    'is-invalid': formik.touched.gender && formik.errors.gender,
                  },
                  {
                    'is-valid': formik.touched.gender && !formik.errors.gender,
                  }
                )}
                type='radio'
                name='gender'
                value='Others'
                checked={formik.values.gender === 'Others'}
                // onClick={(e: any) => {
                //   setGender('Other')
                // }
                // }
                id='flexCheckDefault'
              />
              <label className='fs-6 ms-2 w-50 mb-0'>Others</label>

              {formik.touched.gender && formik.errors.gender && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='text-danger' role='alert'>
                      {formik.errors.gender}
                    </span>
                  </div>
                </div>
              )}
            </label>
          </div>
        </div>
        <div className='m32'>
          <p className='fs-6 required mb-0 fw-bold'>Select Age Group</p>
          <div className='flex mt-2 form-check form-check-custom form-check-solid '>
            <label className='w-100 flex me-10 justify-content-start'>
              <input
                {...formik.getFieldProps('ageGroup')}
                className={clsx(
                  'form-check-input w-20px h-20px',
                  {
                    'is-invalid': formik.touched.ageGroup && formik.errors.ageGroup,
                  },
                  {
                    'is-valid': formik.touched.ageGroup && !formik.errors.ageGroup,
                  }
                )}
                type='radio'
                name='ageGroup'
                value={'13-17'}
                checked={formik.values.ageGroup.includes('13-17')}
                id='flexCheckDefault'
              />
              <label className='fs-6 ms-2 w-50 mb-0'>13-17</label>

              {formik.touched.ageGroup && formik.errors.ageGroup && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='text-danger' role='alert'>
                      {formik.errors.ageGroup}
                    </span>
                  </div>
                </div>
              )}
            </label>
            <label className='w-100 flex me-10 justify-content-start'>
              <input
                {...formik.getFieldProps('ageGroup')}
                className={clsx(
                  'form-check-input w-20px h-20px',
                  {
                    'is-invalid': formik.touched.ageGroup && formik.errors.ageGroup,
                  },
                  {
                    'is-valid': formik.touched.ageGroup && !formik.errors.ageGroup,
                  }
                )}
                type='radio'
                name='ageGroup'
                value={'18-24'}
                checked={formik.values.ageGroup.includes('18-24')}
                id='flexCheckDefault'
              />
              <label className='fs-6 ms-2 w-50 mb-0'>18-24</label>

              {formik.touched.ageGroup && formik.errors.ageGroup && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='text-danger' role='alert'>
                      {formik.errors.ageGroup}
                    </span>
                  </div>
                </div>
              )}
            </label>
            <label className='w-100 flex me-10 justify-content-start'>
              <input
                {...formik.getFieldProps('ageGroup')}
                className={clsx(
                  'form-check-input w-20px h-20px',
                  {
                    'is-invalid': formik.touched.ageGroup && formik.errors.ageGroup,
                  },
                  {
                    'is-valid': formik.touched.ageGroup && !formik.errors.ageGroup,
                  }
                )}
                type='radio'
                name='ageGroup'
                value={'25-34'}
                checked={formik.values.ageGroup.includes('25-34')}
                id='flexCheckDefault'
              />
              <label className='fs-6 ms-2 w-50 mb-0'>25-34</label>

              {formik.touched.ageGroup && formik.errors.ageGroup && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='text-danger' role='alert'>
                      {formik.errors.ageGroup}
                    </span>
                  </div>
                </div>
              )}
            </label>
            <label className='w-100 flex me-10 justify-content-start'>
              <input
                {...formik.getFieldProps('ageGroup')}
                className={clsx(
                  'form-check-input w-20px h-20px',
                  {
                    'is-invalid': formik.touched.ageGroup && formik.errors.ageGroup,
                  },
                  {
                    'is-valid': formik.touched.ageGroup && !formik.errors.ageGroup,
                  }
                )}
                type='radio'
                name='ageGroup'
                value={'35-44'}
                checked={formik.values.ageGroup.includes('35-44')}
                id='flexCheckDefault'
              />
              <label className='fs-6 ms-2 w-50 mb-0'>35-44</label>

              {formik.touched.ageGroup && formik.errors.ageGroup && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='text-danger' role='alert'>
                      {formik.errors.ageGroup}
                    </span>
                  </div>
                </div>
              )}
            </label>
            <label className='w-100 flex me-10 justify-content-start'>
              <input
                {...formik.getFieldProps('ageGroup')}
                className={clsx(
                  'form-check-input w-20px h-20px',
                  {
                    'is-invalid': formik.touched.ageGroup && formik.errors.ageGroup,
                  },
                  {
                    'is-valid': formik.touched.ageGroup && !formik.errors.ageGroup,
                  }
                )}
                type='radio'
                name='ageGroup'
                value={'45-65'}
                checked={formik.values.ageGroup.includes('45-65')}
                id='flexCheckDefault'
              />
              <label className='fs-6 ms-2 w-50 mb-0'>45-65</label>

              {formik.touched.ageGroup && formik.errors.ageGroup && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='text-danger' role='alert'>
                      {formik.errors.ageGroup}
                    </span>
                  </div>
                </div>
              )}
            </label>
            <label className='w-100 flex me-10 justify-content-start'>
              <input
                {...formik.getFieldProps('ageGroup')}
                className={clsx(
                  'form-check-input w-20px h-20px',
                  {
                    'is-invalid': formik.touched.ageGroup && formik.errors.ageGroup,
                  },
                  {
                    'is-valid': formik.touched.ageGroup && !formik.errors.ageGroup,
                  }
                )}
                type='radio'
                name='ageGroup'
                value={'65+'}
                checked={formik.values.ageGroup.includes('65+')}
                id='flexCheckDefault'
              />
              <label className='fs-6 ms-2 w-50 mb-0'>65+</label>

              {/* {formik.touched.ageGroup && formik.errors.ageGroup && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='text-danger' role='alert'>
                      {formik.errors.ageGroup}
                    </span>
                  </div>
                </div>
              )} */}
            </label>
          </div>
        </div>
        {/* <div className='row pt-4 mt-5'>
          <div className='col '>
            <h6 className='fw-bold required'>Brand Interested</h6>
            <Select
              mode='tags'
              style={{width: '100%'}}
              placeholder='Select one or more brands'
              className={clsx(
                'form-control form-control-solid bg-gray-100 p-0 ms-0',
                {
                  'is-invalid': formik.touched.brandInterested && formik.errors.brandInterested,
                },
                {
                  'is-valid': formik.touched.brandInterested && !formik.errors.brandInterested,
                }
              )}
              value={brandInterested}
              onChange={(e: any) => setBrandInterested(e)}
              options={brandList}
            >
              <Option key={'CocoCola'}>{'CocoCola'}</Option>
              <Option key={'Pepsi'}>{'Pepsi'}</Option>
            </Select>
            {formik.touched.brandInterested && formik.errors.brandInterested && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='text-danger' role='alert'>
                    {formik.errors.brandInterested}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div> */}
        <div className='col m32'>
          <h6 className='fw-bold required'>Language</h6>
          <Select
            mode='tags'
            tagRender={tagRender}
            style={{ width: '100%' }}
            placeholder='Choose one or more languages'
            className={clsx(
              'form-control form-control-solid bg-gray-100 p-0 ms-0',
              {
                'is-invalid': formik.touched.languages && formik.errors.languages,
              },
              {
                'is-valid': formik.touched.languages && !formik.errors.languages,
              }
            )}
            value={languages}
            onChange={(e: any) => {
              setLanguages(e)
            }}
          >
            {langs.map((lang: any) => (
              <Option key={lang.value}>{lang.label}</Option>
            ))}
          </Select>
          {formik.touched.languages && formik.errors.languages && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span className='text-danger' role='alert'>
                  {formik.errors.languages}
                </span>
              </div>
            </div>
          )}
        </div>

        <div className='d-flex pt-4 pb-5'>
          {interestedCategories.length > 0 && <Link
            to={{
              pathname: '/master/influencerBasicInfo',
              state: {
                influencerBasicInfo: history.location.state?.influencerBasicInfo,
                influencerAdditinalInfo: {

                  interestedCategories: interestedCategories,
                  gender: formik.values.gender,
                  ageGroup: formik.values.ageGroup,
                  interestedBrands: brandInterested?.join(),
                  language: languages?.join(),
                },
                influencerSocialDetails: history.location.state?.influencerSocialDetails,
                _id: history.location.state?._id,
                type: history.location.state?.type,
              },
            }}
          >
            <button
              type='button'
              style={{ width: '172px', height: '53px' }}
              className='btn fw-bolder btn-gray-200 w-172px h-53px'
            >
              Go Back
            </button>
          </Link>}
          <button
            type='submit'
            id='kt_sign_up_submit'
            className='btn ms-5 create-btn CompanyDetailButton'
          // disabled={formik.isSubmitting || !formik.isValid || interestedCategories=="" || languages==""}
          >
            {!loading && <span className='indicator-label'>Next</span>}
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}
