/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSSVariableValue} from '../../../_metronic/assets/ts/_utils'

type Props = {
  className: string,
  audienceData:any
}

const ColumnDoubleChart: React.FC<Props> = ({className,audienceData}) => {
  const gender=audienceData?.audience_genders_per_age
  const cat:Array<String> =[]
  const male:Array<Number> =[]
  const female:Array<Number> =[]
  const chartRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    gender?.map((reach:any)=>{cat.push(reach.code);male.push(Math.round(reach.male*100));female.push(Math.round(reach.female*100));})
  }, [gender])
  useEffect(() => {
    if (!chartRef.current) {
      return
    }
    var chart1
    if (cat.length>0) {
    chart1 = new ApexCharts(chartRef.current, chart1Options(cat,male,female))
    }
    if (chart1) {
      chart1.render()
      window.dispatchEvent(new Event('resize'));   
    }

    return () => {
      if (chart1) {
        chart1.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [female])
  
  return (
    <div className={`${className}`}>
      <div className='d-flex flex-column'>
        <div ref={chartRef} id='kt_charts_widget_3_chart' style={{height: 'auto'}}></div>     
      </div>
    </div>
  )}
const chart1Options = (cat:any,female:any,male:any): ApexOptions => {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  
  
  return {
    series: [{
        name: 'Male',
        data: male
      },{
        name: 'Female',
        data: female
      }],
        
        chart: {
          height: 350,
          type: 'bar',
          toolbar:{
            show:false
          }
        },
        plotOptions: {
          bar: {
            borderRadius: 17,
            columnWidth: '40%',
            colors:{
                
                backgroundBarOpacity:1
            }
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: 0
        },
        
        grid: {
          show:true,
          strokeDashArray: 3,
          borderColor: '#F2F4F9',
        },
        legend:{
            show:false
        },
        xaxis: {
          labels: {
            rotate: -45
          },
          categories: cat,
          axisBorder:{
            show:false
          },
          axisTicks:{
            show:false
          }
        },
        fill: {
          colors:['#513A98','#E72281']
        }
    
    
  }
  
}

export {ColumnDoubleChart}