import React, {useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSSVariableValue} from '../../../_metronic/assets/ts/_utils'

type Props = {
  className: string,
  audienceData:any,
  type:string
}

const DonutChart2: React.FC<Props> = ({className,audienceData,type}) => {
  const lan:Array<any> =[]
  const lanData:Array<Number> =[]
  const language=audienceData?.audience_languages
  useEffect(() => { 
 language?.map((reach:any)=>{ if(reach.name){lan.push(reach.name)}else{lan.push(reach.code)}
  lanData.push(Math.round(reach.weight*100))})
}, [lan])

  // useEffect(() => {
  //   type=='Location'?
  //   data?.map((reach:any)=>{setLoc({name:reach.Name,weight:(Math.round(reach.weight*100))})}):
  //   data?.map((reach:any)=>{;cat.push(reach.code);dat.push(Math.round(reach.weight*100))})
  // }, [data])
  const chartRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    if (!chartRef.current) {
      return
    }
    var chart1
    if (lan.length>0) {
     chart1 = new ApexCharts(chartRef.current, chart1Options(lanData,lan))
    }
    if (chart1) {
      chart1.render()
      window.dispatchEvent(new Event('resize'));   
    }

    return () => {
      if (chart1) {
        chart1.destroy()
      }
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef,lan])

  return (
    <div className={`${className}`}>
      <div className='d-flex flex-column'>
        <div ref={chartRef} id='kt_charts_widget_3_chart' style={{height: 'auto'}}></div>
      </div>
    </div>
  )
} 
const chart1Options = (lanData:any,lan:any): ApexOptions => {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  
  return {
    
    series: lanData,
    labels:lan
    ,
    chart: {
      type: 'donut',
    },
    dataLabels:{
      enabled:false
    },
    
    plotOptions: {
      pie: {
        customScale:1,
        donut:{
          labels:{
            show:true,
            value:{
              show:true
            },
            total:{
              show:true
            }
          }
        }
      },},
    legend:{
      show:false
    },
    
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 270,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
  }

 }

export {DonutChart2}