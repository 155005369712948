import { SearchOutlined } from '@ant-design/icons'
import { Input, message, Pagination } from 'antd'
import { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap-v5'
import { useHistory } from 'react-router-dom'
import { KTSVG, toAbsoluteUrl } from '../../_metronic/helpers'
import httpService from '../../setup/http/httpServices'
import { ProfileAvatar } from '../modules/widgets/components/ProfileAvatar'
import { FaArrowAltCircleRight, FaArrowAltCircleLeft } from 'react-icons/fa';
import './Imagecarousel.css'
import './Mycard.css'


export function Shortlist(props: any) {

  let box = document.querySelector('.product-container');
  const [influencerListParams, setinfluencerListParams] = useState({
    limit: 10,
    page: 1,
    total: 0,
    search: '',
  })
  const pageType = props.type
  const [totalCount, setTotalCount] = useState(null)
  const [paginationCount, setpaginationCount] = useState(null)
  const [apiComplete, setapiComplete] = useState(false)
  const [allData, setAllData] = useState(null)
  const [show1, setShow1] = useState(false)
  const [shortlistStatus, setshortlistStatus] = useState('applied')
  const [id, setId] = useState(null)
  const [influencerList, setinfluencerList] = useState([])
  // const [type, setType] = useState([{Micro:0}])
  const [standByActive, setstandByActive] = useState(false)
  const [show, setShow] = useState(false)
  const [show2, setShow2] = useState(false)
  const handleClose = () => setShow(false)
  const [searchParam] = useState(['city', 'name'])
  const [priceMatrix, setPriceMatrix] = useState(null)
  const history: any = useHistory()
  const [previewData, setPreviewData] = useState([{
    image:'https://sehaye-insta-login-test.s3.ap-south-1.amazonaws.com/sehayeTemplates/no_preview_img.png'
  }]) as any;
  
  // props.type

  const SliderData = [
    {
      image:
        'https://fastly.picsum.photos/id/883/200/300.jpg?hmac=L62LMsIBfvhnxlTirzshbyv6HarwJvd-tSSBcIvbCVw'
    },
    {
      image:
        'https://images.unsplash.com/photo-1546768292-fb12f6c92568?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80'
    },
    {
      image:
        'https://images.unsplash.com/photo-1501446529957-6226bd447c46?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1489&q=80'
    },
    {
      image:
        'https://images.unsplash.com/photo-1483729558449-99ef09a8c325?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1350&q=80'
    },
    {
      image:
        'https://images.unsplash.com/photo-1475189778702-5ec9941484ae?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1351&q=80'
    },
    {
      image:
        'https://images.unsplash.com/photo-1503177119275-0aa32b3a9368?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1350&q=80'
    }
  ];

  const [showPreview, setShowPreview] = useState(false)
  const [current, setCurrent] = useState(0);
  const length = SliderData.length;

  const nextSlide = () => {
    let width = 460;
    box.scrollLeft = box.scrollLeft + width;
    // console.log(box?.scrollLeft)
  };

  const prevSlide = () => {
    let width = 460;
    box.scrollLeft = box.scrollLeft - width;
    // console.log(box?.scrollLeft)
  };
  // if (!Array.isArray(SliderData) || SliderData.length <= 0) {
  //   return null;
  // }
  

  useEffect(() => {
    try {
      getAllInfluencer()
    } catch {}
  }, [])
  // useEffect(() => {
  //   setFollowes()
  // },[allData])
  useEffect(() => {
    if (apiComplete) {
      if (influencerListParams.search !== '') {
        setinfluencerList(search(influencerListParams.search))
      } else {
        setinfluencerList(allData[shortlistStatus])
      }
    }
  }, [influencerListParams.page, influencerListParams.search, shortlistStatus])
  const getAllInfluencer = () => {
    setapiComplete(false)

    httpService.getData(
      '/influencer-onboarding/priceMatrix/' + `${props.campaignId}`,
      (response: any) => {
        let priceMatrixData = response.data.data
        setPriceMatrix(priceMatrixData)
      },
      (error: any) => {
        message.error(error.message)
      }
    )
    getInfluencers()
    setInterval(() => {
      getInfluencers();
    }, 10000);
  }
  useEffect(() => {
    window.sessionStorage.setItem("shortlistStatus", shortlistStatus);
  }, [shortlistStatus])
  const getInfluencers = () => {
    httpService.getData(
      '/influencer-onboarding/' + `${props.campaignId}`,
      (response: any) => {
        const status = window.sessionStorage.getItem("shortlistStatus")
        let data = response.data.data
        setAllData(data.influencers)
        setinfluencerList(
          data.influencers[status] ? data.influencers[status] : []
        )
        setTotalCount(data.counts ? data.counts : null)
        var standByCount = data.counts.shortListed / 2
        standByCount = standByCount - data.counts.onHold
        setpaginationCount(data.counts[status])
        setapiComplete(true)
        setstandByActive(standByCount > 1)
      },
      (error: any) => {
        message.error(error.message)
        setapiComplete(true)
      }
    )
  }
  function search(q) {
    return allData[shortlistStatus].filter((item) => {
      return searchParam.some((newItem) => {
        return (
          item.influencer.basicInfo[newItem] &&
          item.influencer.basicInfo[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
        )
      })
    })
  }

  const getInfluencerPreview = (influencerId) => {
    httpService.getData(
      '/influencer-onboarding/influencerPreview/' + influencerId,
      (response: any) => {
        let previewObject = response.data.data
        // console.log("previewImageResponse -- ", response?.data?.data)
        setPreviewData(previewObject.imgList)
      },
      (error: any) => {
        message.error(error.message)
      }
    )
  }

  const shortlistaction = (id, action) => {
    if (action === 'onHold') {
          // Calculate the number of influencers required to meet the 5:1 ratio
      const requiredStandbyCount = Math.ceil(totalCount.shortListed / 5);

      // Check if adding one more to standby would exceed the 5:1 ratio
    if (totalCount.onHold >= requiredStandbyCount) {
      // Do nothing, as the ratio is already met
      return;
    }
  }
      // if (totalCount.shortListed % 5 >= totalCount.onHold) {
      //   //do nothing
      // } else {
      //   // setShow(true)
      //   // return
      // }
    // }
    httpService.patchData(
      '/influencer-onboarding/' + id + '/' + action,
      {},
      (response: any) => {
        message.success('Influncer moved successfully')
        getAllInfluencer()
        // if (action === 'shortListed') {
        //   setShow1(true)
        // } else if (action === 'onHold') {
        //   setShow(true)
        // }
      },
      (error: any) => {
        message.error(error.message)
      }
    )
  }

  function numFormatter(num) {
    if (num > 999 && num < 1000000) {
      return (num / 1000).toFixed(1) + 'K' // convert to K for number from > 1000 < 1 million
    } else if (num > 1000000) {
      return (num / 1000000).toFixed(1) + 'M' // convert to M for number from > 1 million
    } else if (num < 900) {
      return num // if value < 1000, nothing to do
    }
  }
  function disableStandByButton() {
    return Math.floor(totalCount.shortListed / 5) > totalCount.onHold
  }
  // function disableStandByButtonByFollowers(props:any) {
  //   // return (Math.floor(totalCount.shortListed / 5) > (totalCount.onHold))
  // let Micro=0
  // let micro=0
  //   type.map((t)=>(
  //     Micro=t['Micro'],
  //     micro=t['micro']
  //   ))
  //   if(Micro>=5){return true}
  //   else if(micro>=5 && props=='micro'){return true}
  //   else {return false}
  // for (var l in type) {
  //   if (type.hasOwnProperty(l)){
  //   }}

  // }
  // function setFollowes() {
  //   // return (Math.floor(totalCount.shortListed / 5) > (totalCount.onHold))
  //   let Count=[]
  //   if(allData){
  //   const micro = allData['shortListed'].filter(data => data?.priceMaster?.bucketName === 'micro');
  //   const Micro = allData['shortListed'].filter(data => data?.priceMaster?.bucketName === 'Micro');

  //   // allData['shortListed'].map((data)=>(

  //   //  Count.push(data.priceMaster.bucketName)
  //   // ))
  //   Count.push({micro:micro.length})
  //   Count.push({Micro:Micro.length})
  //   setType(Count);}
  // }
  return (
    (totalCount || apiComplete) && (
      <>
        <div>
          <div className='d-flex flex-row flex-column-fluid h-800px'>
            <div className='TableDiv' style={{height: '787px'}}>
              <div className='TableHeader w-100 justify-content-start'>
                <h3 className='m-0'>
                  Influencers
                  <p className='text-gray-400 m-0 fs-7 fw-bold'>
                    {totalCount.applied + totalCount.shortListed + totalCount.onHold} influencer
                  </p>
                </h3>
                <div className='d-flex sub-menu w-100 ms-5' style={{minWidth: '450px'}}>
                  <ul className='nav nav-pills justify-content-around align-content-center'>
                    <li className='nav-item '>
                      <a
                        style={{borderRadius: '49px'}}
                        onClick={() => setshortlistStatus('applied')}
                        className={
                          shortlistStatus == 'applied'
                            ? 'nav-link d-flex w-auto h-40px flex ps-6 pe-6 active'
                            : 'nav-link d-flex w-auto h-40px flex text-gray-400 fw-bold'
                        }
                      >
                        {' '}
                        Applicants ( {totalCount.applied} )
                      </a>
                    </li>
                    <li className='nav-item '>
                      <a
                        style={{borderRadius: '49px'}}
                        onClick={() => setshortlistStatus('shortListed')}
                        className={
                          shortlistStatus == 'shortListed'
                            ? 'nav-link d-flex w-auto h-40px flex ps-6 pe-6 active'
                            : 'nav-link d-flex w-auto h-40px flex text-gray-400 fw-bold'
                        }
                      >
                        {' '}
                        Shortlisted ( {totalCount.shortListed} )
                      </a>
                    </li>
                    <li className='nav-item '>
                      <a
                        style={{borderRadius: '49px'}}
                        onClick={() => setshortlistStatus('onHold')}
                        className={
                          shortlistStatus == 'onHold'
                            ? 'nav-link d-flex w-auto h-40px flex ps-6 pe-6 active'
                            : 'nav-link d-flex w-auto h-40px flex text-gray-400 fw-bold'
                        }
                      >
                        {' '}
                        StandBy ( {totalCount.onHold} )
                      </a>
                    </li>
                  </ul>
                </div>
                <div className='d-flex w-100 align-items-center justify-content-end'>
                  <link
                    href='https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css'
                    rel='stylesheet'
                  />
                  <Input
                    placeholder='Search influencers'
                    className='form-control-solid bg-gray-100 rounded w-250px h-38px fs-7'
                    onChange={(e: any) =>
                      setinfluencerListParams({
                        ...influencerListParams,
                        ...{search: e.target.value},
                      })
                    }
                    prefix={
                      <>
                        <SearchOutlined />
                      </>
                    }
                  />
                  {/* <div className='flex justify-content-end '>
                    <button className='btn bg-primary bg-opacity-5 px-3 text-white d-flex flex justify-content-center me-2 h-35px fs-7 ms-3'>
                      <KTSVG
                        className='svg-icon-3 svg-icon-primary flex m-0'
                        path={toAbsoluteUrl('/media/icons/Custom/Filter.svg')}
                      />
                    </button>
                  </div> */}
                </div>
              </div>
              <div className='card-body mt-0 p-0 h-700px'>
                {/* begin::Table container */}
                <div className='table-responsive'>
                  {/* begin::Table */}
                  <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                    {/* begin::Table head */}
                    <thead>
                      <tr className='fw-bolder text-muted'>
                        <th className='bg-primary bg-opacity-5 min-w-190px'>
                          <span className='d-flex ms-8'>Name</span>
                        </th>
                        <th className='bg-primary bg-opacity-5 min-w-120px'>
                          <span className='d-flex'>Type</span>
                        </th>
                        <th className='bg-primary bg-opacity-5 min-w-110px'>
                          <span className='d-flex'>Category</span>
                        </th>

                        <th className='bg-primary bg-opacity-5 min-w-120px'>
                          <span className='d-flex'>Followers</span>
                        </th>

                        <th className='bg-primary  bg-opacity-5 min-w-120px'>
                          <span className='d-flex'>Avg. Views</span>
                        </th>
                        <th className='bg-primary  bg-opacity-5 min-w-120px'>
                          <span className='d-flex'>Eng. Rate</span>
                        </th>

                        <th className='bg-primary bg-opacity-5 max-w-130px d-flex justify-content-end'>
                          Actions
                        </th>
                      </tr>
                    </thead>
                    {/* end::Table head */}
                    {/* begin::Table body */}
                    <tbody>
                      {influencerList.length > 0 &&
                        influencerList.map((data: any, index: number) => (
                          <tr key={index}>
                            {/* <td>
                        <div className='form-check ms-5 form-check-sm form-check-custom form-check-solid'>
                          <input
                            className='form-check-input widget-9-check'
                            type='checkbox'
                            value='1'
                          />
                        </div>
                      </td> */}
                            <td className='fw-bolder ps-8'>
                              <span className='flex justify-content-start w-100'>
                              <a 
                                onClick={(e: any) => {
                                  setShowPreview(true);
                                  getInfluencers();
                                  getInfluencerPreview(data?.influencer?._id)
                                }}
                                >

                                <ProfileAvatar
                                  src={
                                    data?.influencer.basicInfo.avatar
                                      ? data?.influencer.basicInfo.avatar
                                      : null
                                  }
                                  className='w-100 rounded-circle ml-2 '
                                  name={
                                    data?.influencer.basicInfo.name
                                      ? data?.influencer.basicInfo.name[0].toUpperCase()
                                      : 'NA'
                                  }
                                ></ProfileAvatar>
                                </a>

                                <span className='fs-6 ms-4 mt-1 '>
                                  <label
                                    className={'fs-7 text-gray-400 m-0'
                                    }
                                  >
                                    {data?.influencer.basicInfo.name}
                                  </label>
                                  {data.influencer.basicInfo.addressDetails?.find(
                                    (x) => x.isDefault
                                  )?.city && (
                                    <label className='flex fs-7'>
                                      <KTSVG
                                        className='svg-icon me-1'
                                        path={toAbsoluteUrl('/media/icons/Custom/MapMarker.svg')}
                                      />{' '}
                                      {
                                        data.influencer.basicInfo.addressDetails.find(
                                          (x) => x.isDefault
                                        )?.city
                                      }
                                      ,{' '}
                                      {
                                        data.influencer.basicInfo.addressDetails.find(
                                          (x) => x.isDefault
                                        )?.state
                                      }
                                    </label>
                                  )}
                                </span>
                              </span>
                            </td>
                            <td className='fw-bolder'>{data?.priceMaster?.bucketName}</td>

                            <td className='fw-bolder'>
                              <div className='symbol-hover'>
                                {data?.influencer.additionalInfo?.interestedCategories &&
                                data?.influencer.additionalInfo?.interestedCategories.length > 0
                                  ? data?.influencer.additionalInfo?.interestedCategories.map(
                                      (data: any, index: number) => (
                                        <span
                                          className='border rounded-circle me-2'
                                          style={{display: 'inline-block'}}
                                        >
                                          <img
                                            src={toAbsoluteUrl(data.avatar)}
                                            alt=''
                                            height={30}
                                            width={30}
                                          />
                                        </span>
                                      )
                                    )
                                  : 'NA'}
                              </div>
                            </td>

                            <td className='fw-bolder'>
                              {props.campaignDetails.platform === 'Youtube' ? (
                                data?.influencer.socialMediaDetails?.youtube?.basicStats
                                  ?.followers ? (
                                  <>
                                    {' '}
                                    {/* <KTSVG
                                      className='svg-icon-3'
                                      path={toAbsoluteUrl('/media/icons/Custom/peeps.svg')}
                                    /> */}
                                    &nbsp;
                                    <KTSVG
                                      className='svg-icon me-1'
                                      path={toAbsoluteUrl('/media/icons/Custom/people.svg')}
                                    />
                                    {numFormatter(
                                      data?.influencer.socialMediaDetails?.youtube?.basicStats
                                        ?.followers
                                    )}{' '}
                                  </>
                                ) : (
                                  'NA'
                                )
                              ) : data?.influencer.socialMediaDetails?.instagram.basicStats
                                  ?.followers ? (
                                <>
                                  {' '}
                                  {/* <KTSVG
                                    className='svg-icon-3'
                                    path={toAbsoluteUrl('/media/icons/Custom/peeps.svg')}
                                  /> */}
                                  &nbsp;
                                  <KTSVG
                                    className='svg-icon me-1'
                                    path={toAbsoluteUrl('/media/icons/Custom/people.svg')}
                                  />
                                  {numFormatter(
                                    data?.influencer.socialMediaDetails?.instagram?.basicStats
                                      ?.followers
                                  )}{' '}
                                </>
                              ) : (
                                'NA'
                              )}
                            </td>
                            <td className='fw-bolder'>
                              {props.campaignDetails.platform === 'Youtube' ? (
                                data?.influencer?.socialMediaDetails?.youtube?.basicStats
                                  ?.avgViews ? (
                                  <>
                                    {/* {' '} */}
                                    {/* <KTSVG
                                      className='svg-icon-3'
                                      path={toAbsoluteUrl('/media/icons/Custom/eye.svg')}
                                    /> */}
                                    <KTSVG
                                      className='svg-icon me-1'
                                      path={toAbsoluteUrl('/media/icons/Custom/eye2.svg')}
                                    />
                                    {numFormatter(
                                      data?.influencer?.socialMediaDetails?.youtube?.basicStats
                                        ?.avgViews
                                    )}{' '}
                                  </>
                                ) : (
                                  'NA'
                                )
                              ) : data?.influencer?.socialMediaDetails?.instagram?.basicStats
                                  ?.avgViews ? (
                                <>
                                  {' '}
                                  {/* <KTSVG
                                    className='svg-icon-3'
                                    path={toAbsoluteUrl('/media/icons/Custom/eye2.svg')}
                                  /> */}
                                  &nbsp;
                                  <KTSVG
                                    className='svg-icon me-1'
                                    path={toAbsoluteUrl('/media/icons/Custom/eye2.svg')}
                                  />
                                  {numFormatter(
                                    data?.influencer?.socialMediaDetails?.instagram?.basicStats
                                      ?.avgViews
                                  )}{' '}
                                </>
                              ) : (
                                'NA'
                              )}
                            </td>
                            <td className='fw-bolder'>
                              {props.campaignDetails.platform === 'Youtube' ? (
                                data?.influencer.socialMediaDetails?.youtube?.basicStats
                                  ?.engagementRate ? (
                                  <>
                                    {/* <KTSVG
                                      className='svg-icon-3'
                                      path={toAbsoluteUrl('/media/icons/Custom/percentage.svg')}
                                    /> */}
                                    {/* &nbsp;&nbsp;{' '} */}
                                    <KTSVG
                                      className='svg-icon-3 me-1'
                                      path={toAbsoluteUrl('/media/icons/Custom/percentage.svg')}
                                    />
                                    {(data?.influencer.socialMediaDetails?.youtube?.basicStats?.engagementRate).toFixed(
                                      2
                                    )}
                                    %
                                  </>
                                ) : (
                                  'NA'
                                )
                              ) : data?.influencer.socialMediaDetails?.instagram?.basicStats
                                  ?.engagementRate ? (
                                <>
                                  {/* <KTSVG
                                    className='svg-icon-3 me-1'
                                    path={toAbsoluteUrl('/media/icons/Custom/percentage.svg')}
                                  /> */}
                                  {/* &nbsp;&nbsp;{' '} */}
                                  <KTSVG
                                    className='svg-icon-5 me-1'
                                    path={toAbsoluteUrl('/media/icons/Custom/percentage.svg')}
                                  />
                                  {(data?.influencer.socialMediaDetails?.instagram?.basicStats?.engagementRate).toFixed(
                                    2
                                  )}
                                  %
                                </>
                              ) : (
                                'NA'
                              )}
                            </td>
                            <td className='d-flex justify-content-end'>
                              {shortlistStatus === 'onHold' && !data.isPaid && (
                                <>
                                  <button
                                    onClick={() => {
                                      shortlistaction(data?._id, 'shortListed')
                                    }}
                                    className='btn bg-primary bg-opacity-10 border-0 p-2 px-4 h-35px text-primary me-2'
                                  >
                                    {' '}
                                    <KTSVG
                                      className='svg-icon svg-icon-primary'
                                      path={toAbsoluteUrl('/media/icons/Custom/thumbsUp.svg')}
                                    />
                                    Shortlist
                                  </button>
                                  <button
                                    onClick={() => shortlistaction(data?._id, 'applied')}
                                    className='btn bg-danger bg-opacity-10 border-0 p-2 px-4 h-35px text-danger'
                                  >
                                    {' '}
                                    <img
                                      src={toAbsoluteUrl('/media/logos/Block.png')}
                                      alt=''
                                      width='12px'
                                      height='12px'
                                      style={{marginRight: '6px'}}
                                    />
                                    Remove
                                  </button>
                                </>
                              )}
                              {shortlistStatus === 'shortListed' && !data.isPaid && (
                                <>
                                  <button
                                    className='btn bg-gray-100 border-0 p-2 px-4 h-35px text-dark me-2'
                                    // disabled={!standByActive}
                                    onClick={() => {
                                      disableStandByButton()
                                        ? shortlistaction(data?._id, 'onHold')
                                        : setShow(true)
                                    }}
                                  >
                                    {' '}
                                    <KTSVG
                                      className='svg-icon svg-icon-gray-700'
                                      path={toAbsoluteUrl('/media/icons/Custom/boardPin.svg')}
                                    />
                                    StandBy
                                  </button>
                                  <button
                                    className='btn bg-danger bg-opacity-10 border-0 p-2 px-4 h-35px text-danger'
                                    onClick={() => shortlistaction(data?._id, 'applied')}
                                  >
                                    {' '}
                                    <img
                                      src={toAbsoluteUrl('/media/logos/Block.png')}
                                      alt=''
                                      width='12px'
                                      height='12px'
                                      style={{marginRight: '6px'}}
                                    />
                                    Remove
                                  </button>
                                </>
                              )}
                              {shortlistStatus === 'applied' && !data.isPaid && (
                                <>
                                  <button
                                    className='btn bg-primary bg-opacity-10 border-0 p-2 px-4 h-35px text-primary me-2'
                                    onClick={() => shortlistaction(data?._id, 'shortListed')}
                                  >
                                    {' '}
                                    <KTSVG
                                      className='svg-icon svg-icon-primary'
                                      path={toAbsoluteUrl('/media/icons/Custom/thumbsUp.svg')}
                                    />
                                    Shortlist
                                  </button>

                                  {disableStandByButton() && (
                                    <button
                                      className='btn bg-gray-100 border-0 p-2 px-4 h-35px text-dark'
                                      // disabled={!standByActive}
                                      onClick={() => {
                                        shortlistaction(data?._id, 'onHold')
                                      }}
                                    >
                                      {' '}
                                      <KTSVG
                                        className='svg-icon svg-icon-gray-700'
                                        path={toAbsoluteUrl('/media/icons/Custom/boardPin.svg')}
                                      />
                                      StandBy
                                    </button>
                                  )}
                                </>
                              )}
                              <Modal
                                show={show}
                                onHide={handleClose}
                                aria-labelledby='contained-modal-title-vcenter'
                                centered
                              >
                                <Modal.Body>
                                  <h3 className='m-0'>View Influencer</h3>
                                  <h6 className='m24'>Shortlisted to Standby works on 5:1 ratio</h6>
                                  <p className='mb-0 '>
                                    For every 5 shortlisted influencers, 1 influencer maybe added on
                                    standby.
                                  </p>
                                  <div className='w-100 d-flex m24'>
                                    <button
                                      className='btn btn-primary w-100 fs-6 dialogButton'
                                      onClick={() => {
                                        setShow(false)
                                      }}
                                    >
                                      Gotcha! 👍
                                    </button>
                                  </div>
                                </Modal.Body>
                              </Modal>
                              <Modal
                                show={show2}
                                onHide={handleClose}
                                aria-labelledby='contained-modal-title-vcenter'
                                centered
                              >
                                <Modal.Body>
                                  <h3 className='m-0'>View Influencer</h3>
                                  <h6 className='m24'>Shortlisted to Standby works on 5:1 ratio</h6>
                                  <p className='mb-0 '>
                                    Selected Influencer cannot have followers higher than the major
                                    followers in Shortlist
                                  </p>
                                  <div className='w-100 d-flex m24'>
                                    <button
                                      className='btn btn-primary w-100 fs-6 dialogButton'
                                      onClick={() => {
                                        setShow2(false)
                                      }}
                                    >
                                      Gotcha! 👍
                                    </button>
                                  </div>
                                </Modal.Body>
                              </Modal>
                              <Modal
                                show={show1}
                                onHide={handleClose}
                                aria-labelledby='contained-modal-title-vcenter'
                                centered
                              >
                                <Modal.Body>
                                  <h3 className='m-0'>View Influencer</h3>
                                  <span className='flex justify-content-start w-100 m24'>
                                    <ProfileAvatar
                                      src={
                                        data?.influencer.basicInfo.avatar
                                          ? data?.influencer.basicInfo.avatar
                                          : null
                                      }
                                      className='w-100 rounded-circle ml-2 '
                                      name={
                                        data?.influencer.basicInfo.name
                                          ? data?.influencer.basicInfo.name[0].toUpperCase()
                                          : 'NA'
                                      }
                                    ></ProfileAvatar>

                                    <span className='fs-6 ms-4 mt-1 '>
                                      <label
                                        className={
                                          !data.isPaid
                                            ? 'fs-7 m-0 grayout'
                                            : 'fs-7 text-gray-400 m-0'
                                        }
                                      >
                                        {data?.influencer.basicInfo.name}
                                      </label>
                                      <label className='flex fs-7'>
                                        <KTSVG
                                          className='svg-icon me-1'
                                          path={toAbsoluteUrl('/media/icons/Custom/MapMarker.svg')}
                                        />{' '}
                                        {data?.influencer.basicInfo.city},{' '}
                                        {data?.influencer.basicInfo.state}
                                      </label>
                                    </span>
                                  </span>
                                  <p className='mb-0 m24'>
                                    Detailed influencer profile can be viewed once you shortlist
                                    them and make a payment.
                                  </p>
                                  <div className='w-100 d-flex m24'>
                                    <button
                                      className='btn fw-bolder w-100 btn-gray-200 fs-6 dialogButton'
                                      onClick={() => {
                                        setShow1(false)
                                      }}
                                    >
                                      Continue Shortlisting
                                    </button>
                                    <button
                                      className='btn btn-primary w-100 fs-6 dialogButton ms-5'
                                      onClick={() => {
                                        history.push({
                                          pathname:
                                            '/campaign/influencerOnborading/' +
                                            history.location.state._id,
                                          state: {
                                            ...history.location.state,
                                            ...{type: 'influencerOnboard', status: 'Payment'},
                                          },
                                        })
                                      }}
                                    >
                                      Proceed to Pay
                                    </button>
                                  </div>
                                </Modal.Body>
                              </Modal>
                            </td>
                          </tr>
                        ))}
                      {influencerList.length === 0 && (
                        <tr>
                          <td colSpan={7}>
                            <div className='m-20'>
                              <div className='flex'>
                                <img
                                  className='ms-20'
                                  src={toAbsoluteUrl('/media/logos/TableVector.png')}
                                ></img>
                              </div>
                              <div className='flex'>
                                <div>
                                  <h2 className='mb-0 m24 flex'>No applicants to review.</h2>
                                  {/* <p className='m-0 mt-5 w-600px ms-auto me-auto text-center'>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                    eiusmod tempor incididunt ut labore et dolore magna aliquaLorem
                                    ipsum dolor sit amet
                                  </p> */}
                                </div>
                              </div>
                              {/* <div className='flex'>
                                <Link
                                  to={{
                                    pathname: '/companyDetails',
                                    state: {type: pageType},
                                  }}
                                >
                                  <button className='btn m24 TableButton btn-pink text-white'>
                                    + Add New {pageType}
                                  </button>
                                </Link>
                              </div> */}
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>

                    {/* end::Table body */}
                  </table>
                  
                  <Modal
                    show={showPreview}
                    onHide={() => {
                    }}
                    aria-labelledby='contained-modal-title-vcenter'
                    centered
                  >
                    <Modal.Header>
                      <Modal.Title>
                        <h1 className='fw-bolder fs-3'>Preview</h1>
                      </Modal.Title>
                    </Modal.Header>

                    {/* <Modal.Body
                      style={{
                        margin: 'auto',
                        height: '500px'
                      }}
                    > */}

                    <div style={{
                        margin: 'auto',
                        height: '500px'
                      }}>

                      <div className="product-carousel" >
                        <button className="pre-btn" onClick={prevSlide}><p>&lt;</p></button>
                        <button className="next-btn" onClick={nextSlide}><p>&gt;</p></button>

                        <div className="product-container">
                          {previewData.map((slide, index) => {
                            return (
                              <div
                                className='mycard'
                              >
                                <img src={slide.image} className="innerimg" />
                              </div>
                            );
                        })}
                        </div>
                      </div>
                    </div>
                    <Modal.Footer>
                      <div className=' mx-auto'>
                        <button
                          className='btn btn-pink fs-6 dialogButton ms-2 text-white'
                          onClick={() => {
                            setShowPreview(false)
                          }}
                          style={{ width: '220px', height: '50px' }}
                        >
                          Close
                        </button>
                      </div>
                    </Modal.Footer>
                  </Modal>
                  
                  {influencerList.length !== 0 && (
                    <div className='d-flex ms-5 me-10 justify-content-between align-items-end flex-wrap mb-10'>
                      <span className='text-muted pb-2'>
                        Page {influencerListParams.page} of{' '}
                        {Math.floor(paginationCount % influencerListParams.limit) === 0
                          ? Math.floor(paginationCount / influencerListParams.limit)
                          : Math.floor(paginationCount / influencerListParams.limit) + 1}
                      </span>
                      <div className='d-flex flex-wrap '>
                        <Pagination
                          className=''
                          pageSize={influencerListParams.limit}
                          defaultCurrent={influencerListParams.page}
                          total={paginationCount}
                          onChange={(e) =>
                            setinfluencerListParams({...influencerListParams, ...{page: e}})
                          }
                        />
                      </div>
                    </div>
                  )}
                  {/* end::Table */}
                </div>
                {/* end::Table container */}
              </div>
            </div>

            <div className='RecentSummary' style={{minWidth: '400px'}}>
              <div className='RecentDiv'>
                <div className='TableDiv w-100 h-fit-content'>
                  <div className=' p-5 w-100 justify-content-between'>
                    <h3 className='m-0'>Shortlist Count</h3>
                  </div>
                  <div className='mt-0 p-0'>
                    {/* begin::Table container */}
                    <div className='table-responsive' style={{overflowX: 'hidden'}}>
                      {/* begin::Table */}
                      <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                        {/* begin::Table head */}
                        <thead>
                          <tr className='fw-bolder text-muted'>
                            <th className='bg-primary bg-opacity-5 min-w-100px  p-3 ps-7'>Type</th>

                            <th className='bg-primary bg-opacity-5 min-w-150px p-3'>Shortlisted</th>
                            <th className='bg-primary bg-opacity-5 min-w-110px p-3'>StandBy</th>
                          </tr>
                        </thead>
                        {/* end::Table head */}
                        {/* begin::Table body */}
                        <tbody>
                          {priceMatrix?.shortListed.map((x, index) => (
                            <tr>
                              <td className='ps-7 fw-bold'>{x.bucketName}</td>
                              <td className=''>{x.noOfInfluencer}</td>

                              <td className=''>
                                {priceMatrix.standby[index]?.noOfInfluencer
                                  ? priceMatrix.standby[index]?.noOfInfluencer
                                  : 0}
                              </td>
                            </tr>
                          ))}
                          <tr>
                            <td colSpan={3} className='p-0'>
                              <hr className='m-0 p-0'></hr>
                            </td>
                          </tr>
                          <tr>
                            <th className='ps-7 fw-bolder'>Total</th>
                            <th className='fw-bolder'>
                              {priceMatrix?.total?.shortListed ? priceMatrix?.total.shortListed : 0}
                            </th>
                            <th className='fw-bolder'>
                              {priceMatrix?.total?.standby ? priceMatrix?.total.standby : 0}
                            </th>
                          </tr>
                        </tbody>

                        {/* end::Table body */}
                      </table>
                    </div>
                    {/* end::Table container */}
                  </div>
                </div>
                {priceMatrix?.total?.shortListed !== 0 && (
                  <button
                    className='btn btn-primary d-flex flex justify-content-center w-100 h-50px fs-6'
                    style={{marginLeft: '20px'}}
                    onClick={() => {
                      history.push({
                        pathname: '/campaign/influencerOnborading/' + history.location.state._id,
                        state: {
                          ...history.location.state,
                          ...{type: 'influencerOnboard', status: 'Payment'},
                        },
                      })
                    }}
                  >
                    Pay for {priceMatrix?.total.shortListed} Short-listed Influencers
                  </button>
                )}
                <div className='ms-9'>
                  <h5 className='m20'>Note:</h5>
                  <ol className='m12 fs-7'>
                    <li>
                      For every 5 Shortlisted influencer, you can add 1 influencer on standby.
                    </li>
                    <li>You will be paying for shortlisted influencers only.</li>
                    <li>
                      Replacement between shortlisted and standby influencer will be done between
                      same category <br></br>
                      Ex. Micro influencer will be replaced Micro.
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  )
}
