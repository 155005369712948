import {LeftOutlined, RightOutlined, SearchOutlined} from '@ant-design/icons'
import {Input, message} from 'antd'
import clsx from 'clsx'
import {useFormik} from 'formik'
import Moment from 'moment'
import {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap-v5'
import {useHistory} from 'react-router-dom'
import Select from 'react-select'
import * as Yup from 'yup'
import httpService from '../../setup/http/httpServices'
import {toAbsoluteUrl} from '../../_metronic/helpers'
import '../masters/basic.css'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../setup'
import {IUserModel} from '../modules/auth/models/UserModel'
import moment from 'moment'

var initialValues = {
  reach: '',
  impressions: '',
  engagement: '',
  likes: '',
  comments: '',
  share: '',
  content: new File([], ''),
}
const performanceSchema = Yup.object().shape({
  reach: Yup.string().required('Reach is required.'),
  impressions: Yup.string().required('Impressopns is required.'),
  // engagement: Yup.string().required('Engagement is required.'),
  likes: Yup.string().required('Likes is required.'),
  comments: Yup.string().required('Comments is required.'),
  share: Yup.string().required('Share is required.'),
})

function PerformanceInfluencer() {
  const [loading, setLoading] = useState(false)
  const user: IUserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as IUserModel
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: performanceSchema,
    enableReinitialize: true,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      var form_data = new FormData()
      for (var key in values) {
        form_data.append(key, values[key])
      }
      httpService.postData(
        '/content-master/performance/' + contentDetails._id,
        form_data,
        (response: any) => {
          setLoading(false)
          formik.resetForm()
          setBgImage(toAbsoluteUrl('/media/logos/upload_stats.png'))
          setShow(false)
          getContentDetails(formElements.influencer)
        },
        (error: any) => {
          setLoading(false)
          message.error(error.message)
        }
      )
    },
  })
  const [brands, setBrands] = useState([])
  const [agencies, setAgencies] = useState([])
  const [campaigns, setCampaigns] = useState([])
  const [brand, setBrand] = useState(null)
  const [agency, setAgency] = useState(null)
  const [show, setShow] = useState(false)
  const [bgImage, setBgImage] = useState(toAbsoluteUrl('/media/logos/upload_stats.png'))
  const [selectedFile, setSelectedFile] = useState(new File([], ''))
  const [toggleCrop, setToggleCrop] = useState(false)
  const [campaignDetails, setCampaignDetails] = useState(null)
  const [influencers, setInfluencers] = useState([])
  const history: any = useHistory()
  const [contentList, setContentList] = useState([] as any)
  const [contentDetails, setContentDetails] = useState([] as any)
  const [contents, setContents] = useState([] as any)
  const [stats, setStats] = useState({} as any)
  const [formElements, setFormElements] = useState({
    agency: {},
    brand: '',
    campaign: '',
    influencer: '',
    contentType: '',
  })
  const [brandSave, setBrandSave] = useState()

  useEffect(() => {
    if (agencies.length && history.location.state.agencyValue !== null) {
      agencies.forEach((x, i) => {
        if (x._id === history.location.state.agencyValue) {
          setFormElements({...formElements, ...{agency: getAgencies(x)}})
          setAgency(getAgencies(x))
        }
      })
    }
  }, [agencies.length])

  useEffect(() => {
    if (brands.length && history.location.state.brandValue !== null) {
      brands.forEach((x, i) => {
        if (x._id === history.location.state.brandValue) {
          setBrand(getBrands(x))
        }
      })
    }
    if (
      history.location.state.campaignValue !== null &&
      history.location.state.campaignValue !== undefined
    ) {
      setFormElements({...formElements, ...{campaign: history.location.state.campaignValue}})
      httpService.getData(
        '/campaign-master/' + history.location.state.campaignValue,
        (response) => {
          setCampaignDetails(response.data.data.campaign)
          httpService.getData(
            '/influencer-onboarding/' + history.location.state.campaignValue,
            (response: any) => {
              setInfluencers([])
              let data: any = []
              response.data.data.influencers.shortListed.forEach((influencer: any) => {
                data.push({
                  _id: influencer._id,
                  photo: influencer.influencer.basicInfo.avatar,
                  name: influencer.influencer.basicInfo.name,
                  content: [],
                })
              })
              setInfluencers(data)
            },
            (error: any) => {}
          )
        },
        (error) => {
          message.error(error.message)
        }
      )
    }
  }, [brands.length])
  const getBrands = (x: any) => {
    return {
      label: (
        <div>
          <img
            src={x.brandDetails.avatar}
            height='32px'
            width='32px'
            style={{borderRadius: '40px'}}
          />{' '}
          {x.brandDetails.brandName}
        </div>
      ),
      value: x._id,
    }
  }
  const getAgencies = (x: any) => {
    return {
      label: (
        <div>
          <img
            src={x?.agencyDetails?.avatar}
            height='32px'
            width='32px'
            style={{borderRadius: '40px'}}
          />{' '}
          {x?.agencyDetails?.agencyName}
        </div>
      ),
      value: x._id,
    }
  }
  const getContentDetails = (id) => {
    httpService.getData(
      '/content-master/' + id,
      (response: any) => {
        response.data.data.contents.map((x) => {
          x.value = x._id
          x.label = x.title
        })
        setContentList(
          response.data.data.contents.filter((x) => {
            return x.iterations.findIndex((i) => i.status === 'approved') > -1
          })
        )
        httpService.getData(
          '/content-master/performance/' + campaignDetails._id,
          (response: any) => {
            setContents(response.data.data.contents)
            setStats(response.data.data.stats)
          },
          (error: any) => {
            message.error(error?.message)
          }
        )
      },
      (error: any) => {
        message.error(error?.message)
      }
    )
  }
  const getAllMasters = () => {
    getBrand()
    httpService.getData(
      '/agency-master?limit=0',
      (response: any) => {
        setAgencies(
          [
            {
              agencyDetails: {
                avatar: toAbsoluteUrl('/media/logos/categoryIcon.png'),
                agencyName: 'Select Agency',
              },
            },
          ].concat(response.data.data.agencies)
        )
      },
      (error: any) => {
        message.error(error?.message)
      }
    )
    httpService.getData(
      '/campaign-master?limit=0&&brand=' +
        (brand?.value ? brand?.value : '') +
        (user.userType === 'brand' ? user.brandId : '') +
        '&&agency=' +
        (agency?.value ? agency?.value : '') +
        (user.userType === 'agency' ? user.agencyId : '') +
        '&&status=completed',
      (response: any) => {
        response.data.data.campaigns.map((x: any) => {
          x['value'] = x._id
          x['label'] = x.campaignDetails.name
          return x
        })
        setCampaigns([{value: '', label: 'Select Campaign'}].concat(response.data.data.campaigns))
      },
      (error: any) => {
        message.error(error.message)
      }
    )
  }
  const getBrand = () => {
    httpService.getData(
      '/brand-master?limit=0',
      (response: any) => {
        let list = []
        response.data.data.brands.forEach((x: any) => {
          if (agency && agency.value === x?.companyDetails?.agencyAssociated?._id) {
            list.push(x)
          } else if (!agency || agency?.label?.props?.children[2] === 'Select Agency') {
            setBrands(response.data.data.brands)
          }
        })
        if (agency && agency?.label?.props?.children[2] !== 'Select Agency') {
          setBrands(list)
        }
      },
      (error: any) => {}
    )
  }
  function onChange(event: any) {
    let file = event.target.files[0]
    formik.setFieldValue('content', file)
    setSelectedFile(file)
    var reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
      setBgImage('' + reader.result + '')
      setToggleCrop(true)
    }
  }
  function resetImage() {
    setSelectedFile(new File([], ''))
    setBgImage(toAbsoluteUrl('/media/logos/upload_stats.png'))
  }
  useEffect(() => {
    getAllMasters()
  }, [brand, agency])
  return (
    <div style={{minWidth: '1336px'}}>
      <div className='container-fluid flex justify-content-between bg-white h-75px w-100 border'>
        <div className='flex form-check form-check-custom form-check-solid'>
          {!(user.userType === 'brand' || user.userType === 'agency') && (
            <>
              <div className='w240'>
                <Select
                  options={agencies.map((x) => getAgencies(x))}
                  isMulti={false}
                  value={agency}
                  placeholder={
                    <div>
                      <img
                        src={toAbsoluteUrl('/media/logos/categoryIcon.png')}
                        height='32px'
                        width='32px'
                        style={{borderRadius: '40px'}}
                      />{' '}
                      Select Agency{' '}
                    </div>
                  }
                  styles={{
                    menuList: (provided, state) => ({
                      ...provided,
                      height: 'auto',
                      maxHeight: '250px',
                    }),
                  }}
                  className={'form-select p-0 categorySelect w-100'}
                  onChange={(e: any) => {
                    setFormElements({...formElements, ...{agency: e}})
                    setAgency(e)
                  }}
                />
              </div>
              <LeftOutlined style={{marginLeft: '24px', fontSize: '10px'}} />
              <RightOutlined style={{marginRight: '24px', fontSize: '10px'}} />
              <div className='w240'>
                <Select
                  options={brands.map((x) => getBrands(x))}
                  isMulti={false}
                  value={brand}
                  placeholder={
                    <div>
                      <img
                        src={toAbsoluteUrl('/media/logos/categoryIcon.png')}
                        height='32px'
                        width='32px'
                        style={{borderRadius: '40px'}}
                      />{' '}
                      Select Brand{' '}
                    </div>
                  }
                  styles={{
                    menuList: (provided, state) => ({
                      ...provided,
                      height: 'auto',
                      maxHeight: '250px',
                    }),
                  }}
                  className={'form-select p-0 categorySelect w-100'}
                  onChange={(e: any) => {
                    setFormElements({...formElements, ...{brand: e}})
                    setBrand(e)
                  }}
                />
              </div>
              <RightOutlined style={{marginLeft: '24px', marginRight: '24px', fontSize: '10px'}} />
            </>
          )}
          <select
            className='form-select w240'
            value={formElements.campaign}
            onChange={(e) => {
              setFormElements({
                ...formElements,
                ...{campaign: e.target.value, influencer: '', contentType: ''},
              })
              setContentDetails([])
              setContentList([])
              httpService.getData(
                '/campaign-master/' + e.target.value,
                (response) => {
                  setCampaignDetails(response.data.data.campaign)
                  httpService.getData(
                    '/influencer-onboarding/' + e.target.value,
                    (response: any) => {
                      setInfluencers([])
                      let data: any = []
                      response.data.data.influencers.shortListed.forEach((influencer: any) => {
                        data.push({
                          _id: influencer._id,
                          photo: influencer.influencer.basicInfo.avatar,
                          name: influencer.influencer.basicInfo.name,
                          content: [],
                        })
                      })
                      setInfluencers(data)
                    },
                    (error: any) => {}
                  )
                },
                (error) => {
                  message.error(error.message)
                }
              )
            }}
          >
            <option value='' selected disabled hidden>
              Campaign Name
            </option>
            {campaigns.length > 0 &&
              campaigns.map((campaign: any) => (
                <option key={campaign.value} value={campaign?.value}>
                  {campaign.label}
                </option>
              ))}
          </select>
        </div>
        <button
          className='btn create-btn'
          style={{fontSize: '14px', fontWeight: 600, padding: '12px 43px'}}
          onClick={() => {
            history.push({
              pathname: '/performance/campaign-wise',
              state: {
                type: 'performanceCampaign',
                brandValue: brand !== null ? brand.value : brand,
                agencyValue: agency !== null ? agency.value : agency,
                campaignValue: formElements.campaign,
              },
            })
          }}
        >
          Overall Campaign Report
        </button>
      </div>
      <div className='d-flex'>
        {campaignDetails ? (
          <>
            <div className='basicDiv ms-20px' style={{height: 'fit-content', flexGrow: 0.25}}>
              <div className='d-flex'>
                <div className='d-flex w-100 justify-content-between'>
                  <label className='campaignLabel w-95px h-95px rounded-circle'>
                    <img
                      className='w-95px h-95px rounded-circle'
                      src={campaignDetails.campaignDetails?.brandMaster?.brandDetails.avatar}
                    ></img>
                  </label>
                </div>
              </div>
              <div className='m16'>
                <h6>{campaignDetails.campaignDetails?.name}</h6>
                <div className='d-flex'>
                  <img
                    className='w-15px'
                    src={
                      campaignDetails.campaignDetails.platform == 'Instagram'
                        ? toAbsoluteUrl('/media/logos/Instagram.svg')
                        : toAbsoluteUrl('/media/logos/Youtube.svg')
                    }
                  ></img>
                  <p className='m-0 ms-2'>For {campaignDetails.campaignDetails.platform}</p>
                </div>
              </div>
              <div className='m24'>
                <h6 className='m-0'>Brand Associated</h6>
                <p className='m-0 text-gray-400'>
                  {campaignDetails.campaignDetails?.brandMaster?.brandDetails.brandName}
                </p>
              </div>
              <div className='m16'>
                <h6 className='m-0'>Agency Associated</h6>
                <p className='m-0 text-gray-400'>
                  {campaignDetails.campaignDetails?.agencyMaster?.agencyDetails.agencyName || 'NA'}
                </p>
              </div>
              <div className='m16'>
                <h6 className='m-0'>Campaign Description</h6>
                <p className='m-0 text-gray-400'>{campaignDetails.campaignDetails?.description}</p>
              </div>
              {/* Campaign live and End Date */}
              <div className='m16 d-flex flex-column'>
                <div className='w-50 pb-3'>
                  <h6 className='m-0'>Campaign Live Date </h6>
                  <p className='m-0 text-gray-400'>
                    {moment(campaignDetails.campaignDetails?.liveDate).format('DD-MM-YYYY')}
                  </p>
                </div>
                <div>
                  <h6 className='m-0'>Campaign End Date</h6>
                  <p className='m-0 text-gray-400'>
                    {moment(campaignDetails.campaignDetails?.campaignEndDate).format('DD-MM-YYYY')}
                  </p>
                </div>
              </div>

              <div className='m16 d-flex flex-column'>
                <div className='w-50 mb-3'>
                  <h6 className='m-0'>Campaign Type </h6>
                  <p className='m-0 text-gray-400'>
                    {campaignDetails.campaignDetails?.type || 'Barter'}
                  </p>
                </div>
                <div>
                  <h6 className='m-0'>Barter Value</h6>
                  <p className='m-0 text-gray-400'>
                    ₹{campaignDetails.campaignDetails?.barterValue?.min} - ₹
                    {campaignDetails.campaignDetails?.barterValue?.max}
                  </p>
                </div>
              </div>
              {/* Application start and End Date */}
              <div className='m16 d-flex flex-column'>
                <div className=' pb-3'>
                  <h6 className='m-0'>Application Open Date </h6>
                  <p className='m-0 text-gray-400'>
                    {moment(campaignDetails.campaignDetails?.startDate).format('DD-MM-YYYY')}
                  </p>
                </div>
                <div>
                  <h6 className='m-0'>Application Close Date</h6>
                  <p className='m-0 text-gray-400'>
                    {moment(campaignDetails.campaignDetails?.endDate).format('DD-MM-YYYY')}
                  </p>
                </div>
              </div>
              <div className='m16'>
                <h6 className='m-0'>Mode of Barter</h6>
                <p className='m-0 text-gray-400'>{campaignDetails.campaignDetails?.mode}</p>
              </div>
              <div className=''>
                <p className='m-0 mt-6 text-gray-400 fs-8'>
                  Created {Moment(campaignDetails.createdAt).format('DD MMM, YYYY')}
                </p>
              </div>
            </div>
            <div className='d-flex ms-20px flex-column' style={{flexGrow: 1, marginBottom: '16px'}}>
              <div className='basicDiv w-100 d-flex align-items-center' style={{height: '77px'}}>
                <select
                  className='form-select w240 me-4'
                  onChange={(e) => {
                    setFormElements({
                      ...formElements,
                      ...{influencer: e.target.value, contentType: null},
                    })
                    setContentDetails([])
                    getContentDetails(e.target.value)
                  }}
                  style={{background: '#f5f8fa', color: 'gray'}}
                  value={formElements.influencer}
                >
                  <option value='' selected disabled hidden>
                    {influencers.length === 0 ? 'No Influencers Available' : 'Select Influencer'}
                  </option>
                  {influencers.length > 0 &&
                    influencers.map((x) => (
                      <>
                        <option value={x._id}>{x.name}</option>
                      </>
                    ))}
                </select>
                {/* <select className='form-select w240 me-4'
                onChange={(e:any) => {

                  setFormElements({ ...formElements, ...{ contentType: e } })
                  setContentDetails({ ...e })
                }}
                value={formElements.contentType}
              >
                <option value='' selected disabled hidden>
                  {contentList.length === 0 ? 'No Content Available' : 'Select Content'}
                </option>
                {contentList.length > 0 && contentList.map(x => {
                  
                  return<>
                  <option value={x._id}>{x.title}</option>
                </>})}
              </select> */}
                <Select
                  options={contentList}
                  isMulti={false}
                  placeholder='Content type'
                  styles={{}}
                  value={formElements.contentType}
                  className={'form-select w240 me-4 p-0'}
                  onChange={(e: any) => {
                    setFormElements({...formElements, ...{contentType: e}})
                    setContentDetails({...e})
                  }}
                />
                {/* <select className='form-select w240 me-4'>
                <option value='' selected disabled hidden>
                  Content type
                </option>
                <option value='Influencer 1'>Comedy</option>
                <option value='Influencer 2'>Informative</option>
              </select> */}
                {contentDetails.postUrl && (
                  <>
                    <div className='d-flex flex-column me-4'>
                      <span style={{fontSize: '12px', fontWeight: 600}}>Link goes here</span>
                      <span style={{fontSize: '12px', fontWeight: 400, color: 'gray'}}>
                        {contentDetails.postUrl}
                      </span>
                    </div>
                    <a href={`${contentDetails.postUrl}`} target='parent'>
                      <img
                        src={toAbsoluteUrl('/media/logos/link.png')}
                        width='32px'
                        height='32px'
                        className='pointer'
                      />
                    </a>
                  </>
                )}
              </div>
              {contentDetails?._id ? (
                <>
                  <div className='d-flex w-100 m20'>
                    <div
                      className='Dcard Dash-right justify-content-between p16'
                      style={{height: '148px'}}
                    >
                      <div className='Dash-right-top'>
                        <div className='Dash-right-image svg-icon svg-icon-info bg-info bg-opacity-5'>
                          <img src={toAbsoluteUrl('/media/logos/performance_reach.png')} />
                        </div>
                        <div className='Dash-right-head'>
                          <h2 className='fs-16'>Reach</h2>
                        </div>
                      </div>
                      <h1 className='DcustomHeading'>{stats.reach || 0}%</h1>
                    </div>
                    <div
                      className='Dcard Dash-right justify-content-between p16'
                      style={{height: '148px', marginLeft: '20px'}}
                    >
                      <div className='Dash-right-top'>
                        <div className='Dash-right-image svg-icon svg-icon-info bg-info bg-opacity-5'>
                          <img src={toAbsoluteUrl('/media/logos/performance_impressions.png')} />
                        </div>
                        <div className='Dash-right-head'>
                          <h2 className='fs-16'>Impressions</h2>
                        </div>
                      </div>
                      <h1 className='DcustomHeading'>{stats.impressions || 0}%</h1>
                    </div>
                    <div
                      className='Dcard Dash-right justify-content-between p16'
                      style={{height: '148px', marginLeft: '20px'}}
                    >
                      <div className='Dash-right-top'>
                        <div className='Dash-right-image svg-icon svg-icon-info bg-info bg-opacity-5'>
                          <img src={toAbsoluteUrl('/media/logos/performance_engagement.png')} />
                        </div>
                        <div className='Dash-right-head'>
                          <h2 className='fs-16'>Engagement</h2>
                        </div>
                      </div>
                      <h1 className='DcustomHeading'>{stats.engagement || 0}%</h1>
                    </div>
                    <div
                      className='Dcard Dash-right justify-content-between p16'
                      style={{height: '148px', marginLeft: '20px'}}
                    >
                      <div className='Dash-right-top'>
                        <div className='Dash-right-image svg-icon svg-icon-info bg-info bg-opacity-5'>
                          <img src={toAbsoluteUrl('/media/logos/overview_likes.png')} />
                        </div>
                        <div className='Dash-right-head'>
                          <h2 className='fs-16'>Likes</h2>
                        </div>
                      </div>
                      <h1 className='DcustomHeading'>{stats.likes || 0}</h1>
                    </div>
                    <div
                      className='Dcard Dash-right justify-content-between p16'
                      style={{height: '148px', marginLeft: '20px'}}
                    >
                      <div className='Dash-right-top'>
                        <div className='Dash-right-image svg-icon svg-icon-info bg-info bg-opacity-5'>
                          <img src={toAbsoluteUrl('/media/logos/overview_comments.png')} />
                        </div>
                        <div className='Dash-right-head'>
                          <h2 className='fs-16'>Comment and Shares</h2>
                        </div>
                      </div>
                      <h1 className='DcustomHeading'>{stats.comments || 0}</h1>
                    </div>
                  </div>
                  <div
                    className='m20 w-100 d-flex justify-content-between align-items-center'
                    style={{
                      backgroundColor: 'white',
                      borderTopLeftRadius: '12px',
                      borderTopRightRadius: '12px',
                      padding: '16px',
                      height: '84px',
                    }}
                  >
                    <div className='mt-3 min-w-150px'>
                      <h3 style={{cursor: 'default'}}>Influencer Stats History</h3>
                      <p style={{cursor: 'default'}} className='text-gray-500 fs-7'>
                        {contents.length} Transactions
                      </p>
                    </div>
                    <div className='d-flex'>
                      <div className='d-flex align-items-center'>
                        <link
                          href='https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css'
                          rel='stylesheet'
                        />
                        <Input
                          placeholder='Search'
                          style={{width: '250px', height: '40px'}}
                          className='form-control-solid border'
                          onChange={(e: any) => {}}
                          prefix={
                            <>
                              <SearchOutlined />
                            </>
                          }
                        />
                      </div>
                      {contents.length === 0 && (
                        <div className=' d-flex align-items-center'>
                          <button
                            className='btn create-btn d-flex flex me-2 w-auto h-35px fs-7 ms-3'
                            onClick={() => setShow(true)}
                          >
                            Add New Record
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    className='mt-0 p-0 d-flex flex-column justify-content-between'
                    style={{
                      backgroundColor: 'white',
                      height: '550px',
                      borderBottomLeftRadius: '12px',
                      borderBottomRightRadius: '12px',
                    }}
                  >
                    {/* begin::Table container */}
                    <div className='table-responsive'>
                      {/* begin::Table */}
                      <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                        {/* begin::Table head */}
                        <thead>
                          <tr className='fw-bolder text-muted'>
                            <th className='bg-primary bg-opacity-5 w-15px'>
                              <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                {/* <input
                        className='form-check-input'
                        type='checkbox'
                        value='1'
                        data-kt-check='true'
                        data-kt-check-target='.widget-9-check'
                      /> */}
                              </div>
                            </th>
                            <th className='bg-primary bg-opacity-5' style={{minWidth: '250px'}}>
                              <span className='d-flex'>Date Update</span>
                            </th>
                            <th className='bg-primary bg-opacity-5' style={{minWidth: '110px'}}>
                              <span className='d-flex'>Reach</span>
                            </th>
                            <th className='bg-primary bg-opacity-5 ' style={{minWidth: '110px'}}>
                              <span className='d-flex'>Impressions</span>
                            </th>
                            {/* <th className='bg-primary bg-opacity-5' style={{ minWidth: '110px' }}>
                          <span className='d-flex'>Engagement</span>
                        </th> */}
                            <th className='bg-primary bg-opacity-5' style={{minWidth: '100px'}}>
                              <span className='d-flex'>Likes</span>
                            </th>
                            <th className='bg-primary bg-opacity-5' style={{minWidth: '100px'}}>
                              <span className='d-flex'>Comment</span>
                            </th>
                            <th className='bg-primary bg-opacity-5' style={{minWidth: '100px'}}>
                              <span className='d-flex'>Share</span>
                            </th>
                            <th className='bg-primary bg-opacity-5 min-w-150px'>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {contents.length > 0 ? (
                            contents.map((stat: any, index: number) => (
                              <tr key={index}>
                                <td>
                                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                    {/* <input className='form-check-input widget-9-check' type='checkbox' value='1' /> */}
                                  </div>
                                </td>
                                <td className='fw-bold'>
                                  {Moment(stat.postDate).format('DD MMM YYYY HH:SS A')}
                                </td>
                                <td className='fw-bold'>{stat.performance.reach}</td>
                                <td className='fw-bold'>{stat.performance.impressions}</td>
                                {/* <td className='fw-bold'>{stat.performance.engagement}</td> */}

                                <td className='fw-bold'>{stat.performance.likes}</td>
                                <td className='fw-bold'>{stat.performance.comments}</td>
                                <td className='fw-bold'>{stat.performance.share}</td>
                                <td className='flex'>
                                  <a href={stat.performance.link}>
                                    <button className='btn' style={{backgroundColor: '#D2D5D940'}}>
                                      View Screenshot
                                    </button>
                                  </a>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={9}>
                                <div className='basicDiv' style={{width: '100%'}}>
                                  <div className='flex'>
                                    <img
                                      className='ms-20'
                                      src={toAbsoluteUrl('/media/logos/TableVector.png')}
                                    ></img>
                                  </div>
                                  <div className='flex'>
                                    <div>
                                      <h2 className='mb-0 m24 flex'>No Records Available</h2>
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                        {/* end::Table body */}
                      </table>

                      {/* end::Table */}
                    </div>
                    {contents.length > 0 && false && (
                      <div className='d-flex ms-8 me-10 justify-content-between align-items-end flex-wrap mb-10'>
                        <span className='text-muted pb-2'>Page</span>
                        <div className='d-flex flex-wrap '></div>
                      </div>
                    )}
                    {/* end::Table container */}
                  </div>
                </>
              ) : (
                <div className='basicDiv' style={{width: '100%'}}>
                  <div className='flex'>
                    <img
                      className='ms-20'
                      src={toAbsoluteUrl('/media/logos/TableVector.png')}
                    ></img>
                  </div>
                  <div className='flex'>
                    <div>
                      <h2 className='mb-0 m24 flex'>No Content Selected</h2>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        ) : (
          <div className='basicDiv ms-20px' style={{width: '98%'}}>
            <div className='flex'>
              <img className='ms-20' src={toAbsoluteUrl('/media/logos/TableVector.png')}></img>
            </div>
            <div className='flex'>
              <div>
                <h2 className='mb-0 m24 flex'>No Campaign Selected</h2>
              </div>
            </div>
          </div>
        )}
      </div>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        aria-labelledby='contained-modal-title-vcenter '
        centered
        data-backdrop='true'
      >
        <Modal.Body className='p-0 '>
          <div className='d-flex flex-column' style={{width: '600px'}}>
            <form noValidate id='kt_login_signup_form' onSubmit={formik.handleSubmit}>
              <div className=' w-100 bg-white rounded p-8'>
                <h2>Add New Record</h2>
                <div className='d-flex w-100 justify-content-between m20'>
                  <div className='d-flex flex-column'>
                    <label className='required fs-14' style={{marginBottom: '4px'}}>
                      Reach
                    </label>
                    <input
                      {...formik.getFieldProps('reach')}
                      type='number'
                      className={clsx(
                        'form-control form-control-lg form-control-solid',
                        {
                          'is-invalid': formik.touched.reach && formik.errors.reach,
                        },
                        {
                          'is-valid': formik.touched.reach && !formik.errors.reach,
                        }
                      )}
                    />
                    {formik.touched.reach && formik.errors.reach && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span className='text-danger' role='alert'>
                            {formik.errors.reach}
                          </span>
                        </div>
                      </div>
                    )}
                    <label className='required m24 fs-14' style={{marginBottom: '4px'}}>
                      Likes
                    </label>
                    <input
                      {...formik.getFieldProps('likes')}
                      type='number'
                      className={clsx(
                        'form-control form-control-lg form-control-solid',
                        {
                          'is-invalid': formik.touched.likes && formik.errors.likes,
                        },
                        {
                          'is-valid': formik.touched.likes && !formik.errors.likes,
                        }
                      )}
                    />
                    {formik.touched.likes && formik.errors.likes && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span className='text-danger' role='alert'>
                            {formik.errors.likes}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='d-flex flex-column' style={{marginLeft: '24px'}}>
                    <label className='required fs-14' style={{marginBottom: '4px'}}>
                      Impressions
                    </label>
                    <input
                      {...formik.getFieldProps('impressions')}
                      type='number'
                      className={clsx(
                        'form-control form-control-lg form-control-solid',
                        {
                          'is-invalid': formik.touched.impressions && formik.errors.impressions,
                        },
                        {
                          'is-valid': formik.touched.impressions && !formik.errors.impressions,
                        }
                      )}
                    />
                    {formik.touched.impressions && formik.errors.impressions && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span className='text-danger' role='alert'>
                            {formik.errors.impressions}
                          </span>
                        </div>
                      </div>
                    )}
                    <label className='required m24 fs-14' style={{marginBottom: '4px'}}>
                      Comments
                    </label>
                    <input
                      {...formik.getFieldProps('comments')}
                      type='number'
                      className={clsx(
                        'form-control form-control-lg form-control-solid',
                        {
                          'is-invalid': formik.touched.comments && formik.errors.comments,
                        },
                        {
                          'is-valid': formik.touched.comments && !formik.errors.comments,
                        }
                      )}
                    />
                    {formik.touched.comments && formik.errors.comments && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span className='text-danger' role='alert'>
                            {formik.errors.comments}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='d-flex flex-column' style={{marginLeft: '24px'}}>
                    {/* <label className='required fs-14' style={{ marginBottom: '4px' }}>
                      Engagement
                    </label>
                    <input {...formik.getFieldProps('engagement')} type='number'
                      className={clsx(
                        'form-control form-control-lg form-control-solid',
                        {
                          'is-invalid': formik.touched.engagement && formik.errors.engagement,
                        },
                        {
                          'is-valid': formik.touched.engagement && !formik.errors.engagement,
                        }
                      )} />
                    {formik.touched.engagement && formik.errors.engagement && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span className='text-danger' role='alert'>
                            {formik.errors.engagement}
                          </span>
                        </div>
                      </div>
                    )} */}
                    <label className='required fs-14' style={{marginBottom: '4px'}}>
                      Share
                    </label>
                    <input
                      {...formik.getFieldProps('share')}
                      type='number'
                      className={clsx(
                        'form-control form-control-lg form-control-solid',
                        {
                          'is-invalid': formik.touched.share && formik.errors.share,
                        },
                        {
                          'is-valid': formik.touched.share && !formik.errors.share,
                        }
                      )}
                    />
                    {formik.touched.share && formik.errors.share && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span className='text-danger' role='alert'>
                            {formik.errors.share}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <label className='required m24 fs-14' style={{marginBottom: '4px'}}>
                  Upload Screenshot
                </label>
                <div className='w-100'>
                  <div data-kt-image-input='true' className='image-input categoryIcon w-100'>
                    <div className='image-input-wrapper w-100 h-100 flex bg-gray-100'>
                      <img src={bgImage} className='h-100'></img>
                    </div>
                    <label
                      className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow'
                      data-kt-image-input-action='change'
                      data-bs-toggle='tooltip'
                      data-bs-original-title='Change avatar'
                    >
                      <i className='bi bi-pencil-fill fs-7'></i>
                      <input
                        type='file'
                        name='avatar'
                        accept='.png, .jpg, .jpeg'
                        onChange={(event) => onChange(event)}
                      />
                      <input type='hidden' name='avatar_remove' />
                    </label>

                    {selectedFile.size > 0 && (
                      <span
                        className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow'
                        data-kt-image-input-action='cancel'
                        data-bs-toggle='tooltip'
                        data-bs-dismiss='click'
                        title='Cancel avatar'
                      >
                        <i className='bi bi-x fs-2'></i>
                      </span>
                    )}
                    {selectedFile.size > 0 && (
                      <span
                        className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow'
                        data-kt-image-input-action='remove'
                        data-bs-toggle='tooltip'
                        data-bs-dismiss='click'
                        title='Remove avatar'
                        onClick={resetImage}
                      >
                        <i className='bi bi-x fs-2'></i>
                      </span>
                    )}
                  </div>
                </div>
                <div className='d-flex w-100 m24'>
                  <button type='submit' id='kt_sign_up_submit' className='btn create-btn col'>
                    {!loading && <span className='indicator-label'>Submit</span>}
                    {loading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default PerformanceInfluencer
