import {SearchOutlined} from '@ant-design/icons'
import {Input, message} from 'antd'
import clsx from 'clsx'
import {useFormik} from 'formik'
import React, {useEffect, useState} from 'react'
import * as Yup from 'yup'
import httpService from '../../setup/http/httpServices'
import {toAbsoluteUrl} from '../../_metronic/helpers'
import {Alerts} from '../modules/alerts/Alerts'
import './basic.css'
import {SocialMasterTable} from './SocialMasterTable'
import {ImageCropper} from './ImageCropper'
import {IUserModel} from '../modules/auth/models/UserModel'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../setup/redux/RootReducer'

var initialValues = {
  socialMediaName: '',
  socialMediaIconUrl: 'test.png',
}
const socialMediaSchema = Yup.object().shape({
  socialMediaName: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Category name is required')
    .matches(/^(?=.*[A-Za-z]).{3,50}$/, 'Should not contain just numbers'),
})
export function SocialMaster(props: any) {
  const user: IUserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as IUserModel
  const access = user.permissions.masters[1] || user.permissions.admin

  const [bgImage, setBgImage] = useState(toAbsoluteUrl('/media/logos/categoryIcon.png'))
  const [isSuccess, setIsSuccess] = useState(false)
  const [toggleCrop, setToggleCrop] = useState(false)
  const [isFailure, setIsFailure] = useState(false)
  const [socialMediaId, setSocialMediaId] = useState(null)
  const [responseMessage, setResponseMessage] = useState('')
  const [selectedFile, setSelectedFile] = useState(new File([], ''))

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: socialMediaSchema,
    enableReinitialize: true,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setIsFailure(false)
      setIsSuccess(false)
      // values.socialMediaIconUrl = values.socialMediaName + '.png'
      // let socialMediaRequest = {
      //   socialMediaName: values.socialMediaName,
      //   socialMediaIconUrl: values.socialMediaIconUrl,
      // }
      let fd = new FormData()
      fd.append('socialMediaName', values.socialMediaName)
      fd.append('socialMediaIconUrl', selectedFile)
      if (socialMediaId) {
        httpService.patchData(
          '/social-media-master/' + socialMediaId,
          fd,
          (res: any) => {
            formik.resetForm()
            setResponseMessage('Success! Social media updated.')
            setSocialMediaId(null)
            setIsSuccess(true)
            setBgImage(toAbsoluteUrl('/media/logos/categoryIcon.png'))
          },
          (err: any) => {
            setIsFailure(true)
            setResponseMessage(err.message)
          }
        )
      } else if (selectedFile.size > 0) {
        httpService.postData(
          '/social-media-master',
          fd,
          (res: any) => {
            formik.resetForm()
            setResponseMessage('Success! Social media added.')
            setSocialMediaId(null)
            setIsSuccess(true)
            setBgImage(toAbsoluteUrl('/media/logos/categoryIcon.png'))
          },
          (err: any) => {
            setIsFailure(true)
            setResponseMessage(err.message)
          }
        )
      } else if (selectedFile.size === 0) {
        formik.setFieldError('socialMediaIconUrl', 'Please select social media logo.')
      }
    },
  })

  function onChange(event: any) {
    let file = event.target.files[0]
    setSelectedFile(file)
    var reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
      setBgImage('' + reader.result + '')
      setToggleCrop(true)
    }
  }
  function resetImage() {
    setSelectedFile(new File([], ''))
    setBgImage(toAbsoluteUrl('/media/logos/categoryIcon.png'))
  }

  const handleSocialMedia = (id: any) => {
    httpService.getData(
      '/social-media-master/' + id,
      (res: any) => {
        formik.setValues(res.data.data.socialMedia)
        setBgImage(toAbsoluteUrl(res.data.data.socialMedia.avatar))
        setSocialMediaId(id)
      },
      (error: any) => {
        message.error(error.message)
      }
    )
  }

  async function handleCrop(image: any) {
    setBgImage(image)
    const response = await fetch(image, {mode: 'no-cors'})
    const data = await response.blob()
    setSelectedFile(
      new File([data], selectedFile?.name, {
        type: selectedFile.type,
      })
    )
    formik.setErrors({})
    setToggleCrop(false)
  }

  const handleCancel = () => {
    setBgImage(toAbsoluteUrl('/media/logos/categoryIcon.png'))
    setToggleCrop(false)
  }
  return (
    <div className='d-flex justify-content-center m-2' style={{overflow: 'hidden'}}>
      <div className='addCategory'>
        <h3 className='mb-0'>Add New Platform</h3>
        <p className='fs-7 text-gray-400'>
          Platforms are used to set and filter your influencer preferences.{' '}
        </p>
        <Alerts
          visible={isSuccess}
          type='success'
          message={responseMessage}
          description={'Description'}
        ></Alerts>
        <Alerts
          visible={isFailure}
          type='error'
          message={responseMessage}
          description={'Description'}
        ></Alerts>
        <form noValidate id='kt_login_signup_form' onSubmit={formik.handleSubmit}>
          <div>
            <h6 className='fw-bold required m32'>Platform Name</h6>
            <input
              {...formik.getFieldProps('socialMediaName')}
              className={clsx(
                'form-control form-control-solid bg-gray-100',
                {
                  'is-invalid': formik.touched.socialMediaName && formik.errors.socialMediaName,
                },
                {
                  'is-valid': formik.touched.socialMediaName && !formik.errors.socialMediaName,
                }
              )}
            />
          </div>
          {formik.errors.socialMediaName && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span className='text-danger' role='alert'>
                  {formik.errors.socialMediaName}
                </span>
              </div>
            </div>
          )}
          <div className='d-flex justify-content-between m16'>
            <p className='fs-14'>Active Media</p>
            <label className='switch'>
              <input type='checkbox' />
              <span className='slider round'></span>
            </label>
          </div>
          <div>
            <h6 className='fw-bold required m16'>Platform Logo</h6>
            <div data-kt-image-input='true' className='image-input categoryIcon'>
              <div className='image-input-wrapper w-100 h-100 flex bg-gray-100'>
                <img src={bgImage} className='h-100'></img>
              </div>
              <label
                className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow'
                data-kt-image-input-action='change'
                data-bs-toggle='tooltip'
                data-bs-original-title='Change avatar'
              >
                <i className='bi bi-pencil-fill fs-7'></i>
                <input
                  type='file'
                  name='avatar'
                  accept='.png, .jpg, .jpeg'
                  onChange={(event) => onChange(event)}
                />
                <input type='hidden' name='avatar_remove' />
              </label>

              {selectedFile.size > 0 && (
                <span
                  className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow'
                  data-kt-image-input-action='cancel'
                  data-bs-toggle='tooltip'
                  data-bs-dismiss='click'
                  title='Cancel avatar'
                >
                  <i className='bi bi-x fs-2'></i>
                </span>
              )}
              {selectedFile.size > 0 && (
                <span
                  className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow'
                  data-kt-image-input-action='remove'
                  data-bs-toggle='tooltip'
                  data-bs-dismiss='click'
                  title='Remove avatar'
                  onClick={resetImage}
                >
                  <i className='bi bi-x fs-2'></i>
                </span>
              )}
              {formik.errors.socialMediaIconUrl && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='text-danger' role='alert'>
                      {'Media Logo is required fiels.'}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='m32 w-100 flex'>
            <button
              type='button'
              onClick={() => {
                setSocialMediaId(null)
                setSelectedFile(new File([], ''))
                setBgImage(toAbsoluteUrl('/media/logos/categoryIcon.png'))
                formik.resetForm()
              }}
              className='btn fw-bolder btn-gray-200 fs-6 categoryButton'
            >
              Cancel
            </button>
            <button
              type='submit'
              // disabled={!formik.isValid}
              className='btn create-btn fs-6 categoryButton ms-10'
              disabled={!access}
            >
              Add Platform
            </button>
          </div>
        </form>
      </div>
      <SocialMasterTable
        updatable={isSuccess || isFailure}
        editSocialMedia={handleSocialMedia}
        access={access}
      ></SocialMasterTable>
      {toggleCrop && <ImageCropper cancel={handleCancel} editImage={handleCrop} image={bgImage} />}
    </div>
  )
}
