import React, {useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../_metronic/helpers'
import httpService from '../../setup/http/httpServices'

function ProfileInfluencer(props: any) {
  const name = props.data.influencerBasicInfo.name
  const [hashtags, setHashTags] = useState([])
  const [mentions, setMentions] = useState([])
  const [lookAlikes, setLookAlikes] = useState([])
  const [userData, setUserData] = useState(props.userData)

  useEffect(() => {
    setUserData(props.userData)
  }, [props.userData, props.type])
  useEffect(() => {
    setHashTags(userData?.user_profile?.top_hashtags)
    setMentions(userData?.user_profile?.top_mentions)
    setLookAlikes(userData?.audience_followers?.data.audience_lookalikes)
  }, [userData])

  return (
    <div className='d-flex flex-column m20'>
      {props.type == false && (
        <>
          {' '}
          <div
            className='d-flex flex-column w-100 rounded'
            style={{backgroundColor: 'white', padding: '24px'}}
          >
            <h2>{name}'s Interests</h2>
            <div className='d-flex align-items-center m16'>
              <span>
                <img src={toAbsoluteUrl('/media/icons/Custom/travel.svg')} />
                <span className='fs-14' style={{marginLeft: '8px'}}>
                  Travel,Tourism & Aviation
                </span>
              </span>
              <span style={{marginLeft: '24px'}}>
                <img src={toAbsoluteUrl('/media/icons/Custom/clothes.svg')} />
                <span className='fs-14' style={{marginLeft: '8px'}}>
                  Clothes,Shoes & Handbags
                </span>
              </span>
            </div>
          </div>
          <div className='d-flex w-100'>
            <div
              className='basicDiv col'
              style={{backgroundColor: 'white', padding: '24px', marginRight: '20px'}}
            >
              <h2>Popular #</h2>
              <div className='d-flex flex-wrap m20' style={{fontSize: '14px', color: '#1C2D41'}}>
                {hashtags &&
                  hashtags.length > 0 &&
                  hashtags.map((hashtag: any) => (
                    <span
                      className='border border dark me-3 mb-3'
                      style={{padding: '8px 12px', borderRadius: '50px'}}
                    >
                      # {hashtag.tag}
                    </span>
                  ))}
              </div>
            </div>
            <div className='basicDiv col' style={{backgroundColor: 'white', padding: '24px'}}>
              <h2>Popular Mentions</h2>
              <div className='d-flex flex-wrap m20 fs-14'>
                {mentions &&
                  mentions.length > 0 &&
                  mentions.map((mention: any) => (
                    <span
                      className='border border dark me-3 mb-3'
                      style={{padding: '8px 12px', borderRadius: '50px'}}
                    >
                      @ {mention.tag}
                    </span>
                  ))}
              </div>
            </div>
          </div>
        </>
      )}
      <div
        className='d-flex flex-column m20 w-100 rounded'
        style={{padding: '24px', backgroundColor: 'white'}}
      >
        <h2>Look a likes</h2>
        <div className='d-flex m20 flex-wrap'>
          {lookAlikes &&
            lookAlikes.length > 0 &&
            lookAlikes.map((person: any) => (
              <div
                className='d-flex flex-column align-items-center border rounded'
                style={{padding: '24px', marginRight: '6px', marginBottom: '8px'}}
              >
                <img
                  className='rounded-circle'
                  src={person.picture}
                  alt=''
                  style={{height: '100px', width: '100px'}}
                />
                <span
                  style={{
                    marginTop: '16px',
                    width: '154px',
                    textAlign: 'center',
                  }}
                  className='fs-14'
                >
                  {person.fullname}
                </span>
                <span style={{fontSize: '10px'}} className='text-muted'>
                  {person.username}
                </span>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}

export default ProfileInfluencer
