import {LeftOutlined, RightOutlined} from '@ant-design/icons'
import {message} from 'antd'
import Moment from 'moment'
import {useEffect, useState} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import Select from 'react-select'
import {RootState} from '../../setup'
import httpService from '../../setup/http/httpServices'
import {KTSVG, toAbsoluteUrl} from '../../_metronic/helpers'
import '../masters/basic.css'
import {ColumnChart} from '../masters/Charts/ColumnChart'
import {IUserModel} from '../modules/auth/models/UserModel'
import {Modal} from 'react-bootstrap-v5'
import moment from 'moment'

function PerformanceDashboard() {
  const [year, setYear] = useState(false)
  const [month, setMonth] = useState(true)
  const [day, setDay] = useState(false)
  const [brands, setBrands] = useState([])
  const [agencies, setAgencies] = useState([])
  const [campaigns, setCampaigns] = useState([])
  const [brand, setBrand] = useState(null)
  const [agency, setAgency] = useState(null)
  const history: any = useHistory()
  const [campaignValue, setCampaignValue] = useState('')
  const [arr, setArr] = useState([])
  // const [url, setUrl] = useState(null)
  const [campaignDetails, setCampaignDetails] = useState(null)
  const user: IUserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as IUserModel
  const [deleteId, setDeleteId] = useState('')
  const [show, setShow] = useState(false)

  useEffect(() => {
    if (campaignDetails !== null && campaignDetails !== undefined) {
      httpService.getData(
        '/content-master/performance/dashboard/' + campaignDetails._id,
        (response: any) => {
          setArr(response.data.data.performance)
        },
        (error: any) => {}
      )
    }
  }, [campaignDetails])

  const [audienceData, setAudienceData] = useState({
    audience_reachability: [
      {code: 'Tini', weight: 445 / 100},
      {code: 'Celeb', weight: 51 / 100},
      {code: 'Nano', weight: 0},
    ],
  })
  const [audienceData2, setAudienceData2] = useState({
    audience_reachability: [
      {code: 'Tini', weight: 445 / 100},
      {code: 'Celeb', weight: 51 / 100},
      {code: 'Nano', weight: 0},
    ],
  })
  const [audienceData3, setAudienceData3] = useState({
    audience_reachability: [
      {code: 'Tini', weight: 445 / 100},
      {code: 'Celeb', weight: 51 / 100},
      {code: 'Nano', weight: 0},
    ],
  })

  // var audienceData =
  useEffect(() => {
    let newarr = arr.map((x) => {
      return {
        code: x.bucketName,
        weight: x.reach / 100,
      }
    })
    let newarr2 = arr.map((x) => {
      return {
        code: x.bucketName,
        weight: x.engagement / 100,
      }
    })
    let newarr3 = arr.map((x) => {
      return {
        code: x.bucketName,
        weight: x.likes / 100,
      }
    })
    setAudienceData({audience_reachability: newarr})
    setAudienceData2({audience_reachability: newarr2})
    setAudienceData3({audience_reachability: newarr3})
  }, [arr])

  useEffect(() => {
    if (
      agencies.length &&
      history.location.state.agencyValue !== null &&
      history.location.state.agencyValue !== undefined
    ) {
      agencies.forEach((x, i) => {
        if (x._id === history.location.state.agencyValue) {
          setAgency(getAgencies(x))
        }
      })
    }
  }, [agencies.length])
  useEffect(() => {
    if (
      brands.length &&
      history.location.state.brandValue !== null &&
      history.location.state.brandValue !== undefined
    ) {
      brands.forEach((x, i) => {
        if (x._id === history.location.state.brandValue) {
          setBrand(getBrands(x))
        }
      })
    }

    if (
      history.location.state.campaignValue !== null &&
      history.location.state.campaignValue !== undefined
    ) {
      setCampaignValue(history.location.state.campaignValue)
      httpService.getData(
        '/campaign-master/' + history.location.state.campaignValue,
        (response) => {
          setCampaignDetails(response.data.data.campaign)
        },
        (error) => {
          message.error(error.message)
        }
      )
    }
  }, [brands.length])

  const stats = [
    {month: '2021-11', followers: 7086227, following: 299, avg_likes: 78890},
    {month: '2021-12', followers: 7115532, following: 300, avg_likes: 92744},
    {month: '2022-01', followers: 7101039, following: 304, avg_likes: 80388},
    {month: '2022-02', followers: 7105516, following: 308, avg_likes: 57222},
    {month: '2022-03', followers: 7183983, following: 313, avg_likes: 65994},
    {month: '2022-04', followers: 7247610, following: 317, avg_likes: 67388},
    {month: '2022-05', followers: 7293640, following: 318, avg_likes: 61402},
  ]
  const getBrands = (x: any) => {
    return {
      label: (
        <div>
          <img
            src={x?.brandDetails?.avatar}
            height='32px'
            width='32px'
            style={{borderRadius: '40px'}}
          />{' '}
          {x?.brandDetails?.brandName}
        </div>
      ),
      value: x._id,
    }
  }
  const getAgencies = (x: any) => {
    return {
      label: (
        <div>
          <img
            src={x?.agencyDetails?.avatar}
            height='32px'
            width='32px'
            style={{borderRadius: '40px'}}
          />{' '}
          {x?.agencyDetails?.agencyName}
        </div>
      ),
      value: x._id,
    }
  }
  const getAllMasters = () => {
    getBrand()
    httpService.getData(
      '/agency-master?limit=0',
      (response: any) => {
        setAgencies(
          [
            {
              agencyDetails: {
                avatar: toAbsoluteUrl('/media/logos/categoryIcon.png'),
                agencyName: 'Select Agency',
              },
            },
          ].concat(response.data.data.agencies)
        )
      },
      (error: any) => {
        message.error(error?.message)
      }
    )
    httpService.getData(
      '/campaign-master?limit=0&&brand=' +
        (brand?.value ? brand?.value : '') +
        (user.userType === 'brand' ? user.brandId : '') +
        '&&agency=' +
        (agency?.value ? agency?.value : '') +
        (user.userType === 'agency' ? user.agencyId : '') +
        '&&status=completed',
      (response: any) => {
        response.data.data.campaigns.map((x: any) => {
          x['value'] = x._id
          x['label'] = x.campaignDetails.name
          return x
        })
        setCampaigns([{value: '', label: 'Select Campaign'}].concat(response.data.data.campaigns))
      },
      (error: any) => {
        message.error(error.message)
      }
    )
  }
  const getBrand = () => {
    httpService.getData(
      '/brand-master?limit=0',
      (response: any) => {
        let list = []
        response.data.data.brands.forEach((x: any) => {
          if (agency && agency.value === x?.companyDetails?.agencyAssociated?._id) {
            list.push(x)
          } else if (!agency || agency?.label?.props?.children[2] === 'Select Agency') {
            setBrands(response.data.data.brands)
          }
        })
        if (agency && agency?.label?.props?.children[2] !== 'Select Agency') {
          setBrands(list)
        }
      },
      (error: any) => {}
    )
  }
  useEffect(() => {
    if (history?.location?.state?.from === 'ongoing') {
      httpService.getData(
        '/campaign-master/' + history.location.state._id,
        (response) => {
          setCampaignValue(history.location.state._id)
          setCampaignDetails(response.data.data.campaign)
        },
        (error) => {
          message.error(error.message)
        }
      )
    }

    getAllMasters()
  }, [brand, agency])
  const handleEdit = () => {
    let pathname = '/campaign/details'
    if (campaignDetails.stateOverview.step2 === false) {
      pathname = '/campaign/influencerDetails'
    } else if (campaignDetails.stateOverview.step3 === false) {
      pathname = '/campaign/audienceDetails'
    } else if (campaignDetails.stateOverview.step4 === false) {
      pathname = '/campaign/otherDetails'
    }
    history.push({
      pathname: pathname,
      state: {
        ...campaignDetails,
        _id: campaignDetails?._id,
        // ...{type: 'campaignDetails'},
        ...{
          type: campaignDetails.campaignDetails.name,
          breadcrums: ['Campaigns', 'Campaign Overview'],
        },
      },
    })
  }
  const key = 'updatable'
  const handleDelete = () => {
    setShow(false)
    httpService.deleteData(
      '/campaign-master/' + campaignDetails._id,
      (response: any) => {
        setTimeout(() => {
          message.success({content: 'campaign deleted!', key, duration: 2})
          setDeleteId('')
        }, 1000)
        history.push({
          pathname: '/campaign/dashboard',
          state: {type: 'campaign/dashboard', status: '', reloaded: false},
        })
      },
      (error: any) => {
        message.error(error.message)
      }
    )
  }
  return (
    <div style={{minWidth: '1336px'}}>
      <div className='container-fluid flex justify-content-between bg-white h-75px w-100 border'>
        <div className='flex form-check form-check-custom form-check-solid'>
          {!(user.userType === 'brand' || user.userType === 'agency') && (
            <>
              <div className='w240'>
                <Select
                  options={agencies.map((x) => getAgencies(x))}
                  isMulti={false}
                  value={agency}
                  placeholder={
                    <div>
                      <img
                        src={toAbsoluteUrl('/media/logos/categoryIcon.png')}
                        height='32px'
                        width='32px'
                        style={{borderRadius: '40px'}}
                      />{' '}
                      Select Agency{' '}
                    </div>
                  }
                  styles={{
                    menuList: (provided, state) => ({
                      ...provided,
                      height: 'auto',
                      maxHeight: '250px',
                    }),
                  }}
                  className={'form-select p-0 categorySelect w-100'}
                  onChange={(e: any) => {
                    setAgency(e)
                  }}
                />
              </div>
              <LeftOutlined style={{marginLeft: '24px', fontSize: '10px'}} />
              <RightOutlined style={{marginRight: '24px', fontSize: '10px'}} />
              <div className='w240'>
                <Select
                  options={brands.map((x) => getBrands(x))}
                  isMulti={false}
                  value={brand}
                  placeholder={
                    <div>
                      <img
                        src={toAbsoluteUrl('/media/logos/categoryIcon.png')}
                        height='32px'
                        width='32px'
                        style={{borderRadius: '40px'}}
                      />{' '}
                      Select Brand{' '}
                    </div>
                  }
                  styles={{
                    menuList: (provided, state) => ({
                      ...provided,
                      height: 'auto',
                      maxHeight: '250px',
                    }),
                  }}
                  className={'form-select p-0 categorySelect w-100'}
                  onChange={(e: any) => {
                    setBrand(e)
                  }}
                />
              </div>

              <RightOutlined style={{marginLeft: '24px', marginRight: '24px', fontSize: '10px'}} />
            </>
          )}
          <select
            className='form-select w240 campaign-select'
            id='dropDownCampaignName'
            value={campaignValue}
            onChange={(e) => {
              setCampaignValue(e.target.value)

              httpService.getData(
                '/campaign-master/' + e.target.value,
                (response) => {
                  setCampaignDetails(response.data.data.campaign)
                },
                (error) => {
                  message.error(error.message)
                }
              )
            }}
          >
            <option value='' selected disabled hidden>
              Campaign Name
            </option>
            {campaigns.length > 0 &&
              campaigns.map((campaign: any) => (
                <option key={campaign.value} value={campaign?.value}>
                  {campaign.label}
                </option>
              ))}
          </select>
        </div>
        <button
          className='btn create-btn'
          style={{fontSize: '14px', fontWeight: 600, padding: '12px 43px'}}
          onClick={() => {
            history.push({
              pathname: '/performance/influencer-wise',
              state: {
                type: 'performanceInfluencer',
                brandValue: brand !== null ? brand.value : brand,
                agencyValue: agency !== null ? agency.value : agency,
                campaignValue,
              },
            })
          }}
        >
          View Influencer-wise report
        </button>
      </div>
      <div className='d-flex'>
        {campaignDetails ? (
        <>
          <div className='basicDiv w-350px ms-20px' style={{ height: 'fit-content' }}>
            <div className='d-flex'>
              <div className='d-flex w-100 justify-content-between'>
                <label className='campaignLabel w-95px h-95px rounded-circle'>
                  <img
                    className='w-95px h-95px rounded-circle'
                    src={campaignDetails.campaignDetails?.brandMaster?.brandDetails.avatar}
                  ></img>
                </label>
              </div>
              {(campaignDetails.status !== 'completed' || user.userType === 'admin') &&
                <button
                  type='button'
                  className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary rotate-90'
                  data-kt-menu-trigger='hover'
                  data-kt-menu-placement='bottom-end'
                  data-kt-menu-flip='top-end'
                  >
                    <KTSVG
                      className='svg-icon  svg-icon-dark'
                      path={toAbsoluteUrl('/media/icons/Custom/Menu90.svg')}
                    />
                  </button>
                }
                <span
                  className='menu border border-3 menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold w-200px pt-3 pb-3'
                  data-kt-menu='true'
                >
                  <span className='menu-item px-3'>
                    <a
                      className='menu-link px-3 text-hover-white'
                      onClick={(e: any) => {
                        handleEdit()
                      }}
                    >
                      Edit
                    </a>
                  </span>
                  <span className='menu-item px-3'>
                    <a
                      className='menu-link px-3 text-hover-white'
                      onClick={(e: any) => {
                        setShow(true)
                        setDeleteId(history.location.state?.id)
                      }}
                    >
                      Delete
                    </a>
                  </span>

                  <Modal
                    show={show}
                    onHide={() => {
                      setShow(false)
                    }}
                    aria-labelledby='contained-modal-title-vcenter'
                    centered
                  >
                    <Modal.Header>
                      <Modal.Title>Delete</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you wanna delete?</Modal.Body>
                    <Modal.Footer>
                      <button
                        className='btn fw-bolder btn-gray-200 fs-6 dialogButton'
                        onClick={() => {
                          setShow(false)
                          setDeleteId('')
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        className='btn create-btn fs-6 dialogButton ms-2'
                        onClick={() => handleDelete()}
                      >
                        Delete
                      </button>
                    </Modal.Footer>
                  </Modal>
                </span>
              </div>
              <div className='m16'>
                <h6>{campaignDetails.campaignDetails?.name}</h6>
                <div className='d-flex'>
                  <img
                    className='w-15px'
                    src={
                      campaignDetails.campaignDetails.platform == 'Instagram'
                        ? toAbsoluteUrl('/media/logos/Instagram.svg')
                        : toAbsoluteUrl('/media/logos/Youtube.svg')
                    }
                  ></img>
                  <p className='m-0 ms-2'>For {campaignDetails.campaignDetails.platform}</p>
                </div>
              </div>
              <div className='m24'>
                <h6 className='m-0'>Brand Associated</h6>
                <p className='m-0 text-gray-400'>
                  {campaignDetails.campaignDetails?.brandMaster?.brandDetails.brandName}
                </p>
              </div>
              <div className='m16'>
                <h6 className='m-0'>Agency Associated</h6>
                <p className='m-0 text-gray-400'>
                  {campaignDetails.campaignDetails?.agencyMaster?.agencyDetails.agencyName || 'NA'}
                </p>
              </div>
              <div className='m16'>
                <h6 className='m-0'>Campaign Description</h6>
                <p className='m-0 text-gray-400'>{campaignDetails.campaignDetails?.description}</p>
              </div>

              {/* Campaign live and End Date */}
              <div className='m16 d-flex flex-column'>
                <div className='w-50 pb-3'>
                  <h6 className='m-0'>Campaign Live Date </h6>
                  <p className='m-0 text-gray-400'>
                    {moment(campaignDetails.campaignDetails?.liveDate).format('DD-MM-YYYY')}
                  </p>
                </div>
                <div>
                  <h6 className='m-0'>Campaign End Date</h6>
                  <p className='m-0 text-gray-400'>
                    {moment(campaignDetails.campaignDetails?.campaignEndDate).format('DD-MM-YYYY')}
                  </p>
                </div>
              </div>

              <div className='m16 d-flex flex-column'>
                <div className='w-50 pb-3'>
                  <h6 className='m-0'>Campaign Type </h6>
                  <p className='m-0 text-gray-400'>
                    {campaignDetails.campaignDetails?.type || 'Barter'}
                  </p>
                </div>
                <div>
                  <h6 className='m-0'>Barter Value</h6>
                  <p className='m-0 text-gray-400'>
                    ₹{campaignDetails.campaignDetails?.barterValue?.min} - ₹
                    {campaignDetails.campaignDetails?.barterValue?.max}
                  </p>
                </div>
              </div>
              {/* Application start and End Date */}
              <div className='m16 d-flex flex-column'>
                <div className=' pb-3'>
                  <h6 className='m-0'>Application Open Date </h6>
                  <p className='m-0 text-gray-400'>
                    {moment(campaignDetails.campaignDetails?.startDate).format('DD-MM-YYYY')}
                  </p>
                </div>
                <div>
                  <h6 className='m-0'>Application Close Date</h6>
                  <p className='m-0 text-gray-400'>
                    {moment(campaignDetails.campaignDetails?.endDate).format('DD-MM-YYYY')}
                  </p>
                </div>
              </div>
              <div className='m16'>
                <h6 className='m-0'>Mode of Barter</h6>
                <p className='m-0 text-gray-400'>{campaignDetails.campaignDetails?.mode}</p>
              </div>
              <div className=''>
                <p className='m-0 mt-6 text-gray-400 fs-8'>
                  Created {Moment(campaignDetails.createdAt).format('DD MMM, YYYY')}
                </p>
              </div>
            </div>
            <div
              className='d-flex ms-20px flex-column'
              style={{width: '1113px', marginBottom: '16px'}}
            >
              <div
                className='d-flex flex-column m20 w-100 rounded'
                style={{backgroundColor: 'white'}}
              >
                <div className='border-bottom' style={{padding: '16px 24px'}}>
                  <h2 className='m-0'>Audience Reachability</h2>
                </div>
                {arr.length ? (
                  <div className='w-100' style={{marginTop: '8px'}}>
                    <ColumnChart className='w-100 ' audienceData={audienceData} />
                  </div>
                ) : (
                  <>
                    <div className='flex'>
                      <img
                        className='ms-20'
                        src={toAbsoluteUrl('/media/logos/TableVector.png')}
                      ></img>
                    </div>
                    <div className='flex'>
                      <div>
                        <h2 className='mb-0 m24 flex'>No Data Available</h2>
                      </div>
                    </div>
                  </>
                )}
              </div>
              {/* <div className='d-flex flex-column m20 w-100 rounded' style={{ backgroundColor: 'white' }}>
              <div className='border-bottom' style={{ padding: '16px 24px' }}>
                <h2 className='m-0'>Engagement</h2>
              </div>
              {arr.length ? <div className='w-100' style={{ marginTop: '8px' }}>
                <ColumnChart className='w-100 ' audienceData={audienceData2} />
              </div> :
                <>
                  <div className='flex'>
                    <img className='ms-20' src={toAbsoluteUrl('/media/logos/TableVector.png')}></img>
                  </div>
                  <div className='flex'>
                    <div>
                      <h2 className='mb-0 m24 flex'>No Data Available</h2>
                    </div>
                  </div>
                </>}
            </div> */}
              <div
                className='d-flex flex-column m20 w-100 rounded'
                style={{backgroundColor: 'white'}}
              >
                <div className='border-bottom' style={{padding: '16px 24px'}}>
                  <h2 className='m-0'>Likes</h2>
                </div>
                {arr.length ? (
                  <div className='w-100' style={{marginTop: '8px'}}>
                    <ColumnChart className='w-100 ' audienceData={audienceData3} />
                  </div>
                ) : (
                  <>
                    <div className='flex'>
                      <img
                        className='ms-20'
                        src={toAbsoluteUrl('/media/logos/TableVector.png')}
                      ></img>
                    </div>
                    <div className='flex'>
                      <div>
                        <h2 className='mb-0 m24 flex'>No Data Available</h2>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </>
        ) : (
          <div className='basicDiv ms-20px' style={{width: '98%'}}>
            <div className='flex'>
              <img className='ms-20' src={toAbsoluteUrl('/media/logos/TableVector.png')}></img>
            </div>
            <div className='flex'>
              <div>
                <h2 className='mb-0 m24 flex'>No Campaign Selected</h2>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default PerformanceDashboard
