import React from 'react'
import clsx from 'clsx'
import {useFormik} from 'formik'
import * as Yup from 'yup'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  brandName: Yup.string().required('Brand name is required'),
})
const initialValues = {
  email: '',
  password: '',
  brandName: '',
}
function SetupAccount() {
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {},
  })
  return (
    <div style={{width: '546px'}}>
      <div style={{fontSize: '36px', fontWeight: 700}}>Create an Account</div>
      <span>Setup your account and start accessing your panel</span>
      <form onSubmit={formik.handleSubmit} noValidate id='kt_login_signin_form'>
        <div className='fs-14 m32'>
          <label className='required'>Brand Name</label>
          <input
            placeholder='Brand name goes here'
            {...formik.getFieldProps('brandName')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {'is-invalid': formik.touched.brandName && formik.errors.brandName},
              {
                'is-valid': formik.touched.brandName && !formik.errors.brandName,
              }
            )}
            type='text'
            name='brandName'
            autoComplete='off'
          ></input>
          {formik.touched.brandName && formik.errors.brandName && (
            <div className='fv-plugins-message-container'>
              <span role='alert'>{formik.errors.brandName}</span>
            </div>
          )}
        </div>
        <div className='fs-14 m20'>
          <label className='required'>Email</label>
          <input
            placeholder='companyname@gmail.com'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {'is-invalid': formik.touched.email && formik.errors.email},
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
            type='email'
            name='email'
            autoComplete='off'
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          )}
        </div>
        <div className='fs-14 m20'>
          <label className='required'>Password</label>
          <input
            placeholder='company@1234'
            type='password'
            autoComplete='off'
            {...formik.getFieldProps('password')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.password && formik.errors.password,
              },
              {
                'is-valid': formik.touched.password && !formik.errors.password,
              }
            )}
          ></input>
          {formik.touched.password && formik.errors.password && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.password}</span>
              </div>
            </div>
          )}
        </div>
        <div className='d-flex m32'>
          <button
            id='kt_sign_in_submit'
            className='w-100 btn btn-primary rounded'
            style={{fontSize: '14px', fontWeight: 600}}
            type='submit'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            Continue
          </button>
        </div>
      </form>
    </div>
  )
}

export default SetupAccount
