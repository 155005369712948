import Moment from 'moment'
import { Modal } from 'react-bootstrap-v5'
import { useHistory } from 'react-router-dom'
import { toAbsoluteUrl } from '../../_metronic/helpers'
import httpService from '../../setup/http/httpServices'
import './calendar.css'

export const CampaignModal = (props: any) => {
  const history: any = useHistory()

  return (
    <Modal
      show={props.show}
      onHide={props.close}
      aria-labelledby='contained-modal-title-vcenter '
      centered
    >
      <Modal.Body className='p-0 '>
        <div className='bg-white rounded modal-card'>
          <div className='modal-card-title'>
            <img src={props.details.brandLogo} alt='' />
            <div>
              <h2>{props.details.name}</h2>
              <p>{props.details.brandName}</p>
            </div>
          </div>
          <div className='modal-card-body'>
            <div className='modal-card-bodyL'>
              <p>Platform</p>
              <p>Content Type</p>
              {props.details.agencyName && <p>Agency Associated</p>}
              {props.details.brandName && <p>Brand Associated</p>}
              <p>Influencer Associated</p>
            </div>
            <div className='modal-card-bodyR'>
              <div>
                <img
                  src={toAbsoluteUrl('/media/logos/Instagram.png')}
                  style={{marginRight: '6px'}}
                  alt=''
                />
                Instagram
              </div>
              <div className='flex justify-content-start'>
                <div>
                  <img
                    src={
                      props.details.type === 'photo'
                        ? toAbsoluteUrl('/media/icons/Custom/image1.svg')
                        : toAbsoluteUrl('/media/logos/reels.png')
                    }
                    style={{marginRight: '6px'}}
                    alt=''
                  />
                  {props.details.contentType?.replace(/(\w)(\w*)/g, function (g0, g1, g2) {
                    return g1.toUpperCase() + g2.toLowerCase()
                  })}
                </div>
              </div>
              {props.details.agencyName && (
                <div>
                  <img
                    src={props.details.agencyLogo}
                    style={{
                      width: '24px',
                      height: '24px',
                      borderRadius: '60px',
                      marginRight: '8px',
                    }}
                    alt=''
                  />
                  {props.details.agencyName}
                </div>
              )}
              {props.details.brandName && (
                <div>
                  <img
                    src={props.details.brandLogo}
                    style={{
                      width: '24px',
                      height: '24px',
                      borderRadius: '60px',
                      marginRight: '8px',
                    }}
                    alt=''
                  />
                  {props.details.brandName}
                </div>
              )}
              <div className='symbol-group symbol-hover flex-shrink-0 me-2'>
                {props.details?.influencers &&
                  props.details?.influencers.map((x) => (
                    <div className='symbol symbol-circle symbol-35px border-0'>
                      <img
                        src={toAbsoluteUrl(x.avatar)}
                        style={{
                          width: '24px',
                          height: '24px',
                          borderRadius: '60px',
                          marginRight: '8px',
                        }}
                        alt=''
                      />
                      {x.name}
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className='modal-card-footer'>
            <div>
              <p>Start Date</p>
              <h2>{Moment(props.details.startDate).format('YYYY-MM-DD HH:mm')}</h2>
            </div>
            <div>
              <p>End Date</p>
              <h2>{Moment(props.details.endDate).format('YYYY-MM-DD HH:mm')}</h2>
            </div>
            <div>
              <p>Live Date</p>
              <h2>{Moment(props.details.liveDate).format('YYYY-MM-DD HH:mm')}</h2>
            </div>
          </div>
          <button
            className='btn btn-primary d-flex flex h-34px fs-7'
            onClick={() => {
              httpService.getData(
                '/campaign-master/' + props.details.campaignId,
                (response: any) => {
                  response.data.data.campaign.campaignStatus = response.data.data.campaign.status
                  history.push({
                    pathname: '/campaign/contract',
                    state: {
                      ...response.data.data.campaign,
                      ...{
                        type: response.data.data.campaign.campaignDetails.name,
                        breadcrums: ['Campaigns', 'Content Submission'],
                      },
                    },
                  })
                },
                (error: any) => {}
              )
            }}
          >
            View Details
          </button>
        </div>
      </Modal.Body>
    </Modal>
  )
}
