import clsx from 'clsx'
import {useFormik} from 'formik'
import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import * as Yup from 'yup'
import httpService from '../../../../setup/http/httpServices'

const initialValues = {
  email: 'admin@demo.com',
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
})

export function ForgotPassword() {
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setHasErrors(undefined)
      setTimeout(() => {
        httpService.postData(
          '/auth/forgot-password',
          values,
          (response: any) => {
            setHasErrors(false)
            setLoading(false)
          },
          (error: any) => {
            setHasErrors(true)
            setLoading(false)
            setSubmitting(false)
            setStatus(error.message)
          }
        )
      }, 1000)
    },
  })

  return (
    <>
      <form
        className='form h-100 position-relative flex fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
        style={{width: '558px'}}
      >
        <div>
          <div>
            {/* begin::Title */}
            <h1 style={{fontWeight: 700, fontSize: '36px', lineHeight: '54px'}}>
              Reset to get back in action! 🐝
            </h1>
            {/* end::Title */}

            {/* begin::Link */}
            <div className='fs-14'>Enter your email to receive the password reset link.</div>
            {/* end::Link */}
          </div>

          {/* begin::Title */}
          {hasErrors === true && (
            <div className='mb-10 alert alert-danger'>
              <div className='alert-text font-weight-bold'>
                Sorry, looks like there are some errors detected, please try again.
              </div>
            </div>
          )}

          {hasErrors === false && (
            <div className='mb-10 bg-light-info p-8 rounded'>
              <div className='text-info'>Sent password reset. Please check your email</div>
            </div>
          )}
          {/* end::Title */}

          {/* begin::Form group */}
          <div className='fv-row mb-10 m32'>
            <label className='form-label fs-14 required'>Email</label>
            <input
              type='email'
              placeholder='Enter your email'
              autoComplete='off'
              {...formik.getFieldProps('email')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {'is-invalid': formik.touched.email && formik.errors.email},
                {
                  'is-valid': formik.touched.email && !formik.errors.email,
                }
              )}
            />
            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.email}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group */}
          <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
            <button
              type='submit'
              id='kt_password_reset_submit'
              className='btn btn-primary w-100 mb-5'
            >
              <span className='indicator-label'>Send Link</span>
              {loading && (
                <span className='indicator-progress'>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
            <Link to='/auth/admin/login' className='fs-14 fw-bolder' style={{color: '#495767'}}>
              Back to Login
            </Link>{' '}
          </div>
        </div>
        {/* end::Form group */}
      </form>
    </>
  )
}
