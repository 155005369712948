import React from 'react'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import '../Dashboard.scss'

export function NoData(props: any) {
  return (
    <div className='Dempty' style={{height:props.height}}>
      <img src={toAbsoluteUrl('/media/logos/bar-chart 1.png')} alt="" />
      <h2 className='mt-5'>No Data Available</h2>
    </div>         
  )
}