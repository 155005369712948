/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSSVariableValue} from '../../../_metronic/assets/ts/_utils'
import { reach } from 'yup'

type Props = {
  className: string,
  audienceData:any
}

const ColumnChart: React.FC<Props> = ({className,audienceData}) => {
  const reachability=audienceData?.audience_reachability
  const [count,setCount]=useState(0)
  const cat:Array<String> =[]
  const dat:Array<Number> =[]
  useEffect(() => {
  reachability?.map((reach:any)=>{cat.push(reach.code);dat.push(Math.round(reach.weight*100))})
  window.dispatchEvent(new Event('resize'));   
  }, [reachability,cat,dat])
  
  const chartRef = useRef<HTMLDivElement | null>(null) 
  useEffect(() => {
    if (!chartRef.current) {
      return 
    }

    const chart1 = new ApexCharts(chartRef.current, chart1Options(cat,dat,setCount))

    if (chart1) {
      chart1.render()
      window.dispatchEvent(new Event('resize'));   
    }

    return () => {
      if (chart1) {
        chart1.destroy()   
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [count,cat])
  return (
    <div className={`${className}`}>
      <div className='d-flex flex-column'>
        <div ref={chartRef} id='kt_charts_widget_3_chart' style={{height: 'auto'}}></div>     
      </div>
    </div>
  )}
const chart1Options = (cat:any,dat:any,setCount:any): ApexOptions => {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  if(cat.length==0){
    setCount(1)
  }  
  return {
    series: [{
        name: 'Audience',
        data: dat
      }],
      
        chart: {
          height: 350,
          type: 'bar',
          toolbar:{
            show:false
          }
        },
        plotOptions: {
          bar: {
            borderRadius: 17,
            columnWidth: '20%',
            colors:{
                
                backgroundBarOpacity:1
            }
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: 0
        },
        
        grid: {
          show:true,
          strokeDashArray: 3,
          borderColor: '#F2F4F9',
        },
        legend:{
            show:false
        },
        xaxis: {
          labels: {
            rotate: -45
          },
          categories: cat,
          axisBorder:{
            show:false
          },
          axisTicks:{
            show:false
          }
        },
        fill: {
          colors:['#513A98']
        }
     
  }
  
}

export {ColumnChart}