import React, {useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../_metronic/helpers'
import {Campaign} from './Components/Campaign'
import {Schedule} from './Components/Schedule'
import {Transaction} from './Components/Transaction'
import {StatsAll} from './Charts/StatsAll'
import './Dashboard.scss'
import {useHistory} from 'react-router-dom'
import {NoData} from './Components/NoData'
import httpService from '../../setup/http/httpServices'
import './Dashboard.scss'

export function DashboardAgency() {
  const data = true
  const history = useHistory()
  const [dashboardData, setDashboardData] = useState({} as any)

  const getData = () => {
    httpService.getData(
      '/dashboard/admin-agency',
      (res: any) => {
        setDashboardData(res.data.data)
      },
      (err: any) => {
        console.log(err?.message)
      }
    )
  }
  useEffect(() => {
    getData()
  }, [])
  return (
    <div className='Outer d-flex flex-column'>
      <div className='Dagency'>
        <div className='flex Dagency-top border justify-content-between'>
          <div className='Dcard Dash-left' style={{width: '620px', height: '180px'}}>
            <div>
              <div className='Dash-left-top'>
                <div className='Dash-left-image svg-icon svg-icon-secondary bg-secondary bg-opacity-5'>
                  <img src={toAbsoluteUrl('/media/logos/brands.png')} alt='' />
                </div>
                <div className='Dash-left-head'>
                  <h2 style={{fontSize: '16px'}}>Agencies</h2>
                  <p>Total Agencies</p>
                </div>
              </div>
              {!data ? (
                <div className='Dash-left-bottom mt-10'>
                  <h1 className='DheadingBig'>-</h1>
                </div>
              ) : (
                <div className='Dash-left-bottom mt-10'>
                  <h1 className='DheadingBig'>{dashboardData.stats?.agency?.totalAgency}</h1>
                </div>
              )}
            </div>
            {!data ? (
              <NoData height='160px' />
            ) : (
              <div style={{marginTop: '-40px', width: '400px'}}>
                <StatsAll
                  className='w-100'
                  stats={dashboardData.stats?.agency?.agencyStats.monthlyCount}
                />
              </div>
            )}
          </div>
          <div className='Dcard Dash-right' style={{width: '240px', height: '180px'}}>
            <div className='Dash-right-top'>
              <div className='Dash-right-image svg-icon svg-icon-info bg-info bg-opacity-5'>
                <KTSVG
                  className='svg-icon-3'
                  path={toAbsoluteUrl('/media/icons/Custom/Campaign.svg')}
                />
              </div>
              <div className='Dash-right-head'>
                <h2>Campaigns This Month</h2>
              </div>
            </div>
            {!data ? (
              <div className='Dash-right-bottom'>
                <h1 className='DheadingBig'>-</h1>
              </div>
            ) : (
              <div className='Dash-right-bottom'>
                <h1 className='DheadingBig'>
                  {dashboardData.stats?.campaignStats?.created.current}
                </h1>
              </div>
            )}
          </div>
          <div className='Dcard Dash-right' style={{width: '240px', height: '180px'}}>
            <div className='Dash-right-top'>
              <div className='Dash-right-image svg-icon svg-icon-secondary bg-secondary bg-opacity-5'>
                <KTSVG
                  className='svg-icon-3'
                  path={toAbsoluteUrl('/media/icons/Custom/influencer.svg')}
                />
              </div>
              <div className='Dash-right-head justify-content-start'>
                <h2>Influencers</h2>
              </div>
            </div>
            {!data ? (
              <div className='Dash-right-bottom'>
                <h1 className='DheadingBig'>-</h1>
              </div>
            ) : (
              <div className='Dash-right-bottom'>
                <h1 className='DheadingBig'>-</h1>
              </div>
            )}
          </div>
          <div className='Dcard Dash-right' style={{width: '240px', height: '180px'}}>
            <div className='Dash-right-top'>
              <div className='Dash-right-image svg-icon svg-icon-danger bg-danger bg-opacity-5'>
                <KTSVG
                  className='svg-icon-3'
                  path={toAbsoluteUrl('/media/icons/Custom/Campaign.svg')}
                />
              </div>
              <div className='Dash-right-head justify-content-start'>
                <h2>Live Campaigns</h2>
              </div>
            </div>
            {!data ? (
              <div className='Dash-right-bottom'>
                <h1 className='DheadingBig'>-</h1>
              </div>
            ) : (
              <div className='Dash-right-bottom'>
                <h1 className='DheadingBig'>{dashboardData.stats?.campaignStats?.live.count}</h1>
              </div>
            )}
          </div>
        </div>

        <div className='d-flex justify-content-start align-items-start'>
          <Campaign data={dashboardData.topPerformer?.campaign} />
        </div>
        <div className='flex Dagency-bottom'>
          <Transaction name='Dtransaction' data={dashboardData.topPerformer?.transactions} />
          <Schedule data={dashboardData.topPerformer?.campaignSchedule} />
        </div>
      </div>
    </div>
  )
}
