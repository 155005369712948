import clsx from 'clsx'
import {useFormik} from 'formik'
import React, {useEffect, useState} from 'react'
import {Link, useHistory} from 'react-router-dom'
import * as Yup from 'yup'
import './basic.css'
import {BrandAdminLeftBreadcrum} from './BrandAgencyLeftBreadcrum'
import httpService from '../../setup/http/httpServices'
import {message} from 'antd'
import Select from 'react-select'
import loc from '../data/Location.json'
import {relative} from 'path'
import { IUserModel } from '../modules/auth/models/UserModel'
import { shallowEqual } from 'react-intl/src/utils'
import { RootState } from '../../setup'
import { useSelector } from 'react-redux'

export interface iEntity {
  companyDetails: any
  agencyBandDetails: any
  miscellaneousDetais: any
  type: string
}

var initialValues = {
  agencyAssociated: '',
  companyName: '',
  gstNumber: '',
  companyDescription: '',
  companyContactPerson: '',
  companyEmail: '',
  companyPhoneNumber: '',
  companyWebsite: '',
  companyBillingAddress: '',
  companyCity: '',
  companyState: '',
  companyPinCode: '',
}
const regex = /[a-zA-Z]/
const registrationSchema = Yup.object().shape({
  companyName: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Name is required')
    .matches(/^(?=.*[A-Za-z]).{3,50}$/, 'Should not contain just numbers'),
  companyDescription: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(500, 'Maximum 500 characters')
    .required('Description is required'),
  companyContactPerson: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Contact Person is required')
    .matches(/^(?=[^A-Za-z\n]*[A-Za-z])[A-Za-z._-]*[A-Za-z0-9._ -]*$/,"Must be alphanumeric"), 
  companyEmail: Yup.string()
    .email('Wrong format of Email')
    .matches(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      'Wrong format of Email'
    )
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  companyPhoneNumber: Yup.string()
    .min(10, 'Minimum 10 digits')
    .max(12, 'Maximum 13 digits with country code')
    .required('Phone Number is required'),
  companyWebsite: Yup.string()
    .matches(
      /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
      'Wrong format of URL'
    )
    .min(3, 'Minimum 3 symbols')
    .max(500, 'Maximum 500 symbols')
    .required('Website is required'),
  companyBillingAddress: Yup.string().min(3, 'Minimum 3 symbols').max(500, 'Maximum 500 symbols'),
  companyPinCode: Yup.string().min(6, 'Minimum 6 digit').max(6, 'Maximum 6 digit'),
})

export function CompanyDetails() {
  const history: any = useHistory()
  const [loading, setLoading] = useState(false)
  const [s, setS] = useState('')
  const [st, setSt] = useState(['Rajasthan'])
  const [ct, setCt] = useState([])
  const [city, setCity] = useState({})
  const [state, setState] = useState({})
  // const [agencyAssociated, setAgencyAssociated] = useState({})
  const [companyDetails, setCompanyDetails] = useState(initialValues)
  const [isBrand, setIsBrand] = useState(history.location.state?.type === 'Brand')
  const [agencyList, setAgencyList] = useState([])
  const [agency, setAgency] = useState(null)
  const user: IUserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as IUserModel
  const [agencyListParams, setAgencyListParams] = useState({
    limit: 10,
    page: 1,
    total: 0,
    search: '',
  })

  useEffect(() => {
    if(history.location.state.name || user.userType === 'agency'){
      // setAgency(history.location.state.name)
      httpService.getData(
        '/agency-master/' + (user.userType === 'agency' ? user.agencyId : history.location.state.id),
        (response: any) => {
          
          let companyDetails = response.data.data.agency.companyDetails
          formik.setValues({
            agencyAssociated: (user.userType === 'agency' ? user.agencyId : history.location.state.id),
            companyName: companyDetails.companyName,
            gstNumber: companyDetails.gstNumber,
            companyDescription: companyDetails.description,
            companyContactPerson: companyDetails.contactPerson,
            companyEmail: companyDetails.email,
            companyPhoneNumber: companyDetails.phoneNumber,
            companyWebsite: companyDetails.website,
            companyBillingAddress: companyDetails.billingAddress,
            companyCity: companyDetails.city,
            companyState: companyDetails.state,
            companyPinCode: companyDetails.pinCode,
          })
          setState({value: companyDetails.state, label: companyDetails.state})
          setCity({value: companyDetails.city, label: companyDetails.city})
          response.data.data.agency['value'] = response.data.data.agency._id
          response.data.data.agency['label'] = response.data.data.agency.agencyDetails.agencyName
          setAgency(response.data.data.agency)
          
        },
        (error: any) => {
          message.error(error?.message)
        }
      )
    }
    httpService.getData(
      '/agency-master?limit=' +
        0 +
        '&page=' +
        agencyListParams.page +
        '&search=' +
        agencyListParams.search,
      (response: any) => {
        let agencyResponse = response.data.data
        agencyResponse.agencies.map((x: any) => {
          x['value'] = x._id
          x['label'] = x.agencyDetails.agencyName
          return x
        })
        setAgencyList(agencyResponse.agencies)
        if (history.location.state.companyDetails) {
          const selectedAgencyAssociated: any = agencyResponse.agencies.find(
            (x: any) => x._id === (user.userType === 'agency' ? user.agencyId : history.location.state.companyDetails?.agencyAssociated)
          )
          setAgency({...selectedAgencyAssociated})
        }
        setAgencyListParams({
          limit: agencyResponse.limit,
          page: agencyResponse.page,
          total: agencyResponse.total,
          search: agencyListParams.search,
        })
      },
      (error: any) => {
        message.error(error.message)
      }
    )
  }, [])

  const formik = useFormik({
    initialValues: companyDetails || initialValues,
    validationSchema: registrationSchema,
    enableReinitialize: true,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setTimeout(() => {
        try {
          setCompanyDetails(values)
          // let request: any = values;
          // request.agencyAssociated = agencyAssociated;
          setLoading(false)
          setSubmitting(false)
          history.push({
            pathname: '/agencyDetails',
            state: {
              ...history.location.state,
              ...{companyDetails: values},
            },
          })
        } catch {
          setLoading(false)
          setSubmitting(false)
          setStatus('Registration process has broken')
          // Remove logs when not needed
        }
      }, 1000)
    },
  })

  useEffect(() => {
    try {
      var companyDetail1 = history.location.state?.companyDetails
      setCompanyDetails(companyDetail1)
      var c = {value: companyDetail1.companyCity, label: companyDetail1.companyCity}
      var s = {value: companyDetail1.companyState, label: companyDetail1.companyState}
      setState(s)
      setCity(c)
    } catch {}
  }, [history.location.state?.companyDetails])
  //   function checkGST2(g:any) {
  //     let a=65,b=55,c=36;
  //     return Array['from'](g).reduce((i,j,k,g)=>{
  //      p=(p=(j.charCodeAt(0)<a?parseInt(j):j.charCodeAt(0)-b)*(k%2+1))>c?1+(p-c):p;
  //     return k<14?i+p:j==((c=(c-(i%c)))<10?c:String.fromCharCode(c+b));
  //     },0);
  // }

  function checkGST(g: any) {
    if (g === '') return true
    if (g.length != 15) return false
    var inputvalues = g
    var gstinformat =
      /^([0-2][0-9]|[3][0-7])[A-Z]{3}[ABCFGHLJPTK][A-Z]\d{4}[A-Z][A-Z0-9][Z][A-Z0-9]$/
    if (gstinformat.test(inputvalues)) {
      return true
    } else {
      return false
    }
  }
  const getSt = () => {
    var s: any = []
    loc.map((l) => {
      var p = {value: l.State, label: l.State}
      s.push(p)
    })
    setSt(s)
    setS('Andaman and Nicobar Islands')
  }

  const getCt = () => {
    var c: any = []
    var ci: any = []
    loc.map((l) => {
      if (l.State == s) {
        c = l.Cities
        c.map((ct: any) => {
          var p = {value: ct, label: ct}
          ci.push(p)
        })
      }
    })
    setCt(ci)
  }
  useEffect(() => {
    getSt()
    getCt()
  }, [])
  useEffect(() => {
    getCt()
  }, [s])
  const [nameError, setNameError] = useState(false)
  const validateName = (name: any) => {
    httpService.getData(
      '/' + history.location.state?.type.toLowerCase() + '-master/',
      (res: any) => {
        if (history.location.state?.type == 'Agency') {
          for (var i = 0; i < res.data.data?.agencies.length; i++) {
            if (res.data.data?.agencies[i].companyDetails.companyName == name) {
              setNameError(true)
              break
            } else {
              setNameError(false)
            }
          }
        } else {
          for (var i = 0; i < res.data.data?.brands.length; i++) {
            if (res.data.data?.brands[i].companyDetails.companyName == name) {
              setNameError(true)
              break
            } else {
              setNameError(false)
            }
          }
        }
      },
      (err: any) => {}
    )
  }

  return (
    <div className='CompanyDetailsOuter '>
      <BrandAdminLeftBreadcrum currentStep='1' type={history.location.state?.type} />
      <form
        className='CompanyDetails'
        noValidate
        id='kt_login_signup_form'
        onSubmit={formik.handleSubmit}
        style={{marginBottom: '100px'}}
      >
        <h2 className=''>Enter Company Details</h2>
        {isBrand && (
          <div className='row pt-4 pe-5 '>
            <h6 className='fw-bolder '>Select Agency</h6>
            <div className='col '>
              <Select
                options={agencyList}
                value={agency}
                isMulti={false}
                isDisabled = {history.location.state.id || user.userType === 'agency'}
                name='agencyAssociated'
                placeholder='Agency Name'
                styles={{
                  menuList: (provided, state) => ({
                    ...provided,
                    height: 'auto',
                    maxHeight:'200px'
                  }),
                }}
                className={clsx(
                  'text-gray-500 form-control form-control-solid bg-gray-100 p-0 border-0 categorySelect'
                )}
                onChange={(e: any) => {
                  httpService.getData(
                    '/agency-master/' + e.value,
                    (response: any) => {
                      let companyDetails = response.data.data.agency.companyDetails
                      formik.setValues({
                        agencyAssociated: e.value,
                        companyName: companyDetails.companyName,
                        gstNumber: companyDetails.gstNumber,
                        companyDescription: companyDetails.description,
                        companyContactPerson: companyDetails.contactPerson,
                        companyEmail: companyDetails.email,
                        companyPhoneNumber: companyDetails.phoneNumber,
                        companyWebsite: companyDetails.website,
                        companyBillingAddress: companyDetails.billingAddress,
                        companyCity: companyDetails.city,
                        companyState: companyDetails.state,
                        companyPinCode: companyDetails.pinCode,
                      })
                      setState({value: companyDetails.state, label: companyDetails.state})
                      setCity({value: companyDetails.city, label: companyDetails.city})
                      setAgency(e)
                      
                    },
                    (error: any) => {
                      message.error(error?.message)
                    }
                  )
                }}
              />
            </div>
          </div>
        )}

        <div className='row pt-4 pe-5 '>
          <div className='col '>
            <h6 className='fw-bold required'>Company Name</h6>
            <input
              type='text'
              placeholder='Company Name'
              value={formik.values.companyName}
              onChange={(e) => {
                validateName(e.target.value)
                formik.setFieldValue('companyName', e.target.value)
              }}
              className={clsx(
                'form-control form-control-solid bg-gray-100',
                {
                  'is-invalid':
                    (formik.touched.companyName && formik.errors.companyName) || nameError,
                },
                {
                  'is-valid': formik.touched.companyName && !formik.errors.companyName,
                }
              )}
            />
            {nameError && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='text-danger' role='alert'>
                    Company Name already taken.
                  </span>
                </div>
              </div>
            )}
            {formik.errors.companyName && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='text-danger' role='alert'>
                    {formik.errors.companyName}
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className='col '>
            <h6 className='fw-bold'>GST Number</h6>
            <input
              type='text'
              placeholder='GST Number'
              {...formik.getFieldProps('gstNumber')}
              className={clsx(
                'form-control form-control-solid bg-gray-100',
                {
                  'is-invalid': formik.touched.gstNumber && formik.errors.gstNumber,
                },
                {
                  'is-valid': formik.touched.gstNumber && !formik.errors.gstNumber,
                }
              )}
            />
            {!checkGST(formik.values.gstNumber) && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='text-danger' role='alert'>
                    Wrong GST format
                  </span>
                </div>
              </div>
            )}
            {formik.touched.gstNumber && formik.errors.gstNumber && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='text-danger' role='alert'>
                    {formik.errors.gstNumber}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='row pt-4 pe-5 '>
          <h6 className='fw-bold required'>Enter Company Details</h6>
          <div className='col '>
            <textarea
             style={{resize:"none"}}
              placeholder="Example: `Your company name` is a part of `Your brand name's` parent company group. It was established in `Founding year` with the aim of making `Your industry` accessible to all."
              {...formik.getFieldProps('companyDescription')}
              className={clsx(
                'form-control form-control-solid bg-gray-100 h-100px',
                {
                  'is-invalid':
                    formik.touched.companyDescription && formik.errors.companyDescription,
                },
                {
                  'is-valid':
                    formik.touched.companyDescription && !formik.errors.companyDescription,
                }
              )}
            />
            {formik.touched.companyDescription && formik.errors.companyDescription && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='text-danger' role='alert'>
                    {formik.errors.companyDescription}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='row pt-4 pe-5 '>
          <div className='col '>
            <h6 className='fw-bold required'>Contact Person</h6>
            <input
              type='text'
              placeholder='Contact Person'
              {...formik.getFieldProps('companyContactPerson')}
              className={clsx(
                'form-control form-control-solid bg-gray-100',
                {
                  'is-invalid':
                    formik.touched.companyContactPerson && formik.errors.companyContactPerson,
                },
                {
                  'is-valid':
                    formik.touched.companyContactPerson && !formik.errors.companyContactPerson,
                }
              )}
            />
            {formik.touched.companyContactPerson && formik.errors.companyContactPerson && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='text-danger' role='alert'>
                    {formik.errors.companyContactPerson}
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className='col '>
            <h6 className='fw-bold required'>Email</h6>
            <input
              type='text'
              placeholder='Email Address'
              {...formik.getFieldProps('companyEmail')}
              className={clsx(
                'form-control form-control-solid bg-gray-100',
                {
                  'is-invalid': formik.touched.companyEmail && formik.errors.companyEmail,
                },
                {
                  'is-valid': formik.touched.companyEmail && !formik.errors.companyEmail,
                }
              )}
            />
            {formik.touched.companyEmail && formik.errors.companyEmail && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='text-danger' role='alert'>
                    {formik.errors.companyEmail}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='row pt-4 pe-5 '>
          <div className='col '>
            <h6 className='fw-bold required'>Phone Number</h6>
            <input
              type='number'
              placeholder='Phone Number'
              {...formik.getFieldProps('companyPhoneNumber')}
              className={clsx(
                'form-control form-control-solid bg-gray-100',
                {
                  'is-invalid':
                    formik.touched.companyPhoneNumber && formik.errors.companyPhoneNumber,
                },
                {
                  'is-valid':
                    formik.touched.companyPhoneNumber && !formik.errors.companyPhoneNumber,
                }
              )}
            />
            {formik.touched.companyPhoneNumber && formik.errors.companyPhoneNumber && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='text-danger' role='alert'>
                    {formik.errors.companyPhoneNumber}
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className='col '>
            <h6 className='fw-bold required'>Website</h6>
            <input
              type='text'
              placeholder='Website'
              {...formik.getFieldProps('companyWebsite')}
              className={clsx(
                'form-control form-control-solid bg-gray-100',
                {
                  'is-invalid': formik.touched.companyWebsite && formik.errors.companyWebsite,
                },
                {
                  'is-valid': formik.touched.companyWebsite && !formik.errors.companyWebsite,
                }
              )}
            />
            {formik.touched.companyWebsite && formik.errors.companyWebsite && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='text-danger' role='alert'>
                    {formik.errors.companyWebsite}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='row pt-4 pe-5 '>
          <h6 className='fw-bold'>Billing Address</h6>
          <div className='col '>
            <textarea
             style={{resize:"none"}}
              placeholder='Billing Address'
              {...formik.getFieldProps('companyBillingAddress')}
              className={clsx(
                'form-control form-control-solid bg-gray-100 h-100px',
                {
                  'is-invalid':
                    formik.touched.companyBillingAddress && formik.errors.companyBillingAddress,
                },
                {
                  'is-valid':
                    formik.touched.companyBillingAddress && !formik.errors.companyBillingAddress,
                }
              )}
            />
            {formik.touched.companyBillingAddress && formik.errors.companyBillingAddress && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='text-danger' role='alert'>
                    {formik.errors.companyBillingAddress}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='row pt-4 pe-5 pb-5'>
          <div className='col '>
            <h6 className='fw-bold'>State</h6>
            <Select
              options={st}
              value={state}
              isMulti={false}
              name='companyState'
              placeholder='Select State'
              styles={{
                menuList: (provided, state) => ({
                  ...provided,
                  height: 'auto',
                  maxHeight:"200px"
                }),
              }}
              className={clsx(
                'text-gray-500 form-control form-control-solid bg-gray-100 p-0 border-0 categorySelect',
                {
                  'is-invalid': formik.touched.companyState && formik.errors.companyState,
                },
                {
                  'is-valid': formik.touched.companyState && !formik.errors.companyState,
                }
              )}
              onChange={(e: any) => {
                formik.setFieldValue('companyState', e.value)
                setS(e.value)
                setState(e)
              }}
            />
            {formik.touched.companyState && formik.errors.companyState && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='text-danger' role='alert'>
                    {formik.errors.companyState}
                  </span>
                </div>
              </div>
            )}
          </div>

          <div className='col '>
            <h6 className='fw-bold'>City</h6>
            <Select
              options={ct}
              value={city}
              isMulti={false}
              name='companyCity'
              placeholder='Select City'
              styles={{
                menuList: (provided, state) => ({
                  ...provided,
                  height: 'auto',
                  maxHeight:'200px'
                }),
              }}
              className={clsx(
                'text-gray-500 form-control form-control-solid bg-gray-100 p-0 border-0 categorySelect',
                {
                  'is-invalid': formik.touched.companyCity && formik.errors.companyCity,
                },
                {
                  'is-valid': formik.touched.companyCity && !formik.errors.companyCity,
                }
              )}
              onChange={(e: any) => {
                formik.setFieldValue('companyCity', e.value)
                setCity(e)
              }}
            />
            {formik.touched.companyCity && formik.errors.companyCity && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='text-danger' role='alert'>
                    {formik.errors.companyCity}
                  </span>
                </div>
              </div>
            )}
          </div>

          <div className='col '>
            <h6 className='fw-bold'>Pincode</h6>
            <input
              type='text'
              placeholder='Pincode'
              {...formik.getFieldProps('companyPinCode')}
              className={clsx(
                'form-control form-control-solid bg-gray-100',
                {
                  'is-invalid': formik.touched.companyPinCode && formik.errors.companyPinCode,
                },
                {
                  'is-valid': formik.touched.companyPinCode && !formik.errors.companyPinCode,
                }
              )}
              style={{height: '38px'}}
            />
            {formik.touched.companyPinCode && formik.errors.companyPinCode && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='text-danger' role='alert'>
                    {formik.errors.companyPinCode}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='d-flex pt-4 pe-5'>
          <Link
            to={{
              pathname:
                '/master/' + (history.location.state?.type === 'Brand' ? 'brands' : 'agencies'),
              state: history.location.state,
            }}
          >
            <button type='button' className='btn fw-bolder btn-gray-200 CompanyDetailButton'>
              Cancel
            </button>
          </Link>
          <button
            type='submit'
            id='kt_sign_up_submit'
            className='btn create-btn ms-5 CompanyDetailButton'
            disabled={
              formik.isSubmitting ||
              !formik.isValid ||
              nameError ||
              !checkGST(formik.values.gstNumber)
            }
          >
            {!loading && <span className='indicator-label'>Next</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}
