import { Input, message } from 'antd'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { KTSVG, toAbsoluteUrl } from '../../_metronic/helpers'
import httpService from '../../setup/http/httpServices'
import '../masters/basic.css'
import { ProfileAvatar } from '../modules/widgets/components/ProfileAvatar'

declare global {
  interface Window {
    Razorpay: any
  }
}

export function Payment(props: any) {
  const history: any = useHistory()

  // const [priceMaster, setPriceMaster] = useState<any>([]);
  const [priceMatrix, setPriceMatrix] = useState(null)
  const [CGST, setCGST] = useState<number>(0)
  const [SGST, setSGST] = useState<number>(0)
  const [subTotal, setSubTotal] = useState<number>(0)
  const [total, setTotal] = useState<number>(0)
  const [couponCode, setCouponCode] = useState('')
  const [coupons, setCoupons] = useState([])
  const [discount, setDiscount] = useState(0)
  const [totalCount, setTotalCount] = useState(null)

  const [apiComplete, setapiComplete] = useState(false)
  const [allData, setAllData] = useState(null)
  const [shortlistStatus, setshortlistStatus] = useState('shortListed')
  const [influencerList, setinfluencerList] = useState([])
  const [standByActive, setstandByActive] = useState(false)
  useEffect(() => {
    // console.log("Agency GST number -- ", props?.campaignDetails?.agencyMaster?.companyDetails?.gstNumber, "<<<")
    getAllInfluencer()

    httpService.getData(
      '/coupon',
      (response: any) => {
        setCoupons(response.data.data.coupons)
      },
      (error: any) => {}
    )
    httpService.getData(
      '/influencer-onboarding/priceMatrix/' + `${props.campaignId}`,
      (response: any) => {
        // let priceMatrixData = response.data.data
        var transactionAmountDetail = response.data.data
        const subtotal: number = transactionAmountDetail.shortListed.reduce(
          (total: any, number: any) => {
            return (
              parseInt(total) + parseInt(number.noOfInfluencer) * parseInt(number.costPerInfluencer)
            )
          },
          0
        )
        setPriceMatrix(transactionAmountDetail)
        setSubTotal(subtotal)
        setCGST(subtotal * 0.09)
        setSGST(subtotal * 0.09)
        setTotal(subtotal + subtotal * 0.09 * 2)
      },
      (error: any) => {
        message.error(error.message)
      }
    )
  }, [history.location.state?.transactionAmountDetails])

  const getAllInfluencer = () => {
    setapiComplete(false)
    httpService.getData(
      '/influencer-onboarding/' + `${props.campaignId}` + '?status=shortListed',
      (response: any) => {
        let data = response.data.data
        setAllData(data.influencers)
        setTotalCount(data.counts ? data.counts : null)
        setinfluencerList(data.influencers)
        setapiComplete(true)
      },
      (error: any) => {
        message.error(error.message)
        setapiComplete(true)
      }
    )
  }

  const handleSubmit = () => {
    let amountDetails = []
    priceMatrix.shortListed.forEach((influencer: any) => {
      if (influencer.noOfInfluencer > 0)
        amountDetails.push({
          influencerCategory: influencer.priceMasterId,
          qty: influencer.noOfInfluencer,
        })
    })
    httpService.getData(
      '/transaction/reference-number',
      (response: any) => {
        httpService.postData(
          '/transaction',
          {
            basicInformation: {
              referenceNumber: response.data.data.referenceNumber,
              date: new Date().toDateString(),
              agency:
                typeof history.location.state.campaignDetails.agencyMaster === 'string'
                  ? history.location.state.campaignDetails.agencyMaster
                  : history.location.state.campaignDetails?.agencyMaster?._id,
              brand:
                typeof history.location.state.campaignDetails.brandMaster === 'string'
                  ? history.location.state.campaignDetails.brandMaster
                  : history.location.state.campaignDetails.brandMaster._id,
              campaign: history.location.state._id,
              remark:
                'Transaction conducted fot campaign' + history.location.state.campaignDetails.name,
            },
            method: 'Bank Transfer',
            amountDetails: amountDetails,
            coupon: couponCode ? coupons.find((x) => x.code === couponCode)._id : null,
            gstAmount: CGST + SGST,
            discount: discount,
          },
          (response: any) => {
            makePayment(response.data.data.transaction._id)
          },
          (error: any) => {
            message.error(error?.message)
          }
        )
      },
      (error: any) => {
        message.error(error?.message)
      }
    )
  }

  const makePayment = (transactionId) => {
    httpService.postData(
      '/payment/order',
      {
        amount: Math.ceil(total * 100),
        currency: 'INR',
        transaction: transactionId,
      },
      (res: any) => {
        const orderData = res.data.data
        const options = {
          key: process.env.REACT_APP_RZR_KEY, // Enter the Key ID generated from the Dashboard
          amount: orderData.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
          currency: 'INR',
          name: 'Sehaye',
          description: 'Sehaye Payment',
          image: '/media/logos/Vavo-Logo.png',
          order_id: orderData.razorpay.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
          handler: function (response) {
            httpService.postData(
              '/payment/verify',
              {
                id: orderData._id,
                orderId: response.razorpay_order_id,
                paymentId: response.razorpay_payment_id,
                signature: response.razorpay_signature,
              },
              (response: any) => {
                httpService.putData(
                  '/influencer-onboarding/update-paid-status/' + history.location.state._id,
                  {},
                  (response: any) => {
                    message.success('Payment successul')
                    history.push({
                      pathname: '/campaign/influencerOnborading/' + `${history.location.state._id}`,
                      state: {
                        ...history.location.state,
                        ...{type: 'influencerOnboard', status: 'Communicate', isPaid: true},
                        ...{breadcrums: ['Campaigns', 'Communicate']},
                      },
                    })
                  },
                  (error: any) => {}
                )
              },
              (error: any) => {
                message.success('Payment failed')
              }
            )
          },
          theme: {
            color: '#333366',
          },
        }
        const rzp1 = new window.Razorpay(options)
        rzp1.open()
      },
      (err: any) => {}
    )
  }

  return (
    <div className='d-flex'>
      {priceMatrix?.shortListed === 0 && (
        <div className='w-100 h-100 flex'>
          <div className='bg-white p-10 mt-15 rounded'>
            <div className='flex'>
              <img className='ms-20' src={toAbsoluteUrl('/media/logos/TableVector.png')}></img>
            </div>
            <div className='flex'>
              <div>
                <h2 className='mb-0 m24 flex'>No influencers to pay.</h2>
              </div>
            </div>
            <div className='flex'>
              <Link
                to={{
                  pathname: '/campaign/dashboard',
                  state: {type: 'campaign/dashboard', status: '', reloaded: false},
                }}
              >
                <button className='btn m24 TableButton btn-gray-100 text-dark me-5'>
                  Go to Campaigns
                </button>
              </Link>
              <button
                className='btn m24 TableButton btn-pink text-white'
                onClick={() => {
                  history.push({
                    pathname: '/campaign/influencerOnborading/' + history.location.state._id,
                    state: {
                      ...history.location.state,
                      ...{type: 'influencerOnboard', status: 'Shortlist'},
                    },
                  })
                }}
              >
                Proceed To Shortlist Applications
              </button>
            </div>
          </div>
        </div>
      )}
      {priceMatrix?.shortListed !== 0 && (
        <>
          <div className='d-flex w-75'>
            <div className='CompanyDetails w-100 p-8' id='kt_login_signup_form'>
              <h2 className='m-0 fw-bolder'>Pay for Short-Listed Influencers</h2>
              <div className='border border-bottom-0 p-5 rounded-top m24'>
                <div className='d-flex'>
                  <div className='col fw-bolder flex justify-content-start'>
                    Influencer Category
                  </div>
                  <div className='col fw-bolder flex justify-content-end me-5'>
                    Influencer Count
                  </div>
                  <span className='text-gray-400'>x</span>
                  <div className='col fw-bolder flex justify-content-start ms-5'>Barter Value</div>
                  <div className='col fw-bolder flex justify-content-end'>Amount</div>
                </div>
                <span className='separator border-gray-200 m16'></span>

                {priceMatrix?.shortListed
                  .filter((x) => x.noOfInfluencer > 0)
                  .map((bucket: any, index: number) => (
                    <React.Fragment>
                      <div className='col m16 d-flex'>
                        <div className={'col fw-bold flex justify-content-start required'}>
                          {bucket.bucketName}
                        </div>
                        <div className='col flex justify-content-end me-5'>
                          <input
                            type='number'
                            value={bucket.noOfInfluencer}
                            className={clsx(
                              'form-control form-control-solid bg-gray-100 w-95px h-35px'
                            )}
                            disabled
                          />
                        </div>
                        {/* <span className='text-gray-400 flex'>{bucket.type !== "custom" ? 'x' : <div>&nbsp;</div>} </span> */}
                        <div className='col flex justify-content-start ms-5'>
                          <input
                            type='number'
                            value={bucket.costPerInfluencer}
                            className={clsx(
                              'form-control form-control-solid bg-gray-100 w-125px h-35px'
                            )}
                            disabled
                          />
                        </div>

                        <div className='col fw-bold flex justify-content-end'>
                          <span placeholder='200'>
                            Rs. {bucket.noOfInfluencer * bucket.costPerInfluencer}
                          </span>
                        </div>
                      </div>
                    </React.Fragment>
                  ))}

                <span className='separator border-gray-200 m16'></span>
                <div className='m16 d-flex'>
                  <div className='col flex justify-content-start'>
                    Total Payment for Shortlisted Influencers
                  </div>
                  <div className='col flex justify-content-end me-5'>
                    {priceMatrix?.total?.shortListed}
                  </div>

                  <div className='col flex justify-content-start ms-5'></div>

                  <div className='col fw-bold flex justify-content-end'>
                    <span placeholder='0000'>Rs. {subTotal}</span>
                  </div>
                </div>
                <div className='m16 d-flex'>
                  <div className='col flex justify-content-start'>
                    Total Payment for StandBy Influencers
                  </div>
                  <div className='col flex justify-content-end me-5'>
                    {priceMatrix?.total?.standby}
                  </div>
                  <span className='text-gray-400 flex'></span>
                  <div className='col flex justify-content-start ms-5'></div>

                  <div className='col fw-bold flex justify-content-end'>
                    <span placeholder='0000'>Rs. 0</span>
                  </div>
                </div>
                <span className='separator border-gray-200 m16'></span>
                <div className='m16 d-flex'>
                  <div className='col fw-bolder flex justify-content-start'>SubTotal</div>
                  <div className='col flex justify-content-end me-5'>
                    {priceMatrix?.total?.standby + priceMatrix?.total?.shortListed}
                  </div>
                  <span className='text-gray-400 flex'></span>
                  <div className='col flex justify-content-start ms-5'></div>

                  <div className='col fw-bold flex justify-content-end'>
                    <span placeholder='0000'>Rs. {subTotal}</span>
                  </div>
                </div>
                <span className='separator border-gray-200 m16'></span>
                <h6 className='m16'>Taxes</h6>
                {/* <div className='m16 d-flex'>
                  <div className='col fw-bold flex justify-content-start '>CGST(9%)</div>
                  <div className='col fw-bold flex justify-content-end'>
                    <span placeholder='0000'>Rs. {CGST}</span>
                  </div>
                </div>
                <div className='m16 d-flex'>
                  <div className='col fw-bold flex justify-content-start'>SGST(9%)</div>
                  <div className='col fw-bold flex justify-content-end'>
                    <span placeholder='0000'>Rs. {SGST}</span>
                  </div>
                </div> */}

                {props?.campaignDetails?.agencyMaster?.companyDetails?.gstNumber.startsWith("27") ? (
                  <div>
                    <div className='m16 d-flex'>
                      <div className='col fw-bold flex justify-content-start '>CGST(9%)</div>
                      <div className='col fw-bold flex justify-content-end'><span placeholder='0000'>Rs. {CGST}</span></div>
                    </div>
                    <div className='m16 d-flex'>
                      <div className='col fw-bold flex justify-content-start'>SGST(9%)</div>
                      <div className='col fw-bold flex justify-content-end'><span placeholder='0000'>Rs. {SGST}</span></div>
                    </div>
                  </div>
                ) : (
                  <div className='m16 d-flex'>
                    <div className='col fw-bold flex justify-content-start'>IGST(18%)</div>
                    <div className='col fw-bold flex justify-content-end'><span placeholder='0000'>Rs. {CGST + SGST}</span></div>
                  </div>
                )}

                <span className='separator border-gray-200 m16'></span>
                <div className='m16 d-flex'>
                  <div
                    className='col-10 fw-bolder fs-4 flex justify-content-start'
                    style={{lineHeight: '0.8', alignItems: 'baseline'}}
                  >
                    Coupon Code
                    <span className='w-300px h-50px ms-10'>
                      <Input
                        placeholder='Enter the code'
                        className='form-control-solid bg-white border rounded  h-50px'
                        onChange={(e: any) => {
                          setCouponCode(e.target.value)
                        }}
                      />
                    </span>
                    <button
                      type='button'
                      onClick={() => {
                        const coupon = coupons.find((x) => x.code === couponCode)
                        if (coupon) {
                          const subtotal: number = priceMatrix.shortListed.reduce(
                            (total: any, number: any) => {
                              return (
                                parseInt(total) +
                                parseInt(number.noOfInfluencer) * parseInt(number.costPerInfluencer)
                              )
                            },
                            0
                          )
                          const fintotal = subtotal + subtotal * 0.09 * 2
                          if (coupon.minimumSpend > fintotal) {
                            return
                          }
                          if (coupon.percentageValue) {
                            setDiscount((fintotal * coupon.percentageValue) / 100)
                            setTotal(fintotal - (fintotal * coupon.percentageValue) / 100)
                          } else if (coupon.moneyValue) {
                            setDiscount(coupon.moneyValue)
                            setTotal(fintotal - coupon.moneyValue)
                          }
                        }
                      }}
                      className='btn btn-primary ms-5 h-50px lh-1'
                    >
                      <span className=''>Apply</span>
                    </button>
                  </div>
                  <div className='col fw-bold flex justify-content-end'>
                    <span className='fs-3' placeholder='0000'>
                      Rs. {discount}
                    </span>
                  </div>
                </div>

                {couponCode && !coupons.find((x) => x.code === couponCode) ? (
                  <div className='fw-600 fs-5' style={{color: 'red'}}>
                    Coupon is invalid. Try another
                  </div>
                ) : (
                  couponCode &&
                  coupons.find((x) => x.minimumSpend > total) && (
                    <div className='fw-600 fs-5' style={{color: 'red'}}>
                      Spend atleast Rs. {coupons.find((x) => x.minimumSpend > total).minimumSpend}{' '}
                      to apply this coupon.
                    </div>
                  )
                )}
              </div>
              <div className='p-5 d-flex bg-gray-100 rounded-bottom'>
                <div className='col fw-bolder fs-3 flex justify-content-start'>Total</div>
                <div className='col fw-bold flex justify-content-end'>
                  <span className='fs-3' placeholder='0000'>
                    Rs. {total}
                  </span>
                </div>
              </div>
              <div className='d-flex justify-content-end m24'>
                <button
                  type='button'
                  id='kt_sign_up_submit'
                  onClick={handleSubmit}
                  className='btn btn-primary CompanyDetailButton w-400px'
                >
                  <span className='indicator-label'>Pay Now</span>
                </button>
              </div>
            </div>
          </div>
          <div className='RecentSummary w-25'>
            <div className='RecentDiv d-flex pr-0 w-100'>
              <div className='SummaryDiv w-100' style={{marginTop: '20px', marginLeft: '0px'}}>
                <div className='row '>
                  <span className='col-9 w-100'>
                    <div className='d-flex sub-menu w-100'>
                      <ul className='nav nav-pills w-100 justify-content-around align-content-center'>
                        <li className='nav-item '>
                          <a
                            style={{borderRadius: '49px'}}
                            onClick={() => setshortlistStatus('shortListed')}
                            className={
                              shortlistStatus == 'shortListed'
                                ? 'nav-link d-flex w-auto h-40px flex ps-6 pe-6 active'
                                : 'nav-link d-flex w-auto h-40px flex text-gray-400 fw-bold'
                            }
                          >
                            {' '}
                            Shortlisted ( {totalCount?.shortListed} )
                          </a>
                        </li>
                        <li className='nav-item '>
                          <a
                            style={{borderRadius: '49px'}}
                            onClick={() => setshortlistStatus('onHold')}
                            className={
                              shortlistStatus == 'standby'
                                ? 'nav-link d-flex w-auto h-40px flex ps-6 pe-6 active'
                                : 'nav-link d-flex w-auto h-40px flex text-gray-400 fw-bold'
                            }
                          >
                            {' '}
                            Standby ( {totalCount?.onHold} )
                          </a>
                        </li>
                      </ul>
                    </div>
                  </span>
                  {influencerList[shortlistStatus] &&
                    influencerList[shortlistStatus].length > 0 &&
                    influencerList[shortlistStatus].map((data: any, index: number) => (
                      <div className='d-flex mt-3'>
                        {/* {data.status === shortlistStatus && ( */}
                        <>
                          <span className='flex justify-content-start w-100 '>
                            <ProfileAvatar
                              src={
                                data?.influencer.basicInfo.avatar
                                  ? data?.influencer.basicInfo.avatar
                                  : null
                              }
                              className='w-100 rounded-circle ml-2 '
                              name={
                                data?.influencer.basicInfo.name
                                  ? data?.influencer.basicInfo.name[0].toUpperCase()
                                  : 'NA'
                              }
                            ></ProfileAvatar>

                            <span className='fs-6 ms-4 mt-1 '>
                              <label
                                className={
                                  !data.isPaid ? 'fs-7 m-0 grayout' : 'fs-7 text-gray-400 m-0'
                                }
                              >
                                {data?.influencer.basicInfo.name}
                              </label>
                              {data.influencer?.basicInfo?.addressDetails?.find((x) => x.isDefault)
                                ?.city && (
                                <label className='flex fs-7'>
                                  <KTSVG
                                    className='svg-icon me-1'
                                    path={toAbsoluteUrl('/media/icons/Custom/MapMarker.svg')}
                                  />{' '}
                                  {
                                    data.influencer.basicInfo.addressDetails.find(
                                      (x) => x.isDefault
                                    )?.city
                                  }
                                  ,{' '}
                                  {
                                    data.influencer.basicInfo.addressDetails.find(
                                      (x) => x.isDefault
                                    )?.state
                                  }
                                </label>
                              )}
                            </span>
                          </span>
                          <span className='separator border-gray-200 m16'></span>{' '}
                        </>
                        {/* )} */}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}
