import {SearchOutlined} from '@ant-design/icons'
import {Input, message, Pagination} from 'antd'
import Moment from 'moment'
import React, {useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap-v5'
import {shallowEqual, useSelector} from 'react-redux'
import {Link, useHistory} from 'react-router-dom'
import httpService from '../../setup/http/httpServices'
import {RootState} from '../../setup/redux/RootReducer'
import {KTSVG, toAbsoluteUrl} from '../../_metronic/helpers'
import '../masters/basic.css'
import {IUserModel} from '../modules/auth/models/UserModel'

export function TransactionTable(props: any) {
  const user: IUserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as IUserModel
  const access = user.permissions.transactions[1] || user.permissions.admin

  const [sort1, setSort1] = useState('rotate-90 ms-2')
  const [sort2, setSort2] = useState('rotate-90 ms-2')
  const [sort3, setSort3] = useState('rotate-90 ms-2')
  const [sort4, setSort4] = useState('rotate-90 ms-2')
  const [sort5, setSort5] = useState('rotate-90 ms-2')
  const [sort6, setSort6] = useState('rotate-90 ms-2')
  const [asc, setAsc] = useState('-basicInformation.date')
  const [status, setStatus] = useState(true)
  const [show, setShow] = useState(false)
  const [billShow, setBillShow] = useState(false)
  const [brandList, setBrandList] = useState([])
  const [brandDetails, setBrandDetails] = useState(null)
  const history: any = useHistory()

  const handleClose = () => setShow(false)
  // const Data = useMemo(() => MockData, [])
  const [transactionListParams, setTransactionListParams] = useState({
    limit: 10,
    page: 1,
    total: 0,
    search: '',
    status: '',
    transactions: [],
  })
  const [deleteId, setDeleteId] = useState('')
  const [transactionId, setTransactionId] = useState('')
  const [transactionData, setTransactionData] = useState<any>({})
  const [priceMaster, setPriceMaster] = useState<any>({})
  const [priceMatrix, setPriceMatrix] = useState<any>({})
  const [CGST, setCGST] = useState<number>(0)
  const [SGST, setSGST] = useState<number>(0)
  const [subTotal, setSubTotal] = useState<number>(0)
  const [total, setTotal] = useState<number>(0)
  const [discount, setDiscount] = useState(0)
  useEffect(() => {
    httpService.getData(
      '/price-master',
      (response: any) => {
        let priceMaster: any = response.data.data.priceMasters
        setPriceMatrix(priceMaster)
        const priceObject: any = {}
        priceMaster.forEach((x: any) => {
          priceObject[x._id] = x
          x.qty = 0
        })
        setPriceMaster(priceObject)
      },
      (error: any) => {
        message.error(error.message)
      }
    )
    httpService.getData(
      '/brand-master?limit=0',
      (response: any) => {
        let agencyResponse = response.data.data
        setBrandList(agencyResponse.brands)
      },
      (error: any) => {
        message.error(error.message)
        setStatus(false)
      }
    )
  }, [])
  const editTransactionById = (transactionId: string, method: string) => {
    httpService.getData(
      '/transaction/' + transactionId,
      (response: any) => {
        var transactionDetails = response.data.data.transaction
        let pricingData = [...priceMatrix]
        pricingData.forEach((priceObject: any) => {
          priceObject.qty =
            transactionDetails.amountDetails.find(
              (x: any) => x.influencerCategory === priceObject._id
            )?.qty || 0
        })
        transactionDetails.customAmountDetails.forEach((priceObject: any) => {
          pricingData.push({
            bucketName: priceObject.service,
            costPerInfluencer: priceObject.amount,
            qty: priceObject.qty,
            type: 'custom',
          })
        })

        let subtotalValue = 0
        ;[...transactionDetails.amountDetails, ...transactionDetails.customAmountDetails].forEach(
          (number: any) => {
            if (number?.amount)
              subtotalValue +=
                parseInt(number.qty) *
                (number?.amount
                  ? number.amount
                  : parseInt(priceMaster[number.influencerCategory].costPerInfluencer))
          }
        )
        setSubTotal(subtotalValue)
        // method = (method === "Cash") ? "Cash Payments" : method;
        let transactionPaymentDetails: any = {}
        if (method === 'cheque') {
          transactionPaymentDetails = transactionDetails?.chequeDetails
          transactionPaymentDetails.total = transactionPaymentDetails.amount
          transactionPaymentDetails.paymentMethod = method
        } else if (method === 'bankTransfer') {
          transactionPaymentDetails = transactionDetails?.bankTransferDetails
          transactionPaymentDetails.total = transactionPaymentDetails.amount
          transactionPaymentDetails.paymentMethod = method
          transactionPaymentDetails.ifscCode = transactionPaymentDetails.referenceNumber
        } else if (method === 'cash') {
          transactionPaymentDetails = transactionDetails?.cashDetails
          transactionPaymentDetails.total = transactionPaymentDetails.amount
          transactionPaymentDetails.paymentMethod = 'cash'
          transactionPaymentDetails.ifscCode = transactionPaymentDetails.memoNumber
          transactionPaymentDetails.dateOfIssue = transactionPaymentDetails.date
          transactionPaymentDetails.bankName = transactionPaymentDetails.receiverName
        }
        history.push({
          pathname: '/transaction/add',
          state: {
            type: 'transactionAdd',
            transactionInfoDetails: transactionDetails.basicInformation,
            transactionAmountDetails: {
              priceMaster: pricingData,
              sgst: subtotalValue * 0.09,
              cgst: subtotalValue * 0.09,
              total: subtotalValue + subtotalValue * 0.09 * 2,
            },
            transactionPaymentDetails: transactionPaymentDetails,
            _id: transactionId,
          },
        })
      },
      (error: any) => {}
    )
  }

  const getTransactionById = (transactionId: string, flag: boolean) => {
    // setBillShow(flag)
    httpService.getData(
      '/transaction/' + transactionId,
      (response: any) => {
        const transactionDetails = JSON.parse(JSON.stringify(response.data.data.transaction))

        if (transactionDetails.basicInformation.agency) {
          httpService.getData(
            '/agency-master/' + transactionDetails.basicInformation.agency,
            (brandResponse: any) => {
              setBrandDetails(brandResponse.data.data.agency.companyDetails)
            },
            (error: any) => {
              message.error(error.message)
            }
          )
        } else {
          httpService.getData(
            '/brand-master/' + transactionDetails.basicInformation.brand,
            (brandResponse: any) => {
              setBrandDetails(brandResponse.data.data.brand.companyDetails)
            },
            (error: any) => {
              message.error(error.message)
            }
          )
        }
        let totalValue = 0
        transactionDetails.amountDetails.forEach((x) => (totalValue += x.amount))
        transactionDetails.customAmountDetails.forEach((x) => (totalValue += x.amount))
        setTransactionData(transactionDetails)
        setSubTotal(totalValue)
        setCGST(totalValue * 0.09)
        setSGST(totalValue * 0.09)
        setDiscount(transactionDetails.discount || 0)
        setTotal(totalValue + totalValue * 0.09 * 2 - (transactionDetails.discount || 0))
        setBillShow(flag)
      },
      (error: any) => {}
    )
  }

  const key = 'updatable'
  const handleDelete = (deleteId: string) => {
    setShow(false)
    message.loading({content: 'Loading...', key})
    httpService.deleteData(
      '/transaction/' + deleteId,
      (res: any) => {
        setTimeout(() => {
          message.success({content: 'Transaction deleted!', key, duration: 2})
          setDeleteId('')
        }, 1000)
      },
      (err: any) => {
        message.success({content: 'Transaction not deleted!', key, duration: 2})
        setDeleteId('')
      }
    )
  }
  const Sorting1 = () => {
    if (sort1 == 'rotate-90 ms-2') {
      setSort1('rotate-270 ms-2')
    } else {
      setSort1('rotate-90 ms-2')
    }
    if (sort1 == 'rotate-90 ms-2') {
      setSort2('rotate-90 ms-2')
      setSort3('rotate-90 ms-2')
      setSort4('rotate-90 ms-2')
      setSort5('rotate-90 ms-2')
      setSort6('rotate-90 ms-2')
      setAsc('-basicInformation.date')
    } else {
      setSort2('rotate-90 ms-2')
      setSort3('rotate-90 ms-2')
      setSort4('rotate-90 ms-2')
      setSort5('rotate-90 ms-2')
      setSort6('rotate-90 ms-2')
      setAsc('basicInformation.date')
    }
  }
  const Sorting2 = () => {
    if (sort2 == 'rotate-90 ms-2') {
      setSort2('rotate-270 ms-2')
    } else {
      setSort2('rotate-90 ms-2')
    }
    if (sort2 == 'rotate-90 ms-2') {
      setSort1('rotate-90 ms-2')
      setSort3('rotate-90 ms-2')
      setSort4('rotate-90 ms-2')
      setSort5('rotate-90 ms-2')
      setSort6('rotate-90 ms-2')
      setAsc('-basicInformation.referenceNumber')
    } else {
      setSort1('rotate-90 ms-2')
      setSort3('rotate-90 ms-2')
      setSort4('rotate-90 ms-2')
      setSort5('rotate-90 ms-2')
      setSort6('rotate-90 ms-2')
      setAsc('basicInformation.referenceNumber')
    }
  }
  
  // const Sorting3=()=>{
  //   if (sort3 == 'rotate-90 ms-2') {
  //     setSort3('rotate-270 ms-2')
  //   } else {
  //     setSort3('rotate-90 ms-2')
  //   }
  //   if (sort3 == 'rotate-90 ms-2') {
  //     setSort2('rotate-90 ms-2')
  //     setSort1('rotate-90 ms-2')
  //     setSort4('rotate-90 ms-2')
  //     setSort5('rotate-90 ms-2')
  //     setSort6('rotate-90 ms-2')
  //     setAsc('-basicInformation.brand')
  //   } else {
  //     setSort2('rotate-90 ms-2')
  //     setSort1('rotate-90 ms-2')
  //     setSort4('rotate-90 ms-2')
  //     setSort5('rotate-90 ms-2')
  //     setSort6('rotate-90 ms-2')
  //     setAsc('basicInformation.brand')
  //   }
  // }
  const Sorting4 = () => {
    if (sort4 == 'rotate-90 ms-2') {
      setSort4('rotate-270 ms-2')
    } else {
      setSort4('rotate-90 ms-2')
    }
    if (sort4 == 'rotate-90 ms-2') {
      setSort2('rotate-90 ms-2')
      setSort3('rotate-90 ms-2')
      setSort1('rotate-90 ms-2')
      setSort5('rotate-90 ms-2')
      setSort6('rotate-90 ms-2')
      setAsc('-method')
    } else {
      setSort2('rotate-90 ms-2')
      setSort3('rotate-90 ms-2')
      setSort1('rotate-90 ms-2')
      setSort5('rotate-90 ms-2')
      setSort6('rotate-90 ms-2')
      setAsc('method')
    }
  }
  // const Sorting5=()=>{
  //   if (sort5 == 'rotate-90 ms-2') {
  //     setSort5('rotate-270 ms-2')
  //   } else {
  //     setSort5('rotate-90 ms-2')
  //   }
  //   if (sort1 == 'rotate-90 ms-2') {
  //     setSort2('rotate-90 ms-2')
  //     setSort3('rotate-90 ms-2')
  //     setSort4('rotate-90 ms-2')
  //     setSort1('rotate-90 ms-2')
  //     setSort6('rotate-90 ms-2')
  //     setAsc('basicInformation.date')
  //   } else {
  //     setSort2('rotate-90 ms-2')
  //     setSort3('rotate-90 ms-2')
  //     setSort4('rotate-90 ms-2')
  //     setSort1('rotate-90 ms-2')
  //     setSort6('rotate-90 ms-2')
  //     setAsc('-basicInformation.date')
  //   }
  // }
  const Sorting6 = () => {
    if (sort6 == 'rotate-90 ms-2') {
      setSort6('rotate-270 ms-2')
    } else {
      setSort6('rotate-90 ms-2')
    }
    if (sort6 == 'rotate-90 ms-2') {
      setSort2('rotate-90 ms-2')
      setSort3('rotate-90 ms-2')
      setSort4('rotate-90 ms-2')
      setSort5('rotate-90 ms-2')
      setSort1('rotate-90 ms-2')
      setAsc('-status')
    } else {
      setSort2('rotate-90 ms-2')
      setSort3('rotate-90 ms-2')
      setSort4('rotate-90 ms-2')
      setSort5('rotate-90 ms-2')
      setSort1('rotate-90 ms-2')
      setAsc('status')
    }
  }
  // useEffect(() => {

  // }, [sort1])
  // useEffect(() => {
  //   if(sort2=='rotate-90 ms-2'){
  //     setAsc('basicInformation.referenceNumber')
  //   }else{

  //     setAsc('-basicInformation.referenceNumber')
  //   }
  // },[sort2])
  // useEffect(() => {
  //   if (sort3 == 'rotate-90 ms-2') {
  //     setAsc('basicInformation.brand')
  //   } else {

  //     setAsc('-basicInformation.brand')
  //   }
  // }, [sort3])
  // useEffect(() => {
  //   if(sort4=='rotate-90 ms-2'){
  //     setAsc('method')
  //   }else{

  //     setAsc('-method')
  //   }
  // },[sort4])
  // // useEffect(() => {
  // //   if (sort5 == 'rotate-90 ms-2') {
  // //     setAsc('amount')
  // //   } else {

  // //     setAsc('-amount')
  // //   }
  // // }, [sort5])
  // useEffect(() => {
  //   if(sort6=='rotate-90 ms-2'){
  //     setAsc('status')
  //   }else{

  //     setAsc('-status')
  //   }
  // },[sort6])
  useEffect(() => {
    getTransactions()
  }, [deleteId, transactionListParams?.page, asc])

  const getTransactions = () => {
    httpService.getData(
      '/transaction?limit=' +
        transactionListParams?.limit +
        '&&page=' +
        transactionListParams?.page +
        '&&search=' +
        transactionListParams?.search +
        '&sort=' +
        asc,
      (response: any) => {
        let transactionResponse = response.data.data
        // setTransactionListParams(transactionResponse?.transactions)
        setTransactionListParams({
          limit: transactionResponse?.limit,
          page: transactionResponse?.page,
          total: transactionResponse?.total,
          search: transactionListParams?.search,
          status: transactionListParams?.status,
          transactions: transactionResponse?.transactions,
        })
        setStatus(false)
      },
      (error: any) => {
        message.error(error.message)
        setStatus(false)
      }
    )
  }

  const highlightActive = (active: boolean) => {
    return active
      ? 'nav-link d-flex w-auto h-40px flex text-white fw-bolder bg-purple active'
      : 'nav-link d-flex w-auto h-40px flex text-gray-400 fw-bold'
  }
  return (
    <>
      <div className='TableHeader'>
        <div className='mt-3 min-w-150px'>
          <h3 style={{cursor: 'default'}}>Transactions</h3>
          <p style={{cursor: 'default'}} className='text-gray-500 fs-7'>
            {transactionListParams.total} Transactions
          </p>
        </div>
        <div className='flex justify-content-start w-100'>
          <ul className='nav nav-pills'>
            <li className='nav-item '>
              <a
                className={highlightActive(
                  !['completed', 'pending', 'onHold', 'cancelled'].includes(
                    transactionListParams.search
                  )
                )}
                onClick={(e) => {
                  transactionListParams.search = ''
                  setTransactionListParams({...transactionListParams})
                  getTransactions()
                }}
              >
                All
              </a>
            </li>
            <li className='nav-item '>
              <a
                className={highlightActive(transactionListParams.search === 'completed')}
                onClick={(e) => {
                  transactionListParams.search = 'completed'
                  setTransactionListParams({...transactionListParams})
                  getTransactions()
                }}
              >
                Completed
              </a>
            </li>
            <li className='nav-item '>
              <a
                className={highlightActive(transactionListParams.search === 'pending')}
                onClick={(e) => {
                  transactionListParams.search = 'pending'
                  setTransactionListParams({...transactionListParams})
                  getTransactions()
                }}
              >
                Pending
              </a>
            </li>
            <li className='nav-item '>
              <a
                className={highlightActive(transactionListParams.search === 'onHold')}
                onClick={(e) => {
                  transactionListParams.search = 'onHold'
                  setTransactionListParams({...transactionListParams})
                  getTransactions()
                }}
              >
                On Hold
              </a>
            </li>
            <li className='nav-item '>
              <a
                className={highlightActive(transactionListParams.search === 'cancelled')}
                onClick={(e) => {
                  transactionListParams.search = 'cancelled'
                  setTransactionListParams({...transactionListParams})
                  getTransactions()
                }}
              >
                Cancelled
              </a>
            </li>
          </ul>
        </div>
        <div className='d-flex align-items-center justify-content-end'>
          <link
            href='https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css'
            rel='stylesheet'
          />
          <Input
            placeholder='Search'
            style={{width: '200px', height: '37px', borderRadius: '6px'}}
            className='form-control-solid border'
            onChange={(e: any) => {
              transactionListParams.search = e.target.value
              setTransactionListParams({...transactionListParams})
              getTransactions()
            }}
            prefix={
              <>
                <SearchOutlined />
              </>
            }
          />
        </div>
        {access && (
          <div
            className='d-flex align-items-center justify-content-end'
            style={{minWidth: '168px', marginLeft: '8px'}}
          >
            <Link
              to={{
                pathname: '/transaction/add',
                state: {type: 'transactionAdd'},
              }}
            >
              <button className='btn create-btn d-flex flex w-100 fs-7' style={{height: '37px'}}>
                Add Transaction 💸
              </button>
            </Link>
          </div>
        )}
      </div>
      <div className='card-body mt-0 p-0 d-flex flex-column justify-content-between'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder text-muted'>
                <th className='bg-primary bg-opacity-5  w-15px'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    {/* <input
                      className='form-check-input'
                      type='checkbox'
                      value='1'
                      data-kt-check='true'
                      data-kt-check-target='.widget-9-check'
                    /> */}
                  </div>
                </th>
                <th className='bg-primary bg-opacity-5 min-w-190px '>
                  <span className='d-flex'>
                    Date
                    <a
                      className={sort1}
                      onClick={() => {
                        Sorting1()
                      }}
                    >
                      <KTSVG
                        className='svg-icon svg-icon-sm svg-icon-gray-700 flex h-20px pointer'
                        path={toAbsoluteUrl('/media/icons/Custom/SideArrow.svg')}
                      />
                    </a>
                  </span>
                </th>
                <th className='bg-primary bg-opacity-5 min-w-190px '>
                  <span className='d-flex'>
                    Reference No.
                    <a
                      className={sort2}
                      onClick={() => {
                        Sorting2()
                      }}
                    >
                      <KTSVG
                        className='svg-icon svg-icon-sm svg-icon-gray-700 flex h-20px pointer'
                        path={toAbsoluteUrl('/media/icons/Custom/SideArrow.svg')}
                      />
                    </a>
                  </span>
                </th>
                {user.userType === 'brand' && (
                  <th className='bg-primary bg-opacity-5 min-w-120px'>
                    <span className='d-flex'>
                      Brand
                      {/* <a
                      className={sort3}
                      onClick={() => {                        
                        Sorting3()
                      }}
                    >
                      <KTSVG
                        className='svg-icon svg-icon-sm svg-icon-gray-700 flex h-20px pointer'
                        path={toAbsoluteUrl('/media/icons/Custom/SideArrow.svg')}
                      />
                    </a> */}
                    </span>
                  </th>
                )}
                <th className='bg-primary bg-opacity-5 min-w-110px'>
                  <span className='d-flex'>
                    Mode
                    <a
                      className={sort4}
                      onClick={() => {
                        Sorting4()
                      }}
                    >
                      <KTSVG
                        className='svg-icon svg-icon-sm svg-icon-gray-700 flex h-20px pointer'
                        path={toAbsoluteUrl('/media/icons/Custom/SideArrow.svg')}
                      />
                    </a>
                  </span>
                </th>
                <th className='bg-primary bg-opacity-5 min-w-150px'>
                  <span className='d-flex'>
                    Amount
                    {/* <a
                      className={sort5}
                      onClick={() => {
                        Sorting5()
                      }}
                    >
                      <KTSVG
                        className='svg-icon svg-icon-sm svg-icon-gray-700 flex h-20px pointer'
                        path={toAbsoluteUrl('/media/icons/Custom/SideArrow.svg')}
                      />
                    </a> */}
                  </span>
                </th>
                <th className='bg-primary bg-opacity-5 min-w-150px'>
                  <span className='d-flex'>
                    Status
                    <a
                      className={sort6}
                      onClick={() => {
                        Sorting6()
                      }}
                    >
                      <KTSVG
                        className='svg-icon svg-icon-sm svg-icon-gray-700 flex h-20px pointer'
                        path={toAbsoluteUrl('/media/icons/Custom/SideArrow.svg')}
                      />
                    </a>
                  </span>
                </th>
                <th className='bg-primary bg-opacity-5 min-w-100px d-flex justify-content-end'>
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {transactionListParams.transactions.length > 0 &&
                transactionListParams.transactions.map((transaction: any, index: number) => (
                  <tr key={index}>
                    <td>
                      <div className='form-check form-check-sm form-check-custom form-check-solid'>
                        {/* <input className='form-check-input widget-9-check' type='checkbox' value='1' /> */}
                      </div>
                    </td>
                    <td className='fw-bolder'>
                      {Moment(transaction.basicInformation.date).format('DD MMM YYYY')}
                    </td>
                    <td className='fw-bolder'>{transaction.basicInformation.referenceNumber}</td>
                    {user.userType === 'brand' && (
                      <td className='fw-bolder'>
                        <div className='d-flex align-items-center'>
                          <div className='symbol symbol-35px symbol-circle me-5'>
                            {/* {data?.agencyDetails &&<img src={data?.agencyDetails.avatar} alt='' />} */}
                            <img
                              src={transaction.basicInformation.brand.brandDetails.avatar}
                              alt=''
                            />
                          </div>
                          <div className='d-flex justify-content-start flex-column'>
                            <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
                              {transaction.basicInformation.brand.brandDetails.brandName}
                            </a>
                          </div>
                        </div>
                      </td>
                    )}
                    <td className='fw-bold'>
                      {transaction?.method === 'notMentioned'
                        ? 'NA'
                        : transaction?.method.replace(/(\w)(\w*)/g, function (g0, g1, g2) {
                            return g1.toUpperCase() + g2.toLowerCase()
                          })}
                    </td>

                    <td className='fw-bold'>
                      Rs.{' '}
                      {transaction?.cashDetails?.amount ||
                        transaction?.chequeDetails?.amount ||
                        transaction?.bankTransferDetails?.amount ||
                        transaction?.amount}
                    </td>
                    <td className='fw-bold'>
                      <span
                        className={
                          transaction.status === 'Completed'
                            ? 'text-success bg-opacity-20 fs-7 rounded p-2 pt-1 pb-1 bg-success'
                            : 'text-warning bg-opacity-20 fs-7 rounded p-2 pt-1 pb-1 bg-warning'
                        }
                      >
                        {transaction.status.replace(/(\w)(\w*)/g, function (g0, g1, g2) {
                          return g1.toUpperCase() + g2.toLowerCase()
                        })}
                      </span>
                    </td>
                    <td className='d-flex justify-content-end'>
                      <div className='d-flex justify-content-start flex-shrink-0'>
                        <span
                          className='svg-icon flex svg-icon-dark bg-primary bg-opacity-5 w-30px h-30px rounded me-2 pointer'
                          onClick={() => {
                            getTransactionById(transaction._id, true)
                          }}
                          // onClick={() => editBrandOrAgency(data?._id)}
                        >
                          <KTSVG
                            className='svg-icon-3'
                            path={toAbsoluteUrl('/media/icons/Custom/eye.svg')}
                          />
                        </span>
                        {/* <span
                          className='svg-icon flex svg-icon-dark bg-primary bg-opacity-5 w-30px h-30px rounded me-2 pointer'
                          onClick={() => editTransactionById(transaction?._id, transaction?.method)}
                        >
                          <KTSVG
                            className='svg-icon-3'
                            path={toAbsoluteUrl('/media/icons/Custom/Export.svg')}
                          />
                        </span> */}
                        {/* <span
                          className='svg-icon flex svg-icon-dark bg-primary bg-opacity-5 w-30px h-30px rounded me-2 pointer'
                          onClick={() => editTransactionById(transaction?._id, transaction?.method)}
                        >
                          <KTSVG
                            className='svg-icon-3'
                            path={toAbsoluteUrl('/media/icons/Custom/Pen.svg')}
                          />
                        </span> */}
                        {access && (
                          <span
                            onClick={() => {
                              setShow(true)
                              setDeleteId(transaction?._id)
                            }}
                            className='svg-icon flex svg-icon-danger bg-danger bg-opacity-5 w-30px h-30px rounded pointer'
                          >
                            <KTSVG
                              className='svg-icon-3'
                              path={toAbsoluteUrl('/media/icons/Custom/Dustbin.svg')}
                            />
                          </span>
                        )}
                        <Modal
                          show={show}
                          onHide={handleClose}
                          aria-labelledby='contained-modal-title-vcenter'
                          centered
                        >
                          <Modal.Header>
                            <Modal.Title>Delete</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>Are you sure you wanna delete?</Modal.Body>
                          <Modal.Footer>
                            <button
                              className='btn fw-bolder btn-gray-200 fs-6 dialogButton'
                              onClick={() => {
                                setShow(false)
                                setDeleteId('')
                              }}
                            >
                              Cancel
                            </button>
                            <button
                              className='btn create-btn fs-6 dialogButton ms-2'
                              onClick={() => handleDelete(deleteId)}
                            >
                              Delete
                            </button>
                          </Modal.Footer>
                        </Modal>
                      </div>
                    </td>
                  </tr>
                ))}
              {transactionListParams.transactions.length === 0 && !status && (
                <tr>
                  <td colSpan={7}>
                    <div className='w-100 m-20'>
                      <div className='flex'>
                        <img src={toAbsoluteUrl('/media/logos/Finance.png')}></img>
                      </div>
                      <div className='flex'>
                        <div>
                          <h2 className='mb-0 m24 flex'>No Transactions Listed</h2>
                        </div>
                      </div>
                      <div className='flex'>
                        <Link
                          to={{
                            pathname: '/companyDetails',
                            //   state: {type: pageType},
                          }}
                        >
                          <button className='btn m24 TableButton btn-pink text-white'>
                            Create a New Transaction 💸
                          </button>
                        </Link>
                      </div>
                    </div>
                  </td>
                </tr>
              )}
              {transactionListParams.transactions.length === 0 && !status && (
                <tr>
                  <td colSpan={7}>
                    <div className='w-100 m-20'>
                      <div className='flex'>
                        <span className='svg-icon svg-icon-5x flex svg-icon-primary me-3 ms-8'>
                          <KTSVG path={toAbsoluteUrl('/media/icons/Custom/Loading.svg')} />
                        </span>
                      </div>
                      <div className='flex'>
                        <div>
                          <h5 className='mb-0 mt-3 flex'>Fetching Data</h5>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>

            {/* end::Table body */}
          </table>

          {/* end::Table */}
        </div>
        <div className='d-flex ms-8 me-10 justify-content-between align-items-end flex-wrap mb-10'>
          <span className='text-muted pb-2'>
            Page {transactionListParams?.page} of{' '}
            {Math.floor(transactionListParams?.total % transactionListParams?.limit) === 0
              ? Math.floor(transactionListParams?.total / transactionListParams?.limit)
              : Math.floor(transactionListParams?.total / transactionListParams?.limit) + 1}
          </span>
          <div className='d-flex flex-wrap '>
            <Pagination
              className=''
              pageSize={transactionListParams?.limit}
              defaultCurrent={transactionListParams?.page}
              total={transactionListParams?.total}
              onChange={(e) => {
                setTransactionListParams({...transactionListParams, ...{page: e}})
              }}
            />
          </div>
        </div>
        {/* end::Table container */}
      </div>
      <Modal
        show={billShow}
        data-backdrop='true'
        onHide={() => setBillShow(false)}
        aria-labelledby='contained-modal-title-vcenter '
        centered
      >
        <Modal.Body className='p-0 '>
          <div style={{width: '600px'}} className='bg-white rounded'>
            <div className=' w-100 bg-white rounded p-8'>
              <div className='d-flex'>
                <div className='col flex justify-content-start'>
                  <img
                    className='h-40px'
                    src={toAbsoluteUrl('/media/logos/logo__name__color.png')}
                  ></img>
                </div>
                <h3 className='col flex justify-content-end m-0'>Invoice</h3>
              </div>
              <div className='col d-flex m16'>
                <div className='w-400px border rounded p-3 pt-2 me-5'>
                  <p className='m-0 text-gray-400 fw-bold fs-9'>Invoice to</p>
                  <h6 className='m-0 mt-2'>{brandDetails?.companyName}</h6>
                  <p className='m-0 fs-9 mt-1'>
                    {brandDetails?.billingAddress && (brandDetails?.billingAddress + ',')} &nbsp;
                    <br />
                    {brandDetails?.city != '' && (brandDetails?.city + ',')} &nbsp;
                    {brandDetails?.state != '' && (brandDetails?.state + ',')} &nbsp;
                    {brandDetails?.pinCode && brandDetails?.pinCode}
                    {/* Brigade Gateway, 8th floor, 26/1, Dr. Rajkumar Road, Malleshwaram(W),
                    Bangalore-560055, Karnataka, India. */}
                  </p>
                </div>
                <div className='w-150px border rounded p-3 pt-2 '>
                  <p className='m-0 text-gray-400 fs-9'>Invoice No:</p>
                  <p className='m-0'>{transactionData?.basicInformation?.referenceNumber}</p>
                  <p className='m-0 mt-2 text-gray-400 fs-9'>Date:</p>
                  <p className='m-0'>{Moment(transactionData?.createdAt).format('DD MMMM YYYY')}</p>
                </div>
              </div>
              <div className='m16 border rounded'>
                <div className='p-3 rounded'>
                  <div className='d-flex'>
                    <div className='col flex fw-bold justify-content-start'>Type</div>
                    <div className='col flex fw-bold justify-content-end me-5'>
                      Influencer Count
                    </div>
                    <span className='text-gray-400 flex'>x</span>
                    <div className='col flex fw-bold justify-content-start ms-5'>Barter Value</div>
                    <div className='col flex fw-bold justify-content-end'>Amount</div>
                  </div>
                  <span className='separator border-gray-200 m12'></span>
                  {transactionData?.amountDetails?.map((amount: any, index: number) => (
                    <div className='col m12 d-flex' key={index}>
                      <div className='col flex fw-bold justify-content-start'>
                        {priceMaster[amount.influencerCategory]?.bucketName}
                      </div>
                      <div className='col flex fw-bold justify-content-end me-5'>
                        {amount.qty ? amount.qty : 0}
                      </div>
                      <span className='text-gray-400 flex'>x</span>
                      <div className='col flex fw-bold justify-content-start ms-5'>
                        {priceMaster[amount.influencerCategory]?.costPerInfluencer}
                      </div>

                      <div className='col flex fw-bold justify-content-end'>
                        <span placeholder='200'>
                          Rs.{' '}
                          {priceMaster[amount.influencerCategory]?.costPerInfluencer * amount.qty}
                        </span>
                      </div>
                    </div>
                  ))}
                  {transactionData?.customAmountDetails?.map((amount: any, index: number) => (
                    <div className='col m12 d-flex' key={index}>
                      <div className='col flex fw-bold justify-content-start'>{amount.service}</div>
                      <div className='col flex fw-bold justify-content-end me-5'>{amount.qty}</div>
                      <span className='text-gray-400 flex'>x</span>
                      <div className='col flex fw-bold justify-content-start ms-5'>
                        {amount.amount}
                      </div>

                      <div className='col flex fw-bold justify-content-end'>
                        <span placeholder='200'>Rs. {amount.amount * amount.qty}</span>
                      </div>
                    </div>
                  ))}
                  <span className='separator border-gray-200 m12'></span>
                  <div className='col m12 d-flex'>
                    <div className='col flex fw-bold justify-content-start'>Sub Total</div>
                    <div className='col flex fw-bold justify-content-end me-5'>
                      {transactionData?.amountDetails?.reduce((total: any, number: any) => {
                        return total + parseInt(number.qty)
                      }, 0)}
                    </div>
                    <span className='text-gray-400 flex'></span>
                    <div className='col fw-bolder fs-6 flex justify-content-start ms-5'></div>

                    <div className='col fw-bolder fs-6 flex justify-content-end'>
                      <span placeholder='200'>Rs. {subTotal}</span>
                    </div>
                  </div>
                  <span className='separator border-gray-200 m12'></span>
                  <h6 className='m12 fs-7'>Taxes</h6>
                  <div className='m12 d-flex'>
                    <div className='col fs-7 flex justify-content-start '>CGST(9%)</div>
                    <div className='col fs-7 flex justify-content-end'>Rs. {CGST}</div>
                  </div>
                  <div className='m12 d-flex'>
                    <div className='col fs-7 flex justify-content-start'>SGST(9%)</div>
                    <div className='col fs-7 flex justify-content-end'>Rs. {SGST}</div>
                  </div>
                  <div className='m12 d-flex'>
                    <div className='col fs-7 flex justify-content-start'>Discount</div>
                    <div className='col fs-7 flex justify-content-end'>Rs. {discount}</div>
                  </div>
                </div>
                <div className='m12 h-40px d-flex'>
                  <div className='col-8 fw-bolder border p-3 fs-6 bg-gray-100 flex justify-content-start'>
                    Grand Total
                  </div>
                  <div className='col-4 fw-bolder p-3 fs-3 text-white bg-pink flex justify-content-end'>
                    Rs. {total}
                  </div>
                </div>
                <div className='m12 p-3 pt-0  d-flex'>
                  <div className='col fs-7 flex justify-content-start '>Status</div>
                  <div className='col fs-7 flex text-danger fw-bolder justify-content-end'>
                    {/* NOT PAID */}
                    {transactionData?.status?.toUpperCase()}
                  </div>
                </div>
              </div>
              <div className='m16'>
                {transactionData.method === 'cash' && (
                  <h6 className='fs-7 text-gray-400'>Cash Detail:</h6>
                )}
                {transactionData.method === 'cheque' && (
                  <h6 className='fs-7 text-gray-400'>Cheque Detail:</h6>
                )}
                {transactionData.method === 'bankTransfer' && (
                  <h6 className='fs-7 text-gray-400'>Bank Detail:</h6>
                )}
                <div className='col d-flex'>
                  {transactionData.method === 'cash' && (
                    <div className='w-400px border rounded p-3 pt-2 me-5'>
                      <div className='d-flex'>
                        <div className='col-8'>
                          <p className='m-0 text-gray-400 fw-bold fs-9'>Memo Number:</p>
                          <p className='m-0 fs-7'>{transactionData.cashDetails.memoNumber}</p>
                        </div>
                        <div className='col-4'>
                          <p className='m-0 text-gray-400 fw-bold fs-9'>Receiver Name:</p>
                          <p className='m-0 fs-7'>{transactionData.cashDetails.receiverName}</p>
                        </div>
                      </div>
                      <div className='d-flex mt-2'>
                        <div className='col-4'>
                          <p className='m-0 text-gray-400 fw-bold fs-9'>Issue Date:</p>
                          <p className='m-0 fs-7'>
                            {Moment(transactionData?.cashDetails.date).format('DD MMMM YYYY')}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                  {transactionData.method === 'cheque' && (
                    <div className='w-400px border rounded p-3 pt-2 me-5'>
                      <div className='d-flex'>
                        <div className='col-8'>
                          <p className='m-0 text-gray-400 fw-bold fs-9'>Cheque Number: </p>
                          <p className='m-0 fs-7'>{transactionData.chequeDetails.chequeNumber}</p>
                        </div>
                        <div className='col-4'>
                          <p className='m-0 text-gray-400 fw-bold fs-9'>IFSC Code:</p>
                          <p className='m-0 fs-7'>{transactionData.chequeDetails.ifscCode}</p>
                        </div>
                      </div>
                      <div className='d-flex mt-2'>
                        <div className='col-8'>
                          <p className='m-0 text-gray-400 fw-bold fs-9'>Bank Name & Branch:</p>
                          <p className='m-0 fs-7'>
                            {transactionData.chequeDetails.bankName},
                            {transactionData.chequeDetails.branch}
                          </p>
                        </div>
                        <div className='col-8'>
                          <p className='m-0 text-gray-400 fw-bold fs-9'>Issue Date:</p>
                          <p className='m-0 fs-7'>
                            {Moment(transactionData?.chequeDetails.date).format('DD MMMM YYYY')}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                  {transactionData.method === 'bankTransfer' && (
                    <div className='w-400px border rounded p-3 pt-2 me-5'>
                      <div className='d-flex'>
                        <div className='col-8'>
                          <p className='m-0 text-gray-400 fw-bold fs-9'>Cheque Number: </p>
                          <p className='m-0 fs-7'>
                            {transactionData.bankTransferDetails.chequeNumber}
                          </p>
                        </div>
                        <div className='col-4'>
                          <p className='m-0 text-gray-400 fw-bold fs-9'>Reference Number</p>
                          <p className='m-0 fs-7'>
                            {transactionData.bankTransferDetails.referenceNumber}
                          </p>
                        </div>
                      </div>
                      <div className='d-flex mt-2'>
                        <div className='col-4'>
                          <p className='m-0 text-gray-400 fw-bold fs-9'>Issue Date</p>
                          <p className='m-0 fs-7'>
                            {Moment(transactionData?.bankTransferDetails.date).format(
                              'DD MMMM YYYY'
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className='w-150px border rounded p-3 pt-2 '>
                    <p className='m-0 text-gray-400 fs-9'>GST No:</p>
                    <p className='m-0 fs-7'>27AAICC9817C1ZL</p>
                    <p className='m-0 mt-2 text-gray-400 fs-9'>PAN No:</p>
                    <p className='m-0 fs-7'>AAICC9817C</p>
                  </div>
                </div>
              </div>
              <div className='m16'>
                <p className='m-0 fw-bold fs-9'>
                  Terms & Conditions; Payment should be credited within 30 days from date of
                  invoice.
                </p>
                <p style={{fontWeight: 'bolder'}} className='mt-1 m-0 fs-9'>
                  For Clanify Media Private Limited
                </p>
              </div>
              <div className='m30 d-flex'>
                <h6 className='m-0'>Neha Puri, </h6> <p className='fs-8 ms-1 m-0'> Director</p>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
