import clsx from 'clsx'
import {useFormik} from 'formik'
import {default as React, useEffect, useState} from 'react'
import Select from 'react-select'
import * as Yup from 'yup'
import httpService from '../../setup/http/httpServices'
import {toAbsoluteUrl} from '../../_metronic/helpers'
import {Alerts} from '../modules/alerts/Alerts'
import './basic.css'
import {BrandMasterTable} from './BrandMasterTable'
import {ImageCropper} from './ImageCropper'
import {message} from 'antd'
import {IUserModel} from '../modules/auth/models/UserModel'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../setup/redux/RootReducer'

var initialValues = {
  brandName: '',
  relevantCategory: [],
  brandLogoUrl: 'test.png',
}
const brandSchema = Yup.object().shape({
  brandName: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Brand name is required')
    .matches(/^(?=.*[A-Za-z]).{3,50}$/, 'Should not contain just numbers'),
  relevantCategory: Yup.array().required('Category is required'),
})
export function BrandMaster(props: any) {
  const user: IUserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as IUserModel
  const access = user.permissions.masters[1] || user.permissions.admin

  const [bgImage, setBgImage] = useState(toAbsoluteUrl('/media/logos/categoryIcon.png'))
  const [toggleCrop, setToggleCrop] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isFailure, setIsFailure] = useState(false)
  const [brandId, setBrandId] = useState(null)
  const [responseMessage, setResponseMessage] = useState('')
  const [selectedFile, setSelectedFile] = useState(new File([], ''))
  const [brandCategories, setBrandCategories] = useState<any>([])
  const [categories, setCategories] = useState([])

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: brandSchema,
    enableReinitialize: true,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setIsFailure(false)
      setIsSuccess(false)
      values.brandLogoUrl = values.brandName + '.png'
      let fd = new FormData()
      fd.append('brandName', values.brandName)
      fd.append('brandLogoUrl', selectedFile)
      fd.append(
        'relevantCategory',
        JSON.stringify(brandCategories.map((x: any) => x['_id'] || x['value']))
      )
      if (brandId) {
        httpService.patchData(
          '/brand/' + brandId,
          fd,
          (res: any) => {
            formik.resetForm()
            setResponseMessage('Success! Brand updated.')
            setBrandId(null)
            setIsSuccess(true)
            setBgImage(toAbsoluteUrl('/media/logos/categoryIcon.png'))
            setBrandCategories([])
          },
          (error: any) => {
            setIsFailure(true)
            setResponseMessage(error?.message)
          }
        )
      } else if (selectedFile.size > 0)
        httpService.postData(
          '/brand',
          fd,
          (res: any) => {
            formik.resetForm()
            setResponseMessage('Success! Brand added.')
            setBrandId(null)
            setIsSuccess(true)
            setBgImage(toAbsoluteUrl('/media/logos/categoryIcon.png'))
            setBrandCategories([])
          },
          (error: any) => {
            setIsFailure(true)
            setResponseMessage(error?.message)
          }
        )
      else if (selectedFile.size === 0) {
        formik.setFieldError('brandLogoUrl', 'Please select category icon.')
      }
    },
  })

  function onChange(event: any) {
    let file = event.target.files[0]
    setSelectedFile(file)
    var reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
      setBgImage('' + reader.result + '')
      setToggleCrop(true)
    }
  }
  function resetImage() {
    setSelectedFile(new File([], ''))
    setBgImage(toAbsoluteUrl('/media/logos/categoryIcon.png'))
  }

  const handleBrand = (id: any) => {
    httpService.getData(
      '/brand/' + id,
      (res: any) => {
        formik.setValues(res.data.data.brand)
        let categoriesArr: any = []
        categories.forEach((x: any) => {
          if (JSON.stringify(res.data.data.brand?.relevantCategory).indexOf(x._id) !== -1)
            categoriesArr.push({
              value: x._id,
              label: x.categoryName,
            })
        })
        setBrandCategories(categoriesArr)
        setBrandId(id)
        setBgImage(toAbsoluteUrl(res.data.data.brand.avatar))
      },
      (error: any) => {
        message.error(error.message)
      }
    )
  }
  const getAllCategories = () => {
    httpService.getData(
      '/category-master',
      (response: any) => {
        response.data.data.categories.map((x: any) => {
          x['value'] = x._id
          x['label'] = x.categoryName
          return x
        })
        setCategories(response.data.data.categories)
      },
      (error: any) => {
        message.error(error.message)
      }
    )
  }
  useEffect(() => {
    getAllCategories()
  }, [])

  async function handleCrop(image: any) {
    setBgImage(image)
    const response = await fetch(image, {mode: 'no-cors'})
    const data = await response.blob()
    setSelectedFile(
      new File([data], selectedFile?.name, {
        type: selectedFile.type,
      })
    )
    setToggleCrop(false)
  }

  const handleCancel = () => {
    setBgImage(toAbsoluteUrl('/media/logos/categoryIcon.png'))
    setToggleCrop(false)
  }

  return (
    <div className='d-flex justify-content-center ' style={{overflow: 'hidden'}}>
      <div className='addBrand'>
        <h3 className='mb-0'>Add New Brand</h3>
        <p className='fs-7 text-gray-400'>
          Brands will be visible to influencers for campaign applications.
        </p>
        <Alerts
          visible={isSuccess}
          type='success'
          message={responseMessage}
          description={'Description'}
        ></Alerts>
        <Alerts
          visible={isFailure}
          type='error'
          message={responseMessage}
          description={'Description'}
        ></Alerts>
        <form noValidate id='kt_login_signup_form' onSubmit={formik.handleSubmit}>
          <div>
            <h6 className='fw-bold required m32'>Brand Name</h6>
            <input
              {...formik.getFieldProps('brandName')}
              className={clsx(
                'form-control form-control-solid bg-gray-100',
                {
                  'is-invalid': formik.touched.brandName && formik.errors.brandName,
                },
                {
                  'is-valid': formik.touched.brandName && !formik.errors.brandName,
                }
              )}
            />
            {formik.touched.brandName && formik.errors.brandName && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='text-danger' role='alert'>
                    {formik.errors.brandName}
                  </span>
                </div>
              </div>
            )}
          </div>
          <div>
            <h6 className='fw-bold required m16'>Category</h6>
            <Select
              placeholder='Select one or more categories'
              options={categories}
              className={clsx(
                'form-control form-control-solid bg-gray-100 p-0 border-0 categorySelect',
                {
                  'is-invalid': formik.touched.relevantCategory && formik.errors.relevantCategory,
                },
                {
                  'is-valid': formik.touched.relevantCategory && !formik.errors.relevantCategory,
                }
              )}
              value={brandCategories}
              isMulti={true}
              onChange={(e: any) => setBrandCategories(e)}
            />

            {formik.touched.relevantCategory && formik.errors.relevantCategory && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='text-danger' role='alert'>
                    {formik.errors.relevantCategory}
                  </span>
                </div>
              </div>
            )}
          </div>
          <div>
            <h6 className='fw-bold required m16'>Brand Logo</h6>
            <div data-kt-image-input='true' className='image-input categoryIcon'>
              <div className='image-input-wrapper w-100 h-100 flex bg-gray-100'>
                <img src={bgImage} className='h-100'></img>
              </div>
              <label
                className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow'
                data-kt-image-input-action='change'
                data-bs-toggle='tooltip'
                data-bs-original-title='Change avatar'
              >
                <i className='bi bi-pencil-fill fs-7'></i>
                <input
                  type='file'
                  name='avatar'
                  accept='.png, .jpg, .jpeg'
                  onChange={(event) => onChange(event)}
                />
                <input type='hidden' name='avatar_remove' />
              </label>

              {selectedFile.size > 0 && (
                <span
                  className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow'
                  data-kt-image-input-action='cancel'
                  data-bs-toggle='tooltip'
                  data-bs-dismiss='click'
                  title='Cancel avatar'
                >
                  <i className='bi bi-x fs-2'></i>
                </span>
              )}
              {selectedFile.size > 0 && (
                <span
                  className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow'
                  data-kt-image-input-action='remove'
                  data-bs-toggle='tooltip'
                  data-bs-dismiss='click'
                  title='Remove avatar'
                  onClick={resetImage}
                >
                  <i className='bi bi-x fs-2'></i>
                </span>
              )}
              {formik.errors.brandLogoUrl && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='text-danger' role='alert'>
                      {'Category Icon is required.'}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='m32 w-100 flex'>
            <button
              type='button'
              onClick={() => {
                setBrandId(null)
                setBrandCategories([])
                setSelectedFile(new File([], ''))
                setBgImage(toAbsoluteUrl('/media/logos/categoryIcon.png'))
                formik.resetForm()
              }}
              className='btn fw-bolder btn-gray-200 fs-6 categoryButton'
            >
              Cancel
            </button>
            <button
              type='submit'
              className='btn create-btn fs-6 categoryButton ms-10'
              disabled={!access || brandCategories == ''}
            >
              {brandId ? 'Update' : 'Add Brand'}
            </button>
          </div>
        </form>
      </div>

      <BrandMasterTable
        editBrand={handleBrand}
        updatable={isSuccess || isFailure}
        access={access}
      ></BrandMasterTable>
      {toggleCrop && <ImageCropper cancel={handleCancel} editImage={handleCrop} image={bgImage} />}
    </div>
  )
}
