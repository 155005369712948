/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSSVariableValue} from '../../../_metronic/assets/ts/_utils'

type Props = {
  className: string
  Day:boolean
  Month:boolean
  Year:boolean

}

const StatsAll2: React.FC<Props> = ({className,Day,Month,Year}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
    const [cat,setCat]=useState(['Oct', 'Nov', 'Dec','Jan','Feb', 'Mar']);
    const [dat,setDat]=useState([65, 45, 30, 30, 60, 40]);
  useEffect(() => {
    if (!chartRef.current) {
      return
    }

    const chart1 = new ApexCharts(chartRef.current, chart1Options(dat,cat))
    if (chart1) {
      chart1.render()
    }

    return () => {
      if (chart1) {
        chart1.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef,cat,dat])
  useEffect(() => {
  if(Day){
    setDat([30, 30, 60, 40, 65, 45,50])
  setCat(['Sun','Mon', 'Tue', 'Wed','Thu','Fri', 'Sat',])
}
if(Month){
  setDat([65, 45, 30, 30, 60, 40])
setCat(['Oct', 'Nov', 'Dec','Jan','Feb', 'Mar'])
}
if(Year){
  setDat([20, 15, 30, 30, 60, 40])
setCat(['2017', '2018', '2019','2020','2021', '2022'])
}
}, [Day,Month,Year])
  return (
    <div className={`${className}`}>
      <div className='d-flex flex-column'>
        <div ref={chartRef} id='kt_charts_widget_3_chart' style={{height: '200px'}}></div>     
      </div>
    </div>
  )
}

const chart1Options = (cat:any,dat:any): ApexOptions => {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  

  return {
    series: [
      {
        name: 'Categories',
        data: cat,
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height: 200,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'gradient',
      opacity: 1,
      gradient: {
        type: 'vertical',
        shadeIntensity: 1,
        gradientToColors: undefined,
        inverseColors: true,
        opacityFrom: 0,
        opacityTo: 0,
        stops: [25, 50, 100],
      },
    },
    stroke: {
      curve: 'straight',
      show: true,
      width: 2,
      colors: ['#E72281'],
    },
    xaxis: {
      categories: dat,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        show: false,
        position: 'front',
        stroke: {
          color: labelColor,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
        
      labels: {
        show: true,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return `${val}` 
        },
      },
    },
    colors: ['#FDE9F2'],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      strokeColors: '#E72281',
      strokeWidth: 2,
    },
  }
}

export {StatsAll2}