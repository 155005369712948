import React, {useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../_metronic/helpers'
import {Agencies} from './Components/Agencies'
import {Brands} from './Components/Brands'
import {Influencers} from './Components/Influencers'
import {Transaction} from './Components/Transaction'
import {Chart} from './Charts/Chart'
import {StatsGeneral} from './Charts/StatsGeneral'
import './Dashboard.scss'
import {useHistory} from 'react-router-dom'
import {NoData} from './Components/NoData'
import httpService from '../../setup/http/httpServices'

const chartDataPoints = [
  {label: 'J', value: 45, bgColor: '#EEEBF5'},
  {label: 'F', value: 60, bgColor: '#EEEBF5'},
  {label: 'M', value: 70, bgColor: '#EEEBF5'},
  {label: 'A', value: 40, bgColor: '#EEEBF5'},
  {label: 'M', value: 30, bgColor: '#EEEBF5'},
  {label: 'J', value: 50, bgColor: '#EEEBF5'},
  {label: 'J', value: 60, bgColor: '#EEEBF5'},
  {label: 'A', value: 55, bgColor: '#513A98'},
]

export function DashboardGeneral() {
  const data = true
  const history = useHistory()
  const [dashboardData, setDashboardData] = useState({} as any)

  const getData = () => {
    httpService.getData(
      '/dashboard',
      (res: any) => {
        setDashboardData(res.data.data)
      },
      (err: any) => {
        console.log(err?.message)
      }
    )
  }
  useEffect(() => {
    getData()
  }, [])
  return (
    <div className='Outer d-flex flex-column'>
      {/* <div className='Dash-Navbar border'>
        <button
          onClick={() =>
            history.push({pathname: '/dashboard/general', state: {type: 'Dashboard/general'}})
          }
          className='active'
        >
          General
        </button>
        <button
          onClick={() =>
            history.push({pathname: '/dashboard/agency', state: {type: 'Dashboard/agency'}})
          }
        >
          Agency
        </button>
        <button
          onClick={() =>
            history.push({pathname: '/dashboard/brand', state: {type: 'Dashboard/brands'}})
          }
        >
          Brands
        </button>
        <button
          onClick={() =>
            history.push({pathname: '/dashboard/influencer', state: {type: 'Dashboard/influencer'}})
          }
        >
          Influencer
        </button>
      </div> */}
      <div className='Dgeneral'>
        <div className='flex Dgeneral-top justify-content-between'>
          <div className='Dcard' style={{width: '380px', height: '321px'}}>
            <div className='Dash-left-top'>
              <div className='Dash-left-image svg-icon svg-icon-secondary bg-secondary bg-opacity-5'>
                <KTSVG
                  className='svg-icon-3'
                  path={toAbsoluteUrl('/media/icons/Custom/Campaign.svg')}
                />
              </div>
              <div className='Dash-left-head'>
                <h2>Campaigns</h2>
                <p>This Month</p>
              </div>
            </div>
            {!data ? (
              <NoData height='210px' />
            ) : (
              <>
                <div className='Dash-left-bottom'>
                  <h1 className='DheadingBig'>
                    {dashboardData?.chartData?.campaign.stats.current}
                  </h1>
                </div>
                <div style={{marginTop: '-15px'}}>
                  <StatsGeneral
                    className='w-100'
                    stats={dashboardData?.chartData?.campaign?.monthlyCount}
                  />
                </div>
              </>
            )}
          </div>
          <div className='Dcard Dash-right' style={{width: '320px', height: '321px'}}>
            <div className='Dash-right-top'>
              <div className='Dash-right-image svg-icon svg-icon-secondary bg-secondary bg-opacity-5'>
                <img src={toAbsoluteUrl('/media/logos/time.png')} alt='' />
              </div>
              <div className='Dash-right-head'>
                <h2>Brands</h2>
                <p>Joined this Month</p>
              </div>
            </div>
            {!data ? (
              <NoData height='210px' />
            ) : (
              <>
                <div className='Dash-right-bottom'>
                  <h1 className='DheadingBig'>{dashboardData?.chartData?.brand.stats.current}</h1>
                </div>
                <div style={{marginTop: '8px'}}>
                  <Chart dataPoints={chartDataPoints} />
                </div>
              </>
            )}
          </div>
          <div className='Dcard Dash-right' style={{width: '320px', height: '321px'}}>
            <div className='Dash-right-top'>
              <div className='Dash-right-image svg-icon svg-icon-secondary bg-secondary bg-opacity-5'>
                <KTSVG
                  className='svg-icon-3'
                  path={toAbsoluteUrl('/media/icons/Custom/influencer.svg')}
                />
              </div>
              <div className='Dash-right-head'>
                <h2>Influencers </h2>
                <p>Joined this Month</p>
              </div>
            </div>
            {!data ? (
              <NoData height='210px' />
            ) : (
              <>
                <div className='Dash-right-bottom'>
                  <h1 className='DheadingBig'>
                    {dashboardData?.chartData?.influencer.stats.current}
                  </h1>
                </div>
                <div style={{marginTop: '8px'}}>
                  <Chart dataPoints={chartDataPoints} />
                </div>
              </>
            )}
          </div>
          <div className='Dcard Dash-right' style={{width: '320px', height: '321px'}}>
            <div className='Dash-right-top'>
              <div className='Dash-right-image svg-icon svg-icon-secondary bg-secondary bg-opacity-5'>
                <img src={toAbsoluteUrl('/media/logos/agencies.png')} alt='' />
              </div>
              <div className='Dash-right-head'>
                <h2>Agencies</h2>
                <p>Joined this Month</p>
              </div>
            </div>
            {!data ? (
              <NoData height='210px' />
            ) : (
              <>
                <div className='Dash-right-bottom'>
                  <h1 className='DheadingBig'>{dashboardData?.chartData?.agency.stats.current}</h1>
                </div>
                <div style={{marginTop: '8px'}}>
                  <Chart dataPoints={chartDataPoints} />
                </div>
              </>
            )}
          </div>
        </div>
        <div className='d-flex justify-content-start align-items-start'>
          <Brands data={dashboardData?.topPerformer?.brands} />
          <Influencers data={dashboardData?.topPerformer?.influencers} />
        </div>
        <div className='d-flex justify-content-start align-items-start m20'>
          <Agencies data={dashboardData?.topPerformer?.agencies} />
          <Transaction data={dashboardData?.topPerformer?.transactions} />
        </div>
      </div>
    </div>
  )
}
