import React, { FC } from 'react'

const ProfileAvatar = ({src, className, name}) => {

  // var image_path = null;
  // if (src !== null) {
  //   try {
  //     image_path = require('../../../images/' + src);
  //   } catch (err) {
  //     image_path = null  //set default image path
  //   }
  // }
  if (src !== null) {
    return (
      <label className='campaignLabel w-35px h-35px rounded-circle'>
        <img src={src} className={className} />
      </label>
    );
  }
  else {
    return (
      <span className='symbol btn-primary ml-2 w-35px h-35px imageBadge' style={{ fontSize: 18, borderRadius: '100%' }}>
        {name}
      </span>
    )
  }

}

export { ProfileAvatar };   