import React, {useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSSVariableValue} from '../../../_metronic/assets/ts/_utils'

type Props = {
  className: string
  audienceData: any
  type: string
}

const DonutChart1: React.FC<Props> = ({className, audienceData, type}) => {
  const [loc1, setLoc1] = useState([])
  const [lan1, setLan1] = useState([])

  const loc: Array<String> = []
  const lange: Array<String> = []
  const inte: Array<String> = []
  const interest = audienceData?.audience_interests
  const locData: Array<Number> = []
  const lanData: Array<Number> = []
  const inteData: Array<Number> = []

  useEffect(() => {
    interest?.map((reach: any) => {
      inte.push(reach.name)
      inteData.push(Math.round(reach.weight * 100))
    })
  }, [inte])

  // useEffect(() => {
  //   type=='Location'?
  //   data?.map((reach:any)=>{setLoc({name:reach.Name,weight:(Math.round(reach.weight*100))})}):
  //   data?.map((reach:any)=>{;cat.push(reach.code);dat.push(Math.round(reach.weight*100))})
  // }, [data])
  const chartRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    if (!chartRef.current) {
      return
    }
    var chart1
    if (inte.length > 0) {
      chart1 = new ApexCharts(chartRef.current, chart1Options(inteData, inte))
    }
    if (chart1) {
      chart1.render()
      window.dispatchEvent(new Event('resize'))
    }

    return () => {
      if (chart1) {
        chart1.destroy()
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, inte])

  return (
    <div className={`${className}`}>
      <div className='d-flex flex-column'>
        <div ref={chartRef} id='kt_charts_widget_3_chart' style={{height: 'auto'}}></div>
      </div>
    </div>
  )
}
const chart1Options = (inteData: any, inte: any): ApexOptions => {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')

  return {
    series: inteData,
    labels: inte,
    chart: {
      type: 'donut',
    },
    dataLabels: {
      enabled: false,
    },

    plotOptions: {
      pie: {
        customScale: 1,
        donut: {
          labels: {
            show: true,
            value: {
              show: true,
            },
            total: {
              show: true,
            },
          },
        },
      },
    },
    legend: {
      show: false,
    },

    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 270,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
  }
}

export {DonutChart1}
