import {Input, message} from 'antd'
import 'antd/dist/antd.css'
import clsx from 'clsx'
import {useFormik} from 'formik'
import React, {useEffect, useState} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import Select from 'react-select'
import * as Yup from 'yup'
import httpService from '../../setup/http/httpServices'
import {RootState} from '../../setup/redux/RootReducer'
import {Alerts} from '../modules/alerts/Alerts'
import {IUserModel} from '../modules/auth/models/UserModel'
import {PriceMatrixTable} from './PriceMatrixTable'

var initialValues = {
  socialMedia: '',
  bucketName: '',
  minimumFollowerCount: 0,
  maximumFollowerCount: 50,
  costPerInfluencer: '',
}
const priceMatrixSchema = Yup.object().shape({
  bucketName: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Bucket name is required')
    .matches(/^(?=.*[A-Za-z]).{3,50}$/, 'Should not contain just numbers'),
  costPerInfluencer: Yup.number().required('Cost per influencer is required'),
  socialMedia: Yup.object().required('Social media is required'),
  minimumFollowerCount: Yup.number().required('minimum follower count is required'),
  maximumFollowerCount: Yup.number().required('maximum follower count is required'),
})

export function PriceMatrix(props: any) {
  const user: IUserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as IUserModel
  const access = user.permissions.masters[1] || user.permissions.admin

  const [isSuccess, setIsSuccess] = useState(false)
  const [isFailure, setIsFailure] = useState(false)
  const [priceMatrixId, setPriceMatrixId] = useState('')
  const [responseMessage, setResponseMessage] = useState('')
  // const options: any = [];
  // { value: 'Burns Bay Road' }, { value: 'Downing Street' }, { value: 'Wall Street' }
  // ]

  const HandleCancel = (event: any) => {
    formik.resetForm()
  }
  const handlePriceMatrix = (id: any) => {
    httpService.getData(
      '/price-master/' + id,
      (res: any) => {
        let priceMaster = res.data.data.priceMaster
        priceMaster.socialMedia['value'] = priceMaster.socialMedia._id
        priceMaster.socialMedia['label'] = priceMaster.socialMedia.socialMediaName
        formik.setValues(priceMaster)
        setPriceMatrixId(id)
      },
      (error: any) => {
        message.error(error.message)
      }
    )
  }
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: priceMatrixSchema,
    enableReinitialize: true,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setIsFailure(false)
      setIsSuccess(false)
      if (priceMatrixId) {
        httpService.patchData(
          '/price-master/' + priceMatrixId,
          values,
          (res: any) => {
            formik.resetForm()
            setResponseMessage('Success! Price Matrix updated.')
            setPriceMatrixId('')
            setIsSuccess(true)
          },
          (err: any) => {
            setIsFailure(true)
            setResponseMessage(err.message)
          }
        )
      } else
        httpService.postData(
          '/price-master',
          values,
          (res: any) => {
            formik.resetForm()
            setResponseMessage('Success! Price Matrix added.')
            // setBrandId(null)
            setIsSuccess(true)
          },
          (err: any) => {
            setIsFailure(true)
            setResponseMessage(err.message)
          }
        )
    },
  })

  const [socialMedias, setSocialMedias] = useState([])
  const getAllSocialMedias = () => {
    httpService.getData(
      '/social-media-master',
      (response: any) => {
        response.data.data.socialMedias.map((x: any) => {
          x['value'] = x._id
          x['label'] = x.socialMediaName
          return x
        })
        setSocialMedias(response.data.data.socialMedias)
      },
      (error: any) => {
        message.error(error.message)
      }
    )
  }
  useEffect(() => {
    getAllSocialMedias()
  }, [])

  return (
    <div className='d-flex justify-content-center m-2 ' style={{minWidth: '1336px'}}>
      <div className='addCategory'>
        <h3 className='mb-0'>Add Price Matrix</h3>
        <p className='fs-7 text-gray-400'>Create a new price matrix</p>
        <Alerts
          visible={isSuccess}
          type='success'
          message={responseMessage}
          description={'Description'}
        ></Alerts>
        <Alerts
          visible={isFailure}
          type='error'
          message={responseMessage}
          description={'Description'}
        ></Alerts>
        <form noValidate id='kt_login_signup_form' onSubmit={formik.handleSubmit}>
          <div>
            <h6 className='fw-bold required m32'>Social Media</h6>
            <Select
              options={socialMedias}
              className={clsx(
                'form-control form-control-solid bg-gray-100 p-0 border-0 categorySelect',
                {
                  'is-invalid': formik.touched.socialMedia && formik.errors.socialMedia,
                },
                {
                  'is-valid': formik.touched.socialMedia && !formik.errors.socialMedia,
                }
              )}
              value={formik.values['socialMedia']}
              isMulti={false}
              onChange={(e: any) => formik.setFieldValue('socialMedia', e)}
            />
            {formik.touched.socialMedia && formik.errors.socialMedia && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='text-danger' role='alert'>
                    {formik.errors.socialMedia}
                  </span>
                </div>
              </div>
            )}
          </div>
          <div>
            <h6 className='fw-bold required m16'>Bucket Name</h6>
            <input
              type='text'
              placeholder='Enter Bucket Name'
              {...formik.getFieldProps('bucketName')}
              className={clsx(
                'form-control form-control-solid bg-gray-100',
                {
                  'is-invalid': formik.touched.bucketName && formik.errors.bucketName,
                },
                {
                  'is-valid': formik.touched.bucketName && !formik.errors.bucketName,
                }
              )}
            />
            {/* <AutoComplete
              options={[]}
              placeholder='Select Bucket Name'
              {...formik.getFieldProps('brandName')}
              className={clsx(
                'form-control form-control-solid bg-gray-100 p-0',
                {
                  'is-invalid': formik.touched.bucketName && formik.errors.bucketName,
                },
                {
                  'is-valid': formik.touched.bucketName && !formik.errors.bucketName,
                }
              )} filterOption={(inputValue, option) =>
                option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
            /> */}
            {formik.touched.bucketName && formik.errors.bucketName && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='text-danger' role='alert'>
                    {formik.errors.bucketName}
                  </span>
                </div>
              </div>
            )}
          </div>
          <div>
            <h6 className='fw-bold required m16'>Follower Count</h6>
            {/* <Slider
              range={{draggableTrack: true}}
              value={[formik.values['minimumFollowerCount'], formik.values['maximumFollowerCount']]}
              marks={{0: '0K', 50: '50K', 100: {label: <b>100K+</b>}}}
              onChange={(e: any) => {
                formik.setFieldValue('minimumFollowerCount', e[0])
                formik.setFieldValue('maximumFollowerCount', e[1])
              }}
            /> */}
            <div className='d-flex m16'>
              <div className='col' style={{marginRight: '16px'}}>
                <span className='fs-14'>Min</span>
                <input
                  type='number'
                  placeholder='Select'
                  {...formik.getFieldProps('minimumFollowerCount')}
                  className={clsx(
                    'form-control form-control-solid bg-gray-100',
                    {
                      'is-invalid':
                        formik.touched.minimumFollowerCount && formik.errors.minimumFollowerCount,
                    },
                    {
                      'is-valid':
                        formik.touched.minimumFollowerCount && !formik.errors.minimumFollowerCount,
                    }
                  )}
                />
                {formik.touched.minimumFollowerCount && formik.errors.minimumFollowerCount && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span className='text-danger' role='alert'>
                        {formik.errors.minimumFollowerCount}
                      </span>
                    </div>
                  </div>
                )}
              </div>
              <div className='col'>
                <span className='fs-14'>Max</span>
                <input
                  type='number'
                  placeholder='Select'
                  {...formik.getFieldProps('maximumFollowerCount')}
                  className={clsx(
                    'form-control form-control-solid bg-gray-100',
                    {
                      'is-invalid':
                        formik.touched.maximumFollowerCount && formik.errors.maximumFollowerCount,
                    },
                    {
                      'is-valid':
                        formik.touched.maximumFollowerCount && !formik.errors.maximumFollowerCount,
                    }
                  )}
                />
                {formik.touched.maximumFollowerCount && formik.errors.maximumFollowerCount && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span className='text-danger' role='alert'>
                        {formik.errors.maximumFollowerCount}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='m16'>
            <h6 className='fw-bold required'>Cost/Influencer</h6>
            <Input
              type={'number'}
              placeholder='Enter Amount'
              {...formik.getFieldProps('costPerInfluencer')}
              className={clsx(
                'form-control-solid bg-gray-100',
                {
                  'is-invalid': formik.touched.costPerInfluencer && formik.errors.costPerInfluencer,
                },
                {
                  'is-valid': formik.touched.costPerInfluencer && !formik.errors.costPerInfluencer,
                }
              )}
              prefix={'Rs. '}
            />
            {formik.touched.costPerInfluencer && formik.errors.costPerInfluencer && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='text-danger' role='alert'>
                    {formik.errors.costPerInfluencer}
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className='m32 w-100 flex'>
            <button
              type='button'
              onClick={() => {
                setPriceMatrixId('')
                formik.resetForm()
              }}
              className='btn fw-bolder btn-gray-200 fs-6 categoryButton'
            >
              Cancel
            </button>
            <button
              type='submit'
              className='btn create-btn fs-6 categoryButton ms-10'
              disabled={!access}
            >
              Add
            </button>
          </div>
        </form>
      </div>
      <PriceMatrixTable
        editPriceMatrix={handlePriceMatrix}
        updatable={isSuccess || isFailure}
        access={access}
      />
    </div>
  )
}
