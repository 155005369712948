import {SearchOutlined} from '@ant-design/icons'
import {DatePicker, Input, message} from 'antd'
import clsx from 'clsx'
import Moment from 'moment'
import {useEffect, useState} from 'react'
import {Link, useHistory} from 'react-router-dom'
import Select from 'react-select'
import httpService from '../../setup/http/httpServices'
import {KTSVG, toAbsoluteUrl} from '../../_metronic/helpers'
// import { Dropdown2 } from '../../_metronic/partials'
import {Modal} from 'react-bootstrap-v5'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../setup/redux/RootReducer'
import '../masters/basic.css'
import {IUserModel} from '../modules/auth/models/UserModel'

export function CampaignDashboard(props: any) {
  const user: IUserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as IUserModel
  const access: boolean = user.permissions.campaign[1] || user.permissions.admin

  const history: any = useHistory()
  const [campaigns, setCampaigns] = useState({
    limit: 0,
    page: 1,
    search: '',
    status: '',
    brand: '',
    agency: '',
    media: '',
    barter: '',
    total: 0,
    createdAt: '',
    count: {live: 0, completed: 0, underReview: 0, upcoming: 0, draft: 0},
  })
  const [campaignList, setCampaignList] = useState([])
  const [agencies, setAgencies] = useState<any>([])
  const [status, setStatus] = useState(true)
  const [brands, setBrands] = useState<any>()
  const [searchQuery, setSearchQuery] = useState(''); 
  const [tempBarterValue, setTempBarterValue] = useState('');


  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Update the temporary value while typing
    setTempBarterValue(e.target.value);
  };

  const handleInputEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // Update campaigns.barter when Enter key is pressed
    if (e.key === 'Enter') {
      setCampaigns((prevCampaigns) => ({
        ...prevCampaigns,
        barter: tempBarterValue,
      }));    }
  };

  const ResetFilterHandler = () => {
    setCampaigns({
      limit: 0,
      page: 1,
      search: '',
      status: '',
      brand: '',
      agency: '',
      media: '',
      barter: '',
      total: 0,
      createdAt: '',
      count: {live: 0, completed: 0, underReview: 0, draft: 0, upcoming: 0},
    })
    getCampaigns();
  }

  useEffect(
    function () {
      getCampaigns()
      getAllMasters()
    },
    [
      campaigns.status,
      campaigns.media,
      campaigns.barter,
      campaigns.brand,
      campaigns.agency,
      campaigns.search,
      campaigns.createdAt,
    ]
  )
  if (history.location.state && !!!history.location.state.reloaded) {
    history.push({
      pathname: '/campaign/dashboard',
      state: {...history.location.state, ...{reloaded: true}},
    })
    // window.location.reload()
  }
  const getCampaigns = () => {
    const queryParams = {
      limit: 0,
      search: searchQuery,
    };
    httpService.getData(
      '/campaign-master?limit=0&&search=' +
        // (campaigns?.search ? campaigns?.search : '') +
        (searchQuery ? searchQuery : '') +
        '&&status=' +
        (campaigns.status ? campaigns.status : '') +
        '&&barter=' +
        (campaigns.barter ? campaigns.barter : '') +
        '&&brand=' +
        (campaigns.brand ? campaigns.brand : '') +
        '&&agency=' +
        (campaigns.agency ? campaigns.agency : '') +
        '&&createdAt=' +
        (campaigns.createdAt ? campaigns.createdAt : '') +
        '&&media=' +
        (campaigns.media ? campaigns.media.toLowerCase() : ''),
      (response: any) => {
        let campaignData = response.data.data
        campaignData.campaigns.map((x: any) => {
          if (x.instagramInfluencerDetails?.mediaCount) {
            let media = x.instagramInfluencerDetails.mediaCount
            x.contentType = []
            Object.keys(media).forEach((element) => {
              if (media[element]) {
                x.contentType.push('' + element.charAt(0).toUpperCase() + element.slice(1))
              }
            })
          }
          return x
        })
        setCampaignList(campaignData.campaigns)
        delete campaignData.campaigns
        setCampaigns({...campaigns, ...campaignData})
        setStatus(false)
      },
      (error: any) => {
        message.error(error.message)
        setStatus(false)
      }
    )
  }

  const handleSearchButtonClick = () => {
    getCampaigns(); // Trigger the search when the button is clicked
  };

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault(); // Prevent the default form submission behavior
    getCampaigns(); // Trigger the search when Enter is pressed
  };

  useEffect(() => {
    if (searchQuery === '') {
      // If searchQuery is empty, fetch all campaigns
      getCampaigns();
    }
  }, [searchQuery]);

  const Dropdown2 = (props: any) => {
    const [show, setShow] = useState(false)
    const history: any = useHistory()
    const key = 'updatable'
    return (
      <span
        className='menu border border-3 menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold w-200px pt-3 pb-3'
        data-kt-menu='true'
      >
        <span className='menu-item px-3 mb-2'>
          <span className='menu-content fs-6 text-dark fw-bolder px-3 py-4'>Actions</span>
        </span>
        <span className='separator mb-3 opacity-75'></span>
        {access && (
          <span className='menu-item px-3'>
            <span
              className='menu-link px-3 text-hover-white'
              onClick={(e: any) => {
                redirectEditCampaign(props.influencerDetails)
              }}
            >
              Edit
            </span>
          </span>
        )}

        <span className='menu-item px-3'>
          <span
            className='menu-link px-3 text-hover-white'
            onClick={(e: any) => {
              redirectViewCampaign(props.influencerDetails)
            }}
          >
            View
          </span>
        </span>
        {access && (
          <span className='menu-item px-3'>
            <span className='menu-link px-3 text-hover-white' onClick={(e: any) => setShow(true)}>
              Delete
            </span>
          </span>
        )}
        <Modal
          show={show}
          onHide={() => {
            setShow(false)
          }}
          aria-labelledby='contained-modal-title-vcenter'
          centered
        >
          <Modal.Header>
            <Modal.Title>Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you wanna delete?</Modal.Body>
          <Modal.Footer>
            <button
              className='btn fw-bolder btn-gray-200 fs-6 dialogButton'
              onClick={() => {
                setShow(false)
              }}
            >
              Cancel
            </button>
            <button
              className='btn create-btn fs-6 dialogButton ms-2'
              onClick={(e: any) => {
                httpService.deleteData(
                  '/campaign-master/' + props.influencerDetails._id,
                  (response: any) => {
                    getCampaigns()
                  },
                  (error: any) => {
                    message.error(error.message)
                  }
                )
                setShow(true)
              }}
            >
              Delete
            </button>
          </Modal.Footer>
        </Modal>
      </span>
    )
  }
  if (history?.location?.state?.status !== campaigns.status) {
    setCampaigns({...campaigns, ...{status: history?.location?.state?.status}})
  }
  const getAllMasters = () => {
    httpService.getData(
      '/brand-master?limit=0',
      (response: any) => {
        let list = [{value: '', label: 'Select Brand'}]
        response.data.data.brands.map((x: any) => {
          if (campaigns.agency && campaigns.agency == x?.companyDetails?.agencyAssociated?._id) {
            list.push({value: x._id, label: x.brandDetails.brandName})
          } else if (!campaigns.agency) {
            x['value'] = x._id
            x['label'] = x.brandDetails.brandName
            setBrands([{value: '', label: 'Select Brand'}].concat(response.data.data.brands))
            return x
          }
          setBrands(list)
        })
      },
      (error: any) => {
        message.error(error?.message)
      }
    )
    httpService.getData(
      '/agency-master?limit=0',
      (response: any) => {
        response.data.data.agencies.map((x: any) => {
          x['value'] = x._id
          x['label'] = x.agencyDetails.agencyName
          return x
        })
        setAgencies([{value: '', label: 'Select Agency'}].concat(response.data.data.agencies))
      },
      (error: any) => {
        message.error(error?.message)
      }
    )
  }
  const redirectEditCampaign = (data: any) => {
    httpService.getData(
      '/campaign-master/' + data._id,
      (response: any) => {
        response.data.data.campaign.campaignStatus = response.data.data.campaign.status
        history.push({
          pathname: '/campaign/details',
          state: {
            ...response.data.data.campaign,
            _id: data._id,
            ...{
              type: response.data.data.campaign.campaignDetails.name,
              breadcrums: ['Campaigns', 'Campaign Overview'],
            },
          },
        })
      },
      (error: any) => {}
    )
  }
  const redirectViewCampaign = (data: any) => {
    httpService.getData(
      '/campaign-master/' + data._id,
      (response: any) => {
        response.data.data.campaign.campaignStatus = response.data.data.campaign.status
        history.push({
          pathname: '/campaign/campaignDetailById',
          state: {
            ...response.data.data.campaign,
            _id: data._id,
            ...{
              type: response.data.data.campaign.campaignDetails.name,
              breadcrums: ['Campaigns', 'Campaign Overview'],
            },
          },
        })
      },
      (error: any) => {}
    )
  }
  const redirectInfluencerOnboardingCampaign = (data: any) => {
    httpService.getData(
      '/campaign-master/' + data._id,
      (response: any) => {
        let campaignObj = response.data.data.campaign
        campaignObj.campaignStatus = response.data.data.campaign.status
        let campaignStatus = 'Shortlist'
        if (campaignObj.isPaid) {
          campaignStatus = 'Communicate'
        }
        history.push({
          pathname: '/campaign/influencerOnborading/' + data._id,
          state: {
            ...response.data.data.campaign,
            _id: data._id,
            ...{
              status: campaignStatus,
              type: campaignObj.campaignDetails.name,
              breadcrums: ['Campaigns', 'Influencer Onboarding'],
            },
          },
        })
      },
      (error: any) => {}
    )
  }
  const redirectPerformanceCampaign = (data: any) => {
    httpService.getData(
      '/campaign-master/' + data._id,
      (response: any) => {
        history.push({
          pathname: '/performance/campaign-wise',
          state: {
            ...history.location.state,
            _id: response.data.data.campaign._id,
            from: 'ongoing',
            brandValue: data.campaignDetails?.brandMaster?._id,
            agencyValue: data.campaignDetails?.agencyMaster?._id,
            type: 'performanceCampaign',
          },
        })
      },
      (error: any) => {}
    )
  }
  return (
    <div style={{minWidth: '1300px'}}>
      <div className='d-flex  bg-white align-items-center justify-content-between border h-100 pt-4 pb-4'>
        <div>
          <ul className='flex-grow-1 nav nav-pills  ms-10'>
            <li className='nav-item '>
              <a
                style={{borderRadius: '49px'}}
                onClick={() =>
                  history.push({
                    pathname: '/campaign/dashboard',
                    state: {type: 'campaign/dashboard', status: '', reloaded: false},
                  })
                }
                className={
                  campaigns.status == ''
                    ? 'nav-link d-flex w-auto h-40vh flex ps-6 pe-6 active'
                    : 'nav-link d-flex w-auto h-40vh flex text-gray-400 fw-bold'
                }
              >
                All 🌈 ({campaigns.total})
              </a>
            </li>
            <li className='nav-item '>
              <a
                style={{borderRadius: '49px'}}
                onClick={() =>
                  history.push({
                    pathname: '/campaign/dashboard',
                    state: {type: 'campaign/dashboard', status: 'draft', reloaded: false},
                  })
                }
                className={
                  campaigns.status == 'draft'
                    ? 'nav-link d-flex w-auto h-40vh flex ps-6 pe-6 active'
                    : 'nav-link d-flex w-auto h-40vh flex text-gray-400 fw-bold'
                }
              >
                Draft ⚡ ({campaigns.count.draft})
              </a>
            </li>
            <li className='nav-item '>
              <a
                style={{borderRadius: '49px'}}
                onClick={() =>
                  history.push({
                    pathname: '/campaign/dashboard',
                    state: {type: 'campaign/dashboard', status: 'upcoming', reloaded: false},
                  })
                }
                className={
                  campaigns.status == 'upcoming'
                    ? 'nav-link d-flex w-auto h-40vh flex ps-6 pe-6 active'
                    : 'nav-link d-flex w-auto h-40vh flex text-gray-400 fw-bold'
                }
              >
                Upcoming ⚡ ({campaigns.count.upcoming})
              </a>
            </li>
            <li className='nav-item '>
              <a
                style={{borderRadius: '49px'}}
                onClick={() =>
                  history.push({
                    pathname: '/campaign/dashboard',
                    state: {type: 'campaign/dashboard', status: 'underReview', reloaded: false},
                  })
                }
                className={
                  campaigns.status == 'underReview'
                    ? 'nav-link d-flex w-auto h-40vh flex ps-6 pe-6 active'
                    : 'nav-link d-flex w-auto h-40vh flex text-gray-400 fw-bold'
                }
              >
                Under Review 🔎 ({campaigns.count.underReview})
              </a>
            </li>
            <li className='nav-item '>
              <a
                style={{borderRadius: '49px'}}
                onClick={() =>
                  history.push({
                    pathname: '/campaign/dashboard',
                    state: {type: 'campaign/dashboard', status: 'live', reloaded: false},
                  })
                }
                className={
                  campaigns.status == 'live'
                    ? 'nav-link d-flex w-auto h-40vh flex ps-6 pe-6 active'
                    : 'nav-link d-flex w-auto h-40vhflex text-gray-400 fw-bold'
                }
              >
                Ongoing ⚡ ({campaigns.count.live})
              </a>
            </li>
            <li className='nav-item '>
              <a
                style={{borderRadius: '49px'}}
                onClick={() =>
                  history.push({
                    pathname: '/campaign/dashboard',
                    state: {
                      type: 'campaign/dashboard',
                      status: 'completed',
                      reloaded: false,
                    },
                  })
                }
                className={
                  campaigns.status == 'completed'
                    ? 'nav-link d-flex w-auto h-40vh flex ps-6 pe-6 active'
                    : 'nav-link d-flex w-auto h-40vh flex text-gray-400 fw-bold'
                }
              >
                Completed ✅({campaigns.count.completed})
              </a>
            </li>
          </ul>
        </div>

        <div className='d-flex  me-10'>
        <form onSubmit={handleFormSubmit}>

          <div className='TableHeaderSearch d-flex  justify-content-end align-items-center '>
            <link
              href='https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css'
              rel='stylesheet'
            />
            <Input
              placeholder='Search campaigns'
              style={{width: '12vw', height: '40px'}}
              className='form-control-solid border rounded'
              // onChange={(e: any) => {
              //   setTimeout(() => {
              //     setCampaigns({...campaigns, ...{search: e.target.value}})
              //     console.log('Updated campaigns state:', campaigns);

              //   }, 100)
              // }}
              onChange={handleSearchInputChange}
              value={searchQuery} 
              prefix={
                <>
                  <SearchOutlined />
                </>
              }
            />
            {access && (
          <button
          type="submit"
            style={{ width: '12vw', height: '40px', borderRadius: '4px' }}
            className='btn create-btn d-flex flex ms-5 fs-7'
            onClick={handleSearchButtonClick} // Step 3: Handle button click
          >
            Search
          </button>
        )}
        
            {access && (
              <Link to={{pathname: '/campaign/details', state: {type: 'campaignDetails'}}}>
                <button
                  style={{width: '12vw', height: '40px', borderRadius: '4px'}}
                  className='btn create-btn d-flex flex ms-5 fs-7'
                >
                  Create New Campaign
                </button>
              </Link>
            )}
          </div>
          </form>
        </div>
      </div>
      <div className='flex justify-content-start bg-white h-75px w-100 border'>
        <div className='ms-10 flex'>
          <label className='fs-6 me-5'>Filters</label>
          {user.permissions.admin && (
            <Select
              value={campaigns.agency && agencies?.find(x => x._id === campaigns.agency)}
              options={agencies}
              isMulti={false}
              placeholder={'Agencies (' + (agencies?.length > 0 ? agencies?.length - 1 : 0) + ')'}
              styles={{
                menuList: (provided, state) => ({
                  ...provided,
                  height: 'auto',
                  maxHeight: '200px',
                }),
              }}
              className={'form-select p-0 categorySelect ms-5 w-200px'}
              onChange={(e: any) => {
                setCampaigns({...campaigns, ...{agency: e.value}})
                // getCampaigns()
                // formik.setFieldValue('agencyName', e.label)
                // setAgency(e)
              }}
            />
          )}
          {user.permissions.admin && (
            <Select
              value={campaigns.brand && brands?.find(x => x._id == campaigns.brand)}
              options={brands}
              isMulti={false}
              placeholder={'Brands (' + (brands?.length > 0 ? brands?.length - 1 : 0) + ')'}
              styles={{
                menuList: (provided, state) => ({
                  ...provided,
                  height: 'auto',
                  maxHeight: '200px',
                }),
              }}
              className={'form-select p-0 categorySelect ms-5 w-200px'}
              onChange={(e: any) => {
                setTimeout(() => {
                  setCampaigns({...campaigns, ...{brand: e.value}})
                }, 100)
              }}
            />
          )}
          <select
            value={campaigns.media}
            className='form-select w-200px ms-5 '
            placeholder='Media'
            onChange={(e: any) => {
              campaigns.media = e.target.selectedOptions[0].value
              setCampaigns({...campaigns})
            }}
          >
            <option value=''>Select Media</option>
            <option value='Youtube'>Youtube</option>
            <option value='Instagram'>Instagram</option>
          </select>
          <Input
            value={tempBarterValue}
            placeholder='Barter Value'
            style={{width: '150px', height: '40px'}}
            className='form-control-solid border rounded ms-5'
            onChange={handleInputChange}
            onKeyDown={handleInputEnter}
           
            // onChange={(e: any) => {
              // campaigns.barter = e.target.value
            //   setTimeout(() => {
            //     setCampaigns({...campaigns})
            //   }, 100)
            // }}
          // }
          />
          <DatePicker
            name='date'
            className={clsx('form-select w-auto ms-5')}
            onChange={(date, dateString) => {
              setTimeout(() => {
                setCampaigns({...campaigns, ...{createdAt: dateString}})
              }, 100)
            }}
          />
          <span
            className=' fs-7 w-80px ms-5 cursor-pointer'
            // onClick={() =>
            //   history.push({
            //     pathname: '/campaign/dashboard',
            //     state: { type: 'campaign/dashboard', status: '' },
            //   })
            // }
            onClick={ResetFilterHandler}
          >
            Reset Filters
          </span>
        </div>
      </div>
      {campaignList.length === 0 && !status && (
        <>
          <div>
            <div className='flex'>
              <img className='ms-20' src={toAbsoluteUrl('/media/logos/TableVector.png')}></img>
            </div>
            <div className='flex'>
              <div>
                <h2 className='mb-0 m24 flex'>No Campaigns Listed</h2>
              </div>
            </div>
            <div className='flex'>
              {access && (
                <Link to={{pathname: '/campaign/details', state: {type: 'campaignDetails'}}}>
                  <button className='btn m24 TableButton create-btn text-white'>
                    Create New Campaign 🚀
                  </button>
                </Link>
              )}
            </div>
          </div>
        </>
      )}
      {campaignList.length !== 0 && status && (
        <>
          <div>
            <div className='flex'>
              <span className='svg-icon svg-icon-5x flex svg-icon-primary me-3 ms-8'>
                <KTSVG path={toAbsoluteUrl('/media/icons/Custom/Loading.svg')} />
              </span>
            </div>
            <div className='flex'>
              <div>
                <h5 className='mb-0 mt-3 flex'>Fetching Data</h5>
              </div>
            </div>
          </div>
        </>
      )}
      <div className='campaignGrid'>
        {campaignList?.map((data: any) => (
          <div
            className='cursor-pointer'
            onClick={() => {
              if (
                data.status === 'underReview' ||
                data.status === 'draft' ||
                data.status === 'upcoming' ||
                data.status === 'rejected'
              ) {
                redirectViewCampaign(data)
              } else if (data.status === 'live') {
                redirectInfluencerOnboardingCampaign(data)
              } else if (data.status === 'completed') {
                redirectPerformanceCampaign(data)
              }
            }}
          >
            <span className='d-flex w-100'>
              <span className='flex justify-content-start w-100'>
                <label className='campaignLabel w-40px h-40px rounded-circle'>
                  <img
                    className='w-100'
                    style={{borderRadius: '50%'}}
                    src={data.campaignDetails?.brandMaster?.brandDetails.avatar}
                  ></img>{' '}
                </label>
                <span className='fs-6 ms-4 mt-1 '>
                  <h6 className='mb-0'>
                    {data.campaignDetails?.brandMaster?.brandDetails.brandName}
                  </h6>{' '}
                  <span className='fs-7 text-gray-700'>
                    {data.campaignDetails?.agencyMaster?.agencyDetails.agencyName}
                  </span>
                </span>
              </span>
              <span className='flex h-25px justify-content-end w-50'>
                {data.status === 'draft' && (
                  <span
                    className=' fs-7 rounded p-2 pt-1 pb-1'
                    style={{color: '#FCA440', background: 'rgba(254, 225, 191, 0.25)'}}
                  >
                    Draft
                  </span>
                )}
                {data.status === 'upcoming' && (
                  <span
                    className=' fs-7 rounded p-2 pt-1 pb-1'
                    style={{color: '#FCA440', background: 'rgba(254, 225, 191, 0.25)'}}
                  >
                    Upcoming
                  </span>
                )}
                {data.status === 'underReview' && (
                  <span
                    className=' fs-7 rounded p-2 pt-1 pb-1'
                    style={{color: '#0AAFFF', background: 'rgba(206, 239, 255, 0.25)'}}
                  >
                    Under Review
                  </span>
                )}

                {data.status === 'completed' && (
                  <span
                    className=' fs-7 rounded p-2 pt-1 pb-1'
                    style={{color: '#494646', background: '#F9FAFC'}}
                  >
                    Completed
                  </span>
                )}
                {data.status === 'live' && (
                  <span
                    className=' fs-7 rounded p-2 pt-1 pb-1'
                    style={{color: '#24A25C', background: 'rgba(213, 244, 227, 0.25)'}}
                  >
                    Ongoing
                  </span>
                )}
                {data.status === 'rejected' && (
                  <span
                    className=' fs-7 rounded p-2 pt-1 pb-1'
                    style={{color: '#E73C3C', background: 'rgba(250, 216, 216, 0.5)'}}
                  >
                    Rejected
                  </span>
                )}
                {/* {data.stateOverview.isComplete && <span className='text-success bg-success bg-opacity-20 fs-7 rounded p-2 pt-1 pb-1'>Complete</span>}
                {!data.stateOverview.isComplete && <span className='text-warning bg-warning bg-opacity-20 fs-7 rounded p-2 pt-1 pb-1'>Under Review</span>} */}
                {/* {data.Status == "Active" && <span className='text-warning bg-warning bg-opacity-20 fs-7 rounded p-2 pt-1 pb-1'>{data.Status}</span>}
                {data.Status == "Under Review" && <span className='text-info bg-info bg-opacity-20 fs-7 rounded p-2 pt-1 pb-1'>{data.Status}</span>} */}
              </span>
            </span>
            <span className='m16'>
              <h6 className='mb-1'>{data.campaignDetails.name}</h6>
              <p className='fs-7 campaign-description'>{data.campaignDetails.description}</p>
            </span>
            <span className='flex w-100'>
              <span className='w-100'>
                <h6 className='fs-7 text-gray-400'>Media Required</h6>
                {data.campaignDetails.platform === 'Instagram' ? (
                  <span className='d-flex'>
                    <img
                      className='w-15px h-15px'
                      src={toAbsoluteUrl('/media/logos/Instagram.svg')}
                    />
                    <h6 className='fs-7 ms-1'>Instagram</h6>
                  </span>
                ) : (
                  <span className='d-flex'>
                    <img
                      className='w-15px h-15px'
                      src={toAbsoluteUrl('/media/logos/Youtube.svg')}
                    />
                    <h6 className='fs-7 ms-1'>Youtube</h6>
                  </span>
                )}
              </span>
              {!data.youtubeInfluencerDetails && data.contentType?.length > 0 && (
                <span className='w-100'>
                  <h6 className='fs-7 text-gray-400'>Content Type</h6>
                  <span className='d-flex'>
                    <h6 className='fs-7'>{data.contentType.filter((x, i) => i < 3)?.join(', ')}</h6>
                  </span>
                </span>
              )}
            </span>
            <span className='flex w-100 m16'>
              <span className='w-100'>
                <h6 className='fs-7 text-gray-400'>Barter Value</h6>
                <label className='d-flex'>
                  <h6 className='fs-7'>
                    ₹ {data.campaignDetails.barterValue.min} - ₹{' '}
                    {data.campaignDetails.barterValue.max}
                  </h6>
                </label>
              </span>
              <span className='w-100'>
                <h6 className='fs-7 d-flex text-gray-400'>
                  Applications{' '}
                  {/* <ul className='text-info fs-8 mb-0 hide'>
                    <li>18 NEW</li>
                  </ul> */}
                </h6>
                <label className='d-flex'>
                  <h6 className='fs-7'>{data.campaignDetails.applicationCount || 'NA'}</h6>
                </label>
              </span>
            </span>
            <span className='flex w-100 m16'>
              <span className='w-100'>
                <h6 className='fs-7 text-gray-400'>Application Deadline</h6>
                <label className='d-flex'>
                  <h6 className='fs-7 ms-1'>
                    {Moment(data.campaignDetails.endDate).format('DD MMM YYYY')}
                  </h6>
                </label>
              </span>
              <span className='w-100'>
                <h6 className='fs-7 text-gray-400'>Influencer Required</h6>
                <label className='d-flex'>
                  <h6 className='fs-7'>{data.campaignDetails.noOfInfluencers}</h6>
                </label>
              </span>
            </span>
            <span className='d-flex m24 w-100'>
              <p className='flex justify-content-start m-0 fs-8 text-gray-400 w-100'>
                Last Modified: {Moment(data.updatedAt).format('DD MMM, YYYY')}
                {/* {Moment(data.updatedAt).format('HH SS')} */}
              </p>
              {/* {props.writeAccess && (
                <> */}
              {/* <span className='flex justify-content-end w-25'>
                <button
                  type='button'
                  className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary rotate-90'
                  data-kt-menu-trigger='hover'
                  data-kt-menu-placement='bottom-end'
                  data-kt-menu-flip='top-end'
                >
                  <KTSVG
                    className='svg-icon  svg-icon-dark'
                    path={toAbsoluteUrl('/media/icons/Custom/Menu90.svg')}
                  />
                </button>
                <Dropdown2 influencerDetails={data} data-kt-menu='true' />
              </span> */}
              {/* </> ) } */}
            </span>
          </div>
        ))}
      </div>
    </div>
  )
}
