import React from 'react'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import '../Dashboard.scss'
import {NoData} from './NoData'

export function Schedule(props: any) {
  const rows: any[] = [1, 2, 3, 4, 5]

  return (
    <div className='Dcard Dinfluencers col ms-5  h-400px'>
      <h2 className='Dheading'>Campaign Schedule</h2>
      {!props.data || props.data?.length == 0 ? (
        <NoData />
      ) : (
        <div className='table-responsive'>
          <table className='table table-row-dashed table-row align-middle gs-0 gy-4 m-0'>
            <thead>
              <tr className='fw-bolder text-muted'>
                <th style={{width: '350px'}}>
                  <span className='d-flex'>Campaign</span>
                </th>
                <th style={{width: '100px'}}>
                  <span className='d-flex'>Platform</span>
                </th>
                <th style={{width: '140px'}}>
                  <span className='d-flex'>Date</span>
                </th>
                <th>
                  <span className='d-flex'>Status</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {props.data?.map((index) => (
                <tr key={index} className=''>
                  <td className='fw-bolder flex justify-content-start'>
                    <img
                      className='Davatar'
                      src='https://cdn0.iconfinder.com/data/icons/most-usable-logos/120/Amazon-512.png'
                      alt=''
                    />
                    <div className='flex-column'>
                      Netflix
                      <p
                        style={{
                          margin: '0px',
                          fontSize: '10px',
                          lineHeight: '15px',
                          fontWeight: 'normal',
                        }}
                      >
                        Campaign
                      </p>
                    </div>
                  </td>
                  <td className='fw-normal'>
                    <img src={toAbsoluteUrl('/media/logos/Instagram.png')} alt='' />
                  </td>
                  <td className='fw-normal'>6 Feb, 2022</td>
                  <td className='fw-bolder'>
                    <span className='text-danger bg-danger bg-opacity-20 rounded p-2 pt-1 pb-1'>
                      Goes Live
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  )
}
