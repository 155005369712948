import React, {useState} from 'react'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {Link} from 'react-router-dom'

function Brand_Step1() {
  const [industry, setIndustry] = useState('')
  return (
    <div style={{width: '546px'}}>
      <div style={{fontSize: '36px', fontWeight: 700}}>Step 1 of 4</div>
      <label className='fs-14 m16 required'>Selected Associated Industry</label>
      <select
        className='form-select'
        onChange={(event: any) => {
          setIndustry(event.target.value)
        }}
      >
        <option value=''>Industry Name</option>
        <option value='Fashion'>Fashion</option>
        <option value='Beauty/ Makeup'>Beauty/ Makeup</option>
        <option value='Skincare'>Skincare</option>
        <option value='Fitness'>Fitness</option>
        <option value='Entertainment/Comedy'>Entertainment/Comedy</option>
        <option value='Tech Influencers'>Tech Influencers</option>
        <option value='Parenting'>Parenting</option>
        <option value='Food'>Food</option>
      </select>
      <div className='d-flex flex-column m16'>
        <Link to='/auth/register/step2'>
          <button
            className='w-100 btn btn-primary rounded'
            style={{fontSize: '14px', fontWeight: 600}}
            disabled={industry == ''}
          >
            Next
          </button>
        </Link>
        <Link to='/auth/register'>
          <button className='w-100 btn rounded m8' style={{fontSize: '14px', fontWeight: 600}}>
            Go back
          </button>
        </Link>
      </div>
    </div>
  )
}

export default Brand_Step1
