import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import clsx from 'clsx'
import '../masters/basic.css'
import { AccessOption } from './AccessOption'
import { toAbsoluteUrl } from '../../_metronic/helpers'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import httpService from '../../setup/http/httpServices'
import { message } from 'antd'
import { ImageCropper } from '../masters/ImageCropper'
import { IUserModel } from '../modules/auth/models/UserModel'
import { useSelector, shallowEqual } from 'react-redux'
import { RootState } from '../../setup'
var initialValues: any = {
  firstName: '',
  lastName: '',
  email: '',
  role: '',
  phoneNumber: '',
  newPassword: '',
  isActive: true,
  currentPassword: '',
  gender: 'Male',
  permissions: {
    masters: {
      view: false,
      edit: false,
    },
    campaign: {
      view: false,
      edit: false,
    },
    scheduler: {
      view: false,
      edit: false,
    },
    transactions: {
      view: false,
      edit: false,
    },
    admin: false,
    adminSettings: {
      view: false,
      edit: false,
    },
    support: {
      view: false,
      edit: false,
    },
  },
}

const registrationSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Input is required')
    .matches(/^(?=.*[A-Za-z]).{3,50}$/, 'Should not contain just numbers'),
  lastName: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Input is required')
    .matches(/^(?=.*[A-Za-z]).{3,50}$/, 'Should not contain just numbers'),
  role: Yup.string().required('Role is required'),
  phoneNumber: Yup.string()
    .min(10, 'Minimum 10 digits')
    .max(12, 'Maximum 13 digits with country code')
    .required('Phone Number is required'),
})

export function ProfileManagerSetting() {
  const history: any = useHistory()
  const userId = history.location.state.id
  const [loading, setLoading] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [profileDetails, setProfileDetails] = useState(initialValues)
  const [isEditMode, setEditMode] = useState(true)
  const [show, setShow] = useState(isEditMode)
  const [bgImage, setBgImage] = useState(toAbsoluteUrl('/media/avatars/blank.png'))
  const [selectedFile, setSelectedFile] = useState(new File([], ''))
  const [toggleCrop, setToggleCrop] = useState(false)
  const [self, setSelf] = useState(false)
  const [roleList, setRoleList] = useState<any>([])
  const user: IUserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as IUserModel

  const [accessList, updateAccessList] = useState<any>({
    masters: {
      view: false,
      edit: false,
    },
    campaign: {
      view: false,
      edit: false,
    },
    scheduler: {
      view: false,
      edit: false,
    },
    transactions: {
      view: false,
      edit: false,
    },
    admin: false,
    adminSettings: {
      view: false,
      edit: false,
    },
    support: {
      view: false,
      edit: false,
    },
  })
  function onChange(event: any) {
    let file = event.target.files[0]
    setSelectedFile(file)
    var reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
      setBgImage('' + reader.result + '')
      setToggleCrop(true)
    }
  }
  function resetImage() {
    setBgImage(toAbsoluteUrl('/media/logos/profilePicture.png'))
    setSelectedFile(new File([], ''))
  }
  async function handleCrop(image: any) {
    setBgImage(image)
    const response = await fetch(image, { mode: 'no-cors' })
    const data = await response.blob()
    setSelectedFile(
      new File([data], selectedFile?.name, {
        type: selectedFile.type,
      })
    )
    formik.setErrors({})
    setToggleCrop(false)
  }
  const handleCancel = () => {
    setBgImage(toAbsoluteUrl('/media/logos/demoImage.svg'))
    setSelectedFile(new File([], ''))
    setToggleCrop(false)
  }
  const uploadPic = (userId) => {
    let fd = new FormData()
    if (selectedFile.size <= 0) return
    fd.append('logoUrl', selectedFile)
    httpService.postData(
      '/user/upload-picture/' + userId,
      fd,
      (response: any) => { },
      (error: any) => {
        message.error(error.message)
      }
    )
  }
  const formik = useFormik({
    initialValues: profileDetails || initialValues,
    validationSchema: registrationSchema,
    validate: (values => {
      let errors: any = {};
      if(!userId) {
        if(values.currentPassword === ""){
          errors.currentPassword = "Password is required."
        }
        else if (!values.currentPassword.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/)) {
          errors.currentPassword = "Password should have minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character.";
        }
        else if(values.currentPassword !== values.newPassword) {
          errors.newPassword = "Password does not match.";
        }
      }
      else {
        if (values.currentPassword !== "" && !values.currentPassword.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/)) {
          errors.currentPassword = "Password should have minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character.";
        }
        else if(values.currentPassword !== values.newPassword) {
          errors.newPassword = "Password does not match.";
        }
      }
      if(values.email && (values.email.length <=3 || values.email.length > 50 || !values.email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/))){
        errors.email = "Invalid email format.";
      }
      else if(values.email && values.email?.split("@")[1] !== user.email.split("@")[1] && (user.userType === "brand" || user.userType === "agency")) {
        errors.email = "Email must have " + user.email.split("@")[1] + " as part of their email address.";
      }
      return errors;
    }),
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      // if (!userId && (!values.newPassword || !values.currentPassword)) {
      //   message.error('Password is mendatory for the new user')
      //   setSubmitting(false)
      //   return
      // } else if (values.newPassword !== values.currentPassword) {
      //   message.error('Password does not match.')
      //   setSubmitting(false)
      //   return
      // }
      setLoading(true)
      setTimeout(() => {
        setHasError(false)
        setProfileDetails(values)
        setLoading(false)
        let request: any = values
        Object.keys(accessList).forEach((module) => {
          if (module === 'admin') {
            request.permissions[module] = accessList[module]
          } else {
            request.permissions[module] = Object.values(accessList[module])
          }
        })
        request.userType = user.userType
        request.parentUser = profileDetails?.parentUser || user.userId
        request.referenceId = profileDetails?.referenceId || user.agencyId || user.brandId
        // if (user.userType === 'agency') {
        //   userType": "agency"
        // }
        // parentUser: user.userId
        // referenceId: user.userId
        // // userType": "agency", // admin, agency or brand
        // // "parentUser": "", // Add userId of the agency or brand only when you create sub users
        // // "referenceId": "agencyId", // Add agencyId or brandId for every users and sub users except for admin users.
        setSubmitting(false)
        request.password = request.newPassword
        delete request.newPassword
        delete request.currentPassword
        if (userId) {
          httpService.patchData(
            '/user/' + userId,
            request,
            (response: any) => {
              message.success('User details updated.')
              uploadPic(userId)
              history.push({
                pathname: '/adminSettings/profile',
                state: { type: 'Access Manager' },
              })
            },
            (error: any) => {
              message.error(error.message)
            }
          )
        } else {
          httpService.postData(
            '/auth/register',
            request,
            (response: any) => {
              message.success('User added.')
              uploadPic(response?.data?.data?.user?._id)
              history.push({
                pathname: '/adminSettings/profile',
                state: { type: 'Access Manager' },
              })
            },
            (error: any) => {
              message.error(error.message)
            }
          )
        }
      }, 1000)
    },
  })

  useEffect(() => {
    httpService.getData(
      '/role-master/?page=1',
      (response: any) => {
        setRoleList(response.data.data.roles)
        httpService.getData(
          userId ? '/user/' + userId : '',
          (res: any) => {
            const userDetails = res.data.data.user
            updateAccessList({
              masters: {
                view: userDetails.permissions.masters[0],
                edit: userDetails.permissions.masters[1],
              },
              campaign: {
                view: userDetails.permissions.campaign[0],
                edit: userDetails.permissions.campaign[1],
              },
              scheduler: {
                view: userDetails.permissions.scheduler[0],
                edit: userDetails.permissions.scheduler[1],
              },
              transactions: {
                view: userDetails.permissions.transactions[0],
                edit: userDetails.permissions.transactions[1],
              },
              admin: userDetails.permissions.admin,
              adminSettings: {
                view: userDetails.permissions.adminSettings[0],
                edit: userDetails.permissions.adminSettings[1],
              },
              support: {
                view: userDetails.permissions.support[0],
                edit: userDetails.permissions.support[1],
              },
            })
            userDetails.role = userDetails?.role?._id
            userDetails.newPassword = ''
            userDetails.currentPassword = ''
            setProfileDetails(userDetails)
            if (userDetails?.avatar) {
              handleCrop('' + userDetails.avatar + '')
            }
          },
          (err: any) => {
            message.error({ content: err.message })
          }
        )
      },
      (error: any) => { }
    )
    try {
      var profileDetail1 = history.location.state?.profileDetails
      setProfileDetails(profileDetail1)
    } catch { }
    if (history?.location?.state?.id === user.userId) {
      setSelf(true)
    }
  }, [history.location.state?.profileDetails])

  return (
    <div className='d-flex' style={{ minWidth: '1336px' }}>
      <div>
        <div className='profileSettingDiv' style={{ marginBottom: '20px' }}>
          <span
            style={{ width: '120px', height: '120px' }}
            className='col-fluid svg-icon svg-icon-2x flex svg-icon-dark bg-gray-100 h-120px w-120px rounded-circle'
          >
            <div className='image-input image-input-circle' data-kt-image-input='true'>
              <div
                className='image-input-wrapper w-125px h-125px'
                style={{ backgroundImage: `url(` + bgImage + `)` }}
              ></div>
              <label
                className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow'
                data-kt-image-input-action='change'
                data-bs-toggle='tooltip'
                hidden={self}
                data-bs-original-title='Change avatar'
              >
                <i className='bi bi-pencil-fill fs-7'></i>
                <input
                  type='file'
                  name='avatar'
                  accept='.png, .jpg, .jpeg'
                  onChange={(event) => onChange(event)}
                />
                <input type='hidden' name='avatar_remove' />
              </label>

              {selectedFile.size > 0 && (
                <span
                  className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow'
                  data-kt-image-input-action='cancel'
                  data-bs-toggle='tooltip'
                  data-bs-dismiss='click'
                  title='Cancel avatar'
                >
                  <i className='bi bi-x fs-2'></i>
                </span>
              )}
              {selectedFile.size > 0 && (
                <span
                  className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow'
                  data-kt-image-input-action='remove'
                  data-bs-toggle='tooltip'
                  data-bs-dismiss='click'
                  title='Remove avatar'
                  onClick={resetImage}
                >
                  <i className='bi bi-x fs-2'></i>
                </span>
              )}
            </div>
          </span>
          <form className='m32' noValidate onSubmit={formik.handleSubmit}>
            <div className='d-flex'>
              <div className='col me-7'>
                <h6 className='fw-bold required'>First Name</h6>
                <input
                  type='text'
                  placeholder='First Name'
                  // disabled={self}
                  {...formik.getFieldProps('firstName')}
                  className={clsx(
                    'form-control form-control-solid bg-gray-100',
                    {
                      'is-invalid': formik.touched.firstName && formik.errors.firstName,
                    },
                    {
                      'is-valid': formik.touched.firstName && !formik.errors.firstName,
                    }
                  )}
                />
                {formik.touched.firstName && formik.errors.firstName && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span className='text-danger' role='alert'>
                        {formik.errors.firstName}
                      </span>
                    </div>
                  </div>
                )}
              </div>

              <div className='col me-7'>
                <h6 className='fw-bold required'>Last Name</h6>
                <input
                  type='text'
                  placeholder='Last Name'
                  // disabled={self}
                  {...formik.getFieldProps('lastName')}
                  className={clsx(
                    'form-control form-control-solid bg-gray-100',
                    {
                      'is-invalid': formik.touched.lastName && formik.errors.lastName,
                    },
                    {
                      'is-valid': formik.touched.lastName && !formik.errors.lastName,
                    }
                  )}
                />
                {formik.touched.lastName && formik.errors.lastName && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span className='text-danger' role='alert'>
                        {formik.errors.lastName}
                      </span>
                    </div>
                  </div>
                )}
              </div>
              <div className='col '>
                <h6 className='fw-bold required'>Role/Designation</h6>
                <select
                  {...formik.getFieldProps('role')}
                  disabled={self}
                  className={clsx(
                    'form-select text-gray-500 border-0 bg-gray-100',
                    {
                      'is-invalid': formik.touched.role && formik.errors.role,
                    },
                    {
                      'is-valid': formik.touched.role && !formik.errors.role,
                    }
                  )}
                >
                  <option value=''>Role/Designation</option>
                  {roleList.map((role: any) => (
                    <option value={role._id}>{role.role}</option>
                  ))}
                  {/* <option value='Admin'>Admin</option>
                  <option value='Brand'>Brand</option>
                  <option value='Agency'>Agency</option> */}
                </select>
                {formik.touched.role && formik.errors.role && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span className='text-danger' role='alert'>
                        {formik.errors.role}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='col m24 mb-2'>
              <h6 className='fw-bold required'>Email</h6>
              <input
                type='text'
                placeholder='Email'
                disabled={self}
                {...formik.getFieldProps('email')}
                className={clsx(
                  'form-control form-control-solid bg-gray-100',
                  {
                    'is-invalid': formik.touched.email && formik.errors.email,
                  },
                  {
                    'is-valid': formik.touched.email && !formik.errors.email,
                  }
                )}
              />
              {formik.touched.email && formik.errors.email && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='text-danger' role='alert'>
                      {formik.errors.email}
                    </span>
                  </div>
                </div>
              )}
            </div>
            {isEditMode && (
              <span
                className='link-primary fs-6 fw-bolder text-decoration-underline pointer'
                onClick={() => {
                  httpService.postData(
                    '/auth/forgot-password',
                    {
                      email: formik.values.email,
                    },
                    (response: any) => {
                      message.success('Activation link has been sent to ' + formik.values.email)
                    },
                    (error: any) => {
                      message.error('Something went wrong')
                    }
                  )
                }}
              >
                Resend Activation Email
              </span>
            )}
            <div className='col m24'>
              <h6 className='fw-bold required'>Mobile Number</h6>
              <input
                type='number'
                placeholder='Mobile Number'
                // disabled={self}
                {...formik.getFieldProps('phoneNumber')}
                className={clsx(
                  'form-control form-control-solid bg-gray-100',
                  {
                    'is-invalid': formik.touched.phoneNumber && formik.errors.phoneNumber,
                  },
                  {
                    'is-valid': formik.touched.phoneNumber && !formik.errors.phoneNumber,
                  }
                )}
              />
              {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='text-danger' role='alert'>
                      {formik.errors.phoneNumber}
                    </span>
                  </div>
                </div>
              )}
            </div>
            <div className='d-flex m24'>
              <div className='col'>
                <h6 className='m-0'>Profile Status </h6>
                <p className='m-0 fw-bold fs-6'>
                  Inactive profile won’t be able to access their account
                </p>
              </div>
              <div className='col flex justify-content-end'>
                <div className='form-check form-switch form-check-custom form-check-solid'>
                  <input
                    className='form-check-input w-50px h-25px form-check-input-lightPrimary'
                    checked={formik.values.isActive}
                    disabled={self}
                    type='checkbox'
                    {...formik.getFieldProps('isActive')}
                  />
                </div>
              </div>
            </div>
            <div className='d-flex m24'>
              <div className='col'>
                <h3 className='m-0'>Update Password</h3>
                <br />
              </div>
              {/* <div className='col flex justify-content-end'>
                <button
                  type='reset'
                  onClick={e => setShow(!show)}
                  className='btn fw-bolder btn-white text-lightPrimary border border-lightPrimary pt-2 pb-2'
                >
                  Change Password
                </button>
              </div> */}
            </div>
            <div>
              <div className='fv-row'>
                <div className='d-flex justify-content-between mt-n5'>
                  <div className='d-flex flex-stack mb-2'>
                    <label className='form-label fw-bolder text-dark fs-6 mb-0 mt-5 required'>
                      {isEditMode ? 'New' : 'Enter'} Password
                    </label>
                  </div>
                </div>
                <input
                  placeholder='New Password'
                  type='password'
                  autoComplete='off'
                  // disabled={self}
                  {...formik.getFieldProps('currentPassword')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {
                      'is-invalid': formik.touched.currentPassword && formik.errors.currentPassword,
                    },
                    {
                      'is-valid': formik.touched.currentPassword && !formik.errors.currentPassword,
                    }
                  )}
                />
                {formik.errors.currentPassword && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert' className='text-danger'>{formik.errors.currentPassword}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className='fv-row mb-10 m16'>
                <div className='d-flex justify-content-between'>
                  <div className='d-flex flex-stack mb-2'>
                    <label className='form-label fw-bolder text-dark fs-6 mb-0 required'>
                      Confirm Password
                    </label>
                  </div>
                </div>
                <input
                  placeholder='Confirm Password'
                  type='password'
                  autoComplete='off'
                  // disabled={self}
                  {...formik.getFieldProps('newPassword')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {
                      'is-invalid': formik.touched.newPassword && formik.errors.newPassword,
                    },
                    {
                      'is-valid': formik.touched.newPassword && !formik.errors.newPassword,
                    }
                  )}
                />
                {formik.errors.newPassword && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert' className='text-danger'>{formik.errors.newPassword}</span>
                    </div>
                  </div>
                )}
              </div>{' '}
            </div>
            <div className='d-flex m32'>
              <Link to={{ pathname: '/adminSettings/profile', state: { type: 'Access Manager' } }}>
                <button
                  hidden={self}
                  className='btn fw-bolder btn-gray-200 w-200px CompanyDetailButton me-5'
                >
                  Back to Profiles
                </button>
              </Link>
              <button
                type='submit'
                // hidden={self}
                id='kt_sign_up_submit'
                className='btn create-btn w-200px CompanyDetailButton'
                disabled={formik.isSubmitting || !formik.isValid}
              >
                {!loading && <span className='indicator-label'>Save Details</span>}
                {loading && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className='profileSettingDiv accessRight' style={{ height: 'fit-content' }}>
        <div className='d-flex '>
          <div className='col-8'>
            <h3 className='m-0'>Access Right Management</h3>
            <p className='m-0 fw-bold text-gray-400 fs-7'>
              Enabling this allows access to everything
            </p>
          </div>
          <div className='col flex justify-content-end'>
            <div className='form-check form-switch form-check-custom form-check-solid '>
              <input
                className='form-check-input w-40px h-20px form-check-input-lightPrimary'
                type='checkbox'
                value=''
                disabled={self}
                checked={accessList.admin}
                onChange={(e) => {
                  accessList.admin = e.target.checked
                  Object.keys(accessList).forEach((x: any) => {
                    if (x && accessList[x])
                      Object.keys(accessList[x]).forEach((y: any) => {
                        if (y) {
                          accessList[x][y] = e.target.checked
                        }
                      })
                  })
                  updateAccessList({ ...accessList })
                }}
              />
            </div>
          </div>
        </div>
        <div className='m24'>
          <h6 className='m-0 fs-16'>Masters</h6>
        </div>
        <span className='separator border-gray-200 mt-2'></span>
        <AccessOption
          label='Can view'
          accessName='masters-view'
          self={self}
          hasAccess={accessList.masters.view}
          handleChange={(e: any) => {
            accessList.masters.view = e
            if (e === false) accessList.admin = false
            updateAccessList({ ...accessList })
          }}
        ></AccessOption>
        <AccessOption
          label='Can edit'
          accessName='masters-edit'
          self={self}
          hasAccess={accessList.masters.edit}
          handleChange={(e: any) => {
            accessList.masters.edit = e
            if (e === false) accessList.admin = false
            updateAccessList({ ...accessList })
          }}
        ></AccessOption>
        <div className='m24'>
          <h6 className='m-0 fs-16'>Campaign</h6>
        </div>
        <span className='separator border-gray-200 mt-2'></span>
        <AccessOption
          label='Can view'
          accessName='campaign-view'
          self={self}
          hasAccess={accessList.campaign.view}
          handleChange={(e: any) => {
            accessList.campaign.view = e
            if (e === false) accessList.admin = false
            updateAccessList({ ...accessList })
          }}
        ></AccessOption>
        <AccessOption
          label='Can edit'
          accessName='campaign-edit'
          self={self}
          hasAccess={accessList.campaign.edit}
          handleChange={(e: any) => {
            accessList.campaign.edit = e
            if (e === false) accessList.admin = false
            updateAccessList({ ...accessList })
          }}
        ></AccessOption>
        <div className='m24'>
          <h6 className='m-0 fs-16'>Scheduler</h6>
        </div>
        <span className='separator border-gray-200 mt-2'></span>
        <AccessOption
          label='Can view'
          accessName='scheduler-view'
          self={self}
          hasAccess={accessList.scheduler.view}
          handleChange={(e: any) => {
            accessList.scheduler.view = e
            if (e === false) accessList.admin = false
            updateAccessList({ ...accessList })
          }}
        ></AccessOption>
        <AccessOption
          label='Can edit'
          accessName='scheduler-edit'
          self={self}
          hasAccess={accessList.scheduler.edit}
          handleChange={(e: any) => {
            accessList.scheduler.edit = e
            if (e === false) accessList.admin = false
            updateAccessList({ ...accessList })
          }}
        ></AccessOption>
        <div className='m24'>
          <h6 className='m-0 fs-16'>Transactions</h6>
        </div>
        <span className='separator border-gray-200 mt-2'></span>
        <AccessOption
          label='Can view'
          accessName='transactions-view'
          self={self}
          hasAccess={accessList.transactions.view}
          handleChange={(e: any) => {
            accessList.transactions.view = e
            if (e === false) accessList.admin = false
            updateAccessList({ ...accessList })
          }}
        ></AccessOption>
        <AccessOption
          label='Can edit'
          accessName='transactions-edit'
          self={self}
          hasAccess={accessList.transactions.edit}
          handleChange={(e: any) => {
            accessList.transactions.edit = e
            if (e === false) accessList.admin = false
            updateAccessList({ ...accessList })
          }}
        ></AccessOption>
        <div className='m24'>
          <h6 className='m-0 fs-16'>Admin Settings</h6>
        </div>
        <span className='separator border-gray-200 mt-2'></span>
        <AccessOption
          label='Can view'
          accessName='admin-view'
          self={self}
          hasAccess={accessList.adminSettings.view}
          handleChange={(e: any) => {
            accessList.adminSettings.view = e
            if (e === false) accessList.admin = false
            updateAccessList({ ...accessList })
          }}
        ></AccessOption>
        <AccessOption
          label='Can edit'
          accessName='admin-edit'
          self={self}
          hasAccess={accessList.adminSettings.edit}
          handleChange={(e: any) => {
            accessList.adminSettings.edit = e
            if (e === false) accessList.admin = false
            updateAccessList({ ...accessList })
          }}
        ></AccessOption>
        <div className='m24'>
          <h6 className='m-0 fs-16'>Support</h6>
        </div>
        <span className='separator border-gray-200 mt-2'></span>
        <AccessOption
          label='Can view'
          accessName='support-view'
          self={self}
          hasAccess={accessList.support.view}
          handleChange={(e: any) => {
            accessList.support.view = e
            if (e === false) accessList.admin = false
            updateAccessList({ ...accessList })
          }}
        ></AccessOption>
        <AccessOption
          label='Can edit'
          accessName='support-edit'
          self={self}
          hasAccess={accessList.support.edit}
          handleChange={(e: any) => {
            accessList.support.edit = e
            if (e === false) accessList.admin = false
            updateAccessList({ ...accessList })
          }}
        ></AccessOption>
      </div>
      {toggleCrop && <ImageCropper cancel={handleCancel} editImage={handleCrop} image={bgImage} />}
    </div>
  )
}
