import React, { useEffect, useState } from 'react'
import { toAbsoluteUrl } from '../../_metronic/helpers'
import { OverviewChart } from './Charts/OverviewChart'

function ProfileOverview(props: any) {
  const [year, setYear] = useState(false)
  const [month, setMonth] = useState(true)
  const [day, setDay] = useState(false)
  const overView = props.userData?.user_profile?.stat_history
  const [userProfile, setUserProfile] = useState(props.userData?.user_profile)
  const sections = props.type ? [{ label: 'Comments', key: 'avg_comments' },
  { label: 'Followers', key: 'followers' },
  { label: 'Likes', key: 'avg_likes' },
  { label: 'Dislikes', key: 'avg_dislikes' }] : [{ label: 'Followers', key: 'followers' },
  { label: 'Following', key: 'following' },
  { label: 'Likes', key: 'avg_likes' }];

  //followers: 85760000, avg_likes: 14432, avg_dislikes: 0, avg_comments
  useEffect(() => {
    setUserProfile(props.userData?.user_profile)
  }, [props.userData, props.type])

  return (
    <div className='d-flex flex-column mb16'>
      {props.type && (
        <div className='d-flex flex-column w-100'>
          <div className='d-flex w-100 m20'>
            <div className='Dcard Dash-right justify-content-between p16' style={{ height: '148px' }}>
              <div className='Dash-right-top'>
                <div className='Dash-right-image svg-icon svg-icon-info bg-info bg-opacity-5'>
                  <img src={toAbsoluteUrl('/media/logos/overview_followers.png')} />
                </div>
                <div className='Dash-right-head'>
                  <h2 className='fs-16'>Followers</h2>
                </div>
              </div>
              <h1 className='DcustomHeading'>
                {userProfile?.followers?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              </h1>
            </div>
            <div
              className='Dcard Dash-right justify-content-between p16'
              style={{ height: '148px', marginLeft: '20px' }}
            >
              <div className='Dash-right-top'>
                <div className='Dash-right-image svg-icon svg-icon-info bg-info bg-opacity-5'>
                  <img src={toAbsoluteUrl('/media/logos/overview_views.png')} />
                </div>
                <div className='Dash-right-head'>
                  <h2 className='fs-16'>Average Views</h2>
                </div>
              </div>
              <h1 className='DcustomHeading'>
                {userProfile?.avg_views.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              </h1>
            </div>
            <div
              className='Dcard Dash-right justify-content-between p16'
              style={{ height: '148px', marginLeft: '20px' }}
            >
              <div className='Dash-right-top'>
                <div className='Dash-right-image svg-icon svg-icon-info bg-info bg-opacity-5'>
                  <img src={toAbsoluteUrl('/media/logos/overview_comments.png')} />
                </div>
                <div className='Dash-right-head'>
                  <h2 className='fs-16'>Average Comments</h2>
                </div>
              </div>
              <h1 className='DcustomHeading'>
                {userProfile?.avg_comments.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              </h1>
            </div>
            <div
              className='Dcard Dash-right justify-content-between p16'
              style={{ height: '148px', marginLeft: '20px' }}
            >
              <div className='Dash-right-top'>
                <div className='Dash-right-image svg-icon svg-icon-info bg-info bg-opacity-5'>
                  <img src={toAbsoluteUrl('/media/logos/overview_likes.png')} />
                </div>
                <div className='Dash-right-head'>
                  <h2 className='fs-16'>Average Likes</h2>
                </div>
              </div>
              <h1 className='DcustomHeading'>
                {userProfile?.avg_likes?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              </h1>
            </div>
          </div>
        </div>
      )}
      {!props.type && (
        <div className='d-flex flex-column w-100'>
          <div className='d-flex w-100 m20'>
            <div className='Dcard Dash-right justify-content-between p16' style={{ height: '148px' }}>
              <div className='Dash-right-top'>
                <div className='Dash-right-image svg-icon svg-icon-info bg-info bg-opacity-5'>
                  <img src={toAbsoluteUrl('/media/logos/overview_followers.png')} />
                </div>
                <div className='Dash-right-head'>
                  <h2 className='fs-16'>Followers</h2>
                </div>
              </div>
              <h1 className='DcustomHeading'>
                {userProfile?.followers?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              </h1>
            </div>
            <div
              className='Dcard Dash-right justify-content-between p16'
              style={{ height: '148px', marginLeft: '20px' }}
            >
              <div className='Dash-right-top'>
                <div className='Dash-right-image svg-icon svg-icon-info bg-info bg-opacity-5'>
                  <img src={toAbsoluteUrl('/media/logos/overview_following.png')} />
                </div>
                <div className='Dash-right-head'>
                  <h2 className='fs-16'>Following</h2>
                </div>
              </div>
              <h1 className='DcustomHeading'>
                {userProfile?.following?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </h1>
            </div>
            <div
              className='Dcard Dash-right justify-content-between p16'
              style={{ height: '148px', marginLeft: '20px' }}
            >
              <div className='Dash-right-top'>
                <div className='Dash-right-image svg-icon svg-icon-info bg-info bg-opacity-5'>
                  <img src={toAbsoluteUrl('/media/logos/overview_views.png')} />
                </div>
                <div className='Dash-right-head'>
                  <h2 className='fs-16'>Average Views</h2>
                </div>
              </div>
              <h1 className='DcustomHeading'>
                {userProfile?.avg_views.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              </h1>
            </div>
            <div
              className='Dcard Dash-right justify-content-between p16'
              style={{ height: '148px', marginLeft: '20px' }}
            >
              <div className='Dash-right-top'>
                <div className='Dash-right-image svg-icon svg-icon-info bg-info bg-opacity-5'>
                  <img src={toAbsoluteUrl('/media/logos/overview_comments.png')} />
                </div>
                <div className='Dash-right-head'>
                  <h2 className='fs-16'>Average Comments</h2>
                </div>
              </div>
              <h1 className='DcustomHeading'>
                {userProfile?.avg_comments.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              </h1>
            </div>
          </div>
          <div className='d-flex w-100 m20'>
            <div className='Dcard Dash-right justify-content-between p16' style={{ height: '148px' }}>
              <div className='Dash-right-top'>
                <div className='Dash-right-image svg-icon svg-icon-info bg-info bg-opacity-5'>
                  <img src={toAbsoluteUrl('/media/logos/overview_likes.png')} />
                </div>
                <div className='Dash-right-head'>
                  <h2 className='fs-16'>Average Likes</h2>
                </div>
              </div>
              <h1 className='DcustomHeading'>
                {userProfile?.avg_likes?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              </h1>
            </div>
            <div
              className='Dcard Dash-right justify-content-between p16'
              style={{ height: '148px', marginLeft: '20px' }}
            >
              <div className='Dash-right-top'>
                <div className='Dash-right-image svg-icon svg-icon-info bg-info bg-opacity-5'>
                  <img src={toAbsoluteUrl('/media/logos/overview_engagement.png')} />
                </div>
                <div className='Dash-right-head'>
                  <h2 className='fs-16'>Engagement</h2>
                </div>
              </div>
              <h1 className='DcustomHeading'>
                {userProfile?.engagement_rate}
              </h1>
            </div>
            <div
              className='Dcard Dash-right justify-content-between p16'
              style={{ height: '148px', marginLeft: '20px' }}
            >
              <div className='Dash-right-top'>
                <div className='Dash-right-image svg-icon svg-icon-info bg-info bg-opacity-5'>
                  <img src={toAbsoluteUrl('/media/logos/overview_performance.png')} />
                </div>
                <div className='Dash-right-head'>
                  <h2 className='fs-16'>Paid Post Performance</h2>
                </div>
              </div>
              <h1 className='DcustomHeading'>
                {userProfile?.paid_post_performance ? userProfile?.paid_post_performance : '-'}
              </h1>
            </div>
            <div
              className='Dcard Dash-right justify-content-between p16'
              style={{ height: '148px', marginLeft: '20px' }}
            >
              <div className='Dash-right-top'>
                <div className='Dash-right-image svg-icon svg-icon-info bg-info bg-opacity-5'>
                  <img src={toAbsoluteUrl('/media/logos/overview_credibility.png')} />
                </div>
                <div className='Dash-right-head'>
                  <h2 className='fs-16'>Followers Credibility</h2>
                </div>
              </div>
              <h1 className='DcustomHeading'>
                {props.userData?.audience_followers?.data.audience_credibility}
              </h1>
            </div>
          </div>
        </div>
      )}

      {sections.map(section => (<>
        <div
          className='w-100 m20'
          style={{
            backgroundColor: 'white',
            borderTopLeftRadius: '12px',
            borderTopRightRadius: '12px',
          }}
        >
          <div style={{ padding: '16px 26px' }}>
            <h3 className='m-0'>{section.label}</h3>
          </div>
        </div>
        <div className='chart-topbar' style={{ height: 'fit-content', padding: '16px 32px' }}>
          <div className='chart-topbar-left'>
            <div className='d-flex'>
              {userProfile && userProfile[section.key] && <h3 className='m-0'>{userProfile[section.key]?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} {section.label}</h3>}
            </div>
          </div>
          <div className='chart-topbar-right'>
            <div
              onClick={() => {
                setYear(true)
                setDay(false)
                setMonth(false)
              }}
              className={
                year
                  ? ' w-50px pointer flex bg-nightSky text-white h-25px rounded ms-1'
                  : ' w-50px pointer flex bg-gray-100 text-black h-25px rounded ms-1'
              }
            >
              Year
            </div>
            <div
              onClick={() => {
                setYear(false)
                setDay(false)
                setMonth(true)
              }}
              className={
                month
                  ? ' w-50px pointer flex bg-nightSky text-white h-25px rounded ms-1'
                  : ' w-50px pointer flex bg-gray-100 text-black h-25px rounded ms-1'
              }
            >
              Month
            </div>
            <div
              onClick={() => {
                setYear(false)
                setDay(true)
                setMonth(false)
              }}
              className={
                day
                  ? ' w-50px pointer flex bg-nightSky text-white h-25px rounded ms-1'
                  : ' w-50px pointer flex bg-gray-100 text-black h-25px rounded ms-1'
              }
            >
              Day
            </div>
          </div>
        </div>
        <div className='w-100' style={{ backgroundColor: 'white', padding: '24px' }}>
          <OverviewChart className='w-100' stats={overView} section={section} />
        </div>
      </>
      ))}

    </div>
  )
}
export function EmptyHead(props: any) {
  return (
    <div>
      <p>
        <span>-</span> {props.label}
      </p>
    </div>
  )
}

export default ProfileOverview
