import {message} from 'antd'
import Moment from 'moment'
import React, {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap-v5'
import {useHistory} from 'react-router-dom'
import httpService from '../../setup/http/httpServices'
import {KTSVG, toAbsoluteUrl} from '../../_metronic/helpers'
import {NoData} from '../Dashboard/Components/NoData'
import {StatsGeneral} from '../Dashboard/Charts/StatsGeneral'
import '../masters/basic.css'
import {Chart} from './Chart'
import {TransactionTable} from './TransactionTable'

const chartDataPoints = [
  {label: 'J', value: 50},
  {label: 'F', value: 70},
  {label: 'M', value: 80},
  {label: 'A', value: 40},
  {label: 'M', value: 30},
  {label: 'J', value: 50},
  {label: 'J', value: 60},
  {label: 'A', value: 65},
  {label: 'S', value: 85},
  {label: 'O', value: 95},
  {label: 'N', value: 55},
  {label: 'D', value: 40},
]

export function TransactionDashboard() {
  const data = true
  const history: any = useHistory()
  const [show, setShow] = useState(false)
  const [transData, setTransData] = useState({} as any)
  const handleClose = () => setShow(false)

  const handleShow = (id: string) => {
    setShow(true)
  }

  const getData = () => {
    httpService.getData(
      '/transaction/summary',
      (res: any) => {
        setTransData(res.data.data)
      },
      (err: any) => {
        console.log(err?.message)
      }
    )
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <div className='Outer d-flex' style={{minWidth: '1336px'}}>
      <div className='TableDiv'>
        <TransactionTable handleShow={(id: string) => handleShow(id)} />
      </div>
      <div className='RecentSummary'>
        <h3 className='ms-6 mt-6'>Overview</h3>
        <div className='RecentSummaryChild TransGraph'>
          {!data ? (
            <NoData height='210px' />
          ) : (
            <div>
              {transData?.completedStats?.map((item, index) => (
                <div key={index} className='d-flex'>
                  <div className=''>
                    <p className='m-0 fs-8 text-gray-400'>Completed (last 12 months)</p>
                    <h6 className='m-0'>₹ {item.amount}</h6>
                  </div>
                  <div className='col d-flex justify-content-end'>
                    <div className='col flex justify-content-end'>
                      <span className='ms-4 svg-icon d-flex justify-content-center align-items-center svg-icon-white bg-success w-40px h-40px rounded-circle'>
                        <KTSVG path={toAbsoluteUrl('/media/icons/Custom/Active.svg')} />
                      </span>
                    </div>
                  </div>
                </div>
              ))}
              <StatsGeneral className='w-100' stats={transData?.completedStats} />
            </div>
          )}

          {/* {chartDataPoints.length > 0 && (
            <div>
              {
                transData?.completedStats?.map((item,index) => (
                  <div key={index} className='d-flex'>
                <div className=''>
                  <p className='m-0 fs-8 text-gray-400'>Completed (last 12 months)</p>
                  <h6 className='m-0'>₹ {item.amount}</h6>
                </div>
                <div className='col d-flex justify-content-end'>
                  <div className='col flex justify-content-end'>
                    <span className='ms-4 svg-icon d-flex justify-content-center align-items-center svg-icon-white bg-success w-40px h-40px rounded-circle'>
                      <KTSVG path={toAbsoluteUrl('/media/icons/Custom/Active.svg')} />
                    </span>
                  </div>
                </div>
              </div>
                ))
              }
              <Chart dataPoints={chartDataPoints} />
            </div>
          )} */}
          {/* {chartDataPoints.length === 0 && (
            <div>
              <div className='d-flex'>
                <div className=''>
                  <p className='m-0 fs-8 text-gray-400'>Completed (last 12 months)</p>
                  <h6 className='m-0'>N/A</h6>
                </div>
                <div className='col d-flex justify-content-end'>
                  <div className='col flex justify-content-end'>
                    <span className='ms-4 svg-icon d-flex justify-content-center align-items-center svg-icon-white bg-success w-40px h-40px rounded-circle'>
                      <KTSVG path={toAbsoluteUrl('/media/icons/Custom/Active.svg')} />
                    </span>
                  </div>
                </div>
              </div>
              <div className='flex'>
                <img src={toAbsoluteUrl('/media/logos/EmptyState1.png')} />
              </div>
              <h6 className='m-0 flex m12'>No transactions</h6>
              <p className='m-0 flex text-center text-gray-600'>
                Intiate campaign to activate transactions
              </p>
              <div className='flex w-100'>
                <button className='btn fs-7 create-btn CompanyDetailButton m12 pt-2 w-100 h-35px ms-auto me-auto'>
                  Start Campaign
                </button>
              </div>
            </div>
          )} */}
        </div>
        {transData?.summary?.map((item, index) => (
          <div key={index} className='TransGraph bg-white rounded ms-6 m12 p-4 flex'>
            <div className='col'>
              <p className='m-0 text-gray-400 fs-7'>Pending</p>
              <h3 className='m-0'>₹ {item.status === 'pending' ? item.amount : ''}</h3>
            </div>
            <div className='col flex justify-content-end me-3'>
              <span className='ms-4 svg-icon d-flex justify-content-center align-items-center svg-icon-white bg-info w-40px h-40px rounded-circle'>
                <KTSVG path={toAbsoluteUrl('/media/icons/Custom/Recent.svg')} />
              </span>
            </div>
          </div>
        ))}
        {transData.summary?.map((item, index) => (
          <div key={index} className='TransGraph bg-white rounded ms-6 m12 p-4 flex'>
            <div className='col'>
              <p className='m-0 text-gray-400 fs-7'>On Hold</p>
              <h3 className='m-0'>₹ {item.status === 'On Hold' ? item.amount : 0}</h3>
            </div>
            <div className='col flex justify-content-end me-3'>
              <span className='ms-4 svg-icon d-flex justify-content-center align-items-center svg-icon-white bg-warning w-40px h-40px rounded-circle'>
                <KTSVG path={toAbsoluteUrl('/media/icons/Custom/Recent.svg')} />
              </span>
            </div>
          </div>
        ))}
        {transData.summary?.map((item, index) => (
          <div key={index} className='TransGraph bg-white rounded ms-6 m12 p-4 flex'>
            <div className='col'>
              <p className='m-0 text-gray-400 fs-7'>Cancelled</p>
              <h3 className='m-0'>₹ {item.status === 'Cancelled' ? item.amount : 0}</h3>
            </div>
            <div className='col flex justify-content-end me-3'>
              <span className='ms-4 svg-icon d-flex justify-content-center align-items-center svg-icon-white bg-danger w-40px h-40px rounded-circle'>
                <KTSVG path={toAbsoluteUrl('/media/icons/Custom/Recent.svg')} />
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
