import { RightOutlined } from '@ant-design/icons'
import { DatePicker, Image, message, Progress, Spin } from 'antd'
import clsx from 'clsx'
import Moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap-v5'
import ReactPlayer from 'react-player'
import { useHistory } from 'react-router-dom'
import Select from 'react-select'
import httpService from '../../setup/http/httpServices'
import { KTSVG, toAbsoluteUrl } from '../../_metronic/helpers'
import InfluencerList from './InfluencerList'
import { ChatInner } from '../../_metronic/partials'

export function CampaignContract() {
  const history: any = useHistory()
  const [contentList, setContentList] = useState([] as any)
  const [influencers, setInfluencers] = useState([] as any)
  const [contentDetails, setContentDetails] = useState([] as any)
  const [contentApproved, setContentApproved] = useState([] as any)
  const [selectedIteration, setSelectedIteration] = useState({} as any)

  const [postDate, setPostDate] = useState(null)
  const [selectedInfluencer, setSelectedInfluencer] = useState(null)
  const [selectedInfluencerId, setSelectedInfluencerId] = useState(null)
  const [isOpen, setOpen] = useState(false)
  const playerRef = React.useRef(null)
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [commentError, setCommentError] = useState('');


  const handleClick = (e: any, index: number) => {
    setContentDetails([])
    setContentList([])
    setSelectedInfluencerId(influencers[index]._id)
    setSelectedInfluencer(influencers[index])
    getContentDetails(influencers[index]._id)
  }

  const getContentLabel = () => {
    if (contentList) {
      const content = contentList.find((x) => x.label === contentDetails.label)
      setContentDetails({ ...content })
      setContentApproved(content.iterations.findIndex((x) => x.status === 'approved') > -1)
    }
  }
  useEffect(() => {
    let interval
    if (contentList) {
      interval = setInterval(() => {
        if (contentList && contentList.length > 0) {
          getContentLabel()
          getContentDetails(selectedInfluencer._id)
        }
      }, 5000)
    }

    // Check if all content iterations are approved
    const allIterationsApproved = contentDetails.iterations && contentDetails.iterations.length > 0
      ? contentDetails.iterations.every((iteration) => iteration.status === 'approved')
      : false;


    // If all iterations are approved, close the chat
    // if (allIterationsApproved) {
    //   setIsChatOpen(false);
    // } else {
    //   setIsChatOpen(true);
    // }
    getOnboardingDetails()

    if (history.location.state.otherDetails?.do) {
      setEditorState1(history.location.state.otherDetails?.do)
    }
    if (history.location.state.otherDetails?.dont) {
      setEditorState2(history.location.state.otherDetails?.dont)
    }
    if (history.location.state.otherDetails?.postProduction) {
      setEditorState3(history.location.state.otherDetails?.postProduction)
    }
    if (history.location.state.otherDetails?.platformPost) {
      setEditorState4(history.location.state.otherDetails?.platformPost)
    }
    return () => {
      clearInterval(interval)
    }
  }, [contentDetails])

  const getContentDetails = (id) => {
    httpService.getData(
      '/content-master/' + id,
      (response: any) => {
        /**
         * Below removes link field. to make it comparable
         */
        const originalContent = JSON.parse(JSON.stringify(response.data.data.contents))
        originalContent.map((x) => {
          x.iterations.map((iteration) => {
            delete iteration.link
          })
        })
        const oldContentList = JSON.parse(JSON.stringify(contentList))
        oldContentList.map((x) => {
          delete x.value
          delete x.label
          x.iterations.reverse().map((iteration) => {
            delete iteration.link
          })
        })

        response.data.data.contents.map((x) => {
          x.value = x._id
          x.label = x.title
          x.iterations.reverse()
        })

        if (JSON.stringify(oldContentList) !== JSON.stringify(originalContent)) {
          setContentList(response.data.data.contents)
        }
      },
      (error: any) => {
        message.error(error?.message)
      }
    )
  }
  const getOnboardingDetails = () => {
    httpService.getData(
      '/influencer-onboarding/' + history.location.state._id,
      (response: any) => {
        // setInfluencers([])
        let data: any = []
        response.data.data.influencers.shortListed.forEach((influencer: any) => {
          let addressObj: any = {}
          if (influencer.influencer.basicInfo.addressDetails.length > 0) {
            addressObj = influencer.influencer.basicInfo.addressDetails.find((x) => x.isDefault)
          }
          data.push({
            _id: influencer._id,
            photo: influencer.influencer.basicInfo.avatar,
            name: influencer.influencer.basicInfo.name,
            city: addressObj?.city,
            state: addressObj?.state,
            isPaid: influencer.isPaid,
            content: [],
          })
        })
        setInfluencers(data)
      },
      (error: any) => { }
    )
  }
  const [flag, setFlag] = useState(false)
  const [show, setShow] = useState(false)
  const [showModal2, setShowModal2] = useState(false)
  const [showModal3, setShowModal3] = useState(false)
  const [showModal4, setShowModal4] = useState(false)
  const handleClose = () => {
    setShow(false)
    setShowModal2(false)
    setShowModal3(false)
    setShowModal4(false)
    setOpen(false)
  }
  const [editorState1, setEditorState1] = useState('')
  const [editorState2, setEditorState2] = useState('')
  const [editorState3, setEditorState3] = useState('')
  const [editorState4, setEditorState4] = useState('')
  const [content, setContent] = useState<string>('')
  return (
    <div className='d-flex w-100 justify-content-between'>
      <div>
        <InfluencerList influencers={influencers} handleClick={handleClick} height='849px' />
      </div>
      {selectedInfluencer ? (
        <div className='w-100' style={{ padding: '20px' }}>
          <div
            style={{ backgroundColor: 'white', padding: '12px 24px', borderRadius: '12px' }}
            className='d-flex justify-content-between align-items-center'
          >
            <Select
              options={contentList}
              value={contentDetails}
              isMulti={false}
              placeholder='Post'
              styles={{}}
              className={'form-select p-0 categorySelect w-200px'}
              onChange={(e: any) => {
                setTimeout(() => {
                  setContentDetails({ ...e })
                }, 100)
              }}
            />

            {/* {contentDetails.title} */}
            {contentDetails.length === 0 ? (
              <></>
            ) : (
              <>
                <span className='d-flex align-items-center' style={{ width: '200px' }}>
                  <span style={{ marginRight: '16px' }}>
                    Iteration {contentDetails?.iterations?.length}/3
                  </span>
                  <span style={{ width: '100px' }}>
                    {/* {contentDetails?.iterations?.length > 0 && ( */}
                    <Progress
                      percent={
                        contentDetails?.iterations?.length > 0
                          ? parseInt(((contentDetails?.iterations?.length * 100) / 3).toFixed(2))
                          : 0
                      }
                      strokeColor='#2DCA73'
                    />
                    {/* )} */}
                  </span>
                </span>
              </>
            )}
          </div>
          <div className='d-flex flex-column'>
            <div className='d-flex flex-column w-100'>
              {contentDetails &&
                contentDetails?.iterations &&
                contentDetails?.iterations.length > 0 ? (
                contentDetails?.iterations.map((item: any, index: number) => (
                  <div className='basicDiv'>
                    <div
                      key={item._id}
                      className='d-flex border-bottom'
                      style={{ paddingBottom: '24px', marginBottom: '24px' }}
                    >
                      <div className='flex flex-column'>
                        {item.urlType === 'external' && (
                          <a href={item.link} target='self'>
                            <img
                              width={100}
                              src={toAbsoluteUrl('/media/icons/Custom/file.svg')}
                              alt='Google drive link'
                            />
                          </a>
                        )}
                        {item.urlType === 'internal' && contentDetails.type === 'photo' && (
                          <Image width={200} src={item.link} />
                        )}
                        {contentDetails.type === 'video' && (
                          <>
                            <ReactPlayer
                              ref={playerRef}
                              url={item.link}
                              className={'w-250px h-150px'}
                              controls
                            />
                          </>
                        )}

                        {/* <p style={{marginTop: '10px', fontSize: '12px', fontWeight: 600}}>
                          Click to view
                        </p> */}
                      </div>
                      <div className='d-flex flex-column w-100' style={{ marginLeft: '24px' }}>
                        <h2 className='fs-18'>
                          Iteration {contentDetails?.iterations.length - index}
                        </h2>
                        <p style={{ marginTop: '16px', marginBottom: '4px', fontSize: '14px' }}>
                          Comment:<span style={{color: 'red'}}>*</span>
                        </p>
                        <textarea
                          placeholder="Example: We would prefer an alternative angle for the product OR The photo needs to be taken in natural light only as per the campaign 'Dos and Don'ts'"
                          name='comment'
                          disabled={item.status === 'requestedChanges'}
                          value={item.reviewerComment}
                          onChange={(e) => {
                            let ind = contentDetails.iterations.findIndex((x) => x._id === item._id)
                            contentDetails.iterations[ind].reviewerComment = e.target.value
                            // contentDetails.iterations = contentDetails.iterations.reverse()
                            setContentList({ ...contentList })
                            getContentLabel()
                          }}
                          className='form-control form-control-solid bg-gray-100 h-100px'
                          style={{ fontSize: '14px', fontWeight: 400 }}
                          required
                        ></textarea>
                        {/* <button
                      className='btn create-btn d-flex flex fs-6'
                      onClick={() => {

                      }}
                      style={{ width: '135px', height: '34px', borderRadius: '6px', marginTop: '16px' }}
                    >
                      Submit
                    </button> */}
                      </div>
                    </div>
                    {/* {contentApproved && <div className='w-100 mb-16px'>
                      <Spin tip="Loading actions" size="small">
                        <div className="content" />
                      </Spin>
                    </div>} */}
                    {!contentApproved && (
                      <div className='d-flex w-100 mb-16px'>
                        {index === 0 && contentDetails?.iterations.length < 3 && (
                          <button
                            className='btn btn-gray-200 fw-bolder col me-4'
                            disabled={item.status === 'requestedChanges' || !item.reviewerComment?.trim()}
                            onClick={() => {
                              if (!item.reviewerComment?.trim()) {
                                setCommentError('Please fill in the comment field before requesting an iteration.');

                              } else {
                                setCommentError('');

                                httpService.putData(
                                  '/content-master/iteration/requestChanges/' +
                                  contentDetails._id +
                                  '/' +
                                  item._id,
                                  {
                                    reviewerComment: item.reviewerComment,
                                  },
                                  (response: any) => {
                                    getContentDetails(selectedInfluencer._id)
                                    message.success('Changes requested successfully')
                                  },
                                  (error: any) => {
                                    message.error(error?.message)
                                  }
                                )
                              }
                            }}
                          >
                            Request{item.status === 'requestedChanges' ? 'ed' : ''} Iteration
                          </button>

                        )}
                        {commentError && (
                          <p style={{ color: 'red' }}>{commentError}</p>
                        )}
                        <button
                          // disabled={item.status === "requestedChanges"}
                          className='btn bg-success text-white col'
                          onClick={() => {
                            setSelectedIteration(item)
                            getContentDetails(selectedInfluencer._id)
                            setShow(true)
                          }}
                        >
                          Approve & Schedule
                        </button>
                      </div>
                    )}

                    <div className='d-flex w-100'>
                      {/* <button className='btn btn-primary col'>View Submissions</button> */}
                    </div>
                  </div>
                ))
              ) : (
                <>
                  <div className='w-100 flex flex-column basicDiv' style={{ height: '620px' }}>
                    <div>
                      <img
                        alt='empty state'
                        src={toAbsoluteUrl('/media/logos/EmptyState2.png')}
                        style={{ width: '520px', height: '290px' }}
                      ></img>
                    </div>
                    <div>
                      <div>
                        <h2 className='mb-0 m24 flex'>
                          No {selectedInfluencer ? 'Option Selected' : 'Content Submitted'}.
                        </h2>
                        {/* <p
                          className='m-0 mt-5 ms-auto me-auto text-center'
                          style={{width: '383px'}}
                        >
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                          tempor incididunt ut labore et dolore magna aliquaLorem ipsum dolor sit
                          amet
                        </p> */}
                      </div>
                    </div>
                  </div>
                </>
              )}

              {isChatOpen && (
                <Modal
                  show={show}
                  onHide={handleClose}
                  aria-labelledby='contained-modal-title-vcenter'
                  centered
                  dialogClassName='modal-width'
                >
                  <ChatInner />
                  {/* Modal content here */}
                </Modal>
              )}

            </div>
          </div>
        </div>
      ) : (
        <div className='w-100' style={{ padding: '0px 20px' }}>
          <div className='w-100 flex flex-column basicDiv' style={{ height: '620px' }}>
            <div>
              <img
                alt='Empty state'
                src={toAbsoluteUrl('/media/logos/EmptyState2.png')}
                style={{ width: '520px', height: '290px' }}
              ></img>
            </div>
            <div>
              <div>
                <h2 className='mb-0 m24 flex'>No influencer selected.</h2>
                {/* <p className='m-0 mt-5 ms-auto me-auto text-center' style={{width: '383px'}}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                  incididunt ut labore et dolore magna aliquaLorem ipsum dolor sit amet
                </p> */}
              </div>
            </div>
          </div>
        </div>
      )}
      {contentDetails && contentDetails?.iterations?.length > 0 && (
        <div className='d-flex flex-column' style={{ marginRight: '24px' }}>
          {selectedInfluencer && (
            <div className='d-flex flex-column basicDiv' style={{ width: '359px' }}>
              <div className='d-flex justify-content-between align-items-center'>
                <h2 className='fs-16'>{selectedInfluencer?.name}'s Content Status</h2>
                <img
                  onClick={() => {
                    history.push({
                      pathname: '/campaign/influencerOnborading/' + `${history.location.state._id}`,
                      state: {
                        ...history.location.state,
                        ...{ type: 'influencerOnboard', status: 'Communicate' },
                        ...{ breadcrums: ['Campaigns', 'Influencer Onboarding'] },
                      },
                    })
                  }}
                  alt='content status'
                  src={toAbsoluteUrl('/media/logos/ChatIcon.png')}
                  width='40px'
                  height='40px'
                  className='pointer'
                />
              </div>
              <div style={{ marginTop: '24px' }}>
                <div
                  className='d-flex justify-content-between border-bottom'
                  style={{ paddingBottom: '12px', marginBottom: '12px' }}
                >
                  <div className='d-flex flex-column'>
                    <span style={{ fontSize: '14px', fontWeight: 600 }}>
                      {contentDetails?.contentType?.replace(/(\w)(\w*)/g, function (g0, g1, g2) {
                        return g1.toUpperCase() + g2.toLowerCase()
                      })}
                    </span>
                    {contentDetails?.createdAt && (
                      <span>
                        {Moment(contentDetails?.createdAt).format('DD MMM YYYY | HH:mm A')}
                      </span>
                    )}
                  </div>
                  {contentDetails.iterations?.findIndex((x) => x.status === 'approved') > -1 ? (
                    <span
                      className='bg-success svg-icon svg-icon-success text-success bg-opacity-20 p-2 pt-2 pb-2 rounded'
                      style={{ height: 'fit-content' }}
                    >
                      <KTSVG path={toAbsoluteUrl('/media/icons/Custom/Active.svg')} />
                      <span style={{ marginLeft: '8px' }}>Approved</span>
                    </span>
                  ) : contentDetails.iterations?.findIndex((x) => x.status === 'requestedChanges') >
                    -1 ? (
                    <span
                      className='bg-warning svg-icon svg-icon-warning text-warning bg-opacity-20 p-2 pt-2 pb-2 rounded'
                      style={{ height: 'fit-content' }}
                    >
                      <KTSVG path={toAbsoluteUrl('/media/icons/Custom/Active.svg')} />
                      <span style={{ marginLeft: '8px' }}>Changes awaited</span>
                    </span>
                  ) : contentDetails.iterations?.findIndex((x) => x.status === 'underReview') >
                    0 ? (
                    <span
                      className='bg-warning svg-icon svg-icon-warning text-warning bg-opacity-20 p-2 pt-2 pb-2 rounded'
                      style={{ height: 'fit-content' }}
                    >
                      <KTSVG path={toAbsoluteUrl('/media/icons/Custom/Active.svg')} />
                      <span style={{ marginLeft: '8px' }}>Under Review</span>
                    </span>
                  ) : (
                    <span
                      className='bg-warning svg-icon svg-icon-warning text-warning bg-opacity-20 p-2 pt-2 pb-2 rounded'
                      style={{ height: 'fit-content' }}
                    >
                      <KTSVG path={toAbsoluteUrl('/media/icons/Custom/Active.svg')} />
                      <span style={{ marginLeft: '8px' }}>Pending</span>
                    </span>
                  )}
                </div>
              </div>
            </div>
          )}
          <div className='d-flex flex-column basicDiv' style={{ width: '359px' }}>
            <h2 className='fs-16'>Campaign Guidelines</h2>
            <div className='d-flex flex-column m16'>
              <div
                className='d-flex justify-content-between border-bottom pointer'
                style={{ paddingBottom: '12px', marginBottom: '12px' }}
                onClick={() => setShowModal2(true)}
              >
                <span className='fs-14 fw-bold'>Do's and Dont's</span>
                <RightOutlined />
              </div>
              <div
                className='d-flex justify-content-between border-bottom pointer'
                style={{ paddingBottom: '12px', marginBottom: '12px' }}
                onClick={() => setShowModal3(true)}
              >
                <span className='fs-14 fw-bold'>Post Production</span>
                <RightOutlined />
              </div>
              <div
                className='d-flex justify-content-between pointer'
                style={{ paddingBottom: '12px', marginBottom: '12px' }}
                onClick={() => setShowModal4(true)}
              >
                <span className='fs-14 fw-bold'>Platform Test</span>
                <RightOutlined />
              </div>
            </div>
          </div>
        </div>
      )}

      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby='contained-modal-title-vcenter '
        centered
        dialogClassName='modal-width'
      >
        <Modal.Body>
          <div className='d-flex flex-column'>
            <h2 className='fs-16'>Set Post Date</h2>
            <h3 className='m24'>Content:{contentDetails?.type}</h3>
            <span style={{ fontSize: '16px' }}>
              Approved content requires a post date that will be applicable for mentioned content.{' '}
            </span>
            <label className='m16' style={{ marginBottom: '4px', fontSize: '14px' }}>
              Select Date*
            </label>
            <DatePicker
              showTime={true}
              showSecond={false}
              placeholder='Campaign Live Date'
              disabledDate={(current: any) => {
                // Can not select days before today and today
                const liveDate = Moment(
                  history.location.state.campaignDetails.liveDate || new Date().toISOString()
                );
                const campaignEndDate = Moment(
                  history.location.state.campaignDetails.campaignEndDate || new Date().toISOString()
                ).add(1, 'days');
                return (current < liveDate || current > campaignEndDate)
              }}
              name='postDate'
              format={'YYYY-MM-DD HH:mm'}
              className={clsx(
                'border-0 form-control form-control-solid w-100 bg-gray-100 border-radius',
                {
                  // 'is-invalid': formik.touched.liveDate && formik.errors.liveDate,x
                },
                {
                  // 'is-valid': formik.touched.liveDate && !formik.errors.liveDate,
                }
              )}
              onChange={(date, dateString) => setPostDate(Moment(date).format('YYYY-MM-DD HH:mm'))}
            />
            {/* <input
              placeholder='placeholder'
              className='form-control form-control-solid bg-gray-100'
            ></input> */}
            <button
              className='btn create-btn m24'
              onClick={() => {
                console.log(selectedIteration)
                httpService.putData(
                  '/content-master/iteration/approveContent/' +
                  contentDetails._id +
                  '/' +
                  selectedIteration._id,
                  {
                    reviewerComment: selectedIteration.reviewerComment,
                  },
                  (response: any) => {
                    setSelectedIteration({})
                    httpService.putData(
                      '/content-master/schedule-post/' + contentDetails._id,
                      {
                        postDate: postDate,
                      },
                      (response: any) => {
                        setShow(false)
                        message.success('Post approved and scheduled successfully.')
                        // history.push({
                        //   pathname: '/scheduler/calendar',
                        //   state: { type: 'schedular/calendar/week', status: 'week' },
                        // });
                      },
                      (error: any) => {
                        setShow(false)
                        message.error(error.message)
                      }
                    )
                  },
                  (error: any) => { }
                )
              }}
              disabled={!postDate}
            >
              Add to Scheduler
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showModal2}
        onHide={handleClose}
        aria-labelledby='contained-modal-title-vcenter '
        centered
        data-backdrop='true'
      >
        <Modal.Body className='p-0 '>
          <div className='d-flex flex-column' style={{ width: '754px' }}>
            <div className=' w-100 bg-white rounded p-8'>
              <h2>Do's and Dont's</h2>
              <p className='m24 fs-14'>Do's</p>
              <div className='card-body bg-gray-100 m-2 h-150px'>
                <div dangerouslySetInnerHTML={{ __html: editorState1 }} />
              </div>

              <p className='m8 fs-14'>Dont's</p>
              <div className='card-body bg-gray-100 m-2 h-150px'>
                <div dangerouslySetInnerHTML={{ __html: editorState2 }} />
              </div>

              <div className='d-flex w-100 m20'>
                <button className='btn create-btn col' onClick={() => setShowModal2(false)}>
                  Close
                </button>
                {/* <button className='btn create-btn col'>Save Changes</button> */}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showModal3}
        onHide={handleClose}
        aria-labelledby='contained-modal-title-vcenter '
        centered
        data-backdrop='true'
      >
        <Modal.Body className='p-0 '>
          <div className='d-flex flex-column' style={{ width: '754px' }}>
            <div className=' w-100 bg-white rounded p-8'>
              <h2>Post Production</h2>
              <div className='m20'>
                <div className='card-body bg-gray-100 m-2 h-150px'>
                  <div dangerouslySetInnerHTML={{ __html: editorState3 }} />
                </div>
              </div>
              <div className='d-flex w-100 m20'>
                <button className='btn create-btn col' onClick={() => setShowModal3(false)}>
                  Close
                </button>
                {/* <button className='btn create-btn col'>Save Changes</button> */}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showModal4}
        onHide={handleClose}
        aria-labelledby='contained-modal-title-vcenter '
        centered
        data-backdrop='true'
      >
        <Modal.Body className='p-0 '>
          <div className='d-flex flex-column' style={{ width: '754px' }}>
            <div className=' w-100 bg-white rounded p-8'>
              <h2>Platform Post</h2>
              <div className='m20'>
                <div className='card-body bg-gray-100 m-2 h-150px'>
                  <div dangerouslySetInnerHTML={{ __html: editorState4 }} />
                  {/*Dont's  Data call from api */}
                </div>
              </div>
              <div className='d-flex w-100 m20'>
                <button className='btn create-btn col' onClick={() => setShowModal4(false)}>
                  Close
                </button>
                {/* <button className='btn create-btn col'>Save Changes</button> */}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
