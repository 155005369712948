import {SearchOutlined} from '@ant-design/icons'
import {Input, message, Pagination} from 'antd'
import Moment from 'moment'
import React, {useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap-v5'
import {shallowEqual, useSelector} from 'react-redux'
import {Link, useHistory} from 'react-router-dom'
import httpService from '../../setup/http/httpServices'
import {RootState} from '../../setup/redux/RootReducer'
import {KTSVG, toAbsoluteUrl} from '../../_metronic/helpers'
import '../masters/basic.css'
import {IUserModel} from '../modules/auth/models/UserModel'

export function ProfileTable() {
  const user: IUserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as IUserModel
  const access = user.permissions.adminSettings[1] || user.permissions.admin

  const [sort1, setSort1] = useState('rotate-90 ms-2')
  const [sort2, setSort2] = useState('rotate-90 ms-2')
  const [sort3, setSort3] = useState('rotate-90 ms-2')
  const [sort4, setSort4] = useState('rotate-90 ms-2')
  const [asc, setAsc] = useState('firstName')
  const [show, setShow] = useState(false)
  const [status, setStatus] = useState(true)

  const history: any = useHistory()
  const handleClose = () => setShow(false)
  const [agencyListParams, setAgencyListParams] = useState({
    limit: 10,
    page: 1,
    total: 0,
    search: '',
  })
  const [total, setTotal] = useState(0)
  const [deleteId, setDeleteId] = useState('')
  const [userList, setUserList] = useState<any>([])
  const key = 'updatable'
  const handleDelete = () => {
    setShow(false)
    message.loading({content: 'Loading...', key})
    httpService.deleteData(
      '/user/' + deleteId,
      (res: any) => {
        setTimeout(() => {
          message.success({content: 'User access has been revoked.', key, duration: 2})
          setDeleteId('')
        }, 1000)
      },
      (err: any) => {
        message.error({content: err.message, key, duration: 2})
        setDeleteId('')
      }
    )
  }
  const Sorting1 = () => {
    if (sort1 == 'rotate-90 ms-2') {
      setSort1('rotate-270 ms-2')
    } else {
      setSort1('rotate-90 ms-2')
    }
    if (sort1 == 'rotate-90 ms-2') {
      setSort4('rotate-90 ms-2')
      setSort3('rotate-90 ms-2')
      setSort2('rotate-90 ms-2')
      setAsc('-firstName')
    } else {
      setSort4('rotate-90 ms-2')
      setSort3('rotate-90 ms-2')
      setSort2('rotate-90 ms-2')
      setAsc('firstName')
    }
  }
  const Sorting2 = () => {
    if (sort2 == 'rotate-90 ms-2') {
      setSort2('rotate-270 ms-2')
    } else {
      setSort2('rotate-90 ms-2')
    }
    if (sort2 == 'rotate-90 ms-2') {
      setSort4('rotate-90 ms-2')
      setSort3('rotate-90 ms-2')
      setSort1('rotate-90 ms-2')
      setAsc('-role')
    } else {
      setSort4('rotate-90 ms-2')
      setSort3('rotate-90 ms-2')
      setSort1('rotate-90 ms-2')
      setAsc('role')
    }
  }
  const Sorting3 = () => {
    if (sort3 == 'rotate-90 ms-2') {
      setSort3('rotate-270 ms-2')
    } else {
      setSort3('rotate-90 ms-2')
    }
    if (sort3 == 'rotate-90 ms-2') {
      setSort4('rotate-90 ms-2')
      setSort1('rotate-90 ms-2')
      setSort2('rotate-90 ms-2')
      setAsc('isActive')
    } else {
      setSort4('rotate-90 ms-2')
      setSort1('rotate-90 ms-2')
      setSort2('rotate-90 ms-2')
      setAsc('-isActive')
    }
  }
  const Sorting4 = () => {
    if (sort4 == 'rotate-90 ms-2') {
      setSort4('rotate-270 ms-2')
    } else {
      setSort4('rotate-90 ms-2')
    }
    if (sort4 == 'rotate-90 ms-2') {
      setSort1('rotate-90 ms-2')
      setSort3('rotate-90 ms-2')
      setSort2('rotate-90 ms-2')
      setAsc('-updatedAt')
    } else {
      setSort1('rotate-90 ms-2')
      setSort3('rotate-90 ms-2')
      setSort2('rotate-90 ms-2')
      setAsc('updatedAt')
    }
  }
  const handleEdit = (id: string) => {}

  useEffect(() => {
    getUsers()
  }, [agencyListParams.search, agencyListParams.page, asc])
  const getUsers = () => {
    httpService.getData(
      '/user?page=' +
        agencyListParams.page +
        '&limit=' +
        agencyListParams.limit +
        '&search=' +
        agencyListParams.search +
        '&sort=' +
        asc,
      (response: any) => {
        setUserList(response.data.data.users)
        setTotal(response.data.data.total)
        setStatus(false)
      },
      (error: any) => {
        setStatus(false)
      }
    )
  }

  return (
    <>
      <div className='TableHeader w-100'>
        <div className='mt-3 min-w-150px'>
          <h3 style={{cursor: 'default'}}>Users</h3>
          <p style={{cursor: 'default'}} className='text-gray-500 fs-7'>
            {total} Users
          </p>
        </div>
        <div className='TableHeaderSearch d-flex align-items-center justify-content-start'>
          <link
            href='https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css'
            rel='stylesheet'
          />
          <Input
            placeholder='Search'
            className='form-control-solid bg-gray-100 rounded w-250px h-40px'
            onChange={(e: any) =>
              setAgencyListParams({...agencyListParams, ...{search: e.target.value}})
            }
            prefix={
              <>
                <SearchOutlined />
              </>
            }
          />
        </div>
        <div className='flex min-w-200px justify-content-end me-4'>
          {access && (
            <Link
              to={{
                pathname: '/adminSettings/profileManagerSetting',
                state: history.location.state,
              }}
            >
              <button className='btn create-btn d-flex flex justify-content-center me-2 h-35px fs-7 ms-3'>
                Add User ☝️
              </button>
            </Link>
          )}
        </div>
      </div>
      <div className='card-body mt-0 p-0 d-flex flex-column justify-content-between'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder text-muted'>
                {/* <th className='bg-primary bg-opacity-5  w-25px'>
                  <div className='form-check ms-5 form-check-sm form-check-custom form-check-solid'>
                  </div>
                </th> */}
                <th className='bg-primary bg-opacity-5 min-w-190px '>
                  <span className='d-flex ms-5'>
                    User
                    <a
                      className={sort1}
                      onClick={() => {
                        Sorting1()
                      }}
                    >
                      <KTSVG
                        className='svg-icon svg-icon-sm svg-icon-gray-700 flex h-20px pointer'
                        path={toAbsoluteUrl('/media/icons/Custom/SideArrow.svg')}
                      />
                    </a>
                  </span>
                </th>

                <th className='bg-primary bg-opacity-5 min-w-120px'>
                  <span className='d-flex'>
                    Designation
                    <a
                      className={sort2}
                      onClick={() => {
                        Sorting2()
                      }}
                    >
                      <KTSVG
                        className='svg-icon svg-icon-sm svg-icon-gray-700 flex h-20px pointer'
                        path={toAbsoluteUrl('/media/icons/Custom/SideArrow.svg')}
                      />
                    </a>
                  </span>
                </th>
                <th className='bg-primary bg-opacity-5 min-w-110px'>
                  <span className='d-flex'>
                    Status
                    <a
                      className={sort3}
                      onClick={() => {
                        Sorting3()
                      }}
                    >
                      <KTSVG
                        className='svg-icon svg-icon-sm svg-icon-gray-700 flex h-20px pointer'
                        path={toAbsoluteUrl('/media/icons/Custom/SideArrow.svg')}
                      />
                    </a>
                  </span>
                </th>
                <th className='Added bg-primary bg-opacity-5 min-w-150px'>
                  <span className='d-flex'>
                    Added
                    <a
                      className={sort4}
                      onClick={() => {
                        Sorting4()
                      }}
                    >
                      <KTSVG
                        className='svg-icon svg-icon-sm svg-icon-gray-700 flex h-20px pointer'
                        path={toAbsoluteUrl('/media/icons/Custom/SideArrow.svg')}
                      />
                    </a>
                  </span>
                </th>
                {access && (
                  <th className='bg-primary bg-opacity-5 min-w-100px d-flex justify-content-end'>
                    Actions
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {userList.length > 0 &&
                userList.map((data: any) => (
                  <tr>
                    <td className='fw-bolder'>
                      <div className='d-flex align-items-center ms-5'>
                        <div className='symbol symbol-35px symbol-circle me-5'>
                          {/* {data?.agencyDetails &&<img src={data?.agencyDetails.avatar} alt='' />} */}
                          {data.avatar?<img src={data.avatar} alt='' />:<img src={toAbsoluteUrl('/media/logos/influencers.png')} alt='' />}
                        </div>
                        <div className='d-flex justify-content-start flex-column'>
                          <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
                            {data?.firstName} {data?.lastName}
                          </a>
                        </div>
                      </div>
                    </td>
                    <td className='fw-bold'>{data?.role?.role}</td>
                    <td className='fw-bold'>
                      <span
                        className={
                          data?.isActive
                            ? 'text-success bg-success bg-opacity-20 fs-7 rounded p-2 pt-1 pb-1'
                            : 'text-warning bg-warning bg-opacity-20 fs-7 rounded p-2 pt-1 pb-1'
                        }
                      >
                        {data?.isActive ? 'Active' : 'Inactive'}
                      </span>
                    </td>
                    <td className='Added'>
                      <a href='#' className='text-dark fw-bolder text-hover-primary d-block fs-6'>
                        {Moment(data?.updatedAt).format('DD MMMM YYYY')}
                      </a>
                      <span className='text-muted fw-bold text-muted d-block fs-7'>
                        {data?.modifiedBy?.firstName} {data?.modifiedBy?.lastName}
                      </span>
                    </td>
                    {access && (
                      <td className='d-flex justify-content-end'>
                        <div className='d-flex justify-content-start flex-shrink-0'>
                          <Link
                            to={{
                              pathname: '/adminSettings/profileManagerSetting',
                              state: {...history.location.state, ...{id: data?._id}},
                            }}
                          >
                            <span className='svg-icon flex svg-icon-dark bg-primary bg-opacity-5 w-30px h-30px rounded pointer'>
                              <KTSVG
                                className='svg-icon-3'
                                path={toAbsoluteUrl('/media/icons/Custom/Pen.svg')}
                              />
                            </span>
                          </Link>
                          {/* <span
                      className='svg-icon flex svg-icon-dark bg-primary bg-opacity-5 w-30px h-30px rounded me-2 pointer'
                    >
                      <KTSVG
                        className='svg-icon-3'
                        path={toAbsoluteUrl('/media/icons/Custom/Setting.svg')}
                      />
                    </span> */}
                          {data?.isActive && (
                            <span
                              onClick={() => {
                                setShow(true)
                                setDeleteId(data?._id)
                              }}
                              className='svg-icon flex svg-icon-danger bg-danger bg-opacity-5 w-30px h-30px rounded pointer ms-2'
                            >
                              <KTSVG
                                className='svg-icon-3'
                                path={toAbsoluteUrl('/media/icons/Custom/Dustbin.svg')}
                              />
                            </span>
                          )}
                          <Modal
                            show={show}
                            onHide={handleClose}
                            aria-labelledby='contained-modal-title-vcenter'
                            centered
                          >
                            <Modal.Header>
                              <Modal.Title>Disable Access</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              This will disable user's access to the system. Are you sure you wanna
                              inactive user?
                            </Modal.Body>
                            <Modal.Footer>
                              <button
                                className='btn fw-bolder btn-gray-200 fs-6 dialogButton'
                                onClick={() => {
                                  setShow(false)
                                  setDeleteId('')
                                }}
                              >
                                Cancel
                              </button>
                              <button
                                className='btn create-btn fs-6 dialogButton ms-2'
                                onClick={() => handleDelete()}
                              >
                                Inactive
                              </button>
                            </Modal.Footer>
                          </Modal>
                        </div>
                      </td>
                    )}
                  </tr>
                ))}
              {userList.length === 0 && !status && (
                <tr>
                  <td colSpan={7}>
                    <div className='m-20'>
                      <div className='flex'>
                        <img
                          className='ms-20'
                          src={toAbsoluteUrl('/media/logos/TableVector.png')}
                        ></img>
                      </div>
                      <div className='flex'>
                        <div>
                          <h2 className='mb-0 m24 flex'>No Users Listed</h2>
                        </div>
                      </div>
                      <div className='flex'>
                        {access && (
                          <Link
                            to={{
                              pathname: '/companyDetails',
                              //   state: {type: pageType},
                            }}
                          >
                            <button className='btn m24 TableButton create-btn text-white'>
                              Create New User ☝️
                            </button>
                          </Link>
                        )}
                      </div>
                    </div>
                  </td>
                </tr>
              )}
              {userList.length === 0 && !status && (
                <tr>
                  <td colSpan={7}>
                    <div className='m-20'>
                      <div className='flex'>
                        <span className='svg-icon svg-icon-5x flex svg-icon-primary me-3 ms-8'>
                          <KTSVG path={toAbsoluteUrl('/media/icons/Custom/Loading.svg')} />
                        </span>
                      </div>
                      <div className='flex'>
                        <div>
                          <h5 className='mb-0 mt-3 flex'>Fetching Data</h5>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>

            {/* end::Table body */}
          </table>

          {/* end::Table */}
        </div>
        <div className='d-flex ms-5 me-10 justify-content-between align-items-end flex-wrap mb-10'>
          <span className='text-muted pb-2'>
            Page {agencyListParams.page} of{' '}
            {Math.floor(total % agencyListParams.limit) === 0
              ? Math.floor(total / agencyListParams.limit)
              : Math.floor(total / agencyListParams.limit) + 1}
          </span>
          <div className='d-flex flex-wrap '>
            <Pagination
              className=''
              pageSize={agencyListParams.limit}
              defaultCurrent={agencyListParams.page}
              total={total}
              onChange={(e) => setAgencyListParams({...agencyListParams, ...{page: e}})}
            />
          </div>
        </div>
        {/* end::Table container */}
      </div>
    </>
  )
}
