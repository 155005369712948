import React, {useEffect, useState} from 'react'
import Select from 'react-select'
import {KTSVG, toAbsoluteUrl} from '../../_metronic/helpers'
import {Accordion, Modal} from 'react-bootstrap'
import httpService from '../../setup/http/httpServices'
import {useHistory} from 'react-router-dom'

export function Replace(propsL: any) {
  const [show, setShow] = useState(false)
  const history: any = useHistory()
  const [shortListedInfluencerList, setShortlistedInfluencerList] = useState([])
  const [standbyInfluencerList, setStandbyInfluencerList] = useState([])
  const [shortlistedInfluencer, setShortListedInfluencer] = useState(null)
  const [standbyInfluencer, setStandbyInfluencer] = useState(null)
  const [reasonForReplace, setReasonForReplace] = useState('')

  const getInfluencer = (x: any) => {
    return {
      label: (
        <div>
          {x.influencer.basicInfo.avatar?<img className='rounded-circle' src={x.influencer.basicInfo.avatar} height='50px' width='50px' />:<span
                    className='fs-1 pt-2 symbol btn-primary w-50px h-50px imageBadge rounded-circle'
                    style={{fontSize: 26}}>
                    {x.influencer.basicInfo.name[0].toUpperCase()}
                  </span>}{' '}
          {x.influencer.basicInfo.name}
        </div>
      ),
      value: x._id,
    }
  }
  useEffect(() => {
    getList()
  }, [])
  function getList() {
    httpService.getData(
      '/influencer-onboarding/' + history.location.state._id,
      (response: any) => {
        let data = response.data.data
        setShortlistedInfluencerList(data?.influencers?.shortListed)
        setStandbyInfluencerList(data?.influencers?.onHold)
      },
      (error: any) => {}
    )
  }
  const resetForm = () => {
    setShow(false)
    setShortListedInfluencer(null)
    setStandbyInfluencer(null)
    setReasonForReplace('')
    getList()
  }

  return (
    <div className='d-flex'>
      <div className='w-75 ms-5'>
        <div className='basicDiv d-flex'>
          <div className='w-400px'>
            <h3 className=''>Select Shortlisted Influencer</h3>
            <p className='m-0 '>
              This selection lets you choose the shortlisted influencer you would like to replace.
            </p>
          </div>
          <div className='w-75 d-flex justify-content-end'>
            <div className='w-50'>
              <label className='required mb-1'>Shortlisted Influencer</label>
              <Select
                options={shortListedInfluencerList.map((x) => getInfluencer(x))}
                isMulti={false}
                value={shortlistedInfluencer}
                placeholder={
                  <div>
                    <img
                      src={toAbsoluteUrl('/media/logos/categoryIcon.png')}
                      height='50px'
                      width='50px'
                    />{' '}
                    Please Select{' '}
                  </div>
                }
                styles={{
                  menuList: (provided, state) => ({
                    ...provided,
                    height: 'auto',
                    maxHeight: '300px',
                  }),
                }}
                className={'form-select p-0 categorySelect me-5 w-100'}
                onChange={(e: any) => {
                  setShortListedInfluencer(e)
                }}
              />
            </div>
          </div>
        </div>
        <div className='basicDiv d-flex'>
          <div className='w-400px'>
            <h3 className=''>Select Standby Influencer</h3>
            <p className='m-0 '>
              This selection lets you choose the standby influencer as a replacement for the
              shortlisted influencer from the same category.
              <br></br>
              <b>Example:</b> A Micro Influencer can be replaced by only a Micro Influencer and so
              on.
            </p>
          </div>
          <div className='w-75 d-flex justify-content-end'>
            <div className='w-50'>
              <label className='required mb-1'>Standby Influencer</label>
              <Select
                options={standbyInfluencerList.map((x) => getInfluencer(x))}
                isMulti={false}
                value={standbyInfluencer}
                placeholder={
                  <div>
                    <img
                      src={toAbsoluteUrl('/media/logos/categoryIcon.png')}
                      height='50px'
                      width='50px'
                    />{' '}
                    Please Select{' '}
                  </div>
                }
                styles={{
                  menuList: (provided, state) => ({
                    ...provided,
                    height: 'auto',
                    maxHeight: '300px',
                  }),
                }}
                className={'form-select p-0 categorySelect me-5 w-100'}
                onChange={(e: any) => {
                  setStandbyInfluencer(e)
                }}
              />
              <label className='required mt-5 mb-1'>Reason for Replacement</label>
              <textarea
                placeholder='Reason for Replacement'
                className='form-control form-control-solid bg-gray-100 h-70px'
                onChange={(e) => {
                  setReasonForReplace(e.target.value)
                }}
              />
            </div>
          </div>
        </div>
        <div className='w-100 d-flex justify-content-end'>
          <button
            disabled={!(shortlistedInfluencer && standbyInfluencer && reasonForReplace)}
            onClick={() => {
              httpService.putData(
                '/influencer-onboarding/replace-influencer',
                {
                  shortListed: shortlistedInfluencer.value,
                  standby: standbyInfluencer.value,
                },
                (response: any) => {
                  setShow(true)
                  // setShortListedInfluencer(null)
                  // setStandbyInfluencer(null)
                },
                (error: any) => {}
              )
            }}
            className='btn btn-primary w-500px h-45px mt-5 fs-6'
          >
            Replace
          </button>
        </div>
      </div>
      <div className='w-25'>
        <div className='basicDiv ms-5 me-5 d-flex'>
          <KTSVG
            className='svg-icon svg-icon-dark h-20px mt-2 bg-gray-100 me-3 rounded-circle'
            path={toAbsoluteUrl('/media/icons/Custom/Information.svg')}
          />
          <p className='m-0 fs-5'>
            Based on your selection, you can replace 7 influencers with the standby influencer
            belonging from the same category.
          </p>
        </div>
      </div>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        aria-labelledby='contained-modal-title-vcenter '
        centered
      >
        <Modal.Body className='p-0 '>
          <div className='basicDiv m-0'>
            <h3 className='m-0 d-flex'>
              <KTSVG
                className='svg-icon svg-icon-sm svg-icon-gray-700 flex h-20px pointer'
                path={toAbsoluteUrl('/media/icons/Custom/replace.svg')}
              />
              &nbsp;&nbsp; Replacement Successful
            </h3>
            <p className='mb-0 m24'>
              Awesome, you’ve successfully replaced shortlisted influencer with the standby
              influencer for your campaign
            </p>
            <div className='m24 bg-gray-100 p-5'>
              {shortlistedInfluencer && shortlistedInfluencer.label}
            </div>
            <div className='m16 bg-gray-100 p-5'>
              {standbyInfluencer && standbyInfluencer.label}
            </div>
            <div className='d-flex m24 w-100'>
              <button
                onClick={() => {
                  resetForm()
                }}
                className='btn fw-bolder btn-gray-200 w-100 h-50px'
              >
                Cancel
              </button>

              <button
                type='submit'
                id='kt_sign_up_submit'
                className='btn btn-primary ms-5 w-100 h-50px'
                onClick={() => {
                  history.push({
                    pathname: '/campaign/influencerOnborading/' + `${history.location.state._id}`,
                    state: {
                      ...history.location.state,
                      ...{type: 'influencerOnboard', status: 'Communicate'},
                    },
                  })
                }}
              >
                Go to Chat
              </button>
            </div>
            <div
              className='d-flex justify-content-center m24 pointer'
              onClick={() => {
                resetForm()
                // history.push({
                //   pathname: '/campaign/influencerOnborading/' + `${history.location.state._id}`, state: {
                //     ...history.location.state,
                //     ...{ type: 'influencerOnboard', status: "Replace" }
                //   }
                // })
              }}
            >
              <b>Replace another Influencer</b>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
