import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const InfluencerForm = ({ influencer, onSubmit }) => {
  const checkoutSchema = Yup.object().shape({
    productLink: Yup.string().required('Product Link is required'),
    promoCode: Yup.string().required('Promo Code is required'),
  });

  var initialFormValues = {productLink: '',promoCode: ''}

  if (influencer?.shipping?.selfCheckout?.productLink && influencer?.shipping?.selfCheckout?.promoCode) {
    initialFormValues = influencer.shipping.selfCheckout
  }

  const checkoutFormik = useFormik({
    initialValues: initialFormValues,
    validationSchema: checkoutSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      onSubmit(influencer, values);
    },
  });

  return (
    <form noValidate onSubmit={checkoutFormik.handleSubmit}>
      <h6 className='fw-bold required m24'>Product Link</h6>
      <input
        type='text'
        {...checkoutFormik.getFieldProps('productLink')}
        placeholder='Product Link'
        className='form-control form-control-solid bg-gray-100'
      />
      <h6 className='fw-bold required m16'>Promotional Code</h6>
      <input
        type='text'
        {...checkoutFormik.getFieldProps('promoCode')}
        placeholder='Promotional Code'
        className='form-control form-control-solid bg-gray-100'
      />
      <button type='submit' className='btn btn-primary dialogButton w-100 h-50px m20'>
        Update Details
      </button>
    </form>
  );
};

export default InfluencerForm;
