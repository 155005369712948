import React, {useState} from 'react'
import {Link, useHistory} from 'react-router-dom'
import Moment from 'moment'
import {KTSVG, toAbsoluteUrl} from '../../_metronic/helpers'
import {CampaginById} from './CampaginById'
import {InfluencerDetailById} from './InfluencerDetailById'
import {OtherDetailById} from './OtherDetailById'
import httpService from '../../setup/http/httpServices'
import {message} from 'antd'
import {Modal} from 'react-bootstrap-v5'
import {IUserModel} from '../modules/auth/models/UserModel'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../setup/redux/RootReducer'
import moment from 'moment'
import './campaign.scss'

export function CampaignDetailById() {
  const user: IUserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as IUserModel

  const history: any = useHistory()
  const [influencer, setInfluencer] = useState(true)
  const [campaign, setCampaign] = useState(false)
  const [other, setOther] = useState(false)
  // var data = history.location.state
  const [campaignDetails, setCampaignDetails] = useState(history.location.state)

  const [deleteId, setDeleteId] = useState('')
  const [show, setShow] = useState(false)
  const [showR, setShowR] = useState(false)
  const [cnameR, setCnameR] = useState(false)
  const key = 'updatable'

  const updateCampaignStatus = (status: string) => {
    httpService.putData(
      '/campaign-master/status/' + campaignDetails._id + '/' + status,
      {},
      (response: any) => {
        message.success('Campaign status updated to ' + status)
        campaignDetails.status = status
        campaignDetails.campaignStatus = status
        setCampaignDetails({...campaignDetails})
      },
      (error: any) => {
        message.error(error?.message)
      }
    )
  }
  const handleEdit = () => {
    let pathname = '/campaign/details'
    if (campaignDetails.stateOverview.step2 === false) {
      pathname = '/campaign/influencerDetails'
    } else if (campaignDetails.stateOverview.step3 === false) {
      pathname = '/campaign/audienceDetails'
    } else if (campaignDetails.stateOverview.step4 === false) {
      pathname = '/campaign/otherDetails'
    }
    history.push({
      pathname: pathname,
      state: {
        ...campaignDetails,
        _id: campaignDetails?._id,
        // ...{type: 'campaignDetails'},
        ...{
          type: campaignDetails.campaignDetails.name,
          breadcrums: ['Campaigns', 'Campaign Overview'],
        },
      },
    })
  }
  const handleDelete = () => {
    setShow(false)
    httpService.deleteData(
      '/campaign-master/' + campaignDetails._id,
      (response: any) => {
        setTimeout(() => {
          message.success({content: 'Campaign has been deleted successfully!', key, duration: 2})
          setDeleteId('')
        }, 1000)
        history.push({
          pathname: '/campaign/dashboard',
          state: {type: 'campaign/dashboard', status: '', reloaded: false},
        })
      },
      (error: any) => {
        message.error(error.message)
      }
    )
  }
  return (
    <div>
      {campaignDetails.status === 'underReview' && user.permissions.admin && (
        <div className='campaign-approveToggle d-flex'>
          <span className='flex justify-content-start w-75'>
            <div className='flex h-100 w-20px'>
              <KTSVG
                className='svg-icon-4 w-15px h-15px svg-icon-dark'
                path={toAbsoluteUrl('/media/icons/Custom/I.svg')}
              ></KTSVG>
            </div>
            <span className='fs-6 ms-4 mt-1 '>
              <h6 className='mb-0'>
                New campaign “{campaignDetails.campaignDetails.name}” has been created for “
                {campaignDetails.campaignDetails.brandMaster.brandDetails?.brandName}”
              </h6>
              <label className='fs-7 text-gray-700'>
                To proceed with Influencer onboarding process, approve the campaign
              </label>
            </span>
          </span>
          <span className='flex justify-content-end w-25'>
            <button
              className='btn-approveToggle btn btn-success '
              onClick={() => updateCampaignStatus('Approved')}
            >
              <KTSVG
                className='svg-icon-4 w-15px h-15px svg-icon-dark'
                path={toAbsoluteUrl('/media/icons/Custom/Tick.svg')}
              ></KTSVG>
              Approve
            </button>
            <button
              className='btn-approveToggle btn bg-danger text-danger bg-opacity-25 ms-3'
              onClick={() => updateCampaignStatus('Rejected')}
            >
              <KTSVG
                className='svg-icon-4 w-15px h-15px svg-icon-danger'
                path={toAbsoluteUrl('/media/icons/Custom/Cross.svg')}
              ></KTSVG>
              Reject
            </button>
          </span>
        </div>
      )}
      <div className='d-flex'>
        <div>
          <div className='basicDiv w-350px ms-20px'>
            <div className='d-flex'>
              <div className='d-flex w-100 justify-content-between'>
                <label className='campaignLabel w-95px h-95px rounded-circle'>
                  <img
                    className='w-95px h-95px rounded-circle'
                    src={campaignDetails.campaignDetails?.brandMaster?.brandDetails?.avatar}
                  ></img>
                </label>
               {((user.userType !== 'admin' && campaignDetails.campaignStatus !== 'live') || (user.userType === 'admin')) && (
                  <button
                    type='button'
                    className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary rotate-90'
                    data-kt-menu-trigger='hover'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='top-end'
                  >
                    <KTSVG
                      className='svg-icon  svg-icon-dark'
                      path={toAbsoluteUrl('/media/icons/Custom/Menu90.svg')}
                    />
                  </button>
                )}
                <span
                  className='menu border border-3 menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold w-200px pt-3 pb-3'
                  data-kt-menu='true'
                >
                  <span className='menu-item px-3'>
                    <a
                      className='menu-link px-3 text-hover-white'
                      onClick={(e: any) => {
                        handleEdit()
                      }}
                    >
                      Edit
                    </a>
                  </span>
                  <span className='menu-item px-3'>
                    <a
                      className='menu-link px-3 text-hover-white'
                      onClick={(e: any) => {
                        setShow(true)
                        setDeleteId(history.location.state?.id)
                      }}
                    >
                      Delete
                    </a>
                  </span>

                  <Modal
                    show={show}
                    onHide={() => {
                      setShow(false)
                    }}
                    aria-labelledby='contained-modal-title-vcenter'
                    centered
                  >
                    <Modal.Header>
                      <Modal.Title>Delete</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you wanna delete?</Modal.Body>
                    <Modal.Footer>
                      <button
                        className='btn fw-bolder btn-gray-200 fs-6 dialogButton'
                        onClick={() => {
                          setShow(false)
                          setDeleteId('')
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        className='btn create-btn fs-6 dialogButton ms-2'
                        onClick={() => handleDelete()}
                      >
                        Delete
                      </button>
                    </Modal.Footer>
                  </Modal>
                </span>
              </div>
            </div>
            <div className='m16'>
              <h6>{campaignDetails.campaignDetails?.name}</h6>
              <div className='d-flex'>
                <img
                  className='w-15px'
                  src={
                    campaignDetails.campaignDetails.platform == 'Instagram'
                      ? toAbsoluteUrl('/media/logos/Instagram.svg')
                      : toAbsoluteUrl('/media/logos/Youtube.svg')
                  }
                ></img>
                <p className='m-0 ms-2'>For {campaignDetails.campaignDetails.platform}</p>
              </div>
            </div>
            <div className='m24'>
              <h6 className='m-0'>Brand Associated</h6>
              <p className='m-0 text-gray-400'>
                {campaignDetails.campaignDetails?.brandMaster?.brandDetails?.brandName}
              </p>
            </div>
            <div className='m16'>
              <h6 className='m-0'>Agency Associated</h6>
              <p className='m-0 text-gray-400'>
                {campaignDetails.campaignDetails?.agencyMaster?.agencyDetails?.agencyName || 'NA'}
              </p>
            </div>
            <div className='m16'>
              <h6 className='m-0'>Campaign Description</h6>
              <p className='m-0 text-gray-400'>{campaignDetails.campaignDetails?.description}</p>
            </div>

            {/* Campaign live and End Date */}

            <div className='m16 d-flex flex-column'>
              <div className='w-50 pb-3'>
                <h6 className='m-0'>Campaign Live Date </h6>
                <p className='m-0 text-gray-400'>
                  {moment(campaignDetails.campaignDetails?.liveDate).format('DD-MM-YYYY')}
                </p>
              </div>
              <div>
                <h6 className='m-0'>Campaign End Date</h6>
                <p className='m-0 text-gray-400'>
                  {moment(campaignDetails.campaignDetails?.campaignEndDate).format('DD-MM-YYYY')}
                </p>
              </div>
            </div>

            <div className='m16 d-flex flex-column'>
              <div className='w-50 pb-3'>
                <h6 className='m-0'>Campaign Type </h6>
                <p className='m-0 text-gray-400'>{campaignDetails.campaignDetails?.type}</p>
              </div>
              <div>
                <h6 className='m-0'>Barter Value</h6>
                <p className='m-0 text-gray-400'>
                  ₹{campaignDetails.campaignDetails?.barterValue?.min} - ₹
                  {campaignDetails.campaignDetails?.barterValue?.max}
                </p>
              </div>
            </div>

            {/* Application start and End Date */}
            <div className='m16 d-flex flex-column'>
              <div className=' pb-3'>
                <h6 className='m-0'>Application Open Date </h6>
                <p className='m-0 text-gray-400'>
                  {moment(campaignDetails.campaignDetails?.startDate).format('DD-MM-YYYY')}
                </p>
              </div>
              <div>
                <h6 className='m-0'>Application Close Date</h6>
                <p className='m-0 text-gray-400'>
                  {moment(campaignDetails.campaignDetails?.endDate).format('DD-MM-YYYY')}
                </p>
              </div>
            </div>

            <div className='m16'>
              <h6 className='m-0'>Mode of Barter</h6>
              <p className='m-0 text-gray-400'>{campaignDetails.campaignDetails?.mode}</p>
            </div>
            <div className=''>
              <p className='m-0 mt-6 text-gray-400 fs-8'>
                Created {Moment(campaignDetails.createdAt).format('DD MMM, YYYY')}
              </p>
            </div>
          </div>
        </div>
        <div className='ms-7 w-50'>
          <div className='basicDiv pt-0 pb-0 flex justify-content-start w-100'>
            <div
              onClick={() => {
                setInfluencer(true)
                setCampaign(false)
                setOther(false)
              }}
              className={
                influencer
                  ? 'p-5 fw-bolder border-bottom border-primary border-2 h-50px'
                  : 'p-5 h-50px'
              }
            >
              <a>Influencer Preferences</a>
            </div>
            <div
              onClick={() => {
                setInfluencer(false)
                setCampaign(true)
                setOther(false)
              }}
              className={
                campaign
                  ? 'p-5 fw-bolder border-bottom border-primary border-2  h-50px'
                  : 'p-5 h-50px'
              }
            >
              <a>Target Audience</a>
            </div>
            <div
              onClick={() => {
                setInfluencer(false)
                setCampaign(false)
                setOther(true)
              }}
              className={
                other ? 'p-5 fw-bolder border-bottom border-primary border-2 h-50px' : 'p-5 h-50px'
              }
            >
              <a>Content Guidelines</a>
            </div>
          </div>
          {campaign && <InfluencerDetailById data={campaignDetails} />}
          {influencer && <CampaginById data={campaignDetails} />}
          {other && <OtherDetailById data={campaignDetails} />}
        </div>
        <div className='ms-5 m20 col me-10'>
          {/* {!cnameR && ( */}
          <div
            className={
              campaignDetails.campaignStatus === 'live'
                ? 'review-status live flex'
                : campaignDetails.campaignStatus === 'completed'
                ? 'review-status completed  flex'
                : campaignDetails.campaignStatus === 'rejected'
                ? 'review-status rejected flex'
                : campaignDetails.campaignStatus === 'draft'
                ? 'review-status draft flex'
                : campaignDetails.campaignStatus === 'upcoming'
                ? 'review-status upcoming flex'
                : campaignDetails.campaignStatus === 'underReview'
                ? 'review-status underReview  flex'
                : 'review-status flex'
            }
          >
            {campaignDetails.campaignStatus === 'underReview' && 'Under Review'}
            {campaignDetails.campaignStatus === 'draft' && 'Draft'}
            {campaignDetails.campaignStatus === 'upcoming' && 'Upcoming'}
            {campaignDetails.campaignStatus === 'live' && 'Ongoing'}
            {campaignDetails.campaignStatus === 'rejected' && 'Rejected'}
            {campaignDetails.campaignStatus === 'completed' && 'Completed'}
          </div>
          {/* )} */}
          {/* {cnameR && <div className='review-status rejected flex'>Rejected</div>} */}
          {campaignDetails.campaignStatus === 'live' && (
            <div className='basicDiv m20'>
              <h5 className='m-0 text-gray-400'>Jump To</h5>
              <Link
                to={{
                  pathname: '/campaign/influencerOnborading/' + history?.location.state._id,
                  state: {
                    ...history.location.state,
                    ...{
                      status: history?.location.state.status,
                      breadcrums: ['Campaigns', 'Influencer Onboarding'],
                    },
                  },
                }}
              >
                <div className='m24 d-flex'>
                  <KTSVG
                    className='svg-icon-5 mt-1 svg-icon-dark me-3'
                    path={toAbsoluteUrl('/media/icons/Custom/Influencer.svg')}
                  ></KTSVG>
                  Influencer Onboarding
                </div>
              </Link>
              {/* <div className='border border-gray-100 m12'></div> */}
              <a>
                <div className='m12 d-flex'>
                  <KTSVG
                    className='svg-icon-5 mt-1 svg-icon-dark me-3'
                    path={toAbsoluteUrl('/media/icons/Custom/Transaction.svg')}
                  ></KTSVG>
                  campaign Billing
                </div>
              </a>
              {/* <div className='border border-gray-100 m12'></div> */}
              <Link
                to={{
                  pathname: '/campaign/contract',
                  state: {
                    ...history.location.state,
                    ...{breadcrums: ['Campaigns', 'Content Submission']},
                  },
                }}
              >
                <div className='m12 d-flex'>
                  <KTSVG
                    className='svg-icon-5 mt-1 svg-icon-dark me-3'
                    path={toAbsoluteUrl('/media/icons/Custom/image.svg')}
                  ></KTSVG>
                  Content Submission
                </div>
              </Link>
              {/* <div className='border border-gray-100 m12'></div> */}
              {!campaignDetails.isActive && (
                <Link to={{}}>
                  <div className='m12 d-flex'>
                    <KTSVG
                      className='svg-icon-5 mt-1 svg-icon-dark me-3'
                      path={toAbsoluteUrl('/media/icons/Custom/pieChart.svg')}
                    ></KTSVG>
                    Campaign Performance
                  </div>
                </Link>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
