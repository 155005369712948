/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSSVariableValue} from '../../../_metronic/assets/ts/_utils'

type Props = {
  className: string
  campaign: Array<Number>
}

const DonutChart3: React.FC<Props> = ({className, campaign}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    if (!chartRef.current) {
      window.dispatchEvent(new Event('resize'))
      return
    }

    const chart1 = new ApexCharts(chartRef.current, chart1Options(campaign))
    if (chart1) {
      chart1.render()
      window.dispatchEvent(new Event('resize'))
    }

    return () => {
      if (chart1) {
        chart1.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef])

  return (
    <div className={`${className}`}>
      <div className='d-flex flex-column'>
        <div ref={chartRef} id='kt_charts_widget_3_chart' style={{height: 'auto'}}></div>
      </div>
    </div>
  )
}
const chart1Options = (campaign: any): ApexOptions => {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')

  return {
    series: campaign,
    labels: ['Live', 'Completed', 'Under Review'],
    chart: {
      type: 'donut',
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        customScale: 1,
        donut: {
          labels: {
            show: true,
            value: {
              show: true,
            },
            total: {
              show: true,
            },
          },
        },
      },
    },
    legend: {
      show: false,
    },

    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 270,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
  }
}

export {DonutChart3}
