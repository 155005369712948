/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSSVariableValue} from '../../../_metronic/assets/ts/_utils'

type Props = {
  className: string
}

const HorizontalChart: React.FC<Props> = ({className}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    if (!chartRef.current) {
      return
    }

    const chart1 = new ApexCharts(chartRef.current, chart1Options())
    if (chart1) {
      chart1.render()
    }

    return () => {
      if (chart1) {
        chart1.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef])
  
  return (
    <div className={`${className}`}>
      <div className='d-flex flex-column'>
        <div ref={chartRef} id='kt_charts_widget_3_chart' style={{height: 'auto'}}></div>     
      </div>
    </div>
  )}
const chart1Options = (): ApexOptions => {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  
  
  return {
    series: [{
        name: 'Pico',
        data: [44, 52, 15, 9, 1],
      }],
      theme:{
          palette:'palette1'
        },
      grid:{
          show:false
      },
      legend:{
        show:false
      },
        chart: {
          type: 'bar',
          height: 200,
          toolbar:{
            show:false
          }
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
            distributed:true
          }
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: ['pico','micro','nano','mega','exa'
          ],
          labels:{
            show:false
          },
          axisBorder:{
            show:false
          },
          axisTicks:{
            show:false
          }
        },
        yaxis: {
          labels:{
            show:false
          }
        }
        
  }
  
}

export {HorizontalChart}