import { CopyFilled, LeftOutlined, RightOutlined } from '@ant-design/icons'
import { message } from 'antd'
import Moment from 'moment'
import { useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import Select from 'react-select'
import { RootState } from '../../setup'
import httpService from '../../setup/http/httpServices'
import { toAbsoluteUrl } from '../../_metronic/helpers'
import '../masters/basic.css'
import { IUserModel } from '../modules/auth/models/UserModel'
import InfluencerList from './InfluencerList'

export function ContentSubmission() {
  const user: IUserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as IUserModel
  const [contentList, setContentList] = useState([] as any)
  const [agencies, setAgencies] = useState([] as any)
  const [brands, setBrands] = useState([] as any)
  const [agency, setAgency] = useState(null)
  const [brand, setBrand] = useState(null)
  const [campaigns, setCampaigns] = useState([])
  const [influencers, setInfluencers] = useState([])

  const handleClick = (e: any, influencerObj: any) => {
    httpService.getData(
      // '/content-master/621f9f8556207c76071000c7',
      '/content-master/' + influencerObj._id,
      (response: any) => {
        let contentFinalItems = []
        response.data.data.contents.forEach((x: any) => {
          if (x.iterations.length > 0)
            contentFinalItems.push(x)
        })
        setContentList(contentFinalItems)
      },
      (error: any) => {
        message.error(error?.message)
      }
    )
  }
  const getBrands = (x: any) => {
    return {
      label: (
        <div>
          <img
            src={x.brandDetails?.avatar}
            height='32px'
            width='32px'
            style={{ borderRadius: '40px' }}
          />{' '}
          {x.brandDetails?.brandName}
        </div>
      ),
      value: x._id,
    }
  }
  const getAgencies = (x: any) => {
    return {
      label: (
        <div>
          <img
            src={x.agencyDetails.avatar}
            height='32px'
            width='32px'
            style={{ borderRadius: '40px' }}
          />{' '}
          {x.agencyDetails.agencyName}
        </div>
      ),
      value: x._id,
    }
  }
  const getAllMasters = () => {
    httpService.getData(
      '/brand-master?limit=0',
      (response: any) => {
        setBrands(response.data.data.brands)
      },
      (error: any) => {
        message.error(error?.message)
      }
    )
    httpService.getData(
      '/agency-master?limit=0',
      (response: any) => {
        setAgencies(response.data.data.agencies)
      },
      (error: any) => {
        message.error(error?.message)
      }
    )
  }

  const getCampaigns = () => {
    httpService.getData(
      '/campaign-master?limit=0&&brand=' +
      (brand ? brand.value : '') +
      '&&agency=' +
      (agency ? agency.value : ''),
      (response: any) => {
        response.data.data.campaigns.map((x: any) => {
          x['value'] = x._id
          x['label'] = x.campaignDetails.name
          return x
        })
        setCampaigns([{ value: '', label: 'Select Campaign' }].concat(response.data.data.campaigns))
      },
      (error: any) => {
        message.error(error.message)
      }
    )
  }
  useEffect(() => {
    getAllMasters()
  }, [])
  useEffect(() => {
    getCampaigns()
  }, [brand, agency])
  return (
    <div>
      <div className='container-fluid flex justify-content-start bg-white h-75px w-100 border'>
        <div className='flex form-check form-check-custom form-check-solid'>
          {!(user.userType === 'brand' || user.userType === 'agency') && <>
            <div className='w200'>
              <Select
                options={agencies.map((x) => getAgencies(x))}
                isMulti={false}
                value={agency}
                placeholder={
                  <div>
                    <img
                      src={toAbsoluteUrl('/media/logos/categoryIcon.png')}
                      height='32px'
                      width='32px'
                      style={{ borderRadius: '40px' }}
                    />{' '}
                    Select Agency
                  </div>
                }
                styles={{
                  menuList: (provided, state) => ({
                    ...provided,
                    height: 'auto',
                    maxHeight: '250px'
                  }),
                }}
                className={'form-select p-0 categorySelect w-100'}
                onChange={(e: any) => {
                  setAgency(e)
                }}
              />
            </div>
            <LeftOutlined style={{ marginLeft: '24px', fontSize: '10px' }} />
            <RightOutlined style={{ marginRight: '24px', fontSize: '10px' }} />
            <div className='w200'>
              <Select
                options={brands.map((x) => getBrands(x))}
                isMulti={false}
                value={brand}
                placeholder={
                  <div>
                    <img
                      src={toAbsoluteUrl('/media/logos/categoryIcon.png')}
                      height='32px'
                      width='32px'
                      style={{ borderRadius: '40px' }}
                    />{' '}
                    Please Select{' '}
                  </div>
                }
                styles={{
                  menuList: (provided, state) => ({
                    ...provided,
                    height: 'auto',
                    maxHeight: '250px'
                  }),
                }}
                className={'form-select p-0 categorySelect w-100'}
                onChange={(e: any) => {
                  setBrand(e)
                }}
              />
            </div>
            <RightOutlined style={{ marginLeft: '24px', marginRight: '24px', fontSize: '10px' }} />
          </>}
          <select
            className='form-select w200'
            onChange={(event: any) => {
              httpService.getData(
                '/influencer-onboarding/' + event.target.value,
                (response: any) => {
                  setInfluencers([])
                  let data: any = []
                  response.data.data.influencers.shortListed.forEach((influencer: any) => {
                    let addressObj: any = {}
                    if (influencer.influencer.basicInfo.addressDetails.length > 0) {
                      addressObj = influencer.influencer.basicInfo.addressDetails.find(x => x.isDefault)
                    }

                    data.push({
                      _id: influencer._id,
                      photo: influencer.influencer.basicInfo.avatar,
                      name: influencer.influencer.basicInfo.name,
                      city: addressObj?.city,
                      state: addressObj?.state,
                      isPaid: influencer.isPaid,
                      content: [],
                    })
                  })
                  setInfluencers(data)
                  ////// To eb updated with index
                  handleClick({}, influencers[0])
                },
                (error: any) => { }
              )
            }}
          >
            <option value='' selected disabled hidden>
              Campaign Name
            </option>
            {campaigns.length > 0 &&
              campaigns.map((campaign: any) => (
                <option key={campaign.value} value={campaign?.value}>
                  {campaign.label}
                </option>
              ))}
          </select>
        </div>
      </div>
      <div className='main-content d-flex'>
        {influencers && influencers.length > 0 ? (
          <>
            <InfluencerList influencers={influencers} handleClick={(e, i) => handleClick(e, influencers[i])} height='780px' />
            <div className='content-cards container'>
              {contentList && contentList.length > 0 ? (
                contentList.map((item: any, index: number) => (
                  <div
                    key={index}
                    className='d-flex justify-content-start align-items-start content-item'
                  >
                    <div>
                      <img src={item.iterations[item.iterations.length - 1]?.link} className='rounded content-img' alt='' />
                    </div>
                    <div className='flex flex-column justify-content-start align-items-start ml24 w-100'>
                      <div className='flex justify-content-between w-100'>
                        <span className='f18'>Content #{index + 1}</span>
                        <span className='text-success bg-success bg-opacity-20 rounded p-2 pt-1 pb-1'>
                          Approved By:Rishi
                        </span>
                      </div>
                      <span className='f16 m25'>Content</span>
                      <span className='f14'>Type: {item?.contentType}</span>
                      <div className='flex justify-content-between w-100 mt8'>
                        <div className='flex flex-column align-items-start'>
                          <span className='f16'>Caption</span>
                          <span className='f14'>{item?.title}</span>
                        </div>
                        <div
                          style={{
                            backgroundColor: '#D2D5D940',
                            height: '26px',
                            textAlign: 'center',
                            width: '26px',
                            cursor: 'pointer',
                          }}
                          className='rounded-circle'
                          onClick={() => {
                            navigator.clipboard.writeText(item?.caption)
                          }}
                        >
                          <CopyFilled />
                        </div>
                      </div>
                      <div className='flex justify-content-between w-100 mt8'>
                        <div className='flex flex-column align-items-start'>
                          <span className='f16'>Hashtags</span>
                          <span className='f14'>{item?.hashTags}</span>
                        </div>
                        <div
                          style={{
                            backgroundColor: '#D2D5D940',
                            height: '26px',
                            textAlign: 'center',
                            width: '26px',
                            cursor: 'pointer',
                          }}
                          className='rounded-circle'
                          onClick={() => {
                            navigator.clipboard.writeText(item?.hashtags)
                          }}
                        >
                          <CopyFilled />
                        </div>
                      </div>
                      <span className='f16 mt8'>Post Date</span>
                      <span className='f14'>
                        {Moment(item?.submissionDate).format('DD MMMM YYYY HH:mm')}
                      </span>
                    </div>
                  </div>
                ))
              ) : (
                <>
                  <div className='w-100 d-flex flex-column align-items-center'>
                    <div>
                      <img
                        className='ms-20'
                        src={toAbsoluteUrl('/media/logos/TableVector.png')}
                        style={{ width: '600px', height: '400px' }}
                      ></img>
                    </div>
                    <div>
                      <div>
                        <h2 className='mb-0 m24 flex'>No Content Present.</h2>
                        {/* <p className='m-0 mt-5 w-600px ms-auto me-auto text-center'>
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                          tempor incididunt ut labore et dolore magna aliquaLorem ipsum dolor sit
                          amet
                        </p> */}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </>
        ) : (
          <>
            <div className='w-100 flex flex-column'>
              <div className='flex'>
                <img
                  src={toAbsoluteUrl('/media/logos/TableVector.png')}
                  style={{ width: '600px', height: '400px' }}
                ></img>
              </div>
              <div className='flex'>
                <div>
                  {influencers.length === 0 && <h2 className='mb-0 m24 flex'>No influencer present</h2>}
                  {/* <p className='m-0 mt-5 w-600px ms-auto me-auto text-center'>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliquaLorem ipsum dolor sit amet
                  </p> */}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}
