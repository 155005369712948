import React, {useState, useCallback} from 'react'
import {Button} from 'react-bootstrap-v5'
import ReactDOM from 'react-dom'
import Cropper from 'react-easy-crop'
import './basic.css'
import getCroppedImg from './CropImage'

export function ImageCropper(props: any) {
  const image = props.image
  const [crop, setCrop] = useState({x: 1, y: 1})
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [croppedImage, setCroppedImage] = useState(null)

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(props.image, croppedAreaPixels)
      setCroppedImage(croppedImage)
      props.editImage(croppedImage)
    } catch (e) {}
  }, [croppedAreaPixels])
  return (
    <div className='crop-parent'>
      <div className='crop-body rounded border border-2'>
        <div className='crop-container rounded-top'>
          <Cropper
            image={props.image}
            crop={crop}
            zoom={zoom}
            aspect={1 / 1}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
        </div>
        <div className='controls ps-20 pe-5 w-100 '>
          <input
            type='range'
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            aria-labelledby='Zoom'
            onChange={(e: any) => {
              setZoom(e.target.value)
            }}
            className='zoom-range'
          />
          <Button
            onClick={showCroppedImage}
            variant='contained'
            color='primary'
            className='btn btn-primary ms-5 h-50px w-100px'
          >
            Crop
          </Button>
          <Button
            onClick={props.cancel}
            variant='contained'
            color='primary'
            className='btn btn-white border border-2 text-dark ms-5 h-50px w-100px'
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  )
}
