import { DatePicker, message } from 'antd'
import clsx from 'clsx'
import { useFormik } from 'formik'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import Select from 'react-select'
import * as Yup from 'yup'
import httpService from '../../setup/http/httpServices'
import '../masters/basic.css'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../setup'
import { IUserModel } from '../modules/auth/models/UserModel'
import { TransactionLeftBreadcrum } from './TransactionLeftBreadcrum'

var initialValues = {
  referenceNumber: '',
  date: moment(new Date()).format('YYYY/MM/DD'),
  campaign: '',
  agency: null,
  brand: '',
  remark: '',
}

const registrationSchema = Yup.object().shape({
  date: Yup.string().required('Transaction Date is required'),
  // agency: Yup.string().required('Campaign agency is required'),
  brand: Yup.string().required('Campaign brand is required'),
  campaign: Yup.string().required('Campaign is required'),
  remark: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(500, 'Maximum 500 characters')
    .required('Remark is required'),
})

export function TransactionAdd() {
  const history: any = useHistory()
  const [loading, setLoading] = useState(false)
  const [transactionInfoDetails, setTransactionInfoDetails] = useState(initialValues)
  const [brands, setBrands] = useState([])
  const [agencies, setAgencies] = useState([])
  const [campaigns, setCampaigns] = useState([])
  const [agency, setAgency] = useState(null)
  const [brand, setBrand] = useState(null)
  const [campaign, setCampaign] = useState<any>(null)
  const user: IUserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as IUserModel

  const getAllMasters = () => {
    if (!history.location.state?._id && formik.values.referenceNumber === '') {
      httpService.getData(
        '/transaction/reference-number',
        (response: any) => {
          formik.setFieldValue('referenceNumber', response.data.data.referenceNumber)
        },
        (error: any) => {
          message.error(error?.message)
        }
      )
    }

    httpService.getData(
      '/brand-master',
      (response: any) => {
        response.data.data.brands.map((x: any) => {
          x['value'] = x._id
          x['label'] = x.brandDetails.brandName
          return x
        })
        setBrands(response.data.data.brands)
        if (history.location.state?.transactionInfoDetails?.brand) {
          const seletedAgencyAssociated: any = response.data.data.brands.find(
            (x: any) => x._id === history.location.state.transactionInfoDetails.brand
          )
          setBrand({ ...seletedAgencyAssociated })
        }
        else if (user.userType === 'brand') {
          const seletedAgencyAssociated: any = response.data.data.brands.find(
            (x: any) => x._id === user.brandId
          )
          formik.setFieldValue('brand', user.brandId)
          setBrand({ ...seletedAgencyAssociated })
        }
      },
      (error: any) => {
        message.error(error?.message)
      }
    )

    httpService.getData(
      '/campaign-master',
      (response: any) => {
        response.data.data.campaigns.map((x: any) => {
          x['value'] = x._id
          x['label'] = x.campaignDetails.name
          return x
        })
        setCampaigns(response.data.data.campaigns)
        if (history.location.state?.transactionInfoDetails?.campaign) {
          const seletedAgencyCampaign: any = response.data.data.campaigns.find(
            (x: any) => x._id === history.location.state.transactionInfoDetails.campaign
          )
          setCampaign({ ...seletedAgencyCampaign })
        }
      },
      (error: any) => {
        message.error(error?.message)
      }
    )

    httpService.getData(
      '/agency-master',
      (response: any) => {
        let agencyList = [
          {
            value: '',
            label: 'Select Agency',
          },
        ]
        response.data.data.agencies.map((x: any) => {
          x['value'] = x._id
          x['label'] = x.agencyDetails.agencyName
          return x
        })
        agencyList = [...agencyList, ...response.data.data.agencies]
        setAgencies(agencyList)
        if (history.location.state?.transactionInfoDetails?.agency) {
          const seletedAgencyAssociated: any = response.data.data.agencies.find(
            (x: any) => x._id === history.location.state.transactionInfoDetails.agency
          )
          setAgency({ ...seletedAgencyAssociated })
        }
        else if (user.userType === 'agency') {
          const seletedAgencyAssociated: any = response.data.data.agencies.find(
            (x: any) => x._id === user.agencyId
          )
          setAgency({ ...seletedAgencyAssociated })
        }
      },
      (error: any) => {
        message.error(error?.message)
      }
    )
  }
  const getBrand = () => {
    // setBrand(null)
    httpService.getData(
      '/brand-master?limit=0',
      (response: any) => {
        let list = [{ value: '', label: 'Select Brand' }]
        response.data.data.brands.map((x: any) => {
          if (agency && agency._id === x?.companyDetails?.agencyAssociated?._id) {
            list.push({ value: x._id, label: x.brandDetails.brandName })
            setBrands(list)
          }
          else if (!agency || agency?.label === 'Select Agency') {
            x['value'] = x._id
            x['label'] = x.brandDetails.brandName
            setBrands([{ value: '', label: 'Select Brand' }].concat(response.data.data.brands))
            return x
          }
          else {
            setBrands(list)
          }
        })

      },
      (error: any) => {
      }
    )
  }

  useEffect(() => {
    // setEditId(history.location.state?._id);
    getAllMasters()
  }, [])
  useEffect(() => {
    getBrand()
  }, [agency])
  const formik = useFormik({
    initialValues: transactionInfoDetails || initialValues,
    validationSchema: registrationSchema,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      setTimeout(() => {
        try {
          setTransactionInfoDetails(values)
          setLoading(false)
          setSubmitting(false)
          let platform: any = campaigns?.find((campaign: any) => campaign._id == values.campaign)
          history.push({
            pathname: '/transaction/amount',
            state: {
              ...history.location.state,
              ...{
                transactionInfoDetails: {
                  ...values,
                  ...{ platform: platform?.campaignDetails?.platform },
                },
              },
            },
          })
        } catch {
          setLoading(false)
          setSubmitting(false)
          setStatus('Registration process has broken')
          // Remove logs when not needed
        }
      }, 1000)
    },
  })

  useEffect(() => {
    try {
      var transactionInfoDetail1 = history.location.state?.transactionInfoDetails
      setTransactionInfoDetails(transactionInfoDetail1)
    } catch { }
  }, [history.location.state?.transactionInfoDetails])

  return (
    <div className='CompanyDetailsOuter'>
      <TransactionLeftBreadcrum currentStep='1' type={history.location.state?.type} />
      <form
        className='CompanyDetails'
        noValidate
        id='kt_login_signup_form'
        onSubmit={formik.handleSubmit}
      >
        <h2 className='m-0'>Create New Transaction</h2>

        <div className='col m24'>
          <h6 className='fw-bold required'>Reference No./Invoice Number</h6>
          <input
            type='text'
            placeholder='Reference No./Invoice Number'
            {...formik.getFieldProps('referenceNumber')}
            disabled
            className={clsx(
              'form-control form-control-solid bg-gray-100',
              {
                'is-invalid': formik.touched.referenceNumber && formik.errors.referenceNumber,
              },
              {
                'is-valid': formik.touched.referenceNumber && !formik.errors.referenceNumber,
              }
            )}
          />
          {formik.touched.referenceNumber && formik.errors.referenceNumber && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span className='text-danger' role='alert'>
                  {formik.errors.referenceNumber}
                </span>
              </div>
            </div>
          )}
        </div>
        <div className='col m24'>
          <h6 className='fw-bold required'>Date</h6>
          <DatePicker
            {...formik}
            name='date'
            allowClear={false}
            value={moment(formik.values['date'], 'YYYY/MM/DD')}
            className={clsx(
              'border-0 form-control form-control-solid w-100 bg-gray-100 border-radius',
              {
                'is-invalid': formik.touched.date && formik.errors.date,
              },
              {
                'is-valid': formik.touched.date && !formik.errors.date,
              }
            )}
            onChange={(date, dateString) => formik.setFieldValue('date', dateString)}
          />

          {formik.touched.date && formik.errors.date && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span className='text-danger' role='alert'>
                  {formik.errors.date}
                </span>
              </div>
            </div>
          )}
        </div>
        <div className='d-flex m24'>
          <div className='col me-5'>
            <h6 className='fw-bold'>Agency</h6>
            <Select
              options={agencies}
              value={agency}
              isMulti={false}
              isDisabled={!user.permissions.admin && (user.userType === 'brand' || user.userType === 'agency')}
              name='agency'
              placeholder='Select Agency'
              styles={{
                menuList: (provided, state) => ({
                  ...provided,
                  height: 'auto',
                  maxHeight: '200px',
                }),
              }}
              className={clsx(
                'text-gray-500 form-control form-control-solid bg-gray-100 p-0 border-0 categorySelect',
                {
                  'is-invalid': formik.touched.agency && formik.errors.agency,
                },
                {
                  'is-valid': formik.touched.agency && !formik.errors.agency,
                }
              )}
              onChange={(e: any) => {
                formik.setFieldValue('agency', e.value)
                setAgency(e)
              }}
            />
            {formik.touched.agency && formik.errors.agency && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='text-danger' role='alert'>
                    {formik.errors.agency}
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className='col '>
            <h6 className='fw-bold required'>Brand</h6>
            <Select
              options={brands}
              value={brand}
              isDisabled={!user.permissions.admin && user.userType === 'brand'}
              isMulti={false}
              name='brand'
              placeholder='Select Brand'
              styles={{
                menuList: (provided, state) => ({
                  ...provided,
                  height: 'auto',
                  maxHeight: '200px',
                }),
              }}
              className={clsx(
                'text-gray-500 form-control form-control-solid bg-gray-100 p-0 border-0 categorySelect',
                {
                  'is-invalid': formik.touched.brand && formik.errors.brand,
                },
                {
                  'is-valid': formik.touched.brand && !formik.errors.brand,
                }
              )}
              onChange={(e: any) => {
                formik.setFieldValue('brand', e.value)
                setBrand(e)
              }}
            />
            {formik.touched.brand && formik.errors.brand && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='text-danger' role='alert'>
                    {formik.errors.brand}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='d-flex m24'>
          <div className='col '>
            <h6 className='fw-bold required'>Campaign</h6>
            <Select
              options={campaigns}
              value={campaign}
              isMulti={false}
              name='campaign'
              placeholder='Select Campaign'
              styles={{
                menuList: (provided, state) => ({
                  ...provided,
                  height: 'auto',
                  maxHeight: '200px',
                }),
              }}
              className={clsx(
                'text-gray-500 form-control form-control-solid bg-gray-100 p-0 border-0 categorySelect',
                {
                  'is-invalid': formik.touched.campaign && formik.errors.campaign,
                },
                {
                  'is-valid': formik.touched.campaign && !formik.errors.campaign,
                }
              )}
              onChange={(e: any) => {
                formik.setFieldValue('campaign', e.value)
                setCampaign(e)
              }}
            />
            {formik.touched.campaign && formik.errors.campaign && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='text-danger' role='alert'>
                    {formik.errors.campaign}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='m24'>
          <h6 className='fw-bold required'>Remark</h6>
          <div className='col '>
            <textarea
              placeholder='Remark'
              {...formik.getFieldProps('remark')}
              className={clsx(
                'form-control form-control-solid bg-gray-100 h-100px',
                {
                  'is-invalid': formik.touched.remark && formik.errors.remark,
                },
                {
                  'is-valid': formik.touched.remark && !formik.errors.remark,
                }
              )}
            />
            {formik.touched.remark && formik.errors.remark && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='text-danger' role='alert'>
                    {formik.errors.remark}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='d-flex m24'>
          <Link
            to={{
              pathname: '',
              state: history.location.state,
            }}
          >
            <button type='button' className='btn fw-bolder btn-gray-200 CompanyDetailButton'>
              Cancel
            </button>
          </Link>
          <button
            type='submit'
            id='kt_sign_up_submit'
            className='btn create-btn ms-5 CompanyDetailButton'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && <span className='indicator-label'>Continue</span>}
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}
