export function AccessOption(props: any) {
  return (
    <div className='d-flex mt-3'>
      <div className='col'>
        <h6 className='m-0 fw-bold'>{props.label}</h6>
      </div>
      <div className='col flex justify-content-end'>
        <div className='form-check form-switch form-check-custom form-check-solid'>
          <input
            className='form-check-input w-40px h-20px form-check-input-lightPrimary'
            type='checkbox'
            disabled={props.self}
            onChange={(e) => props.handleChange(e.target.checked)}
            checked={props.hasAccess}
          />
          <div></div>
        </div>
      </div>
    </div>
  )
}
