import {SearchOutlined} from '@ant-design/icons'
import {Input, message} from 'antd'
import React, {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap-v5'
import {Link, useHistory} from 'react-router-dom'
import httpService from '../../setup/http/httpServices'
import {KTSVG, toAbsoluteUrl} from '../../_metronic/helpers'
import {ProfileAvatar} from '../modules/widgets/components/ProfileAvatar'
export function Contract(props: any) {
  const {active} = props
  const isBrandMaster = true
  const history: any = useHistory()
  const [influencerListParams, setinfluencerListParams] = useState({
    limit: 10,
    page: 1,
    total: 0,
    search: '',
  })
  const pageType = props.type
  const [totalCount, setTotalCount] = useState(null)
  const [paginationCount, setpaginationCount] = useState(null)
  const [deleteId, setDeleteId] = useState('')
  const [apiComplete, setapiComplete] = useState(false)
  const [allData, setAllData] = useState(null)
  const [contractStatus, setContractStatus] = useState('all')
  const [influencerList, setinfluencerList] = useState([])
  const [standByActive, setstandByActive] = useState(false)
  const [sort1, setSort1] = useState('rotate-90 ms-2')
  const [sort2, setSort2] = useState('rotate-90 ms-2')
  const [sort3, setSort3] = useState('rotate-90 ms-2')
  const [sort4, setSort4] = useState('rotate-90 ms-2')
  const [show, setShow] = useState(false)
  const [show1, setShow1] = useState(false)
  const [contractLink, setContractLink] = useState(history.location.state.contractDocument)

  const [fileName, setFileName] = useState('Attach contract')
  var fileUploaded = null
  const hiddenFileInput = React.useRef(null)
  const handleClick = (event) => {
    hiddenFileInput.current.click()
  }
  const handleChange = (event) => {
    if (event.target.files.length === 0) return
    fileUploaded = event.target.files[0]
    let formData = new FormData()
    formData.append('contract', fileUploaded)
    httpService.putData(
      '/influencer-onboarding/contract/upload-contract/' + history.location.state._id,
      formData,
      (response: any) => {
        message.success('Contact Uploaded successfully')
        // history.replaceState(
        //   {
        //     ...history.location.state,
        //     ...{ type: 'influencerOnboard', status: 'Contract' },
        //   },
        //   ''
        // )
        setContractLink(response.data.data.campaign.contractDocument)
        // window.location.reload();
        // setFileName(fileUploaded.name)
      },
      (error: any) => {}
    )
  }
  const handleClose = () => setShow(false)
  const [searchParam] = useState(['city', 'name'])
  useEffect(() => {
    let interval
    try {
      getAllInfluencer()
      interval = setInterval(() => {
        getAllInfluencer()
      }, 10000)
      httpService.getData(
        '/campaign-master/' + history.location.state._id,
        (response: any) => {
          setContractLink(response.data.data.campaign.contractDocument)
        },
        (error: any) => {}
      )
    } catch {}
    return () => {
      clearInterval(interval)
    }
  }, [])

  useEffect(() => {
    if (apiComplete) {
      if (influencerListParams.search !== '') {
        setinfluencerList(search(influencerListParams.search))
      } else {
        if (contractStatus === 'all') {
          setinfluencerList(allData)
        } else {
          if (allData && Array.isArray(allData) && allData.length > 0) {
            var newData = allData.filter((m) => m.influencer.contractStatus === contractStatus)
            setinfluencerList(newData)
          }
        }
      }
    }
  }, [influencerListParams.page, influencerListParams.search, contractStatus])
  const [responseReceived, setResponseReceived] = useState(false)
  const getAllInfluencer = () => {
    setapiComplete(false)
    if (!responseReceived) {
      setResponseReceived(true)
      httpService.getData(
        '/influencer-onboarding/' + `${props.campaignId}`,
        (response: any) => {
          setResponseReceived(false)
          let data = response.data.data
          setAllData(data.influencers)
          setinfluencerList(data.influencers.shortListed)
          setTotalCount(data.influencers.shortListed.length)
        },
        (error: any) => {
          message.error(error.message)
        }
      )
    }
  }
  const handleDelete = (deleteId: string) => {
    setShow(false)
  }
  function search(q) {
    return allData[contractStatus].filter((item) => {
      return searchParam.some((newItem) => {
        return (
          item.influencer.basicInfo[newItem] &&
          item.influencer.basicInfo[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
        )
      })
    })
  }

  const sendProduct = (id) => {
    httpService.putData(
      '/influencer-onboarding/contract/send-contract/' + id,
      {},
      (response: any) => {
        message.success('Contract sent to the influencer')
        getAllInfluencer()
      },
      (error: any) => {
        message.error(error.message)
      }
    )
  }

  const sendContractToAll = () => {
    for (const data of influencerList) {
      console.log("Sending contract to", data?._id)
      if (data?._id) {
        httpService.putData(
          '/influencer-onboarding/contract/send-contract/' + data?._id,
          {},
          (response: any) => {
            getAllInfluencer()
          },
          (error: any) => {
            message.error(error.message)
          }
        )
      } else {
        console.log("Influencer list ID does not exist")
        message.error("Influencer list ID does not exist")
      }
    }
    message.success('Contract sent to all influencers')
  }

  const sendContract = (id, action) => {
    httpService.postData(
      '/influencer-onboarding/' + `${props.campaignId}`,
      {
        influencer: id,
        status: action,
      },
      (response: any) => {
        message.success('Influncer moved successfully')
        getAllInfluencer()
      },
      (error: any) => {
        message.error(error.message)
      }
    )
  }

  function numFormatter(num) {
    if (num > 999 && num < 1000000) {
      return (num / 1000).toFixed(1) + 'K' // convert to K for number from > 1000 < 1 million
    } else if (num > 1000000) {
      return (num / 1000000).toFixed(1) + 'M' // convert to M for number from > 1 million
    } else if (num < 900) {
      return num // if value < 1000, nothing to do
    }
  }
  const platform = props.campaignDetails.platform

  const redirectViewCampaign = (data: any) => {
    httpService.getData(
      '/influencer-master/' + data?.influencer._id,
      (res: any) => {
        res.data.data.influencer.additionalInfo.interestedCategories =
          res.data.data.influencer.additionalInfo.interestedCategories.join(',')
        history.push({
          pathname: '/master/influencerProfile',
          state: {
            influencerBasicInfo: res.data.data.influencer.basicInfo,
            influencerAdditinalInfo: res.data.data.influencer.additionalInfo,
            influencerSocialDetails: res.data.data.influencer.socialMediaDetails,
            influencerStats: res.data.data.influencer.stats,
            _id: res.data.data.influencer._id,
            type: 'influencerProfile',
          },
        })
      },
      (err: any) => {
        message.error({content: err.message})
      }
    )
  }
  return (
    <>
      <div style={{minWidth: '1336px'}}>
        <div className='d-flex flex-row flex-column-fluid'>
          <div className='TableDiv'>
            <div className='TableHeader w-100 justify-content-start'>
              <h3 className='mb-0 me-3'>
                Influencers
                <h6 className='text-gray-400 fs-7 mb-0'>
                  {totalCount} influencers
                  {/* {totalCount && (totalCount?.ready + totalCount?.sent + totalCount?.signed)} influencer */}
                </h6>
              </h3>
              <div className='d-flex sub-menu w-100'>
                {/* <ul className='nav nav-pills w-100 justify-content-start align-content-center'>
                  <li className='nav-item '>
                    <a
                      style={{borderRadius: '49px'}}
                      onClick={() => setContractStatus('all')}
                      className={
                        contractStatus == 'all'
                          ? 'nav-link d-flex w-auto fw-bold h-40px flex ps-6 pe-6 grey-active'
                          : 'nav-link d-flex w-auto h-40px flex text-gray-400 fw-bold'
                      }
                    >
                      {' '}
                      All
                    </a>
                  </li>
                  <li className='nav-item '>
                    <a
                      style={{borderRadius: '49px'}}
                      onClick={() => setContractStatus('ready')}
                      className={
                        contractStatus == 'ready'
                          ? 'nav-link d-flex w-auto fw-bold h-40px flex ps-6 pe-6 grey-active'
                          : 'nav-link d-flex w-auto h-40px flex text-gray-400 fw-bold'
                      }
                    >
                      {' '}
                      Ready to Ship
                    </a>
                  </li>
                  <li className='nav-item '>
                    <a
                      style={{borderRadius: '49px'}}
                      onClick={() => setContractStatus('sent')}
                      className={
                        contractStatus == 'sent'
                          ? 'nav-link d-flex w-auto fw-bold h-40px flex ps-6 pe-6 grey-active'
                          : 'nav-link d-flex w-auto h-40px flex text-gray-400 fw-bold'
                      }
                    >
                      {' '}
                      Sent
                    </a>
                  </li>
                  <li className='nav-item '>
                    <a
                      style={{borderRadius: '49px'}}
                      onClick={() => setContractStatus('signed')}
                      className={
                        contractStatus == 'signed'
                          ? 'nav-link d-flex w-auto fw-bold h-40px flex ps-6 pe-6 grey-active'
                          : 'nav-link d-flex w-auto h-40px flex text-gray-400 fw-bold'
                      }
                    >
                      {' '}
                      Signed
                    </a>
                  </li>
                </ul> */}
              </div>
              <div className='d-flex w-100 align-items-center justify-content-end'>
                <link
                  href='https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css'
                  rel='stylesheet'
                />
                <Input
                  placeholder='Search'
                  className='form-control-solid bg-gray-100 rounded w-250px h-38px'
                  onChange={(e: any) =>
                    setinfluencerListParams({
                      ...influencerListParams,
                      ...{search: e.target.value},
                    })
                  }
                  prefix={
                    <>
                      <SearchOutlined />
                    </>
                  }
                />
                {/* <div className='flex justify-content-end '>
                    <button className='btn btn-primary d-flex flex justify-content-center me-2 h-35px fs-7 ms-3'>
                      Contract
                    </button>
                  </div> */}
              </div>
            </div>
            <div className='card-body mt-0 p-0'>
              {/* begin::Table container */}
              <div className='table-responsive'>
                {/* begin::Table */}
                <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                  {/* begin::Table head */}
                  <thead>
                    <tr className='fw-bolder fs-7 text-muted'>
                      <th className='bg-primary bg-opacity-5 min-w-300px'>
                        <span className='d-flex ms-8'>Name</span>
                      </th>
                      <th className='bg-primary bg-opacity-5 min-w-150px'>
                        <span className='d-flex'>Contract</span>
                      </th>
                      <th className='bg-primary bg-opacity-5 min-w-100px'>
                        <span className='d-flex'>Signed</span>
                      </th>
                      <th className='bg-primary bg-opacity-5 min-w-100px'>
                        <span className='d-flex'>Ready To Ship</span>
                      </th>

                      {/* <th className='bg-primary bg-opacity-5 min-w-0px d-flex justify-content-end'>
                        Action
                      </th> */}
                    </tr>
                  </thead>
                  {/* end::Table head */}
                  {/* begin::Table body */}
                  <tbody>
                    {influencerList.length > 0 &&
                      influencerList.map((data: any, index: number) => (
                        <tr key={index}>
                          {/* <td>
                        <div className='form-check ms-5 form-check-sm form-check-custom form-check-solid'>
                          <input
                            className='form-check-input widget-9-check'
                            type='checkbox'
                            value='1'
                          />
                        </div>
                      </td> */}
                          <td className='fw-bolder ps-8'>
                            <span className='flex justify-content-start w-100'>
                              <ProfileAvatar
                                src={
                                  data?.influencer.basicInfo.avatar
                                    ? data?.influencer.basicInfo.avatar
                                    : null
                                }
                                className='w-100 rounded-circle ml-2 '
                                name={
                                  data?.influencer.basicInfo.name
                                    ? data?.influencer.basicInfo.name[0].toUpperCase()
                                    : 'NA'
                                }
                              ></ProfileAvatar>

                              <span className='fs-6 ms-4 mt-1 '>
                                <label
                                  className={
                                    !data.isPaid ? 'fs-7 m-0 grayout' : 'fs-7 text-gray-400 m-0'
                                  }
                                >
                                  {data?.influencer.basicInfo.name}
                                </label>
                                {data.influencer.basicInfo.addressDetails?.find((x) => x.isDefault)
                                  ?.city && (
                                  <label className='flex fs-7'>
                                    <KTSVG
                                      className='svg-icon me-1'
                                      path={toAbsoluteUrl('/media/icons/Custom/MapMarker.svg')}
                                    />{' '}
                                    {
                                      data.influencer.basicInfo.addressDetails.find(
                                        (x) => x.isDefault
                                      )?.city
                                    }
                                    ,{' '}
                                    {
                                      data.influencer.basicInfo.addressDetails.find(
                                        (x) => x.isDefault
                                      )?.state
                                    }
                                  </label>
                                )}
                              </span>
                            </span>
                          </td>

                          {/* <td className='fw-bolder'>
                     <div className='d-flex justify-content-start'>
                      <span className={data?.influencer.contractStatus === "signed" ? "admin-tag tag-blue" : data?.influencer.contractStatus === "ready" ? "admin-tag tag-red" : data?.influencer.contractStatus === "sent" ? "admin-tag tag-green" : "d-none"}>{data?.influencer.contractStatus}</span>
                      </div>
                      </td>  */}

                          <td className=''>
                            <div className='w-100 d-flex justify-content-start'>
                              {data?.contract.contractSent ? (
                                <button className='bg-success bg-opacity-25 pointer btn text-success border-0 w-150px d-flex flex cursor-auto'>
                                  <KTSVG
                                    className='svg-icon svg-icon-success flex'
                                    path={toAbsoluteUrl('/media/icons/Custom/paperPlane.svg')}
                                  />
                                  Sent
                                </button>
                              ) : (
                                <button
                                  className='btn flex bg-pink text-white w-150px'
                                  disabled={standByActive}
                                  onClick={() => {
                                    if (contractLink) {
                                      sendProduct(data?._id)
                                    } else setShow1(true)
                                  }}
                                >
                                  Send Contract
                                </button>
                              )}
                            </div>
                            <Modal
                              show={show1}
                              onHide={handleClose}
                              aria-labelledby='contained-modal-title-vcenter'
                              centered
                            >
                              <Modal.Body>
                                <h2>Contract Unavailable</h2>
                                <p className='m24 fs-5'>
                                  You haven’t upload contract yet. Upload contract to finalize the
                                  influencer.
                                </p>

                                <button
                                  className='btn btn-primary fs-6 dialogButton w-100 h-50px m20'
                                  onClick={() => setShow1(false)}
                                >
                                  Gotcha!
                                </button>
                              </Modal.Body>
                            </Modal>
                          </td>
                          <td className='ps-6'>
                            <span
                              className={
                                data?.contract.signed
                                  ? 'bg-success w-35px h-35px flex rounded-circle'
                                  : 'bg-gray-100 w-35px h-35px flex rounded-circle'
                              }
                            >
                              <KTSVG
                                className={
                                  data?.contract.signed
                                    ? 'svg-icon svg-icon-white flex'
                                    : 'svg-icon svg-icon-dark flex'
                                }
                                path={toAbsoluteUrl('/media/icons/Custom/Active.svg')}
                              />
                            </span>
                          </td>
                          <td className='ps-12'>
                            <span
                              className={
                                data?.contract.readyToShip
                                  ? 'bg-success w-35px h-35px flex rounded-circle'
                                  : 'bg-gray-100 w-35px h-35px flex rounded-circle'
                              }
                            >
                              <KTSVG
                                className={
                                  data?.contract.readyToShip
                                    ? 'svg-icon svg-icon-white flex'
                                    : 'svg-icon svg-icon-dark flex'
                                }
                                path={toAbsoluteUrl('/media/icons/Custom/Active.svg')}
                              />
                            </span>
                          </td>

                          {/* <td className=''>
                            <div className='d-flex justify-content-end'>
                              <span
                              onClick={() => {
                                // Trigger the contract download here
                                if (contractLink) {
                                  const link = document.createElement('a');
                                  link.href = contractLink;
                                  link.setAttribute('download', contractLink); // You can specify the desired filename here
                                  link.style.display = 'none';
                                  document.body.appendChild(link);
                                  link.click();
                                  document.body.removeChild(link);
                                }
                              }}
                                className='svg-icon flex svg-icon-dark bg-primary bg-opacity-5 w-30px h-30px rounded me-2 pointer'
                                // onClick={() => editBrandOrAgency(data?._id)}
                              >
                                <KTSVG
                                  className='svg-icon-3'
                                  path={toAbsoluteUrl('/media/icons/Custom/Export.svg')}
                                />
                              </span>
                              <span
                                onClick={() => {
                                  // Redirect to the contract link
                                  if (contractLink) {
                                    window.open(contractLink, '_blank');
                                  }
                                }}
                                className='svg-icon svg-icon-dark bg-primary bg-opacity-5 flex w-30px h-30px rounded pointer'
                              >
                                <KTSVG
                                  className='svg-icon-3'
                                  path={toAbsoluteUrl('/media/icons/Custom/eye.svg')}
                                />
                              </span>
                              <Modal
                                show={show}
                                onHide={handleClose}
                                aria-labelledby='contained-modal-title-vcenter'
                                centered
                              >
                                <Modal.Header>
                                  <Modal.Title>Delete</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>Are you sure you wanna delete?</Modal.Body>
                                <Modal.Footer>
                                  <button
                                    className='btn fw-bolder btn-gray-200 fs-6 dialogButton'
                                    onClick={() => {
                                      setShow(false)
                                      setDeleteId('')
                                    }}
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    className='btn btn-primary fs-6 dialogButton ms-2'
                                    onClick={() => handleDelete(deleteId)}
                                  >
                                    Delete
                                  </button>
                                </Modal.Footer>
                              </Modal>
                            </div>
                          </td> */}
                        </tr>
                      ))}
                    {influencerList.length === 0 && (
                      <tr>
                        <td colSpan={7}>
                          <div className='m-20'>
                            <div className='flex'>
                              <img
                                className='ms-20'
                                src={toAbsoluteUrl('/media/logos/TableVector.png')}
                              ></img>
                            </div>
                            <div className='flex'>
                              <div>
                                <h2 className='mb-0 m24 flex'>No {pageType} Listed</h2>
                                {/* <p className='m-0 mt-5 w-600px ms-auto me-auto text-center'>
                                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                  eiusmod tempor incididunt ut labore et dolore magna aliquaLorem
                                  ipsum dolor sit amet
                                </p> */}
                              </div>
                            </div>
                            <div className='flex'>
                              <Link
                                to={{
                                  pathname: '/companyDetails',
                                  state: {type: pageType},
                                }}
                              >
                                <button className='btn m24 TableButton btn-pink text-white'>
                                  + Add New {pageType}
                                </button>
                              </Link>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>

                  {/* end::Table body */}
                </table>
                {/* <div className='d-flex ms-5 me-10 justify-content-between align-items-end flex-wrap mb-10'>
                  <span className='text-muted pb-2'>
                    Page {influencerListParams.page} of{' '}
                    {Math.floor(paginationCount % influencerListParams.limit) === 0
                      ? Math.floor(paginationCount / influencerListParams.limit)
                      : Math.floor(paginationCount / influencerListParams.limit) + 1}
                  </span>
                  <div className='d-flex flex-wrap '>
                    <Pagination
                      className=''
                      pageSize={influencerListParams.limit}
                      defaultCurrent={influencerListParams.page}
                      total={paginationCount}
                      onChange={(e) =>
                        setinfluencerListParams({...influencerListParams, ...{page: e}})
                      }
                    />
                  </div>
                </div> */}
                {/* end::Table */}
              </div>
              {/* end::Table container */}
            </div>
          </div>

          <div className='RecentSummary'>
            <div className='RecentDiv'>
              {/* {contractLink && ( */}
              <a href='https://sehaye-insta-login-test.s3.ap-south-1.amazonaws.com/sehayeTemplates/sampleContract.docx' download>
                <button
                  className='btn btn-primary w-100 h-45px mt-5 fs-6'
                  style={{marginLeft: '20px'}}
                >
                  Download Sample Contract
                </button>
              </a>
              {/* )} */}
              <div className='SummaryDiv w-100 m20'>
                <KTSVG
                  className='svg-icon svg-icon-dark svg-icon-5x'
                  path={toAbsoluteUrl('/media/icons/Custom/File.svg')}
                />
                <div className='m24'>
                  <h3 className='fw-bolder text-gray-900 mb-1'>
                    {contractLink ? 'Update' : 'Upload'} Contract
                  </h3>
                  <span className=' fw-bold text-gray-400'>
                    The uploaded contract will be visible to the influencer. Once signed, it will be
                    displayed with the influencer details.
                  </span>
                  <div className='d-flex m24'>
                    <button
                      onClick={handleClick}
                      className={
                        !contractLink
                          ? 'btn w-100 btn-gray-100 d-flex'
                          : 'btn w-100 btn-gray-100 d-flex success-contract-button'
                      }
                    >
                      <KTSVG
                        className='svg-icon svg-icon-dark mt-1 me-2'
                        path={toAbsoluteUrl('/media/icons/Custom/paperClip.svg')}
                      />{' '}
                      <div style={{minWidth: '85%', textAlign: 'left'}}>
                        {contractLink
                          ? contractLink
                              .split('/')
                              .find((x) => x.includes('pdf'))
                              .substring(
                                0,
                                contractLink
                                  .split('/')
                                  .find((x) => x.includes('pdf'))
                                  .indexOf('pdf') - 1
                              ) + '.pdf'
                          : fileName}
                      </div>
                      {contractLink && (
                        <KTSVG
                          className='svg-icon-3 float-right'
                          path={toAbsoluteUrl('/media/icons/Custom/Dustbin.svg')}
                        ></KTSVG>
                      )}
                    </button>
                    {/* {fileName != 'Attach contract' && <button onClick={() => { setFileName('Attach contract'); fileUploaded = null }} className='border-0 bg-white ms-2 flex'> <KTSVG
                        className='svg-icon-3 '
                        path={toAbsoluteUrl('/media/icons/Custom/Dustbin.svg')}
                      ></KTSVG></button>} */}
                    <input
                      onChange={handleChange}
                      ref={hiddenFileInput}
                      type='file'
                      accept='application/pdf'
                      className='w-100 m24 d-none'
                    ></input>
                  </div>
                  {contractLink && (
                    <div className='w-100 d-flex m16 justify-content-center pb-2'>
                      <a
                        href={contractLink}
                        download
                        className='link-primary fs-6 fw-bolder'
                        style={{marginLeft: '5px'}}
                      >
                        Download Contract
                      </a>
                    </div>
                  )}
                </div>
              </div>
              <button
                  className='btn btn-primary w-100 h-45px mt-5 fs-6'
                  style={{marginLeft: '20px'}}
                  disabled={standByActive}
                  onClick={() => {
                      if (contractLink) {sendContractToAll()} else setShow1(true)
                    }}
                >Send Contract To All</button>
              {/* <div className='SummaryDiv'>
                  <div className='d-flex flex-column'>
                    <li className='d-flex align-items-center py-2'>
                      <span className='bullet me-5'></span> Ready to Ship
                    </li>
                    <li className='d-flex align-items-center py-2'>
                      <span className='bullet me-5'></span> Signed
                    </li>
                    <li className='d-flex align-items-center py-2'>
                      <span className='bullet me-5'></span> Sent
                    </li>
                  </div>
                </div> */}
              {/* <Link
            to={{
              pathname: '/campaign/influencerOnborading/'+`${props.campaignId}`,
              state: { type: 'influencerOnboard', status: 'Payment' }
            }}
          >
            <button className='btn btn-primary d-flex flex justify-content-center w-100 h-35px fs-7' style={{ marginLeft:"30px"
    }}>
            Pay for {totalCount.shortListed} Short-listed Influencers
            </button>
            </Link> */}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
