import React, {useEffect, useState} from 'react'
import {AgencyView} from './AgencyView'
import {SearchOutlined} from '@ant-design/icons'
import {Input, message} from 'antd'
import './basic.css'
import {Link, useHistory} from 'react-router-dom'
import httpService from '../../setup/http/httpServices'
import {toAbsoluteUrl} from '../../_metronic/helpers'

export function AgencyViewBrands() {
  const history: any = useHistory()
  const [name, setName] = useState('')
  const [brandAssociated, setBrandAssociated] = useState([])
  const [foundBrands, setFoundBrands] = useState([])


  useEffect(() => {

    getAllBrands()
  }, [])
  const getAllBrands = () => {

    httpService.getData(
      history.location.state.type === 'BrandView'
        ? '/brand-master-details/' + history.location?.state?.id + '/profile'
        : '/agency-details/brand-associated/' + history?.location?.state?.id,
      (response: any) => {
        if (history.location.state.type === 'BrandView') {
          setBrandAssociated([])
          setFoundBrands([])
        } else {
          setBrandAssociated(response?.data?.brandAssociated)
          setFoundBrands(response?.data?.brandAssociated)
        }
      },
      (error: any) => {
        message.error(error?.message)
      }
    )
  }
  const filter = (e: any) => {
    const keyword = e.target.value

    if (keyword !== '') {
      const results = brandAssociated.filter((brand: any) => {
        return brand.brandDetails.brandName.toLowerCase().startsWith(keyword.toLowerCase())
        // Use the toLowerCase() method to make it case-insensitive
      })
      setFoundBrands(results)
    } else {
      setFoundBrands(brandAssociated)
      // If the text field is empty, show all users
    }
    setName(keyword)
  }
  const viewBrand = (data: any) => {
    history.push({
      pathname: '/master/profile',
      state: {id: data._id, type: 'BrandView', name: data.brandDetails?.brandName},
    })
  }
  return (
    <div className='Outer d-flex'>
      <AgencyView
        active={5}
        details={history?.location?.state?.profileDetails}
        id={history?.location?.state?.id}
        type={history?.location?.state?.type}
        name={history?.location?.state?.name}
      />
      <div className='View-brands'>
        <div className='Brands-topbar'>
          <div className='Brands-topbar-left'>
            <h2>Brands</h2>
            <p>{brandAssociated?.length} Brands</p>
          </div>
          <div className='Brands-topbar-right'>
            <Input
              placeholder='Search'
              style={{width: '198px', height: '34px'}}
              className='form-control-solid border'
              onChange={filter}
              value={name}
              prefix={
                <>
                  <SearchOutlined />
                </>
              }
            />
            <Link
              to={{
                pathname: '/companyDetails',
                state: {type: history.location.state.type, name:history.location.state.name, id:history.location.state.id},
              }}
            >
              <button className='btn create-btn d-flex flex me-2 w-auto h-34px fs-7 ms-3'>
                Add New Brand 😍
              </button>
            </Link>
          </div>
        </div>
        <div className='Brands-container container'>
          <div className='row'>
            {brandAssociated.length === 0 && (
              <div>
                <div className='flex'>
                  <img src={toAbsoluteUrl('/media/logos/TableVector.png')}></img>
                </div>
                <div className='flex'>
                  <div>
                    <h2 className='mb-0 m24 flex'>No Brands Listed</h2>
                  </div>
                </div>
              </div>
            )}
            {foundBrands?.length > 0 &&
              foundBrands?.map((index) => (
                <div
                  className='Brand-card col pointer'
                  key={index}
                  onClick={() => viewBrand(index)}
                >
                  <div className='Brand-card-header'>
                    <img src={index.brandDetails.avatar} alt='' />
                    <h2>{index.brandDetails.brandName}</h2>
                    <p>{index.companyDetails?.city}, {index.companyDetails?.state}</p>
                  </div>
                  <div className='Brand-card-details'>
                    <div className='Brand-card-detailsCol'>
                      <h2>-</h2>
                      <p>Campaigns</p>
                    </div>
                    <div className='Brand-card-detailsCol'>
                      <h2>-</h2>
                      <p>Transactions</p>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  )
}
