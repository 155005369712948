import {message} from 'antd'
import clsx from 'clsx'
import {useFormik} from 'formik'
import React, {useState} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import * as Yup from 'yup'
import httpService from '../../setup/http/httpServices'
import {RootState} from '../../setup/redux/RootReducer'
import {toAbsoluteUrl} from '../../_metronic/helpers'
import {Alerts} from '../modules/alerts/Alerts'
import {IUserModel} from '../modules/auth/models/UserModel'
import './basic.css'
import {CategoryTable} from './CategoryTable'
import {ImageCropper} from './ImageCropper'

var initialValues = {
  categoryName: '',
  categoryIconUrl: 'test.png',
  iqDataCategoryId: '',
}
const categorySchema = Yup.object().shape({
  categoryName: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Category name is required')
    .matches(/^(?=.*[A-Za-z]).{3,50}$/, 'Should not contain just numbers'),

  iqDataCategoryId: Yup.string().required('IQ Data Id is required'),
})
export function CategoryMaster(props: any) {
  const user: IUserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as IUserModel
  const access = user.permissions.masters[1] || user.permissions.admin

  const [bgImage, setBgImage] = useState(toAbsoluteUrl('/media/logos/categoryIcon.png'))
  const [isSuccess, setIsSuccess] = useState(false)
  const [toggleCrop, setToggleCrop] = useState(false)
  const [isFailure, setIsFailure] = useState(false)
  const [categoryId, setCategoryId] = useState(null)
  const [responseMessage, setResponseMessage] = useState('')
  const [selectedFile, setSelectedFile] = useState(new File([], ''))
  const key = 'updatable'
  const hideAlerts = () => {
    setIsSuccess(false)
    setIsFailure(false)
  }
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: categorySchema,
    enableReinitialize: true,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setIsFailure(false)
      setIsSuccess(false)
      let fd = new FormData()
      fd.append('categoryName', values.categoryName)
      fd.append('iqDataCategoryId', values.iqDataCategoryId)
      fd.append('categoryIconUrl', selectedFile)
      const hide = message.loading({content: 'Loading...', key})
      if (categoryId) {
        httpService.patchData(
          '/category-master/' + categoryId,
          fd,
          (res: any) => {
            formik.resetForm()
            setIsFailure(false)
            setResponseMessage('Success! Category updated.')
            setCategoryId(null)
            setIsSuccess(true)
            setBgImage(toAbsoluteUrl('/media/logos/categoryIcon.png'))
            setTimeout(hide, 500)
            setTimeout(hideAlerts, 4000)
            resetImage()
          },
          (err: any) => {
            hide()
            setIsSuccess(false)
            setIsFailure(true)
            setResponseMessage(err.message)
            setTimeout(hideAlerts, 4000)
          }
        )
      } else if (selectedFile.size > 0)
        httpService.postData(
          '/category-master',
          fd,
          (res: any) => {
            formik.resetForm()
            setIsFailure(false)
            setResponseMessage('Success! Category added.')
            setCategoryId(null)
            setIsSuccess(true)
            setBgImage(toAbsoluteUrl('/media/logos/categoryIcon.png'))
            setTimeout(hide, 500)
            setTimeout(hideAlerts, 4000)
            resetImage()
          },
          (err: any) => {
            hide()
            setIsSuccess(false)
            setIsFailure(true)
            setResponseMessage(err.message)
            setTimeout(hideAlerts, 4000)
          }
        )
      else if (selectedFile.size === 0) {
        formik.setFieldError('categoryIconUrl', 'Please select category icon.')
      }
    },
  })

  function onChange(event: any) {
    let file = event.target.files[0]
    setSelectedFile(file)
    var reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
      setBgImage('' + reader.result + '')
      setToggleCrop(true)
    }
  }

  function resetImage() {
    setSelectedFile(new File([], ''))
    setBgImage(toAbsoluteUrl('/media/logos/categoryIcon.png'))
  }

  const handleCategory = (id: any) => {
    httpService.getData(
      '/category-master/' + id,
      (res: any) => {
        formik.setValues(res.data.data.category)
        setBgImage(toAbsoluteUrl(res.data.data.category.avatar))
        setCategoryId(id)
      },
      (error: any) => {
        message.error(error.message)
      }
    )
  }

  async function handleCrop(image: any) {
    setBgImage(image)
    const response = await fetch(image, {mode: 'no-cors'})
    const data = await response.blob()
    setSelectedFile(
      new File([data], selectedFile?.name, {
        type: selectedFile.type,
      })
    )
    formik.setErrors({})
    setToggleCrop(false)
  }

  const handleCancel = () => {
    setSelectedFile(new File([], ''))
    setBgImage(toAbsoluteUrl('/media/logos/categoryIcon.png'))
    setToggleCrop(false)
  }

  return (
    <div className='d-flex justify-content-center m-2' style={{overflow: 'hidden'}}>
      <div className='addCategory'>
        <h3 className='mb-0'>Add New Category</h3>
        <p className='fs-7 text-gray-400'>
          Categories are used to segregate brands and influencers.{' '}
        </p>
        <Alerts
          visible={isSuccess}
          type='success'
          message={responseMessage}
          description={'Description'}
        ></Alerts>
        <Alerts
          visible={isFailure}
          type='error'
          message={responseMessage}
          description={'Description'}
        ></Alerts>
        <form noValidate id='kt_login_signup_form' onSubmit={formik.handleSubmit}>
          <div>
            <h6 className='fw-bold required m32'>IQ Data Id</h6>
            <input
              {...formik.getFieldProps('iqDataCategoryId')}
              type='number'
              className={clsx(
                'form-control form-control-solid bg-gray-100',
                {
                  'is-invalid': formik.touched.iqDataCategoryId && formik.errors.iqDataCategoryId,
                },
                {
                  'is-valid': formik.touched.iqDataCategoryId && !formik.errors.iqDataCategoryId,
                }
              )}
            />
            {formik.touched.iqDataCategoryId && formik.errors.iqDataCategoryId && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='text-danger' role='alert'>
                    {formik.errors.iqDataCategoryId}
                  </span>
                </div>
              </div>
            )}
          </div>
          <div>
            <h6 className='fw-bold required m32'>Category Name</h6>
            <input
              {...formik.getFieldProps('categoryName')}
              className={clsx(
                'form-control form-control-solid bg-gray-100',
                {
                  'is-invalid': formik.touched.categoryName && formik.errors.categoryName,
                },
                {
                  'is-valid': formik.touched.categoryName && !formik.errors.categoryName,
                }
              )}
            />
            {formik.touched.categoryName && formik.errors.categoryName && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='text-danger' role='alert'>
                    {formik.errors.categoryName}
                  </span>
                </div>
              </div>
            )}
          </div>
          <div>
            <h6 className='fw-bold required m16'>Category Icon</h6>
            <div data-kt-image-input='true' className='image-input categoryIcon'>
              <div className='image-input-wrapper w-100 h-100 flex bg-gray-100'>
                <img src={bgImage} className='h-100'></img>
              </div>
              <label
                className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow'
                data-kt-image-input-action='change'
                data-bs-toggle='tooltip'
                data-bs-original-title='Change avatar'
              >
                <i className='bi bi-pencil-fill fs-7'></i>
                <input
                  type='file'
                  name='avatar'
                  accept='.png, .jpg, .jpeg, .svg'
                  onChange={(event) => onChange(event)}
                />
                <input type='hidden' name='avatar_remove' />
              </label>

              {selectedFile.size > 0 && (
                <span
                  className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow'
                  data-kt-image-input-action='cancel'
                  data-bs-toggle='tooltip'
                  data-bs-dismiss='click'
                  title='Cancel avatar'
                >
                  <i className='bi bi-x fs-2'></i>
                </span>
              )}
              {selectedFile.size > 0 && (
                <span
                  className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow'
                  data-kt-image-input-action='remove'
                  data-bs-toggle='tooltip'
                  data-bs-dismiss='click'
                  title='Remove avatar'
                  onClick={resetImage}
                >
                  <i className='bi bi-x fs-2'></i>
                </span>
              )}
              {formik.errors.categoryIconUrl && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='text-danger' role='alert'>
                      {'Category Icon is required fiels.'}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='m16 w-100 flex'>
            <button
              type='button'
              onClick={() => {
                setCategoryId(null)
                setSelectedFile(new File([], ''))
                setBgImage(toAbsoluteUrl('/media/logos/categoryIcon.png'))
                formik.resetForm()
              }}
              className='btn fw-bolder btn-gray-200 fs-6 categoryButton'
            >
              Cancel
            </button>
            <button
              type='submit'
              // disabled={!formik.isValid}
              className='btn create-btn fs-6 categoryButton ms-5'
              disabled={!access}
            >
              {categoryId ? 'Update' : 'Add'} Category
            </button>
          </div>
        </form>
      </div>
      <CategoryTable
        editCategory={handleCategory}
        updatable={isSuccess || isFailure}
        access={access}
      ></CategoryTable>
      {toggleCrop && <ImageCropper cancel={handleCancel} editImage={handleCrop} image={bgImage} />}
    </div>
  )
}
