/* eslint-disable jsx-a11y/anchor-is-valid */
import { Tooltip, message } from 'antd'
import { useEffect, useState } from 'react'
import { matchPath } from 'react-router'
import { useHistory } from 'react-router-dom'
import { KTSVG, toAbsoluteUrl } from '../../_metronic/helpers'
import httpService from '../../setup/http/httpServices'
import { Communication } from './Communication'
import { Payment } from './Payment'
import { Replace } from './Replace'
import { Shipping } from './Shipping'
import { Shortlist } from './ShortList'
import './admin.css'
import { Contract } from './contract'

export function OnboardingMainPanel(props: any) {
  const history: any = useHistory()
  const [campaignDetails, setCampaignDetails] = useState({})
  const [processStep, setprocessStep] = useState(history.location.state?.status)
  const [campaignId, setCampaignId] = useState(null)
  const [apiComplete, setapiComplete] = useState(false)
  const campaignStatusList = [
    'Approved',
    'Shortlist',
    'Payment',
    'Communicate',
    'Replace',
    'Contract',
    'Shipping',
  ]

  useEffect(() => {
    const matchProfile: any = matchPath(history.location.pathname, {
      path: '/campaign/influencerOnborading/:id',
    })
    const id = matchProfile.params?.id
    setCampaignId(id)
    httpService.getData(
      '/campaign-master/' + id,
      (response: any) => {
        if(!history.location.state){
          history.push({
            pathname: '/campaign/influencerOnborading/' + id,
            state: {
              ...response.data.data.campaign,
              ...{
                status: response.data.data.campaign.isPaid ? 'Communicate' : 'Shortlist',
                type: response.data.data.campaign.campaignDetails.name,
                breadcrums: ['Campaigns', 'Influencer Onboarding'],
              },
            },
          })
          window.location.reload();
        }
        response.data.data.campaign.campaignStatus = response.data.data.campaign.status
        setCampaignDetails(response.data.data.campaign.campaignDetails)
        var step = response.data.data.campaign.processStep
          ? response.data.data.campaign.processStep
          : 'Shortlist'
        setprocessStep(step)
        setapiComplete(true)
      },
      (error: any) => {
        message.error('Campaign Details not found')
        setapiComplete(true)
      }
    )
  }, [])

  const hasAccessRestricted = (status, stepNumber) => {
    if (history.location.state?.isPaid && [1, 2].includes(stepNumber)) {
      return true
    } else if (!history.location.state?.isPaid && [3, 4, 5, 6].includes(stepNumber)) {
      return true
    } else {
      return false
    }
    // if (
    //   campaignStatusList.indexOf(processStep) <= stepNumber &&
    //   status !== 'Communicate' &&
    //   status !== 'Replace' &&
    //   status !== 'Shipping'
    // ) {
    //   return true;
    // }
    // return false;
  }
  const updateOnboardingStatus = (status, stepNumber) => {
    if (hasAccessRestricted(status, stepNumber)) {
      return
    }

    history.push({
      pathname: '/campaign/influencerOnborading/' + `${campaignId}`,
      state: {
        ...history.location.state,
        ...{type: 'influencerOnboard', status: status},
        ...{breadcrums: ['Campaigns', 'Influencer Onboarding']},
      },
    })
  }
  const getClassNames = (status, stepNumber) => {
    var classes = 'nav-link d-flex w-auto h-40px flex '
    if (status === processStep) {
      return (classes += ' ps-6 pe-6 active')
    } else {
      return classes
    }
  }
  if (history?.location?.state?.status !== processStep) {
    setprocessStep(history?.location?.state?.status)
  }
  return (
    <>
      <div className='main-panel' style={{minWidth: '1336px'}}>
        <ul className='nav nav-pills w-100 justify-content-around px-5 nav-panel align-items-center'>
          <li className='nav-item '>
            <a
              style={{borderRadius: '49px'}}
              onClick={() => updateOnboardingStatus('Shortlist', 1)}
              className={getClassNames('Shortlist', 1)}
            >
              <KTSVG
                className='svg-icon svg-icon-sm svg-icon-gray-700 flex h-20px pointer Img'
                path={toAbsoluteUrl('/media/icons/Custom/shortlist_grp.svg')}
              />

              {history.location.state?.isPaid ? (
                <Tooltip
                  placement='top'
                  title={'Changes are not allowed to this stage once payment is done.'}
                  color='red'
                >
                  &nbsp;&nbsp; Shortlist Applications
                </Tooltip>
              ) : (
                <>&nbsp;&nbsp; Shortlist Applications</>
              )}
            </a>
          </li>
          {'>'}
          <li className='nav-item '>
            <a
              style={{borderRadius: '49px'}}
              onClick={() => updateOnboardingStatus('Payment', 2)}
              className={getClassNames('Payment', 2)}
            >
              {' '}
              <KTSVG
                className='svg-icon svg-icon-sm svg-icon-gray-700 flex h-20px pointer Img '
                path={toAbsoluteUrl('/media/icons/Custom/payment.svg')}
              />
              {hasAccessRestricted('Payment', 2) ? (
                <Tooltip
                  placement='top'
                  title={
                    history.location.state?.isPaid
                      ? 'Changes are not allowed to this stage once payment is done.'
                      : 'Section is not accessible before shortlisting.'
                  }
                  color='red'
                >
                  &nbsp;&nbsp; Make Payments
                </Tooltip>
              ) : (
                <>&nbsp;&nbsp; Make Payments</>
              )}
            </a>
          </li>
          {'>'}
          <li className='nav-item '>
            <a
              style={{borderRadius: '49px'}}
              onClick={() => updateOnboardingStatus('Communicate', 3)}
              className={getClassNames('Communicate', 3)}
            >
              {' '}
              <KTSVG
                className='svg-icon svg-icon-sm svg-icon-gray-700 flex h-20px pointer Img'
                path={toAbsoluteUrl('/media/icons/Custom/communicate.svg')}
              />
              {hasAccessRestricted('Communicate', 3) ? (
                <Tooltip
                  placement='top'
                  title={
                    history.location.state?.isPaid
                      ? 'Changes are not allowed to this stage once payment is done.'
                      : 'Section is not accessible before payment.'
                  }
                  color='red'
                >
                  &nbsp;&nbsp; Enter Discussions
                </Tooltip>
              ) : (
                <>&nbsp;&nbsp; Enter Discussions</>
              )}
            </a>
          </li>
          {'>'}
          <li className='nav-item '>
            <a
              style={{borderRadius: '49px'}}
              onClick={() => updateOnboardingStatus('Replace', 4)}
              className={getClassNames('Replace', 4)}
            >
              <KTSVG
                className='svg-icon svg-icon-sm svg-icon-gray-700 flex h-20px pointer Img'
                path={toAbsoluteUrl('/media/icons/Custom/replace.svg')}
              />
              {hasAccessRestricted('Replace', 4) ? (
                <Tooltip
                  placement='top'
                  title={
                    history.location.state?.isPaid
                      ? 'Changes are not allowed to this stage once payment is done.'
                      : 'Section is not accessible before payment.'
                  }
                  color='red'
                >
                  &nbsp;&nbsp; Replace Shortlisted
                </Tooltip>
              ) : (
                <>&nbsp;&nbsp; Replace Shortlisted</>
              )}
            </a>
          </li>
          {'>'}
          <li className='nav-item '>
            <a
              style={{borderRadius: '49px'}}
              onClick={() => updateOnboardingStatus('Contract', 5)}
              className={getClassNames('Contract', 5)}
            >
              {' '}
              <KTSVG
                className='svg-icon svg-icon-sm svg-icon-gray-700 flex h-20px pointer Img'
                path={toAbsoluteUrl('/media/icons/Custom/contract.svg')}
              />
              {hasAccessRestricted('Contract', 5) ? (
                <Tooltip
                  placement='top'
                  title={
                    history.location.state?.isPaid
                      ? 'Section is not accessible at this stage.'
                      : 'Section is not accessible before payment.'
                  }
                  color='red'
                >
                  &nbsp;&nbsp; Exchange Contracts
                </Tooltip>
              ) : (
                <>&nbsp;&nbsp; Exchange Contracts</>
              )}
            </a>
          </li>
          {'>'}
          <li className='nav-item '>
            <a
              style={{borderRadius: '49px'}}
              onClick={() => updateOnboardingStatus('Shipping', 6)}
              className={getClassNames('Shipping', 6)}
            >
              <KTSVG
                className='svg-icon svg-icon-sm svg-icon-gray-700 flex h-20px pointer Img'
                path={toAbsoluteUrl('/media/icons/Custom/shipping.svg')}
              />
              {hasAccessRestricted('Shipping', 6) ? (
                <Tooltip
                  placement='top'
                  title={
                    history.location.state?.isPaid
                      ? 'Section is not accessible at this stage.'
                      : 'Section is not accessible before payment.'
                  }
                  color='red'
                >
                  &nbsp;&nbsp; Ship Products
                </Tooltip>
              ) : (
                <>&nbsp;&nbsp; Ship Products</>
              )}
            </a>
          </li>
        </ul>
      </div>
      {apiComplete && (processStep === 'Shortlist' || processStep === 'Approved') && (
        <Shortlist campaignDetails={campaignDetails} campaignId={campaignId} />
      )}
      {apiComplete && processStep === 'Payment' && (
        <Payment campaignDetails={campaignDetails} campaignId={campaignId} />
      )}
      {apiComplete && processStep === 'Contract' && (
        <Contract campaignDetails={campaignDetails} campaignId={campaignId} />
      )}
      {apiComplete && processStep === 'Communicate' && (
        <Communication campaignDetails={campaignDetails} campaignId={campaignId} />
      )}
      {apiComplete && processStep === 'Replace' && (
        <Replace campaignDetails={campaignDetails} campaignId={campaignId} />
      )}
      {apiComplete && processStep === 'Shipping' && (
        <Shipping campaignDetails={campaignDetails} campaignId={campaignId} />
      )}
    </>
  )
}
