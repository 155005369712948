import React, {useEffect, useState} from 'react'
import {toAbsoluteUrl} from '../../_metronic/helpers'
import {ColumnChart} from './Charts/ColumnChart'
import {ColumnDoubleChart} from './Charts/ColumnDouble'
import {DonutChart} from './Charts/DonutChart'
import {DonutChart1} from './Charts/DonutChart1'
import {DonutChart2} from './Charts/DonutChart2'

const chartDataPoints = [
  {label: 'J', value: 45, bgColor: '#513A98'},
  {label: 'F', value: 60, bgColor: '#513A98'},
  {label: 'M', value: 70, bgColor: '#513A98'},
  {label: 'A', value: 40, bgColor: '#513A98'},
  {label: 'M', value: 30, bgColor: '#513A98'},
  {label: 'J', value: 50, bgColor: '#513A98'},
  {label: 'J', value: 60, bgColor: '#513A98'},
  {label: 'A', value: 55, bgColor: '#513A98'},
]

function ProfileAudience(props: any) {
  const [audienceData, setAudienceData] = useState()
  const [audienceDataCommenter, setAudienceDataCommenter] = useState()
  const [count, setCount] = useState([0, 1, 2])
  const [followerLookAlike, setFollowerLookAlike] = useState([])
  const [followerLanguage, setFollowerLanguage] = useState([])
  const [commenterLookAlike, setCommenterLookAlike] = useState([])
  const [commenterLanguage, setCommenterLanguage] = useState([])

  const name = props.data.influencerBasicInfo.name
  useEffect(() => {
    setAudienceDataCommenter({...props?.userData?.audience_commenters?.data})
    setAudienceData({...props?.userData?.audience_followers?.data})
    setFollowerLookAlike(props?.userData?.audience_followers?.data?.notable_users)
    setFollowerLanguage(props?.userData?.audience_followers?.data?.audience_languages)
    setCommenterLookAlike(props?.userData?.audience_commenters?.data?.notable_users)
    setCommenterLanguage(props?.userData?.audience_commenters?.data?.audience_languages)
  }, [props?.userData])
  return (
    <div className='d-flex flex-column m20 mb16'>
      {props.type === true && (
        <>
          {props?.userData?.audience_followers?.data.notable_users == undefined && (
            <>
              <div>
                <div className='w-100 flex'>
                  <div className='bg-white rounded p-8'>
                    <div className=''>
                      <img className='' src={toAbsoluteUrl('/media/logos/TableVector.png')}></img>
                    </div>
                    <div className='flex'>
                      <div>
                        <h2 className='mb-0 m24 flex'>No Audience Listed</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {props?.userData?.audience_followers?.data.notable_users != undefined && (
            <>
              <div className='d-flex flex-column m20 w-100 rounded bg-white'>
                <div className='border-bottom ' style={{padding: '16px 24px'}}>
                  <h2>Age and Gender Insights (by Followers) </h2>
                </div>
                <div className='w-100' style={{marginTop: '8px'}}>
                  <ColumnDoubleChart className='w-100 ' audienceData={audienceData} />
                </div>
              </div>
              <div className='d-flex m20'>
                <div className='basicDiv w-100 p16 m-2'>
                  <div className=''>
                    <div className=''>
                      <h2 className='fs-16 mb-3'>Audience Look alikes</h2>
                      {count.map((data) => (
                        <div className='d-flex align-items-center mb-2'>
                          <div className='symbol symbol-35px symbol-circle me-5'>
                            <img
                              className='rounded-circle'
                              src={followerLookAlike ? followerLookAlike[data]?.picture : ''}
                              alt=''
                            />
                          </div>
                          <div className='d-flex justify-content-start flex-column'>
                            <a href='#' className='text-dark fw-bold text-hover-primary m-0'>
                              {followerLookAlike ? followerLookAlike[data]?.fullname : '-'}
                            </a>
                            <span className='text-muted fw-bold text-muted d-block fs-8'>
                              {followerLookAlike ? followerLookAlike[data]?.custom_name : '-'}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className='basicDiv w-100 p16 m-2'>
                  <div className=''>
                    <div className=''>
                      <h2 className='fs-16 mb-3'>Influencers Language</h2>
                      {count.map((data) => (
                        <div className='d-flex align-items-center mb-2'>
                          <div className='d-flex justify-content-start flex-column w-100'>
                            <div className='d-flex'>
                              <a
                                href='#'
                                className='text-dark fw-bold text-hover-primary m-0 w-100'
                              >
                                {followerLanguage ? followerLanguage[data]?.name : '-'}
                              </a>
                              <span className='fs-7 text-gray-400 w-100 d-flex justify-content-end'>
                                {followerLanguage
                                  ? (followerLanguage[data]?.weight * 100).toFixed(2) + '%'
                                  : '-'}
                              </span>
                            </div>
                            <div className='w-100 border h-10px rounded mt-1'>
                              <div
                                style={{
                                  width: followerLanguage
                                    ? Math.round(followerLanguage[data]?.weight * 100) + '%'
                                    : '0',
                                }}
                                className='h-100 bg-info rounded'
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className='basicDiv w-100 p16 m-2'>
                  <div className='h-100'>
                    <div className='h-100 d-flex flex-column justify-content-between'>
                      <div>
                        <img src={toAbsoluteUrl('/media/logos/overview_followers.png')} />
                        <h2 className='fs-16' style={{marginTop: '12px'}}>
                          Notable Followers
                        </h2>
                      </div>
                      <h3 className=''>
                        {props?.userData?.audience_followers
                          ? props?.userData?.audience_followers?.data?.notable_users_ratio * 100 +
                            '%'
                          : '-'}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              {/* Commenters Begin*/}

              <div className='d-flex flex-column m20 w-100 rounded bg-white'>
                <div className='border-bottom ' style={{padding: '16px 24px'}}>
                  <h2>Age and Gender Insights (by Commenters) </h2>
                </div>
                <div className='w-100' style={{marginTop: '8px'}}>
                  <ColumnDoubleChart className='w-100 ' audienceData={audienceDataCommenter} />
                </div>
              </div>
              <div className='d-flex m20'>
                <div className='basicDiv w-100 p16 m-2'>
                  <div className=''>
                    <div className=''>
                      <h2 className='fs-16 mb-3'>Audience Look alikes</h2>
                      {count.map((data) => (
                        <div className='d-flex align-items-center mb-2'>
                          <div className='symbol symbol-35px symbol-circle me-5'>
                            <img
                              className='rounded-circle'
                              src={commenterLookAlike ? commenterLookAlike[data]?.picture : ''}
                              alt=''
                            />
                          </div>
                          <div className='d-flex justify-content-start flex-column'>
                            <a href='#' className='text-dark fw-bold text-hover-primary m-0'>
                              {commenterLookAlike ? commenterLookAlike[data]?.fullname : '-'}
                            </a>
                            <span className='text-muted fw-bold text-muted d-block fs-8'>
                              {commenterLookAlike ? commenterLookAlike[data]?.custom_name : '-'}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className='basicDiv w-100 p16 m-2'>
                  <div className=''>
                    <div className=''>
                      <h2 className='fs-16 mb-3'>Influencers Language</h2>
                      {count.map((data) => (
                        <div className='d-flex align-items-center mb-2'>
                          <div className='d-flex justify-content-start flex-column w-100'>
                            <div className='d-flex'>
                              <a
                                href='#'
                                className='text-dark fw-bold text-hover-primary m-0 w-100'
                              >
                                {commenterLanguage ? commenterLanguage[data]?.name : ''}
                              </a>
                              <span className='fs-7 text-gray-400 w-100 d-flex justify-content-end'>
                                {commenterLanguage
                                  ? (commenterLanguage[data]?.weight * 100).toFixed(2) + '%'
                                  : ''}
                              </span>
                            </div>
                            <div className='w-100 border h-10px rounded mt-1'>
                              <div
                                style={{
                                  width: commenterLanguage
                                    ? Math.round(commenterLanguage[data]?.weight * 100) + '%'
                                    : '',
                                }}
                                className='h-100 bg-info rounded'
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className='basicDiv w-100 p16 m-2'>
                  <div className='h-100'>
                    <div className='h-100 d-flex flex-column justify-content-between'>
                      <div>
                        <img src={toAbsoluteUrl('/media/logos/overview_followers.png')} />
                        <h2 className='fs-16' style={{marginTop: '12px'}}>
                          Notable Followers
                        </h2>
                      </div>
                      <h3 className=''>
                        {props?.userData?.audience_commenters
                          ? props?.userData?.audience_commenters?.data?.notable_users_ratio * 100 +
                            '%'
                          : ''}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
      {props.type === false && (
        <>
          <div
            className='d-flex flex-column w-100 rounded'
            style={{backgroundColor: 'white', padding: '24px'}}
          >
            <h2>{name}'s Audience Interests</h2>
            <div className='d-flex flex-wrap align-items-center m16'>
              <span>
                <img src={toAbsoluteUrl('/media/icons/Custom/travel.svg')} />
                <span className='fs-14' style={{marginLeft: '8px'}}>
                  Travel,Tourism & Aviation
                </span>
              </span>
              <span style={{marginLeft: '24px'}}>
                <img src={toAbsoluteUrl('/media/icons/Custom/clothes.svg')} />
                <span className='fs-14' style={{marginLeft: '8px'}}>
                  Clothes,Shoes & Handbags
                </span>
              </span>
            </div>
          </div>
          <div className='d-flex flex-column m20 w-100 rounded' style={{backgroundColor: 'white'}}>
            <div className='border-bottom' style={{padding: '16px 24px'}}>
              <h2>Audience Reachability</h2>
            </div>
            <div className='w-100' style={{marginTop: '8px'}}>
              <ColumnChart className='w-100 ' audienceData={audienceData} />
            </div>
          </div>
          <div className='d-flex flex-column m20 w-100 rounded' style={{backgroundColor: 'white'}}>
            <div className='border-bottom' style={{padding: '16px 24px'}}>
              <h2>Age and Gender Split </h2>
            </div>
            <div className='w-100' style={{marginTop: '8px'}}>
              <ColumnDoubleChart className='w-100 ' audienceData={audienceData} />
            </div>
          </div>
          <div className='d-flex m20'>
            <div
              className='d-flex flex-column w-50 me-5 rounded'
              style={{backgroundColor: 'white'}}
            >
              <div className='border-bottom flex p-5'>
                <h5 className='m-0'>Audience by Location</h5>
              </div>
              <div className='w-100 flex'>
                <DonutChart className='w-300px ' audienceData={audienceData} type='Location' />
              </div>
              <div className='campaign-pie-markers w-100 pt-5 pb-10 ps-3 pe-3'>
                <div className='campaign-pie-marker'>
                  <p>
                    <div style={{backgroundColor: '#EC6363'}}></div>Live
                  </p>
                  <p>-</p>
                </div>
                <div className='border border-gray-100 mb-3' />
                <div className='campaign-pie-marker'>
                  <p>
                    <div style={{backgroundColor: '#2DCA73'}}></div>Completed
                  </p>
                  <p>-</p>
                </div>
                <div className='border border-gray-100 mb-3' />
                <div className='campaign-pie-marker'>
                  <p>
                    <div style={{backgroundColor: '#FCA440'}}></div>Under Review
                  </p>
                  <p>-</p>
                </div>
              </div>
            </div>
            <div className='d-flex flex-column w-50 rounded' style={{backgroundColor: 'white'}}>
              <div className='border-bottom flex p-5'>
                <h5 className='m-0'>Authenticity of Audience</h5>
              </div>
              <div className='w-100 flex'>
                <DonutChart2 className='w-300px' audienceData={audienceData} type='Language' />
              </div>
              <div className='campaign-pie-markers w-100 pt-5 pb-10 ps-3 pe-3'>
                <div className='campaign-pie-marker'>
                  <p>
                    <div style={{backgroundColor: '#EC6363'}}></div>Live
                  </p>
                  <p>-</p>
                </div>
                <div className='border border-gray-100 mb-3' />
                <div className='campaign-pie-marker'>
                  <p>
                    <div style={{backgroundColor: '#2DCA73'}}></div>Completed
                  </p>
                  <p>-</p>
                </div>
                <div className='border border-gray-100 mb-3' />
                <div className='campaign-pie-marker'>
                  <p>
                    <div style={{backgroundColor: '#FCA440'}}></div>Under Review
                  </p>
                  <p>-</p>
                </div>
              </div>
            </div>
          </div>
          <div className='d-flex flex-column w-50 rounded m20' style={{backgroundColor: 'white'}}>
            <div className='border-bottom flex p-5'>
              <h5 className='m-0'>Audience Interest Affinity </h5>
            </div>
            <div className='w-100 flex'>
              <DonutChart1 className='w-300px ' audienceData={audienceData} type='Interest' />
            </div>
            <div className='campaign-pie-markers w-100 pt-5 pb-10 ps-3 pe-3'>
              <div className='campaign-pie-marker'>
                <p>
                  <div style={{backgroundColor: '#EC6363'}}></div>Live
                </p>
                <p>-</p>
              </div>
              <div className='border border-gray-100 mb-3' />
              <div className='campaign-pie-marker'>
                <p>
                  <div style={{backgroundColor: '#2DCA73'}}></div>Completed
                </p>
                <p>-</p>
              </div>
              <div className='border border-gray-100 mb-3' />
              <div className='campaign-pie-marker'>
                <p>
                  <div style={{backgroundColor: '#FCA440'}}></div>Under Review
                </p>
                <p>-</p>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default ProfileAudience
