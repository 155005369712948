
import {useHistory} from 'react-router-dom'
import './basic.css'
import {Table} from './Table'
export function AgencyBrandTable(props: any) {
  const history: any = useHistory()
  const pageType = history.location.state?.type
 
  return (
    <div className='Outer d-flex'>
      <div className='TableDiv'>
        <Table type={pageType}></Table>
      </div>
      {/* <div className='RecentSummary'>
        <div className='RecentDiv d-flex'>
          <div className='RecentSummaryChild'>
            <span className='svg-icon svg-icon-2x svg-icon-dark'>
              <KTSVG path={toAbsoluteUrl('/media/icons/Custom/Recent.svg')} />
            </span>
            <div className='d-flex position-relative'>
              <div className='w-75'>
                <h6 className='text-gray-500 mt-4'>In Review</h6>
                <h3 className='fw-bolder'>123</h3>
              </div>
              <span className='svg-icon svg-icon-gray-700 me-1 flex pointer'>
                <KTSVG path={toAbsoluteUrl('/media/icons/Custom/SideArrow.svg')} />
              </span>
            </div>
          </div>
          <div className='RecentSummaryChild' style={{marginLeft: '15px'}}>
            <span className='svg-icon svg-icon-2x svg-icon-dark'>
              <KTSVG path={toAbsoluteUrl('/media/icons/Custom/Laptop.svg')} />
            </span>
            <div className='d-flex position-relative'>
              <div className='w-75'>
                <h6 className='text-gray-500 mt-4'>Total Due</h6>
                <h3 className='w-75'>123</h3>
              </div>
              <span className='svg-icon svg-icon-gray-700 me-1 flex pointer'>
                <KTSVG path={toAbsoluteUrl('/media/icons/Custom/SideArrow.svg')} />
              </span>
            </div>
          </div>
        </div>
        <div className='SummaryDiv'>
          <div className='row mt-2'>
            <span className='col-9 '>
              <h4 className='ms-2 fw-bolder '>Overview 👁️</h4>
            </span>
          </div>
          <div className='row mt-5'>
            {pageType == 'Brand' ? (
              <span className='ms-4 svg-icon d-flex justify-content-center align-items-center svg-icon-info bg-light bg-opacity-25 w-40px h-40px rounded'>
                <KTSVG path={toAbsoluteUrl('/media/icons/Custom/Total.svg')} />
              </span>
            ) : (
              <span className='ms-4 svg-icon d-flex justify-content-center align-items-center svg-icon-warning bg-warning-custom-light1 bg-opacity-25 w-40px h-40px rounded'>
                <KTSVG path={toAbsoluteUrl('/media/icons/Custom/Total.svg')} />
              </span>
            )}

            <span className='col-6'>
              <div className='fw-bolder'>Total {pageType == 'Brand' ? 'Brands' : 'Agencies'}</div>
              <div className='fs-8 text-gray-400'>Overall {pageType} Onboarded</div>
            </span>
            <span className='col d-flex justify-content-end pe-5 me-4 align-items-center fw-bolder'>
              102
            </span>
          </div>
          {pageType === 'Agency' && (
            <div className='row mt-5'>
              <span className='ms-4 svg-icon d-flex justify-content-center align-items-center svg-icon-info bg-light bg-opacity-25 w-40px h-40px rounded'>
                <KTSVG path={toAbsoluteUrl('/media/icons/Custom/Total.svg')} />
              </span>

              <span className='col-7'>
                <div className='fw-bolder'>Brands {'<>'} Agencies</div>
                <div className='fs-8 text-gray-400'>Brands associated with agencies</div>
              </span>
              <span className='col d-flex justify-content-end pe-5 me-4  align-items-center fw-bolder'>
                221
              </span>
            </div>
          )}
          <div className='row mt-5'>
            <span className='ms-4 svg-icon d-flex justify-content-center align-items-center svg-icon-primary bg-primary bg-opacity-5 w-40px h-40px rounded'>
              <KTSVG path={toAbsoluteUrl('/media/icons/Custom/Recent.svg')} />
            </span>

            <span className='col-6'>
              <div className='fw-bolder'>Recently Added</div>
              <div className='fs-8 text-gray-400'>Added in last 30 days</div>
            </span>
            <span className='col d-flex justify-content-end pe-5 me-4  align-items-center fw-bolder'>
              6
            </span>
          </div>
          <div className='row mt-5'>
            <span className='ms-4 svg-icon d-flex justify-content-center align-items-center svg-icon-success bg-success bg-opacity-5 w-40px h-40px rounded'>
              <KTSVG path={toAbsoluteUrl('/media/icons/Custom/Active.svg')} />
            </span>
            <span className='col-6'>
              <div className='fw-bolder'>Active Campaign</div>
              <div className='fs-8 text-gray-400'>Approved campaigns</div>
            </span>
            <span className='col d-flex justify-content-end pe-5 me-4  align-items-center fw-bolder'>
              122
            </span>
          </div>
          <div className='row mt-5'>
            <span className='ms-4 svg-icon d-flex justify-content-center align-items-center svg-icon-secondary bg-secondary bg-opacity-5 w-40px h-40px rounded'>
              <KTSVG path={toAbsoluteUrl('/media/icons/Custom/Influencer.svg')} />
            </span>
            <span className='col-6'>
              <div className='fw-bolder'>Influencer Associated</div>
              <div className='fs-8 text-gray-400'>For active brands</div>
            </span>
            <span className='col d-flex justify-content-end pe-5 me-4  align-items-center fw-bolder'>
              350
            </span>
          </div>
        </div>
      </div> */}
    </div>
  )
}
